import { createAsyncThunk } from '@reduxjs/toolkit';

import { ICallAddParticipantRequest } from '@/interfaces/voice/call';

import handleErrors from '@/services/handle-errors';

import * as contactApi from '@/services/api/v1/contact';
import * as voiceApi from '@/services/api/v1/voice';

import { IThunkExtra } from '@/store/store';

import { IGetContactsQueryParams } from '@/interfaces/contact';

export const fetchContacts = createAsyncThunk(
  'voice/fetchContactsInitial',
  async (data: Pick<IGetContactsQueryParams, 'limit' | 'contactType'>, thunkAPI) => {
    const response = await contactApi.getByParams({
      ...data,
      skip: 0,
    });

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSearchContact = createAsyncThunk(
  'voice/fetchSearchContact',
  async (data: Pick<IGetContactsQueryParams, 'search' | 'contactType'>, thunkAPI) => {
    const response = await contactApi.getByParams({
      ...data,
      skip: 0,
      limit: 0,
    });

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchOnLoadMore = createAsyncThunk(
  'voice/fetchOnLoadMore',
  async (data: IGetContactsQueryParams, thunkAPI) => {
    const response = await contactApi.getByParams(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchTwilioAccessToken = createAsyncThunk(
  'voice/fetchTwilioAccessToken',
  async (data, thunkAPI) => {
    const response = await voiceApi.getTwilioAccessToken();
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCallByProviderCallId = createAsyncThunk(
  'voice/fetchCallByProviderCallId',
  async (data: string, thunkAPI) => {
    const response = await voiceApi.getCallByProviderCallId(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchPutCallOnHold = createAsyncThunk(
  'voice/fetchPutCallOnHold',
  async (data: string, thunkAPI) => {
    const response = await voiceApi.putCallOnHold(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchResumeCall = createAsyncThunk(
  'voice/fetchResumeCall',
  async (data: string, thunkAPI) => {
    const response = await voiceApi.resumeCall(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCancelCall = createAsyncThunk(
  'voice/fetchCancelCall',
  async (data: string, thunkAPI) => {
    const response = await voiceApi.cancelCall(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchForwardCall = createAsyncThunk(
  'voice/fetchForwardCall',
  async (data: { uuid: string; to: string }, thunkAPI) => {
    const response = await voiceApi.forwardCall(data.uuid, data.to);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchMuteCall = createAsyncThunk(
  'voice/fetchMuteCall',
  async (data: string, thunkAPI) => {
    const response = await voiceApi.muteCall(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCallsHistoryInitial = createAsyncThunk(
  'voice/fetchCallsHistoryInitial',
  async (data: { skip: number; limit: number }, thunkAPI) => {
    const response = await voiceApi.getCallsHistory(data.skip, data.limit);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCallsHistory = createAsyncThunk(
  'voice/fetchCallsHistory',
  async (data: { skip: number; limit: number }, thunkAPI) => {
    const response = await voiceApi.getCallsHistory(data.skip, data.limit);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSetAnsweredBy = createAsyncThunk(
  'voice/fetchSetAnsweredBy',
  async (data: { uuid: string; userId: string }, thunkAPI) => {
    const response = await voiceApi.setAnsweredBy(data.uuid, { userId: data.userId });
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchUserByCompanyExtension = createAsyncThunk(
  'voice/fetchUserByCompanyExtension',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.getByCompanyExtension(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCallsRecording = createAsyncThunk(
  'voice/fetchCallsRecording',
  async (data: { skip: number; limit: number }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.voiceApis.getCallsRecordings(data.skip, data.limit);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchDeleteCallRecordings = createAsyncThunk(
  'voice/fetchDeleteCallRecordings',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.voiceApis.deleteCallRecording(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchUsers = createAsyncThunk(
  'voice/fetchUsers',
  async (data: { excludeMe: boolean; limit: number; skip: number }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.getByParams({
      excludeMe: data.excludeMe,
      limit: data.limit,
      skip: data.skip,
    });

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchAddParticipants = createAsyncThunk(
  'voice/fetchAddParticipants',
  async (data: { uuid: string; data: ICallAddParticipantRequest }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.voiceApis.addParticipantToCall(data.uuid, data.data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCallTranscriptionByCallHistoryId = createAsyncThunk(
  'voice/fetchCallTranscriptionByCallHistoryId',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.voiceApis.getCallTranscriptionByCallHistoryId(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCallRecordingByCallHistoryId = createAsyncThunk(
  'voice/fetchCallRecordingByCallHistoryId',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.voiceApis.getCallRecordingByCallHistoryId(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchDeleteCallTranscriptionById = createAsyncThunk(
  'voice/fetchDeleteCallTranscriptionById',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.voiceApis.deleteCallTranscriptionById(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchTranscribeCallRecording = createAsyncThunk(
  'voice/fetchTranscribeCallRecording',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.voiceApis.transcribeCallRecording(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchEmailCallTranscription = createAsyncThunk(
  'voice/fetchEmailCallTranscription',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.voiceApis.emailCallTranscription(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchConferenceParticipants = createAsyncThunk(
  'voice/fetchConferenceParticipants',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.voiceApis.getConferenceParticipants(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchDropConferenceParticipant = createAsyncThunk(
  'voice/fetchDropConferenceParticipant',
  async (data: { callUuid: string; participantUuid: string }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.voiceApis.dropConferenceParticipant(
      data.callUuid,
      data.participantUuid,
    );

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchMakeParticipantConferenceOwner = createAsyncThunk(
  'voice/fetchMakeParticipantConferenceOwner',
  async (data: { callUuid: string; participantUuid: string }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.voiceApis.makeParticipantConferenceOwner(
      data.callUuid,
      data.participantUuid,
    );

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchResetMissedCallsCount = createAsyncThunk(
  'voice/fetchResetMissedCallsCount',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.resetMissedCallsCount();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchDownloadCallRecordingTrack = createAsyncThunk(
  'voice/fetchDownloadCallRecordingTrack',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    return v1Apis.voiceApis.downloadCallRecordingTrack(data);
  },
);

export const fetchDownloadVoicemailTrack = createAsyncThunk(
  'voice/fetchDownloadVoicemailTrack',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    return v1Apis.voiceApis.downloadVoicemailTrack(data);
  },
);

export const fetchDownloadCallTranscription = createAsyncThunk(
  'voice/fetchDownloadCallTranscription',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    return v1Apis.voiceApis.downloadCallTranscription(data);
  },
);

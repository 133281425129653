import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import {
  Autocomplete,
  Box,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

import { IUserResult } from '@/interfaces/users/users';
import { IContact, IContactResult, IGetContactsQueryParams } from '@/interfaces/contact';
import { IAddNewChatDialogResponse } from '@/interfaces/chat';

import CustomAvatar from '@/components/Custom-avatar/Avatar';
import PhoneNumberInput from '@/components/Phone-number-input/Phone-number-input';
import Transition from '@/components/Dialogs/Transition';

import * as contactThunk from '@/pages/dashboard/contacts/thunk';
import * as chatsThunk from '../../thunk';

import * as socketService from '@/services/socketio/socketio';
import apisV1 from '@/services/api/v1/index';

import { useFormik } from 'formik';

import CreateNewChatValidationSchema from '@/helpers/validation-schemas/create-new-chat';

import SOCKETIO from '@/constants/socketio';
import CHAT from '@/constants/chat';
import { CONTACT_TYPE } from '@/constants/contacts';
import { FORMIK_STATUS } from '@/constants/formik';
import { ADDONS } from '@/constants/addons';

const NewChatDialog = (props: {
  showEnterPhoneNumberDialog: boolean;
  handleNewChat: (data: IAddNewChatDialogResponse) => void;
  setShowEnterPhoneNumberDialog: Dispatch<SetStateAction<boolean>>;
}) => {
  const { handleNewChat, showEnterPhoneNumberDialog, setShowEnterPhoneNumberDialog } = props;

  const dispatch = useAppDispatch();

  const { tenant } = useAppSelector((state) => state.tenant);

  const { user } = useAppSelector((state) => state.user);

  const [users, setUsers] = useState<IUserResult[]>([]);
  const [contacts, setContacts] = useState<IContactResult[]>([]);

  const formik = useFormik<IAddNewChatDialogResponse & { chatVariant: CHAT.VARIANT }>({
    initialStatus: FORMIK_STATUS.LOADING,
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      chatVariant: CHAT.VARIANT.CLASSIC,
      chatType: user?.canSeeCompanyMsgsAndCalls ? CHAT.TYPE.TENANT : CHAT.TYPE.INTERNAL,
      chatPlatform: CHAT.PLATFORM.SMS,
      contact: null,
      internalUser: null,
      phoneNumber: '',
      chat: null,
    },
    validationSchema: CreateNewChatValidationSchema,
    onSubmit: async (response, formikHelpers) => {
      formikHelpers.setStatus(FORMIK_STATUS.LOADING);

      const isChatInternal = (response.chatType as string) === CHAT.TYPE.INTERNAL;

      if (isChatInternal) {
        const { payload } = await dispatch(
          chatsThunk.fetchChatByReceiverCompanyExt(
            (response.internalUser as IUserResult).companyExtension,
          ),
        );

        response.chat = payload;
      } else {
        if (response.contact) {
          const { payload } = await dispatch(
            chatsThunk.fetchGetOneByRelativeInfo({
              phoneNumber: response.contact.phoneNumber,
              type: response.chatType as CHAT.TYPE,
              platform: response.chatPlatform,
            }),
          );

          response.chat = payload;
        } else {
          const [contactResult, chatResult] = await Promise.all([
            dispatch(
              contactThunk.fetchGetPrivateOrCompanyContact({
                chatType: response.chatType as CHAT.TYPE,
                phoneNumber: response.phoneNumber as string,
              }),
            ),
            dispatch(
              chatsThunk.fetchGetOneByRelativeInfo({
                phoneNumber: response.phoneNumber as string,
                type: response.chatType,
                platform: response.chatPlatform,
              }),
            ),
          ]);

          response.contact = contactResult.payload;
          response.chat = chatResult.payload;
        }
      }

      if (response.chat) {
        socketService.socket?.emit(
          SOCKETIO.EVENTS.JOIN_CHAT,
          JSON.stringify({ chatId: response.chat.id }),
        );
      }

      formikHelpers.resetForm({
        status: FORMIK_STATUS.IDLE,
      });

      handleNewChat(response);

      setShowEnterPhoneNumberDialog(false);
    },
  });

  const getUsers = async (): Promise<void> => {
    if (formik.values.chatType !== 'internal') {
      return;
    }

    const { success, data } = await apisV1.usersApis.getByParams({
      excludeMe: true,
      skip: 0,
      limit: 0,
    });

    if (success) {
      setUsers(data.records);
    }
  };

  const getContacts = async (): Promise<void> => {
    if (formik.values.chatType === 'internal') {
      return;
    }

    const queryParams: IGetContactsQueryParams = { limit: 0, skip: 0 };

    if (formik.values.chatType === CHAT.TYPE.TENANT) {
      queryParams.contactType = CONTACT_TYPE.COMPANY;
    }

    const { payload } = await dispatch(contactThunk.fetchGetContacts(queryParams));

    if (payload) {
      setContacts(payload.records);
    }
  };

  const handleContactChange = (value: IContact | undefined): void => {
    formik.setFieldValue('phoneNumber', '', true);
    formik.setFieldValue('contact', value ?? null, true);
  };

  const handleInternalUserChange = (value: IUserResult | undefined): void => {
    formik.setFieldValue('phoneNumber', '', true);
    formik.setFieldValue('internalUser', value ?? null, true);
  };

  const loadAllData = async () => {
    formik.setStatus(FORMIK_STATUS.LOADING);

    await Promise.all([getUsers(), getContacts()]);

    formik.setStatus(FORMIK_STATUS.IDLE);
  };

  useEffect(() => {
    if (showEnterPhoneNumberDialog) {
      loadAllData();
    }
  }, [showEnterPhoneNumberDialog]);

  useEffect(() => {
    const isChatInternal = formik.values.chatType === 'internal';

    if (isChatInternal) {
      formik.setFieldValue('phoneNumber', '', true);
      getUsers();
    } else {
      formik.setFieldValue('internalUser', null, true);
      formik.setFieldValue('contact', null, true);
      getContacts();
    }
  }, [formik.values.chatType]);

  const isChatInternal = formik.values.chatType === 'internal';
  const isFormNotAvailable = formik.status === FORMIK_STATUS.LOADING;

  const disableTenantChatSelection = !user?.canSeeCompanyMsgsAndCalls;
  const disableDirectChatSelection = !user?.directExtension;

  const isWhatsAppEnabled = tenant?.addons.some((el) => el.name === ADDONS.WHATSAPP);

  return (
    <Dialog
      open={showEnterPhoneNumberDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setShowEnterPhoneNumberDialog(false)}
    >
      <DialogTitle color="primary">Send message</DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <Stack spacing={2} width={500} maxHeight={300}>
          <Box minHeight={60}>
            {isChatInternal ? (
              <Autocomplete
                id="internalUser"
                options={users}
                disabled={isFormNotAvailable}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                getOptionLabel={(option) => `${option.fullName} | ${option.companyExtension}`}
                onChange={(event, list, reason, detail) => handleInternalUserChange(detail?.option)}
                onBlur={formik.handleBlur}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    display={'flex'}
                    alignItems={'center'}
                    px={1}
                    {...props}
                    key={option.id}
                  >
                    <CustomAvatar
                      url={option.avatar?.url}
                      lastActivityAt={option.lastActivityAt}
                      sx={{ height: 20, width: 20 }}
                    />
                    <Box>
                      <Typography fontSize="small" ml={1}>
                        {option.fullName} | {option.companyExtension}
                      </Typography>
                      <Typography fontSize="small" color={'grey'} ml={1}>
                        {option.companyExtension}
                      </Typography>
                    </Box>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Internal extension"
                    error={formik.touched.internalUser && !!formik.errors.internalUser}
                    helperText={
                      formik.touched.internalUser && (formik.errors.internalUser as string)
                    }
                  />
                )}
              />
            ) : (
              <Stack spacing={1}>
                <PhoneNumberInput
                  returnNullOnInvalid={true}
                  value={formik.values.phoneNumber ?? ''}
                  countryCodesListType={'user-list'}
                  onPhoneNumberChange={(value) => {
                    if (value) {
                      formik.setFieldValue('contact', null, true);
                      formik.setFieldValue('phoneNumber', value, true);
                      formik.setFieldTouched('phoneNumber', true);
                    }
                  }}
                  fullWidth
                />

                <Autocomplete
                  id="contact"
                  options={contacts}
                  value={formik.values.contact}
                  disabled={isFormNotAvailable}
                  onChange={(event, list, reason, detail) => handleContactChange(detail?.option)}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option) => `${option.fullName} | ${option.phoneNumber}`}
                  isOptionEqualToValue={(option, value) => option.id === value?.id}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      display={'flex'}
                      alignItems={'center'}
                      px={1}
                      {...props}
                      key={option.id}
                    >
                      <CustomAvatar
                        url={option.avatar?.url}
                        showLiveStatus={false}
                        sx={{ height: 20, width: 20 }}
                      />

                      <Box>
                        <Typography fontSize="small" ml={1}>
                          {option.fullName}
                        </Typography>

                        <Typography fontSize="small" color={'grey'} ml={1}>
                          {option.phoneNumber}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Contact"
                      error={formik.touched.contact && !!formik.errors.contact}
                      helperText={formik.touched.contact && (formik.errors.contact as string)}
                    />
                  )}
                />
              </Stack>
            )}
          </Box>

          <Stack spacing={1}>
            <FormControl>
              <FormLabel>Select outgoing number</FormLabel>

              <RadioGroup
                row
                name="chatType"
                value={formik.values.chatType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="tenant"
                  label="Main/Business"
                  control={<Radio disabled={disableTenantChatSelection} />}
                />
                <FormControlLabel
                  value="direct"
                  label="Direct"
                  control={<Radio disabled={disableDirectChatSelection} />}
                />
                <FormControlLabel label="Internal" value="internal" control={<Radio />} />
              </RadioGroup>
            </FormControl>

            {!isChatInternal ? (
              <FormControl>
                <FormLabel>Send via</FormLabel>

                <RadioGroup
                  row
                  name="chatPlatform"
                  value={formik.values.chatPlatform}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel value={CHAT.PLATFORM.SMS} label="SMS" control={<Radio />} />
                  <FormControlLabel
                    disabled={!isWhatsAppEnabled}
                    value={CHAT.PLATFORM.WHATSAPP}
                    label="WhatsApp"
                    control={<Radio />}
                  />
                </RadioGroup>
              </FormControl>
            ) : null}
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setShowEnterPhoneNumberDialog(false);
          }}
        >
          Close
        </Button>

        <Button
          color="primary"
          variant="contained"
          disabled={
            (!formik.values.phoneNumber && !formik.values.contact && !formik.values.internalUser) ||
            isFormNotAvailable
          }
          onClick={() => {
            formik.submitForm();
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewChatDialog;

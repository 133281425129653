import { Outlet } from 'react-router-dom';

import { Box, Paper, Stack } from '@mui/material';

import NavTab from '@/components/Tabs-navigation/Tabs-navigation';

import ROUTES from '@/constants/routes';

const UsersRelatedInfoLayout = () => {
  const links = [
    { label: 'Users', path: ROUTES.DASHBOARD_USERS },
    { label: 'Direct extensions', path: ROUTES.DASHBOARD_DIRECT_EXTENSIONS },
    { label: 'Groups', path: ROUTES.DASHBOARD_USERS_GROUPS },
  ];

  return (
    <Box flexGrow={1} m={2}>
      <Box component={Paper}>
        <Stack spacing={2}>
          <NavTab links={links} />
          <Outlet />
        </Stack>
      </Box>
    </Box>
  );
};

export default UsersRelatedInfoLayout;

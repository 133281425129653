import { FunctionComponent } from 'react';

import { Box, Typography, Tooltip, ListItem, ListItemButton, Stack, Button } from '@mui/material';

import {
  Groups as GroupsIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@mui/icons-material';

import { IConnection } from '@/interfaces/voice/call';

import '@/pages/dashboard/voice/call-status/conference/styles/voice.css';

interface ICallListItemProps {
  call: IConnection;
  handleSelectConnection: (connection: IConnection) => void;
}

const CallListItem: FunctionComponent<ICallListItemProps> = ({
  call,
  handleSelectConnection,
}): JSX.Element => {
  const dataToDisplay = call.direction === 'outbound' ? call.callee : call.caller;

  return (
    <ListItem disableGutters disablePadding color="error" sx={{ position: 'relative' }}>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => handleSelectConnection(call)}
      >
        <Box
          component="span"
          display="flex"
          flex={1}
          gap={2}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {dataToDisplay?.firstName ? (
            <Box display="flex" flexDirection="column">
              <Typography
                variant="body2"
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  maxWidth: '250px',
                }}
              >
                {dataToDisplay?.firstName} {dataToDisplay?.lastName ?? ''}
              </Typography>

              <Typography variant="caption" textAlign="start" fontSize={12}>
                {dataToDisplay?.phoneNumber}
              </Typography>
            </Box>
          ) : (
            <Typography noWrap py={1}>
              {dataToDisplay?.phoneNumber}
            </Typography>
          )}

          {call.isMultipleParticipants && (
            <Box display={'flex'} alignItems={'center'}>
              <Tooltip
                title={<Typography fontSize={12}>This call is a conference call</Typography>}
                followCursor
              >
                <GroupsIcon sx={{ color: 'primary.main' }} />
              </Tooltip>
            </Box>
          )}

          <Box display="flex">
            {call.selected && (
              <Tooltip title="Selected connection" placement="left">
                <CheckCircleOutlineIcon color="success" fontSize="small" />
              </Tooltip>
            )}

            {call.activeNow && (
              <Tooltip title="Currently speaking" placement="left">
                <RadioButtonCheckedIcon color="success" fontSize="small" />
              </Tooltip>
            )}
          </Box>
        </Box>
      </Button>
    </ListItem>
  );
};

export default CallListItem;

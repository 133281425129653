import {
  FormControlLabel,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useFormik } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';

import { IUserAllUsersPreferencesResult } from '@/interfaces/users/users';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as tenantReducer from '@/store/reducers/tenant';
import * as userThunk from '@/pages/dashboard/users/thunk';

import * as SNACKBAR from '@/constants/snackbar';

import { FORMIK_STATUS } from '@/constants/formik';
import CALL from '@/constants/voice/voice';

const DevicesPreferences: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [initialDevicesPreferences, setInitialDevicesPreferences] = useState<
    IUserAllUsersPreferencesResult['devicesPreferences']
  >([]);
  const { tenant } = useAppSelector((state) => state.tenant);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialStatus: FORMIK_STATUS.LOADING,
    enableReinitialize: true,
    initialValues: {
      canUsersUpdateDevicesPreferences: false,
      devicesPreferences: [],
    } as any,
    onSubmit: () => {},
  });

  const loadDevicesPreferences = async () => {
    formik.setStatus(FORMIK_STATUS.LOADING);

    if (!tenant?.devicesPreferences) {
      return;
    }

    setInitialDevicesPreferences(tenant.devicesPreferences);

    formik.setValues({
      canUsersUpdateDevicesPreferences: tenant.canUsersUpdateDevicesPreferences,
      devicesPreferences: tenant.devicesPreferences
        .filter((el) => el.allowCall)
        .map((el) => el.type),
    });

    setTimeout(() => formik.setStatus(FORMIK_STATUS.IDLE), 0);
  };

  const updateDevicesPreferences = async (values: string[], checked: boolean): Promise<void> => {
    setIsLoading(true);

    await formik.setFieldValue('devicesPreferences', values, true);
    await formik.setFieldValue('canUsersUpdateDevicesPreferences', checked, true);

    const devicesPreferences = initialDevicesPreferences.map((el) => ({
      type: el.type,
      allowCall: !!values.includes(el.type),
    }));

    const { meta, payload } = await dispatch(
      userThunk.fetchUpdateAllUsersPreferences({
        devicesPreferences,
        canUsersUpdateDevicesPreferences: checked,
      }),
    );

    if (meta.requestStatus === 'rejected') {
      await loadDevicesPreferences();
    }
    if (tenant) {
      dispatch(tenantReducer.setTenant({ ...tenant, ...payload }));
    }

    dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.UPDATED('Call preferences')));

    setIsLoading(false);
  };

  useEffect(() => {
    loadDevicesPreferences();
  }, []);

  return (
    <Stack spacing={2}>
      <FormControlLabel
        label="Allow users to update their call preferences"
        name="canUsersUpdateDevicesPreferences"
        control={
          <Switch
            checked={formik.values.canUsersUpdateDevicesPreferences}
            onChange={(e, checked) =>
              updateDevicesPreferences(formik.values.devicesPreferences, checked)
            }
            onBlur={formik.handleBlur}
          />
        }
      />

      <Stack spacing={1}>
        <Typography component="label" htmlFor="devices-preferences">
          Select devices that should ring for inbound call
        </Typography>

        <ToggleButtonGroup
          id="devices-preferences"
          size="small"
          fullWidth
          sx={{ maxWidth: '25rem' }}
          value={formik.values.devicesPreferences}
          onChange={(event, values) => {
            updateDevicesPreferences(values, formik.values.checked);
          }}
        >
          {initialDevicesPreferences.map((el: any) => (
            <ToggleButton name="devicesPreferences" key={el.type} value={el.type}>
              {CALL.VOICE_DEVICES_PREFERENCES_LABELS[el.type as CALL.VOICE_DEVICE]}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
    </Stack>
  );
};

export default DevicesPreferences;

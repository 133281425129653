import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import PublicLayoutFooter from '@/components/Public-layout/Public-layout-footer';
import PublicLayoutHeader from '@/components/Public-layout/Public-layout-header';

import ROUTES from '@/constants/routes';

interface ISplashProps {
  showLoading?: boolean;
}

const Splash = ({ showLoading = false }: ISplashProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const id = setTimeout(() => {
      navigate(ROUTES.SIGN_IN, {
        replace: true,
      });
    }, 5000);

    return () => clearTimeout(id);
  });

  return (
    <Box display="flex" justifyContent="space-between" flexDirection="column" minHeight="100vh">
      <PublicLayoutHeader showLinks={false} />

      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        sx={{
          // 128px because it is height of AppBar and Footer components combined
          height: 'calc(100vh - 128px)',
          overflow: 'auto',
        }}
      >
        {showLoading ? (
          <Stack spacing={1} justifyContent="center" alignItems="center">
            <CircularProgress color="primary" size={120} />

            <Typography variant="body1" component="p">
              Loading...
            </Typography>
          </Stack>
        ) : null}
      </Box>

      <PublicLayoutFooter />
    </Box>
  );
};

export default Splash;

namespace CHAT {
  export enum TYPE {
    TENANT = 'tenant',
    DIRECT = 'direct',
    INTERNAL = 'internal',
    ANY = 'any',
  }

  export enum PLATFORM {
    SMS = 'sms',
    WHATSAPP = 'whatsapp',
    ANY = 'any',
  }

  export enum STATUS {
    ACTIVE = 'active',
    ARCHIVED = 'archived',
  }

  export enum MARKS {
    COMPLETED = 'completed',
    FOLLOW_UP = 'follow_up',
  }

  export enum VARIANT {
    IOT_DEVICE = 'iot_device',
    CLASSIC = 'classic',
  }
}

export default CHAT;

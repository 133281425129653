import { FunctionComponent, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { Box } from '@mui/material';

import { IConnection } from '@/interfaces/voice/call';

import * as voiceReducer from '@/store/reducers/dashboard/voice';
import * as voiceThunk from '@/pages/dashboard/voice/thunk';

import ConferenceCallListItem from '../conference/Conference-call-list-item';
import CallListItem from '../call/Call-list-item';

const ActiveCallsList: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const connections = useAppSelector((state) => state.voice.connections);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSelectConnection = async (connection: IConnection) => {
    dispatch(voiceReducer.setSelectedConnection(connection.uuid));
  };

  const handleDropParticipant = async (
    callUuid: string,
    participantUuid: string,
  ): Promise<void> => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    await dispatch(voiceThunk.fetchDropConferenceParticipant({ callUuid, participantUuid }));
    setIsLoading(false);
  };

  const handleMakeParticipantConferenceOwner = async (
    callUuid: string,
    participantUuid: string,
  ): Promise<void> => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    await dispatch(voiceThunk.fetchMakeParticipantConferenceOwner({ callUuid, participantUuid }));
    setIsLoading(false);
  };

  if (connections.length === 1 && !connections[0].participants.length) {
    return <></>;
  }

  return (
    <Box flex={1} overflow="auto" p={1} display="flex" flexDirection="column" gap={1}>
      {connections.map((el, i) => {
        const isConference = el?.isMultipleParticipants && el?.participants.length;

        if (isConference) {
          return (
            <ConferenceCallListItem
              key={el.uuid}
              conference={el}
              orderNumber={i}
              user={user}
              handleDropParticipant={handleDropParticipant}
              handleMakeParticipantConferenceOwner={handleMakeParticipantConferenceOwner}
              handleSelectConnection={handleSelectConnection}
            />
          );
        }

        return (
          <CallListItem key={el.uuid} call={el} handleSelectConnection={handleSelectConnection} />
        );
      })}
    </Box>
  );
};

export default ActiveCallsList;

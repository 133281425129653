import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUseLongPress {
  startLongPress: boolean;
}

const initialState: IUseLongPress = {
  startLongPress: false,
};

export const useLongPressSlice = createSlice({
  name: 'useLongPress',
  initialState,
  reducers: {
    setStartLongPress: (state, action: PayloadAction<boolean>) => {
      state.startLongPress = action.payload;
    },
  },
});

export const { setStartLongPress } = useLongPressSlice.actions;

export default useLongPressSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { FormikValues, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { Paper, Step, StepContent, StepLabel, Stepper } from '@mui/material';

import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Autocomplete,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Link,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

import SignUpSchema from '@/helpers/validation-schemas/sign-up';
import { validateForm } from '@/helpers/formik';
import * as phoneNumberHelper from '@/helpers/phone-number';

import { ISignUpTenant } from '@/interfaces/tenant';
import { ITimeZone } from '@/interfaces/time-zone';
import { ICountriesResult } from '@/interfaces/countries/countries';

import * as timeZoneThunk from '@/store/thunk/time-zone';
import * as countriesThunk from '@/store/thunk/countries';
import * as tenantSignUpThunk from '@/store/thunk/tenant-sign-up';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as backdropReducer from '@/store/reducers/backdrop';

import ROUTES from '@/constants/routes';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  companyName: '',
  timeZone: 'America/New_York',
  companyContactFirstName: '',
  companyContactLastName: '',
  companyContactEmail: '',
  companyContactPhoneNumber: '',
  companyBillingAdminFirstName: '',
  companyBillingAdminLastName: '',
  companyBillingAdminEmail: '',
  companyBillingAdminPhoneNumber: '',
  companyAddressCountry: '',
  companyAddressCity: '',
  companyAddressProvince: '',
  companyAddressAddress1: '',
  companyAddressAddress2: '',
  companyAddressPostcode: '',
};

const SignUp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState<boolean>(false);
  const [isTermsOfServiceAccepted, setIsTermsOfServiceAccepted] = useState<boolean>(false);
  const [timeZones, setTimeZones] = useState<ITimeZone[]>([]);
  const [countries, setCountries] = useState<ICountriesResult[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountriesResult>();
  const [selectedProvince, setSelectedProvince] = useState<{ name: string; iso: string }>();
  const [activeStep, setActiveStep] = useState<number>(0);

  const formik = useFormik({
    initialValues,
    validationSchema: SignUpSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => createTenant(values),
  });

  const createTenant = async (values: FormikValues): Promise<void> => {
    dispatch(backdropReducer.setShow());

    let companyContactPhoneNumber = null;
    let companyBillingAdminPhoneNumber = null;
    if (values.companyContactPhoneNumber) {
      const result = phoneNumberHelper.validate(values.companyContactPhoneNumber);
      companyContactPhoneNumber = result;
    }
    if (values.companyBillingAdminPhoneNumber) {
      const result = phoneNumberHelper.validate(values.companyBillingAdminPhoneNumber);
      companyBillingAdminPhoneNumber = result;
    }

    if (!companyContactPhoneNumber || !companyBillingAdminPhoneNumber) {
      return;
    }

    const data: ISignUpTenant = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      companyName: values.companyName,
      timeZone: values.timeZone,
      companyContact: {
        firstName: values.companyContactFirstName,
        lastName: values.companyContactLastName,
        email: values.companyContactEmail,
        phoneNumber: companyContactPhoneNumber.validatedPhoneNumber,
      },
      companyBillingAdmin: {
        firstName: values.companyBillingAdminFirstName,
        lastName: values.companyBillingAdminLastName,
        email: values.companyBillingAdminEmail,
        phoneNumber: companyBillingAdminPhoneNumber.validatedPhoneNumber,
      },
      companyAddress: {
        country: values.companyAddressCountry,
        province: values.companyAddressProvince,
        city: values.companyAddressCity,
        address1: values.companyAddressAddress1,
        address2: values.companyAddressAddress2,
        postalCode: values.companyAddressPostcode,
      },
    };

    const result = await dispatch(tenantSignUpThunk.fetchTenantSignUp(data));

    if (result.payload) {
      dispatch(
        snackbarReducer.showSuccess('Congratulations! Please login and select subscription...'),
      );
      navigate(ROUTES.AUTH_LAYOUT);
    }
  };

  const getCountries = async (): Promise<void> => {
    const { payload } = await dispatch(countriesThunk.fetchCountries());
    if (payload) {
      setCountries(payload);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleOnTimeZoneChanges = (reason: string, value: string) => {
    if (reason === 'selectOption') {
      formik.setFieldValue('timeZone', value);
    }
    if (reason === 'clear') {
      formik.setFieldValue('timeZone', '');
    }
  };

  const handleOnCountryChanges = (reason: string, value: ICountriesResult) => {
    if (reason === 'selectOption') {
      setSelectedCountry(value);
      formik.setFieldValue('companyAddressCountry', value.iso, true);
      formik.setFieldValue('companyAddressProvince', '', true);
      setSelectedProvince(undefined);
    }
    if (reason === 'clear') {
      setSelectedCountry(undefined);
      formik.setFieldValue('companyAddressCountry', '', true);
      formik.setFieldValue('companyAddressProvince', '', true);
      setSelectedProvince(undefined);
    }
  };

  const handleOnProvinceChanges = (reason: string, value: { name: string; iso: string }) => {
    if (reason === 'selectOption') {
      setSelectedProvince(value);
      formik.setFieldValue('companyAddressProvince', value.iso, true);
    }
    if (reason === 'clear') {
      setSelectedProvince(undefined);
      formik.setFieldValue('companyAddressProvince', '', true);
    }
  };

  const handleLegalInfoAccept = (
    which: 'terms of service' | 'privacy policy' = 'terms of service',
  ) => {
    if (which === 'terms of service') {
      setIsTermsOfServiceAccepted(!isTermsOfServiceAccepted);
    } else {
      setIsPrivacyPolicyAccepted(!isPrivacyPolicyAccepted);
    }
  };

  const getTimezones = async () => {
    const { payload } = await dispatch(timeZoneThunk.fetchTimeZones());
    if (payload) {
      setTimeZones(payload);
    }
  };

  const handleOpenLegalInfo = (
    which: 'terms of service' | 'privacy policy' | 'contact us',
  ): void => {
    if (which === 'contact us') {
      window.open('https://whistle.tech/contact-us/', '_blank', 'noopener,noreferrer');
      return;
    }
    window.open('https://whistle.tech/tos/', '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    getTimezones();
    getCountries();
  }, []);

  const companyInfoFields = [
    'companyName',
    'timeZone',
    'companyContactFirstName',
    'companyContactLastName',
    'companyContactEmail',
    'companyContactPhoneNumber',
    'companyAddressCountry',
    'companyAddressProvince',
    'companyAddressAddress1',
    'companyAddressCity',
    'companyAddressPostcode',
  ];

  const companyBillingFields = [
    'companyBillingAdminFirstName',
    'companyBillingAdminLastName',
    'companyBillingAdminEmail',
    'companyBillingAdminPhoneNumber',
  ];

  const administrationFields = ['firstName', 'lastName', 'email', 'password', 'confirmPassword'];

  // For now leave as it is
  const isCompanyInfoInvalid = false;
  // activeStep !== 0 &&
  // companyInfoFields.some((field) => {
  //   const key = field as keyof typeof formik.values;
  //   return formik.touched[key] && !formik.values[key];
  // });

  // For now leave as it is
  const isBillingInfoInvalid = false;
  // activeStep !== 1 &&
  // companyBillingFields.some((field) => {
  //   const key = field as keyof typeof formik.values;
  //   return formik.touched[key] && !formik.values[key];
  // });

  // For now leave as it is
  const isAdministrationInfoInvalid = false;
  // activeStep !== 2 &&
  // administrationFields.some((field) => {
  //   const key = field as keyof typeof formik.values;
  //   return formik.touched[key] && !formik.values[key];
  // });

  return (
    <Box display="flex" flex={1} p={2} overflow="auto" justifyContent="center">
      <Box flex={1} maxWidth="50rem" p={2} component={Paper}>
        <Box mb={2}>
          <Typography variant="h5" component="span" color="primary" textAlign="center">
            Sign up
          </Typography>
        </Box>

        <Stepper orientation="vertical" activeStep={activeStep}>
          <Step key={0}>
            <StepLabel
              sx={{
                '& .Mui-error': {
                  color: 'secondary.dark',
                },
              }}
              error={isCompanyInfoInvalid}
              optional={
                isCompanyInfoInvalid ? (
                  <Typography variant="caption" color="secondary.dark">
                    Please verify your company information
                  </Typography>
                ) : null
              }
            >
              Company
            </StepLabel>

            <StepContent>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" flexDirection="column" gap={1}>
                  <TextField
                    required
                    label="Company name"
                    id="companyName"
                    name="companyName"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                    helperText={formik.touched.companyName && formik.errors.companyName}
                  />

                  <Autocomplete
                    disablePortal
                    id="time-zones"
                    options={timeZones?.length ? timeZones : []}
                    getOptionLabel={(option) => option.fullName}
                    isOptionEqualToValue={(option, value) => option.fullName === value.fullName}
                    defaultValue={{
                      fullName: 'America/New_York | (GMT-05:00) Eastern Time',
                      name: 'America/New_York',
                      description: '(GMT-05:00) Eastern Time',
                    }}
                    onChange={(event, list, reason, detail) =>
                      handleOnTimeZoneChanges(reason, detail?.option?.name as string)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onBlur={formik.handleBlur}
                        error={formik.touched.timeZone && Boolean(formik.errors.timeZone)}
                        helperText={formik.touched.timeZone && formik.errors.timeZone}
                        label="Time Zone"
                      />
                    )}
                  />

                  <TextField
                    label="Company contact: first name"
                    required
                    id="companyContactFirstName"
                    name="companyContactFirstName"
                    value={formik.values.companyContactFirstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyContactFirstName &&
                      Boolean(formik.errors.companyContactFirstName)
                    }
                    helperText={
                      formik.touched.companyContactFirstName &&
                      formik.errors.companyContactFirstName
                    }
                  />

                  <TextField
                    label="Company contact: last name"
                    required
                    id="companyContactLastName"
                    name="companyContactLastName"
                    value={formik.values.companyContactLastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyContactLastName &&
                      Boolean(formik.errors.companyContactLastName)
                    }
                    helperText={
                      formik.touched.companyContactLastName && formik.errors.companyContactLastName
                    }
                  />

                  <TextField
                    required
                    label="Company contact: email"
                    id="companyContactEmail"
                    name="companyContactEmail"
                    value={formik.values.companyContactEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyContactEmail &&
                      Boolean(formik.errors.companyContactEmail)
                    }
                    helperText={
                      formik.touched.companyContactEmail && formik.errors.companyContactEmail
                    }
                  />

                  <TextField
                    label="Company contact: phone number"
                    required
                    id="companyContactPhoneNumber"
                    name="companyContactPhoneNumber"
                    value={formik.values.companyContactPhoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyContactPhoneNumber &&
                      Boolean(formik.errors.companyContactPhoneNumber)
                    }
                    helperText={
                      formik.touched.companyContactPhoneNumber &&
                      formik.errors.companyContactPhoneNumber
                    }
                    inputMode="numeric"
                  />

                  <Autocomplete
                    disablePortal
                    id="country"
                    value={selectedCountry ?? null}
                    options={countries?.length ? countries : []}
                    getOptionLabel={(option) => option.name as string}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    onChange={(event, list, reason, detail) => {
                      return handleOnCountryChanges(reason, detail?.option as ICountriesResult);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.companyAddressCountry &&
                          Boolean(formik.errors.companyAddressCountry)
                        }
                        helperText={
                          formik.touched.companyAddressCountry &&
                          formik.errors.companyAddressCountry
                        }
                        label="Company address: Country"
                      />
                    )}
                  />

                  {selectedCountry?.provinces?.length ? (
                    <Autocomplete
                      disablePortal
                      id="province"
                      value={selectedProvince ?? null}
                      options={selectedCountry ? selectedCountry.provinces ?? [] : []}
                      getOptionLabel={(option) => option.name as string}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      onChange={(event, list, reason, detail) => {
                        return handleOnProvinceChanges(reason, detail?.option as any);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.companyAddressProvince &&
                            Boolean(formik.errors.companyAddressProvince)
                          }
                          helperText={
                            formik.touched.companyAddressProvince &&
                            formik.errors.companyAddressProvince
                          }
                          label="Company address: Province/State"
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      required
                      label="Company address: Province/State"
                      id="companyAddressProvince"
                      name="companyAddressProvince"
                      value={formik.values.companyAddressProvince}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.companyAddressProvince &&
                        Boolean(formik.errors.companyAddressProvince)
                      }
                      helperText={
                        formik.touched.companyAddressProvince &&
                        formik.errors.companyAddressProvince
                      }
                    />
                  )}

                  <TextField
                    required
                    label="Company address: Address 1"
                    id="companyAddressAddress1"
                    name="companyAddressAddress1"
                    value={formik.values.companyAddressAddress1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyAddressAddress1 &&
                      Boolean(formik.errors.companyAddressAddress1)
                    }
                    helperText={
                      formik.touched.companyAddressAddress1 && formik.errors.companyAddressAddress1
                    }
                  />

                  <TextField
                    label="Company address: Address 2"
                    id="companyAddressAddress2"
                    name="companyAddressAddress2"
                    value={formik.values.companyAddressAddress2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyAddressAddress2 &&
                      Boolean(formik.errors.companyAddressAddress2)
                    }
                    helperText={
                      formik.touched.companyAddressAddress2 && formik.errors.companyAddressAddress2
                    }
                  />

                  <TextField
                    required
                    label="Company address: City"
                    id="companyAddressCity"
                    name="companyAddressCity"
                    value={formik.values.companyAddressCity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyAddressCity && Boolean(formik.errors.companyAddressCity)
                    }
                    helperText={
                      formik.touched.companyAddressCity && formik.errors.companyAddressCity
                    }
                  />

                  <TextField
                    required
                    label="Company address: Post code/ZIP"
                    id="companyAddressPostcode"
                    name="companyAddressPostcode"
                    value={formik.values.companyAddressPostcode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyAddressPostcode &&
                      Boolean(formik.errors.companyAddressPostcode)
                    }
                    helperText={
                      formik.touched.companyAddressPostcode && formik.errors.companyAddressPostcode
                    }
                  />
                </Box>

                <Box display="flex" gap={1}>
                  <Button color="primary" variant="contained" onClick={() => setActiveStep(1)}>
                    Next
                  </Button>
                </Box>
              </Box>
            </StepContent>
          </Step>

          <Step key={1}>
            <StepLabel
              error={isBillingInfoInvalid}
              sx={{
                '& .Mui-error': {
                  color: 'secondary.dark',
                },
              }}
              optional={
                isBillingInfoInvalid ? (
                  <Typography variant="caption" color="secondary.dark">
                    Please verify your billing information
                  </Typography>
                ) : null
              }
            >
              Billing
            </StepLabel>

            <StepContent>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" flexDirection="column" gap={1}>
                  <TextField
                    label="Billing administrator: first name"
                    required
                    id="companyBillingAdminFirstName"
                    name="companyBillingAdminFirstName"
                    value={formik.values.companyBillingAdminFirstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyBillingAdminFirstName &&
                      Boolean(formik.errors.companyBillingAdminFirstName)
                    }
                    helperText={
                      formik.touched.companyBillingAdminFirstName &&
                      formik.errors.companyBillingAdminFirstName
                    }
                  />

                  <TextField
                    label="Billing administrator: last name"
                    required
                    id="companyBillingAdminLastName"
                    name="companyBillingAdminLastName"
                    value={formik.values.companyBillingAdminLastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyBillingAdminLastName &&
                      Boolean(formik.errors.companyBillingAdminLastName)
                    }
                    helperText={
                      formik.touched.companyBillingAdminLastName &&
                      formik.errors.companyBillingAdminLastName
                    }
                  />

                  <TextField
                    required
                    label="Billing administrator: email"
                    id="companyBillingAdminEmail"
                    name="companyBillingAdminEmail"
                    value={formik.values.companyBillingAdminEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyBillingAdminEmail &&
                      Boolean(formik.errors.companyBillingAdminEmail)
                    }
                    helperText={
                      formik.touched.companyBillingAdminEmail &&
                      formik.errors.companyBillingAdminEmail
                    }
                  />

                  <TextField
                    label="Billing administrator: phone number"
                    required
                    id="companyBillingAdminPhoneNumber"
                    name="companyBillingAdminPhoneNumber"
                    value={formik.values.companyBillingAdminPhoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.companyBillingAdminPhoneNumber &&
                      Boolean(formik.errors.companyBillingAdminPhoneNumber)
                    }
                    helperText={
                      formik.touched.companyBillingAdminPhoneNumber &&
                      formik.errors.companyBillingAdminPhoneNumber
                    }
                    inputMode="tel"
                  />
                </Box>

                <Box display="flex" gap={1}>
                  <Button color="primary" variant="contained" onClick={() => setActiveStep(0)}>
                    Previous
                  </Button>

                  <Button color="primary" variant="contained" onClick={() => setActiveStep(2)}>
                    Next
                  </Button>
                </Box>
              </Box>
            </StepContent>
          </Step>

          <Step key={2}>
            <StepLabel
              error={isAdministrationInfoInvalid}
              sx={{
                '& .Mui-error': {
                  color: 'secondary.dark',
                },
              }}
              optional={
                isAdministrationInfoInvalid ? (
                  <Typography variant="caption" color="secondary.dark">
                    Please verify your administration configuration
                  </Typography>
                ) : null
              }
            >
              Administration
            </StepLabel>

            <StepContent>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" flexDirection="column" gap={1}>
                  <TextField
                    required
                    label="First name"
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                  />

                  <TextField
                    required
                    label="Last name"
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                  />

                  <TextField
                    required
                    label="Email"
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <TextField
                    required
                    label="Password"
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    required
                    label="Confirm password"
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <FormControl component="fieldset" variant="standard">
                    <FormGroup sx={{ pl: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isTermsOfServiceAccepted}
                            onChange={() => handleLegalInfoAccept()}
                            size="small"
                          />
                        }
                        label={
                          <>
                            <Typography variant="inherit" mr={1} display="inline">
                              I accept
                            </Typography>

                            <Link
                              href="#"
                              color="info.main"
                              sx={{ display: 'inline' }}
                              onClick={() => handleOpenLegalInfo('terms of service')}
                              display={'flex'}
                              flexDirection={'row'}
                            >
                              Terms of Service
                            </Link>
                          </>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isPrivacyPolicyAccepted}
                            onChange={() => handleLegalInfoAccept('privacy policy')}
                            size="small"
                          />
                        }
                        label={
                          <>
                            <Typography variant="inherit" mr={1} display="inline">
                              I accept
                            </Typography>

                            <Link
                              href="#"
                              color="info.main"
                              sx={{ display: 'inline' }}
                              onClick={() => handleOpenLegalInfo('terms of service')}
                              display={'flex'}
                              flexDirection={'row'}
                            >
                              Privacy Policy
                            </Link>
                          </>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Box>

                <Box display="flex" gap={1} justifyContent="space-between">
                  <Button color="primary" variant="contained" onClick={() => setActiveStep(1)}>
                    Previous
                  </Button>

                  <Button
                    disableElevation={false}
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={() => {
                      validateForm(initialValues, formik);
                      formik.handleSubmit();
                    }}
                    disabled={!isTermsOfServiceAccepted || !isPrivacyPolicyAccepted}
                    sx={{ fontWeight: 'bold' }}
                  >
                    Create account
                  </Button>
                </Box>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
      </Box>
    </Box>
  );
};

export default SignUp;

import React from 'react';

import { Dialog, Box, Slide, DialogContent, LinearProgress, Typography } from '@mui/material';
import { TransitionProps } from 'notistack';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const SwitchingAccountDialog = (props: { show: boolean }) => {
  const { show } = props;

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth
      maxWidth="xs"
      open={show}
      keepMounted={false}
    >
      <DialogContent>
        <Box px={2} pt={1} display={'flex'} justifyContent={'center'}>
          <Typography>Switching account. One moment please...</Typography>
        </Box>
        <Box minHeight={10}>
          <LinearProgress variant="indeterminate" color="primary" />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SwitchingAccountDialog;

import { ButtonGroup, Button, Typography } from '@mui/material';

import { SubsTerm } from '@/types/subs';

import * as styles from './styles';
import { Dispatch, SetStateAction } from 'react';

const priceSwitchStyle = (selected: boolean) => {
  const style: { [key: string]: string | number | { [key: string]: string } } = {
    width: 200,
  };

  if (selected) {
    return {
      ...style,
      color: '#f6c004',
      borderColor: 'black',
      backgroundColor: 'black',
      '&:hover': {
        borderColor: 'inherit',
        color: '#f6c004',
        backgroundColor: 'black',
      },
    };
  }
  return {
    ...style,
    color: 'black',
    borderColor: '#f6c004',
    backgroundColor: 'white',
    '&:hover': {
      borderColor: 'inherit',
      color: 'black',
      backgroundColor: '#f6c004',
    },
  };
};

interface IPricingDialogSlide {
  paymentType: SubsTerm;
  setPaymentType: Dispatch<SetStateAction<SubsTerm>>;
}

const MonthlyYearlySwitcher = (props: IPricingDialogSlide) => {
  const { paymentType, setPaymentType } = props;

  return (
    <ButtonGroup variant="outlined">
      <Button
        onClick={() => setPaymentType('monthly')}
        sx={priceSwitchStyle(paymentType === 'monthly')}
      >
        Monthly
      </Button>
      <Button
        onClick={() => setPaymentType('annual')}
        sx={priceSwitchStyle(paymentType === 'annual')}
      >
        Yearly
        <Typography
          sx={{
            ...styles.Typography4SX,
            color: paymentType === 'annual' ? '#f6c004' : 'black',
            textShadow:
              paymentType === 'annual'
                ? '1px 1px 10px #f6c004, 0 0 1em #f6c004, 0 0 0.2em #f6c004;'
                : '1px 1px 10px black, 0 0 1em #f6c004, 0 0 0.2em #f6c004;',
          }}
        >
          discounted
        </Typography>
      </Button>
    </ButtonGroup>
  );
};

export default MonthlyYearlySwitcher;

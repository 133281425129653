import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';

import {
  Close as CloseIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon,
  Phone as PhoneIcon,
  Message as MessageIcon,
  FilterListOutlined as FilterListOutlinedIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  MoreTime as MoreTimeIcon,
} from '@mui/icons-material';

import {
  Box,
  TextField,
  Paper,
  InputAdornment,
  Fab,
  LinearProgress,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  ListItemIcon,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

import { useAppSelector } from '@/store/hooks';

import { IChatResult, IGetChatsQueryParams, INotCreatedChat } from '@/interfaces/chat';
import { IPartialResult } from '@/interfaces/querying-data';
import { IChatMenuState } from '../Chats-messages-layout';

import useAccess from '@/hooks/use-access';

import * as styles from './styles';

import ClassicList from '@/pages/dashboard/chat/chats-list/Classic-list';
import IoTDevicesList from '@/pages/dashboard/chat/chats-list/IoT-devices-list';

import CHAT from '@/constants/chat';
import { ADDONS } from '@/constants/addons';
import { FEATURES } from '@/constants/features';
import { ACCESS_CONTROLS } from '@/constants/access-controls';

interface IChatsListProps {
  isLoading: boolean;
  menuState: IChatMenuState;
  setMenuState: Dispatch<SetStateAction<IChatMenuState>>;
  setIsEnterPhoneNumberDialogOpened: Dispatch<SetStateAction<boolean>>;
  setShowStartNewIotDeviceChatDialog: Dispatch<SetStateAction<boolean>>;
  selectedChat: IChatResult | null;
  notCreatedSelectedChat: INotCreatedChat | null;
  setSelectedChat: Dispatch<SetStateAction<IChatResult | null>>;
  setNotCreatedSelectedChat: Dispatch<SetStateAction<INotCreatedChat | null>>;
  chatsResult: IPartialResult<IChatResult>;
  queryParams: Required<IGetChatsQueryParams> & Record<'search', string>;
  setChatsResult: React.Dispatch<React.SetStateAction<IPartialResult<IChatResult>>>;
  setQueryParams: Dispatch<SetStateAction<IChatsListProps['queryParams']>>;
  handleSendOtherTypeOfMessage: (chat: IChatResult) => Promise<void>;
  handleSelectChat: (chat: IChatResult) => void;
  handleUpdateChatMarks: (chatId: string, marks: CHAT.MARKS[]) => Promise<void>;
  handleCallFromChatMenu: (chat: IChatResult) => void;
}

const ChatsList: FunctionComponent<IChatsListProps> = ({
  isLoading,
  menuState,
  setMenuState,
  selectedChat,
  notCreatedSelectedChat,
  setSelectedChat,
  setNotCreatedSelectedChat,
  chatsResult,
  queryParams,
  setChatsResult,
  setQueryParams,
  handleSelectChat,
  handleSendOtherTypeOfMessage,
  handleUpdateChatMarks,
  setIsEnterPhoneNumberDialogOpened,
  setShowStartNewIotDeviceChatDialog,
  handleCallFromChatMenu,
}): JSX.Element => {
  const { user } = useAppSelector((state) => state.user);
  const { tenant } = useAppSelector((state) => state.tenant);

  const [filterButtonMenuAnchor, setFilterButtonMenuAnchor] = useState<HTMLElement | null>(null);

  const { isFeatureAvailable } = useAccess();

  const isChatMenuOpened = !!menuState.anchor;
  const isWhatsAppEnabled = tenant?.addons.some((el) => el.name === ADDONS.WHATSAPP);
  const isSearchActive = !!queryParams.search;

  const handleOpenNewChatDialog = (): void => {
    if (queryParams.variant === CHAT.VARIANT.CLASSIC) {
      setIsEnterPhoneNumberDialogOpened(true);
    }
    if (queryParams.variant === CHAT.VARIANT.IOT_DEVICE) {
      setShowStartNewIotDeviceChatDialog(true);
    }
  };

  return (
    <Box display="flex" flexDirection="column" position="relative" width={320} gap={1}>
      <Box component={Paper}>
        <TextField
          fullWidth
          sx={styles.searchInputSX}
          id="outlined-basic"
          label="Search by number"
          variant="outlined"
          size="small"
          value={queryParams.search}
          onChange={(event) =>
            setQueryParams((prevState) => ({
              ...prevState,
              search: event.target.value,
              platform: CHAT.PLATFORM.ANY,
            }))
          }
          InputProps={{
            endAdornment: isSearchActive ? (
              <InputAdornment position="end">
                <IconButton
                  disabled={!isSearchActive}
                  onClick={() => {
                    setQueryParams((prevState) => ({
                      ...prevState,
                      search: '',
                      skip: 0,
                      platform: CHAT.PLATFORM.ANY,
                    }));
                  }}
                >
                  <CloseIcon color="inherit" />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
      </Box>

      <Box
        component={Paper}
        display="flex"
        flexDirection="column"
        position="relative"
        overflow="auto"
        flex={1}
      >
        <Box display="flex" position="sticky" top={0}>
          <Box display={'flex'} width={'100%'} flexDirection={'column'}>
            {tenant?.accessControls.some((element) => element.name === ACCESS_CONTROLS.IOT) && (
              <FormControl>
                <RadioGroup
                  row
                  value={queryParams.variant}
                  sx={{ display: 'flex', justifyContent: 'space-evenly' }}
                  onChange={(e, value) => {
                    setChatsResult({ maxCount: 0, records: [] });
                    setSelectedChat(null);

                    setQueryParams((prevState) => ({
                      ...prevState,
                      variant: value as CHAT.VARIANT,
                      platform:
                        value === CHAT.VARIANT.IOT_DEVICE ? CHAT.PLATFORM.ANY : prevState.platform,
                    }));
                  }}
                >
                  <FormControlLabel
                    value={CHAT.VARIANT.CLASSIC}
                    control={<Radio size="small" />}
                    label="Classic"
                  />
                  <FormControlLabel
                    value={CHAT.VARIANT.IOT_DEVICE}
                    control={<Radio size="small" />}
                    label="Devices"
                  />
                </RadioGroup>
              </FormControl>
            )}

            {queryParams.variant === CHAT.VARIANT.CLASSIC && (
              <Box display={'flex'} width={'100%'}>
                <ToggleButtonGroup
                  size="extra-small"
                  exclusive
                  disabled={isSearchActive}
                  value={queryParams.platform}
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    '& > button': {
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      borderTopRightRadius: 0,
                    },
                  }}
                  onChange={(event, newAlignment) => {
                    if (newAlignment) {
                      setQueryParams((prevState) => ({
                        ...prevState,
                        skip: 0,
                        platform: newAlignment,
                      }));
                    }
                  }}
                >
                  <ToggleButton value={CHAT.PLATFORM.ANY}>All</ToggleButton>

                  <ToggleButton value={CHAT.PLATFORM.SMS}>SMS</ToggleButton>

                  <ToggleButton disabled={!isWhatsAppEnabled} value={CHAT.PLATFORM.WHATSAPP}>
                    WhatsApp
                  </ToggleButton>
                </ToggleButtonGroup>
                <IconButton
                  disabled={isSearchActive}
                  size="small"
                  color="primary"
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    borderRadius: 0,
                    '&[disabled]': {
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                      opacity: 0.38,
                    },
                  }}
                  onClick={(event) => {
                    setFilterButtonMenuAnchor(event.currentTarget);
                  }}
                >
                  {!queryParams.marks.length ? <FilterListOutlinedIcon fontSize="small" /> : null}

                  {queryParams.marks.includes(CHAT.MARKS.COMPLETED) ? (
                    <CheckCircleOutlineIcon fontSize="small" />
                  ) : null}

                  {queryParams.marks.includes(CHAT.MARKS.FOLLOW_UP) ? (
                    <MoreTimeIcon fontSize="small" />
                  ) : null}
                </IconButton>
              </Box>
            )}
          </Box>

          <Menu
            open={!!filterButtonMenuAnchor}
            anchorEl={filterButtonMenuAnchor}
            onClose={() => setFilterButtonMenuAnchor(null)}
          >
            <MenuItem
              onClick={() => {
                setFilterButtonMenuAnchor(null);

                setSelectedChat(null);
                setNotCreatedSelectedChat(null);

                setQueryParams((prevState) => ({
                  ...prevState,
                  skip: 0,
                  marks: prevState.marks.includes(CHAT.MARKS.COMPLETED)
                    ? []
                    : [CHAT.MARKS.COMPLETED],
                }));
              }}
            >
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" fontSize="small" />
              </ListItemIcon>
              {queryParams.marks.includes(CHAT.MARKS.COMPLETED) ? 'Clear filter' : 'Filter'} by
              completed
            </MenuItem>

            <MenuItem
              onClick={() => {
                setFilterButtonMenuAnchor(null);

                setSelectedChat(null);
                setNotCreatedSelectedChat(null);

                setQueryParams((prevState) => ({
                  ...prevState,
                  skip: 0,
                  marks: prevState.marks.includes(CHAT.MARKS.FOLLOW_UP)
                    ? []
                    : [CHAT.MARKS.FOLLOW_UP],
                }));
              }}
            >
              <ListItemIcon>
                <MoreTimeIcon color="primary" fontSize="small" />
              </ListItemIcon>
              {queryParams.marks.includes(CHAT.MARKS.FOLLOW_UP) ? 'Clear filter' : 'Filter'} by
              follow up
            </MenuItem>
          </Menu>
        </Box>

        {isLoading && <LinearProgress variant="indeterminate" color="primary" />}

        {/* Classic chats list */}
        {queryParams.variant === CHAT.VARIANT.CLASSIC && (
          <ClassicList
            chatsResult={chatsResult}
            isChatMenuOpened={isChatMenuOpened}
            isLoading={isLoading}
            isSearchActive={isSearchActive}
            handleSelectChat={handleSelectChat}
            handleUpdateChatMarks={handleUpdateChatMarks}
            setQueryParams={setQueryParams}
            notCreatedSelectedChat={notCreatedSelectedChat}
            menuState={menuState}
            queryParams={queryParams}
            selectedChat={selectedChat}
            setMenuState={setMenuState}
          />
        )}

        {/* IoT Devices chats list */}
        {queryParams.variant === CHAT.VARIANT.IOT_DEVICE && (
          <IoTDevicesList
            isLoading={isLoading}
            isSearchActive={isSearchActive}
            chatsResult={chatsResult}
            handleSelectChat={handleSelectChat}
            menuState={menuState}
            notCreatedSelectedChat={notCreatedSelectedChat}
            selectedChat={selectedChat}
            setQueryParams={setQueryParams}
          />
        )}
      </Box>

      <Fab
        size="small"
        color="primary"
        sx={{
          position: 'absolute',
          bottom: '2.5%',
          right: '4%',
        }}
        onClick={() => handleOpenNewChatDialog()}
      >
        <AddIcon />
      </Fab>

      <Menu
        id="chat-menu"
        aria-labelledby="chat-menu-button"
        anchorEl={menuState.anchor}
        open={isChatMenuOpened}
        onClose={() => setMenuState((prevState) => ({ ...prevState, anchor: null }))}
      >
        <MenuItem
          disabled={!isFeatureAvailable(FEATURES.VOICE)}
          onClick={() => {
            setMenuState((prevState) => ({ ...prevState, anchor: null }));
            handleCallFromChatMenu(menuState.chat as IChatResult);
          }}
        >
          <ListItemIcon>
            <PhoneIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Call
        </MenuItem>
        {!menuState.chat?.isInternal && menuState.chat?.type !== CHAT.TYPE.INTERNAL && (
          <MenuItem
            disabled={
              (menuState.chat?.type !== CHAT.TYPE.DIRECT && !user?.directExtension) ||
              (menuState.chat?.type === CHAT.TYPE.DIRECT && !user?.canSeeCompanyMsgsAndCalls)
            }
            onClick={() => handleSendOtherTypeOfMessage(menuState.chat as IChatResult)}
          >
            <ListItemIcon>
              <MessageIcon color="primary" fontSize="small" />
            </ListItemIcon>
            Send {menuState.chat?.type === CHAT.TYPE.DIRECT ? 'company' : 'direct'} message
          </MenuItem>
        )}
        <MenuItem
          disabled={selectedChat?.id === menuState.chat?.id}
          onClick={() => {
            setMenuState((prevState) => ({ ...prevState, anchor: null }));
            handleSelectChat(menuState.chat as IChatResult);
          }}
        >
          <ListItemIcon>
            <VisibilityIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Messages
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ChatsList;

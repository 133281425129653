import { createAsyncThunk } from '@reduxjs/toolkit';

import { IVoiceSettingsRequest, IVoiceIncomingSettingsCallFlow } from '@/interfaces/voice/settings';
import { IGetUsersQueryParams } from '@/interfaces/users/users';

import { IThunkExtra } from '@/store/store';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import handleErrors from '@/services/handle-errors';

import * as voiceSettingsApi from '@/services/api/v1/voice-settings';
import * as extensionsGroupsApi from '@/services/api/v1/extensions-groups';

import * as SNACKBAR from '@/constants/snackbar';

export const fetchGetVoiceSettings = createAsyncThunk(
  'voiceSettings/fetchGetVoiceSettings',
  async (data, thunkAPI) => {
    const response = await voiceSettingsApi.getVoiceSettings();
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSaveSetupSettings = createAsyncThunk(
  'voiceSettings/fetchSaveSetupSettings',
  async (data: IVoiceSettingsRequest, thunkAPI) => {
    const response = await voiceSettingsApi.saveSetupSettings(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      const { dispatch } = thunkAPI;
      dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.SAVED('Greeting settings')));
      return response.data;
    }
  },
);

export const fetchSaveCallFlowSettings = createAsyncThunk(
  'voiceSettings/fetchSaveCallFlowSettings',
  async (data: IVoiceIncomingSettingsCallFlow[], thunkAPI) => {
    const response = await voiceSettingsApi.saveCallFlowSettings(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      const { dispatch } = thunkAPI;
      dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.SAVED('Call flow settings')));
      return response.data;
    }
  },
);

export const fetchGetUsers = createAsyncThunk(
  'voiceSettings/fetchGetUsers',
  async (data: IGetUsersQueryParams, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.getByParams(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchExtensionsGroups = createAsyncThunk(
  'voiceSettings/fetchExtensionsGroups',
  async (data, thunkAPI) => {
    const response = await extensionsGroupsApi.getAll();
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Button, Typography } from '@mui/material';

import SignInImage from '@/assets/images/sign-in-white-bg.png';

import useThemeBreakpoints from '@/hooks/use-theme-breakpoints';

import ROUTES from '@/constants/routes';

const SignIn = () => {
  const { loginWithRedirect } = useAuth0();

  const navigate = useNavigate();

  const isMdOrUp = useThemeBreakpoints('up', 'md');
  const isLgOrUp = useThemeBreakpoints('up', 'lg');

  const handleSignUp = (): void => {
    navigate(ROUTES.SIGN_UP);
  };

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent={isMdOrUp ? 'flex-start' : 'center'}
      alignItems="center"
      py={1}
      sx={{
        backgroundImage: isLgOrUp ? `url(${SignInImage})` : '',
        backgroundPosition: isLgOrUp ? 'right' : 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'inherit',
        height: 'inherit',
        width: 'inherit',
      }}
    >
      <Box
        maxWidth="23rem"
        ml={isMdOrUp ? 20 : 0}
        alignItems={'center'}
        display={'flex'}
        flexDirection={'column'}
      >
        <Stack spacing={1} alignItems="center">
          <Typography
            variant="h6"
            component="h1"
            fontWeight="600"
            fontSize={36}
            fontFamily={'DM Serif Display'}
          >
            “Whistle”
          </Typography>
        </Stack>

        <Stack spacing={1} alignItems="center" justifyContent="center">
          <Button
            fullWidth
            color="secondary"
            size="large"
            sx={{
              fontWeight: 'bold',
            }}
            variant="contained"
            onClick={() => loginWithRedirect()}
          >
            Login
          </Button>

          <Button
            fullWidth
            color="primary"
            size="large"
            sx={{
              color: 'secondary.main',
              fontWeight: 'bold',
            }}
            variant="contained"
            onClick={handleSignUp}
          >
            Sign up
          </Button>

          <Typography variant="body2" textAlign="center">
            By continuing, I confirm I have read and accept the “Terms of Service“ and the “Privacy
            Policy“
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default SignIn;

import { Outlet } from 'react-router-dom';

import { Box, Paper, Stack } from '@mui/material';

import NavTab from '@/components/Tabs-navigation/Tabs-navigation';

import useAccess from '@/hooks/use-access';

import ROUTES from '@/constants/routes';
import { ROLES } from '@/constants/roles';

const IotDevicesPageLayout = () => {
  const { isUserRoleMatches } = useAccess();

  const links = [{ label: 'Devices', path: ROUTES.DASHBOARD_IOT_DEVICES_PAGE_LAYOUT }];

  if (isUserRoleMatches([ROLES.SUPER_ADMIN, ROLES.ADMIN])) {
    links.push({ label: 'Groups', path: ROUTES.DASHBOARD_IOT_DEVICES_GROUPS });
  }

  return (
    <Box flexGrow={1} m={2}>
      <Box component={Paper}>
        <Stack spacing={2}>
          <NavTab links={links} />
          <Outlet />
        </Stack>
      </Box>
    </Box>
  );
};

export default IotDevicesPageLayout;

export enum STATUSES {
  INITIATED = 'initiated',
  COMPLETED = 'completed',
  REFUNDED = 'refunded',
  ERROR = 'error',
}

export default {
  STATUSES,
};

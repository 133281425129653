export const dialogTitle1SX = {
  display: 'flex',
  justifyContent: 'center',
  p: 0,
};

export const dialogContent1SX = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  pb: 0,
};

export const dialogContentText1SX = {
  mb: 1,
};

export const dialogAction1SX = {
  p: 0,
};

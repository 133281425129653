import { IConnection } from '@/interfaces/voice/call';

import * as voiceReducer from '@/store/reducers/dashboard/voice';
import { AppDispatch, store } from '@/store/store';

export const handleEndRinging = async (data: {
  dispatch: AppDispatch;
  uuid: string;
  userId: string;
}) => {
  const state = store.getState();
  const { dispatch, uuid, userId } = data;

  const { voice: voiceState, twilio: twilioState, user: authorizedUserState } = state;

  if (userId !== authorizedUserState.user?.id) {
    return;
  }

  const { connections } = voiceState;
  const { device } = twilioState;

  const connection = voiceState.connections.find((conn: IConnection) => conn.uuid === uuid);

  if (!connection) {
    return;
  }

  dispatch(voiceReducer.setDialerActionBtn('call'));
  dispatch(voiceReducer.setCallToContact(null));
  dispatch(voiceReducer.removeConnection(uuid));

  dispatch(voiceReducer.setIsShowCallStatusLoader(false));
  clearInterval(connection.counterFunction);

  connection.conn.disconnect();
  connection.conn.reject();

  if (connections.length === 1) {
    device?.audio?.incoming(true);
  }

  dispatch(voiceReducer.setDialerSearchedUser(undefined));
};

import { createSlice } from '@reduxjs/toolkit';

import { IAnalyticsResult } from '@/interfaces/analytics';

import { fetchAnalytics } from '../thunk/analytics';

export interface IAnalyticsSlice {
  analytics: IAnalyticsResult | undefined;
}

const initialState: IAnalyticsSlice = {
  analytics: undefined,
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    reset: (state) => {
      state.analytics = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnalytics.fulfilled, (state, action) => {
      state.analytics = action.payload;
    });
  },
});

export const { reset } = analyticsSlice.actions;

export default analyticsSlice.reducer;

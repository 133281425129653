import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';
import RouterIcon from '@mui/icons-material/Router';

import {
  IUseMultiCheckboxSelectState,
  useMultiCheckboxSelect,
} from '@/hooks/use-multi-checkbox-select';

import { IPartialResult } from '@/interfaces/querying-data';
import { IGetIotDevicesQueryParams, IIoTDeviceResult } from '@/interfaces/iot-device';
import { IIotDevicesGroupResult } from '@/interfaces/iot-devices-groups';

import DataTemplate from '@/components/Data-template/Data-template';

import apisV1 from '@/services/api/v1/index';

import { NO_DATA_VALUE } from '@/constants/layout';

export type SelectIotDevicesFromListDialogIotDevice = Pick<
  IIoTDeviceResult,
  'id' | 'description' | 'ipAddress' | 'mac' | 'serialNumber' | 'name'
>;

interface ISelectIotDevicesFromListDialogProps {
  initialIotDevices: SelectIotDevicesFromListDialogIotDevice[];
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (
    response: IUseMultiCheckboxSelectState<SelectIotDevicesFromListDialogIotDevice> | null,
  ) => void;
}

const SelectIotDevicesFromListDialog: FunctionComponent<ISelectIotDevicesFromListDialogProps> = ({
  initialIotDevices,
  isOpened,
  setIsOpened,
  handleSubmit,
}): JSX.Element => {
  const [iotDevicesQueryParams, setIotDevicesQueryParams] = useState<IGetIotDevicesQueryParams>({
    search: '',
    skip: 0,
    limit: 50,
  });

  const [iotDevicesResult, setIotDevicesResult] = useState<IPartialResult<IIoTDeviceResult>>({
    maxCount: 0,
    records: [],
  });

  const [iotDevicesSelection, selectedIotDevicesActions] =
    useMultiCheckboxSelect<SelectIotDevicesFromListDialogIotDevice>({
      selectAllBehavior: 'select-all-with-exclude',
      initialOptions: initialIotDevices,
      getItemId: (el) => el.id as string,
      maxCount: iotDevicesResult.maxCount,
    });

  const [isLoading, setIsLoading] = useState(false);

  const loadIotDevices = async () => {
    setIsLoading(true);

    const { success, data } = await apisV1.iotDevicesApis.getByParams(iotDevicesQueryParams);

    if (success) {
      setIotDevicesResult(data);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoading) {
      loadIotDevices();
    }
  }, [iotDevicesQueryParams.search, iotDevicesQueryParams.skip, iotDevicesQueryParams.limit]);

  const handleClose = (
    response: IUseMultiCheckboxSelectState<SelectIotDevicesFromListDialogIotDevice> | null,
  ) => {
    setIsOpened(false);
    handleSubmit(response);
  };

  return (
    <Dialog keepMounted fullWidth maxWidth="sm" open={isOpened} onClose={() => handleClose(null)}>
      <DialogContent sx={{ pb: 0 }}>
        <Stack spacing={2}>
          <>
            <TextField
              fullWidth
              type="search"
              placeholder="Search"
              variant="outlined"
              size="small"
              value={iotDevicesQueryParams.search}
              onChange={(e) =>
                setIotDevicesQueryParams((prevState) => ({ ...prevState, search: e.target.value }))
              }
              onBlur={(e) =>
                setIotDevicesQueryParams((prevState) => ({ ...prevState, search: e.target.value }))
              }
            />

            <DataTemplate
              isLoading={isLoading}
              pagination={{
                maxCount: iotDevicesResult.maxCount,
                paginationData: {
                  skip: iotDevicesQueryParams.skip,
                  limit: iotDevicesQueryParams.limit,
                },
                onPageChange: (paginationData) =>
                  setIotDevicesQueryParams((prevState) => ({
                    ...prevState,
                    ...paginationData,
                  })),
                onRowsPerPageChange: (paginationData) =>
                  setIotDevicesQueryParams((prevState) => ({
                    ...prevState,
                    ...paginationData,
                  })),
              }}
            >
              <List
                dense
                disablePadding
                sx={{
                  position: 'relative',
                  height: '50vh',
                  overflow: 'auto',
                }}
              >
                <ListItem
                  disablePadding
                  disableGutters
                  dense
                  sx={{
                    position: 'sticky',
                    top: 0,
                    bgcolor: 'common.white',
                    zIndex: 2,
                    borderColor: 'secondary.light',
                  }}
                  secondaryAction={
                    <Checkbox
                      tabIndex={-1}
                      disableRipple
                      indeterminate={selectedIotDevicesActions.isAllOptionHasIndeterminateState(
                        iotDevicesResult.records,
                      )}
                      checked={selectedIotDevicesActions.isAllOptionSelected(
                        iotDevicesResult.records,
                      )}
                      inputProps={{ 'aria-labelledby': 'all-iot-devices-selected-btn' }}
                      onChange={(event, checked) =>
                        selectedIotDevicesActions.handleSelectAll(checked)
                      }
                    />
                  }
                >
                  <ListItemButton
                    disableRipple
                    role="checkbox"
                    onClick={() => {
                      const currentlyChecked = selectedIotDevicesActions.isAllOptionSelected();
                      selectedIotDevicesActions.handleSelectAll(!currentlyChecked);
                    }}
                  >
                    <ListItemText id="all-iot-devices-selected-btn" primary="Select all" />
                  </ListItemButton>
                </ListItem>

                {iotDevicesResult.records.map((el) => {
                  const labelId = `iot-devices-checkbox-list-label-${el.id}`;

                  return (
                    <ListItem
                      key={el.id}
                      disablePadding
                      disableGutters
                      dense
                      secondaryAction={
                        <Checkbox
                          edge="start"
                          checked={selectedIotDevicesActions.isSelected(el)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          onChange={() => selectedIotDevicesActions.handleSelect(el)}
                        />
                      }
                    >
                      <ListItemButton
                        role="checkbox"
                        onClick={() => selectedIotDevicesActions.handleSelect(el)}
                      >
                        <ListItemAvatar>
                          <Box
                            height={30}
                            width={30}
                            borderColor={'primary'}
                            border={'2px solid'}
                            borderRadius={50}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            <RouterIcon color="primary" fontSize="small" />
                          </Box>
                        </ListItemAvatar>

                        <ListItemText
                          id={labelId}
                          primary={el.name}
                          secondary={'Description: ' + (el.description ?? NO_DATA_VALUE)}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </DataTemplate>
          </>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          color="error"
          size="medium"
          disabled={isLoading}
          onClick={() => setIsOpened(false)}
        >
          Cancel
        </Button>

        <Button
          disableElevation
          color="primary"
          variant="contained"
          size="medium"
          disabled={isLoading}
          onClick={() => {
            handleClose(iotDevicesSelection);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectIotDevicesFromListDialog;

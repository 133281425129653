import React, { Dispatch, SetStateAction } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogTitle, Button, Box, Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const paymentFields = [
  { name: 'Subscription Cost:', value: 'amount' },
  { name: 'Prorated Amount:', value: 'safetyDepositAmount' },
  { name: 'Top Up:', value: 'topUp' },
  { name: 'Subtotal:', value: 'subTotal' },
  { name: null, value: 'taxes' },
  { name: 'Total:', value: 'total' },
  { name: 'Description:', value: 'description' },
];

interface IDisclaimer {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  handleAccept: VoidFunction;
  amount: number | null | undefined;
  safetyDepositAmount: number | null | undefined;
  topUp: number | null | undefined;
  subTotal: number | null | undefined;
  total: number | null | undefined;
  taxes:
    | {
        amount: number;
        percentage: string;
        taxType: string;
      }[]
    | undefined;
  description: string | null | undefined;
}

const CalculationResult = (props: IDisclaimer) => {
  const { isVisible, setIsVisible, handleAccept, taxes } = props;

  const handleClose = (): void => {
    setIsVisible(false);
  };

  const handleOk = (): void => {
    setIsVisible(false);
    handleAccept();
  };

  return (
    <Dialog open={isVisible} TransitionComponent={Transition} keepMounted maxWidth={false}>
      <DialogTitle display={'flex'} justifyContent={'center'}>
        {'Payment info'}
      </DialogTitle>
      <DialogContent>
        <Box
          minWidth={{ xs: 250, lg: 400 }}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Box display={'flex'} width={'100%'} alignItems={'center'} flexDirection={'column'}>
            {paymentFields.map((element) => {
              if (element.value === 'description') {
                return props.description ? (
                  <Box
                    key={element.name}
                    display={'flex'}
                    width={'100%'}
                    alignItems={'start'}
                    mt={1}
                  >
                    <Typography fontSize={12}>{element.name} </Typography>
                    <Typography fontSize={12} ml={1}>
                      {props.description}
                    </Typography>
                  </Box>
                ) : null;
              }

              if (element.value === 'taxes') {
                return taxes?.map((element, index) => {
                  return element.amount ? (
                    <Box
                      key={index}
                      display={'flex'}
                      width={'80%'}
                      justifyContent={'space-between'}
                    >
                      <Typography>
                        {element.taxType.toUpperCase()} ({element.percentage}%):
                      </Typography>
                      <Typography>${element.amount}</Typography>
                    </Box>
                  ) : null;
                });
              }

              return props[element.value as never] ? (
                <Box
                  key={element.name}
                  display={'flex'}
                  width={'80%'}
                  justifyContent={'space-between'}
                >
                  <Typography>{element.name}</Typography>
                  <Typography>${props[element.value as never]}</Typography>
                </Box>
              ) : null;
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleOk}>Proceed</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CalculationResult;

import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useAppDispatch } from '@/store/hooks';

import {
  Typography,
  Paper,
  Box,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Switch,
  FormControlLabel,
  Checkbox,
  ListItemText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import {
  IIoTCreateDeviceRequest,
  IIoTDeviceResult,
  IIoTUpdateDeviceRequest,
} from '@/interfaces/iot-device';

import iotDevicesValidationSchemas from '@/helpers/validation-schemas/iot-devices/index';
import generateUuid from '@/utils/uuid';

import useAccess from '@/hooks/use-access';

import * as iotDevicesThunk from '@/pages/dashboard/iot-devices/thunk';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import { defStyles } from '@/theme';

import ROUTES from '@/constants/routes';
import IOT_DEVICE from '@/constants/iot-device';
import { ROLES } from '@/constants/roles';

type CommandWithExtractKeysAsString = (Omit<IIoTDeviceResult['commands'][0], 'extractKeys'> & {
  extractKeys: string;
})[];
type WsMessagesWithExtractKeysAsString = (Omit<IIoTDeviceResult['wsMessages'][0], 'extractKeys'> & {
  extractKeys: string;
})[];

const AddEditContact: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isUserRoleMatches } = useAccess();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [commands, setCommands] = useState<CommandWithExtractKeysAsString>([]);
  const [wsMessages, setWsMessages] = useState<WsMessagesWithExtractKeysAsString>([]);

  const iotDeviceToEdit = location.state?.data;

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: iotDeviceToEdit
      ? {
          name: iotDeviceToEdit.name,
          identifier: iotDeviceToEdit.identifier,
          description: iotDeviceToEdit.description,
          mac: iotDeviceToEdit.mac,
          serialNumber: iotDeviceToEdit.serialNumber,
          ipAddress: iotDeviceToEdit.ipAddress,
          connectionType: iotDeviceToEdit.connectionType,
          connectionDirection: iotDeviceToEdit.connectionDirection,
          commands: iotDeviceToEdit.commands ?? [],
          wsMessages: iotDeviceToEdit.wsMessages ?? [],
          wsUrl: iotDeviceToEdit.wsUrl,
          ignoreUnspecifiedWsMessages: iotDeviceToEdit.ignoreUnspecifiedWsMessages,
        }
      : {
          name: '',
          identifier: '',
          description: '',
          mac: '',
          serialNumber: '',
          ipAddress: '',
          wsUrl: '',
          connectionType: IOT_DEVICE.CONNECTION_TYPE.WS,
          connectionDirection: IOT_DEVICE.CONNECTION_DIRECTION.IN,
          commands: [],
          wsMessages: [],
          ignoreUnspecifiedWsMessages: false,
        },
    validationSchema: !!iotDeviceToEdit
      ? iotDevicesValidationSchemas.update
      : iotDevicesValidationSchemas.create,
    onSubmit: () => {},
  });

  const addEdit = async (
    data:
      | (Omit<IIoTUpdateDeviceRequest, 'commands'> & { commands: CommandWithExtractKeysAsString })
      | (Omit<IIoTCreateDeviceRequest, 'wsMessages'> & {
          messages: WsMessagesWithExtractKeysAsString;
        }),
  ) => {
    setIsLoading(true);

    const payload = {
      ...structuredClone(data),
      commands: commands?.map((element) => {
        return {
          ...element,
          extractKeys: element.extractKeys?.length ? element.extractKeys?.split(',') : [],
        };
      }),
      wsMessages: wsMessages?.map((element) => {
        return {
          ...element,
          extractKeys: element.extractKeys?.length ? element.extractKeys?.split(',') : [],
        };
      }),
    };

    const { meta } = iotDeviceToEdit
      ? await dispatch(
          iotDevicesThunk.fetchUpdateIotDevices({
            id: iotDeviceToEdit.id,
            updates: payload as IIoTUpdateDeviceRequest,
          }),
        )
      : await dispatch(iotDevicesThunk.fetchCreateIotDevices(payload));

    if (meta.requestStatus === 'fulfilled') {
      dispatch(
        snackbarReducer.showSuccess(iotDeviceToEdit ? 'IoT device updated' : 'IoT device created'),
      );

      navigate(ROUTES.DASHBOARD_IOT_DEVICES_PAGE_LAYOUT);
    }

    setIsLoading(false);
  };

  const updateAutoPopulateByIndex = (
    commandIndex: number,
    autoPopulateIndex: number,
    key: keyof IIoTDeviceResult['commands'][0]['autoPopulate'][0],
    value: any,
  ): void => {
    setCommands((current) =>
      current.map((el, i) => {
        if (commandIndex === i) {
          el.autoPopulate.map((autoPopulate, index) => {
            if (index === autoPopulateIndex) {
              //@ts-expect-error need to check
              autoPopulate[key] = value;
            }
            return autoPopulate;
          });
        }
        return el;
      }),
    );
  };

  const updateCommandByIndex = (
    index: number,
    key: keyof IIoTDeviceResult['commands'][0],
    value: any,
  ): void => {
    setCommands((current) =>
      current.map((el, i) => {
        if (index === i) {
          el[key] = value;
        }
        return el;
      }),
    );
  };

  const handleUpdateWsMessageByIndex = (
    index: number,
    key: keyof IIoTDeviceResult['wsMessages'][0],
    value: any,
  ): void => {
    setWsMessages((current) =>
      current.map((el, i) => {
        if (index === i) {
          el[key] = value;
        }
        return el;
      }),
    );
  };

  const handleAddAutoPopulate = (commandIndex: number): void => {
    setCommands((current) =>
      current.map((element, index) => {
        if (index === commandIndex) {
          element.autoPopulate.push({
            from: 'body',
            active: true,
            asKey: '',
            key: '',
            to: 'headers',
            toCommands: [],
          });
        }
        return element;
      }),
    );
  };

  const handleCloneCommand = (index: number): void => {
    setCommands((current) => [
      ...current,
      structuredClone({ ...commands[index], uuid: generateUuid() }),
    ]);
  };

  const handleRemoveCommand = (index: number): void => {
    setCommands((current) => current.filter((_, i) => i !== index));
  };

  const handleRemoveAutoPopulate = (commandIndex: number, autoPopulateIndex: number): void => {
    setCommands((current) =>
      current.map((el, i) => {
        if (i === commandIndex) {
          el.autoPopulate = el.autoPopulate.filter((_, i) => i !== autoPopulateIndex);
        }
        return el;
      }),
    );
  };

  const handleCloneWsMessage = (index: number): void => {
    setWsMessages((current) => [...current, structuredClone(wsMessages[index])]);
  };

  const handleRemoveWsMessage = (index: number): void => {
    setWsMessages((current) => current.filter((_, i) => i !== index));
  };

  const handleAddCommand = (): void => {
    setCommands((current) => [
      ...current,
      {
        command: '',
        description: '',
        url: '',
        method: 'GET',
        headers: '',
        body: '',
        extractKeys: '',
        autoPopulate: [],
        uuid: generateUuid(),
      },
    ]);
  };

  const handleAddWsMessage = (): void => {
    setWsMessages((current) => [
      ...current,
      {
        event: '',
        extractKeys: '',
      },
    ]);
  };

  const handleSelectToCommand = (
    toCommands: string[],
    commandIndex: number,
    autoPopulateIndex: number,
  ): void => {
    setCommands((current) =>
      current.map((element, index) => {
        if (index === commandIndex) {
          element.autoPopulate = element.autoPopulate.map((el, i) => {
            if (i === autoPopulateIndex) {
              if (toCommands.includes('all') && el.toCommands?.length === current.length) {
                el.toCommands = [];
              } else {
                el.toCommands = toCommands.includes('all')
                  ? current.map((el) => el.uuid)
                  : toCommands;
              }
            }
            return el;
          });
        }
        return element;
      }),
    );
  };

  useEffect(() => {
    if (iotDeviceToEdit) {
      setCommands(
        iotDeviceToEdit.commands.map((element: IIoTDeviceResult['commands'][0]) => {
          return { ...element, extractKeys: element.extractKeys?.join(',') ?? '' };
        }),
      );
      setWsMessages(
        iotDeviceToEdit.wsMessages.map((element: IIoTDeviceResult['wsMessages'][0]) => {
          return { ...element, extractKeys: element.extractKeys?.join(',') ?? '' };
        }),
      );
    }
  }, [iotDeviceToEdit]);

  return (
    <Box flex={1} display="flex" p={2} justifyContent="center">
      <Box
        component={Paper}
        display="flex"
        flexDirection="column"
        flex={1}
        gap={1}
        p={2}
        maxWidth="sm"
        overflow="auto"
        sx={defStyles.scroll}
      >
        <Typography variant="h5" color="primary.main" textAlign="center" fontWeight="bold">
          {iotDeviceToEdit ? 'Edit' : 'Add'} Device
        </Typography>

        <TextField
          required
          size="small"
          variant="standard"
          label="Name"
          id="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name ? (formik.errors.name as string) : ''}
        />

        <TextField
          size="small"
          variant="standard"
          label="Identifier"
          id="identifier"
          name="identifier"
          value={formik.values.identifier}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.identifier && Boolean(formik.errors.identifier)}
          helperText={formik.touched.identifier ? (formik.errors.identifier as string) : ''}
        />

        <TextField
          id="description"
          name="description"
          size="small"
          label="Description"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description ? (formik.errors.description as string) : ''}
        />

        <TextField
          id="mac"
          name="mac"
          size="small"
          label="MAC"
          value={formik.values.mac}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.mac && Boolean(formik.errors.mac)}
          helperText={formik.touched.mac ? (formik.errors.mac as string) : ''}
        />

        <TextField
          id="serialNumber"
          name="serialNumber"
          label="Serial Number"
          variant="standard"
          size="small"
          value={formik.values.serialNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
          helperText={formik.touched.serialNumber ? (formik.errors.serialNumber as string) : ''}
        />

        <TextField
          id="ipAddress"
          name="ipAddress"
          label="IP Address"
          variant="standard"
          size="small"
          value={formik.values.ipAddress}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.ipAddress && Boolean(formik.errors.ipAddress)}
          helperText={formik.touched.ipAddress ? (formik.errors.ipAddress as string) : ''}
        />

        <FormControl fullWidth>
          <InputLabel>Connection Direction</InputLabel>
          <Select
            id="connectionDirection"
            value={formik.values.connectionDirection}
            label="Connection Direction"
            onChange={(e) => {
              formik.setFieldValue('connectionDirection', e.target.value);
              if (e.target.value === IOT_DEVICE.CONNECTION_DIRECTION.IN) {
                formik.setFieldValue('connectionType', IOT_DEVICE.CONNECTION_TYPE.WS);
              }
            }}
          >
            <MenuItem value={IOT_DEVICE.CONNECTION_DIRECTION.IN}>IN</MenuItem>
            <MenuItem value={IOT_DEVICE.CONNECTION_DIRECTION.OUT}>OUT</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          fullWidth
          disabled={formik.values.connectionDirection === IOT_DEVICE.CONNECTION_DIRECTION.IN}
        >
          <InputLabel>Connection Type</InputLabel>
          <Select
            id="connectionType"
            value={formik.values.connectionType}
            label="Connection Type"
            onChange={(e) => {
              formik.setFieldValue('connectionType', e.target.value);
            }}
          >
            <MenuItem value={IOT_DEVICE.CONNECTION_TYPE.REST_API}>REST API</MenuItem>
            <MenuItem value={IOT_DEVICE.CONNECTION_TYPE.WS}>Websocket</MenuItem>
          </Select>
        </FormControl>

        {formik.values.connectionType === IOT_DEVICE.CONNECTION_TYPE.WS && (
          <>
            <TextField
              disabled={formik.values.connectionDirection === IOT_DEVICE.CONNECTION_DIRECTION.IN}
              id="wsUrl"
              name="wsUrl"
              label="WS URL"
              variant="standard"
              size="small"
              value={formik.values.wsUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.wsUrl && Boolean(formik.errors.wsUrl)}
              helperText={formik.touched.wsUrl ? (formik.errors.wsUrl as string) : ''}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.ignoreUnspecifiedWsMessages}
                  onChange={(e, checked) => {
                    formik.setFieldValue('ignoreUnspecifiedWsMessages', checked);
                  }}
                />
              }
              label="Ignore Unspecified WS Incoming Messages"
            />
          </>
        )}

        <>
          <Box mt={1}>
            <Typography textAlign={'center'}>Commands</Typography>

            <Box>
              {commands.map((command, index) => {
                return (
                  <Box key={index} component={Paper} p={1.5} my={1}>
                    <TextField
                      fullWidth
                      id="command"
                      name="command"
                      label={`Command ${index + 1}`}
                      variant="standard"
                      size="small"
                      value={command.command}
                      onChange={(e) => {
                        updateCommandByIndex(index, 'command', e.target.value);
                      }}
                      error={!Boolean(command.command)}
                      helperText={!command.command && 'Command is required'}
                    />
                    <TextField
                      fullWidth
                      id="description"
                      name="description"
                      label={`Description`}
                      variant="standard"
                      size="small"
                      value={command.description}
                      onChange={(e) => {
                        updateCommandByIndex(index, 'description', e.target.value);
                      }}
                    />

                    {isUserRoleMatches([ROLES.SUPER_ADMIN]) && (
                      <>
                        {formik.values.connectionType === IOT_DEVICE.CONNECTION_TYPE.REST_API && (
                          <>
                            <TextField
                              fullWidth
                              id="url"
                              name="url"
                              label="URL"
                              variant="standard"
                              size="small"
                              value={command.url}
                              onChange={(e) => {
                                updateCommandByIndex(index, 'url', e.target.value);
                              }}
                            />

                            <FormControl fullWidth>
                              <InputLabel>Method</InputLabel>
                              <Select
                                id="connectionType"
                                value={command.method}
                                label="Connection Type"
                                onChange={(e) => {
                                  updateCommandByIndex(index, 'method', e.target.value);
                                }}
                              >
                                <MenuItem value={'GET'}>GET</MenuItem>
                                <MenuItem value={'POST'}>POST</MenuItem>
                                <MenuItem value={'PATCH'}>PATCH</MenuItem>
                                <MenuItem value={'PUT'}>PUT</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              fullWidth
                              id="header"
                              name="header"
                              label="JSON Headers"
                              variant="outlined"
                              multiline
                              minRows={4}
                              maxRows={4}
                              size="small"
                              value={command.headers}
                              onChange={(e) => {
                                updateCommandByIndex(index, 'headers', e.target.value);
                              }}
                              onBlur={formik.handleBlur}
                              sx={{ mt: 1.5 }}
                            />
                          </>
                        )}

                        <TextField
                          fullWidth
                          id="body"
                          name="body"
                          label={`JSON Body`}
                          variant="outlined"
                          multiline
                          minRows={4}
                          maxRows={4}
                          size="small"
                          value={command.body}
                          onChange={(e) => {
                            updateCommandByIndex(index, 'body', e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          sx={{ mt: 1.5 }}
                        />
                        {formik.values.connectionType === IOT_DEVICE.CONNECTION_TYPE.REST_API && (
                          <TextField
                            fullWidth
                            id="extractKeys"
                            name="extractKeys"
                            label="Response Body Extract Keys(comma separated)"
                            variant="standard"
                            size="small"
                            value={command.extractKeys ?? ''}
                            onChange={(e) => {
                              updateCommandByIndex(index, 'extractKeys', e.target.value);
                            }}
                            onBlur={formik.handleBlur}
                          />
                        )}
                        {formik.values.connectionType === IOT_DEVICE.CONNECTION_TYPE.REST_API && (
                          <Box mt={1} component={Paper} p={2}>
                            <Typography textAlign={'center'}>Auto Populate Settings</Typography>
                            {command.autoPopulate.map((autoPopulate, autoPopulateIndex) => {
                              return (
                                <Box my={1} key={autoPopulateIndex}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={autoPopulate.active}
                                        onChange={(e, checked) => {
                                          updateAutoPopulateByIndex(
                                            index,
                                            autoPopulateIndex,
                                            'active',
                                            checked,
                                          );
                                        }}
                                      />
                                    }
                                    label="Active"
                                  />
                                  <FormControl fullWidth disabled={!autoPopulate.active}>
                                    <InputLabel>Take From</InputLabel>
                                    <Select
                                      id="from"
                                      value={autoPopulate.from}
                                      label="Take From"
                                      onChange={(e) => {
                                        updateAutoPopulateByIndex(
                                          index,
                                          autoPopulateIndex,
                                          'from',
                                          e.target.value,
                                        );
                                      }}
                                    >
                                      <MenuItem value={'headers'}>Headers</MenuItem>
                                      <MenuItem value={'body'}>Body</MenuItem>
                                    </Select>
                                  </FormControl>
                                  <TextField
                                    disabled={!autoPopulate.active}
                                    fullWidth
                                    id="key"
                                    name="key"
                                    label={`Key to extract`}
                                    variant="standard"
                                    size="small"
                                    value={autoPopulate.key}
                                    onChange={(e) => {
                                      updateAutoPopulateByIndex(
                                        index,
                                        autoPopulateIndex,
                                        'key',
                                        e.target.value,
                                      );
                                    }}
                                  />
                                  <FormControl fullWidth disabled={!autoPopulate.active}>
                                    <InputLabel>Place To</InputLabel>
                                    <Select
                                      id="to"
                                      value={autoPopulate.to}
                                      label="Place to"
                                      onChange={(e) => {
                                        updateAutoPopulateByIndex(
                                          index,
                                          autoPopulateIndex,
                                          'to',
                                          e.target.value,
                                        );
                                      }}
                                    >
                                      <MenuItem value={'headers'}>Headers</MenuItem>
                                      <MenuItem value={'body'}>Body</MenuItem>
                                      <MenuItem value={'params'}>Params</MenuItem>
                                    </Select>
                                  </FormControl>
                                  <TextField
                                    disabled={!autoPopulate.active}
                                    fullWidth
                                    id="asKey"
                                    name="asKey"
                                    label={`Place as key`}
                                    variant="standard"
                                    size="small"
                                    value={autoPopulate.asKey}
                                    onChange={(e) => {
                                      updateAutoPopulateByIndex(
                                        index,
                                        autoPopulateIndex,
                                        'asKey',
                                        e.target.value,
                                      );
                                    }}
                                  />

                                  <FormControl
                                    fullWidth
                                    sx={{ mt: 1 }}
                                    disabled={
                                      !autoPopulate.active ||
                                      commands.some((element) => !element.command)
                                    }
                                    variant="standard"
                                  >
                                    <InputLabel>Apply to Commands</InputLabel>
                                    <Select
                                      fullWidth
                                      labelId="toCommands"
                                      id="toCommands"
                                      multiple
                                      value={autoPopulate.toCommands ?? []}
                                      onChange={(e) => {
                                        console.log(e.target.value);
                                        handleSelectToCommand(
                                          e.target.value as string[],
                                          index,
                                          autoPopulateIndex,
                                        );
                                      }}
                                      renderValue={() =>
                                        `Selected ${
                                          autoPopulate.toCommands?.length ?? 0
                                        } command(s)`
                                      }
                                    >
                                      <MenuItem value={'all'}>
                                        <ListItemText primary={'Select all'} />
                                      </MenuItem>
                                      {commands.map((element, _i) => (
                                        <MenuItem key={_i} value={element.uuid}>
                                          <Checkbox
                                            checked={
                                              !!autoPopulate.toCommands?.find(
                                                (el) => el === element.uuid,
                                              )
                                            }
                                          />
                                          <ListItemText primary={element.command} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <Box display={'flex'} justifyContent={'end'}>
                                    <IconButton
                                      onClick={() => {
                                        handleRemoveAutoPopulate(index, autoPopulateIndex);
                                      }}
                                    >
                                      <DeleteForeverIcon color="error" />
                                    </IconButton>
                                  </Box>
                                </Box>
                              );
                            })}
                            <Box display={'flex'} justifyContent={'center'}>
                              <IconButton
                                onClick={() => {
                                  handleAddAutoPopulate(index);
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        )}
                      </>
                    )}
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <IconButton
                        onClick={() => {
                          handleCloneCommand(index);
                        }}
                      >
                        <FileCopyIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          handleRemoveCommand(index);
                        }}
                      >
                        <DeleteForeverIcon color="error" />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
            </Box>

            <Box display={'flex'} justifyContent={'center'}>
              <IconButton onClick={handleAddCommand}>
                <AddIcon />
              </IconButton>
            </Box>
          </Box>

          {formik.values.connectionType === IOT_DEVICE.CONNECTION_TYPE.WS && (
            <Box mt={1}>
              <Typography textAlign={'center'}>WS incoming messages</Typography>

              {wsMessages.map((element, index) => {
                return (
                  <Box>
                    <Box display={'flex'} flexDirection={'row'} gap={2}>
                      <TextField
                        fullWidth
                        id="event"
                        name="event"
                        label={`Event ${index + 1}`}
                        variant="standard"
                        size="small"
                        value={element.event}
                        onChange={(e) => {
                          handleUpdateWsMessageByIndex(index, 'event', e.target.value);
                        }}
                        error={!Boolean(element.event)}
                        helperText={!element.event && 'Event name is required'}
                      />
                      <TextField
                        fullWidth
                        id="extractKey"
                        name="extractKey"
                        label={`Extract Keys(comma separated)`}
                        variant="standard"
                        size="small"
                        value={element.extractKeys}
                        onChange={(e) => {
                          handleUpdateWsMessageByIndex(index, 'extractKeys', e.target.value);
                        }}
                      />
                    </Box>

                    <Box display={'flex'} justifyContent={'space-between'}>
                      <IconButton
                        onClick={() => {
                          handleCloneWsMessage(index);
                        }}
                      >
                        <FileCopyIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          handleRemoveWsMessage(index);
                        }}
                      >
                        <DeleteForeverIcon color="error" />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}

              <Box display={'flex'} justifyContent={'center'}>
                <IconButton onClick={handleAddWsMessage}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </>

        <Box display="flex" justifyContent="flex-end" mt="auto">
          <Button
            color="primary"
            variant="contained"
            size="large"
            disabled={isLoading || !formik.isValid}
            onClick={() => addEdit(formik.values)}
          >
            {isLoading ? <CircularProgress /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditContact;

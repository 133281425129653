import { useState } from 'react';
import { useAppSelector } from '@/store/hooks';

import {
  Box,
  Button,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import SelectReceiversFromContacts from './Select-receivers-from-contacts';
import SelectReceiversFromContactsGroups from './Select-receivers-from-contacts-groups';
import SelectReceiversFromFile from './Select-receivers-from-file';

enum SELECTED_RECEIVER_TYPE {
  FILE = 'file',
  CONTACTS = 'contacts',
  CONTACTS_GROUPS = 'contacts_groups',
}

const SelectReceiversLayout = () => {
  const {
    selectedContacts,
    selectedAllContacts,
    contactsGroupsState,
    contactsFile,
    phoneNumbersCount,
  } = useAppSelector((state) => state.broadcastCampaign);

  const [isContactsFileValidating, setIsContactsFileValidating] = useState<boolean>(false);

  const isSelectContactsDisabled =
    !!contactsFile ||
    !!contactsGroupsState.selectedContactsGroups.length ||
    contactsGroupsState.selectedAllContactsGroups ||
    isContactsFileValidating;

  const isSelectContactsGroupsDisabled =
    !!contactsFile || !!selectedContacts.length || selectedAllContacts || isContactsFileValidating;

  const isSelectFileWithPhoneNumbersDisabled =
    !!contactsGroupsState.selectedContactsGroups.length ||
    contactsGroupsState.selectedAllContactsGroups ||
    !!selectedContacts.length ||
    selectedAllContacts;

  const getSelectType = () => {
    if (!isSelectFileWithPhoneNumbersDisabled) {
      return SELECTED_RECEIVER_TYPE.FILE;
    }

    if (!isSelectContactsDisabled) {
      return SELECTED_RECEIVER_TYPE.CONTACTS;
    }

    if (!isSelectContactsGroupsDisabled) {
      return SELECTED_RECEIVER_TYPE.CONTACTS_GROUPS;
    }
  };

  const [selectType, setSelectType] = useState<SELECTED_RECEIVER_TYPE | undefined>(getSelectType());

  return (
    <Box display="flex" flexDirection="column" gap={2} pt={4}>
      <Box>
        <ToggleButtonGroup
          exclusive
          fullWidth
          sx={{ maxWidth: '30rem' }}
          size="extra-small"
          value={selectType}
          onChange={(event, value) => {
            if (value) {
              setSelectType(value);
            }
          }}
        >
          <ToggleButton value={SELECTED_RECEIVER_TYPE.FILE}>Upload file</ToggleButton>

          <ToggleButton value={SELECTED_RECEIVER_TYPE.CONTACTS}>Select contacts</ToggleButton>

          <ToggleButton value={SELECTED_RECEIVER_TYPE.CONTACTS_GROUPS}>
            Select contacts groups
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {selectType === SELECTED_RECEIVER_TYPE.FILE ? (
        <SelectReceiversFromFile
          selectedContacts={selectedContacts}
          contactsFile={contactsFile}
          phoneNumbersCount={phoneNumbersCount}
          isContactsFileValidating={isContactsFileValidating}
          setIsContactsFileValidating={setIsContactsFileValidating}
        />
      ) : null}

      {selectType === SELECTED_RECEIVER_TYPE.CONTACTS ? (
        <SelectReceiversFromContacts isContactsFileValidating={isContactsFileValidating} />
      ) : null}

      {selectType === SELECTED_RECEIVER_TYPE.CONTACTS_GROUPS ? (
        <SelectReceiversFromContactsGroups isContactsFileValidating={isContactsFileValidating} />
      ) : null}
    </Box>
  );
};

export default SelectReceiversLayout;

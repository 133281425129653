import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { ISubscriptionResult } from '@/interfaces/subscriptions';
import { ITenantSubsPreOrderResult } from '@/interfaces/tenant';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  Box,
  Button,
  Typography,
  Stepper,
  Paper,
  StepLabel,
  Step,
  StepContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Stack,
} from '@mui/material';

import * as tenantsThunk from '@/store/thunk/tenant';
import * as subsSetupThunk from '@/pages/subscription-setup/thunk';

import PricingDialog from '@/components/Dialogs/Pricing/Pricing';
import StripeCheckoutForm from '@/components/Stripe/Checkout-form';

import * as styles from './styles';

import { SubsTerm, PhoneNumberType } from '@/types/subs';

import PromoCodesInput, {
  IPromoCodeOnChangeData,
} from '@/components/Promo-codes-input/Promo-codes-input';
import PublicLayoutHeader from '@/components/Public-layout/Public-layout-header';
import PublicLayoutFooter from '@/components/Public-layout/Public-layout-footer';

import TENANT from '@/constants/tenant';
import SUBS from '@/constants/subscriptions';
import ROUTES from '@/constants/routes';

const steps = ['Select subscription', 'Credit card info', 'Success'];

const paymentInfo = [
  { name: 'Balance of month:', value: 'amount' },
  { name: 'Prorated Amount:', value: 'safetyDepositAmount' },
  { name: 'Top Up:', value: 'topUp' },
  { name: 'Subtotal:', value: 'subTotal' },
  { name: null, value: 'taxes' },
  { name: 'Total:', value: 'total' },
];
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

const checkPricingButton = (
  setShowPricingDialog: Dispatch<SetStateAction<boolean>>,
): JSX.Element => {
  return (
    <Button variant="text" sx={styles.Button2SX} onClick={() => setShowPricingDialog(true)}>
      <Typography sx={styles.Typography5SX}>View pricing</Typography>
    </Button>
  );
};

const SubscriptionSetup = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { tenant } = useAppSelector((state) => state.tenant);

  const [subsTerm, setSubsTerm] = useState<SubsTerm>(SUBS.TERM.ANNUAL);
  const [subscriptions, setSubscriptions] = useState<ISubscriptionResult[]>([]);
  const [showPriceDialog, setShowPricingDialog] = useState<boolean>(false);
  const [selectedSubs, setSelectedSubs] = useState<ISubscriptionResult>();
  const [quantity, setQuantity] = useState<number>(1);
  const [stripeClientSecret, setStripeClientSecret] = useState<string>();
  const [isPaymentProcessed, setIsPaymentProcessed] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [phoneNumberType, setPhoneNumberType] = React.useState<PhoneNumberType>('Toll Free');
  const [prices, setPrices] = React.useState<ITenantSubsPreOrderResult>();
  const [promoCodesInfo, setPromoCodesInfo] = useState<IPromoCodeOnChangeData>({
    all: [],
    valid: [],
    haveErrors: false,
  });

  const getSubscriptions = async (): Promise<void> => {
    const { payload } = await dispatch(subsSetupThunk.fetchSubscriptions());

    if (payload) {
      const [defSub] = payload;
      setSelectedSubs(defSub);
      setSubscriptions(payload);
    }
  };

  const initiateSubsPreOrder = async (): Promise<void> => {
    if (selectedSubs?.id) {
      const { payload } = await dispatch(
        subsSetupThunk.fetchSubsPreOrder({
          quantity,
          phoneNumberType,
          subsTerm,
          subsId: selectedSubs.id,
          promoCodes: promoCodesInfo.valid.map((el) => el.value),
        }),
      );
      if (payload) {
        setStripeClientSecret(payload.secret);
        setPrices(payload);
      } else {
        setTimeout(() => setActiveStep(0), 0);
      }
    }
  };

  const handleNext = async (): Promise<void> => {
    const newActiveStep = activeStep + 1;
    if (activeStep === 0 && newActiveStep === 1) {
      await initiateSubsPreOrder();
    }
    if (activeStep === 1) {
      dispatch(tenantsThunk.fetchTenantInfo());
    }

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSelectSub = (subName: string): void => {
    const sub = subscriptions.find((element) => element.name === subName);
    if (!sub) {
      return;
    }

    setSelectedSubs(sub);
  };

  const handleChangeQuantity = (value: number): void => {
    setQuantity(value > 0 ? value : 1);
  };

  const handlePromoCodesChange = (data: IPromoCodeOnChangeData) => {
    setPromoCodesInfo(data);
  };

  useEffect(() => {
    const isSuccess = searchParams.get('success') === 'true';

    if (isSuccess) {
      setActiveStep(2);
    } else {
      getSubscriptions();
    }
  }, []);

  useEffect(() => {
    if (!selectedSubs) {
      return;
    }
    const pricingTermToSubsTerm = {
      [SUBS.PRICING_TERM.ALL]: SUBS.TERM.ANNUAL,
      [SUBS.PRICING_TERM.ANNUAL]: SUBS.TERM.ANNUAL,
      [SUBS.PRICING_TERM.MONTHLY]: SUBS.TERM.MONTHLY,
    };

    setSubsTerm(pricingTermToSubsTerm[selectedSubs.pricingTerm as SUBS.PRICING_TERM]);
  }, [selectedSubs]);

  useEffect(() => {
    if (tenant?.subs?.isActive && tenant?.status === TENANT.STATUSES.ACTIVE) {
      navigate(ROUTES.AUTH_LAYOUT);
    }
  }, [tenant]);

  return (
    <Box display="flex" justifyContent="space-between" flexDirection="column" minHeight="100vh">
      <PublicLayoutHeader showSignOut showSignIn={false} showSignUp={false} />

      <Box display="flex" flex={1} height={'calc(100vh - 128px)'} overflow={'auto'}>
        <Box m={2} p={2} component={Paper} display="flex" flex={1}>
          <PricingDialog isVisible={showPriceDialog} setIsVisible={setShowPricingDialog} />

          <Box flex={1}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step}>
                  <StepLabel color="inherit">
                    {index === 0 ? (
                      <React.Fragment>
                        {step}
                        {checkPricingButton(setShowPricingDialog)}
                      </React.Fragment>
                    ) : (
                      step
                    )}
                  </StepLabel>

                  <StepContent>
                    {/* Step ONE */}
                    {activeStep === 0 ? (
                      <>
                        <Stack spacing={4} mt={2}>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Box flexGrow={1} maxWidth="12rem">
                              <InputLabel sx={styles.InputLabel1SX}>Subscription:</InputLabel>

                              <FormControl fullWidth>
                                <Select
                                  value={selectedSubs?.name ?? ''}
                                  onChange={(e) => handleSelectSub(e.target.value)}
                                  variant="standard"
                                >
                                  {subscriptions.map((element) => {
                                    return (
                                      <MenuItem key={element.name} value={element.name}>
                                        {element.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>

                            <Box>
                              <InputLabel sx={styles.InputLabel1SX}>Quantity:</InputLabel>

                              <TextField
                                onChange={(e) => handleChangeQuantity(+e.target.value)}
                                variant="standard"
                                value={quantity}
                                type="number"
                                sx={styles.TextField1SX}
                              />
                            </Box>
                          </Stack>

                          <Box display="flex" gap={4} flexWrap="wrap">
                            <Box>
                              <InputLabel sx={styles.InputLabel2SX}>Subscription term:</InputLabel>

                              <FormControl
                                disabled={selectedSubs?.pricingTerm !== SUBS.PRICING_TERM.ALL}
                              >
                                <RadioGroup
                                  defaultValue={SUBS.TERM.ANNUAL}
                                  row
                                  onChange={(e) => setSubsTerm(e.target.value as SubsTerm)}
                                >
                                  <FormControlLabel
                                    checked={subsTerm === SUBS.TERM.MONTHLY}
                                    value={SUBS.TERM.MONTHLY}
                                    control={<Radio />}
                                    label="Month"
                                  />
                                  <FormControlLabel
                                    checked={subsTerm === SUBS.TERM.ANNUAL}
                                    value={SUBS.TERM.ANNUAL}
                                    control={<Radio />}
                                    label="Annual"
                                  />
                                </RadioGroup>
                              </FormControl>

                              <Box sx={styles.Box10SX}>
                                <Typography sx={styles.Typography1SX}>
                                  {subsTerm === SUBS.TERM.ANNUAL
                                    ? 'Min annual commitment'
                                    : 'Monthly commitment'}
                                </Typography>
                              </Box>
                            </Box>

                            <Box>
                              <InputLabel sx={styles.InputLabel2SX}>Phone number:</InputLabel>

                              <FormControl>
                                <RadioGroup
                                  defaultValue={'Toll Free'}
                                  row
                                  onChange={(e) =>
                                    setPhoneNumberType(e.target.value as PhoneNumberType)
                                  }
                                >
                                  <FormControlLabel
                                    checked={phoneNumberType === 'Toll Free'}
                                    value="Toll Free"
                                    control={<Radio />}
                                    label={<Typography>Toll Free</Typography>}
                                  />
                                  <FormControlLabel
                                    checked={phoneNumberType === 'Local Number'}
                                    value="Local Number"
                                    control={<Radio />}
                                    label={<Typography>Local Number</Typography>}
                                  />
                                  <FormControlLabel
                                    checked={phoneNumberType === 'Number Porting'}
                                    value="Number Porting"
                                    control={<Radio />}
                                    label={<Typography>Number Porting</Typography>}
                                  />
                                </RadioGroup>
                              </FormControl>

                              <Box sx={styles.Box10SX}>
                                <Typography sx={styles.Typography1SX}>
                                  Custom/Vanity phone numbers are charged separately. Please
                                  purchase them on products page
                                </Typography>
                              </Box>
                            </Box>

                            {/* <Box>
                              <InputLabel sx={styles.InputLabel2SX}>Currency location:</InputLabel>

                              <FormControl>
                                <RadioGroup
                                  defaultValue={PRICE_TYPES.CANADA}
                                  row
                                  onChange={(e) => setPricingType(e.target.value as PricingType)}
                                >
                                  <FormControlLabel
                                    checked={pricingType === PRICE_TYPES.CANADA}
                                    value={PRICE_TYPES.CANADA}
                                    control={<Radio />}
                                    label="Canada"
                                  />
                                  <FormControlLabel
                                    checked={pricingType === PRICE_TYPES.GLOBAL}
                                    value={PRICE_TYPES.GLOBAL}
                                    control={<Radio />}
                                    label="USA/Global"
                                  />
                                </RadioGroup>
                              </FormControl>

                              <Box sx={styles.Box10SX}>
                                <Typography sx={styles.Typography1SX}>
                                  Select one base on your location
                                </Typography>
                              </Box>
                            </Box> */}
                          </Box>
                          <PromoCodesInput
                            onChange={handlePromoCodesChange}
                            seats={quantity}
                            selectedSubscription={selectedSubs}
                            AutocompleteProps={{ size: 'small', sx: { flexGrow: 1 } }}
                          />
                          <Box>
                            {selectedSubs?.minTopUp ? (
                              <Typography sx={styles.Typography6SX}>
                                - This subscription has Top Up amount of ${selectedSubs.minTopUp},
                                which will be included into first payment.
                              </Typography>
                            ) : null}

                            {selectedSubs?.isProrationApplicable ? (
                              <Typography sx={styles.Typography6SX}>
                                - Pricing for 1st month is pro-rated to include 30 day termination
                                notice.
                              </Typography>
                            ) : null}

                            <Typography sx={styles.Typography6SX}>
                              - One complimentary Toll Free or Local Number is assigned to new
                              sign-ups. Custom or vanity numbers can be purchased from Products
                              page.
                            </Typography>
                            <Typography sx={styles.Typography6SX}>
                              - We will reach out to you for custom number or porting information
                              after order is placed.
                            </Typography>
                            <Typography sx={styles.Typography6SX}>
                              - Annual Customers allowed to cancel service within first 30 days of
                              sign-up and get billed for only that month.
                            </Typography>
                            <Typography sx={styles.Typography6SX}>
                              - Promo codes are valid only if no change made to subscription
                            </Typography>
                          </Box>
                        </Stack>
                      </>
                    ) : null}

                    {/* Step TWO */}
                    {activeStep === 1 ? (
                      <>
                        {prices && stripeClientSecret ? (
                          <>
                            {!isPaymentProcessed && (
                              <Box sx={styles.Box7SX}>
                                <Box
                                  display={'flex'}
                                  flexDirection={'column'}
                                  width={250}
                                  p={2}
                                  mb={2}
                                >
                                  <Typography variant="body1" fontWeight="bold" mb={1}>
                                    Payment info
                                  </Typography>
                                  {paymentInfo.map((element) => {
                                    if (element.value === 'taxes') {
                                      return prices?.taxes?.map((element, index) => {
                                        return element.amount ? (
                                          <Box
                                            key={index}
                                            display={'flex'}
                                            width={'100%'}
                                            justifyContent={'space-between'}
                                          >
                                            <Typography>
                                              {element.taxType.toUpperCase()} ({element.percentage}
                                              %):
                                            </Typography>
                                            <Typography>${element.amount}</Typography>
                                          </Box>
                                        ) : null;
                                      });
                                    } else {
                                      return prices && prices[element.value as never] ? (
                                        <Box
                                          key={element.name}
                                          display={'flex'}
                                          width={'100%'}
                                          justifyContent={'space-between'}
                                        >
                                          <Typography>{element.name}</Typography>
                                          <Typography>${prices[element.value as never]}</Typography>
                                        </Box>
                                      ) : null;
                                    }
                                  })}
                                </Box>
                              </Box>
                            )}

                            <Elements
                              stripe={stripePromise}
                              options={{ clientSecret: stripeClientSecret }}
                            >
                              <StripeCheckoutForm setIsPaymentProcessed={setIsPaymentProcessed} />
                            </Elements>
                          </>
                        ) : null}
                      </>
                    ) : null}

                    {/* Step THREE */}
                    {activeStep === 2 ? (
                      <>
                        <DoneAllIcon color="success" sx={styles.Icon4SX} />
                        <Typography sx={styles.Icon1SX}>
                          Congratulations. Subscription requires business verification. You will
                          soon receive an email with additional details and next steps.
                        </Typography>
                      </>
                    ) : null}

                    {activeStep !== 2 ? (
                      <Box mt={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          sx={styles.Button1SX}
                          disabled={
                            (activeStep === 1 && !isPaymentProcessed) || promoCodesInfo.haveErrors
                          }
                        >
                          {activeStep === 0 ? 'Calculate and continue' : 'Continue'}
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          disabled={index === 0 || isPaymentProcessed}
                          onClick={handleBack}
                          sx={styles.Button1SX}
                        >
                          Back
                        </Button>
                      </Box>
                    ) : null}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
      </Box>

      <Box mb={-3}>
        <PublicLayoutFooter />
      </Box>
    </Box>
  );
};

export default SubscriptionSetup;

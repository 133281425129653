import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Typography } from '@mui/material';

import * as styles from './styles';

import ROUTES from '@/constants/routes';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IAlertDialogSlide {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const AlertDialogSlide = (props: IAlertDialogSlide) => {
  const { isVisible, setIsVisible } = props;

  const navigate = useNavigate();

  const handleClose = () => {
    setIsVisible(false);
    navigate(ROUTES.DASHBOARD_BROADCAST_CAMPAIGN);
  };

  return (
    <Dialog open={isVisible} TransitionComponent={Transition} keepMounted onClose={handleClose}>
      <DialogTitle>{'Creating broadcast campaign...'}</DialogTitle>
      <DialogContent>
        <Box sx={styles.Box1SX}>
          <Typography>
            We will notify you by email when broadcast campaign will be created.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialogSlide;

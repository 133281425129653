import { IContact, IGetContactsQueryParams } from '@/interfaces/contact';
import { IPartialResult } from '@/interfaces/querying-data';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store/hooks';

import { Stack } from '@mui/material';

import { IAddNewChatDialogResponse } from '@/interfaces/chat';

import * as contactsThunk from '@/pages/dashboard/contacts/thunk';
import * as chatsThunk from '@/pages/dashboard/chat/thunk';

import * as menuReducer from '@/store/reducers/dashboard/menu';

import { useMultiCheckboxSelect } from '@/hooks/use-multi-checkbox-select';
import useAccess from '@/hooks/use-access';

import * as callService from '@/services/voice/call';
import * as socketService from '@/services/socketio/socketio';

import DataTemplate from '@/components/Data-template/Data-template';
import ContactsHeader from './Contacts-header';
import ContactsList from './Contacts-list';

import ROUTES from '@/constants/routes';
import DASHBOARD from '@/constants/dashboard';
import { CONTACT_TYPE } from '@/constants/contacts';
import CHAT from '@/constants/chat';
import SOCKETIO from '@/constants/socketio';
import { ADDONS } from '@/constants/addons';

const ContactsLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<IGetContactsQueryParams>({
    search: '',
    contactType: CONTACT_TYPE.COMPANY,
    skip: 0,
    limit: 50,
  });

  const [contactsResult, setContactsResult] = useState<IPartialResult<IContact>>({
    maxCount: 0,
    records: [],
  });

  const [{ selected: selectedContactsIds }, selectedContactsIdsActions] =
    useMultiCheckboxSelect<string>({
      selectAllBehavior: 'select-all-from-displayed',
      getItemId: (el) => el,
      maxCount: contactsResult.maxCount,
    });

  const [isLoading, setIsLoading] = useState(false);

  const { isAddonAvailable } = useAccess();

  const loadContacts = async () => {
    setIsLoading(true);

    const { payload } = await dispatch(contactsThunk.fetchGetContacts(queryParams));

    if (payload) {
      setContactsResult(payload as IPartialResult<IContact>);
    }

    setIsLoading(false);
  };

  const handleCreateContact = () => {
    navigate(ROUTES.DASHBOARD_CONTACTS_ADD_EDIT, {
      state: { defaultType: queryParams.contactType },
    });
  };

  const handleImportContacts = () => {
    navigate(ROUTES.DASHBOARD_CONTACTS_UPLOAD);
  };

  const handleUpdateContact = (contact: IContact) => {
    navigate(ROUTES.DASHBOARD_CONTACTS_ADD_EDIT, {
      state: { data: contact },
    });
  };

  const handleCopyContact = async (contact: IContact) => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append('firstName', contact.firstName);
    formData.append('lastName', contact.lastName || '');
    formData.append('email', contact.email || '');
    formData.append('phoneNumber', contact.phoneNumber);
    formData.append(
      'type',
      contact.type === CONTACT_TYPE.COMPANY ? CONTACT_TYPE.PRIVATE : CONTACT_TYPE.COMPANY,
    );

    const { meta } = await dispatch(contactsThunk.fetchCopy(formData));

    if (meta.requestStatus === 'fulfilled') {
      loadContacts();
    }

    setIsLoading(false);
  };

  const handleDeleteContact = async (contact: IContact) => {
    setIsLoading(true);

    const { meta } = await dispatch(contactsThunk.fetchDeleteById({ id: contact.id as string }));

    if (meta.requestStatus === 'fulfilled') {
      loadContacts();

      selectedContactsIdsActions.setSelected((prevState) =>
        prevState.filter((el) => el !== contact.id),
      );
    }

    setIsLoading(false);
  };

  const handleCallContact = async (contact: IContact) => {
    callService.handleInitCall({ dispatch, to: contact.phoneNumber });
    navigate(ROUTES.DASHBOARD_VOICE_STATUS);
  };

  const handleSendMessageToContact = async (contact: IContact, chatPlatform: CHAT.PLATFORM) => {
    if (chatPlatform === CHAT.PLATFORM.WHATSAPP && !isAddonAvailable(ADDONS.WHATSAPP)) {
      return;
    }

    const { payload } = await dispatch(
      chatsThunk.fetchGetOneByRelativeInfo({
        phoneNumber: contact.phoneNumber,
        type: CHAT.TYPE.TENANT,
        platform: chatPlatform,
      }),
    );

    const response: IAddNewChatDialogResponse = {
      chat: payload,
      chatPlatform,
      contact,
      chatType: CHAT.TYPE.TENANT,
      internalUser: null,
      phoneNumber: null,
    };

    if (response.chat) {
      socketService.socket?.emit(
        SOCKETIO.EVENTS.JOIN_CHAT,
        JSON.stringify({ chatId: response.chat.id }),
      );
    }

    navigate(ROUTES.DASHBOARD_MESSAGES, {
      state: {
        sendMessageInformation: response,
      },
    });
  };

  const handleDeleteManyContacts = async (ids: string[]) => {
    setIsLoading(true);

    const { meta } = await dispatch(contactsThunk.fetchBulkDelete({ ids }));

    if (meta.requestStatus === 'fulfilled') {
      selectedContactsIdsActions.reset();

      if (queryParams.skip) {
        setQueryParams((prevState) => ({ ...prevState, skip: 0 }));
      } else {
        loadContacts();
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.CONTACTS }));
    const intervalId = setInterval(() => loadContacts(), 60_000); // 1 minute
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      loadContacts();
    }
  }, [
    queryParams.search,
    queryParams.contactType,
    queryParams.skip,
    queryParams.limit,
    queryParams.sortField,
    queryParams.sortDirection,
  ]);

  return (
    <Stack spacing={2}>
      <ContactsHeader
        isLoading={isLoading}
        selectedContactsIds={selectedContactsIds}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        handleDeleteManyContacts={handleDeleteManyContacts}
        handleCreateContact={handleCreateContact}
        handleImportContacts={handleImportContacts}
      />

      <DataTemplate
        isLoading={isLoading}
        pagination={{
          maxCount: contactsResult.maxCount,
          paginationData: {
            skip: queryParams.skip,
            limit: queryParams.limit,
          },
          onPageChange: (paginationData) =>
            setQueryParams((prevState) => ({
              ...prevState,
              ...paginationData,
            })),
          onRowsPerPageChange: (paginationData) =>
            setQueryParams((prevState) => ({
              ...prevState,
              ...paginationData,
            })),
        }}
      >
        <ContactsList
          data={contactsResult.records}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          selectedContactsIdsActions={selectedContactsIdsActions}
          handleUpdateContact={handleUpdateContact}
          handleDeleteContact={handleDeleteContact}
          handleCopyContact={handleCopyContact}
          handleCallContact={handleCallContact}
          handleSendMessageToContact={handleSendMessageToContact}
          isWhatsAppEnabled={isAddonAvailable(ADDONS.WHATSAPP)}
        />
      </DataTemplate>
    </Stack>
  );
};

export default ContactsLayout;

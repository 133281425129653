import { store } from '@/store/store';

import { getActiveConnection } from '../voice/call';

export const sendDigits = (data: { uuid?: string; digits: string }) => {
  const { digits } = data;
  let { uuid } = data;

  if (!uuid) {
    const activeNowConnection = getActiveConnection();
    if (activeNowConnection) {
      uuid = activeNowConnection.uuid;
    } else {
      return;
    }
  }
  const state = store.getState();
  const { voice: voiceState } = state;
  const { connections } = voiceState;
  const connection = connections.find((connection) => connection.uuid === uuid);
  if (connection) {
    connection.conn.sendDigits(digits);
  }
};

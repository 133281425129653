import { IGetIotDevicesQueryParams, IIoTDeviceResult } from '@/interfaces/iot-device';
import { IAddNewIotDeviceChatDialogResponse } from '@/interfaces/chat';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store/hooks';

import { Paper, Stack } from '@mui/material';

import * as iotDevicesThunk from '@/pages/dashboard/iot-devices/thunk';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import { useMultiCheckboxSelect } from '@/hooks/use-multi-checkbox-select';

import DataTemplate from '@/components/Data-template/Data-template';

import * as socketService from '@/services/socketio/socketio';

import IotDeviceHeader from './Header';
import IotDevicesList from './List';

import apisV1 from '@/services/api/v1/index';

import ROUTES from '@/constants/routes';
import CHAT from '@/constants/chat';
import SOCKETIO from '@/constants/socketio';

const IotDeviceLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<IGetIotDevicesQueryParams>({
    search: '',
    skip: 0,
    limit: 50,
  });

  const [iotDevicesResult, setIotDevicesResult] = useState<{
    maxCount: number;
    records: IIoTDeviceResult[];
  }>({
    maxCount: 0,
    records: [],
  });

  const [{ selected: selectedIotDevicesIds }, selectedIotDevicesIdsActions] =
    useMultiCheckboxSelect<string>({
      selectAllBehavior: 'select-all-from-displayed',
      getItemId: (el) => el,
      maxCount: iotDevicesResult.maxCount,
    });

  const [isLoading, setIsLoading] = useState(false);

  const loadIotDevices = async (): Promise<void> => {
    setIsLoading(true);

    const { success, data } = await apisV1.iotDevicesApis.getByParams(queryParams);

    if (success) {
      setIotDevicesResult(data);
    }

    setIsLoading(false);
  };

  const handleCreateIotDevice = (): void => {
    navigate(ROUTES.DASHBOARD_IOT_DEVICES_ADD_EDIT, {});
  };

  const handleUpdateIotDevice = (iotDevice: IIoTDeviceResult): void => {
    navigate(ROUTES.DASHBOARD_IOT_DEVICES_ADD_EDIT, {
      state: { data: iotDevice },
    });
  };

  const handleDeleteIotDevice = async (iotDevice: IIoTDeviceResult): Promise<void> => {
    setIsLoading(true);

    const { meta } = await dispatch(iotDevicesThunk.fetchDeleteIotDevices(iotDevice.id));

    if (meta.requestStatus === 'fulfilled') {
      dispatch(snackbarReducer.showSuccess('IoT device was deleted'));
      await loadIotDevices();

      selectedIotDevicesIdsActions.setSelected((prevState) =>
        prevState.filter((el) => el !== iotDevice.id),
      );
    }

    setIsLoading(false);
  };

  const handleBulkDelete = async (ids: string[]): Promise<void> => {
    setIsLoading(true);

    const { meta } = await dispatch(iotDevicesThunk.fetchBulkDeleteIotDevices({ ids }));

    if (meta.requestStatus === 'fulfilled') {
      selectedIotDevicesIdsActions.reset();

      if (queryParams.skip) {
        setQueryParams((prevState) => ({ ...prevState, skip: 0 }));
      } else {
        loadIotDevices();
      }
    }

    setIsLoading(false);
  };

  const handleSendMessage = async (data: IIoTDeviceResult): Promise<void> => {
    const { payload } = await dispatch(iotDevicesThunk.fetchGetChatByIotDeviceId(data.id));
    if (!payload) {
      return;
    }

    const response: IAddNewIotDeviceChatDialogResponse = {
      chat: payload,
      chatPlatform: CHAT.PLATFORM.ANY,
      contact: null,
      chatType: CHAT.TYPE.TENANT,
      internalUser: null,
      phoneNumber: null,
      iotDevice: data,
    };

    socketService.socket?.emit(SOCKETIO.EVENTS.JOIN_CHAT, JSON.stringify({ chatId: payload.id }));

    navigate(ROUTES.DASHBOARD_MESSAGES, {
      state: { sendMessageInformation: { ...response, variant: CHAT.VARIANT.IOT_DEVICE } },
    });
  };

  useEffect(() => {
    if (!isLoading) {
      loadIotDevices();
    }
  }, [
    queryParams.search,
    queryParams.skip,
    queryParams.limit,
    queryParams.sortField,
    queryParams.sortDirection,
  ]);

  return (
    <Stack spacing={2} m={2} component={Paper} flex={1}>
      <IotDeviceHeader
        isLoading={isLoading}
        selectedIotDevicesIds={selectedIotDevicesIds}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        handleBulkDelete={handleBulkDelete}
        handleCreateIotDevice={handleCreateIotDevice}
      />

      <DataTemplate
        isLoading={isLoading}
        pagination={{
          maxCount: iotDevicesResult.maxCount,
          paginationData: {
            skip: queryParams.skip,
            limit: queryParams.limit,
          },
          onPageChange: (paginationData) =>
            setQueryParams((prevState) => ({
              ...prevState,
              ...paginationData,
            })),
          onRowsPerPageChange: (paginationData) =>
            setQueryParams((prevState) => ({
              ...prevState,
              ...paginationData,
            })),
        }}
      >
        <IotDevicesList
          data={iotDevicesResult.records}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          selectedContactsIdsActions={selectedIotDevicesIdsActions}
          handleUpdate={handleUpdateIotDevice}
          handleDelete={handleDeleteIotDevice}
          handleSendMessage={handleSendMessage}
        />
      </DataTemplate>
    </Stack>
  );
};

export default IotDeviceLayout;

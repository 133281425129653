import * as voiceThunk from '@/store/thunk/dashboard/voice';

import * as voiceReducer from '@/store/reducers/dashboard/voice';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import { AppDispatch, store } from '@/store/store';

import CALL from '@/constants/voice/voice';

export const putTwilioCallOnHold = async (data: { dispatch: AppDispatch; uuid?: string }) => {
  const { dispatch } = data;
  let { uuid } = data;

  const state = store.getState();
  const { voice: voiceState } = state;

  const { connections } = voiceState;

  if (!uuid) {
    const selectedConnection = connections.find((connection) => connection.selected);
    if (!selectedConnection) {
      return;
    }
    if (
      selectedConnection.isMultipleParticipants &&
      selectedConnection.participants.some((element) => element.status === CALL.STATUSES.RINGING)
    ) {
      dispatch(
        snackbarReducer.showInfo(
          `We can't put the conference on hold right now. It seems one of the participants still has ringing status. `,
        ),
      );
      return;
    }

    uuid = selectedConnection?.uuid as string;
  }

  dispatch(voiceReducer.setOnHoldCall({ uuid, onHold: true }));
  await dispatch(voiceThunk.fetchPutCallOnHold(uuid));

  setTimeout(() => {
    dispatch(voiceReducer.setIsOnHoldDisabled({ uuid: uuid as string, value: false }));
  }, 4000);
};

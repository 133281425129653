export const Box1SX = {
  borderRadius: 5,
  p: 1,
  py: 2,
  px: 3,
  justifyContent: 'space-between',
  display: 'flex',
};

export const Box4SX = {
  width: '50%',
  justifyContent: 'flex-end',
  display: 'flex',
  alignItems: 'center',
};

export const Box5SX = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

export const Box6SX = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};

export const Box7SX = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};

export const Box8SX = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
};

export const Typography2SX = {
  textDecoration: 'underline',
  fontSize: 11,
};

export const Typography3SX = {
  fontSize: 17,
};

export const Typography4SX = {
  fontWeight: 'bold',
};

export const Typography5SX = {
  fontSize: 9,
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: 'bold',
};

export const InputLabel4SX = {
  fontSize: 13,
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: 'bold',
  right: 0,
};

export const TextFiledSX = {
  '& .MuiFormLabel-root': {
    fontWeight: 'bold',
  },
  width: 100,
};

import yup from '@/helpers/yup';

import { getInvalid, getRequired } from '@/helpers/form-fields-error-messages';

const updateMeCallPreferences = yup.object().shape({
  isVoicemailEnabled: yup.boolean(),
  isAlwaysForwardEnabled: yup.boolean(),
  devicesPreferences: yup
    .array()
    .min(1, getInvalid('devices call preferences'))
    .of(
      yup.object().shape({
        type: yup.string().required(getRequired('device')),
        allowCall: yup.boolean().required(getRequired('device configuration')),
      }),
    ),
  alwaysForwardTo: yup.string().when('isAlwaysForwardEnabled', {
    is: true,
    then: (schema) => schema.required(getRequired('always forward number')),
    otherwise: (schema) => schema.nullable(),
  }),
});

export default updateMeCallPreferences;

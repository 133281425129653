import yup from '@/helpers/yup';
import { getRequired } from '@/helpers/form-fields-error-messages';

import FILE from '@/constants/file';

const EXCEL_MIMETYPES_ARRAY = Object.values(FILE.EXCEL.MIMETYPE);

export default yup.object().shape({
  file: yup
    .mixed()
    .test('file', getRequired('file with contacts'), (value: any) => {
      if (!value) {
        return false;
      }

      return EXCEL_MIMETYPES_ARRAY.includes(value.type);
    })
    .required(getRequired('file with contacts')),
  contactsGroup: yup.object().nullable(),
});

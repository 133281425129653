import makeRequest from '../make-request';

import token from '../token';

import {
  ICallAddParticipantRequest,
  ICallConferenceParticipantResult,
  ICallSetAnsweredBy,
} from '@/interfaces/voice/call';
import { IResponseResult } from '@/interfaces/base';
import { ICallsVoicemailResult } from '@/interfaces/voice/call-voicemail';
import { IPartialResult } from '@/interfaces/querying-data';

const TWILIO_ACCESS_TOKEN = '/api/v1/voice/t/access-token';

const PUT_CALL_ON_HOLD = '/api/v1/voice/call/hold';
const RESUME_CALL = '/api/v1/voice/call/resume';
const CANCEL_CALL = '/api/v1/voice/call/cancel';
const FORWARD_CALL = '/api/v1/voice/call/forward';
const MUTE_CALL = '/api/v1/voice/call/mute';

const GET_CALL_BY_PROVIDER_CALL_ID = '/api/v1/voice/call';
const GET_CALLS_HISTORY = '/api/v1/voice/calls-history';
const SET_ANSWERED_BY = '/api/v1/voice/call/answered-by';
const ADD_PARTICIPANT_TO_CALL = '/api/v1/voice/call/add-participant/:uuid';

const GET_CALLS_RECORDINGS = '/api/v1/voice/calls-recordings';
const GET_CALL_RECORDING_BY_CALL_HISTORY_ID = (callHistoryId: string): string =>
  `/api/v1/voice/calls-recordings/call-history/${callHistoryId}`;
const GET_CALL_TRANSCRIPTION_BY_CALL_HISTORY_ID = (callHistoryId: string): string =>
  `/api/v1/voice/calls-transcriptions/call-history/${callHistoryId}`;
const DELETE_CALL_TRANSCRIPTION_BY_ID = (id: string): string =>
  `/api/v1/voice/calls-transcriptions/${id}`;
const DELETE_CALL_RECORDINGS = '/api/v1/voice/calls-recordings/:id';
const DOWNLOAD_CALL_RECORDING_TRACK = (track: string): string =>
  `/api/v1/voice/calls-recordings/download/track/${track}`;
const TRANSCRIBE_CALL_RECORDING = (callRecordingId: string): string =>
  `/api/v1/voice/call-recording/${callRecordingId}/transcribe`;
const DOWNLOAD_CALL_TRANSCRIPTION = (callTranscriptionId: string): string =>
  `/api/v1/voice/call-transcription/${callTranscriptionId}/download`;
const EMAIL_CALL_TRANSCRIPTION = (callTranscriptionId: string): string =>
  `/api/v1/voice/call-transcription/${callTranscriptionId}/email`;
const GET_CONFERENCE_PARTICIPANTS = (callUuid: string): string =>
  `/api/v1/voice/call/conference/${callUuid}/participants`;
const DROP_CONFERENCE_PARTICIPANT = (callUuid: string, participantUuid: string): string =>
  `/api/v1/voice/call/conference/${callUuid}/drop-participant/${participantUuid}`;
const MAKE_PARTICIPANT_CONFERENCE_OWNER = (callUuid: string, participantUuid: string): string =>
  `/api/v1/voice/call/conference/${callUuid}/make-as-owner/${participantUuid}`;
const DOWNLOAD_VOICEMAIL_TRACK = (callId: string): string =>
  `/api/v1/voice/voicemail/download/${callId}/`;
const GET_VOICEMAILS = '/api/v1/voice/calls-voicemails';

export const getTwilioAccessToken = async () => {
  try {
    const result = await makeRequest(TWILIO_ACCESS_TOKEN, {
      method: 'GET',
      headers: { Authorization: await token() },
      params: { type: 'web' },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getCallByProviderCallId = async (uuid: string) => {
  try {
    const result = await makeRequest(`${GET_CALL_BY_PROVIDER_CALL_ID}/${uuid}`, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const putCallOnHold = async (uuid: string) => {
  try {
    const result = await makeRequest(`${PUT_CALL_ON_HOLD}/${uuid}`, {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const resumeCall = async (uuid: string) => {
  try {
    const result = await makeRequest(`${RESUME_CALL}/${uuid}`, {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const cancelCall = async (uuid: string) => {
  try {
    const result = await makeRequest(`${CANCEL_CALL}/${uuid}`, {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const forwardCall = async (uuid: string, to: string) => {
  try {
    const result = await makeRequest(`${FORWARD_CALL}/${uuid}`, {
      method: 'POST',
      headers: { Authorization: await token() },
      data: { to },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const muteCall = async (uuid: string) => {
  try {
    const result = await makeRequest(`${MUTE_CALL}/${uuid}`, {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getCallsHistory = async (skip: number, limit: number) => {
  try {
    const result = await makeRequest(GET_CALLS_HISTORY, {
      method: 'GET',
      headers: { Authorization: await token() },
      params: { limit: limit.toString(), skip: skip.toString() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const setAnsweredBy = async (uuid: string, data: ICallSetAnsweredBy) => {
  try {
    const result = await makeRequest(`${SET_ANSWERED_BY}/${uuid}`, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getCallsRecordings = async (skip: number, limit: number) => {
  try {
    const result = await makeRequest(GET_CALLS_RECORDINGS, {
      method: 'GET',
      headers: { Authorization: await token() },
      params: { limit: limit.toString(), skip: skip.toString() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCallRecording = async (id: string) => {
  try {
    const result = await makeRequest(DELETE_CALL_RECORDINGS.replace(':id', id), {
      method: 'DELETE',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const downloadCallRecordingTrack = async (track: string) => {
  try {
    const result = await makeRequest(DOWNLOAD_CALL_RECORDING_TRACK(track), {
      method: 'GET',
      headers: { Authorization: await token() },
      responseType: 'blob',
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const downloadVoicemailTrack = async (callId: string) => {
  return makeRequest(DOWNLOAD_VOICEMAIL_TRACK(callId), {
    method: 'GET',
    headers: { Authorization: await token() },
    responseType: 'blob',
  });
};

export const addParticipantToCall = async (uuid: string, data: ICallAddParticipantRequest) => {
  try {
    const result = await makeRequest(ADD_PARTICIPANT_TO_CALL.replace(':uuid', uuid), {
      data,
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getCallRecordingByCallHistoryId = async (callHistoryId: string) => {
  try {
    const result = await makeRequest(GET_CALL_RECORDING_BY_CALL_HISTORY_ID(callHistoryId), {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getCallTranscriptionByCallHistoryId = async (callHistoryId: string) => {
  try {
    const result = await makeRequest(GET_CALL_TRANSCRIPTION_BY_CALL_HISTORY_ID(callHistoryId), {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCallTranscriptionById = async (id: string) => {
  try {
    const result = await makeRequest(DELETE_CALL_TRANSCRIPTION_BY_ID(id), {
      method: 'DELETE',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const transcribeCallRecording = async (callRecordingId: string) => {
  try {
    const result = await makeRequest(TRANSCRIBE_CALL_RECORDING(callRecordingId), {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const downloadCallTranscription = async (transcriptionId: string) => {
  try {
    const result = await makeRequest(DOWNLOAD_CALL_TRANSCRIPTION(transcriptionId), {
      method: 'GET',
      headers: { Authorization: await token() },
      responseType: 'blob',
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const emailCallTranscription = async (transcriptionId: string) => {
  try {
    const result = await makeRequest(EMAIL_CALL_TRANSCRIPTION(transcriptionId), {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getConferenceParticipants = async (
  callUuid: string,
): Promise<IResponseResult<ICallConferenceParticipantResult[]>> => {
  return makeRequest(GET_CONFERENCE_PARTICIPANTS(callUuid), {
    method: 'GET',
    headers: { Authorization: await token() },
  });
};

export const dropConferenceParticipant = async (
  callUuid: string,
  participantUuid: string,
): Promise<IResponseResult<string>> => {
  return makeRequest(DROP_CONFERENCE_PARTICIPANT(callUuid, participantUuid), {
    method: 'POST',
    headers: { Authorization: await token() },
  });
};

export const makeParticipantConferenceOwner = async (
  callUuid: string,
  participantUuid: string,
): Promise<IResponseResult<string>> => {
  return makeRequest(MAKE_PARTICIPANT_CONFERENCE_OWNER(callUuid, participantUuid), {
    method: 'POST',
    headers: { Authorization: await token() },
  });
};

export const getVoicemails = async (
  skip: number,
  limit: number,
): Promise<IResponseResult<IPartialResult<ICallsVoicemailResult>>> => {
  return makeRequest(GET_VOICEMAILS, {
    method: 'GET',
    headers: { Authorization: await token() },
    params: { skip, limit },
  });
};

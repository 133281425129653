import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useRoutes, Navigate } from 'react-router-dom';

import useAuth from '@/hooks/use-auth';

import AuthLayout from '@/pages/auth/Auth-layout';
import SignIn from '@/pages/auth/sign-in/Sign-in';
import SignUp from '@/pages/auth/sign-up/Sign-up';
import Pricing from '@/pages/pricing/Pricing';
import DashboardLayout from '@/pages/dashboard/Dashboard-layout';

import IotDevicesPageLayout from '@/pages/dashboard/iot-devices/Layout';
import IotDevicesLayout from '@/pages/dashboard/iot-devices/iot-devices-list/Layout';
import AddEditIotDevice from '@/pages/dashboard/iot-devices/add-edit/Add-edit';
import IotDeviceGroups from '@/pages/dashboard/iot-devices/groups/Layout';

import ContactsPageLayout from '@/pages/dashboard/contacts/Contacts-page-layout';
import ContactsGroupsLayout from '@/pages/dashboard/contacts/contacts-groups/Contacts-groups-layout';
import ContactsLayout from '@/pages/dashboard/contacts/contacts-list/Contacts-layout';
import AddEditContact from '@/pages/dashboard/contacts/add-edit/Add-edit';
import CreateOrUpdateContactsGroupsPage from '@/pages/dashboard/contacts/create-or-update-contacts-group-page/Create-or-update-contacts-group-page';
import UploadContacts from '@/pages/dashboard/contacts/upload-company-contacts/Upload-company-contacts';
import SettingsLayout from '@/pages/dashboard/settings/Settings-layout';
import WrongInputCallFlow from '@/pages/dashboard/settings/wrong-input-call-flow/Wrong-input-call-flow';
import VoiceSetup from '@/pages/dashboard/settings/voice-setup/Voice-setup';
import DirectExtensionsLayout from '@/pages/dashboard/users/direct-extensions/Direct-extensions-layout';
import UsersRelatedInfoLayout from '@/pages/dashboard/users/Users-related-info-layout';
import AddEditUser from '@/pages/dashboard/users/users/Add-edit-user/Add-edit-user';
import UsersLayout from '@/pages/dashboard/users/users/Users-layout';
import VoiceLayout from '@/pages/dashboard/voice/Voice-layout';
import CallStatusLayout from '@/pages/dashboard/voice/call-status/Call-status-layout';
import CallsHistory from '@/pages/dashboard/voice/calls-history/Calls-history';
import CallsVoicemails from '@/pages/dashboard/voice/calls-voicemails/Calls-voicemails';
import CreditCards from '@/pages/profile/credit-cards/Credit-cards';
import ChatMessagesLayout from '@/pages/dashboard/chat/Chats-messages-layout';
import BroadcastCampaignLayout from '@/pages/dashboard/broadcast/campaign/Broadcast-campaign-layout';
import ExtensionsGroupsLayout from '@/pages/dashboard/extensions-groups/Extensions-groups-layout';
import CreateUpdateExtensionsGroup from '@/pages/dashboard/extensions-groups/add-edit/Add-edit';
import MeProfile from '@/pages/profile/me/Me-profile';
import ChangePassword from '@/pages/profile/change-password/Change-password';
import Subscriptions from '@/pages/profile/subscriptions/Subscriptions';
import ProfileLayout from '@/pages/profile/Profile-main-layout';
import KYC from '@/pages/subscription-setup/KYC-wait-page/KYC-wait-page';
import SubscriptionSetup from '@/pages/subscription-setup/Subscription-setup';
import GeneralSettings from '@/pages/dashboard/settings/general-settings/General';
import CountryCodes from '@/pages/dashboard/settings/country-codes/Country-codes';
import Splash from '@/pages/splash/Splash';
import MePreferencesLayout from '@/pages/profile/preferences/Me-preferences-layout';
import MeSuperAdminLayout from '@/pages/profile/super-admin/Super-admin-layout';
import UsersGroupsLayout from '@/pages/dashboard/users/users-groups/Layout';
import AddEditUsersGroup from '@/pages/dashboard/users/users-groups/Add-edit';
import AddEditIotDevicesGroup from '@/pages/dashboard/iot-devices/groups/Add-edit';

import AuthGuard from '@/guards/auth-guard';
import FeaturesGuard from '@/guards/features-guard';
import RolesGuard from '@/guards/roles-guard';
import TenantGuard from '@/guards/tenant-guard';
import AddonsGuard from '@/guards/addons-guard';

import ROUTES from '@/constants/routes';
import { FEATURES } from '@/constants/features';
import { ROLES } from '@/constants/roles';
import { ADDONS } from '@/constants/addons';

const Routes = () => {
  const { authorize } = useAuth();
  const { isLoading } = useAuth0();

  useEffect(() => {
    authorize();
  }, [isLoading]);

  const routes = useRoutes([
    {
      path: ROUTES.SPLASH,
      element: <Splash showLoading />,
    },
    {
      path: ROUTES.AUTH_LAYOUT,
      element: (
        <AuthGuard onAuthorizedRedirectTo={ROUTES.DASHBOARD_LAYOUT}>
          <AuthLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <Navigate replace to={ROUTES.SIGN_IN} />,
        },
        {
          path: ROUTES.SIGN_IN,
          element: <SignIn />,
        },
        {
          path: ROUTES.SIGN_UP,
          element: <SignUp />,
        },
      ],
    },
    {
      path: ROUTES.DASHBOARD_LAYOUT,
      element: (
        <TenantGuard>
          <AuthGuard onUnauthorizedRedirectTo={ROUTES.SIGN_IN}>
            <DashboardLayout />
          </AuthGuard>
        </TenantGuard>
      ),
      children: [
        {
          index: true,
          element: <Navigate replace to={ROUTES.DASHBOARD_VOICE} />,
        },
        // DASHBOARD
        {
          path: ROUTES.DASHBOARD_VOICE,
          element: (
            <FeaturesGuard
              features={[FEATURES.VOICE]}
              defaultOnNoAccessRedirectTo={ROUTES.DASHBOARD_MESSAGES}
            >
              <VoiceLayout />
            </FeaturesGuard>
          ),
          children: [
            {
              index: true,
              element: <Navigate to={ROUTES.DASHBOARD_VOICE_STATUS} replace />,
            },
            {
              path: ROUTES.DASHBOARD_VOICE_STATUS,
              element: <CallStatusLayout />,
            },
            {
              path: ROUTES.DASHBOARD_VOICE_HISTORY,
              element: <CallsHistory />,
            },
            {
              path: ROUTES.DASHBOARD_VOICE_CALLS_VOICEMAILS,
              element: <CallsVoicemails />,
            },
          ],
        },
        {
          path: ROUTES.DASHBOARD_MESSAGES,
          element: (
            <FeaturesGuard
              features={[FEATURES.SMS]}
              defaultOnNoAccessRedirectTo={ROUTES.DASHBOARD_CONTACTS}
            >
              <ChatMessagesLayout />
            </FeaturesGuard>
          ),
        },
        {
          path: ROUTES.DASHBOARD_BROADCAST_CAMPAIGN,
          element: (
            <AddonsGuard
              addons={[ADDONS.BROADCAST]}
              defaultOnNoAccessRedirectTo={ROUTES.DASHBOARD_CONTACTS}
            >
              <BroadcastCampaignLayout />
            </AddonsGuard>
          ),
        },
        {
          path: ROUTES.DASHBOARD_CONTACTS_PAGE_LAYOUT,
          element: <ContactsPageLayout />,
          children: [
            {
              index: true,
              element: <Navigate to={ROUTES.DASHBOARD_CONTACTS} replace />,
            },
            {
              path: ROUTES.DASHBOARD_CONTACTS,
              element: <ContactsLayout />,
            },
            {
              path: ROUTES.DASHBOARD_CONTACTS_GROUPS,
              element: <ContactsGroupsLayout />,
            },
          ],
        },
        {
          path: ROUTES.DASHBOARD_CONTACTS_UPLOAD,
          element: <UploadContacts />,
        },
        {
          path: ROUTES.DASHBOARD_CONTACTS_ADD_EDIT,
          element: <AddEditContact />,
        },
        {
          path: ROUTES.DASHBOARD_CONTACTS_GROUPS_CREATE_OR_UPDATE,
          element: <CreateOrUpdateContactsGroupsPage />,
        },

        {
          path: ROUTES.DASHBOARD_IOT_DEVICES_PAGE_LAYOUT,
          element: <IotDevicesPageLayout />,
          children: [
            {
              index: true,
              element: <Navigate to={ROUTES.DASHBOARD_IOT_DEVICES} replace />,
            },
            {
              path: ROUTES.DASHBOARD_IOT_DEVICES,
              element: <IotDevicesLayout />,
            },
            {
              path: ROUTES.DASHBOARD_IOT_DEVICES_ADD_EDIT,
              element: <AddEditIotDevice />,
            },
            {
              path: ROUTES.DASHBOARD_IOT_DEVICES_GROUPS,
              element: <IotDeviceGroups />,
            },
          ],
        },
        {
          path: ROUTES.DASHBOARD_IOT_DEVICES_GROUPS_ADD_EDIT,
          element: <AddEditIotDevicesGroup />,
        },
        {
          path: ROUTES.DASHBOARD_USERS_RELATED_INFO,
          element: (
            <RolesGuard
              roles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
              defaultOnNoAccessRedirectTo={ROUTES.DASHBOARD_CONTACTS}
            >
              <UsersRelatedInfoLayout />
            </RolesGuard>
          ),
          children: [
            {
              index: true,
              element: <Navigate to={ROUTES.DASHBOARD_USERS} replace />,
            },
            {
              path: ROUTES.DASHBOARD_USERS,
              element: <UsersLayout />,
            },
            {
              path: ROUTES.DASHBOARD_DIRECT_EXTENSIONS,
              element: <DirectExtensionsLayout />,
            },
            {
              path: ROUTES.DASHBOARD_USERS_GROUPS,
              element: <UsersGroupsLayout />,
            },
          ],
        },
        {
          path: ROUTES.DASHBOARD_USERS_GROUPS_ADD_EDIT,
          element: (
            <RolesGuard
              roles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
              defaultOnNoAccessRedirectTo={ROUTES.DASHBOARD_CONTACTS}
            >
              <AddEditUsersGroup />
            </RolesGuard>
          ),
        },
        {
          path: ROUTES.DASHBOARD_ADD_EDIT_USER,
          element: (
            <RolesGuard
              roles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
              defaultOnNoAccessRedirectTo={ROUTES.DASHBOARD_CONTACTS}
            >
              <AddEditUser />
            </RolesGuard>
          ),
        },
        {
          path: ROUTES.DASHBOARD_SETTINGS_LAYOUT,
          element: (
            <RolesGuard
              roles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
              defaultOnNoAccessRedirectTo={ROUTES.DASHBOARD_CONTACTS}
            >
              <SettingsLayout />
            </RolesGuard>
          ),
          children: [
            {
              index: true,
              element: <Navigate to={ROUTES.DASHBOARD_SETTINGS_VOICE_SETUP} replace />,
            },
            {
              path: ROUTES.DASHBOARD_SETTINGS_VOICE_SETUP,
              element: <VoiceSetup />,
            },
            {
              path: ROUTES.DASHBOARD_SETTINGS_CALL_FLOW,
              element: <WrongInputCallFlow />,
            },
            {
              path: ROUTES.DASHBOARD_SETTINGS_GENERAL,
              element: <GeneralSettings />,
            },
            {
              path: ROUTES.DASHBOARD_SETTINGS_COUNTRY_CODES,
              element: <CountryCodes />,
            },
          ],
        },
        {
          path: ROUTES.DASHBOARD_EXTENSIONS_GROUPS,
          element: (
            <RolesGuard
              roles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
              defaultOnNoAccessRedirectTo={ROUTES.DASHBOARD_CONTACTS}
            >
              <ExtensionsGroupsLayout />
            </RolesGuard>
          ),
        },
        {
          path: ROUTES.DASHBOARD_EXTENSIONS_GROUPS_ADD,
          element: (
            <RolesGuard
              roles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
              defaultOnNoAccessRedirectTo={ROUTES.DASHBOARD_CONTACTS}
            >
              <CreateUpdateExtensionsGroup />
            </RolesGuard>
          ),
        },
        {
          path: ROUTES.DASHBOARD_EXTENSIONS_GROUPS_UPDATE,
          element: (
            <RolesGuard
              roles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
              defaultOnNoAccessRedirectTo={ROUTES.DASHBOARD_CONTACTS}
            >
              <CreateUpdateExtensionsGroup />
            </RolesGuard>
          ),
        },
      ],
    },
    // PROFILE
    {
      path: ROUTES.PROFILE,
      element: (
        <TenantGuard>
          <AuthGuard onUnauthorizedRedirectTo={ROUTES.SIGN_IN}>
            <ProfileLayout />
          </AuthGuard>
        </TenantGuard>
      ),
      children: [
        {
          path: ROUTES.PROFILE_ME,
          element: <MeProfile />,
        },
        {
          path: ROUTES.PROFILE_PREFERENCES,
          element: <MePreferencesLayout />,
        },
        {
          path: ROUTES.PROFILE_CHANGE_PASSWORD,
          element: <ChangePassword />,
        },
        {
          path: ROUTES.PROFILE_SUBSCRIPTIONS,
          element: <Subscriptions />,
        },
        {
          path: ROUTES.PROFILE_CREDIT_CARDS,
          element: <CreditCards />,
        },
        {
          path: ROUTES.PROFILE_SUPER_ADMIN,
          element: <MeSuperAdminLayout />,
        },
      ],
    },
    {
      path: ROUTES.PRICING,
      element: <Pricing />,
    },
    {
      path: ROUTES.SUBSCRIPTION_SETUP,
      element: (
        <TenantGuard>
          <AuthGuard onUnauthorizedRedirectTo={ROUTES.SIGN_IN}>
            <SubscriptionSetup />
          </AuthGuard>
        </TenantGuard>
      ),
    },
    {
      path: ROUTES.KYC,
      element: (
        <TenantGuard>
          <AuthGuard onUnauthorizedRedirectTo={ROUTES.SIGN_IN}>
            <KYC />
          </AuthGuard>
        </TenantGuard>
      ),
    },
    {
      path: '*',
      element: <Navigate replace to={ROUTES.SPLASH} />,
    },
  ]);

  return routes;
};

export default Routes;

import makeRequest from '../make-request';

import token from '../token';

import {
  ICreateExtensionsGroupRequest,
  IUpdateExtensionsGroupRequest,
} from '@/interfaces/extensions-groups';

const GET_EXTENSIONS_GROUP_BY_ID = '/api/v1/extensions-groups/:id';
const GET_EXTENSIONS_GROUPS = '/api/v1/extensions-groups';
const CREATE_EXTENSIONS_GROUP = '/api/v1/extensions-groups';
const DELETE_EXTENSIONS_GROUP = '/api/v1/extensions-groups';
const EDIT_EXTENSIONS_GROUP = '/api/v1/extensions-groups';

export const getById = async (id: string) => {
  try {
    const result = await makeRequest(GET_EXTENSIONS_GROUP_BY_ID.replace(':id', id), {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getAll = async () => {
  try {
    const result = await makeRequest(GET_EXTENSIONS_GROUPS, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const createExtensionsGroup = async (data: ICreateExtensionsGroupRequest) => {
  try {
    const result = await makeRequest(CREATE_EXTENSIONS_GROUP, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const deleteExtensionsGroup = async (id: string) => {
  try {
    const result = await makeRequest(`${DELETE_EXTENSIONS_GROUP}/${id}`, {
      method: 'DELETE',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const editExtensionsGroup = async (id: string, data: IUpdateExtensionsGroupRequest) => {
  try {
    const result = await makeRequest(`${EDIT_EXTENSIONS_GROUP}/${id}`, {
      method: 'PUT',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

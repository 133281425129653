export const box1SX = {
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'start',
};

export const box2SX = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const formControl1SX = {
  '& .MuiInput-underline:after': {
    borderBottomColor: 'secondary.main',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'secondary.main',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'secondary.main',
  },
};

export const typography1SX = {
  fontSize: 11,
  color: 'grey',
  mb: 0.5,
};

export const iconButton1SX = {
  p: 0,
};

import { useEffect } from 'react';

import * as socketService from '@/services/socketio/socketio';

import * as userThunk from '@/pages/dashboard/users/thunk';

import * as socketReducer from '@/store/reducers/socket';
import * as tenantThunk from '@/store/thunk/tenant';
import { useAppDispatch } from '@/store/hooks';

import SOCKETIO from '@/constants/socketio';

const useSocket = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    socketService.socket
      ?.on('disconnect', () => {
        dispatch(socketReducer.setIsSocketConnected(false));
      })
      .on('connect', () => {
        dispatch(socketReducer.setIsSocketConnected(true));
      })
      .on(SOCKETIO.EVENTS.FETCH_DATA, () => {
        dispatch(tenantThunk.fetchTenantInfo());
        dispatch(userThunk.fetchGetUserInfo());
      });

    return function cleanup() {
      socketService.socket?.removeListener('disconnect');
      socketService.socket?.removeListener('connect');
      socketService.socket?.removeListener(SOCKETIO.EVENTS.FETCH_DATA);
    };
  }, [socketService.socket]);

  return {};
};

export default useSocket;

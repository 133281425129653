import * as React from 'react';
import { useEffect, useState } from 'react';

import { useAppSelector } from '@/store/hooks';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Box, Paper, Slide, SlideProps } from '@mui/material';

const INTERNAL_ERROR_REPLACER = 'Something went wrong. Please try again later.';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={24} ref={ref} variant="standard" {...props} />;
});

type TransitionProps = Omit<SlideProps, 'direction'>;

function Transition(props: TransitionProps) {
  return <Slide {...props} direction="right" />;
}

const Toaster = () => {
  const { uuid, message, barType } = useAppSelector((state) => state.snackbar);

  const [alerts, setAlerts] = useState<{ uuid: string; alert: JSX.Element }[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const handleRemoveAlert = (uuidToRemove: string): void => {
    setAlerts((current) => {
      const newAlerts = current.filter((element) => element.uuid !== uuidToRemove);
      if (!newAlerts.length) {
        setOpen(false);
      }
      return newAlerts;
    });
  };

  useEffect(() => {
    if (!message || alerts.some((element) => element.uuid === uuid)) {
      return;
    }

    setAlerts((current) => [
      {
        uuid,
        alert: (
          <Alert
            key={uuid}
            onClose={() => handleRemoveAlert(uuid)}
            severity={barType}
            sx={{ mt: 1 }}
          >
            {message === 'Internal server error.' ? INTERNAL_ERROR_REPLACER : message}
          </Alert>
        ),
      },
      ...current,
    ]);

    setOpen(true);

    setTimeout(() => handleRemoveAlert(uuid), 5000);
  }, [uuid, message]);

  return (
    <Stack spacing={2} width={'50%'} component={Paper} elevation={24}>
      <Snackbar
        open={open}
        TransitionComponent={Transition}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box>{alerts.map((element) => element.alert)}</Box>
      </Snackbar>
    </Stack>
  );
};

export default Toaster;

import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';
import { useState } from 'react';

import {
  Avatar,
  Box,
  Chip,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  Typography,
} from '@mui/material';

import {
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';

import { IIotDevicesGroupResult } from '@/interfaces/iot-devices-groups';

import TemplateTable, { ITemplateTableProps } from '@/components/Template-table/Template-table';
import ConfirmationDialog from '@/components/Dialogs/Confirmation';
import NoDataValue from '@/components/Data/No-data-value';

import useAccess from '@/hooks/use-access';

import { ROLES } from '@/constants/roles';

interface IIotDevicesGroupsListProps {
  data: IIotDevicesGroupResult[];
  handleUpdate: (el: IIotDevicesGroupResult) => void;
  handleDelete: (el: IIotDevicesGroupResult) => void;
}

interface IIotDevicesGroupActionsMenuButtonProps {
  data: IIotDevicesGroupResult;
  handleUpdate: (el: IIotDevicesGroupResult) => void;
  handleDelete: (el: IIotDevicesGroupResult) => void;
}

const IotDevicesGroupActionsMenuButton = ({
  data,
  ...actions
}: IIotDevicesGroupActionsMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const isOpened = !!anchorEl;

  return (
    <>
      <ConfirmationDialog
        show={showConfirmationDialog}
        handleAccepted={() => {
          setShowConfirmationDialog(false);
          actions.handleDelete(data);
        }}
        handleRejected={() => setShowConfirmationDialog(false)}
        title="Do you wish to delete the Devices Group?"
        subtitle="Once you press Yes, the Devices Group will be deleted, and this operation cannot be undone."
      />
      <IconButton
        color="primary"
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl((prevState) => (prevState ? null : event.currentTarget));
        }}
      >
        <Menu
          anchorEl={anchorEl}
          open={isOpened}
          disableAutoFocusItem
          onClose={() => setAnchorEl(null)}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => actions.handleUpdate(data)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="small" />
            </ListItemIcon>
            Edit
          </MenuItem>

          <MenuItem onClick={() => setShowConfirmationDialog(true)}>
            <ListItemIcon>
              <DeleteOutlineIcon color="error" fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>

        <MoreVertIcon />
      </IconButton>
    </>
  );
};

const IotDevicesGroupsList = ({ data, ...actions }: IIotDevicesGroupsListProps) => {
  const { isUserRoleMatches } = useAccess();

  const templateTableProps: ITemplateTableProps<IIotDevicesGroupResult> = {
    data,
    getDataEntityId: (el) => el.id,
    getExpandComponent: (el) => (
      <TableCell colSpan={templateTableProps.columns.length + 1} sx={{ pt: 0 }}>
        <Typography variant="subtitle2" component="p" fontWeight="bold" mb={1}>
          Devices
        </Typography>

        <Box display="flex" flexWrap="wrap" gap={1} mb={1}>
          {el.iotDevices.length ? (
            el.iotDevices.map((el) => (
              <Chip
                sx={{ height: 'auto', pl: 1 }}
                key={el.id}
                label={
                  <Stack p={1}>
                    <Typography color="inherit" fontSize="inherit">
                      {el.name}
                    </Typography>
                    <Typography color="inherit" fontSize="inherit">
                      {el.description}
                    </Typography>
                    {isUserRoleMatches([ROLES.SUPER_ADMIN, ROLES.ADMIN]) && (
                      <>
                        <Typography color="inherit" fontSize="inherit">
                          IP: {el.ipAddress ?? <NoDataValue />}
                        </Typography>
                        <Typography color="inherit" fontSize="inherit">
                          MAC: {el.mac ?? <NoDataValue />}
                        </Typography>
                        <Typography color="inherit" fontSize="inherit">
                          Serial Number: {el.serialNumber ?? <NoDataValue />}
                        </Typography>
                      </>
                    )}
                  </Stack>
                }
              />
            ))
          ) : (
            <NoDataValue />
          )}
        </Box>

        <Typography variant="subtitle2" component="p" fontWeight="bold" mb={1}>
          Users
        </Typography>

        <Box display="flex" flexWrap="wrap" gap={1} mb={1}>
          {el.users.length ? (
            el.users.map((el) => (
              <Chip
                sx={{ height: 'auto', pl: 1 }}
                key={el.id}
                avatar={<Avatar src={el.avatarUrl ? el.avatarUrl : DefaultAvatar} />}
                label={
                  <Stack p={1}>
                    <Typography color="inherit" fontSize="inherit">
                      {el.fullName}
                    </Typography>
                    <Typography color="inherit" fontSize="inherit">
                      {el.companyExtension}
                    </Typography>
                  </Stack>
                }
              />
            ))
          ) : (
            <NoDataValue />
          )}
        </Box>

        <Typography variant="subtitle2" component="p" fontWeight="bold" mb={1}>
          Users Groups
        </Typography>

        <Box display="flex" flexWrap="wrap" gap={1} mb={1}>
          {el.usersGroups.length ? (
            el.usersGroups.map((el) => (
              <Chip
                sx={{ height: 'auto' }}
                key={el.id}
                label={
                  <Stack p={1}>
                    <Typography color="inherit" fontSize="inherit">
                      {el.name}
                    </Typography>
                    <Typography color="inherit" fontSize="inherit">
                      Description: {el.description ?? <NoDataValue />}
                    </Typography>
                  </Stack>
                }
              />
            ))
          ) : (
            <NoDataValue />
          )}
        </Box>
      </TableCell>
    ),
    columns: [
      {
        header: 'Name',
        getValue: (el) => el.name,
      },
      {
        sharedProps: { sx: { width: '30%' } },
        header: 'Description',
        getValue: (el) => el.description || <NoDataValue />,
      },
      {
        sharedProps: { sx: { minWidth: '10rem' } },
        header: 'Devices in group',
        getValue: (el) => el.iotDevices.length || <NoDataValue />,
      },
      {
        sharedProps: { sx: { minWidth: '10rem' } },
        header: 'Users in group',
        getValue: (el) => el.users.length || <NoDataValue />,
      },
      {
        sharedProps: { sx: { minWidth: '10rem' } },
        header: 'Users Groups in group',
        getValue: (el) => el.usersGroups.length || <NoDataValue />,
      },
      {
        sharedProps: { sx: { width: '7rem' } },
        header: 'Actions',
        getValue: (el) => <IotDevicesGroupActionsMenuButton data={el} {...actions} />,
      },
    ],
    tableContainerProps: { sx: { height: 'calc(100vh - 24rem)' } },
  };

  return <TemplateTable {...templateTableProps} />;
};

export default IotDevicesGroupsList;

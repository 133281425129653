import React, { useEffect } from 'react';
import '@/styles/datepicker-styles.scss';

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import * as styles from './styles';

import * as bmcReducer from '@/pages/dashboard/broadcast/campaign/reducer';

import {
  buildDate,
  getHours,
  format,
  eachDayNameOfInterval,
  getDay,
  getMinutes,
  isAfter,
  isValid,
} from '@/helpers/date/date';

import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

import DATE from '@/constants/date';

const days: string[] = ['Mon.', 'Tues.', 'Wed.', 'Thurs.', 'Fri.', 'Sat.', 'Sun.'];

const SelectDateAndTime: React.FC = () => {
  const dispatch = useAppDispatch();

  const { startDate, stopDate, enabledDays, runDays, minStopTime, startTime, stopTime } =
    useAppSelector((state) => state.broadcastCampaign);

  const handleOnStartDateSelect = (date: Date) => {
    dispatch(bmcReducer.setStartDate(date));
    dispatch(bmcReducer.setStopDate(date));
    //@ts-expect-error need to check
    dispatch(bmcReducer.setEnabledDays([DATE.DAYS.NUMBER_DAY_SHORT[getDay(date)]]));
  };

  const handleOnStopDateSelect = (date: Date) => {
    dispatch(bmcReducer.setStopDate(date));

    if (format(startDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')) {
      //@ts-expect-error need to check
      dispatch(bmcReducer.setEnabledDays([DATE.DAYS.NUMBER_DAY_SHORT[getDay(date)]]));
    } else {
      dispatch(bmcReducer.setEnabledDays(eachDayNameOfInterval(startDate, date)));
    }
  };

  const handleOnMonthChange = (date: Date) => {
    if (startDate < date) {
      dispatch(bmcReducer.setMinStopTime(buildDate(date, { hours: 0 })));
    }
  };

  const handleOnSelectRunDay = (day: string) => {
    dispatch(bmcReducer.setRunDays(day));
  };

  const handleOnSelectStartTime = (date: Date) => {
    dispatch(bmcReducer.setStartTime(date));

    let stopTimeAndMinStopTime = buildDate(date, {
      hours: getHours(date),
      minutes: getMinutes(date) + 5,
    });
    if (
      isAfter(
        buildDate(date, {
          hours: getHours(date),
          minutes: getMinutes(date) + 5,
        }),
        buildDate(date, { hours: 23, minutes: 59 }),
      )
    ) {
      stopTimeAndMinStopTime = buildDate(date, {
        hours: 23,
        minutes: 59,
      });
    }
    dispatch(bmcReducer.setStopTime(stopTimeAndMinStopTime));
    dispatch(bmcReducer.setMinStopTime(stopTimeAndMinStopTime));
  };

  const handleOnSelectStopTime = (day: Date) => {
    dispatch(bmcReducer.setStopTime(day));
  };

  useEffect(() => {
    const today = new Date();
    if (!enabledDays.length) {
      //@ts-expect-error need to fix this place
      dispatch(bmcReducer.setEnabledDays([DATE.DAYS.NUMBER_DAY_SHORT[getDay(today)]]));
    }
    if (!startDate) {
      dispatch(bmcReducer.setStartDate(today));
      dispatch(bmcReducer.setStopDate(today));
    }
    if (!startTime) {
      dispatch(bmcReducer.setStartTime(today));
      dispatch(
        bmcReducer.setStopTime(
          buildDate(today, { hours: getHours(today), minutes: getMinutes(today) + 5 }),
        ),
      );
      dispatch(
        bmcReducer.setMinStopTime(
          buildDate(today, { hours: getHours(today), minutes: getMinutes(today) + 5 }),
        ),
      );
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={2} m={2} mt={4}>
      <Box display="flex" gap={3}>
        <DateTimePicker
          label="Start date and time"
          minDate={new Date()}
          format="MM/dd/yyyy hh:mm aa"
          value={startDate}
          onChange={(value) => {
            if (value && isValid(value)) {
              handleOnStartDateSelect(value);
              handleOnSelectStartTime(value);
            }
          }}
          // renderInput={(props) => <TextField {...props} />}
        />

        <DateTimePicker
          label="End date and time"
          format="MM/dd/yyyy hh:mm aa"
          minDate={startDate}
          value={stopDate}
          onChange={(value) => {
            if (value && isValid(value)) {
              handleOnStopDateSelect(value);
              handleOnSelectStopTime(value);
            }
          }}
          // renderInput={(props) => <TextField {...props} />}
        />
      </Box>

      <Box>
        <Typography variant="body2" id="broadcast-id" component="label">
          Run broadcast on days
        </Typography>

        <FormGroup sx={styles.formGroupSX} aria-labelledby="broadcast-id">
          {days.map((day: string) => {
            return (
              <FormControlLabel
                sx={styles.formControlLabeSX}
                key={day}
                onChange={() => handleOnSelectRunDay(day)}
                control={
                  <Checkbox checked={runDays.includes(day)} disabled={!enabledDays.includes(day)} />
                }
                label={day}
              />
            );
          })}
        </FormGroup>
      </Box>
    </Box>
  );
};

export default SelectDateAndTime;

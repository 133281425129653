import makeRequest from '../make-request';

const GET_TIME_ZONES = '/api/v1/tz';

export const getAll = async () => {
  try {
    const result = await makeRequest(GET_TIME_ZONES, {
      method: 'GET',
      headers: {},
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

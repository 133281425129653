import { FunctionComponent, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import * as callService from '@/services/voice/call';

import { LOCAL_STORAGE_KEYS } from '@/constants/local-storage';
import ROUTES from '@/constants/routes';

// This will use saved phone number from local storage and initiate the call
const ClickToCallAuthorizedHandler: FunctionComponent = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const useEffectOnReady = useAppSelector((state) => state.twilio.useEffectOnReady);

  const clickToCallRef = useRef({ isInProgress: false });

  useEffect(() => {
    if (useEffectOnReady && !clickToCallRef.current.isInProgress) {
      clickToCallRef.current.isInProgress = true;

      // Do not allow more calls
      setTimeout(() => {
        clickToCallRef.current.isInProgress = false;
      }, 2000);

      const phoneNumber = localStorage.getItem(LOCAL_STORAGE_KEYS.CLICK_TO_CALL_PHONE_NUMBER);

      if (phoneNumber) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.CLICK_TO_CALL_PHONE_NUMBER);

        callService.handleInitCall({ dispatch, to: phoneNumber });
        navigate(ROUTES.DASHBOARD_VOICE_STATUS);
      }
    }
  }, [useEffectOnReady]);

  return <></>;
};

export default ClickToCallAuthorizedHandler;

import { AppDispatch } from '@/store/store';

import { isCallOnHold } from '../voice/call';
import { handleDisconnect } from './handle-disconnect';
import { endCall } from './end-call';

export const handleInboundCallEvents = (data: { connection: any; dispatch: AppDispatch }) => {
  const { connection, dispatch } = data;

  connection
    .on('cancel', () => {
      const { customParameters } = connection;
      const uuid = customParameters.get('uuid');
      endCall({ dispatch, uuid, skipInvokeCancelCall: true });
    })
    .on('reject', () => {
      const { customParameters } = connection;
      const uuid = customParameters.get('uuid');
      endCall({ dispatch, uuid });
    })
    .on('disconnect', (conn: any) => {
      const { customParameters } = conn;
      const uuid = customParameters.get('uuid');
      if (!isCallOnHold(uuid)) {
        handleDisconnect({ dispatch, uuid });
      }
    })
    .on('error', (error: any) => {
      console.debug('Twilio inbound call error: ', error);
      // const { customParameters } = connection;
      // const uuid = customParameters.get('uuid');
      // endCall({ dispatch, uuid });
    });
};

import { FunctionComponent, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { ITimeZone } from '@/interfaces/time-zone';

import * as timeZoneThunk from '@/store/thunk/time-zone';
import * as tenantThunk from '@/store/thunk/tenant';

import { TemplateDialog } from '@/components/Dialogs/Template-dialog';

import useTemplateDialog from '@/hooks/use-template-dialog';

const TimeZoneSettings: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const tenant = useAppSelector((state) => state.tenant.tenant);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timeZone, setTimeZone] = useState<ITimeZone | null>(null);
  const [timeZones, setTimeZones] = useState<ITimeZone[]>([]);

  const [confirmationDialogState, confirmationDialogActions] = useTemplateDialog({
    isOpened: false,
    title: 'Time zone change',
    textContent: 'Are you sure you want to change time zone of organization?',
    showCloseButton: false,
    dialogProps: { maxWidth: 'xs' },
    submitButtonProps: { children: 'Yes, change time zone' },
  });

  const handleTimeZoneChange = async (value: ITimeZone) => {
    if (!value || !tenant) {
      return;
    }

    const response = await confirmationDialogActions.openDialog();

    if (!response) {
      return;
    }

    setIsLoading(true);

    const { meta } = await dispatch(tenantThunk.fetchSetTimeZone({ timeZone: value.name }));

    if (meta.requestStatus === 'fulfilled') {
      setTimeZone(value);
    }

    setIsLoading(false);
  };

  const handleLoadTimezones = async () => {
    setIsLoading(true);

    const timeZones: ITimeZone[] = await dispatch(timeZoneThunk.fetchTimeZones()).unwrap();

    if (timeZones) {
      setTimeZone(timeZones.find((el) => el.name === tenant?.timeZone) ?? null);
      setTimeZones(timeZones);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (tenant?.id) {
      handleLoadTimezones();
    }
  }, [tenant?.id]);

  return (
    <>
      <TemplateDialog {...confirmationDialogState} />

      <Autocomplete
        id="timeZones"
        options={timeZones}
        value={timeZone}
        disabled={!timeZones.length || isLoading}
        onChange={(event, list, reason, detail) => {
          handleTimeZoneChange(detail?.option as ITimeZone);
        }}
        getOptionLabel={(option) => option.fullName}
        isOptionEqualToValue={(option, value) => option.name === value?.name}
        renderInput={(params) => <TextField {...params} label="Time zone of organization" />}
      />
    </>
  );
};

export default TimeZoneSettings;

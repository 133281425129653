import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { IContact } from '@/interfaces/contact';
import { IPartialResult } from '@/interfaces/querying-data';
import { IContactsGroupResult } from '@/interfaces/contacts-group';
import { BroadcastTypes } from '@/types/broadcast';

import {
  fetchContactsInitial,
  fetchOnLoadMore,
  fetchValidateFile,
  fetchSearchContact,
  fetchGetContactsGroupsInitial,
  fetchGetLoadMoreContactsGroups,
  fetchGetSearchContactsGroups,
} from '@/pages/dashboard/broadcast/campaign/thunk';

import { addMinutes } from '@/helpers/date/date';

import BROADCAST from '@/constants/broadcast';

export interface IMessageState {
  type: BroadcastTypes;
  activeStep: number;
  isStepError: boolean;
  invalidSteps: number[];
  files: any[];
  message: string;

  contactsGroupsState: {
    contactGroupsResult: IPartialResult<IContactsGroupResult>;
    selectedContactsGroups: string[];
    excludedContactsGroups: string[];
    selectedAllContactsGroups: boolean;
    selectedContactsGroupsCount: number;
  };

  contactsMaxCount: number;
  contacts: IContact[];
  selectedContacts: string[];
  excludedContacts: string[];
  selectedAllContacts: boolean;
  selectedContactsCount: number;

  minLimit: number;
  contactsFile: any;
  phoneNumbersCount: number;
  startDate: Date;
  stopDate: Date;
  startTime: any;
  stopTime: any;
  minStopTime: Date | null;
  enabledDays: string[];
  runDays: string[];
  showModal: boolean;
  isInputValid: boolean;
}

const initialState: IMessageState = {
  type: BROADCAST.TYPES.SMS,
  activeStep: 0,
  isStepError: false,
  invalidSteps: [],
  files: [],
  message: '',
  contactsMaxCount: 0,

  contactsGroupsState: {
    contactGroupsResult: {
      maxCount: 0,
      records: [],
    },
    selectedContactsGroups: [],
    excludedContactsGroups: [],
    selectedAllContactsGroups: false,
    selectedContactsGroupsCount: 0,
  },

  contacts: [],
  selectedContacts: [],
  excludedContacts: [],
  selectedAllContacts: false,
  selectedContactsCount: 0,
  minLimit: 50,
  contactsFile: null,
  phoneNumbersCount: 0,
  startDate: new Date(),
  stopDate: addMinutes(new Date(), 5),
  startTime: null,
  stopTime: null,
  minStopTime: null,
  enabledDays: [],
  runDays: [],
  showModal: false,
  isInputValid: false,
};

export const broadcastCampaign = createSlice({
  name: 'broadcast-campaign',
  initialState,
  reducers: {
    setDefault: (state) => {
      for (const key in initialState) {
        //@ts-ignore
        state[key] = initialState[key];
      }
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    setIsStepError: (state, action: PayloadAction<boolean>) => {
      state.isStepError = action.payload;
    },
    setInvalidSteps: (state, action: PayloadAction<number[]>) => {
      state.invalidSteps = action.payload;
    },
    setFiles: (state, action: PayloadAction<any[]>) => {
      state.files = action.payload;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    selectedContact: (state, action: PayloadAction<string>) => {
      const currentIndex = state.selectedContacts.indexOf(action.payload);
      const newChecked: string[] = [...state.selectedContacts];
      if (currentIndex === -1) {
        newChecked.push(action.payload);
        state.selectedContactsCount = state.selectedContactsCount + 1;
      } else {
        newChecked.splice(currentIndex, 1);
        state.selectedContactsCount = state.selectedContactsCount - 1;
        if (state.selectedAllContacts) {
          state.excludedContacts = [...new Set([...state.excludedContacts, action.payload])];
        }
      }
      state.invalidSteps = [];
      state.selectedContacts = newChecked;
    },
    setSelectAllContacts: (state, action: PayloadAction<string[]>) => {
      if (state.selectedContacts.length === action.payload.length) {
        state.selectedContacts = [];
        state.selectedAllContacts = false;
        state.selectedContactsCount = 0;
      } else {
        state.selectedContacts = action.payload;
        state.selectedAllContacts = true;
        state.selectedContactsCount = state.contactsMaxCount;
      }
      state.invalidSteps = [];
      state.excludedContacts = [];
    },
    selectedContactsGroup: (state, action: PayloadAction<string>) => {
      const { contactsGroupsState } = state;

      const currentIndex = contactsGroupsState.selectedContactsGroups.indexOf(action.payload);
      const newChecked: string[] = [...contactsGroupsState.selectedContactsGroups];

      if (currentIndex === -1) {
        newChecked.push(action.payload);

        contactsGroupsState.selectedContactsGroupsCount =
          contactsGroupsState.selectedContactsGroupsCount + 1;
      } else {
        newChecked.splice(currentIndex, 1);

        contactsGroupsState.selectedContactsGroupsCount =
          contactsGroupsState.selectedContactsGroupsCount - 1;

        if (contactsGroupsState.selectedAllContactsGroups) {
          contactsGroupsState.excludedContactsGroups = [
            ...new Set([...contactsGroupsState.excludedContactsGroups, action.payload]),
          ];
        }
      }

      state.invalidSteps = [];
      contactsGroupsState.selectedContactsGroups = newChecked;
    },
    setSelectAllContactsGroups: (state, action: PayloadAction<string[]>) => {
      const { contactsGroupsState } = state;

      if (contactsGroupsState.selectedContactsGroups.length === action.payload.length) {
        contactsGroupsState.selectedContactsGroups = [];
        contactsGroupsState.selectedAllContactsGroups = false;
        contactsGroupsState.selectedContactsGroupsCount = 0;
      } else {
        contactsGroupsState.selectedContactsGroups = action.payload;
        contactsGroupsState.selectedAllContactsGroups = true;
        contactsGroupsState.selectedContactsGroupsCount =
          contactsGroupsState.contactGroupsResult.maxCount;
      }

      state.invalidSteps = [];
      contactsGroupsState.excludedContactsGroups = [];
    },
    setContactsFile: (state, action: PayloadAction<any>) => {
      state.contactsFile = action.payload;
    },
    deleteContactsFile: (state) => {
      state.contactsFile = null;
    },
    setStartDate: (state, action: PayloadAction<Date>) => {
      state.startDate = action.payload;
    },
    setStopDate: (state, action: PayloadAction<Date>) => {
      state.stopDate = action.payload;
    },
    setStartTime: (state, action: PayloadAction<Date>) => {
      state.startTime = action.payload;
    },
    setStopTime: (state, action: PayloadAction<Date>) => {
      state.stopTime = action.payload;
    },
    setMinStopTime: (state, action: PayloadAction<Date>) => {
      state.minStopTime = action.payload;
    },
    setEnabledDays: (state, action: PayloadAction<string[]>) => {
      state.enabledDays = action.payload;
      state.runDays = action.payload;
    },
    setRunDays: (state, action: PayloadAction<string>) => {
      const currentIndex = state.runDays.indexOf(action.payload);
      const newChecked: string[] = [...state.runDays];
      if (currentIndex === -1) {
        newChecked.push(action.payload);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      state.runDays = newChecked;
    },
    setShowModal: (state) => {
      state.showModal = !state.showModal;
      state.isInputValid = false;
    },
    setIsInputValid: (state, action: PayloadAction<boolean>) => {
      state.isInputValid = action.payload;
    },
    setType: (state, action: PayloadAction<BroadcastTypes>) => {
      state.type = action.payload;
    },
    // setContactsGroupsState: (
    //   state,
    //   action: PayloadAction<Partial<IMessageState['contactsGroupsState']>>,
    // ) => {
    //   state.contactsGroupsState = { ...state.contactsGroupsState, ...action.payload };
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetContactsGroupsInitial.fulfilled, (state, action) => {
      state.contactsGroupsState.contactGroupsResult = action.payload;
    });
    builder.addCase(fetchGetLoadMoreContactsGroups.fulfilled, (state, action) => {
      const { contactsGroupsState } = state;
      const { maxCount, records } = action.payload;

      contactsGroupsState.contactGroupsResult = {
        maxCount,
        records: contactsGroupsState.contactGroupsResult.records.concat(records),
      };

      if (contactsGroupsState.selectedAllContactsGroups) {
        const contactsGroups = [];

        for (const { id } of records) {
          if (contactsGroupsState.excludedContactsGroups.indexOf(id) === -1) {
            contactsGroups.push(id);
          }
        }

        contactsGroupsState.selectedContactsGroups = [
          ...new Set([...contactsGroupsState.selectedContactsGroups, ...contactsGroups]),
        ];
      }
    });
    builder.addCase(fetchGetSearchContactsGroups.fulfilled, (state, action) => {
      const { contactsGroupsState } = state;
      const { records } = action.payload;

      if (contactsGroupsState.selectedAllContactsGroups) {
        const contactsGroups = [];

        for (const { id } of records) {
          if (contactsGroupsState.excludedContactsGroups.indexOf(id) === -1) {
            contactsGroups.push(id);
          }
        }

        contactsGroupsState.selectedContactsGroups = [
          ...new Set([...contactsGroupsState.selectedContactsGroups, ...contactsGroups]),
        ];
      }

      contactsGroupsState.contactGroupsResult = { records, maxCount: 0 };
    });
    builder.addCase(fetchContactsInitial.fulfilled, (state, action) => {
      state.contacts = action.payload.records;
      state.contactsMaxCount = action.payload.maxCount;
    });
    builder.addCase(fetchOnLoadMore.fulfilled, (state, action) => {
      const records = action.payload.records;
      state.contacts = [...state.contacts, ...records];
      state.contactsMaxCount = action.payload.maxCount;

      if (state.selectedAllContacts) {
        const contacts = [];
        for (const contact of action.payload.records) {
          if (state.excludedContacts.indexOf(contact.id) === -1) {
            contacts.push(contact.id);
          }
        }
        state.selectedContacts = [...new Set([...state.selectedContacts, ...contacts])];
      }
    });
    builder.addCase(fetchSearchContact.fulfilled, (state, action) => {
      const { records } = action.payload;

      if (state.selectedAllContacts) {
        const contacts = [];

        for (const contact of records) {
          if (state.excludedContacts.indexOf(contact.id) === -1) {
            contacts.push(contact.id);
          }
        }

        state.selectedContacts = [...new Set([...state.selectedContacts, ...contacts])];
      }

      state.contacts = records;
      state.contactsMaxCount = 0;
    });
    builder.addCase(fetchValidateFile.fulfilled, (state, action) => {
      state.selectedContacts = [];
      state.excludedContacts = [];
      state.selectedAllContacts = false;
      state.selectedContactsCount = 0;
      state.phoneNumbersCount = action.payload.phoneNumbersCount;
      state.contactsGroupsState = {
        contactGroupsResult: {
          records: [],
          maxCount: 0,
        },
        excludedContactsGroups: [],
        selectedContactsGroups: [],
        selectedAllContactsGroups: false,
        selectedContactsGroupsCount: 0,
      };
      state.invalidSteps = [];
    });
  },
});
export const {
  setDefault,
  setActiveStep,
  setIsStepError,
  setInvalidSteps,
  setFiles,
  setMessage,
  setSelectAllContacts,
  selectedContact,
  setContactsFile,
  deleteContactsFile,
  setMinStopTime,
  setStartDate,
  setStopDate,
  setStartTime,
  setStopTime,
  setEnabledDays,
  setRunDays,
  setShowModal,
  setIsInputValid,
  setType,
  selectedContactsGroup,
  setSelectAllContactsGroups,
} = broadcastCampaign.actions;

export default broadcastCampaign.reducer;

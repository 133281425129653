import React, { SyntheticEvent } from 'react';
import '@/styles/datepicker-styles.scss';

import { Box, TextField, Typography, Tooltip, IconButton, Chip } from '@mui/material';
import Autocomplete, { AutocompleteChangeDetails } from '@mui/material/Autocomplete';
import { TimePicker } from '@mui/x-date-pickers';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { VoiceSettingsIncomingSetup } from '@/interfaces/voice/settings';

import { buildDate, format, isValid } from '@/helpers/date/date';

import * as styles from './styles';

import DATE from '@/constants/date';

const SELECT_ALL_DAYS_OPTION = {
  name: 'Select all',
  selected: false,
};
const WorkingSchedule = (props: {
  voiceSetup: VoiceSettingsIncomingSetup;
  setVoiceSetup: React.Dispatch<React.SetStateAction<VoiceSettingsIncomingSetup>>;
}) => {
  const { voiceSetup, setVoiceSetup } = props;
  const workingScheduleGroupsSetup = voiceSetup.workingScheduleGroups;

  const addDays = (index: number, day: string): void => {
    const schedule = workingScheduleGroupsSetup[index];
    if (schedule.days.includes(day)) {
      setVoiceSetup((current) => {
        return {
          ...current,
          workingScheduleGroups: workingScheduleGroupsSetup.map((element, i) => {
            return i === index
              ? { ...element, days: element.days.filter((e) => e !== day) }
              : element;
          }),
        };
      });
    } else {
      setVoiceSetup((current) => {
        return {
          ...current,
          workingScheduleGroups: workingScheduleGroupsSetup.map((element, i) => {
            return i === index ? { ...element, days: [...element.days, day] } : element;
          }),
        };
      });
    }
  };

  const handleOnChangeDay = (
    index: number,
    event: SyntheticEvent,
    list: any[],
    reason: string,
    detail?: AutocompleteChangeDetails<any>,
  ) => {
    if (workingScheduleGroupsSetup[index]) {
      if (reason === 'clear') {
        setVoiceSetup((current) => {
          return {
            ...current,
            workingScheduleGroups: workingScheduleGroupsSetup.map((element, i) => {
              return i === index ? { ...element, days: [] } : element;
            }),
          };
        });
        return;
      }

      const value = detail?.option;

      if (!value) {
        return;
      }

      if (value.name === SELECT_ALL_DAYS_OPTION.name) {
        if (list.length < 8) {
          const newDays: string[] = [];
          days.forEach((day) => {
            const isAlreadySelected = workingScheduleGroupsSetup.some((el) =>
              el.days.includes(day.name),
            );
            if (!isAlreadySelected && day.name !== SELECT_ALL_DAYS_OPTION.name) {
              newDays.push(day.name);
            }
          });
          setVoiceSetup((current) => {
            return {
              ...current,
              workingScheduleGroups: workingScheduleGroupsSetup.map((element, i) => {
                return i === index ? { ...element, days: [...element.days, ...newDays] } : element;
              }),
            };
          });
        }

        return;
      }

      if (reason === 'removeOption') {
        setVoiceSetup((current) => {
          return {
            ...current,
            workingScheduleGroups: workingScheduleGroupsSetup.map((element, i) => {
              return i === index
                ? { ...element, days: element.days.filter((e) => e !== value.name) }
                : element;
            }),
          };
        });
      }

      if (reason === 'selectOption') {
        addDays(index, value.name);
      }
    }
  };

  const handleAddStep = () => {
    setVoiceSetup((current) => {
      return {
        ...current,
        workingScheduleGroups: [
          ...current.workingScheduleGroups,
          {
            days: [],
            start: '09:00',
            end: '17:00',
          },
        ],
      };
    });
  };

  const handleDeleteStep = (index: number) => {
    workingScheduleGroupsSetup.splice(index, 1);
    setVoiceSetup((current) => {
      return {
        ...current,
        workingScheduleGroups: workingScheduleGroupsSetup,
      };
    });
  };

  const buildSelectedTime = (index: number, key: 'start' | 'end' = 'start') => {
    const schedule = workingScheduleGroupsSetup[index];
    if (schedule) {
      const [h, m] = schedule[key].split(':');
      return buildDate(new Date(), { hours: +h, minutes: +m });
    }
  };

  const handleSelectTime = (index: number, date: Date, key: 'start' | 'end' = 'start') => {
    if (workingScheduleGroupsSetup[index]) {
      const time = format(date, 'HH:mm');
      if (key === 'start') {
        setVoiceSetup((current) => {
          return {
            ...current,
            workingScheduleGroups: workingScheduleGroupsSetup.map((element, i) => {
              return i === index ? { ...element, start: time } : element;
            }),
          };
        });
      } else {
        setVoiceSetup((current) => {
          return {
            ...current,
            workingScheduleGroups: workingScheduleGroupsSetup.map((element, i) => {
              return i === index ? { ...element, end: time } : element;
            }),
          };
        });
      }
    }
  };

  const days = Object.values(DATE.DAYS.SHORT_NAME).map((day) => ({
    name: day,
    selected: workingScheduleGroupsSetup.some((el) => el.days.includes(day)),
  }));

  const areAllDaysSelected = days.every((el) => el.selected);

  days.unshift(SELECT_ALL_DAYS_OPTION);

  return (
    <Box sx={styles.box10SX}>
      <Box sx={styles.box11SX}>
        <Box sx={styles.box6SX}>
          <Typography sx={styles.typography1SX}>Call Flow Schedule</Typography>
          <Tooltip
            title={
              <Typography sx={styles.typography3SX}>
                Use the call flow options defined below to make changes to suit your business needs.
                We have selected default options that work for most customers.
              </Typography>
            }
          >
            <HelpOutlineIcon color="primary" fontSize="small" />
          </Tooltip>
        </Box>

        <Box display="flex" gap={2} flexDirection="column">
          {workingScheduleGroupsSetup.map((element, index) => {
            return (
              <Box key={index} display="flex" flexDirection="row" gap={2} alignItems="flex-start">
                <Autocomplete
                  multiple
                  size="small"
                  sx={{ width: '50%' }}
                  limitTags={7}
                  options={days}
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  getOptionDisabled={(option) => {
                    //@ts-ignore
                    return option.selected || areAllDaysSelected;
                  }}
                  value={element.days.map((day) => ({ name: day }))}
                  renderInput={(params) => (
                    <TextField {...params} label="Days" placeholder="Type day name" />
                  )}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index: number) => {
                      const { key, ...tagProps } = getTagProps({ index });

                      return (
                        <Chip
                          key={key}
                          size="small"
                          color="primary"
                          label={option.name}
                          {...tagProps}
                        />
                      );
                    });
                  }}
                  onChange={(...eventParams) => handleOnChangeDay(index, ...eventParams)}
                />

                <Box display="flex" gap={2} alignItems="center">
                  <TimePicker
                    label="Start hours"
                    format="hh:mm aa"
                    minutesStep={1}
                    orientation="landscape"
                    views={['hours', 'minutes']}
                    minTime={buildDate(new Date(), { hours: 0, minutes: 1 })}
                    maxTime={buildDate(new Date(), { hours: 23, minutes: 59 })}
                    value={
                      buildSelectedTime(index) || buildDate(new Date(), { hours: 9, minutes: 0 })
                    }
                    onChange={(value) => {
                      if (value && isValid(value)) {
                        handleSelectTime(index, value);
                      }
                    }}

                    // renderInput={(params) => <TextField {...params} size="small" />}
                  />

                  <TimePicker
                    label="End hours"
                    format="hh:mm aa"
                    minutesStep={1}
                    orientation="landscape"
                    views={['hours', 'minutes']}
                    minTime={buildDate(new Date(), { hours: 0, minutes: 1 })}
                    maxTime={buildDate(new Date(), { hours: 23, minutes: 59 })}
                    value={
                      buildSelectedTime(index, 'end') ||
                      buildDate(new Date(), { hours: 17, minutes: 0 })
                    }
                    onChange={(value) => {
                      if (value && isValid(value)) {
                        handleSelectTime(index, value, 'end');
                      }
                    }}
                    // renderInput={(params) => <TextField {...params} size="small" />}
                  />

                  <IconButton color="error" onClick={() => handleDeleteStep(index)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              </Box>
            );
          })}

          <Box display="flex">
            <IconButton color="primary" onClick={handleAddStep}>
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkingSchedule;

import { IThunkExtra } from '@/store/store';

import { IPaginationParams } from '@/interfaces/querying-data';

import { createAsyncThunk } from '@reduxjs/toolkit';

import handleErrors from '@/services/handle-errors';

import * as featureAccessDialogReducer from '@/store/reducers/features-access-dialog';

import * as contactApi from '@/services/api/v1/contact';
import * as bmcApi from '@/services/api/v1/broadcast-campaign';

import { CONTACT_TYPE } from '@/constants/contacts';
import { IGetContactsGroupsQueryParams } from '@/interfaces/contacts-group';

export const fetchContactsInitial = createAsyncThunk(
  'bmc/fetchContactsInitial',
  async (limit: number, thunkAPI) => {
    const response = await contactApi.getByParams({
      limit,
      skip: 0,
      contactType: CONTACT_TYPE.COMPANY,
    });

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchOnLoadMore = createAsyncThunk(
  'bmc/fetchOnLoadMore',
  async (data: IPaginationParams, thunkAPI) => {
    const response = await contactApi.getByParams({
      limit: data.limit,
      skip: data.skip,
      contactType: CONTACT_TYPE.COMPANY,
    });

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchValidateFile = createAsyncThunk(
  'bmc/fetchValidateFile',
  async (data: FormData, thunkAPI) => {
    const response = await bmcApi.validateFile(data);
    if (!response.success) {
      thunkAPI.abort();
      if (response.errorType?.features) {
        const { dispatch } = thunkAPI;
        dispatch(featureAccessDialogReducer.show({ message: response.message }));
        return;
      }
      handleErrors(thunkAPI, response.statusCode, { ...response, barType: 'warning' });
    } else {
      return response.data;
    }
  },
);

export const fetchSearchContact = createAsyncThunk(
  'bmc/fetchSearchContact',
  async (data: string, thunkAPI) => {
    const response = await contactApi.getByParams({
      search: data,
      skip: 0,
      limit: 0,
      contactType: CONTACT_TYPE.COMPANY,
    });

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCreateBroadcastCampaign = createAsyncThunk(
  'bmc/fetchCreateBroadcastCampaign',
  async (data: FormData, thunkAPI) => {
    const response = await bmcApi.create(data);
    if (!response.success) {
      thunkAPI.abort();
      if (response.errorType?.features) {
        const { dispatch } = thunkAPI;
        dispatch(featureAccessDialogReducer.show({ message: response.message }));
        return;
      }
      handleErrors(thunkAPI, response.statusCode, response);
    } else {
      return response.data;
    }
  },
);

export const fetchGetContactsGroupsInitial = createAsyncThunk(
  'bmc/fetchGetContactsGroupsInitial',
  async (data: Pick<IPaginationParams, 'limit'>, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.contactsGroupsApis.getByParams({
      skip: 0,
      ...data,
    });

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchGetLoadMoreContactsGroups = createAsyncThunk(
  'bmc/fetchGetLoadMoreContactsGroups',
  async (data: IPaginationParams, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.contactsGroupsApis.getByParams(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchGetSearchContactsGroups = createAsyncThunk(
  'bmc/fetchGetSearchContactsGroups',
  async (data: Pick<IGetContactsGroupsQueryParams, 'search'>, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.contactsGroupsApis.getByParams({
      ...data,
      skip: 0,
      limit: 0,
    });

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

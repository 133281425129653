import React, { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import TextField from '@mui/material/TextField';

import * as bmcReducer from '@/pages/dashboard/broadcast/campaign/reducer';

const VALIDATION_STR = 'I agree to start the broadcast campaign';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IAlertDialogSlide {
  handleSave: () => void;
}

const AlertDialogSlide = (props: IAlertDialogSlide) => {
  const { handleSave } = props;

  const inputRef = useRef(null);
  const dispatch = useAppDispatch();

  const { showModal, isInputValid } = useAppSelector((state) => state.broadcastCampaign);

  const formik = useFormik({
    initialValues: { input: '' },
    onSubmit: () => {},
  });

  const handleClose = () => {
    dispatch(bmcReducer.setShowModal());
  };

  const handleInput = (input: string) => {
    formik.setFieldValue('input', input, false);
    dispatch(bmcReducer.setIsInputValid(input === VALIDATION_STR));
  };

  useEffect(() => {
    if (showModal) {
      formik.setFieldValue('input', '', false);
      //@ts-ignore
      inputRef.current?.focus();
    }
  }, [showModal]);

  return (
    <div>
      <Dialog open={showModal} TransitionComponent={Transition} keepMounted onClose={handleClose}>
        <DialogTitle>{'Create broadcast campaign'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Type "I agree to start the broadcast campaign" to create broadcast campaign.
          </DialogContentText>
          <TextField
            id="input"
            autoFocus={true}
            autoComplete="off"
            inputRef={inputRef}
            margin="dense"
            fullWidth
            variant="outlined"
            value={formik.values.input}
            onChange={(event) => handleInput(event.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose}>
            Close
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={!isInputValid}
            onClick={() => {
              dispatch(bmcReducer.setShowModal());
              handleSave();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialogSlide;

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

export interface IUseMultiCheckboxSelectActions<T> {
  setSelected: Dispatch<SetStateAction<T[]>>;
  reset: VoidFunction;
  isSelected: (el: T) => boolean;
  isAllOptionHasIndeterminateState: (dataToCompare?: T[]) => boolean;
  handleSelect: (item: T) => void;
  // data must be supplied if selectAllBehavior: 'select-all-displayed'
  handleSelectAll: (checked: boolean, data?: T[]) => void;
  // dataToCompare must be supplied if selectAllBehavior: 'select-all-displayed'
  isAllOptionSelected: (dataToCompare?: T[]) => boolean;
}

interface IUseMultiCheckboxSelectConfig<T> {
  // For now works only with selectAllBehavior="select-all-from-displayed"
  enableReinitializeInitialOptions?: boolean;
  initialOptions?: T[];
  selectAllBehavior: 'select-all-from-displayed' | 'select-all-with-exclude';
  maxCount: number;
  getItemId: (item: T) => string;
}

export interface IUseMultiCheckboxSelectState<T> {
  selected: T[];
  excluded: T[];
  isAllSelected: boolean;
}

export function useMultiCheckboxSelect<T = string>({
  getItemId,
  selectAllBehavior,
  enableReinitializeInitialOptions = false,
  initialOptions,
  ...config
}: IUseMultiCheckboxSelectConfig<T>): [
  IUseMultiCheckboxSelectState<T>,
  IUseMultiCheckboxSelectActions<T>,
] {
  const isSelectAllBehaviorEnabled = selectAllBehavior === 'select-all-with-exclude';

  const [selected, setSelected] = useState<T[]>(initialOptions ?? []);
  const [isAllOptionSelected, setIsAllOptionSelected] = useState<boolean>(false);
  const [excluded, setExcluded] = useState<T[]>([]);

  const isAllOptionSelectedInitializedRef = useRef<boolean>(false);

  const isAllSelected = (currentlyDisplayedData?: T[]): boolean => {
    if (!config.maxCount) {
      return false;
    }

    if (isSelectAllBehaviorEnabled) {
      return isAllOptionSelected;
    }

    return (
      selected.length === config.maxCount ||
      (currentlyDisplayedData as T[]).every((el) =>
        selected.some((selectedEl) => getItemId(selectedEl) === getItemId(el)),
      )
    );
  };

  const isAllOptionHasIndeterminateState = (currentlyDisplayedData?: T[]): boolean => {
    if (isSelectAllBehaviorEnabled) {
      return isAllOptionSelected && !!excluded.length;
    }

    return (
      selected.length > 0 &&
      selected.length < config.maxCount &&
      !(currentlyDisplayedData as T[]).every((el) =>
        selected.some((selectedEl) => getItemId(selectedEl) === getItemId(el)),
      )
    );
  };

  const handleSelectAll = (checked: boolean, currentlyDisplayedData?: T[]): void => {
    if (isSelectAllBehaviorEnabled) {
      if (!checked) {
        setExcluded([]);
      }

      setIsAllOptionSelected(checked);
      setSelected([]);

      return;
    }

    if (checked) {
      setSelected((prevState) =>
        prevState.length
          ? [
              ...prevState,
              ...(currentlyDisplayedData as T[]).filter(
                (el) =>
                  !prevState.some((prevStateElEl) => getItemId(prevStateElEl) === getItemId(el)),
              ),
            ]
          : (currentlyDisplayedData as T[]),
      );

      return;
    }

    setSelected((prevState) =>
      prevState.filter(
        (el) =>
          !(currentlyDisplayedData as T[]).some(
            (currentlyDisplayedDataEl) => getItemId(currentlyDisplayedDataEl) === getItemId(el),
          ),
      ),
    );
  };

  const handleSelect = (item: T): void => {
    let array: T[];
    let setArray: Dispatch<SetStateAction<T[]>>;

    if (isSelectAllBehaviorEnabled && isAllOptionSelected) {
      array = excluded;
      setArray = setExcluded;
    } else {
      array = selected;
      setArray = setSelected;
    }

    const selectedIndex = array.findIndex((el) => getItemId(el) === getItemId(item));

    let newSelected: T[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(array, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(array.slice(1));
    } else if (selectedIndex === array.length - 1) {
      newSelected = newSelected.concat(array.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        array.slice(0, selectedIndex),
        array.slice(selectedIndex + 1),
      );
    }

    setArray(newSelected);
  };

  const isSelected = (item: T): boolean => {
    if (isSelectAllBehaviorEnabled) {
      return isAllOptionSelected
        ? !excluded.some((el) => getItemId(el) === getItemId(item))
        : selected.some((el) => getItemId(el) === getItemId(item));
    } else {
      return selected.some((el) => getItemId(el) === getItemId(item));
    }
  };

  const reset = (): void => {
    setSelected(initialOptions ?? []);
  };

  useEffect(() => {
    /* 
      Need to wait for maxCount since it is async operation 
      so when it is available we will mark as initialized
    */
    if (
      isSelectAllBehaviorEnabled &&
      initialOptions?.length &&
      config.maxCount &&
      !isAllOptionSelectedInitializedRef.current
    ) {
      setIsAllOptionSelected(config.maxCount === initialOptions.length);
      isAllOptionSelectedInitializedRef.current = true;
    }
  }, [config.maxCount]);

  useEffect(() => {
    if (!isSelectAllBehaviorEnabled && enableReinitializeInitialOptions) {
      reset();
    }
  }, [initialOptions]);

  const actions: IUseMultiCheckboxSelectActions<T> = {
    setSelected,
    isSelected,
    handleSelectAll,
    handleSelect,
    isAllOptionHasIndeterminateState,
    isAllOptionSelected: isAllSelected,
    reset,
  };

  return [{ selected, excluded, isAllSelected: isAllOptionSelected }, actions];
}

import makeRequest from '../make-request';
import token from '../token';

import {
  IChatResult,
  IChatCreateRequest,
  IGetOneChatByRelativeInfo,
  IGetChatsQueryParams,
  IChatUpdateMarksRequest,
} from '@/interfaces/chat';

import { avoidEmptyStringValue } from '@/helpers/body';

import CHAT from '@/constants/chat';

const GET_ALL = '/api/v1/chat';
const GET_BY_ID = '/api/v1/chat/$chatId';
const CREATE_CHAT = '/api/v1/chat';
const GET_ONE_CHAT_BY_RELATIVE_INFO = '/api/v1/chat/related-info';
const SEARCH = '/api/v1/chat/search';
const FIND_BY_RECEIVER_COMPANY_EXT = '/api/v1/chat/receiver-ext/$companyExtension';
const GET_UPDATE_MARKS_URL = (id: string) => `/api/v1/chat/${id}/marks`;
const GET_CHAT_BY_IOT_DEVICE_ID = (iotDeviceId: string) =>
  `/api/v1/chat/get-by-iot-device-id/${iotDeviceId}`;

export const getByParams = async (data: IGetChatsQueryParams) => {
  const queryParams: Record<string, string | string[]> = {
    limit: data.limit.toString(),
    skip: data.skip.toString(),
  };

  if (data.variant) {
    queryParams.variant = data.variant;
  }
  if (data.platform && data.platform !== CHAT.PLATFORM.ANY) {
    queryParams.platform = data.platform;
  }

  if (data.marks?.length) {
    data.marks.forEach((el, i) => {
      queryParams[`marks[${i}]`] = el as string;
    });
  }

  return makeRequest(GET_ALL, {
    method: 'GET',
    headers: { Authorization: await token() },
    params: queryParams,
  });
};

export const getById = async (id: IChatResult['id']) => {
  try {
    const result = await makeRequest(GET_BY_ID.replace('$chatId', id), {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const createChat = async (data: IChatCreateRequest) => {
  try {
    const result = await makeRequest(CREATE_CHAT, {
      method: 'POST',
      headers: { Authorization: await token() },
      data: avoidEmptyStringValue(data),
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateMarks = async (id: string, body: IChatUpdateMarksRequest) => {
  return makeRequest(GET_UPDATE_MARKS_URL(id), {
    method: 'POST',
    headers: { Authorization: await token() },
    data: body,
  });
};

export const getOneByRelativeInfo = async (data: IGetOneChatByRelativeInfo) => {
  return makeRequest(GET_ONE_CHAT_BY_RELATIVE_INFO, {
    method: 'GET',
    headers: { Authorization: await token() },
    params: data,
  });
};

export const search = async (data: Pick<IGetChatsQueryParams, 'variant'> & { search: string }) => {
  try {
    const result = await makeRequest(SEARCH, {
      method: 'GET',
      headers: { Authorization: await token() },
      params: {
        search: encodeURIComponent(data.search),
        variant: encodeURIComponent(data.variant ?? CHAT.VARIANT.CLASSIC),
      },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const findByReceiverCompanyExt = async (companyExtension: string) => {
  try {
    const result = await makeRequest(
      FIND_BY_RECEIVER_COMPANY_EXT.replace('$companyExtension', companyExtension),
      {
        method: 'GET',
        headers: { Authorization: await token() },
      },
    );

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getByIotDeviceId = async (iotDeviceId: string) => {
  return makeRequest(GET_CHAT_BY_IOT_DEVICE_ID(iotDeviceId), {
    method: 'GET',
    headers: { Authorization: await token() },
  });
};

import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';

import { IPaginationParams, IPartialResult } from '@/interfaces/querying-data';
import {
  IContact,
  IContactImportHistoryResult,
  IGetContactsQueryParams,
} from '@/interfaces/contact';

import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import { format } from '@/helpers/date/date';

import * as contactsThunk from '@/pages/dashboard/contacts/thunk';

import {
  IUseMultiCheckboxSelectState,
  useMultiCheckboxSelect,
} from '@/hooks/use-multi-checkbox-select';
import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';

import DataTemplate from '@/components/Data-template/Data-template';

import { defStyles } from '@/theme';

import { CONTACT_TYPE } from '@/constants/contacts';
import { DATE_FORMAT_LONG } from '@/constants/layout';

interface ISelectContactsFromListDialogProps {
  initialContacts: IContact[];
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (response: IUseMultiCheckboxSelectState<IContact> | null) => void;
}

const SelectContactsFromListDialog: FunctionComponent<ISelectContactsFromListDialogProps> = ({
  initialContacts,
  isOpened,
  setIsOpened,
  handleSubmit,
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const [groupedBy, setGroupedBy] = useState<string>('contacts');

  const [contactsQueryParams, setContactsQueryParams] = useState<IGetContactsQueryParams>({
    search: '',
    contactType: CONTACT_TYPE.COMPANY,
    skip: 0,
    limit: 50,
  });

  const [importHistoryQueryParams, setImportHistoryQueryParams] = useState<IPaginationParams>({
    skip: 0,
    limit: 50,
  });

  const [contactsResult, setContactsResult] = useState<IPartialResult<IContact>>({
    maxCount: 0,
    records: [],
  });

  const [importHistoryResult, setImportHistoryResult] = useState<
    IPartialResult<IContactImportHistoryResult>
  >({
    maxCount: 0,
    records: [],
  });

  const [contactsSelection, selectedContactsActions] = useMultiCheckboxSelect<IContact>({
    selectAllBehavior: 'select-all-with-exclude',
    initialOptions: initialContacts,
    getItemId: (el) => el.id as string,
    maxCount: contactsResult.maxCount,
  });

  const [importHistorySelection, importHistorySelectionActions] =
    useMultiCheckboxSelect<IContactImportHistoryResult>({
      selectAllBehavior: 'select-all-from-displayed',
      initialOptions: [],
      getItemId: (el) => el.importedAt.toString(),
      maxCount: importHistoryResult.maxCount,
    });

  const [isLoading, setIsLoading] = useState(false);

  const loadContacts = async () => {
    setIsLoading(true);

    const { payload } = await dispatch(contactsThunk.fetchGetContacts(contactsQueryParams));

    if (payload) {
      setContactsResult(payload as IPartialResult<IContact>);
    }

    setIsLoading(false);
  };

  const loadContactImportHistory = async () => {
    setIsLoading(true);

    const { payload } = await dispatch(
      contactsThunk.fetchGetImportHistory(importHistoryQueryParams),
    );

    if (payload) {
      setImportHistoryResult(payload);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoading && groupedBy === 'contacts') {
      loadContacts();
    }
  }, [
    groupedBy,
    contactsQueryParams.search,
    contactsQueryParams.contactType,
    contactsQueryParams.skip,
    contactsQueryParams.limit,
  ]);

  useEffect(() => {
    if (!isLoading && groupedBy === 'imported-at') {
      loadContactImportHistory();
    }
  }, [groupedBy, importHistoryQueryParams.skip, importHistoryQueryParams.limit]);

  const handleClose = (response: IUseMultiCheckboxSelectState<IContact> | null) => {
    setIsOpened(false);
    handleSubmit(response);
  };

  return (
    <Dialog keepMounted fullWidth maxWidth="sm" open={isOpened} onClose={() => handleClose(null)}>
      <DialogTitle>
        <Stack spacing={1}>
          <Typography htmlFor="group-by" component="label">
            Group contacts by
          </Typography>

          <ToggleButtonGroup
            exclusive
            fullWidth
            size="extra-small"
            value={groupedBy}
            onChange={(event, value) => {
              if (value) {
                setGroupedBy(value);
              }
            }}
          >
            <ToggleButton value="contacts">Existing contacts</ToggleButton>
            <ToggleButton value="imported-at">Import date</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <Stack spacing={2}>
          {groupedBy === 'contacts' ? (
            <>
              <TextField
                fullWidth
                type="search"
                placeholder="Search"
                variant="outlined"
                size="small"
                value={contactsQueryParams.search}
                onChange={(e) =>
                  setContactsQueryParams((prevState) => ({ ...prevState, search: e.target.value }))
                }
                onBlur={(e) =>
                  setContactsQueryParams((prevState) => ({ ...prevState, search: e.target.value }))
                }
              />

              <DataTemplate
                isLoading={isLoading}
                pagination={{
                  maxCount: contactsResult.maxCount,
                  paginationData: {
                    skip: contactsQueryParams.skip,
                    limit: contactsQueryParams.limit,
                  },
                  onPageChange: (paginationData) =>
                    setContactsQueryParams((prevState) => ({
                      ...prevState,
                      ...paginationData,
                    })),
                  onRowsPerPageChange: (paginationData) =>
                    setContactsQueryParams((prevState) => ({
                      ...prevState,
                      ...paginationData,
                    })),
                }}
              >
                <List
                  dense
                  disablePadding
                  sx={[
                    {
                      position: 'relative',
                      height: '50vh',
                      overflow: 'auto',
                    },
                    defStyles.scroll,
                  ]}
                >
                  <ListItem
                    disablePadding
                    disableGutters
                    dense
                    sx={{
                      position: 'sticky',
                      top: 0,
                      bgcolor: 'common.white',
                      zIndex: 2,
                      borderColor: 'secondary.light',
                    }}
                    secondaryAction={
                      <Checkbox
                        tabIndex={-1}
                        disableRipple
                        indeterminate={selectedContactsActions.isAllOptionHasIndeterminateState(
                          contactsResult.records,
                        )}
                        checked={selectedContactsActions.isAllOptionSelected(
                          contactsResult.records,
                        )}
                        inputProps={{ 'aria-labelledby': 'all-contacts-selected-btn' }}
                        onChange={(event, checked) =>
                          selectedContactsActions.handleSelectAll(checked)
                        }
                      />
                    }
                  >
                    <ListItemButton
                      disableRipple
                      role="checkbox"
                      onClick={() => {
                        const currentlyChecked = selectedContactsActions.isAllOptionSelected();
                        selectedContactsActions.handleSelectAll(!currentlyChecked);
                      }}
                    >
                      <ListItemText id="all-contacts-selected-btn" primary="Select all" />
                    </ListItemButton>
                  </ListItem>

                  {contactsResult.records.map((el) => {
                    const labelId = `contacts-checkbox-list-label-${el.id}`;

                    return (
                      <ListItem
                        key={el.id}
                        disablePadding
                        disableGutters
                        dense
                        secondaryAction={
                          <Checkbox
                            edge="start"
                            checked={selectedContactsActions.isSelected(el)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                            onChange={() => selectedContactsActions.handleSelect(el)}
                          />
                        }
                      >
                        <ListItemButton
                          role="checkbox"
                          onClick={() => selectedContactsActions.handleSelect(el)}
                        >
                          <ListItemAvatar>
                            <Avatar src={el.avatar?.url || DefaultAvatar} />
                          </ListItemAvatar>

                          <ListItemText
                            id={labelId}
                            primary={el.fullName}
                            secondary={el.phoneNumber}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </DataTemplate>
            </>
          ) : null}

          {groupedBy === 'imported-at' ? (
            <DataTemplate
              isLoading={isLoading}
              pagination={{
                maxCount: importHistoryResult.maxCount,
                paginationData: {
                  skip: importHistoryQueryParams.skip,
                  limit: importHistoryQueryParams.limit,
                },
                onPageChange: (paginationData) =>
                  setImportHistoryQueryParams((prevState) => ({
                    ...prevState,
                    ...paginationData,
                  })),
                onRowsPerPageChange: (paginationData) =>
                  setImportHistoryQueryParams((prevState) => ({
                    ...prevState,
                    ...paginationData,
                  })),
              }}
            >
              <List
                dense
                disablePadding
                sx={{
                  position: 'relative',
                  height: '50vh',
                  overflow: 'auto',
                }}
              >
                {importHistoryResult.records.map((el) => {
                  const labelId = `contacts-checkbox-list-label-${el.importedAt}`;
                  const primaryText =
                    'Contacts imported at ' + format(el.importedAt, DATE_FORMAT_LONG);
                  const secondaryText =
                    el.contacts
                      .slice(0, 3)
                      .map((el) => el.fullName)
                      .join(', ') +
                    (el.contacts.length > 3 ? ` and ${el.contacts.length - 3} more` : '');

                  return (
                    <ListItem
                      key={el.importedAt.toString()}
                      disablePadding
                      disableGutters
                      dense
                      secondaryAction={
                        <Checkbox
                          edge="start"
                          checked={importHistorySelectionActions.isSelected(el)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          onChange={() => importHistorySelectionActions.handleSelect(el)}
                        />
                      }
                    >
                      <ListItemButton
                        role="checkbox"
                        onClick={() => importHistorySelectionActions.handleSelect(el)}
                      >
                        <ListItemText
                          id={labelId}
                          primary={primaryText}
                          secondary={secondaryText}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </DataTemplate>
          ) : null}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          color="error"
          size="medium"
          disabled={isLoading}
          onClick={() => setIsOpened(false)}
        >
          Cancel
        </Button>

        <Button
          disableElevation
          color="primary"
          variant="contained"
          size="medium"
          disabled={isLoading}
          onClick={() => {
            if (groupedBy === 'contacts') {
              handleClose(contactsSelection);
            } else {
              handleClose({
                excluded: [],
                isAllSelected: false,
                selected: importHistorySelection.selected.map((el) => el.contacts).flat(),
              });
            }
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectContactsFromListDialog;

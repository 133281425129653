import {
  ISignUpTenant,
  ITenantCardsMakeDefaultRequest,
  ITenantCompanyMsgsCallsGroupRequest,
  ITenantSubsCalculatePriceRequest,
  ITenantSubsPreOrderRequest,
  ITenantSubsProceedPaymentRequest,
  ITenantSubsPurchaseRequest,
  ITenantSubsQuantityCalculateRequest,
  ITenantSubsUpdateQuantityRequest,
  ITenantSetTimeZoneRequest,
  ITenantSubsSetTopUpRequest,
} from '@/interfaces/tenant';

import makeRequest from '../make-request';

import token from '../token';

const GET_TENANT_INFO = '/api/v1/tenant/info';
const SIGN_UP = '/api/v1/tenant/sign-up';

const SUBS_PRE_ORDER = '/api/v1/tenant/subs/pre-order';
const SUBS_QUANTITY_CALCULATE = '/api/v1/tenant/subs/quantity-calculate';
const SUBS_UPDATE_QUANTITY = '/api/v1/tenant/subs/update-quantity';
const CALCULATE_SUBS_PRICE = '/api/v1/tenant/subs/calculate-price';
const PURCHASE_SUBS = '/api/v1/tenant/subs/purchase';
const SUBS_CHECK_PAYMENT = '/api/v1/tenant/subs/check-payment';
const SUBS_TOP_UP_BALANCE = '/api/v1/tenant/subs/top-up-balance';
const SUBS_PROCEED_PAYMENT = '/api/v1/tenant/subs/proceed-payment';
const SUBS_SET_TOP_UP = '/api/v1/tenant/subs/top-up';

const GET_CREDIT_CARDS = '/api/v1/tenant/cards';
const ADD_CREDIT_CARD = '/api/v1/tenant/cards';
const MAKE_CREDIT_CARD_DEFAULT = '/api/v1/tenant/cards/make-default';

const SET_COMPANY_MSGS_CALLS_GROUP = '/api/v1/tenant/company-msgs-calls-group';
const GET_COMPANY_MSGS_CALLS_GROUP = '/api/v1/tenant/company-msgs-calls-group';
const TURN_ON_OFF_CALLS_RECORDING = '/api/v1/tenant/calls-recordings';
const TURN_ON_OFF_LIVE_CALLS_TRANSCRIPTION = '/api/v1/tenant/live-calls-transcription';
const TURN_ON_OFF_VOICEMAIL_TRANSCRIPTION = '/api/v1/tenant/voicemail-transcription';
const SET_TIME_ZONE = '/api/v1/tenant/time-zone';

export const getTenantInfo = async () => {
  try {
    const result = await makeRequest(GET_TENANT_INFO, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const signUpTenant = async (data: ISignUpTenant) => {
  try {
    const result = await makeRequest(SIGN_UP, {
      method: 'POST',
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const subsPreOrder = async (data: ITenantSubsPreOrderRequest) => {
  try {
    const result = await makeRequest(SUBS_PRE_ORDER, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const subsQuantityCalculate = async (data: ITenantSubsQuantityCalculateRequest) => {
  try {
    const result = await makeRequest(SUBS_QUANTITY_CALCULATE, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateSubsQuantity = async (data: ITenantSubsUpdateQuantityRequest) => {
  try {
    const result = await makeRequest(SUBS_UPDATE_QUANTITY, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const calculateSubsPrice = async (data: ITenantSubsCalculatePriceRequest) => {
  try {
    const result = await makeRequest(CALCULATE_SUBS_PRICE, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getCreditCards = async () => {
  try {
    const result = await makeRequest(GET_CREDIT_CARDS, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const addCreditCards = async () => {
  try {
    const result = await makeRequest(ADD_CREDIT_CARD, {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const makeCreditCardDefault = async (data: ITenantCardsMakeDefaultRequest) => {
  try {
    const result = await makeRequest(MAKE_CREDIT_CARD_DEFAULT, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const purchaseSubs = async (data: ITenantSubsPurchaseRequest) => {
  try {
    const result = await makeRequest(PURCHASE_SUBS, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const subsCheckPayment = async () => {
  try {
    const result = await makeRequest(SUBS_CHECK_PAYMENT, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const subsProceedPayment = async (data: ITenantSubsProceedPaymentRequest) => {
  try {
    const result = await makeRequest(SUBS_PROCEED_PAYMENT, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getCompanyMsgsCallsGroup = async () => {
  try {
    const result = await makeRequest(GET_COMPANY_MSGS_CALLS_GROUP, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const setCompanyMsgsCallsGroup = async (data: ITenantCompanyMsgsCallsGroupRequest) => {
  try {
    const result = await makeRequest(SET_COMPANY_MSGS_CALLS_GROUP, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const turnOnOffCallsRecordings = async () => {
  try {
    const result = await makeRequest(TURN_ON_OFF_CALLS_RECORDING, {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const turnOnOffLiveCallsTranscription = async () => {
  try {
    const result = await makeRequest(TURN_ON_OFF_LIVE_CALLS_TRANSCRIPTION, {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const turnOnOffVoicemailTranscription = async () => {
  try {
    const result = await makeRequest(TURN_ON_OFF_VOICEMAIL_TRANSCRIPTION, {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const setTimeZone = async (data: ITenantSetTimeZoneRequest) => {
  try {
    const result = await makeRequest(SET_TIME_ZONE, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const topUpBalance = async () => {
  return makeRequest(SUBS_TOP_UP_BALANCE, {
    method: 'POST',
    headers: { Authorization: await token() },
  });
};

export const setTopUp = async (data: ITenantSubsSetTopUpRequest) => {
  return makeRequest(SUBS_SET_TOP_UP, {
    method: 'POST',
    headers: { Authorization: await token() },
    data,
  });
};

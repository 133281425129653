import { createAsyncThunk } from '@reduxjs/toolkit';

import handleErrors from '@/services/handle-errors';

import * as contactApi from '@/services/api/v1/contact';
import * as voiceApi from '@/services/api/v1/voice';
import * as extensionsGroupsApi from '@/services/api/v1/extensions-groups';

import { IThunkExtra } from '@/store/store';
import { IGetContactsQueryParams } from '@/interfaces/contact';
import { IPaginationParams } from '@/interfaces/querying-data';

export const fetchExtensionGroupById = createAsyncThunk(
  'voice/fetchExtensionGroup',
  async (id: string, thunkAPI) => {
    const response = await extensionsGroupsApi.getById(id);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchContactsInitial = createAsyncThunk(
  'voice/fetchContactsInitial',
  async (data: Required<Pick<IGetContactsQueryParams, 'limit' | 'contactType'>>, thunkAPI) => {
    const response = await contactApi.getByParams({
      ...data,
      skip: 0,
    });

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSearchContact = createAsyncThunk(
  'voice/fetchSearchContact',
  async (data: Required<Pick<IGetContactsQueryParams, 'limit' | 'contactType'>>, thunkAPI) => {
    const response = await contactApi.getByParams({
      ...data,
      skip: 0,
      limit: 0,
    });

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchOnLoadMore = createAsyncThunk(
  'voice/fetchOnLoadMore',
  async (
    data: Required<Pick<IGetContactsQueryParams, 'limit' | 'skip' | 'contactType'>>,
    thunkAPI,
  ) => {
    const response = await contactApi.getByParams(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchTwilioAccessToken = createAsyncThunk(
  'voice/fetchTwilioAccessToken',
  async (data, thunkAPI) => {
    const response = await voiceApi.getTwilioAccessToken();
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCallByProviderCallId = createAsyncThunk(
  'voice/fetchCallByProviderCallId',
  async (data: string, thunkAPI) => {
    const response = await voiceApi.getCallByProviderCallId(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchPutCallOnHold = createAsyncThunk(
  'voice/fetchPutCallOnHold',
  async (data: string, thunkAPI) => {
    const response = await voiceApi.putCallOnHold(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchResumeCall = createAsyncThunk(
  'voice/fetchResumeCall',
  async (data: string, thunkAPI) => {
    const response = await voiceApi.resumeCall(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCancelCall = createAsyncThunk(
  'voice/fetchCancelCall',
  async (data: string, thunkAPI) => {
    const response = await voiceApi.cancelCall(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchForwardCall = createAsyncThunk(
  'voice/fetchForwardCall',
  async (data: { uuid: string; to: string }, thunkAPI) => {
    const response = await voiceApi.forwardCall(data.uuid, data.to);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCallsHistoryInitial = createAsyncThunk(
  'voice/fetchCallsHistoryInitial',
  async (data: { skip: number; limit: number }, thunkAPI) => {
    const response = await voiceApi.getCallsHistory(data.skip, data.limit);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCallsHistory = createAsyncThunk(
  'voice/fetchCallsHistory',
  async (data: { skip: number; limit: number }, thunkAPI) => {
    const response = await voiceApi.getCallsHistory(data.skip, data.limit);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSetAnsweredBy = createAsyncThunk(
  'voice/fetchSetAnsweredBy',
  async (data: { uuid: string; userId: string }, thunkAPI) => {
    const response = await voiceApi.setAnsweredBy(data.uuid, { userId: data.userId });
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchUserByCompanyExtension = createAsyncThunk(
  'voice/fetchUserByCompanyExtension',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.getByCompanyExtension(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchGetVoicemails = createAsyncThunk(
  'voice/fetchGetVoicemails',
  async (data: IPaginationParams, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.voiceApis.getVoicemails(data.skip, data.limit);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

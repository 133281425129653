import { Outlet } from 'react-router-dom';

import { useState } from 'react';

import { Box } from '@mui/material';

import useRedirectToPreviousLocation from '@/hooks/use-redirect-to-previous-location';
import useThemeBreakpoints from '@/hooks/use-theme-breakpoints';

import PrivateLayoutHeader from '@/components/Private-layout/Private-layout-header';
import PrivateLayoutFooter from '@/components/Private-layout/Private-layout-footer';
import DashboardSideMenu from '@/pages/dashboard/Dashboard-side-menu';
import ClickToCallAuthorizedHandler from '@/components/Click-to-call/Click-to-call-authorized-handler/Click-to-call-authorized-handler';

const DashboardLayout = () => {
  const [isSideMenuOpened, setIsSideMenuOpened] = useState(false);

  const isSmOrDown = useThemeBreakpoints('down', 'sm');

  useRedirectToPreviousLocation();

  return (
    <Box display="flex" justifyContent="space-between" flexDirection="column" minHeight="100vh">
      <ClickToCallAuthorizedHandler />

      <PrivateLayoutHeader
        isSideMenuOpened={isSideMenuOpened}
        setIsSideMenuOpened={setIsSideMenuOpened}
        showSideMenu={isSmOrDown}
      />

      <Box display="flex">
        <DashboardSideMenu
          isSideMenuOpened={isSideMenuOpened}
          setIsSideMenuOpened={setIsSideMenuOpened}
        />

        <Box
          display="flex"
          flex={1}
          sx={{
            // 128px because it is height of AppBar and Footer components combined
            height: 'calc(100vh - 128px)',
            overflow: 'auto',
          }}
        >
          <Outlet />
        </Box>
      </Box>

      <PrivateLayoutFooter />
    </Box>
  );
};

export default DashboardLayout;

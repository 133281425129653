export const validateForm = async (initialValues: { [key: string]: any }, formik: any) => {
  const touched = Object.keys(initialValues).reduce(
    (acc: { [key: string]: boolean }, key: string) => {
      acc[key] = true;
      return acc;
    },
    {},
  );
  formik.setTouched(touched);

  const errors = await formik.validateForm(formik.values);
  formik.setErrors(errors);
  if (!errors) {
    return true;
  }
};

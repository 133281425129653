import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ISocketInitial {
  isSocketConnected: boolean;
}

const initialState: ISocketInitial = {
  isSocketConnected: false,
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setIsSocketConnected: (state, action: PayloadAction<boolean>) => {
      state.isSocketConnected = action.payload;
    },
  },
});

export const { setIsSocketConnected } = socketSlice.actions;

export default socketSlice.reducer;

import { FunctionComponent, ReactElement } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  Button,
  CircularProgress,
  DialogActionsProps,
  ButtonProps,
  DialogContentText,
} from '@mui/material';

export interface ITemplateDialogProps<SubmitResponse = true> {
  isOpened: boolean;
  title: string;
  handleClose: (value: SubmitResponse | false | null) => void;
  textContent?: string;
  returnValueOnSubmit?: () => SubmitResponse;
  getChildren?: (
    actions: Pick<ITemplateDialogProps<SubmitResponse | false | null>, 'handleClose'>,
  ) => ReactElement;
  showCancelButton?: boolean;
  showCloseButton?: boolean;
  isLoading?: boolean;
  dialogProps?: Omit<DialogProps, 'open'>;
  dialogTitleProps?: DialogTitleProps;
  dialogContentProps?: DialogContentProps;
  dialogActionsProps?: DialogActionsProps;
  closeButtonProps?: ButtonProps;
  submitButtonProps?: ButtonProps;
}

const TemplateDialog: FunctionComponent<ITemplateDialogProps<any>> = ({
  isOpened,
  title,
  getChildren,
  textContent,
  isLoading = false,
  showCloseButton = true,
  showCancelButton = true,
  handleClose,
  returnValueOnSubmit = () => true,
  dialogProps = {},
  dialogTitleProps = {},
  dialogContentProps = {},
  dialogActionsProps = {},
  closeButtonProps = {},
  submitButtonProps = {},
}): JSX.Element => {
  const submitButtonLabel = submitButtonProps?.children ? submitButtonProps.children : 'Submit';
  const closeButtonLabel = closeButtonProps?.children ? closeButtonProps.children : 'Close';

  return (
    <Dialog fullWidth maxWidth="xs" {...dialogProps} open={isOpened}>
      <DialogTitle {...dialogTitleProps}>{title}</DialogTitle>

      <DialogContent {...dialogContentProps}>
        {textContent ? <DialogContentText>{textContent}</DialogContentText> : null}
        {getChildren ? getChildren({ handleClose }) : null}
      </DialogContent>

      <DialogActions {...dialogActionsProps}>
        {showCancelButton && (
          <TemplateDialogButton color="error" variant="outlined" onClick={() => handleClose(null)}>
            Cancel
          </TemplateDialogButton>
        )}

        {showCloseButton && (
          <TemplateDialogButton
            color="error"
            variant="outlined"
            {...closeButtonProps}
            disabled={isLoading}
            onClick={() => handleClose(false)}
          >
            {closeButtonLabel}
          </TemplateDialogButton>
        )}

        <TemplateDialogButton
          color="primary"
          disabled={isLoading}
          onClick={() => handleClose(returnValueOnSubmit())}
          {...submitButtonProps}
        >
          {isLoading ? <CircularProgress color="primary" size={20} /> : submitButtonLabel}
        </TemplateDialogButton>
      </DialogActions>
    </Dialog>
  );
};

const ErrorMessageDialog = ({
  isOpened,
  handleClose,
  textContent,
}: Pick<ITemplateDialogProps, 'isOpened' | 'textContent' | 'handleClose'>) => {
  return (
    <TemplateDialog
      isOpened={isOpened}
      title="Error occured"
      handleClose={handleClose}
      showCloseButton={false}
      textContent={textContent}
      dialogTitleProps={{ color: 'error' }}
      submitButtonProps={{ color: 'secondary', children: 'Close' }}
    />
  );
};

const SuccessMessageDialog = ({
  isOpened,
  handleClose,
  textContent,
}: Pick<ITemplateDialogProps, 'isOpened' | 'textContent' | 'handleClose'>) => {
  return (
    <TemplateDialog
      isOpened={isOpened}
      title="Success"
      showCloseButton={false}
      handleClose={handleClose}
      textContent={textContent}
      dialogTitleProps={{ color: 'success.main', fontWeight: '600' }}
    />
  );
};

const WarningMessageDialog = ({
  isOpened,
  handleClose,
  textContent,
  submitButtonText = 'Close',
  closeButtonText,
}: Pick<ITemplateDialogProps, 'isOpened' | 'textContent' | 'handleClose'> &
  Partial<Record<'submitButtonText' | 'closeButtonText', string>>) => {
  return (
    <TemplateDialog
      isOpened={isOpened}
      title="Warning"
      handleClose={handleClose}
      textContent={textContent}
      dialogTitleProps={{ color: 'warning.main', fontWeight: '600' }}
      submitButtonProps={{ children: submitButtonText }}
      showCloseButton={!!closeButtonText}
      closeButtonProps={{ children: closeButtonText }}
    />
  );
};

const InfoMessageDialog = ({
  isOpened,
  handleClose,
  textContent,
  submitButtonText = 'Close',
}: Pick<ITemplateDialogProps, 'isOpened' | 'textContent' | 'handleClose'> &
  Partial<Record<'submitButtonText', string>>) => {
  return (
    <TemplateDialog
      isOpened={isOpened}
      title="Attention"
      handleClose={handleClose}
      textContent={textContent}
      dialogTitleProps={{ color: 'primary.main', fontWeight: '600' }}
      submitButtonProps={{ children: submitButtonText }}
      showCloseButton={false}
    />
  );
};

const TemplateDialogButton: FunctionComponent<ButtonProps> = (props): JSX.Element => (
  <Button {...props} disableElevation variant="contained" sx={{ minWidth: '7rem' }} />
);

export {
  TemplateDialog,
  ErrorMessageDialog,
  SuccessMessageDialog,
  WarningMessageDialog,
  InfoMessageDialog,
  TemplateDialogButton,
};

import * as broadcastCampaignApis from '@/services/api/v1/broadcast-campaign';
import * as chatsApis from '@/services/api/v1/chat';
import * as contactsApis from '@/services/api/v1/contact';
import * as extensionGroupsApis from '@/services/api/v1/extensions-groups';
import * as featuresComparisonsApis from '@/services/api/v1/features-comparison';
import * as filesApis from '@/services/api/v1/file';
import * as messagesApis from '@/services/api/v1/message';
import * as tenantsApis from '@/services/api/v1/tenant';
import * as timeZonesApis from '@/services/api/v1/time-zone';
import * as usersApis from '@/services/api/v1/user';
import * as userLocationsApis from '@/services/api/v1/user-location';
import * as voiceApis from '@/services/api/v1/voice';
import * as voiceSettingsApis from '@/services/api/v1/voice-settings';
import * as subscriptionsApis from '@/services/api/v1/subscriptions';
import * as phoneNumbersApis from '@/services/api/v1/phone-numbers';
import * as countriesApis from '@/services/api/v1/countries';
import * as permissionsApis from '@/services/api/v1/permissions';
import * as countryCodesApis from '@/services/api/v1/country-codes';
import * as ipsApis from '@/services/api/v1/ips';
import * as twoFAApis from '@/services/api/v1/2fa';
import * as iotDevicesApis from '@/services/api/v1/iot-devices';
import * as usersGroupsApis from '@/services/api/v1/users-groups';
import * as iotDevicesGroupsApis from '@/services/api/v1/iot-devices-groups';
import * as analyticsApis from '@/services/api/v1/analytics';

import promoCodesApis from '@/services/api/v1/promo-codes';
import contactsGroupsApis from '@/services/api/v1/contacts-group';

export default {
  broadcastCampaignApis,
  chatsApis,
  contactsApis,
  extensionGroupsApis,
  featuresComparisonsApis,
  filesApis,
  messagesApis,
  tenantsApis,
  timeZonesApis,
  usersApis,
  userLocationsApis,
  voiceApis,
  voiceSettingsApis,
  subscriptionsApis,
  phoneNumbersApis,
  countriesApis,
  promoCodesApis,
  contactsGroupsApis,
  permissionsApis,
  countryCodesApis,
  ipsApis,
  twoFAApis,
  iotDevicesApis,
  usersGroupsApis,
  iotDevicesGroupsApis,
  analyticsApis,
};

import { useEffect, useState } from 'react';
import { useAppSelector } from '@/store/hooks';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, ListItem, Tooltip } from '@mui/material';

import { IConnection } from '@/interfaces/voice/call';

import { format } from '@/helpers/date/date';

import CustomAvatar from '@/components/Custom-avatar/Avatar';
import LiveTranscriptionDialog from '../../dialogs/Live-transcription-dialog';

import useAccess from '@/hooks/use-access';

import { ADDONS } from '@/constants/addons';
import CALL from '@/constants/voice/voice';

const LiveTranscription = () => {
  const { isAddonAvailable } = useAccess();

  const [connection, setConnection] = useState<IConnection>();
  const [showTranscription, setShowTranscription] = useState<boolean>(true);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const { connections } = useAppSelector((state) => state.voice);
  const { tenant } = useAppSelector((state) => state.tenant);

  const handleShowTranscription = (): void => {
    setShowTranscription((current) => !current);
  };

  const handleLiveStatusColor = (value: CALL.TRANSCRIPTION_STATUS): string => {
    if (!tenant?.liveCallsTranscription) {
      return 'secondary.main';
    }

    if (value === CALL.TRANSCRIPTION_STATUS.ERROR) {
      return 'error.main';
    }
    if (value === CALL.TRANSCRIPTION_STATUS.ON_HOLD) {
      return 'gray';
    }
    if (value === CALL.TRANSCRIPTION_STATUS.ONLINE) {
      return 'success.main';
    }

    return 'gray';
  };

  useEffect(() => {
    setConnection(connections.find((element) => element.selected));
  }, [connections]);

  return (
    <Box
      boxShadow={2}
      height={'100%'}
      p={1}
      bgcolor={'background.paper'}
      overflow={'auto'}
      flex={1}
    >
      {isAddonAvailable(ADDONS.LIVE_CALLS_TRANSCRIPTION) && connection && (
        <Box height={'100%'} width={'100%'}>
          <LiveTranscriptionDialog
            show={showDialog}
            setShow={setShowDialog}
            connection={connection}
            liveCallsTranscription={!!tenant?.liveCallsTranscription}
          />

          <Box
            display={'flex'}
            width={'100%'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box display={'flex'} alignItems={'center'}>
              <Tooltip
                title={
                  <Box display={'flex'} flexDirection={'column'}>
                    <Typography variant="caption" mb={1}>
                      {tenant?.liveCallsTranscription
                        ? connection.transcriptionStatus.toUpperCase()
                        : 'LIVE CALL TRANSCRIPTION IS DISABLED'}
                    </Typography>
                    <Typography variant="caption">
                      Quick note: For the best experience with our live call transcription feature,
                      it's important to have good voice quality(microphone, internet connection).
                      This helps our AI accurately transcribe the conversation in real-time.
                    </Typography>
                  </Box>
                }
              >
                <Box
                  borderRadius={20}
                  width={12}
                  height={12}
                  bgcolor={handleLiveStatusColor(connection.transcriptionStatus)}
                  mr={1}
                />
              </Tooltip>
              <Typography variant="body2" component="label">
                AI Live Call Transcription
              </Typography>
            </Box>

            <Box display={'flex'} justifyContent={'flex-end'}>
              <Button
                variant="text"
                size="small"
                onClick={() => setShowDialog(true)}
                disabled={!tenant?.liveCallsTranscription}
              >
                Open In Dialog
              </Button>
              <Button
                variant="text"
                size="small"
                sx={{ ml: 1 }}
                onClick={handleShowTranscription}
                disabled={!tenant?.liveCallsTranscription}
              >
                {showTranscription ? 'Hide' : 'Show'}
              </Button>
            </Box>
          </Box>

          {showTranscription && (
            <>
              {connection?.transcription.map((element, index) => {
                return (
                  <ListItem alignItems="flex-start" key={index} sx={{ p: 0, py: 1 }}>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      width={'100%'}
                      p={1}
                    >
                      <CustomAvatar url={''} key={index} showLiveStatus={false} />
                      <Box display={'flex'} width={'100%'} ml={1} flexDirection={'column'}>
                        <Typography fontSize={12}>{element.label}</Typography>
                        <Typography>{element.text}</Typography>
                        <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
                          <Typography fontSize={11} color={'Highlight'}>
                            AI transcription accuracy: {(element.confidence * 100).toFixed()}%
                          </Typography>
                          <Typography fontSize={11}>
                            {format(element.createdAt, 'hh:mm:ss a')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default LiveTranscription;

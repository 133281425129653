import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ISubsPreOrderData,
  ITenantSubsCalculatePriceRequest,
  ITenantSubsProceedPaymentRequest,
  ITenantSubsPurchaseRequest,
  ITenantSubsQuantityCalculateRequest,
  ITenantSubsSetTopUpRequest,
  ITenantSubsUpdateQuantityRequest,
} from '@/interfaces/tenant';

import handleErrors from '@/services/handle-errors';

import { IThunkExtra } from '@/store/store';

export const fetchSubscriptions = createAsyncThunk(
  'profileSubscriptions/fetchSubscriptions',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.subscriptionsApis.getAll();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSubsPreOrder = createAsyncThunk(
  'profileSubscriptions/fetchSubsPreOrder',
  async (data: ISubsPreOrderData, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.subsPreOrder(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSubsQuantityCalculate = createAsyncThunk(
  'profileSubscriptions/fetchSubsQuantityCalculate',
  async (data: ITenantSubsQuantityCalculateRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.subsQuantityCalculate(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSubsUpdateQuantity = createAsyncThunk(
  'profileSubscriptions/fetchSubsUpdateQuantity',
  async (data: ITenantSubsUpdateQuantityRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.updateSubsQuantity(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCalculateSubsPrice = createAsyncThunk(
  'profileSubscriptions/fetchCalculateSubsPrice',
  async (data: ITenantSubsCalculatePriceRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.calculateSubsPrice(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchPurchaseSubs = createAsyncThunk(
  'profileSubscriptions/fetchSubsPurchase',
  async (data: ITenantSubsPurchaseRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.purchaseSubs(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSubsCheckPayment = createAsyncThunk(
  'profileSubscriptions/fetchSubsCheckPayment',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.subsCheckPayment();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSubsProceedPayment = createAsyncThunk(
  'profileSubscriptions/fetchSubsProceedPayment',
  async (data: ITenantSubsProceedPaymentRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.subsProceedPayment(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSubsTopUpBalance = createAsyncThunk(
  'profileSubscriptions/fetchSubsTopUpBalance',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.topUpBalance();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSetSubsTopUp = createAsyncThunk(
  'profileSubscriptions/fetchSetSubsTopUp',
  async (data: ITenantSubsSetTopUpRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.setTopUp(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

import { configureStore } from '@reduxjs/toolkit';

import authReducer from './reducers/auth';
import userReducer from './reducers/user';
import snackbarReducer from './reducers/snackbar/snackbar';
import twilioReducer from './reducers/twilio';
import tenantReducer from './reducers/tenant';
import countryCodeReducer from './reducers/country-code';
import featureAccessDialogCodeReducer from './reducers/features-access-dialog';
import backdropReducer from './reducers/backdrop';
import countryCodesReducer from './reducers/country-codes';
import analyticsReducer from './reducers/analytics';

import useLongPressReducer from './reducers/hooks/useLongPress';

import menuReducer from './reducers/dashboard/menu';
import broadcastCampaignReducer from '../pages/dashboard/broadcast/campaign/reducer';
import voiceReducer from './reducers/dashboard/voice';
import userLocationReducer from './reducers/user-location';
import socketReducer from './reducers/socket';

import profileReducer from './reducers/profile/profile';

import v1Apis from '@/services/api/v1/index';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    snackbar: snackbarReducer,
    broadcastCampaign: broadcastCampaignReducer,
    user: userReducer,
    voice: voiceReducer,
    useLongPress: useLongPressReducer,
    twilio: twilioReducer,
    tenant: tenantReducer,
    countryCode: countryCodeReducer,
    userLocation: userLocationReducer,
    profile: profileReducer,
    featureAccessDialog: featureAccessDialogCodeReducer,
    backdrop: backdropReducer,
    countryCodes: countryCodesReducer,
    socket: socketReducer,
    analytics: analyticsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: {
        extraArgument: {
          v1Apis: v1Apis,
        },
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export interface IThunkExtra {
  v1Apis: typeof v1Apis;
}

import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';

import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import { IActiveProvider } from '@/interfaces/provider';
import { IConnection } from '@/interfaces/voice/call';

import { delay } from '@/helpers/delay';

import * as styles from './styles';

import * as twilioAcceptCallService from '@/services/twilio/accept-call';
import * as twilioEndCallService from '@/services/twilio/end-call';
import * as microphoneService from '@/services/devices/microphone';

import * as voiceReducer from '@/store/reducers/dashboard/voice';

import ROUTES from '@/constants/routes';
import PROVIDERS from '@/constants/providers';

type Operation = 'accept call' | 'reject call';

const NewIncomingCall = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { connections } = useAppSelector((state) => state.voice);
  const { activeProvider } = useAppSelector((state) => state.tenant);
  const activeProviderRef = useRef<null | IActiveProvider>(null);
  const [currentlyExecutingOperation, setCurrentlyExecutingOperation] = useState<
    { operation: Operation; isExecuting: boolean }[]
  >([
    { operation: 'accept call', isExecuting: false },
    { operation: 'reject call', isExecuting: false },
  ]);

  const isCurrentlyExecuting = (operation: Operation): boolean => {
    return currentlyExecutingOperation.some(
      (element) => element.operation === operation && element.isExecuting,
    );
  };

  const updateExecutingOperation = (operation: Operation, isExecuting: boolean): void => {
    setCurrentlyExecutingOperation((current) =>
      current.map((element) => {
        if (element.operation === operation) {
          element.isExecuting = isExecuting;
        }
        return element;
      }),
    );
  };

  const handleAcceptCall = async (uuid: string, connection: IConnection): Promise<void> => {
    if (isCurrentlyExecuting('accept call')) {
      return;
    }

    updateExecutingOperation('accept call', true);
    if (!(await microphoneService.isMicrophoneEnabled({ dispatch }))) {
      dispatch(voiceReducer.setShowEnableMicrophoneDialog(true));
      updateExecutingOperation('accept call', false);
      return;
    }

    if (activeProviderRef.current?.name === PROVIDERS.TWILIO) {
      await delay(500);
      await twilioAcceptCallService.acceptCall({ dispatch, uuid, connection });
    }
    navigate(ROUTES.DASHBOARD_VOICE);
    updateExecutingOperation('accept call', false);
  };

  const handleEndCall = async (uuid: string) => {
    if (isCurrentlyExecuting('reject call')) {
      return;
    }

    updateExecutingOperation('reject call', true);
    if (activeProviderRef.current?.name === PROVIDERS.TWILIO) {
      twilioEndCallService.endCall({ dispatch, uuid });
    }
    updateExecutingOperation('reject call', false);
  };

  useEffect(() => {
    activeProviderRef.current = activeProvider;
  }, [activeProvider]);

  return (
    <Box sx={styles.box3SX}>
      {connections.length
        ? connections.map((connection) => {
            if (!connection.answered && connection.direction === 'inbound') {
              const { uuid, caller, conn } = connection;
              return (
                <Slide key={uuid} direction="down" in={true} mountOnEnter unmountOnExit>
                  <Paper elevation={3} sx={styles.paper1SX}>
                    <Box sx={styles.box1SX}>
                      {caller?.firstName ? (
                        <Box sx={styles.box2SX}>
                          <Typography noWrap sx={styles.typography1SX}>
                            {caller.firstName} {caller.lastName}
                          </Typography>
                          <Typography sx={styles.typography2SX}>{caller.phoneNumber}</Typography>
                        </Box>
                      ) : (
                        <Box sx={styles.box2SX}>
                          <Typography sx={styles.typography1SX}>{caller?.phoneNumber}</Typography>
                        </Box>
                      )}

                      <Box sx={styles.box4SX}>
                        <IconButton
                          sx={styles.iconButton1SX}
                          onClick={() => handleAcceptCall(uuid, conn)}
                        >
                          <CallIcon sx={styles.callIcon1SX} />
                        </IconButton>

                        <IconButton sx={styles.iconButton2SX} onClick={() => handleEndCall(uuid)}>
                          <CallEndIcon sx={styles.callIcon2SX} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Paper>
                </Slide>
              );
            }
          })
        : null}
    </Box>
  );
};

export default NewIncomingCall;

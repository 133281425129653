import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import { AppDispatch } from '@/store/store';

export const isMicrophoneEnabled = async (options?: {
  dispatch: AppDispatch;
}): Promise<boolean> => {
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    return true;
  } catch (error) {
    options?.dispatch(
      snackbarReducer.showError(
        //@ts-expect-error Message exist there
        `Microphone error: ${error?.message ?? ''}. Please check if microphone is connected.`,
      ),
    );
    console.debug('Microphone permission error: ', error);
    return false;
  }
};

export const askMicrophonePermission = async (): Promise<void> => {
  //@ts-expect-error Looks like enum is outdated
  const permission = await navigator.permissions.query({ name: 'microphone' });

  if (permission.state !== 'granted') {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      console.log('Microphone permission error: ', error);
    }
  }
};

export const Box2SX = {
  display: { xs: 'none', md: 'flex' },
  flexDirection: 'column',
  backgroundColor: 'black',
  borderRadius: 5,
};

export const Box3SX = {
  width: '100%',
  height: 50,
  display: 'flex',
  alignItems: 'center',
};

export const Box4SX = {
  width: '33%',
  display: 'flex',
  justifyContent: 'center',
};

export const Box5SX = {
  display: 'flex',
};

export const Box6SX = {
  width: '25%',
};

export const Box7SX = {
  width: '33%',
  justifyContent: 'center',
  display: 'flex',
};

export const Box8SX = {
  width: '25%',
};

export const Box9SX = {
  mt: 5,
  display: 'flex',
  justifyContent: 'flex-start',
};

export const Box10SX = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
};

export const Typography4SX = {
  fontSize: 16,
  fontWeight: 'bold',
  ml: 1,
};

export const Typography5SX = {
  fontSize: 20,
  fontWeight: 550,
};

export const Typography6SX = {
  fontSize: 13,
  mt: 1,
};

export const Typography7SX = {
  fontWeight: 'bold',
  fontSize: 35,
};

export const Typography8SX = {
  width: 100,
  fontSize: 11,
  ml: 1,
  color: 'grey',
};

export const Typography9SX = {
  fontSize: 13,
  ml: 1,
  color: '#f6c004',
  fontWeight: 700,
};

export const Typography10SX = {
  fontSize: 13,
  ml: 1,
  color: '#f6c004',
  fontWeight: 700,
};

export const Typography11SX = {
  maxWidth: '20%',
  flexShrink: 0,
  fontSize: 20,
  fontWeight: 700,
};

export const Typography19SX = {
  fontSize: 15,
  color: 'black',
  fontWeight: 'bold',
  textShadow: '1px 1px 2px #f6c004, 0 0 1em #f6c004, 0 0 0.2em #f6c004;',
};

export const Typography20SX = {
  fontSize: 11,
  fontStyle: 'italic',
  fontWeight: 'bold',
};

export const CardContent1SX = {
  minHeight: 290,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const CardContent2SX = {
  mt: 3,
  mb: 2,
  display: 'flex',
  alignItems: 'center',
};

export const Icon1SX = {
  width: '1.5rem',
};

export const IButton1SX = {
  position: 'absolute',
};

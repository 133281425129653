import {
  IGetContactByPhoneNumberAndTypeRequest,
  IGetContactsQueryParams,
} from '@/interfaces/contact';

import makeRequest from '../make-request';
import token from '../token';

import { avoidEmptyStringValue } from '@/helpers/body';
import { IPaginationParams } from '@/interfaces/querying-data';

const CREATE = '/api/v1/contact';
const EDIT = '/api/v1/contact';
const UPLOAD = '/api/v1/contact/upload';
const GET_CONTACTS = '/api/v1/contact';
const GET_CONTACT_IMPORT_HISTORY = '/api/v1/contact/import-history';
const GET_CONTACT_BY_ID = '/api/v1/contact/:id';
const BULK_DELETE = '/api/v1/contact/delete';
const DELETE_BY_ID = '/api/v1/contact';
const GET_BY_PHONE_NUMBER_AND_TYPE = '/api/v1/contact/phone-number-and-type/:phoneNumber/:type';

export const create = async (data: FormData) => {
  try {
    const result = await makeRequest(CREATE, {
      method: 'POST',
      headers: {
        Authorization: await token(),
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
      },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const edit = async (id: string, data: FormData) => {
  try {
    const result = await makeRequest(`${EDIT}/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: await token(),
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
      },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const upload = async (data: FormData) => {
  try {
    const result = await makeRequest(UPLOAD, {
      method: 'POST',
      headers: {
        Authorization: await token(),
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
      },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getByParams = async (data: IGetContactsQueryParams) => {
  const queryParams: Record<string, string> = {
    limit: data.limit.toString(),
    skip: data.skip.toString(),
  };

  if (data.search) {
    queryParams.search = data.search;
  }

  if (data.contactType) {
    queryParams.contactType = data.contactType;
  }

  if (data.sortField) {
    queryParams.sortField = data.sortField;
  }

  if (data.sortDirection) {
    queryParams.sortDirection = data.sortDirection.toString();
  }

  try {
    const result = await makeRequest(GET_CONTACTS, {
      method: 'GET',
      headers: { Authorization: await token() },
      params: queryParams,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getImportHistory = async (data: IPaginationParams) => {
  const queryParams: Record<string, string> = {
    limit: data.limit.toString(),
    skip: data.skip.toString(),
  };

  try {
    const result = await makeRequest(GET_CONTACT_IMPORT_HISTORY, {
      method: 'GET',
      headers: { Authorization: await token() },
      params: queryParams,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getById = async (id: string) => {
  try {
    const result = await makeRequest(GET_CONTACT_BY_ID.replace(':id', id), {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};
export const getByPhoneNumberAndType = async (data: IGetContactByPhoneNumberAndTypeRequest) => {
  try {
    const result = await makeRequest(
      GET_BY_PHONE_NUMBER_AND_TYPE.replace(':phoneNumber', data.phoneNumber).replace(
        ':type',
        data.type,
      ),
      {
        method: 'GET',
        headers: { Authorization: await token() },
      },
    );

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const bulkDelete = async (data: { ids: string[] }) => {
  try {
    const result = await makeRequest(BULK_DELETE, {
      method: 'POST',
      headers: { Authorization: await token() },
      data: avoidEmptyStringValue(data),
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const deleteById = async (id: string) => {
  try {
    const result = await makeRequest(`${DELETE_BY_ID}/${id}`, {
      method: 'DELETE',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

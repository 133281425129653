export const Typography1SX = {
  fontSize: 11,
};

export const Typography2SX = {
  fontSize: 13,
  ml: 1,
};

export const Typography3SX = {
  fontSize: 13,
  ml: 1,
};

export const Typography4SX = {
  fontSize: 13,
  ml: 1,
};

export const Typography5SX = {
  fontSize: 13,
};

export const Icon1SX = {
  width: '1rem',
  ml: 2,
};

export const Box1SX = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
};

export const Box2SX = {
  display: 'flex',
  alignItems: 'flex-end',
};

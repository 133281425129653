import { IPartialResult } from '@/interfaces/querying-data';

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import CallIcon from '@mui/icons-material/Call';
import { Box, Button } from '@mui/material';

import * as voiceReducer from '@/store/reducers/dashboard/voice';

import apisV1 from '@/services/api/v1/index';

import { IGetUsersQueryParams, IUserResult } from '@/interfaces/users/users';

import CustomAvatar from '@/components/Custom-avatar/Avatar';

import * as callService from '@/services/voice/call';

import ROUTES from '@/constants/routes';

interface IUsersProps {
  search: string | undefined;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  onCallPlacement?: VoidFunction;
}

const Users = (props: IUsersProps) => {
  const { search, isLoading, setIsLoading } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { connections } = useAppSelector((state) => state.voice);

  const [queryParams, setQueryParams] = useState<IGetUsersQueryParams>({
    search: '',
    excludeMe: true,
    sortField: undefined,
    sortDirection: undefined,
    skip: 0,
    limit: 50,
  });

  const [usersResult, setUsersResult] = useState<IPartialResult<IUserResult>>({
    maxCount: 0,
    records: [],
  });

  const handleLoadUsers = async (executeLoaders: boolean = true): Promise<void> => {
    if (executeLoaders) {
      setIsLoading(true);
    }

    if (queryParams.search) {
      dispatch(voiceReducer.setIsSearchingContact(true));
    }

    const { success, data } = await apisV1.usersApis.getByParams(queryParams);

    if (success) {
      setUsersResult((prevState) => {
        if (!queryParams.skip) {
          return data;
        }

        return {
          records: prevState.records.concat(data.records),
          maxCount: data.maxCount,
        };
      });
    }

    setIsLoading(false);
  };

  const handleStartCall = (data: IUserResult): void => {
    props.onCallPlacement?.();

    callService.handleInitCall({ dispatch, to: data.companyExtension });
    navigate(ROUTES.DASHBOARD_VOICE_STATUS);
  };

  const isCallBtnDisabled = (data: IUserResult): boolean => {
    const isAnswered = connections.some(
      (connection) => !connection.answered && connection.direction === 'outbound',
    );

    const isInConnections = connections.some(
      (connection) =>
        connection.caller?.phoneNumber === data.companyExtension ||
        connection.callee?.phoneNumber === data.companyExtension,
    );

    return isAnswered || isInConnections;
  };

  useEffect(() => {
    const interval = setInterval(() => handleLoadUsers(false), 60_000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setQueryParams((prevState) => ({ ...prevState, search, skip: 0 }));
  }, [search]);

  useEffect(() => {
    handleLoadUsers();
  }, [
    queryParams.search,
    queryParams.excludeMe,
    queryParams.skip,
    queryParams.limit,
    queryParams.sortField,
    queryParams.sortDirection,
  ]);

  return (
    <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {usersResult.records.map((user: IUserResult) => {
        return (
          <Box key={user.id} display={'flex'} flexDirection={'column'}>
            <ListItem>
              <ListItemAvatar>
                <CustomAvatar
                  url={user.avatar?.url}
                  lastActivityAt={user.lastActivityAt}
                  showLiveStatus={true}
                />
              </ListItemAvatar>
              <ListItemText
                style={{ cursor: 'pointer' }}
                primary={
                  <Typography fontSize={14} sx={{ wordBreak: 'break-word' }}>
                    {user.fullName}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      display={'inline'}
                      sx={{ color: 'grey' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {user.companyExtension}
                    </Typography>
                  </React.Fragment>
                }
              />
              <IconButton
                color="primary"
                disabled={isCallBtnDisabled(user)}
                onClick={() => handleStartCall(user)}
              >
                <CallIcon fontSize="small" />
              </IconButton>
            </ListItem>
            <Divider component="li" />
          </Box>
        );
      })}

      {usersResult.records.length < usersResult.maxCount && (
        <Button
          fullWidth
          color="secondary"
          onClick={() =>
            setQueryParams((prevState) => ({
              ...prevState,
              skip: prevState.skip + prevState.limit,
            }))
          }
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="5%" />
          ) : (
            <ExpandMoreIcon fontSize="medium" />
          )}
        </Button>
      )}
    </List>
  );
};

export default Users;

import { FunctionComponent } from 'react';

import { Grid, Button, Box, Typography, GridProps } from '@mui/material';

interface IDialMobileKeyboardProps {
  containerProps?: GridProps;
  disabled?: boolean;
  handleDialChange: (data: string) => void;
}

const DIALER_BUTTONS = [
  { primary: '1' },
  { primary: '2', secondary: 'ABC' },
  { primary: '3', secondary: 'DEF' },
  { primary: '4', secondary: 'GHI' },
  { primary: '5', secondary: 'JKL' },
  { primary: '6', secondary: 'MNO' },
  { primary: '7', secondary: 'PQRS' },
  { primary: '8', secondary: 'TUV' },
  { primary: '9', secondary: 'WXYZ' },
  { primary: '*', sx: { fontSize: '1.5rem', mt: 'auto' } },
  { primary: '0', secondary: '+' },
  { primary: '#' },
];

const DialMobileKeyboard: FunctionComponent<IDialMobileKeyboardProps> = ({
  containerProps = {},
  disabled = false,
  handleDialChange,
}): JSX.Element => {
  return (
    <Grid
      container
      columnSpacing={1}
      rowSpacing={1.5}
      columns={3}
      alignItems="stretch"
      {...containerProps}
    >
      {DIALER_BUTTONS.map(({ primary, secondary, sx }) => (
        <Grid key={primary} item xs={1}>
          <Button
            fullWidth
            sx={{ fontWeight: 'bold' }}
            key={primary}
            disabled={disabled}
            onClick={() => {
              handleDialChange(primary);
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              minHeight="2.5rem"
              justifyContent={isNaN(Number(primary)) ? 'center' : 'initial'}
            >
              <Typography fontWeight="inherit" fontSize="inherit" component="span" sx={sx}>
                {primary}
              </Typography>

              <Typography fontWeight="inherit" fontSize=".625rem" component="small">
                {secondary}
              </Typography>
            </Box>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default DialMobileKeyboard;

import * as yup from 'yup';

import CHAT from '@/constants/chat';

const CreateNewChatValidationSchema = yup.object().shape(
  {
    chatType: yup.string().required('Chat type is required'),
    chatVariant: yup.string().required('Chat variant is required'),
    chatPlatform: yup.string().when('chatType', {
      is: (value: string) => value !== 'internal',
      then: (schema) => schema.required('Platform is required'),
    }),
    phoneNumber: yup.string().when(['chatType', 'contact'], {
      is: (chatType: string, contact: object | null) => chatType === 'internal' || !!contact,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required(),
    }),
    contact: yup.object().when(['chatType', 'phoneNumber'], {
      is: (chatType: CHAT.TYPE, phoneNumber: string) => chatType === 'internal' || !!phoneNumber,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required('Contact is required'),
    }),
    internalUser: yup.object().when(['chatType', 'phoneNumber'], {
      is: (chatType: string, phoneNumber: string) => chatType === 'internal' && !phoneNumber,
      then: (schema) => schema.required('User is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  },
  [
    ['phoneNumber', 'contact'],
    ['contact', 'phoneNumber'],
  ],
);

export default CreateNewChatValidationSchema;

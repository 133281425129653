import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import PublicLayoutHeader from '@/components/Public-layout/Public-layout-header';
import PublicLayoutFooter from '@/components/Public-layout/Public-layout-footer';

const AuthLayout = () => {
  return (
    <Box display="flex" justifyContent="space-between" flexDirection="column" minHeight="100vh">
      <PublicLayoutHeader />

      <Box
        display="flex"
        flex={1}
        sx={{
          // 128px because it is height of AppBar and Footer components combined
          height: 'calc(100vh - 128px)',
          overflow: 'auto',
        }}
      >
        <Outlet />
      </Box>

      <PublicLayoutFooter />
    </Box>
  );
};

export default AuthLayout;

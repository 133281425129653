import create from './create';
import updateMe from './update-me';
import updateUser from './update-user';
import updateMeCallPreferences from './update-me-preferences';
import updateSaAllowedIp from './update-sa-allowed-ip';
import createSaAllowedIp from './create-sa-allowed-ip';

export default {
  create,
  updateMe,
  updateUser,
  updateMeCallPreferences,
  updateSaAllowedIp,
  createSaAllowedIp,
};

import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { Box, Badge, Button, Paper, IconButtonProps } from '@mui/material';

import { defStyles } from '@/theme';

import { IConnection } from '@/interfaces/voice/call';
import { IUserResult } from '@/interfaces/users/users';

import * as phoneNumberValidator from '@/helpers/phone-number';

import * as callService from '@/services/voice/call';
import * as socketService from '@/services/socketio/socketio';
import * as twilioEndCallService from '@/services/twilio/end-call';
import * as twilioSendDigitsService from '@/services/twilio/send-digits';
import * as twilioForwardCallService from '@/services/twilio/forward-call';
import * as twilioResumeCallService from '@/services/twilio/resume-call';
import * as twilioPutCallOnHoldService from '@/services/twilio/put-on-hold';
import * as twilioMuteService from '@/services/twilio/mute';

import * as userThunk from '@/pages/dashboard/users/thunk';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as voiceThunk from '@/pages/dashboard/voice/thunk';

import * as voiceReducer from '@/store/reducers/dashboard/voice';

import EmergencyCallDialog from '@/pages/dashboard/voice/dialogs/emergency-call/Dialog';
import LiveTranscription from '@/pages/dashboard/voice/call-status/components/Live-transcription';
import LiveTranscriptionDialog from '@/pages/dashboard/voice/dialogs/Live-transcription-dialog';
import OutgoingNumberToggleButtonGroup from '@/pages/dashboard/voice/call-status/components/Outgoing-number-toggle-button-group';
import IdleCallOrConference from '@/pages/dashboard/voice/call-status/components/Idle-call-or-conference';
import AddParticipantDialog from '@/pages/dashboard/voice/call-status/conference/dialogs/Add-participant-dialog';
import ActiveCall from '@/pages/dashboard/voice/call-status/call/Active-call';
import ActiveConference from '@/pages/dashboard/voice/call-status/conference/Active-conference';
import ActiveCallsList from '@/pages/dashboard/voice/call-status/components/Active-calls-list';

import useThemeBreakpoints from '@/hooks/use-theme-breakpoints';

import SOCKETIO from '@/constants/socketio';
import * as SNACKBAR from '@/constants/snackbar';
import { PHONE_NUMBER_TYPE } from '@/constants/phone-numbers';
import PROVIDERS from '@/constants/providers';
import CALL from '@/constants/voice/voice';

type Operations = 'mute' | 'forward' | 'end call' | 'start call';

const ALLOWED_DIALER_CHARACTERS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '+', '*', '#'];

const buildTimer = (
  counter: { minutes: number | undefined; seconds: number | undefined } | undefined,
): string => {
  if (counter?.minutes !== undefined && counter.seconds !== undefined) {
    const m = counter.minutes.toString().padStart(2, '0');
    const s = counter.seconds.toString().padStart(2, '0');
    return `${m}:${s}`;
  }
  return '00:00';
};

const CallStatusLayout: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { callToContact, connections } = useAppSelector((state) => state.voice);
  const { tenant, phoneNumbers } = useAppSelector((state) => state.tenant);
  const { activeProvider } = useAppSelector((state) => state.tenant);
  const { user, countryCodes } = useAppSelector((state) => state.user);
  const { isSocketConnected } = useAppSelector((state) => state.socket);

  const [connection, setConnection] = useState<IConnection>();
  const [isTransciptionDialogOpened, setIsTransciptionDialogOpened] = useState<boolean>(false);

  const isMdOrDown = useThemeBreakpoints('down', 'md');

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [countryCode, setCountryCode] = useState<IUserResult['allowedCountries'][0] | null>(null);

  const [isForwardProcessInProgress, setIsForwardProcessInProgress] = useState<boolean>(false);

  const [isAddParticipantsDialogOpened, setIsAddParticipantsDialogOpened] =
    useState<boolean>(false);

  const [isDialSearchActive, setIsDialSearchActive] = useState<boolean>(false);
  const [currentlyExecutingOperation, setCurrentlyExecutingOperation] = useState<
    { operation: Operations; isExecuting: boolean }[]
  >([
    { operation: 'mute', isExecuting: false },
    { operation: 'forward', isExecuting: false },
    { operation: 'end call', isExecuting: false },
    { operation: 'start call', isExecuting: false },
  ]);

  const windowListenersDataRef = useRef({
    activeProvider,
    isDialSearchActive,
    phoneNumber,
  });

  const isCurrentlyExecuting = (operation: Operations): boolean => {
    return currentlyExecutingOperation.some(
      (element) => element.operation === operation && element.isExecuting,
    );
  };

  const updateExecutingOperation = (operation: Operations, isExecuting: boolean): void => {
    setCurrentlyExecutingOperation((current) =>
      current.map((element) => {
        if (element.operation === operation) {
          element.isExecuting = isExecuting;
        }
        return element;
      }),
    );
  };

  // CALL/CONFERENCE FUNCTIONALITY
  const handleStartCall = async (): Promise<void> => {
    if (!countryCode) {
      return;
    }
    if (isCurrentlyExecuting('start call')) {
      return;
    }
    updateExecutingOperation('start call', true);

    await callService.handleInitCall({
      dispatch,
      to:
        phoneNumber.length >= 3 && phoneNumber.length <= 4
          ? phoneNumber
          : countryCode.extension + phoneNumber,
    });

    setPhoneNumber('');

    dispatch(voiceReducer.setDialerSearchedUser(undefined));
    updateExecutingOperation('start call', false);
  };

  const handleStartEmergencyCall = async (): Promise<void> => {
    await callService.handleInitEmergencyCall(dispatch);
  };

  const handleEndCall = async () => {
    if (isCurrentlyExecuting('end call')) {
      return;
    }
    updateExecutingOperation('end call', true);
    const selectedConnection = callService.getSelectedConnection();

    if (activeProvider?.name === PROVIDERS.TWILIO) {
      await twilioEndCallService.endCall({ dispatch, uuid: selectedConnection?.uuid as string });
    }
    updateExecutingOperation('end call', false);
  };

  const handleCancelForward = () => {
    setPhoneNumber('');
    setIsForwardProcessInProgress(false);
  };

  const handleForward = async (): Promise<void> => {
    if (isForwardProcessInProgress) {
      if (!countryCode) {
        return;
      }

      if (isCurrentlyExecuting('forward')) {
        return;
      }

      setIsForwardProcessInProgress(false);

      updateExecutingOperation('forward', true);
      const connection = callService.getSelectedConnection();

      if (connection?.connectionType === CALL.CONNECTION_TYPES.INTERNAL) {
        dispatch(snackbarReducer.showError('Unable to forward internal calls.'));
        updateExecutingOperation('forward', false);
        return;
      }

      dispatch(voiceReducer.setIsShowCallStatusLoader(true));

      if (activeProvider?.name === PROVIDERS.TWILIO) {
        await twilioForwardCallService.forwardCall({
          dispatch,
          phoneNumbers,
          to: phoneNumber,
        });

        setPhoneNumber('');
      }

      dispatch(voiceReducer.setIsShowCallStatusLoader(false));
      updateExecutingOperation('forward', false);
    } else {
      setIsForwardProcessInProgress(true);
    }
  };

  const handleAddParticipant = () => {
    setIsAddParticipantsDialogOpened(true);
  };

  const handleMute = async () => {
    if (isCurrentlyExecuting('mute')) {
      return;
    }

    updateExecutingOperation('mute', true);
    if (activeProvider?.name === PROVIDERS.TWILIO) {
      twilioMuteService.mute({ dispatch });
    }
    updateExecutingOperation('mute', false);
  };

  const handleHoldOrResumeCall = async (): Promise<void> => {
    if (activeProvider?.name === PROVIDERS.TWILIO) {
      if (callService.isCallOnHold()) {
        await twilioResumeCallService.resumeTwilioCall({ dispatch });
      } else {
        await twilioPutCallOnHoldService.putTwilioCallOnHold({ dispatch });
      }
    }
  };

  const isMenuBtnDisabled = (btn?: string): boolean => {
    if (!tenant) {
      return true;
    }
    const connection = callService.getSelectedConnection();
    if (!connection) {
      return true;
    }

    if (btn === 'end' && connection.direction === 'outbound') {
      return false;
    }

    if (!connection.answered) {
      return true;
    }

    if (btn === 'call') {
      const { isValid } = phoneNumberValidator.validate(phoneNumber);

      return !(isValid || (phoneNumber.length > 2 && phoneNumber.length < 10000));
    }

    if (btn === 'forward') {
      return (
        !connection.answered ||
        connection.connectionType === CALL.CONNECTION_TYPES.INTERNAL ||
        connection.isMultipleParticipants ||
        connection.onHold
      );
    }

    if (btn === 'hold') {
      return (
        connection.isHoldDisabled ||
        (connection.connectionType === CALL.CONNECTION_TYPES.INTERNAL &&
          connection.internalHoldByOther)
      );
    }

    if (btn === 'mute') {
      return !!connection.onHold;
    }

    if (btn === 'add-participant') {
      return tenant.voice === CALL.VOICE.CALL;
    }

    return !connection.answered;
  };

  const getCallActionIconButtonSxProps = (color: string): IconButtonProps['sx'] => {
    return {
      color,
      '&:hover': {
        color,
      },
      '&[disabled]': {
        color: 'primary.light',
      },
    };
  };

  const isHoldPulsation = (): boolean => {
    const connection = callService.getSelectedConnection();

    if (!connection || !connection.answered) {
      return false;
    }

    return connection.showHoldPulsation && !connection.internalHoldByOther;
  };

  const handleUpdateSelectedPhoneNumberTypeToMakeCalls = async (
    event: any,
    value: PHONE_NUMBER_TYPE,
  ) => {
    if (!value) {
      return;
    }

    const { meta } = await dispatch(
      userThunk.fetchUpdateMePreferences({ selectedPhoneNumberTypeToMakeCallsFrom: value }),
    );

    if (meta.requestStatus === 'fulfilled') {
      dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.UPDATED('From phone number')));
    } else if (meta.requestStatus === 'rejected') {
      dispatch(
        snackbarReducer.showError(SNACKBAR.MESSAGES.GENERAL.FAILED_TO('update from phone number')),
      );
    }
  };

  const handleCheckForUserByCompanyExt = async (value: string): Promise<void> => {
    let dialerSearchedUser = undefined;

    if (!Number.isNaN(value) && +value >= 100 && +value <= 9999) {
      const payload = await dispatch(voiceThunk.fetchUserByCompanyExtension(value)).unwrap();
      dialerSearchedUser = payload ?? undefined;
    }

    dispatch(voiceReducer.setDialerSearchedUser(dialerSearchedUser));
  };

  // WINDOW EVENTS LISTENERS

  const handleKeypress = (event: { key: number | string }) => {
    if (windowListenersDataRef.current.isDialSearchActive) {
      return;
    }

    const value = event.key.toString();
    handleSendDigits(value);

    if (value === '') {
      setPhoneNumber('');
      dispatch(voiceReducer.setDisableActionBtn(true));
      return;
    }

    if (ALLOWED_DIALER_CHARACTERS.includes(value[value.length - 1])) {
      setPhoneNumber((prevState) => prevState + value);
      dispatch(voiceReducer.setDisableActionBtn(false));
    }
  };

  const handleSendDigits = (value: string): void => {
    if (windowListenersDataRef.current.activeProvider?.name === PROVIDERS.TWILIO) {
      twilioSendDigitsService.sendDigits({ digits: value });
    }
  };

  const handleKeydown = (event: { code: string }): void => {
    if (windowListenersDataRef.current.isDialSearchActive) {
      return;
    }

    const code = event.code;

    if (code === 'Backspace') {
      setPhoneNumber((prevState) => prevState.slice(0, -1));
    }

    if (code === 'Delete') {
      setPhoneNumber('');
    }
  };

  useEffect(() => {
    if (windowListenersDataRef?.current) {
      windowListenersDataRef.current = {
        ...windowListenersDataRef.current,
        isDialSearchActive: isAddParticipantsDialogOpened,
      };
    }
  }, [isAddParticipantsDialogOpened]);

  useEffect(() => {
    windowListenersDataRef.current = {
      activeProvider,
      phoneNumber,
      isDialSearchActive,
    };
  }, [isDialSearchActive, phoneNumber, activeProvider]);

  useEffect(() => {
    if (callToContact) {
      callService.handleInitCall({ dispatch, to: '' });
    }
  }, [callToContact]);

  useEffect(() => {
    if (connections.length) {
      const lastConnection = connections.at(-1);

      if (lastConnection) {
        if (!lastConnection.answered) {
          const uuid = lastConnection.conn.customParameters.get('uuid');
          socketService.socket?.emit(SOCKETIO.EVENTS.JOIN_CALL, JSON.stringify({ uuid }));
        }
      }
    } else {
      setIsForwardProcessInProgress(false);
    }

    setConnection(connections.find((element) => element.selected));
  }, [connections, isSocketConnected]);

  useEffect(() => {
    setCountryCode(
      countryCodes.find((element) => element.iso === 'CA') as IUserResult['allowedCountries'][0],
    );

    dispatch(voiceReducer.setDialerSearchedUser(undefined));

    window.addEventListener('keypress', handleKeypress);
    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keypress', handleKeypress);
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  useEffect(() => {
    handleCheckForUserByCompanyExt(phoneNumber);
  }, [phoneNumber]);

  const selectedConnection = callService.getSelectedConnection();
  const isActiveCalls = !!connections.length;

  return (
    <>
      <EmergencyCallDialog handleStartCall={handleStartEmergencyCall} />

      <AddParticipantDialog
        show={isAddParticipantsDialogOpened}
        setShow={setIsAddParticipantsDialogOpened}
      />

      <Box display="flex" flex={1} gap={1} overflow="auto" p={1} m={-1}>
        <Box
          component={Paper}
          elevation={2}
          sx={[{ width: 360 }, defStyles.scroll]}
          overflow="auto"
          mx="auto"
        >
          <Box display="flex" flexDirection="column" gap={1} flex={1} mx="auto">
            {!isActiveCalls && user && user?.directExtension ? (
              <OutgoingNumberToggleButtonGroup
                onChange={handleUpdateSelectedPhoneNumberTypeToMakeCalls}
              />
            ) : null}

            {isMdOrDown && connections.length ? (
              <Box display="flex" justifyContent="flex-end" px={2} pt={2}>
                <Badge badgeContent={connections.length} color="primary">
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ pr: 1 }}
                    onClick={() => setIsTransciptionDialogOpened(true)}
                  >
                    View live transcription
                  </Button>
                </Badge>
              </Box>
            ) : null}

            {isActiveCalls ? (
              <Box pb={2}>
                {selectedConnection?.participants.length ? (
                  <ActiveConference
                    handleSendDigits={handleSendDigits}
                    buildTimer={buildTimer}
                    selectedConnection={selectedConnection}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    isForwardProcessInProgress={isForwardProcessInProgress}
                    isMenuBtnDisabled={isMenuBtnDisabled}
                    getCallActionIconButtonSxProps={getCallActionIconButtonSxProps}
                    isHoldPulsation={isHoldPulsation}
                    handleMute={handleMute}
                    handleHoldOrResumeCall={handleHoldOrResumeCall}
                    handleStartCall={handleStartCall}
                    handleForward={handleForward}
                    handleCancelForward={handleCancelForward}
                    handleEndCall={handleEndCall}
                    handleAddParticipant={handleAddParticipant}
                  />
                ) : (
                  <ActiveCall
                    handleSendDigits={handleSendDigits}
                    buildTimer={buildTimer}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    isForwardProcessInProgress={isForwardProcessInProgress}
                    selectedConnection={selectedConnection}
                    isMenuBtnDisabled={isMenuBtnDisabled}
                    getCallActionIconButtonSxProps={getCallActionIconButtonSxProps}
                    isHoldPulsation={isHoldPulsation}
                    handleMute={handleMute}
                    handleHoldOrResumeCall={handleHoldOrResumeCall}
                    handleStartCall={handleStartCall}
                    handleForward={handleForward}
                    handleCancelForward={handleCancelForward}
                    handleEndCall={handleEndCall}
                    handleAddParticipant={handleAddParticipant}
                  />
                )}

                <ActiveCallsList />
              </Box>
            ) : (
              <IdleCallOrConference
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                handleStartCall={handleStartCall}
              />
            )}
          </Box>
        </Box>

        {isMdOrDown ? (
          <LiveTranscriptionDialog
            show={isTransciptionDialogOpened}
            setShow={setIsTransciptionDialogOpened}
            connection={connection}
            liveCallsTranscription={!!tenant?.liveCallsTranscription}
          />
        ) : (
          <LiveTranscription />
        )}
      </Box>
    </>
  );
};

export default CallStatusLayout;

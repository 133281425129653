import makeRequest from '../make-request';
import token from '../token';

const GET_COUNTRY_CODES = '/api/v1/country-codes';

export const getAll = async () => {
  try {
    const result = await makeRequest(GET_COUNTRY_CODES, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ITwilioDevice } from '@/interfaces/voice/twilio';

export interface ITwilioSlice {
  device: ITwilioDevice | null;
  createDevice: { initCreate: boolean; force: boolean };
  accessToken: string | null;
  // Use this in dependencies of useEffect hook as a onReady change event. null when no events were fired before
  useEffectOnReady: number | null;
}

const initialState: ITwilioSlice = {
  device: null,
  createDevice: { initCreate: false, force: false },
  accessToken: null,
  useEffectOnReady: null,
};

export const twilioSlice = createSlice({
  name: 'twilio',
  initialState,
  reducers: {
    onReady: (state) => {
      state.useEffectOnReady = Date.now();
    },
    reset: (state) => {
      state = initialState;
    },
    setDevice: (state, action: PayloadAction<ITwilioDevice | null>) => {
      state.device = action.payload;
    },
    setCreateDevice: (state, action: PayloadAction<{ initCreate: boolean; force?: boolean }>) => {
      state.createDevice = {
        initCreate: action.payload.initCreate,
        force: !!action.payload.force,
      };
    },
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
  },
});

export const { onReady, reset, setDevice, setCreateDevice, setAccessToken } = twilioSlice.actions;

export default twilioSlice.reducer;

import { Box, Paper } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch } from '@/store/hooks';

import * as menuReducer from '@/store/reducers/dashboard/menu';

import DASHBOARD from '@/constants/dashboard';
import CallsSettingsLayout from './calls/Calls-settings-layout';
import ChatsAndMessagingSettingsLayout from './chats-and-messages/Chats-and-messages-settings-layout';
import TenantSettingsLayout from './tenant/Tenant-settings-layout';

const GeneralSettings = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.SETTINGS }));
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': { display: 'none' },
      }}
      p={2}
      pt={0}
    >
      <Box p={2} pb={4} component={Paper}>
        <TenantSettingsLayout />
      </Box>

      <Box p={2} pb={4} component={Paper}>
        <CallsSettingsLayout />
      </Box>

      <Box p={2} pb={4} component={Paper}>
        <ChatsAndMessagingSettingsLayout />
      </Box>
    </Box>
  );
};

export default GeneralSettings;

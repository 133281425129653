export const avatar = {
  width: 150,
  height: 150,
};

export const disabledButReadableTextField = {
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.6)',
  },
};

export default {
  DAYS: {
    SHORT_NAME: {
      MONDAY: 'Mon.',
      TUESDAY: 'Tues.',
      WEDNESDAY: 'Wed.',
      THURSDAY: 'Thurs.',
      FRIDAY: 'Fri.',
      SATURDAY: 'Sat.',
      SUNDAY: 'Sun.',
    },
    SHORT_NAME_2: {
      'Mon.': 'Mon.',
      'Tues.': 'Tues.',
      'Wed.': 'Wed.',
      'Thurs.': 'Thurs.',
      'Fri.': 'Fri.',
      'Sat.': 'Sat.',
      'Sun.': 'Sun.',
    },
    NUMBER_DAY_SHORT: {
      1: 'Mon.',
      2: 'Tues.',
      3: 'Wed.',
      4: 'Thurs.',
      5: 'Fri.',
      6: 'Sat.',
      0: 'Sun.',
    },
    NUMBER_DAY_LONG: {
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      0: 'Sunday',
    },
    FULL_NAME: {
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday',
    },
  },
};

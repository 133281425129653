import makeRequest from '../make-request';

import { ITwoFactorAuthRequest } from '@/interfaces/2fa';

import token from '../token';

const GENERATE = '/api/v1/2fa/generate';

export const generate = async (data: ITwoFactorAuthRequest) => {
  return makeRequest(GENERATE, {
    method: 'POST',
    headers: { Authorization: await token() },
    data,
  });
};

import { useEffect, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Box,
  DialogContent,
  Typography,
  TextField,
} from '@mui/material';

import Transition from './Transition';

import * as twoFAThunk from '@/store/thunk/2fa';
import { useAppDispatch } from '@/store/hooks';

import TWOFA from '@/constants/2fa';

const TwoFADialog = (props: {
  show: boolean;
  handleClose: () => void;
  type: TWOFA.TYPES;
  handler: (input: string) => Promise<void> | void;
}) => {
  const { handleClose, show, type, handler } = props;

  const dispatch = useAppDispatch();

  const [input, setInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInitialCodeSent, setIsInitialCodeSent] = useState<boolean>(false);

  const handleGenerate = async (): Promise<void> => {
    setIsLoading(true);
    await dispatch(twoFAThunk.fetchGenerate2fa({ type }));
    setIsLoading(false);
    setIsInitialCodeSent(true);
  };

  const handleProceed = async (): Promise<void> => {
    setIsLoading(true);
    await handler(input);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!show) {
      setInput('');
      setIsInitialCodeSent(false);
    }
  }, [show]);

  return (
    <Dialog open={show} TransitionComponent={Transition} sx={{ zIndex: 100000 }}>
      <Box minWidth={400} position={'relative'}>
        <DialogTitle fontSize={20}>Two-Factor Authentication</DialogTitle>
        <DialogContent>
          <Box display={'flex'} width={'100%'} alignItems={'center'} flexDirection={'column'}>
            {isInitialCodeSent ? (
              <>
                <Typography fontSize={15}>Please enter your code here</Typography>
                <TextField
                  label="2FA code"
                  variant="outlined"
                  value={input}
                  size="small"
                  onChange={(e) => setInput(e.target.value)}
                  sx={{ mt: 0.8 }}
                />
              </>
            ) : (
              <Button
                variant="contained"
                size="small"
                disabled={isLoading}
                onClick={handleGenerate}
              >
                <Typography>Email me 2FA code</Typography>
              </Button>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Close
          </Button>
          <Button disabled={!input || isLoading} color="success" onClick={() => handleProceed()}>
            Proceed
          </Button>
        </DialogActions>
        <Box position={'absolute'} bottom={5} left={5}>
          {isInitialCodeSent && (
            <Button variant="text" size="small" disabled={isLoading} onClick={handleGenerate}>
              <Typography fontSize={12}>Resend code</Typography>
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default TwoFADialog;

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IUserChangePasswordRequest,
  IUserUpdateMePreferencesRequest,
  IUserUpdateAllUsersPreferencesRequest,
  IUserLinkAccountsRequest,
  IUserSetPermissionsRequest,
  IUserSwitchAccountRequest,
  IUserUnlinkAccountRequest,
  IUserAcceptLegalInfoRequest,
  IUserUpdateMeRequest,
  IUserSetAllowedCountryCodesRequest,
} from '@/interfaces/users/users';

import handleErrors from '@/services/handle-errors';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import { IThunkExtra } from '../../../store/store';

import * as userApi from '@/services/api/v1/user';

import * as SNACKBAR from '@/constants/snackbar';

export const fetchGetUserInfo = createAsyncThunk(
  'user/fetchGetUserInfo',
  async (data, thunkAPI) => {
    const response = await userApi.getUserInfo();
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSetSession = createAsyncThunk('user/fetchSetSession', async (data, thunkAPI) => {
  const response = await userApi.setSession();
  if (!response.success) {
    handleErrors(thunkAPI, response.statusCode, response);
    thunkAPI.abort();
  } else {
    return response.data;
  }
});

export const fetchCheckSession = createAsyncThunk(
  'user/fetchCheckSession',
  async (data, thunkAPI) => {
    const response = await userApi.checkSession();
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSaveLegalInfoAccepted = createAsyncThunk(
  'user/fetchSaveLegalInfoAccepted',
  async (data: IUserAcceptLegalInfoRequest, thunkAPI) => {
    const response = await userApi.saveLegalInfoAccepted(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

// Refer to IUserSetAvatarRequest interface for structure of FormData
export const fetchUploadUserAvatar = createAsyncThunk(
  'user/fetchUploadUserAvatar',
  async (data: FormData, thunkAPI) => {
    const response = await userApi.uploadUserAvatar(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(SNACKBAR.MESSAGES.USER.AVATAR_SUCCESSFULLY_UPLOADED),
      );
      return response.data;
    }
  },
);

export const fetchUpdateMe = createAsyncThunk(
  'user/fetchUpdateMe',
  async (data: IUserUpdateMeRequest, thunkAPI) => {
    const response = await userApi.updateMe(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.UPDATED('User')));
      return response.data;
    }
  },
);

export const fetchChangePassword = createAsyncThunk(
  'user/fetchChangePassword',
  async (data: IUserChangePasswordRequest, thunkAPI) => {
    const response = await userApi.changePassword(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(SNACKBAR.MESSAGES.USER.PASSWORD_SUCCESSFULLY_CHANGED),
      );
      return response.data;
    }
  },
);

export const fetchDeleteAvatar = createAsyncThunk(
  'user/fetchDeleteAvatar',
  async (data, thunkAPI) => {
    const response = await userApi.deleteAvatar();
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.DELETED('User avatar')),
      );
      return response.data;
    }
  },
);

export const fetchUpdateMePreferences = createAsyncThunk(
  'user/fetchUpdateMePreferences',
  async (data: IUserUpdateMePreferencesRequest, thunkAPI) => {
    const response = await userApi.updateMePreferences(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchUpdateAllUsersPreferences = createAsyncThunk(
  'user/fetchUpdateAllUsersPreferences',
  async (data: IUserUpdateAllUsersPreferencesRequest, thunkAPI) => {
    const response = await userApi.updateAllUsersPreferences(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchLogout = createAsyncThunk('user/fetchLogout', async (data, thunkAPI) => {
  const { v1Apis } = thunkAPI.extra as IThunkExtra;
  const response = await v1Apis.usersApis.logout();

  if (!response.success) {
    handleErrors(thunkAPI, response.statusCode, response);
    thunkAPI.abort();
  } else {
    return response.data;
  }
});

export const fetchLogin = createAsyncThunk('user/fetchLogin', async (data, thunkAPI) => {
  const { v1Apis } = thunkAPI.extra as IThunkExtra;
  const response = await v1Apis.usersApis.login();

  if (!response.success) {
    handleErrors(thunkAPI, response.statusCode, response);
    thunkAPI.abort();
  } else {
    return response.data;
  }
});

export const fetchAccounts = createAsyncThunk('user/fetchAccounts', async (data, thunkAPI) => {
  const { v1Apis } = thunkAPI.extra as IThunkExtra;
  const response = await v1Apis.usersApis.getAccounts();

  if (!response.success) {
    handleErrors(thunkAPI, response.statusCode, response);
    thunkAPI.abort();
  } else {
    return response.data;
  }
});

export const fetchLinkNewAccount = createAsyncThunk(
  'user/fetchLinkNewAccount',
  async (data: IUserLinkAccountsRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.linkNewAccount(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSwitchAccount = createAsyncThunk(
  'user/fetchSwitchAccount',
  async (data: IUserSwitchAccountRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.switchAccount(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchUnlinkAccount = createAsyncThunk(
  'user/fetchUnlinkAccount',
  async (data: IUserUnlinkAccountRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.unlinkAccount(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSetPermissions = createAsyncThunk(
  'user/fetchSetPermissions',
  async (data: { id: string; data: IUserSetPermissionsRequest }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.setPermissions(data.id, data.data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchGetById = createAsyncThunk(
  'user/fetchGetById',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.getById(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSetAllowedCountryCodes = createAsyncThunk(
  'user/fetchSetAllowedCountryCodes',
  async (data: { userId: string; data: IUserSetAllowedCountryCodesRequest }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.setAllowedCountryCodes(data.userId, data.data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchRequestClickToCallDekstopApp = createAsyncThunk(
  'user/fetchRequestClickToCallDekstopApp',
  async (data: void, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.requestClickToCallDekstopApp();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

import { Box, Divider, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

import DefaultPhoneNumberForTenantSettings from './Default-phone-number-for-tenant-settings';
import MaskingSettings from './Masking-settings';
import TimeZoneSettings from './Time-zone-settings';

const TenantSettingsLayout: FunctionComponent = (): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" flex={1} gap={1}>
      <Box mb={2}>
        <Typography variant="h6" component="p">
          Organization settings
        </Typography>

        <Divider />
      </Box>

      <Box display="flex" flexDirection="column" gap={3}>
        <DefaultPhoneNumberForTenantSettings />
        <MaskingSettings />
        <TimeZoneSettings />
      </Box>
    </Box>
  );
};

export default TenantSettingsLayout;

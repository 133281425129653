import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { IFeatureComparison } from '@/interfaces/features-comparison';
import { ISubscriptionResult } from '@/interfaces/subscriptions';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SportsIcon from '@mui/icons-material/Sports';
import CloseIcon from '@mui/icons-material/Close';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Divider,
  Grid,
  Box,
  Typography,
  IconButton,
} from '@mui/material';

import MonthlyYearlySwitcher from '@/components/Buttons/Monthly-yearly/Monthly-yearly';

import * as pricingThunk from '@/pages/pricing/thunk';
import { useAppDispatch } from '@/store/hooks';

import { SubsTerm } from '@/types/subs';

import * as styles from './styles';

import SUBS from '@/constants/subscriptions';

interface IFeature extends IFeatureComparison {
  expanded: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IPricingDialogSlide {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const PricingDialogSlide = (props: IPricingDialogSlide) => {
  const { isVisible, setIsVisible } = props;
  const dispatch = useAppDispatch();

  const [subsTerm, setSubsTerm] = useState<SubsTerm>(SUBS.TERM.ANNUAL);
  const [features, setFeatures] = useState<IFeature[]>([]);
  const [subscriptions, setSubscriptions] = useState<ISubscriptionResult[]>([]);

  const getFeaturesComparison = async (): Promise<void> => {
    const { payload } = await dispatch(pricingThunk.fetchFeaturesComparison());

    if (payload) {
      setFeatures(payload.map((element: IFeature) => ({ ...element, expanded: false })));
    }
  };

  const getSubscriptions = async (): Promise<void> => {
    const { payload } = await dispatch(pricingThunk.fetchSubscriptions());

    if (payload) {
      setSubscriptions(payload);
    }
  };

  const handleExpandAccordion = (index: number): void => {
    const updatedFeatures = features.map((element, i) => {
      if (i === index) {
        element.expanded = !element.expanded;
      }
      return element;
    });
    setFeatures(updatedFeatures);
  };

  const handleClose = (): void => {
    setIsVisible(false);
  };

  const getPrice = (data: ISubscriptionResult): number => {
    const price = data.prices.find((element) => element.name === SUBS.PRICE_TYPES.GLOBAL);

    if (!price) {
      return 99;
    }

    if (data.pricingTerm !== SUBS.PRICING_TERM.ALL) {
      return data.pricingTerm === SUBS.PRICING_TERM.ANNUAL ? price.annual : price.monthly;
    }
    if (price) {
      return subsTerm === SUBS.TERM.MONTHLY ? price[subsTerm] : +(price[subsTerm] / 12).toFixed(2);
    }
    return 99;
  };

  useEffect(() => {
    getFeaturesComparison();
    getSubscriptions();
  }, []);

  return (
    <Dialog
      open={isVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth={false}
    >
      <DialogContent>
        <DialogActions>
          <IconButton onClick={handleClose} sx={styles.IButton1SX}>
            <CloseIcon color="error" />
          </IconButton>
        </DialogActions>
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent={'center'}
              alignItems={'flex-end'}
              flexDirection={'row'}
              spacing={1}
            >
              <Grid item xs={12}>
                <MonthlyYearlySwitcher paymentType={subsTerm} setPaymentType={setSubsTerm} />
              </Grid>
              {subscriptions.map((element, index) => {
                return (
                  <Grid item xs={12} sm={9} md={4} xl={3} lg={4} key={index}>
                    <Card elevation={24}>
                      {element.isBestValue ? (
                        <Box>
                          <CardContent>
                            <Typography sx={styles.Typography19SX}>Best Value</Typography>
                          </CardContent>
                        </Box>
                      ) : null}

                      <CardContent sx={styles.CardContent1SX}>
                        <Box>
                          <Typography sx={styles.Typography5SX}>{element.name}</Typography>
                          <Typography sx={styles.Typography20SX}>{element.subheader}</Typography>
                          <Typography sx={styles.Typography6SX}>{element.description}</Typography>
                          {element.description2 && (
                            <Typography sx={styles.Typography6SX}>
                              {element.description2}
                            </Typography>
                          )}
                        </Box>

                        <Box sx={styles.CardContent2SX}>
                          <Typography sx={styles.Typography7SX}>${getPrice(element)}</Typography>
                          <Typography sx={styles.Typography8SX}>user/month</Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
              {/* <Grid item xs={12} sm={9} md={3} lg={2}>
                <Card elevation={15}>
                  <CardContent sx={styles.CardContent1SX}>
                    <Box>
                      <Typography sx={styles.Typography5SX}>
                        Scale-Up / Political Campaigns
                      </Typography>
                      <Typography sx={styles.Typography20SX}>
                        Custom licensing or Political Campaigns
                      </Typography>
                      <Typography sx={styles.Typography6SX}>
                        For special business needs, political campaigns, or for Enterprise
                        requirements, please contact us and our sales team can assist.
                      </Typography>
                    </Box>

                    <Box sx={styles.Box9SX}>
                      <Link href="mailto: getwhistle@whistle.biz" sx={{}}>
                        Contact us
                      </Link>
                    </Box>
                  </CardContent>
                </Card>
              </Grid> */}
            </Grid>
          </Grid>

          <Grid item md={11} lg={11}>
            <Box sx={styles.Box2SX}>
              <Box sx={styles.Box3SX}>
                <Box sx={styles.Box8SX}>
                  <Typography sx={styles.Typography9SX}>BUSINESS FEATURES COMPARISON</Typography>
                </Box>

                {subscriptions.map((element, index) => {
                  return (
                    <Box key={index} sx={styles.Box4SX}>
                      <Typography sx={styles.Typography10SX}>{element.name}</Typography>
                    </Box>
                  );
                })}
              </Box>

              {features.map((element, index) => {
                return (
                  <Accordion
                    disableGutters
                    key={index}
                    expanded={element.expanded}
                    onChange={() => handleExpandAccordion(index)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={styles.Typography11SX}>{element.category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {element.items.map((item, i) => {
                        return (
                          <Box key={i}>
                            <Box sx={styles.Box5SX}>
                              <Box sx={styles.Box6SX}>
                                <Typography fontSize={13} py={1}>
                                  {item}
                                </Typography>
                              </Box>

                              {subscriptions.map((subscription, idx) => {
                                const isIncluded = subscription.featuresComparisons?.some(
                                  (feature: string) => feature === item,
                                );

                                return (
                                  <Box key={idx} sx={styles.Box7SX}>
                                    <SportsIcon
                                      color={isIncluded ? 'success' : 'error'}
                                      sx={styles.Icon1SX}
                                    />
                                  </Box>
                                );
                              })}
                            </Box>
                            <Divider />
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PricingDialogSlide;

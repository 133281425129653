import yup from '@/helpers/yup';

import {
  getInvalid,
  getMax,
  getMin,
  getOneOfType,
  getRequired,
} from '@/helpers/form-fields-error-messages';

import { ROLES } from '@/constants/roles';

const create = yup.object().shape({
  firstName: yup
    .string()
    .min(1, getMin('first name', 'one character'))
    .max(50, getMax('first name', '50 characters'))
    .required(getRequired('first name'))
    .typeError(getInvalid('first name')),
  lastName: yup
    .string()
    .min(1, getMin('first name', 'one character'))
    .max(50, getMax('first name', '50 characters'))
    .typeError(getInvalid('first name')),
  email: yup
    .string()
    .email(getInvalid('email'))
    .required(getRequired('email'))
    .typeError(getInvalid('email')),
  companyExtension: yup.number().extension().required(getRequired('company extension')),
  directExtension: yup
    .string()
    .phone({ errorMessage: getInvalid('direct extension'), skipIfEmpty: true })
    .nullable(),
  sip: yup.string().nullable().typeError(getInvalid('sip')),
  role: yup
    .string()
    .oneOf(
      [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.USER],
      getOneOfType('role', ROLES.SUPER_ADMIN, ROLES.USER, ROLES.ADMIN),
    )
    .required(getRequired('role')),
  file: yup.mixed().nullable(),
  transferChatsToAssignedUser: yup.boolean().typeError(getInvalid('transfer chat configuration')),
});

export default create;

import { Box } from '@mui/material';

import IPs from './Ips';

const SuperAdminLayout = () => {
  return (
    <Box display="flex" flex={1} gap={2} p={2}>
      <IPs />
    </Box>
  );
};

export default SuperAdminLayout;

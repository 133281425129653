import makeRequest from '../make-request';

import token from '../token';

import { IVoiceSettingsRequest, IVoiceIncomingSettingsCallFlow } from '@/interfaces/voice/settings';

const GET_VOICE_SETTINGS = '/api/v1/voice/settings';
const SAVE_SETUP_SETTINGS = '/api/v1/voice/settings/incoming/setup';
const SAVE_CALL_FLOW_SETTINGS = '/api/v1/voice/settings/incoming/call-flow';

export const getVoiceSettings = async () => {
  try {
    const result = await makeRequest(GET_VOICE_SETTINGS, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const saveSetupSettings = async (data: IVoiceSettingsRequest) => {
  try {
    const result = await makeRequest(SAVE_SETUP_SETTINGS, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const saveCallFlowSettings = async (data: IVoiceIncomingSettingsCallFlow[]) => {
  try {
    const result = await makeRequest(SAVE_CALL_FLOW_SETTINGS, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

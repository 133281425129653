import { Box, Typography } from '@mui/material';

import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';

import PublicLayoutFooter from '@/components/Public-layout/Public-layout-footer';
import PublicLayoutHeader from '@/components/Public-layout/Public-layout-header';

const SubscriptionSetupSuccess = () => {
  return (
    <Box display="flex" justifyContent="space-between" flexDirection="column" minHeight="100vh">
      <PublicLayoutHeader showSignOut showSignIn={false} showSignUp={false} />

      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{
          // 128px because it is height of AppBar and Footer components combined
          height: 'calc(100vh - 128px)',
          overflow: 'auto',
        }}
      >
        <HourglassTopRoundedIcon
          sx={{
            height: 150,
            width: 150,
          }}
          color="success"
        />

        <Typography fontSize={30} fontStyle="italic">
          Status: Business verification in progress...
        </Typography>
      </Box>

      <PublicLayoutFooter />
    </Box>
  );
};

export default SubscriptionSetupSuccess;

import * as voiceReducer from '@/store/reducers/dashboard/voice';
import { AppDispatch } from '@/store/store';

import { endCall } from './end-call';
import { isCallOnHold } from '../voice/call';
import { handleDisconnect } from './handle-disconnect';

export const handleOutboundCallEvents = (data: {
  connection: any;
  dispatch: AppDispatch;
  isResuming?: boolean;
}) => {
  const { connection, dispatch, isResuming = false } = data;

  connection
    .on('accept', () => {
      if (!isResuming) {
        dispatch(voiceReducer.setIsShowCallStatusLoader());
      }
    })
    .on('disconnect', (conn: any) => {
      const { customParameters } = conn;
      const uuid = customParameters.get('uuid');

      if (!isCallOnHold(uuid)) {
        handleDisconnect({ dispatch, uuid });
      }
    })
    .on('error', () => {
      const { customParameters } = connection;
      const uuid = customParameters.get('uuid');

      endCall({ dispatch, uuid });
    });
};

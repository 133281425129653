import { Dialog, DialogActions, DialogTitle, Button, Typography } from '@mui/material';

import Transition from './Transition';

export interface IConfirmationDialog {
  show: boolean;
  title?: string;
  subtitle?: string;
  acceptButtonColor?: string;
  rejectButtonColor?: string;
  acceptButtonText?: string;
  rejectButtonText?: string;
  handleAccept?: VoidFunction;
}

type colors = 'inherit' | 'secondary' | 'primary' | 'success' | 'error' | 'info' | 'warning';

const ConfirmationDialog = (props: {
  show: boolean;
  handleAccepted: VoidFunction | undefined;
  handleRejected: VoidFunction | undefined;
  title?: string;
  subtitle?: string;
  acceptButtonColor?: string;
  rejectButtonColor?: string;
  acceptButtonText?: string;
  rejectButtonText?: string;
}) => {
  const {
    show,
    subtitle,
    handleAccepted = () => {},
    handleRejected = () => {},
    title = 'Do you wish to proceed?',
    acceptButtonColor = 'error',
    rejectButtonColor = 'primary',
    acceptButtonText = 'Yes',
    rejectButtonText = 'No',
  } = props;

  return (
    <Dialog fullWidth maxWidth="sm" open={show} TransitionComponent={Transition}>
      <DialogTitle>{title}</DialogTitle>
      {subtitle && (
        <Typography variant="subtitle2" component="div" color={'gray'} mx={3}>
          {subtitle}
        </Typography>
      )}

      <DialogActions sx={{ mt: 2 }}>
        <Button
          onClick={() => handleRejected()}
          variant="contained"
          color={rejectButtonColor as colors}
        >
          {rejectButtonText}
        </Button>
        <Button
          onClick={() => handleAccepted()}
          variant="contained"
          color={acceptButtonColor as colors}
        >
          {acceptButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

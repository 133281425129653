import { FunctionComponent, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { useAppDispatch } from '@/store/hooks';

import { delay } from '@/helpers/delay';

import * as userThunk from '@/pages/dashboard/users/thunk';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import { TemplateDialogButton } from '@/components/Dialogs/Template-dialog';

import * as SNACKBAR from '@/constants/snackbar';

interface IRequestClickToCallDesktopAppDialogProps {
  isOpened: boolean;
  handleClose: VoidFunction;
}

const RequestClickToCallDesktopAppDialog: FunctionComponent<
  IRequestClickToCallDesktopAppDialogProps
> = ({ isOpened, handleClose }): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isClickToCallRequestButtonDisabled, setIsClickToCallRequestButtonDisabled] =
    useState<boolean>(false);

  const handleRequestClickToCallDesktopApp = async (): Promise<void> => {
    setIsClickToCallRequestButtonDisabled(true);

    const reqsponse = await dispatch(userThunk.fetchRequestClickToCallDekstopApp());

    if (reqsponse.meta.requestStatus === 'fulfilled') {
      dispatch(
        snackbarReducer.showSuccess(
          SNACKBAR.MESSAGES.USER.CLICK_TO_CALL_DESKTOP_APP_REQUEST_EMAIL_SENT,
        ),
      );
    }

    setIsClickToCallRequestButtonDisabled(false);

    await delay(500);

    handleClose();
  };

  return (
    <Dialog
      open={isOpened}
      fullWidth
      maxWidth="sm"
      onClose={() => {
        if (isClickToCallRequestButtonDisabled) {
          return;
        }

        handleClose();
      }}
    >
      <DialogTitle>Request Click-To-Call desktop application</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Send an email request for click-to-call desktop application
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <TemplateDialogButton
          color="primary"
          disabled={isClickToCallRequestButtonDisabled}
          onClick={handleClose}
        >
          Cancel
        </TemplateDialogButton>

        <TemplateDialogButton
          color="primary"
          disabled={isClickToCallRequestButtonDisabled}
          onClick={handleRequestClickToCallDesktopApp}
        >
          Send
        </TemplateDialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default RequestClickToCallDesktopAppDialog;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import generateUuid from '@/utils/uuid';

import * as SNACKBAR from '@/constants/snackbar';

type barTypes = 'success' | 'error' | 'warning' | 'info';

export interface ISnackbarState {
  uuid: string;
  message: string | null;
  barType: barTypes;
}

const initialState: ISnackbarState = {
  message: null,
  barType: 'success',
  uuid: generateUuid(),
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSuccess: (state, action: PayloadAction<string | undefined>) => {
      state.uuid = generateUuid();
      state.message = action.payload || SNACKBAR.MESSAGES.SUCCESS;
      state.barType = SNACKBAR.TYPES.SUCCESS;
    },
    showError: (state, action: PayloadAction<string | undefined>) => {
      state.uuid = generateUuid();
      state.message = action.payload || SNACKBAR.MESSAGES.SOME_ERROR_OCURRED;
      state.barType = SNACKBAR.TYPES.ERROR;
    },
    showInfo: (state, action: PayloadAction<string>) => {
      state.uuid = generateUuid();
      state.message = action.payload;
      state.barType = SNACKBAR.TYPES.INFO;
    },
    showWarning: (state, action: PayloadAction<string>) => {
      state.uuid = generateUuid();
      state.message = action.payload;
      state.barType = SNACKBAR.TYPES.WARNING;
    },
    reset: (state) => {
      state.message = null;
      state.barType = SNACKBAR.TYPES.SUCCESS;
    },
  },
});

export const { showSuccess, showInfo, showError, showWarning, reset } = snackbarSlice.actions;

export default snackbarSlice.reducer;

import React from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import * as styles from './styles';

import * as voiceReducer from '@/store/reducers/dashboard/voice';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = (props: { handleStartCall: () => Promise<void> }) => {
  const { handleStartCall } = props;
  const dispatch = useAppDispatch();

  const { showEmergencyCallDialog } = useAppSelector((state) => state.voice);

  const handleClose = () => {
    dispatch(voiceReducer.setShowEmergencyCallDialog(false));
  };

  const handleYes = () => {
    dispatch(voiceReducer.setShowEmergencyCallDialog(false));
    handleStartCall();
  };

  return (
    <Dialog
      open={showEmergencyCallDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogContent sx={styles.dialogContent1SX}>
        <DialogContentText sx={styles.dialogContentText1SX}>
          You are trying to reach 911 - Emergency Services. Please note that a service charge of
          $85USD is applicable for all 911 calls. If you accept, you may proceed by pressing "Yes"
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={styles.dialogAction1SX}>
        <Button onClick={handleYes}>Yes</Button>
        <Button onClick={handleClose}>No</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialogSlide;

import { PropsWithChildren, ReactElement } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { ROLES } from '@/constants/roles';
import { useAppSelector } from '@/store/hooks';

interface IRolesGuardProps extends Required<PropsWithChildren> {
  roles: ROLES[];
  // either defaultOnNoAccessRedirectTo or onNoAccessRedirectTo must be provided
  defaultOnNoAccessRedirectTo: string;
  onNoAccessRedirects?: Record<ROLES, string>;
}

const RolesGuard = ({
  roles,
  onNoAccessRedirects,
  defaultOnNoAccessRedirectTo,
  children,
}: IRolesGuardProps) => {
  const user = useAppSelector((state) => state.user.user);
  const location = useLocation();

  const i = roles.indexOf(user?.role as any);

  if (i === -1) {
    const redirectTo = onNoAccessRedirects?.[roles[i]] ?? defaultOnNoAccessRedirectTo;

    if (!location.pathname.includes(redirectTo)) {
      return <Navigate replace to={redirectTo} />;
    }
  }

  return children as ReactElement;
};

export default RolesGuard;

import { _getAccessTokenSilently } from '@/hooks/use-auth';

import { IDecodedToken } from '@/interfaces/auth/jwt';

import { addHours, isAfter } from '@/helpers/date/date';

import { decode } from '@/utils/jwt';

export const getAndSaveToken = async (): Promise<string> => {
  const newToken = await _getAccessTokenSilently({ cacheMode: 'off' });

  const decoded = decode<IDecodedToken>(newToken);

  if (decoded) {
    localStorage.setItem('t', newToken);
    localStorage.setItem('exp_at', decoded.exp.toString());
  }

  return newToken;
};

const getToken = async (): Promise<string> => {
  const extAt = localStorage.getItem('exp_at');

  if (extAt && isAfter(new Date(+extAt * 1000), addHours(new Date(), 1))) {
    return `Bearer ${localStorage.getItem('t')}`;
  } else {
    const newToken = await getAndSaveToken();

    return `Bearer ${newToken}`;
  }
};

export default getToken;

import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
  SyntheticEvent,
} from 'react';

import ImageViewer from 'react-simple-image-viewer';

import {
  Box,
  Paper,
  LinearProgress,
  Modal,
  List,
  IconButton,
  TextField,
  InputAdornment,
  CircularProgress,
  Button,
  Typography,
} from '@mui/material';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';

import { IMessageResult } from '@/interfaces/message';
import { IChatResult } from '@/interfaces/chat';
import { IPaginationParams, IPartialResult } from '@/interfaces/querying-data';
import { IIoTDeviceCommandResult } from '@/interfaces/iot-device';

import sendMsgImage from '@/assets/images/img-send-msg.png';

import { ISendMessageContent } from '@/pages/dashboard/chat/Chats-messages-layout';
import Message from '@/pages/dashboard/chat/chat-messages/iot-device/Message';

import apiServicesV1 from '@/services/api/v1/index';

import * as styles from '@/pages/dashboard/chat/style';

import { defStyles } from '@/theme';

import { NO_DATA_VALUE } from '@/constants/layout';

interface IMessagesProps {
  isMessageSending: boolean;
  isMessagesLoading: boolean;
  isSearchActive: boolean;
  selectedChat: IChatResult | null;
  messagesResult: IPartialResult<IMessageResult>;
  setQueryParams: Dispatch<SetStateAction<IPaginationParams>>;
  setChatsResult: React.Dispatch<React.SetStateAction<IPartialResult<IChatResult>>>;
  sendMessageContent: ISendMessageContent;
  setSendMessageContent: Dispatch<SetStateAction<ISendMessageContent>>;
  handleSendMessage: (event: KeyboardEvent | MouseEvent | SyntheticEvent) => void;
  handleSelectMessage: (message: IMessageResult) => void;
}

const Messages: FunctionComponent<IMessagesProps> = ({
  isMessagesLoading,
  isMessageSending,
  isSearchActive,
  selectedChat,
  messagesResult,
  setQueryParams,
  sendMessageContent,
  setSendMessageContent,
  handleSendMessage,
  handleSelectMessage,
}): JSX.Element => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [showCommands, setShowCommands] = useState<boolean>(false);
  const [commands, setCommands] = useState<IIoTDeviceCommandResult[]>([]);
  const [commandSearch, setCommandSearch] = useState<string>('');

  const handleGetCommands = async (): Promise<void> => {
    if (!selectedChat?.iotDeviceRef) {
      return;
    }

    const { data, success } = await apiServicesV1.iotDevicesApis.getCommands(
      selectedChat.iotDeviceRef,
    );
    if (success) {
      setCommands(data);
    }
  };

  const handleShowCommands = async (): Promise<void> => {
    const newShowCommands = !showCommands;

    setShowCommands(newShowCommands);

    if (newShowCommands && !commands.length && selectedChat?.iotDeviceRef) {
      await handleGetCommands();
    }
  };

  const handleSelectCommand = (data: IIoTDeviceCommandResult): void => {
    setSendMessageContent((prevState) => ({
      ...prevState,
      text: data.command,
    }));
  };

  useEffect(() => {
    setCommandSearch('');
    setCommands([]);
    handleGetCommands();
  }, [selectedChat?.id]);

  return (
    <Box display="flex" flexDirection="column" flex={1} overflow="auto" component={Paper}>
      <Box>
        {isMessagesLoading ? <LinearProgress variant="indeterminate" color="primary" /> : null}
      </Box>

      <Box sx={styles.paperSx} flex={1} alignItems={'center'}>
        {/* //TODO: move it to the component */}
        <Modal open={!!imagePreview}>
          <Box>
            <ImageViewer
              src={[imagePreview ?? '']}
              currentIndex={0}
              disableScroll={true}
              closeOnClickOutside={true}
              onClose={() => setImagePreview(null)}
              backgroundStyle={styles.imagePreview}
            />
          </Box>
        </Modal>

        <List
          sx={{
            '&::-webkit-scrollbar': { display: 'none' },
            bgcolor: 'background.paper',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column-reverse',
            width: '100%',
          }}
        >
          {messagesResult.records.map((message) => (
            <Message
              key={message.id}
              message={message}
              setImagePreview={setImagePreview}
              onAvatarClick={() => handleSelectMessage(message)}
            />
          ))}

          {selectedChat &&
          !isMessagesLoading &&
          messagesResult.maxCount > messagesResult.records.length ? (
            <Button
              fullWidth
              color="secondary"
              onClick={() => {
                setQueryParams((prevState) => ({
                  ...prevState,
                  skip: messagesResult.records.length,
                }));
              }}
            >
              <KeyboardArrowUpIcon fontSize="medium" />
            </Button>
          ) : null}
        </List>

        {showCommands && (
          <Box
            width={'99%'}
            minHeight={200}
            maxHeight={200}
            bgcolor={'primary.main'}
            borderRadius={2}
            component={Paper}
            p={1}
            flexDirection={'column'}
            display={'flex'}
            gap={0.5}
          >
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                type="search"
                size="small"
                placeholder="Search command"
                value={commandSearch}
                onChange={(e) => setCommandSearch(e.target.value)}
                sx={{ background: 'white', borderRadius: 1 }}
              />
            </Box>
            <Box overflow={'auto'} sx={defStyles.scroll}>
              {commands
                .filter((element) => {
                  if (!commandSearch) {
                    return true;
                  }

                  const commandSearchLowerCased = commandSearch.toLowerCase();
                  return (
                    element.command.includes(commandSearchLowerCased) ||
                    (element.description && element.description.includes(commandSearchLowerCased))
                  );
                })
                .map((element, index) => {
                  return (
                    <Box
                      bgcolor={'white'}
                      key={element.command + index}
                      border={'0.3px solid silver'}
                      borderRadius={1}
                      display={'flex'}
                      mb={0.2}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={1}
                      component={'div'}
                      onClick={() => handleSelectCommand(element)}
                      sx={{ ':hover': { cursor: 'pointer' } }}
                    >
                      <Box display={'flex'} flexDirection={'column'}>
                        <Typography>{element.command}</Typography>
                        <Typography fontSize={13}>
                          {element.description ?? NO_DATA_VALUE}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        )}

        {isSearchActive ? null : (
          <Box display="flex" flexDirection="column" gap={1} p={1} width={'100%'}>
            {sendMessageContent.files.length ? (
              <Box
                component={Paper}
                display="flex"
                flexDirection="column"
                maxHeight="160px"
                flex={1}
                overflow="auto"
                sx={defStyles.scroll}
              >
                {sendMessageContent.files.map((file, index) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    key={file.name + file.size + file.lastModified + index}
                  >
                    <IconButton
                      aria-label="comment"
                      color="error"
                      onClick={() =>
                        setSendMessageContent((prevState) => ({
                          ...prevState,
                          files: prevState.files.filter((el, i) => i !== index),
                        }))
                      }
                    >
                      <DeleteOutlineIcon />
                    </IconButton>

                    <Typography variant="body2">{file.name}</Typography>
                  </Box>
                ))}
              </Box>
            ) : null}

            <Box component="form" sx={styles.inputBoxSX} noValidate autoComplete="off">
              {/* SEND BUTTON */}
              <TextField
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                value={sendMessageContent.text}
                onChange={(event) =>
                  setSendMessageContent((prevState) => ({
                    ...prevState,
                    text: event.target.value,
                  }))
                }
                onKeyDown={handleSendMessage}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton component="label" onClick={() => handleShowCommands()}>
                        {showCommands ? (
                          <ExpandMoreOutlinedIcon fontSize="large" />
                        ) : (
                          <ExpandLessOutlinedIcon fontSize="large" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled={isMessageSending} onClick={handleSendMessage}>
                        {isMessageSending ? (
                          <CircularProgress color="inherit" />
                        ) : (
                          <Box
                            component="img"
                            sx={{ width: '50px' }}
                            src={sendMsgImage}
                            loading="lazy"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Messages;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IActiveProvider } from '@/interfaces/provider';
import { ITenantResult } from '@/interfaces/tenant';
import { IPhoneNumbersResult } from '@/interfaces/phone-numbers';

import { fetchSetTimeZone, fetchTenantInfo } from '@/store/thunk/tenant';
import {
  fetchTurnOnOffCallsRecordings,
  fetchTurnOnOffLiveCallsTranscription,
  fetchTurnOnOffVoicemailTranscription,
} from '@/pages/dashboard/settings/thunk';
import { fetchGetTenantPhoneNumbersList } from '@/store/thunk/phone-numbers';

export interface ITenantSlice {
  tenant: ITenantResult | null;
  activeProvider: IActiveProvider | null;
  phoneNumbers: IPhoneNumbersResult[];
}

const initialState: ITenantSlice = {
  tenant: null,
  activeProvider: null,
  phoneNumbers: [],
};

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
    setTenant: (state, action: PayloadAction<ITenantResult | null>) => {
      state.tenant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTenantInfo.fulfilled, (state, action) => {
      state.tenant = action.payload;
      state.activeProvider = action.payload.providers.find((provider: any) => provider.active);
    });
    builder.addCase(fetchTurnOnOffCallsRecordings.fulfilled, (state, action) => {
      state.tenant = action.payload;
    });
    builder.addCase(fetchTurnOnOffLiveCallsTranscription.fulfilled, (state, action) => {
      state.tenant = action.payload;
    });
    builder.addCase(fetchTurnOnOffVoicemailTranscription.fulfilled, (state, action) => {
      state.tenant = action.payload;
    });
    builder.addCase(fetchSetTimeZone.fulfilled, (state, action) => {
      state.tenant = action.payload;
    });
    builder.addCase(fetchGetTenantPhoneNumbersList.fulfilled, (state, action) => {
      state.phoneNumbers = action.payload;
    });
  },
});

export const { setTenant, reset } = tenantSlice.actions;

export default tenantSlice.reducer;

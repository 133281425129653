import React from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import Button from '@mui/material/Button';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';

import * as styles from './styles';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as bmcReducer from '@/pages/dashboard/broadcast/campaign/reducer';

import FILE from '@/constants/file';
import ERRORS from '@/constants/errors';
import BROADCAST from '@/constants/broadcast';

const AttachFile: React.FC = () => {
  const dispatch = useAppDispatch();

  const { files, type } = useAppSelector((state) => state.broadcastCampaign);

  const handleFile = (event: any): void => {
    const isSMS = type === BROADCAST.TYPES.SMS;
    const FILE_LIMITS = isSMS ? BROADCAST.SMS_FILE_LIMITS : BROADCAST.VOICE_FILE_LIMITS;

    const selectedFiles = [...event.target.files];
    const filesCount = files ? files.length + selectedFiles.length : selectedFiles.length;

    if (
      !isSMS &&
      selectedFiles.some((element) => !Object.values(BROADCAST.MIME_TYPES).includes(element.type))
    ) {
      dispatch(snackbarReducer.showWarning(ERRORS.FILES.UNSUPPORTED_FILE_TYPE));
      return;
    }

    if (filesCount > FILE_LIMITS.MAX_FILES_COUNT) {
      dispatch(
        snackbarReducer.showWarning(
          ERRORS.FILES.MAX_FILES_COUNT.replace('$count', FILE_LIMITS.MAX_FILES_COUNT.toString()),
        ),
      );
      return;
    }

    dispatch(bmcReducer.setFiles([...files, ...selectedFiles]));
    dispatch(bmcReducer.setInvalidSteps([]));
  };

  const handleDeleteFile = (index: number) => {
    dispatch(bmcReducer.setFiles(files.filter((element, i: number) => i !== index)));
  };

  const isSelectFilesDisabled = (): boolean => {
    const MAX_FILES_COUNT =
      type === BROADCAST.TYPES.SMS
        ? BROADCAST.SMS_FILE_LIMITS.MAX_FILES_COUNT
        : BROADCAST.VOICE_FILE_LIMITS.MAX_FILES_COUNT;

    return files.length === MAX_FILES_COUNT;
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={2} overflow="auto">
      {type === BROADCAST.TYPES.VOICE && (
        <Typography mb={1} fontSize={13}>
          Allowed audio file formats are: mp3, wav, wave
        </Typography>
      )}

      <Box
        display="flex"
        gap={type === BROADCAST.TYPES.SMS ? 3 : 0}
        alignItems={type === BROADCAST.TYPES.SMS ? 'center' : 'initial'}
      >
        <Button
          disabled={isSelectFilesDisabled()}
          color="primary"
          variant="contained"
          component="label" //@ts-ignore
          onClick={(event: React.ChangeEvent<HTMLInputElement>) =>
            //@ts-ignore
            (event.target.value = null)
          }
        >
          {type === BROADCAST.TYPES.SMS ? 'Select files' : 'Attach audio file'}
          <input
            hidden
            accept="*"
            multiple={type === BROADCAST.TYPES.SMS}
            type="file" //@ts-ignore
            onChange={handleFile}
          />
        </Button>

        {type === BROADCAST.TYPES.SMS && (
          <ImageListItem sx={styles.filesCounterBtnSX} key="Subheader" cols={3}>
            <Typography variant="body2">{files.length} of 10</Typography>
          </ImageListItem>
        )}
      </Box>

      <ImageList sx={styles.imageListSX}>
        {files.map((item: any, index: number) => (
          <ImageListItem sx={styles.imageListItemSX} key={URL.createObjectURL(item)}>
            {Object.values(FILE.IMAGE.EXTENSION).includes(
              `.${item.name.split('.').reverse()[0]}`,
            ) ? (
              <img style={styles.image} src={URL.createObjectURL(item)} loading="lazy" />
            ) : (
              <DescriptionIcon sx={styles.descriptionSX} color="inherit" />
            )}

            <ImageListItemBar
              sx={styles.imageListItemBarSX}
              title={item.name.split('.').reverse()[1]}
              subtitle={`.${item.name.split('.').reverse()[0]}`}
              actionIcon={
                <IconButton onClick={() => handleDeleteFile(index)} sx={styles.iconBtn1SX}>
                  <DeleteOutlineIcon color="error" />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};

export default AttachFile;

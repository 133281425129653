import ReactAudioPlayer from 'react-audio-player';
import { useEffect, useState } from 'react';
import React from 'react';
import { TransitionProps } from 'notistack';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  List,
  ListItem,
  Paper,
  Slide,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  ListSubheader,
} from '@mui/material';

import { ICallsTranscriptionResult } from '@/interfaces/voice/call-transcription';
import { ICallsRecordingsResult } from '@/interfaces/voice/call-recordings';
import { ICallsHistoryResult } from '@/interfaces/voice/call';

import { format } from '@/helpers/date/date';
import * as dateHelper from '@/helpers/date/date';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as voiceThunk from '../thunk';
import { useAppDispatch } from '@/store/hooks';

import useAccess from '@/hooks/use-access';

import CustomAvatar from '@/components/Custom-avatar/Avatar';
import ConfirmationDialog, { IConfirmationDialog } from '@/components/Dialogs/Confirmation';

import { ROLES } from '@/constants/roles';
import { ADDONS } from '@/constants/addons';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type Tab = 'recordings' | 'transcription' | 'voicemail' | 'forwardings';

const CallDetailsDialog = (props: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  call: ICallsHistoryResult;
  handleCallHistoryChanges: (data: {
    id: string;
    hasRecording?: boolean;
    hasTranscription?: boolean;
  }) => void;
}) => {
  const { show, setShow, call, handleCallHistoryChanges } = props;
  const dispatch = useAppDispatch();
  const { isUserRoleMatches, isAddonAvailable } = useAccess();

  const [callTranscription, setCallTranscription] = useState<ICallsTranscriptionResult>();
  const [callRecording, setCallRecording] = useState<ICallsRecordingsResult>();
  const [isLoadingCallRecordings, setIsLoadingCallRecordings] = useState<boolean>(false);
  const [isLoadingCallTranscription, setIsLoadingCallTranscription] = useState<boolean>(false);
  const [isLoadingVoicemail, setIsLoadingVoicemail] = useState<boolean>(false);
  const [audioTracks, setAudioTracks] = useState<{ name: string; track: string }[]>([]);
  const [voicemailTrack, setVoicemailTrack] = useState<string>();
  const [selectedTab, setSelectedTab] = useState<Tab>('recordings');
  const [confirmationDialog, setConfirmationDialog] = useState<IConfirmationDialog>({
    show: false,
  });

  const handleClose = (): void => {
    setShow(false);
  };

  const handleGetCallTranscription = async (): Promise<void> => {
    setIsLoadingCallTranscription(true);
    const { meta, payload } = await dispatch(
      voiceThunk.fetchCallTranscriptionByCallHistoryId(call.id),
    );

    if (meta.requestStatus === 'fulfilled') {
      setCallTranscription(payload);
    }
    setIsLoadingCallTranscription(false);
  };

  const handleGetCallRecording = async (): Promise<void> => {
    setIsLoadingCallRecordings(true);
    const { meta, payload } = await dispatch(voiceThunk.fetchCallRecordingByCallHistoryId(call.id));

    if (meta.requestStatus === 'fulfilled') {
      setCallRecording(payload);
      await downloadCallRecordingsTracks(payload?.recordings || []);
    }
    setIsLoadingCallRecordings(false);
  };

  const handleDeleteCallRecordings = async (): Promise<void> => {
    if (!callRecording) {
      return;
    }
    const { meta } = await dispatch(voiceThunk.fetchDeleteCallRecordings(callRecording.id));
    if (meta.requestStatus === 'fulfilled') {
      setCallRecording(undefined);
      setAudioTracks([]);
      dispatch(snackbarReducer.showSuccess('Call recordings deleted successfully.'));
      setConfirmationDialog({ show: false });
      handleCallHistoryChanges({ id: call.id, hasRecording: false });
    }
  };

  const downloadCallRecordingsTracks = async (
    recordings: ICallsRecordingsResult['recordings'],
  ): Promise<void> => {
    setAudioTracks([]);
    for (const recording of recordings) {
      const response = await dispatch(voiceThunk.fetchDownloadCallRecordingTrack(recording.key));
      if (response.meta.requestStatus === 'fulfilled') {
        setAudioTracks((current) => [
          ...current,
          {
            name: `${recording.name}${recording.extension}`,
            track: URL.createObjectURL(response.payload),
          },
        ]);
      }
    }
  };

  const downloadVoicemailTrack = async (): Promise<void> => {
    if (isLoadingVoicemail || !call.hasVoicemail) {
      return;
    }
    setIsLoadingVoicemail(true);
    setVoicemailTrack(undefined);

    const response = await dispatch(voiceThunk.fetchDownloadVoicemailTrack(call.id));
    if (response.meta.requestStatus === 'fulfilled') {
      setVoicemailTrack(URL.createObjectURL(response.payload));
    }
    setIsLoadingVoicemail(false);
  };

  const handleSelectTab = async (newTab: Tab): Promise<void> => {
    if (!newTab) {
      return;
    }

    setSelectedTab(newTab);

    if (newTab === 'forwardings') {
      return;
    }
    if (newTab === 'recordings' && !callRecording) {
      await handleGetCallRecording();
    }
    if (newTab === 'transcription' && !callTranscription) {
      await handleGetCallTranscription();
    }
    if (newTab === 'voicemail' && !voicemailTrack) {
      await downloadVoicemailTrack();
    }
  };

  const handleDeleteCallTranscription = async (): Promise<void> => {
    if (!callTranscription) {
      return;
    }
    const { meta } = await dispatch(
      voiceThunk.fetchDeleteCallTranscriptionById(callTranscription.id),
    );
    if (meta.requestStatus === 'fulfilled') {
      setCallTranscription(undefined);
      dispatch(snackbarReducer.showSuccess('Call transcription deleted successfully.'));
      setConfirmationDialog({ show: false });
      handleCallHistoryChanges({ id: call.id, hasTranscription: false });
    }
  };

  const handleExecuteTranscribeRecording = async (): Promise<void> => {
    if (!callRecording) {
      return;
    }
    setCallTranscription(undefined);
    const { meta } = await dispatch(voiceThunk.fetchTranscribeCallRecording(callRecording.id));
    if (meta.requestStatus === 'fulfilled') {
      dispatch(
        snackbarReducer.showSuccess('We are processing AI Transcription for selected recording.'),
      );
      setConfirmationDialog({ show: false });
    }
  };

  const handleDelete = (): void => {
    if (selectedTab === 'recordings') {
      setConfirmationDialog({
        show: true,
        title: 'Do you wish to delete call recording?',
        subtitle: `Performing this action will permanently delete call recording, and there's no way to undo it.`,
        handleAccept: handleDeleteCallRecordings,
      });
    }
    if (selectedTab === 'transcription') {
      setConfirmationDialog({
        show: true,
        title: 'Do you wish to delete call transcription?',
        subtitle: `Performing this action will permanently delete call transcription, and there's no way to undo it.`,
        handleAccept: handleDeleteCallTranscription,
      });
    }
  };

  const handleTranscribeCallRecording = (): void => {
    setConfirmationDialog({
      show: true,
      title: 'Do you wish to transcribe this recording?',
      subtitle: `Transcribing this recording might take a little time, usually a couple of minutes. Once it's ready, you'll find the results over on the Transcription tab.`,
      acceptButtonColor: 'secondary',
      acceptButtonText: 'Run AI Transcription',
      handleAccept: handleExecuteTranscribeRecording,
    });
  };

  const handleDownloadCallTranscription = async (): Promise<void> => {
    if (!callTranscription) {
      return;
    }
    const { meta, payload } = await dispatch(
      voiceThunk.fetchDownloadCallTranscription(callTranscription.id),
    );
    if (meta.requestStatus === 'fulfilled') {
      const url = window.URL.createObjectURL(new Blob([payload]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `call_transcription_${Date.now()}.txt`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  const handleEmailCallTranscription = async (): Promise<void> => {
    if (!callTranscription) {
      return;
    }
    const { meta } = await dispatch(voiceThunk.fetchEmailCallTranscription(callTranscription.id));
    if (meta.requestStatus === 'fulfilled') {
      dispatch(
        snackbarReducer.showSuccess(
          'We have just sent AI Call Transcription to your email address.',
        ),
      );
    }
  };

  useEffect(() => {
    if (show) {
      handleGetCallRecording();
    } else {
      setSelectedTab('recordings');
      setCallRecording(undefined);
      setCallTranscription(undefined);
      setVoicemailTrack(undefined);
      setAudioTracks([]);
    }
  }, [show]);

  return (
    <BootstrapDialog
      fullWidth
      maxWidth="sm"
      open={show}
      keepMounted={true}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <ConfirmationDialog
        show={confirmationDialog.show}
        title={confirmationDialog.title}
        subtitle={confirmationDialog.subtitle}
        handleAccepted={confirmationDialog.handleAccept}
        handleRejected={() => setConfirmationDialog({ show: false })}
        acceptButtonText={confirmationDialog.acceptButtonText ?? 'Delete'}
        rejectButtonText={confirmationDialog.rejectButtonText ?? 'Cancel'}
        acceptButtonColor={confirmationDialog.acceptButtonColor ?? 'error'}
        rejectButtonColor={confirmationDialog.rejectButtonColor ?? 'secondary'}
      />
      <DialogContent sx={{ px: 1, position: 'relative' }}>
        <ToggleButtonGroup
          fullWidth
          size="extra-small"
          exclusive
          value={selectedTab}
          onChange={(event, newTab) => handleSelectTab(newTab)}
        >
          <ToggleButton value={'recordings'} sx={{ borderRight: '2px solid black' }}>
            Recording
          </ToggleButton>
          <ToggleButton value={'transcription'} sx={{ borderRight: '2px solid black' }}>
            Transcription
          </ToggleButton>
          <ToggleButton value={'voicemail'} sx={{ borderRight: '2px solid black' }}>
            Voicemail
          </ToggleButton>
          <ToggleButton value={'forwardings'}>Forwarding</ToggleButton>
        </ToggleButtonGroup>

        {selectedTab === 'recordings' && (
          <Box p={1} component={Paper}>
            <Box height={10}>
              {isLoadingCallRecordings && (
                <LinearProgress variant="indeterminate" color="primary" />
              )}
              {!isLoadingCallRecordings && !audioTracks.length && (
                <Typography>No recordings found for this call</Typography>
              )}
            </Box>

            <List sx={{ overflow: 'auto', height: audioTracks.length <= 1 ? 80 : '100%' }}>
              {audioTracks.map((element) => {
                return (
                  <Box key={element.name} width={'100%'}>
                    <ReactAudioPlayer
                      style={{ width: '100%' }}
                      src={element.track}
                      controls
                      title={element.name}
                      preload="metadata"
                    />
                  </Box>
                );
              })}
            </List>
          </Box>
        )}

        {selectedTab === 'transcription' && (
          <Box component={Paper} p={1}>
            <Box height={10}>
              {isLoadingCallTranscription && (
                <LinearProgress variant="indeterminate" color="primary" />
              )}
              {!isLoadingCallTranscription && !callTranscription?.transcriptions.length && (
                <Typography>No transcription found for this call</Typography>
              )}
            </Box>

            <List
              sx={{
                overflow: 'auto',
                height: callTranscription?.transcriptions.length ? 600 : 80,
              }}
              dense={false}
              subheader={
                callTranscription?.transcriptions?.length ? (
                  <ListSubheader>
                    <Typography fontSize={11} color={'GrayText'}>
                      {callTranscription?.isLive
                        ? 'This transcription was generated during the call (AI Live Call Transcription).'
                        : 'This transcription was generated using call recording (AI Call Recording Transcription).'}
                    </Typography>
                  </ListSubheader>
                ) : null
              }
            >
              {callTranscription?.transcriptions.map((element, index) => {
                return (
                  <ListItem alignItems="flex-start" key={index} sx={{ p: 0, py: 1 }}>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      width={'100%'}
                      p={1}
                    >
                      <CustomAvatar url={''} key={index} showLiveStatus={false} />
                      <Box display={'flex'} width={'100%'} ml={1} flexDirection={'column'}>
                        <Typography fontSize={12}>{element.label}</Typography>
                        <Typography>{element.text}</Typography>
                        <Box
                          display={'flex'}
                          width={'100%'}
                          justifyContent={'space-between'}
                          flexDirection={{ xs: 'column', md: 'row' }}
                        >
                          <Typography fontSize={11} color={'Highlight'}>
                            AI transcription accuracy: {(element.confidence * 100).toFixed()}%
                          </Typography>

                          <Typography fontSize={11} color={'GrayText'}>
                            {format(element.createdAt, 'hh:mm:ss a')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )}

        {selectedTab === 'forwardings' && (
          <Box component={Paper} p={1}>
            <Box height={10}>
              {!call.forwardings.length && (
                <Typography>No forwarding found for this call</Typography>
              )}
            </Box>

            <List
              sx={{
                overflow: 'auto',
                maxHeight: 400,
                minHeight: 80,
              }}
              dense={false}
              subheader={
                call.forwardings.length ? (
                  <ListSubheader>
                    <Typography fontSize={11} color={'GrayText'}>
                      Call forwarded to:
                    </Typography>
                  </ListSubheader>
                ) : null
              }
            >
              {call.forwardings.map((element, index) => {
                return (
                  <ListItem alignItems="flex-start" key={index} sx={{ p: 0, py: 1 }}>
                    <Box
                      key={index}
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      width={'100%'}
                      p={1}
                    >
                      <Box display={'flex'} width={'100%'} ml={1} flexDirection={'column'}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Box
                            width={'19%'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDirection={'column'}
                          >
                            <CustomAvatar
                              url={element.avatarUrl}
                              key={index}
                              showLiveStatus={false}
                            />
                            <Box
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              flexDirection={'column'}
                              flex={1}
                              width={'100%'}
                              overflow={'hidden'}
                            >
                              <Typography
                                fontSize={13}
                                textAlign={'center'}
                                className={
                                  element.fullName && element.fullName.length > 10
                                    ? 'participant-name-number-sliding-animation'
                                    : ''
                                }
                                component="span"
                                overflow="hidden"
                                whiteSpace="nowrap"
                              >
                                {element.fullName ?? element.phoneNumber}
                              </Typography>
                              {element.companyExtension && (
                                <Typography fontSize={12}>{element.companyExtension}</Typography>
                              )}
                            </Box>
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'space-between'}
                            flexWrap={'wrap'}
                            bgcolor={'primary.main'}
                            borderRadius={4}
                            width={'79%'}
                            p={1}
                          >
                            <Typography color={'white'} fontSize={12} mx={1}>
                              {element.forwardedBySystem
                                ? `Call was forwarded based on 'Always Forward' option.`
                                : 'Call forwarding action performed by user.'}
                            </Typography>
                            <Typography color={'white'} fontSize={12} mx={1}>
                              {element.isInternal
                                ? 'Call forwarded to an internal extension.'
                                : 'Call forwarded to external phone number.'}
                            </Typography>
                            <Typography color={'white'} fontSize={12} mx={1}>
                              {element.wasAnswered
                                ? 'Forwarded call was answered by recipient.'
                                : 'Forwarded call was not answered.'}
                            </Typography>
                            <Typography color={'white'} fontSize={12} mx={1}>
                              Duration: {dateHelper.secondsToHms(element.duration)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )}

        {selectedTab === 'voicemail' && (
          <Box p={1} component={Paper}>
            <Box height={10}>
              {isLoadingVoicemail && <LinearProgress variant="indeterminate" color="primary" />}
              {!isLoadingVoicemail && !voicemailTrack && (
                <Typography>No voicemail found for this call</Typography>
              )}
            </Box>

            <Box width={'100%'} height={80}>
              {voicemailTrack && !isLoadingVoicemail && (
                <ReactAudioPlayer
                  style={{ width: '100%' }}
                  src={voicemailTrack}
                  controls
                  title={'voicemail'}
                  preload="metadata"
                />
              )}
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Box display="flex" gap={1}>
          {selectedTab === 'recordings' && (
            <>
              <Button
                onClick={handleDelete}
                variant="outlined"
                color="error"
                disabled={
                  !isUserRoleMatches([ROLES.ADMIN, ROLES.SUPER_ADMIN]) || !audioTracks.length
                }
              >
                Delete recordings
              </Button>

              {isAddonAvailable(ADDONS.CALLS_RECORDING_TRANSCRIPTION) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleTranscribeCallRecording}
                  disabled={!audioTracks.length}
                >
                  Transcribe
                </Button>
              )}
            </>
          )}
          {selectedTab === 'transcription' && (
            <>
              <Button
                onClick={handleDelete}
                variant="outlined"
                color="error"
                disabled={
                  !isUserRoleMatches([ROLES.ADMIN, ROLES.SUPER_ADMIN]) ||
                  !callTranscription?.transcriptions.length
                }
              >
                Delete transcription
              </Button>

              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownloadCallTranscription}
                  disabled={!callTranscription?.transcriptions.length}
                >
                  Download
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEmailCallTranscription}
                  disabled={!callTranscription?.transcriptions.length}
                >
                  Email me
                </Button>
              </>
            </>
          )}

          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default CallDetailsDialog;

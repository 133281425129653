import { createSlice } from '@reduxjs/toolkit';

export interface IAuth {
  isLoggedIn: boolean;
}

const initialState: IAuth = {
  isLoggedIn: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    set: (state) => {
      state.isLoggedIn = true;
    },
    reset: (state) => {
      state.isLoggedIn = false;
    },
  },
});

export const { set, reset } = authSlice.actions;

export default authSlice.reducer;

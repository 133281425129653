namespace SUBS {
  export enum TERM {
    MONTHLY = 'monthly',
    ANNUAL = 'annual',
  }

  export enum PRICING_TERM {
    ALL = 'all',
    MONTHLY = 'monthly',
    ANNUAL = 'annual',
  }

  //TODO: old logic
  export enum PRICE_TYPES {
    CANADA = 'canada',
    GLOBAL = 'global',
  }
}

export default SUBS;

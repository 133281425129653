import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { BroadcastTypes } from '@/types/broadcast';

import { clientTomeZone, zonedTimeToUtc } from '@/helpers/date/tz';
import { buildDate, format, isBefore, getHours, getMinutes } from '@/helpers/date/date';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import TypeText from './type-text/Type-text';
import AttachFile from './attach-file/Attach-file';
import SelectBroadcastReceiversLayout from './select-receivers/Select-receivers-layout';
import SelectDateAndTime from './select-date-and-time/Select-date-and-time';
import Review from './review/Review';
import Modal from './dialogs/alert/Dialog';

import * as bcThunk from '@/pages/dashboard/broadcast/campaign/thunk';
import * as userThunk from '@/pages/dashboard/users/thunk';

import * as menuReducer from '@/store/reducers/dashboard/menu';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as bcReducer from '@/pages/dashboard/broadcast/campaign/reducer';
import CreatingProgressDialog from './dialogs/creating-progress/Dialog';
import BroadcastValidationDialog from './dialogs/Broadcast-validation-dialog';

import * as styles from './styles';

import ROUTES from '@/constants/routes';
import DASHBOARD from '@/constants/dashboard';
import BROADCAST from '@/constants/broadcast';

const SMS_STEPS = [
  'Type a message to broadcast',
  'Attach files',
  'Select recipients',
  'Select date and time',
  'Review',
];
const VOICE_STEPS = [
  'Attach voice recording',
  'Select recipients',
  'Select date and time',
  'Review',
];

const getStepByType = (activeStep: number, type: BroadcastTypes) => {
  const views = {
    sms: {
      '0': <TypeText />,
      '1': <AttachFile />,
      '2': <SelectBroadcastReceiversLayout />,
      '3': <SelectDateAndTime />,
      '4': <Review />,
    },
    voice: {
      '0': <AttachFile />,
      '1': <SelectBroadcastReceiversLayout />,
      '2': <SelectDateAndTime />,
      '3': <Review />,
    },
  };

  //@ts-ignore
  return views[type][activeStep.toString()];
};

const BroadcastCampaignLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    activeStep,
    runDays,
    invalidSteps,
    files,
    message,
    selectedContacts,
    excludedContacts,
    selectedAllContacts,
    contactsGroupsState,
    contactsFile,
    startDate,
    stopDate,
    startTime,
    stopTime,
    showModal,
    phoneNumbersCount,
    type,
  } = useAppSelector((state) => state.broadcastCampaign);

  const tenant = useAppSelector((state) => state.tenant.tenant);

  const [isCreatingDialogVisible, setIsCreatingDialogVisible] = useState<boolean>(false);
  const [validationDialogState, setValidationDialogState] = useState<{
    isOpened: boolean;
    errors: string[];
  }>({
    isOpened: false,
    errors: [],
  });
  const [steps, setSteps] = useState<string[]>(SMS_STEPS);

  const handleNext = () => {
    const isSMS = type === BROADCAST.TYPES.SMS;

    if (activeStep === (isSMS ? 4 : 3)) {
      const stopDateWithStopTimeInUTC = zonedTimeToUtc(
        buildDate(new Date(stopDate), {
          hours: getHours(stopTime),
          minutes: getMinutes(stopTime),
        }),
        tenant?.timeZone as string,
      );

      if (!isBefore(new Date(), stopDateWithStopTimeInUTC)) {
        setValidationDialogState({
          isOpened: true,
          errors: ['Broadcast stop date is in the past'],
        });

        return;
      }

      dispatch(bcReducer.setShowModal());
      return;
    }

    const isValid = validateStep();
    if (isValid) {
      dispatch(bcReducer.setActiveStep(activeStep + 1));
    } else {
      dispatch(bcReducer.setIsStepError(true));
    }

    if (activeStep === (isSMS ? 5 : 4)) {
      dispatch(bcReducer.setShowModal());
      return;
    }
  };

  const handleBack = () => {
    dispatch(bcReducer.setActiveStep(activeStep - 1));
  };

  const setInvalidStep = () => {
    const isSMS = type === BROADCAST.TYPES.SMS;
    if (activeStep === (isSMS ? 1 : 0)) {
      dispatch(
        snackbarReducer.showWarning(
          isSMS ? 'At least a message or file should be present.' : 'Please attach audio file.',
        ),
      );
      dispatch(bcReducer.setInvalidSteps(isSMS ? [0, 1] : [0]));
    }
    if (activeStep === (isSMS ? 2 : 1)) {
      dispatch(
        snackbarReducer.showWarning(
          'Please select contacts/groups or upload Excel file with phone numbers.',
        ),
      );
      dispatch(bcReducer.setInvalidSteps([activeStep]));
    }
    if (activeStep === (isSMS ? 3 : 2)) {
      dispatch(snackbarReducer.showWarning('Please select days.'));
      dispatch(bcReducer.setInvalidSteps([activeStep]));
    }
  };

  const validateStep = () => {
    const isSMS = type === BROADCAST.TYPES.SMS;
    if (isSMS) {
      if (activeStep === 1 && !message && !files.length) {
        setInvalidStep();
        return false;
      }
    } else {
      if (activeStep === 0 && !files.length) {
        setInvalidStep();
        return false;
      }
    }

    if (
      activeStep === (isSMS ? 2 : 1) &&
      !contactsFile &&
      (selectedAllContacts ? false : !selectedContacts.length) &&
      (contactsGroupsState.selectedAllContactsGroups
        ? false
        : !contactsGroupsState.selectedContactsGroups.length)
    ) {
      setInvalidStep();
      return false;
    }

    if (activeStep === (isSMS ? 3 : 2) && !runDays.length) {
      setInvalidStep();
      return false;
    }

    dispatch(bcReducer.setInvalidSteps([]));
    return true;
  };

  const handleSave = async () => {
    if (phoneNumbersCount > 1000 || selectedContacts.length > 1000) {
      setIsCreatingDialogVisible(true);
    }

    const formData = new FormData();

    formData.append('text', message);
    formData.append('type', type);
    formData.append('startDate', format(startDate, 'yyyy-MM-dd'));
    formData.append('stopDate', format(stopDate, 'yyyy-MM-dd'));
    formData.append('startTime', format(startTime, 'HH:mm'));
    formData.append('stopTime', format(stopTime, 'HH:mm'));
    formData.append('runDays', runDays.join(', '));

    if (contactsFile) {
      formData.append('phoneNumbersFile', contactsFile);
    } else {
      if (selectedAllContacts || !!selectedContacts.length) {
        formData.append('selectedAllContacts', selectedAllContacts ? '1' : '0');

        if (selectedAllContacts) {
          formData.append('contactExcludeIds', excludedContacts.join(', '));
        } else {
          formData.append('contactsIds', selectedContacts.join(', '));
        }
      }

      if (
        contactsGroupsState.selectedAllContactsGroups ||
        !!contactsGroupsState.selectedContactsGroups.length
      ) {
        formData.append(
          'selectedAllContactsGroups',
          contactsGroupsState.selectedAllContactsGroups ? '1' : '0',
        );

        if (contactsGroupsState.selectedAllContactsGroups) {
          formData.append(
            'contactsGroupsExcludeIds',
            contactsGroupsState.excludedContactsGroups.join(', '),
          );
        } else {
          formData.append(
            'contactsGroupsIds',
            contactsGroupsState.selectedContactsGroups.join(', '),
          );
        }
      }
    }

    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append(`file${i + 1}`, files[i]);
      }
    }

    dispatch(bcReducer.setActiveStep(0));

    const result = await dispatch(bcThunk.fetchCreateBroadcastCampaign(formData));

    if (result.payload) {
      dispatch(
        snackbarReducer.showInfo(
          'Creating a Broadcast Campaign. We’ll keep you inform via email once we have the results.',
        ),
      );
      dispatch(bcReducer.setDefault());
      setSteps(SMS_STEPS);

      return navigate(ROUTES.DASHBOARD_BROADCAST_CAMPAIGN);
    }

    setIsCreatingDialogVisible(false);
  };

  const handleChangeType = (value: BroadcastTypes): void => {
    dispatch(bcReducer.setDefault());
    dispatch(bcReducer.setType(value));

    setSteps(value === BROADCAST.TYPES.SMS ? SMS_STEPS : VOICE_STEPS);
    dispatch(bcReducer.setActiveStep(0));
  };

  useEffect(() => {
    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.MESSAGE_BROADCAST_CAMPAIGN }));
    dispatch(bcReducer.setDefault());
    dispatch(userThunk.fetchCheckSession());
  }, []);

  return (
    <Box sx={styles.box1SX}>
      <BroadcastValidationDialog
        isOpened={validationDialogState.isOpened}
        errors={validationDialogState.errors}
        handleClose={() => {
          setValidationDialogState({ isOpened: false, errors: [] });
        }}
        handleGoBackAndUpdateStopTime={() => {
          setValidationDialogState({ isOpened: false, errors: [] });
          handleBack();
        }}
      />

      <CreatingProgressDialog
        isVisible={isCreatingDialogVisible}
        setIsVisible={setIsCreatingDialogVisible}
      />

      <Box position={'relative'} component={Paper} p={2}>
        <ToggleButtonGroup
          size="extra-small"
          exclusive
          value={type}
          fullWidth
          sx={{ maxWidth: '20rem', mb: 2 }}
          onChange={(event, newAlignment) => !!newAlignment && handleChangeType(newAlignment)}
        >
          <ToggleButton value={BROADCAST.TYPES.SMS}>SMS Broadcast</ToggleButton>
          <ToggleButton value={BROADCAST.TYPES.VOICE}>Voice Broadcast</ToggleButton>
        </ToggleButtonGroup>

        <Stepper activeStep={activeStep} color="secondary">
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
              error?: boolean;
              info?: boolean;
            } = {};

            if (invalidSteps.includes(index)) {
              labelProps.error = true;
            }

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep !== steps.length ? (
          <Box>
            {getStepByType(activeStep, type)}
            {showModal ? <Modal handleSave={handleSave} /> : null}

            <Box sx={styles.box3SX}>
              {activeStep ? (
                <Button
                  color="primary"
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={styles.btnSX}
                >
                  Back
                </Button>
              ) : null}

              <Box sx={styles.box2SX} />

              <Button color="primary" variant="contained" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default BroadcastCampaignLayout;

import { createAsyncThunk } from '@reduxjs/toolkit';

import handleErrors from '@/services/handle-errors';

import * as messageApi from '@/services/api/v1/message';

export const fetchMessagesInitial = createAsyncThunk(
  'messages/fetchMessagesInitial',
  async (data: { chatId: string }, thunkAPI) => {
    const response = await messageApi.getAll(data.chatId);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchMessages = createAsyncThunk(
  'messages/fetchMessages',
  async (data: { chatId: string; limit?: number; skip?: number }, thunkAPI) => {
    const response = await messageApi.getAll(data.chatId, data.limit, data.skip);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchOnLoadMore = createAsyncThunk(
  'messages/fetchOnLoadMore',
  async (data: { chatId: string; limit: number; skip: number }, thunkAPI) => {
    const response = await messageApi.getAll(data.chatId, data.limit, data.skip);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSend = createAsyncThunk(
  'messages/fetchSend',
  async (data: FormData, thunkAPI) => {
    const response = await messageApi.sendMessage(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

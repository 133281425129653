import { createAsyncThunk } from '@reduxjs/toolkit';

import { ISubsPreOrderData, ITenantSubsPreOrderRequest } from '@/interfaces/tenant';

import handleErrors from '@/services/handle-errors';

import { IThunkExtra } from '@/store/store';

export const fetchSubscriptions = createAsyncThunk(
  'subsSetup/fetchSubscriptions',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.subscriptionsApis.getAll();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSubsPreOrder = createAsyncThunk(
  'subsSetup/fetchSubsPreOrder',
  async (data: ITenantSubsPreOrderRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.subsPreOrder(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

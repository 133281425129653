namespace IOT_DEVICE {
  export enum STATUS {
    CONNECTED = 'connected',
    DISCONNECTED = 'disconnected',
    UNKNOWN = 'unknown',
  }
  export enum CONNECTION_TYPE {
    REST_API = 'rest api',
    WS = 'ws',
  }
  export enum CONNECTION_DIRECTION {
    IN = 'in',
    OUT = 'out',
  }
}

export default IOT_DEVICE;

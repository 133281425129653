import { useEffect } from 'react';

import logo from '@/assets/images/whiisl-logo.png';

const useBrowserNotifications = () => {
  const showNotification = (title: string, body: string): void => {
    if (title && body) {
      new Notification(title, {
        body,
        icon: logo,
      });
    }
  };

  useEffect(() => {
    if ('Notification' in window) {
      Notification.requestPermission();
    }
  }, []);

  return {
    showNotification,
  };
};

export default useBrowserNotifications;

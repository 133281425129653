import makeRequest from '../make-request';
import makeRequestV2 from '../make-request-v2';
import token from '../token';

import {
  IGetIotDevicesQueryParams,
  IIoTCreateDeviceRequest,
  IIoTDeviceCommandResult,
  IIoTDeviceResult,
  IIoTDeviceSendMessageRequest,
  IIoTUpdateDeviceRequest,
} from '@/interfaces/iot-device';
import { IResponseResult } from '@/interfaces/base';
import { IPartialResult } from '@/interfaces/querying-data';

const GET_IOT_DEVICES = '/api/v1/iot/devices';
const CREATE_IOT_DEVICES = '/api/v1/iot/devices';
const UPDATE_IOT_DEVICES = (id: string) => `/api/v1/iot/devices/${id}`;
const REMOVE_IOT_DEVICES = (id: string) => `/api/v1/iot/devices/${id}`;
const BULK_DELETE_IOT_DEVICES = `/api/v1/iot/devices/bulk-delete`;
const SEND_MESSAGE = `/api/v1/iot/devices/messages/send`;
const GET_COMMANDS = (iotDeviceId: string) => `/api/v1/iot/devices/${iotDeviceId}/commands`;

export const getByParams = async (
  data: IGetIotDevicesQueryParams,
): Promise<IResponseResult<IPartialResult<IIoTDeviceResult>>> => {
  const queryParams: Record<string, string> = {
    limit: data.limit.toString(),
    skip: data.skip.toString(),
  };

  if (data.search) {
    queryParams.search = data.search;
  }

  if (data.sortField) {
    queryParams.sortField = data.sortField;
  }

  if (data.sortDirection) {
    queryParams.sortDirection = data.sortDirection.toString();
  }

  return makeRequestV2(GET_IOT_DEVICES, {
    method: 'GET',
    headers: { Authorization: await token() },
    query: queryParams,
  });
};

export const create = async (data: IIoTCreateDeviceRequest) => {
  return makeRequest(CREATE_IOT_DEVICES, {
    method: 'POST',
    headers: { Authorization: await token() },
    data,
  });
};

export const update = async (id: string, data: IIoTUpdateDeviceRequest) => {
  return makeRequest(UPDATE_IOT_DEVICES(id), {
    method: 'PATCH',
    headers: { Authorization: await token() },
    data,
  });
};

export const remove = async (id: string) => {
  return makeRequest(REMOVE_IOT_DEVICES(id), {
    method: 'DELETE',
    headers: { Authorization: await token() },
  });
};

export const bulkDelete = async (data: { ids: string[] }) => {
  return makeRequest(BULK_DELETE_IOT_DEVICES, {
    method: 'POST',
    headers: { Authorization: await token() },
    data,
  });
};

export const sendMessage = async (data: IIoTDeviceSendMessageRequest) => {
  return makeRequest(SEND_MESSAGE, {
    method: 'POST',
    headers: { Authorization: await token() },
    data,
  });
};

export const getCommands = async (
  iotDeviceId: string,
): Promise<IResponseResult<IIoTDeviceCommandResult[]>> => {
  return makeRequestV2(GET_COMMANDS(iotDeviceId), {
    method: 'GET',
    headers: { Authorization: await token() },
  });
};

export const searchInputSX = {
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  border: 'none',
  '& fieldset': { border: 'none' },
};

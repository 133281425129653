import * as voiceReducer from '@/store/reducers/dashboard/voice';
import * as voiceThunk from '@/pages/dashboard/voice/thunk';
import { AppDispatch, store } from '@/store/store';

export const mute = async (data: { dispatch: AppDispatch }) => {
  const { dispatch } = data;
  const state = store.getState();
  const { voice: voiceState } = state;
  const { connections } = voiceState;

  const connection = connections.find((connection) => connection.selected);
  if (connection) {
    let newMuteValue;

    if (connection.isConference) {
      const result = await dispatch(voiceThunk.fetchMuteCall(connection.uuid));
      newMuteValue = result.payload.muted;
    } else {
      newMuteValue = !connection.conn.isMuted();
      connection.conn.mute(newMuteValue);
    }

    dispatch(voiceReducer.setMuted({ uuid: connection.uuid, mute: newMuteValue }));
  }
};

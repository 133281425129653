import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Box,
  Slide,
  IconButton,
  DialogContent,
  TextField,
  InputAdornment,
  LinearProgress,
} from '@mui/material';
import { TransitionProps } from 'notistack';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as userThunk from '@/pages/dashboard/users/thunk';
import { useAppDispatch } from '@/store/hooks';

import { getAndSaveToken } from '@/services/api/token';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const LinkNewAccountDialog = (props: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  getAccounts: () => Promise<void>;
}) => {
  const { setShow, show, getAccounts } = props;
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [newAccountCredentials, setNewAccountCredentials] = useState<{
    email: string;
    password: string;
  }>({ email: '', password: '' });

  const handleClose = (): void => {
    setShow(false);
  };

  const handleLinkNewAccount = async (): Promise<void> => {
    setIsLoading(true);
    const { meta } = await dispatch(userThunk.fetchLinkNewAccount(newAccountCredentials));
    if (meta.requestStatus === 'fulfilled') {
      await getAndSaveToken();

      dispatch(snackbarReducer.showSuccess('Account was linked successfully.'));

      setShow(false);

      await getAccounts();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!show) {
      setNewAccountCredentials({ email: '', password: '' });
      setIsLoading(false);
      setIsPasswordVisible(false);
    }
  }, [show]);

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth
      maxWidth="xs"
      open={show}
      keepMounted={false}
      onClose={handleClose}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>Link New Account</DialogTitle>

      <DialogContent>
        {isLoading ? <LinearProgress variant="indeterminate" color="primary" /> : null}

        <Box display="flex" flexDirection="column" gap={1}>
          <TextField
            fullWidth
            label="Email"
            disabled={isLoading}
            value={newAccountCredentials.email}
            onChange={(event) => {
              setNewAccountCredentials((current) => ({ ...current, email: event.target.value }));
            }}
          />

          <TextField
            fullWidth
            label="Password"
            disabled={isLoading}
            value={newAccountCredentials.password}
            type={isPasswordVisible ? 'text' : 'password'}
            onChange={(event) => {
              setNewAccountCredentials((current) => ({ ...current, password: event.target.value }));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setIsPasswordVisible((current) => !current)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => setShow(false)}>
          Close
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={handleLinkNewAccount}
        >
          Link
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkNewAccountDialog;

export const chipSX = {
  pt: 1,
  pb: 1,
  fontSize: '15px',
  maxWidth: '100%',
  height: 'auto',
  wordBreak: 'break-all',
  '& .MuiChip-label': {
    display: 'block',
    whiteSpace: 'normal',
  },
};

export const imageListItemSX = {
  minHeight: '150px',
  minWidth: '200px',
  border: '1px solid',
  borderColor: 'grey',
  borderRadius: '2px',
};

export const image = {
  borderRadius: '2px',
  height: '150px',
  width: '200px',
};

export const descriptionSX = {
  height: '50px',
  width: '50px',
};

export const imageListItemBarSX = {
  '.MuiImageListItemBar-title': { fontSize: '10px', color: 'white' },
  borderBottomRightRadius: '2px',
  borderBottomLeftRadius: '2px',
};

export const iconBtn1SX = {
  color: 'rgba(255, 255, 255, 0.54)',
};

export const attachmentsTopographySX = {
  mt: 1,
};

export const contactsTopographySX = {
  mt: 1,
  mb: 1,
};

export const dateTopographySX = {
  mb: 1,
  mt: 1,
};

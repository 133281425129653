export const mainBox2SX = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
};

export const mainBox3SX = {
  mt: 2,
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
};

export const startDayBoxSX = {
  mb: 1,
};

export const stopDayCalendarBoxSX = {
  ml: 5,
};

export const stopDayBoxSX = {
  mb: 1,
};

export const formGroupSX = {
  flexDirection: 'row',
};

export const formControlLabeSX = {
  color: 'inherit',
};

export const topographySX1 = {
  display: 'flex',
  justifyContent: 'center',
  mb: 2,
};

export const topographySX2 = {
  display: 'flex',
  justifyContent: 'center',
  mb: 2,
};

import headers from './headers';

import handleErrorsV2 from '@/services/handle-errors-v2';

const BACK_END_HOST = process.env.REACT_APP_BACKEND_HOST;

interface IOptions extends RequestInit {}

const makeRequest = async (url: string, options: IOptions & { query?: Record<string, any> }) => {
  try {
    const queryString = options.query ? '?' + new URLSearchParams(options.query).toString() : '';
    const fullUrl = BACK_END_HOST + url + queryString;

    const response = await fetch(fullUrl, {
      ...options,
      headers: { ...options.headers, ...headers.defaults() },
    });
    const json = await response.json();
    if (!json.success) {
      handleErrorsV2(json.statusCode, json.message);
    }

    return json;
  } catch (error) {
    console.error(error);

    return { isSuccess: false, message: 'Some error occurred. Please try again.' };
  }
};

export default makeRequest;

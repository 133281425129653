import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Box, Button, Paper, Stack, TextField, Typography } from '@mui/material';

import { IUserResult } from '@/interfaces/users/users';
import { IUsersGroupCreateRequest, IUsersGroupResult } from '@/interfaces/users-groups';

import usersGroupsValidationSchemas from '@/helpers/validation-schemas/users-groups/index';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import { useAppDispatch } from '@/store/hooks';

import SelectUsersFromListDialog, {
  SelectUsersFromListDialogUser,
} from '@/components/Dialogs/Select-users-from-list-dialog';

import apisV1 from '@/services/api/v1/index';

import ROUTES from '@/constants/routes';
import { FORMIK_STATUS } from '@/constants/formik';

const AddEditUsersGroupsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const usersGroup = location.state?.data as IUsersGroupResult | undefined;
  const isCreating = !usersGroup;

  const formik = useFormik({
    initialStatus: FORMIK_STATUS.IDLE,
    enableReinitialize: true,
    initialValues: isCreating
      ? {
          name: '',
          description: '',
          selectAllUsers: false,
          selectedUsers: [] as SelectUsersFromListDialogUser[],
          excludedUsers: [] as SelectUsersFromListDialogUser[],
        }
      : {
          name: usersGroup.name as string,
          description: usersGroup.description ?? '',
          selectAllUsers: false,
          selectedUsers: usersGroup.users,
          excludedUsers: [] as SelectUsersFromListDialogUser[],
        },
    validationSchema: isCreating
      ? usersGroupsValidationSchemas.create
      : usersGroupsValidationSchemas.update,
    onSubmit: async (values, formikHelpers) => {
      const body: IUsersGroupCreateRequest = {
        name: values.name,
        description: values.description,
        selectAll: values.selectAllUsers,
        selectedIds: values.selectAllUsers
          ? []
          : (values.selectedUsers.map((el) => el.id) as string[]),
        excludedIds: values.excludedUsers.map((el) => el.id) as string[],
      };

      const { success } = isCreating
        ? await apisV1.usersGroupsApis.create(body)
        : await apisV1.usersGroupsApis.update(usersGroup.id, body);

      if (success) {
        formikHelpers.resetForm();
        navigate(ROUTES.DASHBOARD_USERS_GROUPS);
        dispatch(
          snackbarReducer.showSuccess(isCreating ? 'Users Group created.' : 'Users Group updated.'),
        );
      }
    },
  });

  const [isSelectUsersDialogOpened, setIsSelectUsersDialogOpened] = useState(false);

  const getSelectedUsersLabel = () => {
    const { selectAllUsers, selectedUsers, excludedUsers } = formik.values;
    if (selectAllUsers) {
      const hasMoreThanOne = excludedUsers.length > 1;
      return `all users selected ${
        excludedUsers.length
          ? `except ${excludedUsers.length} user${hasMoreThanOne ? 's' : ''}`
          : ''
      }`;
    } else {
      return selectedUsers.length ? selectedUsers.length : 'No users selected';
    }
  };

  useEffect(() => {}, []);

  const isLoading = formik.status === FORMIK_STATUS.LOADING;

  return (
    <Box flex={1} display="flex" p={2} justifyContent="center" overflow="auto">
      <SelectUsersFromListDialog
        initialUsers={formik.values.selectedUsers}
        isOpened={isSelectUsersDialogOpened}
        setIsOpened={setIsSelectUsersDialogOpened}
        handleSubmit={(response) => {
          if (response) {
            formik.setValues((prevState) => ({
              ...prevState,
              selectAllUsers: response.isAllSelected,
              excludedUsers: response.excluded,
              selectedUsers: response.selected,
            }));
          } else {
            formik.setValues((prevState) => ({
              ...prevState,
              selectAllUsers: false,
              excludedUsers: [],
              selectedUsers: [],
            }));
          }
        }}
      />

      <Box
        component={Paper}
        display="flex"
        flexDirection="column"
        flex={1}
        gap={1}
        p={2}
        maxWidth="sm"
      >
        <Box flexGrow={1} m={2}>
          <Stack spacing={2}>
            <Typography variant="h5" color="primary.main" textAlign="center" fontWeight="bold">
              {isCreating ? 'Add' : 'Edit'} users group
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  required
                  name="name"
                  label="Name"
                  placeholder="Sales"
                  disabled={isLoading}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <TextField
                  fullWidth
                  name="description"
                  label="Description"
                  placeholder="Whistle company's sales team"
                  disabled={isLoading}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && !!formik.errors.description}
                  helperText={formik.touched.description && formik.errors.description}
                />

                <Stack spacing={1}>
                  <Stack
                    spacing={1}
                    direction="row"
                    gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle2" component="p">
                      Users: {getSelectedUsersLabel()}
                    </Typography>

                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setIsSelectUsersDialogOpened(true)}
                    >
                      Modify selection
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </Box>

        <Box display="flex" mt="auto" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            size="large"
            disabled={isLoading || !formik.isValid || formik.isSubmitting}
            onClick={formik.submitForm}
          >
            {isCreating ? 'Create' : 'Update'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditUsersGroupsPage;

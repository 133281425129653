import { Dispatch, SetStateAction, useState } from 'react';

import { useAppDispatch } from '@/store/hooks';

import {
  Box,
  Typography,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Stack,
  ImageList,
  CircularProgress,
} from '@mui/material';

import {
  Description as DescriptionIcon,
  DeleteOutline as DeleteOutlineIcon,
  UploadFile as UploadFileIcon,
} from '@mui/icons-material';

import * as bmcThunk from '@/pages/dashboard/broadcast/campaign/thunk';

import * as bmcReducer from '@/pages/dashboard/broadcast/campaign/reducer';

import CircularProgressWithLabel from '@/components/Circular-progress/With-label/Progress';

interface ISelectReceiversFromFileProps {
  selectedContacts: string[];
  contactsFile: any;
  phoneNumbersCount: number;
  isContactsFileValidating: boolean;
  setIsContactsFileValidating: Dispatch<SetStateAction<boolean>>;
}

const SelectReceiversFromFile = ({
  selectedContacts,
  contactsFile,
  phoneNumbersCount,
  isContactsFileValidating,
  setIsContactsFileValidating,
}: ISelectReceiversFromFileProps) => {
  const dispatch = useAppDispatch();

  const [isValidatingFile, setIsValidatingFile] = useState<boolean>(false);
  const [validationProgress, setValidationProgress] = useState<number>(1);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();

  const handleFile = async (event: any) => {
    startValidationProgress();
    setIsValidatingFile(true);
    setIsContactsFileValidating(true);

    const [selectedFile] = event.target.files;
    const formData = new FormData();
    formData.append('file', selectedFile);

    const result = await dispatch(bmcThunk.fetchValidateFile(formData));
    if (result.payload) {
      dispatch(bmcReducer.setContactsFile(selectedFile));
    }

    setIsValidatingFile(false);
    setValidationProgress(100);
    setTimeout(() => setIsContactsFileValidating(false), 1000);

    if (timer) {
      clearInterval(timer);
    }
  };

  const handleDeleteFile = () => {
    dispatch(bmcReducer.deleteContactsFile());
  };

  const startValidationProgress = () => {
    const timer = setInterval(() => {
      setValidationProgress((validationProgress) => {
        const newProgress = validationProgress >= 100 ? 1 : validationProgress + 1;
        if (newProgress === 100) {
          return validationProgress;
        }
        return newProgress;
      });
    }, 10_000); // 10 sec

    setTimer(timer);
  };

  return (
    <Stack
      spacing={2}
      sx={{
        height: 'calc(100vh - 425px)',
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        <IconButton
          disabled={!!selectedContacts.length || isContactsFileValidating}
          color="primary"
          size="small"
          component="label"
          onClick={(event) => ((event.target as any).value = null)}
        >
          {isContactsFileValidating ? <CircularProgress /> : <UploadFileIcon />}

          <input
            disabled={isValidatingFile}
            hidden
            accept=".xlsx, .xls"
            multiple={false}
            type="file"
            onChange={handleFile}
          />
        </IconButton>

        {isContactsFileValidating ? (
          <Box display="flex" justifyContent="center">
            <Typography mr={2}>
              Validating file. Please wait and do not close this page...
            </Typography>

            <CircularProgressWithLabel
              size={30}
              thickness={20}
              color="primary"
              value={validationProgress}
            />
          </Box>
        ) : null}
      </Box>

      <Typography variant="body1" component="p">
        {contactsFile ? `Phone numbers count: ${phoneNumbersCount}` : null}
      </Typography>

      {contactsFile ? (
        <ImageList cols={5} rowHeight={150}>
          <ImageListItem
            sx={{
              maxWidth: 235,
              border: '1px solid',
              borderColor: 'grey',
              borderRadius: 1,
            }}
          >
            <DescriptionIcon
              sx={{
                height: '50px',
                width: '50px',
              }}
              color="inherit"
            />

            <ImageListItemBar
              sx={{
                '.MuiImageListItemBar-title': { fontSize: 13 },
                '.MuiImageListItemBar-subtitle': { fontSize: 12 },
                borderBottomRightRadius: 1,
                borderBottomLeftRadius: 1,
              }}
              title={contactsFile.name.split('.').reverse()[1]}
              subtitle={`.${contactsFile.name.split('.').reverse()[0]}`}
              actionIcon={
                <IconButton
                  onClick={handleDeleteFile}
                  sx={{
                    color: 'rgba(255, 255, 255, 0.54)',
                  }}
                >
                  <DeleteOutlineIcon color="error" />
                </IconButton>
              }
            />
          </ImageListItem>
        </ImageList>
      ) : null}
    </Stack>
  );
};

export default SelectReceiversFromFile;

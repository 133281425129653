import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IFeatureAccessDialog {
  show: boolean;
  message: string | null;
}

const initialState: IFeatureAccessDialog = {
  show: false,
  message: null,
};

export const featureDialogAccessSlice = createSlice({
  name: 'featureDialogAccess',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<{ message: string } | undefined>) => {
      if (action.payload) {
        state.show = true;
        state.message = action.payload.message;
      } else {
        state.show = false;
        state.message = null;
      }
    },
  },
});

export const { show } = featureDialogAccessSlice.actions;

export default featureDialogAccessSlice.reducer;

import * as datefns from 'date-fns';

import DATE from '@/constants/date';

export const properDateType = (date: Date | string) =>
  date instanceof Date ? new Date(date) : datefns.parseISO(date);

export const format = (date: string | Date = new Date(), format = 'yyyy-MM-dd HH:mm:ss') =>
  datefns.format(new Date(date), format);

export const isToday = (date: string | Date = new Date()) => datefns.isToday(new Date(date));

export const startOfDay = (date: string | Date = new Date()) =>
  datefns.startOfDay(properDateType(date));

export const endOfDay = (date: string | Date = new Date()) =>
  datefns.endOfDay(properDateType(date));

export const eachDayOfInterval = (start = new Date(), end = new Date(), step?: number) =>
  datefns.eachDayOfInterval({ start: properDateType(start), end: properDateType(end) }, { step });

export const eachMinutesOfInterval = (start = new Date(), end = new Date(), step?: number) =>
  datefns.eachMinuteOfInterval(
    { start: properDateType(start), end: properDateType(end) },
    { step },
  );

export const isEqual = (date = new Date(), dateToCompare = new Date()) =>
  datefns.isEqual(date, dateToCompare);

export const isAfter = (date = new Date(), dateToCompare = new Date()) =>
  datefns.isAfter(date, dateToCompare);

export const isBefore = (date = new Date(), dateToCompare = new Date()) =>
  datefns.isBefore(properDateType(date), properDateType(dateToCompare));

export const isValid = (date = new Date()) => datefns.isValid(date);

export const nearestDatetime = (date = new Date(), step?: number) => {
  const intervals = eachMinutesOfInterval(
    buildDate(date, { minutes: 0 }),
    buildDate(date, { minutes: 60 }),
    step,
  );

  for (const interval of intervals) {
    if (isBefore(date, interval)) {
      return interval;
    }
  }
};

export const eachDayNameOfInterval = (
  start = new Date(),
  end = new Date(),
  step?: number,
): string[] => {
  const days = new Set();
  const dates = eachDayOfInterval(start, end, step);

  for (const date of dates) {
    //@ts-expect-error need to check this place
    const day: string = DATE.DAYS.NUMBER_DAY_SHORT[getDay(date)];
    if (days.size === 7) {
      return [...days] as string[];
    }
    days.add(day);
  }
  return [...days] as string[];
};

export const getTimeAsString = (date: Date | string = new Date()) =>
  `${getHours(date).toString().padStart(2, '0')}:${getMinutes(date)
    .toString()
    .padStart(2, '0')}:${getSeconds(date).toString().padStart(2, '0')}`;

export const getTime = (date = new Date()) => datefns.getTime(properDateType(date));

export const getDay = (date = new Date()) => datefns.getDay(properDateType(date));

export const addDays = (date = new Date(), days = 1) => datefns.addDays(properDateType(date), days);

export const addHours = (date = new Date(), hours = 1) =>
  datefns.addHours(properDateType(date), hours);

export const addMinutes = (date: Date | string = new Date(), minutes = 1) =>
  datefns.addMinutes(properDateType(date), minutes);

export const getYear = (date = new Date()): number => datefns.getYear(properDateType(date));

export const getDate = (date = new Date()): number => datefns.getDate(properDateType(date));

export const getMonth = (date = new Date()): number => datefns.getMonth(properDateType(date));

export const getHours = (date: Date | string = new Date()): number =>
  datefns.getHours(properDateType(date));

export const getMinutes = (date: Date | string = new Date()): number =>
  datefns.getMinutes(properDateType(date));

export const getSeconds = (date: Date | string = new Date()): number =>
  datefns.getSeconds(properDateType(date));

export const getMilliseconds = (date = new Date()): number =>
  datefns.getMilliseconds(properDateType(date));

export const differenceInMilliseconds = (date1 = new Date(), date2 = new Date()): number =>
  datefns.differenceInMilliseconds(properDateType(date1), properDateType(date2));

export const isTomorrow = (date = new Date()): boolean => datefns.isTomorrow(properDateType(date));

export const buildDate = (
  date = new Date(),
  options?: {
    year?: number | undefined;
    month?: number | undefined;
    date?: number | undefined;
    hours?: number | undefined;
    minutes?: number | undefined;
    seconds?: number | undefined;
    milliseconds?: number | undefined;
  },
) =>
  datefns.set(properDateType(date), {
    year: options?.year !== undefined ? options?.year : getYear(date),
    month: options?.month !== undefined ? options?.month : getMonth(date),
    date: options?.date !== undefined ? options?.date : getDate(date),
    hours: options?.hours !== undefined ? options?.hours : getHours(date),
    minutes: options?.minutes !== undefined ? options?.minutes : getMinutes(date),
    seconds: options?.seconds !== undefined ? options?.seconds : getSeconds(date),
    milliseconds:
      options?.milliseconds !== undefined ? options?.milliseconds : getMilliseconds(date),
  });

export const getNextDateByDayName = (day: string) => {
  let found = false;
  let date = new Date();
  while (!found) {
    const dayName = datefns.format(date, 'EEEE');
    //@ts-ignore
    if (DATE.DAYS.SHORT_NAME[dayName.toUpperCase()] === day) {
      found = true;
      return date;
    } else {
      date = addDays(date, 1);
    }
  }
};

export const secondsToHms = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
  const mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
  const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';

  const result = hDisplay + mDisplay + sDisplay;

  return result ? result : '0 seconds';
};

export const difference = (
  date1: Date | string = new Date(),
  date2: Date | string = new Date(),
): {
  value: number;
  unit: 'years' | 'days' | 'hours' | 'minutes' | 'seconds';
  readable: string;
} => {
  const diffInYears = datefns.differenceInYears(properDateType(date2), properDateType(date1));
  if (diffInYears >= 1) {
    return { value: diffInYears, unit: 'years', readable: `${diffInYears} years` };
  }

  const diffInDays = datefns.differenceInDays(properDateType(date2), properDateType(date1));
  if (diffInDays >= 1) {
    return { value: diffInDays, unit: 'days', readable: `${diffInDays} days` };
  }

  const diffInHours = datefns.differenceInHours(properDateType(date2), properDateType(date1));
  if (diffInHours >= 1) {
    return { value: diffInHours, unit: 'hours', readable: `${diffInHours} hours` };
  }

  const diffInMinutes = datefns.differenceInMinutes(properDateType(date2), properDateType(date1));
  if (diffInMinutes >= 1) {
    return { value: diffInMinutes, unit: 'minutes', readable: `${diffInMinutes} minutes` };
  }

  const diffInSeconds = datefns.differenceInSeconds(properDateType(date2), properDateType(date1));
  return { value: diffInSeconds, unit: 'seconds', readable: `${diffInSeconds} seconds` };
};

export const substractTimezoneOffset = (date: Date | string = new Date()) => {
  const properDate = properDateType(date);
  return new Date(properDate.valueOf() + properDate.getTimezoneOffset() * 60 * 1000);
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  fetchGetUserInfo,
  fetchUploadUserAvatar,
  fetchUpdateMe,
  fetchChangePassword,
  fetchDeleteAvatar,
  fetchUpdateMePreferences,
  fetchUpdateAllUsersPreferences,
} from '@/pages/dashboard/users/thunk';

import { fetchEdit } from '@/store/thunk/dashboard/users';

import { IUserMeResult, IUserResult } from '@/interfaces/users/users';
import { fetchAssignUnassignDirectExtensionForUser } from '../thunk/phone-numbers';

export interface IUserInitial {
  user: IUserMeResult | null;
  countryCodes: IUserResult['allowedCountries'];
  showAccountsDialog: boolean;
}

const initialState: IUserInitial = {
  user: null,
  showAccountsDialog: false,
  countryCodes: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
    openAccountsDialog: (state) => {
      state.showAccountsDialog = true;
    },
    closeAccountsDialog: (state) => {
      state.showAccountsDialog = false;
    },
    setUserMissedCalls: (state, action: PayloadAction<{ count: number }>) => {
      if (state.user) {
        state.user.missedCallsCount = action.payload.count;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetUserInfo.fulfilled, (state, action) => {
      state.user = action.payload;
      state.countryCodes = action.payload.allowedCountries;
    });
    builder.addCase(fetchUploadUserAvatar.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchEdit.fulfilled, (state, action) => {
      if (state.user?.id === action.payload.id) {
        state.user = { ...state.user, ...action.payload };
        state.countryCodes = action.payload.allowedCountries;
      }
    });
    builder.addCase(fetchUpdateMe.fulfilled, (state, action) => {
      state.user = action.payload;
      state.countryCodes = action.payload.allowedCountries;
    });
    builder.addCase(fetchAssignUnassignDirectExtensionForUser.fulfilled, (state, action) => {
      if (!state.user) {
        return;
      }

      const isSameUser = state.user?.id === action.payload.userRef;
      const isSameDirectExtension = state.user?.directExtension === action.payload.phoneNumber;

      if (isSameDirectExtension) {
        if (!isSameUser) {
          state.user.directExtension = '';
        }
      } else if (isSameUser) {
        if (!isSameDirectExtension) {
          state.user.directExtension = action.payload.phoneNumber;
        }
      }
    });
    builder.addCase(fetchChangePassword.fulfilled, (state, action) => {
      state.user = action.payload;
      state.countryCodes = action.payload.allowedCountries;
    });
    builder.addCase(fetchDeleteAvatar.fulfilled, (state, action) => {
      state.user = action.payload;
      state.countryCodes = action.payload.allowedCountries;
    });
    builder.addCase(fetchUpdateMePreferences.fulfilled, (state, action) => {
      state.user = action.payload;
      state.countryCodes = action.payload.allowedCountries;
    });
    builder.addCase(fetchUpdateAllUsersPreferences.fulfilled, (state, action) => {
      if (state.user) {
        state.user = {
          ...state.user,
          devicesPreferences: action.payload.devicesPreferences,
        };
      }
    });
  },
});

export const { reset, openAccountsDialog, closeAccountsDialog, setUserMissedCalls } =
  userSlice.actions;

export default userSlice.reducer;

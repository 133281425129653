import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useAppDispatch } from '@/store/hooks';

import {
  Stack,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Paper,
  Box,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';

import { IContactUpdateRequest } from '@/interfaces/contact';

import PhoneNumberInput from '@/components/Phone-number-input/Phone-number-input';

import * as contactsThunk from '@/pages/dashboard/contacts/thunk';

import * as menuReducer from '@/store/reducers/dashboard/menu';

import UploadFileInput from '@/components/Upload-file-input/Upload-file-input';

import contactsValidationSchemas from '@/helpers/validation-schemas/contacts/index';

import ROUTES from '@/constants/routes';
import DASHBOARD from '@/constants/dashboard';
import { CONTACT_TYPE } from '@/constants/contacts';

const AddEditContact: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [avatar, setAvatar] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const contactToEdit = location.state?.data;

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: contactToEdit
      ? {
          firstName: contactToEdit.firstName,
          lastName: contactToEdit.lastName ?? '',
          email: contactToEdit.email ?? '',
          phoneNumber: contactToEdit.phoneNumber,
        }
      : {
          firstName: '',
          lastName: '',
          email: '',
          type: location.state?.defaultType ? location.state?.defaultType : CONTACT_TYPE.COMPANY,
          phoneNumber: '',
        },
    validationSchema: !!contactToEdit
      ? contactsValidationSchemas.updateContactValidationSchema
      : contactsValidationSchemas.createContactValidationSchema,
    onSubmit: (values) => {
      addEdit(values as any);
    },
  });

  const addEdit = async (data: Omit<IContactUpdateRequest, 'file'>) => {
    const formData = new FormData();

    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName || '');
    formData.append('email', data.email || '');
    formData.append('phoneNumber', data.phoneNumber);

    if (!contactToEdit && Object.hasOwn(data, 'type')) {
      // @ts-ignore
      formData.append('type', data.type);
    }

    if (avatar) {
      formData.append('file', avatar);
    }

    setIsLoading(true);

    const result = contactToEdit
      ? await dispatch(contactsThunk.fetchEdit({ id: contactToEdit.id as string, formData }))
      : await dispatch(contactsThunk.fetchCreate(formData));

    if (result.payload) {
      navigate(ROUTES.DASHBOARD_CONTACTS);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.CONTACTS }));
  }, []);

  return (
    <Box flex={1} display="flex" p={2} justifyContent="center" overflow="auto">
      <Box
        component={Paper}
        display="flex"
        flexDirection="column"
        flex={1}
        gap={1}
        p={2}
        maxWidth="sm"
      >
        <Typography variant="h5" color="primary.main" textAlign="center" fontWeight="bold">
          {contactToEdit ? 'Edit' : 'Add'} contact
        </Typography>

        <TextField
          required
          size="small"
          variant="standard"
          label="First name"
          id="firstName"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName ? (formik.errors.firstName as string) : ''}
        />

        <TextField
          id="lastName"
          name="lastName"
          size="small"
          label="Last name"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName ? (formik.errors.lastName as string) : ''}
        />

        <TextField
          id="email"
          name="email"
          label="Email"
          variant="standard"
          size="small"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email ? (formik.errors.email as string) : ''}
        />

        <Box my={1}>
          <PhoneNumberInput
            fullWidth
            value={formik.values.phoneNumber}
            countryCodesListType={'full-list'}
            onPhoneNumberChange={(value) => {
              formik.setFieldTouched('phoneNumber', true);
              formik.setFieldValue('phoneNumber', value, false);
            }}
          />
        </Box>

        {!contactToEdit ? (
          <Stack spacing={1}>
            <Typography component="label" htmlFor="contact-type">
              Save contact as
            </Typography>

            <ToggleButtonGroup
              id="contact-type"
              size="extra-small"
              fullWidth
              exclusive
              value={formik.values.type}
              onChange={(event, value) =>
                value ? formik.setFieldValue('type', value, true) : null
              }
            >
              <ToggleButton name="devicesPreferences" value={CONTACT_TYPE.COMPANY}>
                Company
              </ToggleButton>

              <ToggleButton name="devicesPreferences" value={CONTACT_TYPE.PRIVATE}>
                Private
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        ) : null}

        <Box mt={2}>
          <UploadFileInput
            accept="image/*"
            value={avatar}
            label="Upload avatar"
            buttonProps={{
              size: 'small',
            }}
            onChange={({ file }) => {
              if (file) {
                setAvatar(file);
              } else {
                if (avatar) {
                  setAvatar(undefined);
                }
              }
            }}
          />
        </Box>

        <Box display="flex" justifyContent="flex-end" mt="auto">
          <Button
            color="primary"
            variant="contained"
            size="large"
            disabled={isLoading}
            onClick={() => {
              addEdit(formik.values as Omit<IContactUpdateRequest, 'file'>);
            }}
          >
            {isLoading ? <CircularProgress /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditContact;

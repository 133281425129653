import { createAsyncThunk } from '@reduxjs/toolkit';

import handleErrors from '@/services/handle-errors';

import { ITwoFactorAuthRequest } from '@/interfaces/2fa';

import { IThunkExtra } from '../store';

export const fetchGenerate2fa = createAsyncThunk(
  '2fa/fetchGenerate2fa',
  async (data: ITwoFactorAuthRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.twoFAApis.generate(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';

import { IThunkExtra } from '../store';

import {
  IGetPhoneNumbersParams,
  IPhoneNumbersAssignUnassignMaskingPhoneNumberForTenantRequest,
  IPhoneNumbersSetDefaultForTenantRequest,
  IUpdateAssignUnassignDirectExtensionForUserRequest,
} from '@/interfaces/phone-numbers';

import handleErrors from '@/services/handle-errors';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import * as SNACKBAR from '@/constants/snackbar';

export const fetchGetPhoneNumbers = createAsyncThunk(
  'phoneNumbers/fetchGetPhoneNumbers',
  async (data: IGetPhoneNumbersParams<any>, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.phoneNumbersApis.getPhoneNumbers(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchAssignUnassignDirectExtensionForUser = createAsyncThunk(
  'phoneNumbers/fetchAssignUnassignDirectExtensionForUser',
  async (data: IUpdateAssignUnassignDirectExtensionForUserRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.phoneNumbersApis.assignUnassignDirectExtensionForUser(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.UPDATED('Direct extension')),
      );

      return response.data;
    }
  },
);

export const fetchAssignUnassignMaskingPhoneNumberForTenant = createAsyncThunk(
  'phoneNumbers/fetchAssignUnassignMaskingPhoneNumberForTenant',
  async (data: IPhoneNumbersAssignUnassignMaskingPhoneNumberForTenantRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.phoneNumbersApis.assignUnassignMaskingPhoneNumberForTenant(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.UPDATED('Masking configuration')),
      );

      return response.data;
    }
  },
);

export const fetchSetDefaultPhoneNumberForTenant = createAsyncThunk(
  'phoneNumbers/fetchSetDefaultPhoneNumberForTenant',
  async (data: IPhoneNumbersSetDefaultForTenantRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.phoneNumbersApis.setDefaultPhoneNumberForTenant(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(
          SNACKBAR.MESSAGES.GENERAL.UPDATED('Main/Business default phone number'),
        ),
      );

      return response.data;
    }
  },
);

export const fetchGetTenantPhoneNumbersList = createAsyncThunk(
  'phoneNumbers/fetchGetTenantPhoneNumbersList',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.phoneNumbersApis.getTenantPhoneNumbersList();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';

import { IThunkExtra } from '@/store/store';

import {
  IContactsGroupCreateRequest,
  IContactsGroupUpdateRequest,
  IGetContactsGroupsQueryParams,
} from '@/interfaces/contacts-group';

import handleErrors from '@/services/handle-errors';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import * as SNACKBAR from '@/constants/snackbar';

const fetchGetByParams = createAsyncThunk(
  'contactsGroups/fetchGetByParams',
  async (data: IGetContactsGroupsQueryParams, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.contactsGroupsApis.getByParams(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

const fetchCreate = createAsyncThunk(
  'contactsGroups/fetchCreate',
  async (data: IContactsGroupCreateRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.contactsGroupsApis.create(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.CREATED('Contacts group')),
      );
      return response.data;
    }
  },
);

const fetchUpdateById = createAsyncThunk(
  'contactsGroups/fetchUpdateById',
  async ({ id, ...data }: IContactsGroupUpdateRequest & Record<'id', string>, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.contactsGroupsApis.updateById(id, data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.UPDATED('Contacts group')),
      );
      return response.data;
    }
  },
);

const fetchDeleteById = createAsyncThunk(
  'contactsGroups/fetchDeleteById',
  async ({ id }: Record<'id', string>, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.contactsGroupsApis.deleteById(id);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.DELETED('Contacts group')),
      );
      return response.data;
    }
  },
);

export default {
  fetchGetByParams,
  fetchCreate,
  fetchUpdateById,
  fetchDeleteById,
};

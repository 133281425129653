import { FunctionComponent } from 'react';

import { IConnection } from '@/interfaces/voice/call';

import { Box, Tooltip, IconButton, IconButtonProps } from '@mui/material';

import {
  MicOff as MicOffIcon,
  PhonePaused as PhonePausedIcon,
  PhoneForwarded as PhoneForwardedIcon,
  CallEnd as CallEndIcon,
  Call as CallIcon,
  PersonAdd as PersonAddIcon,
  PhoneForwardedOutlined as PhoneForwardedOutlinedIcon,
} from '@mui/icons-material';

interface ICallOrConferenceActionsProps {
  selectedConnection: IConnection | undefined;
  isForwardProcessInProgress: boolean;

  isMenuBtnDisabled: (buttonType?: string) => boolean;
  getCallActionIconButtonSxProps: (color: string) => IconButtonProps['sx'];
  isHoldPulsation: () => boolean;

  handleMute: () => Promise<void>;
  handleHoldOrResumeCall: () => Promise<void>;
  handleStartCall: () => Promise<void>;
  handleForward: () => Promise<void>;
  handleCancelForward: VoidFunction;
  handleEndCall: () => Promise<void>;
  handleAddParticipant: VoidFunction;
}

const CallOrConferenceActions: FunctionComponent<ICallOrConferenceActionsProps> = ({
  selectedConnection,
  isForwardProcessInProgress,
  isMenuBtnDisabled,
  getCallActionIconButtonSxProps,
  isHoldPulsation,
  handleMute,
  handleHoldOrResumeCall,
  handleStartCall,
  handleForward,
  handleCancelForward,
  handleEndCall,
  handleAddParticipant,
}): JSX.Element => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      columnGap={1}
      alignItems="center"
      justifyContent="center"
      bgcolor="primary.main"
      px={2}
    >
      <Tooltip title="Mute">
        <Box component="span">
          <IconButton
            size="medium"
            disabled={isMenuBtnDisabled('mute')}
            sx={getCallActionIconButtonSxProps(
              selectedConnection?.muted ? 'info.main' : 'primary.contrastText',
            )}
            onClick={handleMute}
          >
            <MicOffIcon />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="On hold">
        <Box component="span">
          <IconButton
            size="medium"
            className={isHoldPulsation() ? 'pulse' : undefined}
            disabled={isMenuBtnDisabled('hold')}
            sx={getCallActionIconButtonSxProps(
              selectedConnection?.onHold && !selectedConnection.internalHoldByOther
                ? 'info.main'
                : 'primary.contrastText',
            )}
            onClick={handleHoldOrResumeCall}
          >
            <PhonePausedIcon />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Conference">
        <Box component="span">
          <IconButton
            size="medium"
            disabled={isMenuBtnDisabled('add-participant')}
            sx={getCallActionIconButtonSxProps('primary.contrastText')}
            onClick={handleAddParticipant}
          >
            <PersonAddIcon />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title="Call">
        <Box component="span">
          <IconButton
            color="success"
            size="medium"
            disabled={isMenuBtnDisabled('call')}
            sx={getCallActionIconButtonSxProps('success.main')}
            onClick={handleStartCall}
          >
            <CallIcon />
          </IconButton>
        </Box>
      </Tooltip>

      <Box>
        <Tooltip title="Forward">
          <Box component="span">
            <IconButton
              size="medium"
              disabled={isMenuBtnDisabled('forward')}
              sx={getCallActionIconButtonSxProps(
                isForwardProcessInProgress ? 'success.main' : 'primary.contrastText',
              )}
              onClick={handleForward}
            >
              <PhoneForwardedIcon />
            </IconButton>
          </Box>
        </Tooltip>

        {isForwardProcessInProgress && !isMenuBtnDisabled('forward') ? (
          <Tooltip title="Cancel forward">
            <Box component="span">
              <IconButton
                size="medium"
                sx={getCallActionIconButtonSxProps('error.main')}
                onClick={handleCancelForward}
              >
                <PhoneForwardedOutlinedIcon />
              </IconButton>
            </Box>
          </Tooltip>
        ) : null}
      </Box>

      <Tooltip title="Hang up">
        <Box component="span">
          <IconButton
            size="medium"
            color="error"
            sx={getCallActionIconButtonSxProps('error.main')}
            disabled={isMenuBtnDisabled('end')}
            onClick={handleEndCall}
          >
            <CallEndIcon />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default CallOrConferenceActions;

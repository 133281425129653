import React, { useEffect, useState } from 'react';
import '@/styles/datepicker-styles.scss';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';

import { VoiceSettingsIncomingSetup } from '@/interfaces/voice/settings';
import { IExtensionsGroupResult } from '@/interfaces/extensions-groups';
import { IUserResult } from '@/interfaces/users/users';

import Switch from '@mui/material/Switch';
import PhoneNumberInput from '@/components/Phone-number-input/Phone-number-input';

import CallFlow from '@/pages/dashboard/settings/voice-setup/call-flow/Call-flow';
import {
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  FormControlLabel,
  InputAdornment,
} from '@mui/material';

const CONNECTION_TIMEOUTS = Array.from({ length: 30 }, (_, i) => i + 1);
const CONNECTION_TIMES = Array.from({ length: 60 }, (_, i) => (i + 1) * 5);

const AfterHours = (props: {
  voiceSetup: VoiceSettingsIncomingSetup;
  setVoiceSetup: React.Dispatch<React.SetStateAction<VoiceSettingsIncomingSetup>>;
  users: IUserResult[];
  extensionsGroups: IExtensionsGroupResult[];
}) => {
  const { voiceSetup, setVoiceSetup, users, extensionsGroups } = props;

  const [afterHoursSetup, setAfterHoursSetup] = useState(voiceSetup.afterHours);

  const handleTimeoutChange = (value: number) => {
    setVoiceSetup((current) => {
      return { ...current, afterHours: { ...current.afterHours, timeout: value } };
    });
  };

  const handleConnectionTimeChange = (value: number) => {
    setVoiceSetup((current) => {
      return { ...current, afterHours: { ...current.afterHours, connectionTime: value } };
    });
  };

  const handleIncreaseRepeatGreeting = () => {
    const oldValue = afterHoursSetup.repeat || 1;
    if (oldValue < 10) {
      const newValue = oldValue + 1;
      setVoiceSetup((current) => {
        return { ...current, afterHours: { ...current.afterHours, repeat: newValue } };
      });
    }
  };

  const handleDecreaseRepeatGreeting = () => {
    const oldValue = afterHoursSetup.repeat || 1;
    if (oldValue && oldValue > 1) {
      const newValue = oldValue - 1;
      setVoiceSetup((current) => {
        return { ...current, afterHours: { ...current.afterHours, repeat: newValue } };
      });
    }
  };

  const handleGatherInputSwitch = () => {
    const oldValue = afterHoursSetup.gatherInput ?? false;
    setVoiceSetup((current) => {
      return { ...current, afterHours: { ...current.afterHours, gatherInput: !oldValue } };
    });
  };

  const handleOnNoActionActiveSwitch = () => {
    const oldValue = afterHoursSetup.onNoAction.active ?? true;
    setVoiceSetup((current) => {
      return {
        ...current,
        afterHours: {
          ...current.afterHours,
          onNoAction: { ...current.afterHours.onNoAction, active: !oldValue },
        },
      };
    });
  };

  const handleForwardingActive = () => {
    const oldValue = afterHoursSetup.forwarding.active ?? false;
    setVoiceSetup((current) => {
      return {
        ...current,
        afterHours: {
          ...current.afterHours,
          forwarding: { ...current.afterHours.forwarding, active: !oldValue },
        },
      };
    });
  };

  const handleOnNoActionExtensionsGroupChange = (reason: string, extensionsGroupRef: string) => {
    if (reason === 'clear') {
      setVoiceSetup((current) => {
        return {
          ...current,
          afterHours: {
            ...current.afterHours,
            onNoAction: { ...current.afterHours.onNoAction, extensionsGroupRef: '' },
          },
        };
      });
    }
    if (reason === 'selectOption') {
      setVoiceSetup((current) => {
        return {
          ...current,
          afterHours: {
            ...current.afterHours,
            onNoAction: {
              ...current.afterHours.onNoAction,
              extensionsGroupRef,
            },
          },
        };
      });
    }
  };

  const handleTextToSpeechChanges = (text: string) => {
    setVoiceSetup((current) => {
      return {
        ...current,
        afterHours: {
          ...current.afterHours,
          textToSpeech: text,
        },
      };
    });
  };

  const handleForwardToPhoneNumber = (phoneNumber: string | null): void => {
    setVoiceSetup((current) => {
      return {
        ...current,
        afterHours: {
          ...current.afterHours,
          forwarding: { ...current.afterHours.forwarding, phoneNumber: phoneNumber ?? '' },
        },
      };
    });
  };

  useEffect(() => {
    setAfterHoursSetup(voiceSetup.afterHours);
  }, [voiceSetup.afterHours]);

  return (
    <Box display="flex" flex={1} flexDirection="column" gap={1}>
      <Typography variant="h6" component="span" mb={1}>
        After Hours
      </Typography>

      <FormControl>
        <FormLabel sx={{ color: 'text.primary', display: 'flex', gap: 1, alignItems: 'center' }}>
          AI Voice Greeting
          <Tooltip
            title={
              <Typography fontSize={12}>
                The caller will hear this text as a voice on an incoming call. It can be a greeting
                or instruction on how to connect with company extension etc.
              </Typography>
            }
          >
            <HelpOutlineIcon color="primary" fontSize="small" />
          </Tooltip>
        </FormLabel>

        <TextField
          fullWidth
          multiline
          minRows={4}
          maxRows={4}
          variant="outlined"
          value={voiceSetup.afterHours.textToSpeech || ''}
          onChange={(e) => {
            handleTextToSpeechChanges(e.target.value);
          }}
        />
      </FormControl>

      <FormControl size="small">
        <FormLabel sx={{ color: 'text.primary' }}>
          Wait time before Call Hangup/Send to Voicemail
          <Tooltip
            title={
              <Typography fontSize={12}>
                This is the time in seconds before a call is either disconnected or sent to
                voicemail. Calls will keep ringing for the number of seconds selected.
              </Typography>
            }
          >
            <HelpOutlineIcon color="primary" fontSize="small" sx={{ mb: -0.5, ml: 1 }} />
          </Tooltip>
        </FormLabel>

        <Select
          size="small"
          value={afterHoursSetup.connectionTime || 30}
          onChange={(event) => {
            if (event.target.value) {
              handleConnectionTimeChange(event.target.value as number);
            }
          }}
          sx={{ maxWidth: '7rem' }}
          MenuProps={{ sx: { maxHeight: '20rem' } }}
        >
          {CONNECTION_TIMES.map((el) => {
            return (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl size="small">
        <FormLabel sx={{ color: 'text.primary' }}>
          Wait time between Greeting and Call Connection
          <Tooltip
            title={
              <Typography fontSize={12}>
                Enter time after which call will be connected to extensions or disconnected if no
                input is received.
              </Typography>
            }
          >
            <HelpOutlineIcon color="primary" fontSize="small" sx={{ mb: -0.5, ml: 1 }} />
          </Tooltip>
        </FormLabel>

        <Select
          size="small"
          label="Wait time between Greeting and Call Connection"
          value={afterHoursSetup.timeout || 1}
          onChange={(event) => {
            if (event.target.value) {
              handleTimeoutChange(event.target.value as number);
            }
          }}
          sx={{ maxWidth: '7rem' }}
          MenuProps={{ sx: { maxHeight: '20rem' } }}
        >
          {CONNECTION_TIMEOUTS.map((el) => {
            return (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {/* For now leave it as is. Requirement to remove from front end*/}
      {/* <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'}>
            <IconButton onClick={handleDecreaseRepeatGreeting} sx={{ padding: 0 }}>
              <RemoveIcon />
            </IconButton>

            <Typography mr={2} ml={2} fontSize={19} width={'10px'}>
              {afterHoursSetup.repeat || 1}
            </Typography>

            <IconButton onClick={handleIncreaseRepeatGreeting} sx={{ padding: 0 }}>
              <AddIcon />
            </IconButton>

            <Typography mx={1}>Repeat greeting before call connection</Typography>

            <Tooltip
              title={
                <Typography fontSize={12}>
                  Select the number of times you would like greeting to be played to caller before
                  call connects.
                </Typography>
              }
            >
              <HelpOutlineIcon color="primary" fontSize="small" />
            </Tooltip>
          </Box>
        </Grid> */}

      <Box>
        <FormControlLabel
          label={
            <Box color="text.primary">
              Allow extension calling
              <Tooltip
                title={
                  <Typography fontSize={12}>
                    Select ON for allowing calls to connect to individual extensions instead of
                    redirecting all calls to Default Extension.
                  </Typography>
                }
              >
                <HelpOutlineIcon color="primary" fontSize="small" sx={{ mb: -0.5, ml: 1 }} />
              </Tooltip>
            </Box>
          }
          control={
            <Switch
              checked={afterHoursSetup.gatherInput ?? false}
              onChange={handleGatherInputSwitch}
            />
          }
        />
      </Box>

      <Box>
        <FormControlLabel
          label={
            <Box color="text.primary">
              Connect to default extension on no input{' '}
              <Tooltip
                title={
                  <Typography fontSize={12}>
                    Selected ON if calls should be disconnected in case no input is received from
                    caller within specified duration.
                  </Typography>
                }
              >
                <HelpOutlineIcon color="primary" fontSize="small" sx={{ mb: -0.5, ml: 1 }} />
              </Tooltip>
            </Box>
          }
          control={
            <Switch
              checked={afterHoursSetup.onNoAction?.active ?? true}
              onChange={handleOnNoActionActiveSwitch}
            />
          }
        />
      </Box>

      {afterHoursSetup.onNoAction?.active ? (
        <Autocomplete
          fullWidth
          sx={{ maxWidth: '20rem' }}
          disabled={!afterHoursSetup.onNoAction?.active}
          options={extensionsGroups}
          value={
            extensionsGroups.find(
              (el) => el.id === afterHoursSetup.onNoAction?.extensionsGroupRef,
            ) ?? null
          }
          getOptionLabel={(el) => el.name}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          onChange={(event, list, reason, detail) =>
            handleOnNoActionExtensionsGroupChange(reason, detail?.option?.id as string)
          }
          renderInput={(params: any) => (
            <TextField
              {...params}
              label="Default extension group"
              placeholder="Select extension group when no action is made"
            />
          )}
        />
      ) : null}

      <Box>
        <FormControlLabel
          label={
            <Box color="text.primary" display="flex" gap={1} alignItems="center">
              Forward all calls
              <Tooltip
                title={
                  <Typography fontSize={12}>
                    Select this option if you want all calls forwarded by default to the number
                    below
                  </Typography>
                }
              >
                <HelpOutlineIcon color="primary" fontSize="small" />
              </Tooltip>
            </Box>
          }
          control={
            <Switch
              checked={afterHoursSetup.forwarding.active ?? false}
              onChange={handleForwardingActive}
            />
          }
        />
      </Box>

      {afterHoursSetup.forwarding.active ? (
        <Box display={'flex'} alignItems={'center'}>
          <PhoneNumberInput
            returnNullOnInvalid
            fullWidth
            sx={{ width: '15rem' }}
            label="Forward calls to"
            disabled={!afterHoursSetup.forwarding.active}
            value={afterHoursSetup.forwarding.phoneNumber}
            countryCodesListType={'tenant-list'}
            onPhoneNumberChange={(value) => {
              handleForwardToPhoneNumber(value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      <Typography fontSize={12}>
                        Enter the external number which should receive forwarded calls. This field
                        will be visible only if 'Forward all calls' is selected above
                      </Typography>
                    }
                  >
                    <HelpOutlineIcon color="primary" fontSize="small" />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ) : null}

      <CallFlow
        callFlow={afterHoursSetup.callFlow}
        users={users}
        extensionsGroups={extensionsGroups}
        whichHours="afterHours"
        setVoiceSetup={setVoiceSetup}
      />
    </Box>
  );
};

export default AfterHours;

import * as colors from '@mui/material/colors';

export const Box1SX = {
  width: '100%',
  mb: 1,
  ':hover': { cursor: 'pointer' },
};

export const Box2SX = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const Icon1SX = {
  height: 20,
  width: 20,
  mr: 1,
};

export const Typography1SX = {
  fontSize: 13,
};

export const searchInputSX = {
  my: 0.7,
  ml: 0.7,
  '& input': {
    fontSize: '1rem',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  border: 'none',
  '& fieldset': { border: 'none' },
};

export const contactListItemSelected = {
  color: 'white',
  borderColor: 'inherit',
  background: 'linear-gradient(118deg,#4e5154,rgba(78,81,84,.7))',
};

export const smsIconSelected = {
  color: 'white',
};

export const smsIcon = {
  color: 'grey',
};

export const Box1SX = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Box2SX = {
  position: 'relative',
  display: 'inline-flex',
};

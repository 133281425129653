import Menu from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { Divider, IconProps, Typography, TypographyProps } from '@mui/material';

const CustomContextMenu = (props: {
  anchorEl: null | HTMLElement;
  list: {
    isDivider?: boolean;
    name?: string;
    props?: MenuItemProps;
    icon?: { Icon?: any; props?: IconProps };
    typographyProps?: TypographyProps;
  }[];
  open: boolean;
  handleClose: VoidFunction;
}) => {
  const { list, anchorEl, open, handleClose } = props;

  return (
    <div>
      <Menu
        id="custom-context-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        elevation={24}
        sx={{ minWidth: 500 }}
      >
        {list.map((element, index) => {
          if (element.isDivider) {
            return <Divider key={index} />;
          }
          return (
            <MenuItem key={index} {...element.props}>
              {element.icon?.Icon && <element.icon.Icon {...element.icon.props} />}
              <Typography {...element.typographyProps}>{element.name}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default CustomContextMenu;

import { IGetUsersQueryParams } from '@/interfaces/users/users';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { IThunkExtra } from '@/store/store';

import {
  ICreateExtensionsGroupRequest,
  IUpdateExtensionsGroupRequest,
} from '@/interfaces/extensions-groups';

import handleErrors from '@/services/handle-errors';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import * as extensionsGroupsApi from '@/services/api/v1/extensions-groups';

import * as SNACKBAR from '@/constants/snackbar';

export const fetchGetAll = createAsyncThunk('voiceSettings/fetchGetAll', async (data, thunkAPI) => {
  const response = await extensionsGroupsApi.getAll();
  if (!response.success) {
    handleErrors(thunkAPI, response.statusCode, response);
    thunkAPI.abort();
  } else {
    return response.data;
  }
});

export const fetchGetUsers = createAsyncThunk(
  'voiceSettings/fetchGetUsers',
  async (data: IGetUsersQueryParams, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.getByParams(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCreateExtensionsGroup = createAsyncThunk(
  'voiceSettings/fetchCreateExtensionsGroup',
  async (data: ICreateExtensionsGroupRequest, thunkAPI) => {
    const response = await extensionsGroupsApi.createExtensionsGroup(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      const { dispatch } = thunkAPI;
      dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.SAVED('Extensions group')));
      return response.data;
    }
  },
);

export const fetchDeleteExtensionsGroup = createAsyncThunk(
  'voiceSettings/fetchDeleteExtensionsGroup',
  async (data: string, thunkAPI) => {
    const response = await extensionsGroupsApi.deleteExtensionsGroup(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      const { dispatch } = thunkAPI;
      dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.DELETED('Extensions group')));
      return response.data;
    }
  },
);

export const fetchEditExtensionsGroup = createAsyncThunk(
  'voiceSettings/fetchEditExtensionsGroup',
  async (data: { id: string; updates: IUpdateExtensionsGroupRequest }, thunkAPI) => {
    const response = await extensionsGroupsApi.editExtensionsGroup(data.id, data.updates);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      const { dispatch } = thunkAPI;
      dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.UPDATED('Extensions group')));
      return response.data;
    }
  },
);

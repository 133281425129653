import { io } from 'socket.io-client';

const host = process.env.REACT_APP_WS_BACKEND_HOST as string;

const connect = () => {
  if (localStorage.getItem('t')) {
    return io(host, {
      path: '/socket.io',
      transports: ['websocket'],
      auth: { token: `Bearer ${localStorage.getItem('t')}` },
      autoConnect: true,
      query: { 'd-type': 'web' },
      reconnectionDelay: 100,
    });
  }
};

export let socket = connect();

export const connectSocket = () => {
  if (!socket?.connected) {
    socket = connect();
  }
};

export const disconnectSocket = () => {
  if (socket?.connected) {
    socket.disconnect();
  }
};

import { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Typography,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  List,
  ListItem,
  IconButton,
  Grid,
  Autocomplete,
  Paper,
} from '@mui/material';

import { Add as AddIcon, DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';

import { IUserResult } from '@/interfaces/users/users';
import { ICreateExtensionsGroupRequest } from '@/interfaces/extensions-groups';

import extensionsGroupSchema from '@/helpers/validation-schemas/extensions-group';

import * as menuReducer from '@/store/reducers/dashboard/menu';

import * as extensionGroupsThunk from '@/store/thunk/dashboard/extensions-groups';

import { defStyles } from '@/theme';

import PhoneNumberInput from '@/components/Phone-number-input/Phone-number-input';
import TemplateTable, { ITemplateTableProps } from '@/components/Template-table/Template-table';

import apisV1 from '@/services/api/v1/index';

import DASHBOARD from '@/constants/dashboard';
import ROUTES from '@/constants/routes';
import EXTENSIONS_GROUP from '@/constants/extensions-groups';

const CONNECTION_TIME = new Array(180).fill(null).map((el, i) => i + 1);

const CreateUpdateExtensionsGroup: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const extensionsGroupToEdit = location.state?.extensionsGroupToEdit ?? null;
  const isEditing = !!extensionsGroupToEdit;
  //TODO: need to fix any
  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: isEditing
      ? {
          name: extensionsGroupToEdit.name,
          description: extensionsGroupToEdit.description ?? '',
          connectionTime:
            extensionsGroupToEdit.connectionTime ??
            EXTENSIONS_GROUP.DEFAULT_RING_ALL_CONNECTION_TIME,
          connectionType: extensionsGroupToEdit.connectionType,
          connectTo: extensionsGroupToEdit.connectTo.map((element: any) => ({
            ...element,
            phoneNumber: element.phoneNumber ?? '',
            extension: element.extension ?? '',
          })) as any[],
        }
      : {
          name: '',
          description: '',
          connectionTime: EXTENSIONS_GROUP.DEFAULT_RING_ALL_CONNECTION_TIME,
          connectionType: EXTENSIONS_GROUP.CONNECTION_TYPE.RING_ALL,
          connectTo: [] as any[],
        },
    validationSchema: isEditing
      ? extensionsGroupSchema.updateExtensionsGroup
      : extensionsGroupSchema.createExtensionsGroup,
    onSubmit: async (values) => {
      const data = {
        ...values,
        connectTo: values.connectTo.map((element) => {
          return {
            connectionTime: element.connectionTime,
            extension: element.extension,
            phoneNumber: element.phoneNumber,
            sequence: element.sequence,
          };
        }),
      };

      const { meta } = isEditing
        ? await dispatch(
            extensionGroupsThunk.fetchEditExtensionsGroup({
              id: extensionsGroupToEdit.id,
              updates: data,
            }),
          )
        : await dispatch(extensionGroupsThunk.fetchCreateExtensionsGroup(data));

      if (meta.requestStatus === 'fulfilled') {
        navigate(ROUTES.DASHBOARD_EXTENSIONS_GROUPS);
      }
    },
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [userExtensions, setUserExtensions] = useState<Record<'label' | 'extension', string>[]>([]);

  const handleLoadUserExtensions = async (): Promise<void> => {
    setIsLoading(true);

    const { success, data } = await apisV1.usersApis.getByParams({
      excludeMe: false,
      limit: 0,
      skip: 0,
    });

    if (success) {
      setUserExtensions(
        data.records.map((user: IUserResult) => ({
          label: `${user.companyExtension} | ${user.fullName}`,
          extension: user.companyExtension,
        })),
      );
    }

    setIsLoading(false);
  };

  const handleConnectToFieldChange = async (
    i: number,
    fieldName: string,
    value: string | number | null,
  ) => {
    await formik.setFieldValue(`connectTo[${i}].${fieldName}`, value);
    formik.setFieldTouched(`connectTo[${i}].${fieldName}`, true);
  };

  const handleConnectionTime = (reason: string, value: number, index: number) => {
    if (['removeOption', 'clear'].includes(reason)) {
      const connectionTime =
        formik.values.connectionType === EXTENSIONS_GROUP.CONNECTION_TYPE.CALL_HUNT
          ? EXTENSIONS_GROUP.DEFAULT_CALL_HUNT_CONNECT_TO_CONNECTION_TIME
          : EXTENSIONS_GROUP.DEFAULT_RING_ALL_CONNECT_TO_CONNECTION_TIME;

      handleConnectToFieldChange(index, 'connectionTime', connectionTime);
    }
    if (reason === 'selectOption') {
      handleConnectToFieldChange(index, 'connectionTime', value);
    }
  };

  const handleExtGroupConnectionTime = (reason: string, value: number) => {
    if (['removeOption', 'clear'].includes(reason)) {
      formik.setFieldValue('connectionTime', EXTENSIONS_GROUP.DEFAULT_RING_ALL_CONNECTION_TIME);
      formik.setFieldTouched('connectionTime', true);
    }

    if (reason === 'selectOption') {
      formik.setFieldValue('connectionTime', value);
      formik.setFieldTouched('connectionTime', true);
    }
  };

  const handleAddSequence = () => {
    const connectTo = formik.values.connectTo as any[];

    const newConnectTo = [
      ...connectTo,
      {
        sequence: connectTo.length
          ? Math.max(...(connectTo.map((el) => el.sequence) as number[])) + 1
          : 1,
        connectionTime: EXTENSIONS_GROUP.DEFAULT_CALL_HUNT_CONNECT_TO_CONNECTION_TIME,
        extension: '',
        phoneNumber: '',
        countryCode: 1,
      },
    ].sort((a, b) => a.sequence - b.sequence);

    formik.setFieldValue('connectTo', newConnectTo);
    formik.setFieldTouched('connectTo', true);
  };

  useEffect(() => {
    handleLoadUserExtensions();
    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.EXTENSIONS_GROUPS }));
  }, []);

  const isRingAllSelected =
    formik.values.connectionType === EXTENSIONS_GROUP.CONNECTION_TYPE.RING_ALL;

  const templateTableProps: ITemplateTableProps<ICreateExtensionsGroupRequest['connectTo']['0']> = {
    getDataEntityId: (el) => el.sequence,
    data: formik.values.connectTo,
    columns: [
      {
        header: 'Sequence',
        getValue: (el) => el.sequence,
      },
      {
        sharedProps: { sx: { width: '30%' } },
        header: 'Extension',
        getValue: (el, index) => {
          const errors = formik.errors.connectTo?.[index] as Record<string, any>;
          const touched = (formik.touched.connectTo as any[])?.[index];

          return (
            <Autocomplete
              // @ts-ignore
              value={(el?.extension as string) ?? ''}
              disabled={!!el.phoneNumber}
              options={userExtensions}
              // @ts-ignore
              isOptionEqualToValue={(option, value) => option.extension === value}
              onChange={async (event, list, reason, detail) => {
                await handleConnectToFieldChange(
                  index,
                  'extension',
                  detail?.option?.extension ?? '',
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="standard"
                  placeholder="Type extension or user name"
                  error={touched?.extension && !!errors?.extension}
                  helperText={touched?.extension && (errors?.extension as string)}
                />
              )}
              renderOption={(props, option) => (
                <Box {...props} key={option.label} component="li">
                  {option.label}
                </Box>
              )}
            />
          );
        },
      },
      {
        sharedProps: { sx: { width: '40%' } },
        header: 'Phone Number',
        getValue: (el, index) => {
          return (
            <PhoneNumberInput
              variant="standard"
              size="small"
              value={el?.phoneNumber ?? ''}
              countryCodesListType={'tenant-list'}
              onPhoneNumberChange={async (value: string | null) => {
                await handleConnectToFieldChange(index, 'phoneNumber', value ?? '');
              }}
              disabled={!!el.extension}
              returnNullOnInvalid
            />
          );
        },
      },
      {
        sharedProps: { sx: { width: '15%' } },
        header: 'Ring Duration',
        getValue: (el, index) => {
          const errors = formik.errors.connectTo?.[index] as Record<string, any>;
          const touched = (formik.touched.connectTo as any[])?.[index];

          return (
            <Autocomplete
              disabled={isRingAllSelected}
              size="small"
              id="connectionTime"
              options={CONNECTION_TIME}
              getOptionLabel={(option) => option.toString()}
              value={el.connectionTime}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Select Ring Duration seconds"
                />
              )}
              onChange={(event, list, reason, detail) => {
                let connectionTime = detail?.option;

                if (!connectionTime) {
                  connectionTime =
                    formik.values.connectionType === EXTENSIONS_GROUP.CONNECTION_TYPE.CALL_HUNT
                      ? EXTENSIONS_GROUP.DEFAULT_CALL_HUNT_CONNECT_TO_CONNECTION_TIME
                      : EXTENSIONS_GROUP.DEFAULT_RING_ALL_CONNECT_TO_CONNECTION_TIME;
                }

                handleConnectionTime(reason, connectionTime, index);
              }}
            />
          );
        },
      },
      {
        header: 'Delete',
        getValue: (_, index) => {
          return (
            <IconButton
              onClick={() => {
                formik.setValues((prevState) => {
                  return {
                    ...prevState,
                    connectTo: prevState.connectTo
                      .filter((el: any, i: number) => i !== index)
                      .map((element: any, i: number) => ({
                        ...element,
                        sequence: i + 1,
                      })),
                  };
                });

                formik.setFieldTouched('connectTo', true);
              }}
            >
              <DeleteOutlineIcon color="error"></DeleteOutlineIcon>
            </IconButton>
          );
        },
      },
    ],
  };

  return (
    <Box flex={1} p={2} display="flex" justifyContent="center" overflow="auto">
      <Box
        component={Paper}
        display="flex"
        flexDirection="column"
        flex={1}
        gap={1}
        py={2}
        overflow="auto"
        maxWidth="md"
        sx={defStyles.scroll}
      >
        <Typography px={2} variant="h5" color="primary.main" textAlign="center" fontWeight="bold">
          {isEditing ? 'Edit' : 'Add'} extensions group
        </Typography>

        <Box
          display="flex"
          gap={3}
          flexDirection="column"
          component="form"
          onSubmit={formik.handleSubmit}
        >
          <Box display="flex" gap={1} px={2} flexDirection="column">
            <TextField
              fullWidth
              required
              id="name"
              name="name"
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name ? (formik.errors.name as string) : ''}
            />

            <TextField
              fullWidth
              label="Description"
              variant="standard"
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description ? (formik.errors.description as string) : ''}
            />

            <Box display="flex" gap="inherit" mt={1}>
              <FormControl sx={{ minWidth: '10rem' }} variant="standard" required>
                <InputLabel>Connection Type</InputLabel>

                <Select
                  id="connectionType"
                  name="connectionType"
                  value={formik.values.connectionType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={EXTENSIONS_GROUP.CONNECTION_TYPE.RING_ALL}>Ring All</MenuItem>
                  <MenuItem value={EXTENSIONS_GROUP.CONNECTION_TYPE.CALL_HUNT}>Call Hunt</MenuItem>
                </Select>
              </FormControl>

              {isRingAllSelected && (
                <Autocomplete
                  options={CONNECTION_TIME}
                  value={formik.values.connectionTime}
                  getOptionLabel={(el) => el.toString()}
                  onChange={(event, list, reason, detail) =>
                    handleExtGroupConnectionTime(
                      reason,
                      detail?.option ?? EXTENSIONS_GROUP.DEFAULT_RING_ALL_CONNECTION_TIME,
                    )
                  }
                  onBlur={formik.handleBlur}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Ring Duration"
                      placeholder="Select Ring Duration seconds"
                      error={formik.touched.connectionTime && !!formik.errors.connectionTime}
                      helperText={
                        formik.touched.connectionTime && (formik.errors.connectionTime as string)
                      }
                    />
                  )}
                />
              )}
            </Box>
          </Box>

          <Box display="flex" gap={1} flexDirection="column">
            <TemplateTable {...templateTableProps} />

            <Box px={2}>
              <IconButton size="large" onClick={() => handleAddSequence()}>
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" mt="auto" px={2}>
          <Button
            color="success"
            variant="contained"
            onClick={formik.submitForm}
            disabled={isLoading || formik.isSubmitting || !formik.isValid}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateUpdateExtensionsGroup;

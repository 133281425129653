import { Fragment, FunctionComponent, useEffect, useState } from 'react';

import { useAppDispatch } from '@/store/hooks';

import { Avatar, Box, CircularProgress, Divider, IconButton, Typography } from '@mui/material';
import {
  CloudDownload as CloudDownloadIcon,
  ExpandMore as ExpandMoreIcon,
  Voicemail as VoicemailIcon,
} from '@mui/icons-material';

import { IPaginationParams, IPartialResult } from '@/interfaces/querying-data';
import { ICallsVoicemailResult } from '@/interfaces/voice/call-voicemail';

import { format } from '@/helpers/date/date';

import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';

import * as voiceThunk from '@/store/thunk/dashboard/voice';
import * as voiceLocalThunk from '@/pages/dashboard/voice/thunk';

import ReactAudioPlayer from 'react-audio-player';

import { defStyles } from '@/theme';
import CALL from '@/constants/voice/voice';

interface ICallerCalleeDataToDisplay {
  callerAvatarUrl: string | undefined;
  callerPrimary: string | null;
  callerSecondary: number | string | null | undefined;

  calleeAvatarUrl: string | undefined;
  calleePrimary: string | null;
  calleeSecondary: number | string | null | undefined;
}

const prepareCallerCalleeDisplay = (data: ICallsVoicemailResult): ICallerCalleeDataToDisplay => {
  const deletedAccountLabel = 'DELETED ACCOUNT';

  const callerAvatarUrl = data.callHistoryCaller?.avatarUrl || DefaultAvatar;
  const callerPrimary =
    data.callHistoryCaller?.fullName ??
    data.callHistoryCaller?.phoneNumber ??
    data.callHistoryCaller?.companyExtension ??
    deletedAccountLabel;

  let callerSecondary =
    data.callHistoryCaller?.phoneNumber ?? data.callHistoryCaller?.companyExtension;

  if (callerSecondary === callerPrimary) {
    callerSecondary = '';
  }

  const calleeAvatarUrl = data.callHistoryCallee?.avatarUrl || DefaultAvatar;
  const calleePrimary =
    data.callHistoryCallee?.fullName ??
    data.callHistoryCallee?.phoneNumber ??
    data.callHistoryCallee?.companyExtension ??
    deletedAccountLabel;

  let calleeSecondary =
    data.callHistoryCallee?.phoneNumber ?? data.callHistoryCallee?.companyExtension;

  if (calleeSecondary === calleePrimary) {
    calleeSecondary = '';
  }

  return {
    callerAvatarUrl,
    callerPrimary,
    callerSecondary,
    calleeAvatarUrl,
    calleePrimary,
    calleeSecondary,
  };
};

const CallsVoicemails: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [queryParams, setQueryParams] = useState<IPaginationParams>({
    skip: 0,
    limit: 50,
  });

  const [callsVoicemailsResult, setCallsVoicemailsResult] = useState<
    IPartialResult<ICallsVoicemailResult>
  >({
    records: [],
    maxCount: 0,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [voicemailTrackInfo, setVoicemailTrackInfo] = useState({
    isLoading: false,
    track: '',
    selectedId: null as string | null,
  });

  const handleLoadVoicemails = async (): Promise<void> => {
    setIsLoading(true);

    const payload = await dispatch(voiceThunk.fetchGetVoicemails(queryParams)).unwrap();

    if (payload) {
      setCallsVoicemailsResult((prevState) => {
        if (!queryParams.skip) {
          return payload;
        }

        return {
          maxCount: payload.maxCount,
          records: prevState.records.concat(payload.records),
        };
      });
    }

    setIsLoading(false);
  };

  const handleDownloadVoicemail = async (el: ICallsVoicemailResult): Promise<void> => {
    if (voicemailTrackInfo.isLoading) {
      return;
    }

    setVoicemailTrackInfo((prevState) => ({ ...prevState, isLoading: true }));

    const { meta, payload } = await dispatch(
      voiceLocalThunk.fetchDownloadVoicemailTrack(el.callHistoryRef),
    );

    if (meta.requestStatus === 'fulfilled') {
      setVoicemailTrackInfo((prevState) => ({
        ...prevState,
        track: URL.createObjectURL(payload),
        selectedId: el.id,
      }));
    }

    setVoicemailTrackInfo((prevState) => ({ ...prevState, isLoading: false }));
  };

  const getColorByStatus = (status: ICallsVoicemailResult['callHistoryStatus']) => {
    if (
      status === CALL.STATUSES.BUSY ||
      status === CALL.STATUSES.NO_ANSWER ||
      status === CALL.STATUSES.MISSED
    ) {
      return 'error.main';
    }

    return 'primary.main';
  };

  useEffect(() => {
    if (!isLoading) {
      handleLoadVoicemails();
    }
  }, [queryParams.skip, queryParams.limit]);

  return (
    <Box display="flex" flexDirection="column" overflow="auto" sx={defStyles.scroll}>
      {callsVoicemailsResult.records.map((el) => {
        const isSelected = voicemailTrackInfo.selectedId === el.id;

        const displayData = prepareCallerCalleeDisplay(el);

        return (
          <Fragment key={el.id}>
            <Box display="flex" flexDirection="column" gap={1} p={1}>
              <Box
                display="flex"
                alignItems={{ md: 'center' }}
                justifyContent="space-between"
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={1}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                  maxWidth="40rem"
                  flex={1}
                >
                  <Box display="flex" gap={1} alignItems="center" width="40%">
                    <Avatar src={displayData.callerAvatarUrl} />

                    <Box>
                      <Typography variant="body2">{displayData.callerPrimary}</Typography>

                      {displayData.callerSecondary ? (
                        <Typography variant="caption">{displayData.callerSecondary}</Typography>
                      ) : null}
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="flex-start" minWidth="5rem">
                    <Typography
                      variant="body2"
                      fontSize={12}
                      component="span"
                      color={getColorByStatus(el.callHistoryStatus)}
                    >
                      {el.callHistoryStatus}
                    </Typography>
                  </Box>

                  <Box display="flex" gap={1} alignItems="center" width="40%">
                    <Avatar src={displayData.calleeAvatarUrl} />

                    <Box>
                      <Typography variant="body2">{displayData.calleePrimary}</Typography>

                      {displayData.calleeSecondary ? (
                        <Typography variant="caption">{displayData.calleeSecondary}</Typography>
                      ) : null}
                    </Box>
                  </Box>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  justifyContent={{ xs: 'space-between' }}
                >
                  <Typography variant="body2" component="span">
                    {format(el.createdAt)}
                  </Typography>

                  {isSelected ? (
                    <Box display="flex" alignItems="center" justifyContent="center" width="2.5rem">
                      {voicemailTrackInfo.isLoading ? (
                        <CircularProgress color="primary" size={24} />
                      ) : (
                        <VoicemailIcon color="primary" />
                      )}
                    </Box>
                  ) : (
                    <IconButton color="primary" onClick={() => handleDownloadVoicemail(el)}>
                      <CloudDownloadIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>

              {isSelected && !voicemailTrackInfo.isLoading && voicemailTrackInfo.track ? (
                <ReactAudioPlayer
                  style={{ width: '100%' }}
                  src={voicemailTrackInfo.track}
                  controls
                  title={'voicemail'}
                  preload="metadata"
                />
              ) : null}
            </Box>

            <Divider />
          </Fragment>
        );
      })}

      {callsVoicemailsResult.records.length < callsVoicemailsResult.maxCount ? (
        <IconButton
          color="secondary"
          disabled={isLoading}
          onClick={() => {
            setQueryParams((prevState) => ({
              ...prevState,
              skip: callsVoicemailsResult.records.length,
            }));
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};

export default CallsVoicemails;

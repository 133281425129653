import { IGetUsersQueryParams, IUserResult } from '@/interfaces/users/users';
import { Dispatch, SetStateAction, useState } from 'react';

import { Box, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import {
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  LockReset as LockResetIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';

import TemplateTable, { ITemplateTableProps } from '@/components/Template-table/Template-table';

import CustomAvatar from '@/components/Custom-avatar/Avatar';
import ConfirmationDialog, { IConfirmationDialog } from '@/components/Dialogs/Confirmation';

import PermissionsDialog from './Permissions-dialog';
import AllowedCountryCodesDialog from './Allowed-country-codes-dialog';

interface IUsersListProps {
  isLoading: boolean;
  data: IUserResult[];
  queryParams: IGetUsersQueryParams;
  setQueryParams: Dispatch<SetStateAction<IGetUsersQueryParams>>;
  handleUpdateUser: (el: IUserResult) => void;
  handleDeleteUser: (el: IUserResult) => void;
  handleResetUserPassword: (el: IUserResult) => void;
}

interface IUserActionsMenuButtonProps
  extends Pick<
    IUsersListProps,
    'handleUpdateUser' | 'handleDeleteUser' | 'handleResetUserPassword' | 'isLoading'
  > {
  user: IUserResult;
}

const UserActionsMenuButton = ({
  isLoading,
  user,
  handleUpdateUser,
  handleDeleteUser,
  handleResetUserPassword,
}: IUserActionsMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [showPermissionsDialog, setShowPermissionsDialog] = useState<boolean>(false);
  const [showAllowedCountryCodesDialog, setShowAllowedCountryCodesDialog] =
    useState<boolean>(false);
  const [confirmationDialog, setConfirmationDialog] = useState<IConfirmationDialog>({
    show: false,
  });

  const open = Boolean(anchorEl);

  const handleDeleteUserConfirmation = (): void => {
    setAnchorEl(null);
    setConfirmationDialog({
      show: true,
      title: 'Do you wish to delete user?',
      subtitle:
        'Warning: This action will permanently delete the user, and there is no way to undo it.',
      handleAccept: () => handleDeleteUser(user),
      rejectButtonText: 'Cancel',
      acceptButtonText: 'Delete',
    });
  };
  const handleResetUserPasswordConfirmation = (): void => {
    setAnchorEl(null);
    setConfirmationDialog({
      show: true,
      title: 'Do you wish to reset selected user password?',
      subtitle: `Selected user will receive an email with new password. Remember, it's a good practice for the user to update their password after logging in with the new one.`,
      handleAccept: () => {
        handleResetUserPassword(user);
        setConfirmationDialog({ show: false });
      },
    });
  };

  return (
    <>
      <IconButton color="primary" onClick={(event) => setAnchorEl(event.currentTarget)}>
        <MoreVertIcon />
      </IconButton>

      <PermissionsDialog
        isOpened={showPermissionsDialog}
        handleClose={() => {
          setAnchorEl(null);
          setShowPermissionsDialog(false);
        }}
        userId={user.id}
      />

      <AllowedCountryCodesDialog
        isOpened={showAllowedCountryCodesDialog}
        handleClose={() => {
          setAnchorEl(null);
          setShowAllowedCountryCodesDialog(false);
        }}
        user={user}
      />

      <ConfirmationDialog
        show={confirmationDialog.show}
        title={confirmationDialog.title}
        subtitle={confirmationDialog.subtitle}
        handleAccepted={confirmationDialog.handleAccept}
        handleRejected={() => setConfirmationDialog({ show: false })}
        rejectButtonText={confirmationDialog.rejectButtonText}
        acceptButtonText={confirmationDialog.acceptButtonText}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        disableAutoFocusItem
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleUpdateUser(user)}>
          <ListItemIcon>
            <EditIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setShowAllowedCountryCodesDialog(true);
          }}
        >
          <ListItemIcon>
            <FlagCircleIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Allowed Country Codes
        </MenuItem>

        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setShowPermissionsDialog(true);
          }}
        >
          <ListItemIcon>
            <AdminPanelSettingsIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Permissions
        </MenuItem>

        <MenuItem disabled={isLoading} onClick={() => handleResetUserPasswordConfirmation()}>
          <ListItemIcon>
            <LockResetIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Reset password
        </MenuItem>

        <MenuItem onClick={() => handleDeleteUserConfirmation()}>
          <ListItemIcon>
            <DeleteOutlineIcon color="error" fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

const UsersList = ({
  isLoading,
  data,
  queryParams,
  setQueryParams,
  handleUpdateUser,
  handleDeleteUser,
  handleResetUserPassword,
}: IUsersListProps) => {
  const templateTableProps: ITemplateTableProps<IUserResult> = {
    data,
    getDataEntityId: (el) => el.id,
    columns: [
      {
        sharedProps: { sx: { py: '.25rem' } },
        header: 'Avatar',
        getValue: (el) => (
          <Box width={40} height={40}>
            <CustomAvatar url={el.avatar?.url} lastActivityAt={el.lastActivityAt} />
          </Box>
        ),
      },
      {
        header: 'First name',
        sortFieldName: 'firstName',
        getValue: (el) => el.firstName,
      },
      {
        header: 'Last name',
        sortFieldName: 'lastName',
        getValue: (el) => el.lastName,
      },
      {
        header: 'Email',
        sortFieldName: 'email',
        getValue: (el) => el.email,
      },
      {
        header: 'Role',
        sortFieldName: 'role',
        getValue: (el) => el.role,
      },
      {
        header: 'Company extension',
        sortFieldName: 'companyExtension',
        getValue: (el) => el.companyExtension,
      },
      {
        header: 'Direct extension',
        sortFieldName: 'directExtension',
        getValue: (el) => el.directExtension,
      },
      {
        sharedProps: { align: 'center', sx: { py: '.25rem', width: '4rem' } },
        header: 'Action',
        getValue: (el) => (
          <UserActionsMenuButton
            isLoading={isLoading}
            user={el}
            handleDeleteUser={handleDeleteUser}
            handleUpdateUser={handleUpdateUser}
            handleResetUserPassword={handleResetUserPassword}
          />
        ),
      },
    ],
    onSortChange: (event, sortParams) =>
      setQueryParams((prevState) => ({ ...prevState, ...sortParams })),
    currentSortParams: {
      sortField: queryParams.sortField,
      sortDirection: queryParams.sortDirection,
    },
    tableContainerProps: { sx: { height: 'calc(100vh - 23rem)' } },
  };

  return <TemplateTable {...templateTableProps} />;
};

export default UsersList;

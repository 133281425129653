import { IPromoCodeVerifyRequest } from '@/interfaces/promo-codes';

import makeRequest from '../make-request';
import token from '../token';

const VERIFY_PROMO_CODES = '/api/v1/promo-codes/verify';

const verifyPromoCodes = async (data: IPromoCodeVerifyRequest) => {
  try {
    const result = await makeRequest(VERIFY_PROMO_CODES, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export default {
  verifyPromoCodes,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IContact } from '@/interfaces/contact';
import { IConnection } from '@/interfaces/voice/call';
import { IUserResult } from '@/interfaces/users/users';
import { ICallsTranscriptionResult } from '@/interfaces/voice/call-transcription';

import { DialerActionBtn } from '@/types/voice';

import { fetchPutCallOnHold } from '@/store/thunk/dashboard/voice';
import { fetchConferenceParticipants } from '@/pages/dashboard/voice/thunk';

import CALL from '@/constants/voice/voice';

export interface IVoice {
  dialerActionBtn: DialerActionBtn;
  disabledActionBtn: boolean;
  callToContact: null | IContact;
  connections: IConnection[];
  isShowCallStatusLoader: boolean;
  isSearchingContact: boolean;
  showEnableMicrophoneDialog: boolean;
  showEmergencyCallDialog: boolean;
  dialerSearchedUser: IUserResult | undefined;
}

const initialState: IVoice = {
  dialerActionBtn: 'call',
  disabledActionBtn: true,
  callToContact: null,
  connections: [],
  isShowCallStatusLoader: false,
  isSearchingContact: false,
  showEnableMicrophoneDialog: false,
  showEmergencyCallDialog: false,
  dialerSearchedUser: undefined,
};

export const voiceSlice = createSlice({
  name: 'voice',
  initialState,
  reducers: {
    setDefault: (state) => {
      for (const key in initialState) {
        //@ts-ignore
        state[key] = initialState[key];
      }
    },
    setDialerActionBtn: (state, action: PayloadAction<DialerActionBtn>) => {
      state.dialerActionBtn = action.payload;
    },
    setDisableActionBtn: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload === undefined) {
        state.disabledActionBtn = !!state.disabledActionBtn;
      } else {
        state.disabledActionBtn = action.payload;
      }
    },
    setCallToContact: (state, action: PayloadAction<null | IContact>) => {
      state.callToContact = action.payload;
    },
    setConnection: (state, action: PayloadAction<IConnection>) => {
      state.connections = [...state.connections, action.payload];
    },
    setOnHoldCall: (state, action: PayloadAction<{ uuid: string; onHold: boolean }>) => {
      const connections = state.connections.map((connection) => {
        if (connection.uuid === action.payload.uuid) {
          connection.onHold = action.payload.onHold;
          connection.isHoldDisabled = true;
          connection.showHoldPulsation = true;
        }

        return connection;
      });
      state.connections = connections;
    },
    setAnswered: (state, action: PayloadAction<string>) => {
      const connections = state.connections.map((connection) => {
        connection.activeNow = false;
        connection.selected = false;
        if (connection.uuid === action.payload) {
          connection.activeNow = true;
          connection.answered = true;
          connection.selected = true;
        }
        return connection;
      });
      state.connections = connections;
    },
    setMuted: (state, action: PayloadAction<{ uuid: string; mute?: boolean }>) => {
      const connections = state.connections.map((connection) => {
        if (connection.uuid === action.payload.uuid) {
          connection.muted = action.payload.mute ?? !connection.muted;
        }
        return connection;
      });
      state.connections = connections;
    },
    setIsForwarding: (state, action: PayloadAction<{ uuid: string; value: boolean }>) => {
      const connections = state.connections.map((connection) => {
        if (connection.uuid === action.payload.uuid) {
          connection.isForwarding = action.payload.value;
        }
        return connection;
      });
      state.connections = connections;
    },
    setCounter: (
      state,
      action: PayloadAction<{ uuid: string; counter: { minutes: number; seconds: number } }>,
    ) => {
      const connections = state.connections.map((connection) => {
        if (connection.uuid === action.payload.uuid) {
          connection.counter = action.payload.counter;
        }
        return connection;
      });
      state.connections = connections;
    },
    setCounterFunction: (state, action: PayloadAction<{ uuid: string; counterFunction: any }>) => {
      const connections = state.connections.map((connection) => {
        if (connection.uuid === action.payload.uuid) {
          connection.counterFunction = action.payload.counterFunction;
        }
        return connection;
      });
      state.connections = connections;
    },
    setIsShowCallStatusLoader: (state, action: PayloadAction<undefined | boolean>) => {
      state.isShowCallStatusLoader =
        action.payload === undefined ? !state.isShowCallStatusLoader : action.payload;
    },
    setIsSearchingContact: (state, action: PayloadAction<undefined | boolean>) => {
      state.isSearchingContact =
        action.payload === undefined ? !state.isSearchingContact : action.payload;
    },
    setShowEnableMicrophoneDialog: (state, action: PayloadAction<undefined | boolean>) => {
      state.showEnableMicrophoneDialog =
        action.payload === undefined ? !state.showEnableMicrophoneDialog : action.payload;
    },
    setShowEmergencyCallDialog: (state, action: PayloadAction<undefined | boolean>) => {
      state.showEmergencyCallDialog =
        action.payload === undefined ? !state.showEmergencyCallDialog : action.payload;
    },
    setInternalHoldByOther: (state, action: PayloadAction<{ uuid: string; isOnHold: boolean }>) => {
      const connections = state.connections.map((connection) => {
        if (connection.uuid === action.payload.uuid && !connection.onHold) {
          connection.internalHoldByOther = action.payload.isOnHold;
        }
        return connection;
      });
      state.connections = connections;
    },
    setLiveCallTranscription: (
      state,
      action: PayloadAction<{
        uuid: string;
        transcription: ICallsTranscriptionResult['transcriptions'][0];
      }>,
    ) => {
      const connections = state.connections.map((connection) => {
        if (connection.uuid === action.payload.uuid) {
          connection.transcription = [action.payload.transcription, ...connection.transcription];
          connection.transcriptionStatus = CALL.TRANSCRIPTION_STATUS.ONLINE;
        }
        return connection;
      });
      state.connections = connections;
    },
    setLiveCallTranscriptionStatus: (
      state,
      action: PayloadAction<{
        uuid: string;
        status: CALL.TRANSCRIPTION_STATUS;
      }>,
    ) => {
      const connections = state.connections.map((connection) => {
        if (connection.uuid === action.payload.uuid) {
          connection.transcriptionStatus = action.payload.status;
        }
        return connection;
      });
      state.connections = connections;
    },
    setSelectedConnection: (state, action: PayloadAction<string>) => {
      const connections = state.connections.map((connection) => {
        connection.selected = false;
        if (connection.uuid === action.payload) {
          connection.selected = true;
        }
        return connection;
      });
      state.connections = connections;
    },
    setProviderConnection: (state, action: PayloadAction<{ uuid: string; conn: any }>) => {
      const uuidToReplace = action.payload.uuid;
      const newConnection = action.payload.conn;

      state.connections = state.connections.map((connection) => {
        if (connection.uuid === uuidToReplace) {
          connection.conn = newConnection;
        }

        return connection;
      });
    },
    removeConnection: (state, action: PayloadAction<string>) => {
      const connections = state.connections.filter(({ uuid, counterFunction }) => {
        const isSameConnection = uuid === action.payload;

        if (isSameConnection) {
          clearInterval(counterFunction);
        }

        return !isSameConnection;
      });

      state.connections = connections;
    },
    stopCounter: (state, action: PayloadAction<string>) => {
      const connection = state.connections.find((connection) => {
        connection.uuid === action.payload;
      });
      clearInterval(connection?.counterFunction);
    },
    setDialerSearchedUser: (state, action: PayloadAction<IUserResult | undefined>) => {
      state.dialerSearchedUser = action.payload;
    },
    setIsOnHoldDisabled: (state, action: PayloadAction<{ uuid: string; value: boolean }>) => {
      state.connections = state.connections.map((element) => {
        if (element.uuid === action.payload.uuid) {
          element.isHoldDisabled = action.payload.value;
          element.showHoldPulsation = action.payload.value;
        }
        return element;
      });
    },
    setShowHoldPulsation: (state, action: PayloadAction<{ uuid: string; value: boolean }>) => {
      state.connections = state.connections.map((element) => {
        if (element.uuid === action.payload.uuid) {
          element.showHoldPulsation = action.payload.value;
        }
        return element;
      });
    },
    setIsMultipleParticipants: (state, action: PayloadAction<{ uuid: string; value: boolean }>) => {
      state.connections = state.connections.map((element) => {
        if (element.uuid === action.payload.uuid) {
          element.isMultipleParticipants = action.payload.value;
        }
        return element;
      });
    },
    setParticipants: (
      state,
      action: PayloadAction<{ uuid: string; value: IConnection['participants'] }>,
    ) => {
      state.connections = state.connections.map((element) => {
        if (element.uuid === action.payload.uuid) {
          element.participants = action.payload.value;
        }

        return element;
      });
    },
    setIsActiveNow: (state, action: PayloadAction<{ uuid: string }>) => {
      state.connections = state.connections.map((element) => {
        element.activeNow = element.uuid === action.payload.uuid;
        return element;
      });
    },
    updateConnection: (state, action: PayloadAction<IConnection>) => {
      state.connections = state.connections.map((element) => {
        if (element.uuid === action.payload.uuid) {
          element = action.payload;
        }
        return element;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPutCallOnHold.rejected, (state, action) => {
      const callUuid = action.meta.arg;
      const connections = state.connections.map((connection) => {
        if (connection.uuid === callUuid) {
          connection.onHold = false;
        }
        return connection;
      });
      state.connections = connections;
    });
    builder.addCase(fetchConferenceParticipants.fulfilled, (state, action) => {
      const callUuid = action.meta.arg;
      const connections = state.connections.map((connection) => {
        if (connection.uuid === callUuid && action.payload) {
          connection.participants = action.payload;
        }
        return connection;
      });
      state.connections = connections;
    });
  },
});

export const {
  setDefault,
  setDialerActionBtn,
  setDisableActionBtn,
  setCallToContact,
  setConnection,
  setProviderConnection,
  setOnHoldCall,
  removeConnection,
  setAnswered,
  setCounter,
  setCounterFunction,
  stopCounter,
  setIsShowCallStatusLoader,
  setMuted,
  setIsSearchingContact,
  setShowEnableMicrophoneDialog,
  setShowEmergencyCallDialog,
  setInternalHoldByOther,
  setSelectedConnection,
  setDialerSearchedUser,
  setIsOnHoldDisabled,
  updateConnection,
  setIsMultipleParticipants,
  setIsActiveNow,
  setShowHoldPulsation,
  setParticipants,
  setIsForwarding,
  setLiveCallTranscription,
  setLiveCallTranscriptionStatus,
} = voiceSlice.actions;

export default voiceSlice.reducer;

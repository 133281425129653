import { IPermissionsResult, IUserResult } from '@/interfaces/users/users';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { TemplateDialog } from '@/components/Dialogs/Template-dialog';
import Transition from '@/components/Dialogs/Transition';

import * as permissionsThunk from '@/store/thunk/permissions';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as usersThunk from '@/pages/dashboard/users/thunk';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const not = (a: IUserResult['permissions'], b: IUserResult['permissions']) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a: IUserResult['permissions'], b: IUserResult['permissions']) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

const union = (a: IUserResult['permissions'], b: IUserResult['permissions']) => {
  return [...a, ...not(b, a)];
};

const PermissionsDialog = (props: {
  isOpened: boolean;
  handleClose: Dispatch<SetStateAction<boolean>>;
  userId: string;
}) => {
  const { isOpened, userId, handleClose } = props;
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<IUserResult['permissions']>([]);
  const [allPermissions, setAllPermissions] = useState<IUserResult['permissions']>([]);
  const [userPermissions, setUserPermissions] = useState<IUserResult['permissions']>([]);

  const allPermissionsChecked = intersection(checked, allPermissions);
  const userPermissionsChecked = intersection(checked, userPermissions);

  const handleToggle = (value: IUserResult['permissions'][0]) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: IUserResult['permissions']) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: IUserResult['permissions']) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setUserPermissions(userPermissions.concat(allPermissionsChecked));
    setAllPermissions(not(allPermissions, allPermissionsChecked));
    setChecked(not(checked, allPermissionsChecked));
  };

  const handleCheckedLeft = () => {
    setAllPermissions(allPermissions.concat(userPermissionsChecked));
    setUserPermissions(not(userPermissions, userPermissionsChecked));
    setChecked(not(checked, userPermissionsChecked));
  };

  const handleGetUser = async (): Promise<void> => {
    setIsLoading(true);
    const { payload: user } = await dispatch(usersThunk.fetchGetById(userId));
    if (user) {
      setUserPermissions(user.permissions);
      const { payload } = await dispatch(permissionsThunk.fetchGetAll());
      if (payload) {
        setAllPermissions(
          payload
            .map((element: IPermissionsResult) => {
              const permission = { label: element.label, key: element.key };
              if (
                !user.permissions.find(
                  (perm: IUserResult['permissions'][0]) => perm.key === element.key,
                )
              ) {
                return permission;
              }
            })
            .filter(Boolean),
        );
      }
    }
    setIsLoading(false);
  };

  const handleSetPermissions = async () => {
    const { meta } = await dispatch(
      usersThunk.fetchSetPermissions({ id: userId, data: { permissions: userPermissions } }),
    );

    if (meta.requestStatus === 'fulfilled') {
      dispatch(snackbarReducer.showSuccess('User permissions were updated.'));
      handleClose(false);
      if (userId === user?.id) {
        await dispatch(usersThunk.fetchGetUserInfo());
      }
    }
  };

  const customList = (title: React.ReactNode, items: IUserResult['permissions']) => (
    <Card elevation={4}>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
      />
      <Divider />
      <List
        sx={{
          width: 240,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: IUserResult['permissions'][0], index: number) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItemButton key={index} role="listitem" onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.label} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  useEffect(() => {
    if (isOpened && !allPermissions.length) {
      handleGetUser();
    } else {
      setAllPermissions([]);
      setUserPermissions([]);
    }
  }, [isOpened]);

  return (
    <TemplateDialog
      isLoading={isLoading}
      isOpened={isOpened}
      handleClose={handleClose}
      title="Permissions"
      showCancelButton={false}
      closeButtonProps={{ color: 'primary' }}
      dialogProps={{ maxWidth: 'md', onClose: handleClose, TransitionComponent: Transition }}
      submitButtonProps={{ children: 'Submit', onClick: handleSetPermissions }}
      getChildren={() => (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>{customList('All Permissions', allPermissions)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={allPermissionsChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={userPermissionsChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList('Assigned Permissions', userPermissions)}</Grid>
        </Grid>
      )}
    />
  );
};

export default PermissionsDialog;

import * as yup from 'yup';

import { CONTACT_TYPE } from '@/constants/contacts';

const createContactValidationSchema = yup.object().shape({
  firstName: yup.string().min(1).max(50).required('First name is required'),
  lastName: yup.string().nullable().optional(),
  email: yup.string().email().nullable().optional(),
  type: yup.mixed<CONTACT_TYPE>().oneOf(Object.values(CONTACT_TYPE)),
  phoneNumber: yup.string().required('Phone number is required'),
});

export default createContactValidationSchema;

export const Box1SX = {
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
};

export const Box2SX = {
  position: 'relative',
  display: 'inline-flex',
};

export const Box3SX = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Icon1SX = {
  width: 50,
  height: 50,
};

export const Typography1SX = {
  fontSize: 15,
  color: 'grey',
};

export const Button1SX = {
  mt: 1,
};

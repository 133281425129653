import * as yup from 'yup';

const update = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().nullable(),
  selectAllContacts: yup.boolean().required(),
  selectedContacts: yup.array(yup.object().required()),
  excludedContacts: yup.array(yup.object().required()),
});

export default update;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { IThunkExtra } from '../store';

export const fetchCountryCodes = createAsyncThunk(
  'countryCodes/fetchCountryCodes',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.countryCodesApis.getAll();
    if (!response.success) {
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

import { FunctionComponent, useEffect, useState } from 'react';

import { useAppSelector } from '@/store/hooks';

import {
  Box,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  ListItemText,
  TextField,
  Typography,
  FormControlProps,
} from '@mui/material';

import { IUserResult } from '@/interfaces/users/users';

type CountryCodesListType = 'full-list' | 'user-list' | 'tenant-list' | 'only-default';

interface ICountryCodeProps {
  handleChange: (data: IUserResult['allowedCountries'][0]) => void;
  countryCodesListType: CountryCodesListType;
  disabled?: boolean;
  reserveSpaceForError?: boolean;
  formControlProps?: FormControlProps;
}

const CountryCode: FunctionComponent<ICountryCodeProps> = ({
  countryCodesListType,
  disabled = false,
  reserveSpaceForError = false,
  handleChange,
  formControlProps = {},
}): JSX.Element => {
  const tenant = useAppSelector((state) => state.tenant.tenant);
  const userCountryCodes = useAppSelector((state) => state.user.countryCodes);
  const allCountryCodes = useAppSelector((state) => state.countryCodes.list);

  const [countryCodes, setCountryCodes] = useState<IUserResult['allowedCountries']>([]);

  const [selectedCountryCode, setSelectedCountryCode] =
    useState<IUserResult['allowedCountries'][0]>();

  const [isListOpened, setIsListOpened] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleInitCountryCodesList = () => {
    if (countryCodesListType === 'full-list') {
      setCountryCodes(allCountryCodes as unknown as IUserResult['allowedCountries']);

      if (!selectedCountryCode && !selectedCountryCode) {
        setSelectedCountryCode(
          allCountryCodes.find(
            (element) => element.iso === 'US',
          ) as unknown as IUserResult['allowedCountries'][0],
        );
      }

      return;
    }

    if (countryCodesListType === 'tenant-list') {
      if (!tenant) {
        return;
      }

      const mappedTenantList = tenant.allowedCountries.map((element) => ({
        ...element,
        id: element.countryCodeRef,
      }));

      setCountryCodes(mappedTenantList);

      if (!selectedCountryCode && !selectedCountryCode) {
        setSelectedCountryCode(
          mappedTenantList.find(
            (element) => element.iso === 'US',
          ) as IUserResult['allowedCountries'][0],
        );
      }

      return;
    }

    if (countryCodesListType === 'user-list') {
      const mappedTenantList = userCountryCodes.map((element) => ({
        ...element,
        id: element.countryCodeRef,
      }));

      setCountryCodes(mappedTenantList);

      if (!selectedCountryCode && !selectedCountryCode) {
        setSelectedCountryCode(mappedTenantList.find((element) => element.iso === 'US'));
      }

      return;
    }
  };

  const handleSelect = (countryCode: IUserResult['allowedCountries'][0]): void => {
    setSelectedCountryCode(countryCode);
    handleChange(countryCode);
  };

  useEffect(() => {
    handleInitCountryCodesList();
  }, [countryCodesListType]);

  return (
    <FormControl sx={{ minWidth: '7rem' }} {...formControlProps}>
      {selectedCountryCode && (
        <Select
          variant={formControlProps.variant}
          value={selectedCountryCode.iso}
          renderValue={(value) => {
            const option = countryCodes.find((el) => el.iso === value);

            if (!option) {
              return 'Loading...';
            }

            const className = `fi fi-${option.iso.toLowerCase()}`;
            const flagStyle = disabled ? { background: 'inherit' } : {};

            return (
              <Box display="flex" gap={1}>
                <Box className={className} sx={{ ...flagStyle, fontSize: '1rem' }} />
                <Typography variant="body1" component="span">
                  {option?.extension}
                </Typography>
              </Box>
            );
          }}
          disabled={disabled}
          onOpen={() => setIsListOpened(true)}
          onClose={() => {
            setIsListOpened(false);
            setSearchValue('');
          }}
          MenuProps={{
            style: {
              height: 300,
            },
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          onChange={(event) => {
            const option = countryCodes.find((el) => el.iso === event.target.value);

            if (option) {
              handleSelect(option);
            }
          }}
        >
          <TextField
            label="Search"
            type="search"
            variant="standard"
            sx={{ minWidth: 300, mb: 2, mx: 2 }}
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
          />

          {countryCodes
            .filter((element) => {
              if (!searchValue) {
                return true;
              }
              return (
                element.country.toLowerCase().includes(searchValue) ||
                element.extension.toLowerCase().includes(searchValue) ||
                element.iso.toLowerCase().includes(searchValue)
              );
            })
            .sort((a, b) => a.extension.localeCompare(b.extension))
            .map((element) => {
              const className = `fi fi-${element.iso.toLowerCase()}`;
              const flagStyle = disabled ? { background: 'inherit' } : {};
              return (
                <MenuItem dense key={element.country} value={element.iso} sx={{ gap: 2 }}>
                  <Box className={className} sx={{ ...flagStyle, fontSize: '1rem' }} />
                  <ListItemText
                    primaryTypographyProps={{ sx: { fontSize: '.9rem' } }}
                    secondaryTypographyProps={{
                      sx: { fontSize: '.625rem', textOverflow: 'ellipsis' },
                    }}
                    primary={element.extension}
                    secondary={element.country}
                  />
                </MenuItem>
              );
            })}
        </Select>
      )}

      {/* Space must be presented to render this component */}
      {reserveSpaceForError ? <FormHelperText> </FormHelperText> : null}
    </FormControl>
  );
};
export default CountryCode;

import { IConnection } from '@/interfaces/voice/call';

import { delay } from '@/helpers/delay';

import * as voiceReducer from '@/store/reducers/dashboard/voice';
import { AppDispatch, store } from '@/store/store';

import { endCall } from './end-call';
import { putTwilioCallOnHold } from './put-on-hold';
import { handleOutboundCallEvents } from './outbound-call';

export const resumeTwilioCall = async (data: { dispatch: AppDispatch }) => {
  const { dispatch } = data;

  const state = store.getState();
  const {
    voice: { connections },
    tenant: { tenant, activeProvider },
    user: { user },
    twilio: { device },
  } = state;

  if (!device || !user || !tenant || !connections.length || !activeProvider) {
    return;
  }

  const selectedConnection = connections.find((connection) => connection.selected) as IConnection;

  for (const connection of connections) {
    if (!connection.answered || connection.internalHoldByOther) {
      await endCall({ dispatch, uuid: connection.uuid });
    }

    if (connection.answered && !connection.internalHoldByOther && !connection.onHold) {
      await putTwilioCallOnHold({ dispatch, uuid: connection.uuid });
    }
  }

  // Need to give some time to the provider for processing prev requests
  if (connections.some((element) => !element.onHold)) {
    await delay();
  }

  dispatch(voiceReducer.setOnHoldCall({ uuid: selectedConnection.uuid, onHold: false }));

  const defaultTenantPhoneNumber = tenant.phoneNumbers.find((el) => el.isDefault)
    ?.phoneNumber as string;

  const connection = await device.connect({
    params: {
      uuid: selectedConnection.uuid,
      To: defaultTenantPhoneNumber,
      From: defaultTenantPhoneNumber,
      userId: user.id,
      tenantId: tenant.id,
    },
  });

  dispatch(voiceReducer.setIsActiveNow({ uuid: selectedConnection.uuid }));
  dispatch(voiceReducer.setProviderConnection({ uuid: selectedConnection.uuid, conn: connection }));

  if (selectedConnection.muted && !selectedConnection.isConference) {
    // Without timeout it will not work
    setTimeout(() => {
      connection.mute(selectedConnection.muted);
    }, 1000);
  }

  if (selectedConnection.muted && selectedConnection.isConference) {
    dispatch(
      voiceReducer.setMuted({
        uuid: selectedConnection.uuid,
        mute: false,
      }),
    );

    connection.mute(false);
  }

  handleOutboundCallEvents({ connection, dispatch, isResuming: true });

  setTimeout(() => {
    dispatch(voiceReducer.setIsOnHoldDisabled({ uuid: selectedConnection.uuid, value: false }));
  }, 4000);
};

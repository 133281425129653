import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { useAuth0 } from '@auth0/auth0-react';

import { AppBar, Box, Toolbar, Button, Typography } from '@mui/material';

import * as lsService from '@/services/local-storage';

import logo from '@/assets/images/whiisl-logo.png';

import useThemeBreakpoints from '@/hooks/use-theme-breakpoints';

import * as userThunk from '@/pages/dashboard/users/thunk';

import AccountsDialog from '@/components/Dialogs/Accounts/Accounts';

import ROUTES from '@/constants/routes';
import TENANT from '@/constants/tenant';
import { FEATURES } from '@/constants/features';

interface IPublicLayoutHeaderProps {
  showLinks?: boolean;
  showSignOut?: boolean;
  showSignIn?: boolean;
  showSignUp?: boolean;
}

const PublicLayoutHeader = ({
  showLinks = true,
  showSignOut = false,
  showSignIn = true,
  showSignUp = true,
}: IPublicLayoutHeaderProps) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const { logout } = useAuth0();

  const { user } = useAppSelector((state) => state.user);
  const { tenant } = useAppSelector((state) => state.tenant);
  const { device } = useAppSelector((state) => state.twilio);

  const [showAccountsDialog, setShowAccountsDialog] = useState<boolean>(false);

  const isXsOrDown = useThemeBreakpoints('down', 'xs');
  const isMdOrUp = useThemeBreakpoints('up', 'md');

  const handleOnLogoPress = (): void => {
    if (location.pathname.includes(ROUTES.PRICING)) {
      window.open('https://whistle.tech/', '_self');
      return;
    }

    if (!tenant) {
      navigate(ROUTES.AUTH_LAYOUT);
      return;
    }

    if (tenant.skipSubsCheck) {
      navigate(
        tenant.features?.some((element) => element.name === FEATURES.VOICE)
          ? ROUTES.DASHBOARD_VOICE
          : ROUTES.DASHBOARD_MESSAGES,
      );
      return;
    }

    if (!tenant.skipSubsCheck && tenant.subs && !tenant.subs.isActive) {
      navigate(ROUTES.PROFILE_SUBSCRIPTIONS);
      return;
    }

    if (tenant.status === TENANT.STATUSES.ACTIVE && user && tenant?.subs) {
      if (tenant.features?.some((element) => element.name === FEATURES.VOICE)) {
        navigate(ROUTES.DASHBOARD_VOICE);
        return;
      } else {
        navigate(ROUTES.DASHBOARD_MESSAGES);
        return;
      }
    }

    if (tenant.status === TENANT.STATUSES.PENDING_APPROVAL && tenant.subs) {
      navigate(ROUTES.KYC);
      return;
    }
  };

  const handleLogout = async (): Promise<void> => {
    if (device && device.state === 'registered') {
      await device.unregister();
    }

    await dispatch(userThunk.fetchLogout());

    lsService.onLogout();

    return logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URL,
      },
    });
  };

  return (
    <AppBar
      position="sticky"
      color="primary"
      sx={{
        backgroundSize: '100%',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <AccountsDialog show={showAccountsDialog} setShow={setShowAccountsDialog} />

      <Toolbar disableGutters sx={{ px: isMdOrUp ? 20 : 0 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" flex={1}>
          {isXsOrDown ? null : (
            <Button
              color="primary"
              variant="contained"
              sx={{
                py: 0,
                fontSize: 36,
                fontFamily: 'DM Serif Display',
                '&:hover': {
                  bgcolor: 'primary.main',
                },
              }}
              startIcon={
                <Box component="img" width="50px" height="50px" src={logo} loading="lazy" />
              }
              onClick={handleOnLogoPress}
            >
              Whistle
            </Button>
          )}

          {showLinks ? (
            <Box display="flex" alignItems="center" gap={1} ml="auto">
              <Button
                color="secondary"
                sx={{
                  fontWeight: 'bold',
                  fontSize: 18,
                }}
                onClick={() => navigate(ROUTES.PRICING)}
              >
                Pricing
              </Button>

              {showSignUp ? (
                <Button
                  color="secondary"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 18,
                  }}
                  onClick={() => navigate(ROUTES.SIGN_UP)}
                >
                  Sign up
                </Button>
              ) : null}

              {showSignIn ? (
                <Button
                  color="secondary"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 18,
                  }}
                  onClick={() => navigate(ROUTES.SIGN_IN)}
                >
                  Login
                </Button>
              ) : null}

              {showSignOut ? (
                <Button
                  color="secondary"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 18,
                  }}
                  onClick={() => handleLogout()}
                >
                  Logout
                </Button>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default PublicLayoutHeader;

import { IPaginationParams } from '@/interfaces/querying-data';

import { PropsWithChildren, FunctionComponent, useEffect, useState } from 'react';

import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Stack,
  TablePagination,
  TablePaginationProps,
  Typography,
} from '@mui/material';

interface IDataTemplateProps extends PropsWithChildren {
  isLoading: boolean;
  noDataConfig?: {
    text: string;
    behaviour: 'hide-content-show-only-text' | 'show-content-then-text';
  };
  pagination?: {
    maxCount: number;
    paginationData?: IPaginationParams;
    onPageChange: (data: IPaginationParams) => void;
    onRowsPerPageChange: (data: IPaginationParams) => void;
  };
  loadingComponentProps?: LinearProgressProps;
}

interface IDataTemplatePaginationProps
  extends Pick<TablePaginationProps, 'onPageChange' | 'onRowsPerPageChange'> {
  paginationData: IPaginationParams;
  maxCount: number;
}

const DataTemplatePagination: FunctionComponent<IDataTemplatePaginationProps> = ({
  maxCount,
  paginationData,
  onPageChange,
  onRowsPerPageChange,
}): JSX.Element => {
  const { skip, limit } = paginationData;

  const page = skip ? Math.ceil(skip / limit) : 0;

  if (!maxCount) {
    return <></>;
  }

  return (
    <TablePagination
      component="div"
      color="primary"
      page={page}
      count={maxCount}
      onPageChange={onPageChange}
      rowsPerPage={limit}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
};

const DataTemplate: FunctionComponent<IDataTemplateProps> = ({
  isLoading,
  noDataConfig,
  children,
  pagination,
  loadingComponentProps = {},
}): JSX.Element => {
  const noDataText = noDataConfig?.text ?? 'No data available';
  const hideCutentOnNoData = noDataConfig?.behaviour ?? 'show-content-then-text';

  const [showLinearProgress, setShowLinearProgress] = useState(isLoading);

  const enablePagination = !!pagination;

  const handlePageChange = (event: any, page: number): void => {
    if (!pagination?.paginationData) {
      return;
    }

    const { skip, limit } = pagination.paginationData;

    const currentPage = skip ? Math.ceil(skip / limit) : 0;

    let newSkip = skip;

    if (currentPage < page) {
      newSkip += limit;
    } else if (currentPage > page) {
      newSkip -= limit;
    }

    pagination.onPageChange({ skip: newSkip, limit });
  };

  const handleRowsPerPageChange = (event: any): void => {
    if (!pagination?.paginationData) {
      return;
    }

    const { skip } = pagination.paginationData;

    pagination.onRowsPerPageChange({ skip, limit: event.target.value });
  };

  const getShouldShowNoDataMessage = (): boolean => {
    if (enablePagination && pagination.paginationData) {
      const { skip } = pagination.paginationData;
      return !pagination.maxCount && !skip && !isLoading;
    } else {
      return !isLoading && !children;
    }
  };

  useEffect(() => {
    let id: any;

    if (isLoading) {
      id = setTimeout(() => {
        setShowLinearProgress(true);
      }, 100);
    } else {
      setShowLinearProgress(false);
    }

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [isLoading]);

  return (
    <Stack sx={{ position: 'relative' }}>
      {showLinearProgress ? (
        <LinearProgress
          variant="indeterminate"
          color="primary"
          {...loadingComponentProps}
          sx={{
            position: 'absolute',
            top: -8,
            left: 0,
            zIndex: 101,
            width: '100%',
            ...(loadingComponentProps.sx ?? {}),
          }}
        />
      ) : (
        <></>
      )}

      {getShouldShowNoDataMessage() && hideCutentOnNoData ? null : children}

      {getShouldShowNoDataMessage() ? (
        <Typography variant="body1" component="p" p={1} mt={2} textAlign="center">
          {noDataText}
        </Typography>
      ) : (
        <></>
      )}

      {enablePagination ? (
        <Box mt={2}>
          <DataTemplatePagination
            maxCount={pagination.maxCount}
            paginationData={pagination.paginationData as IPaginationParams}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default DataTemplate;

import { IGetContactsQueryParams } from '@/interfaces/contact';

import { Dispatch, SetStateAction } from 'react';

import { Add, Delete, UploadFile } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import { CONTACT_TYPE } from '@/constants/contacts';

interface IContactsHeaderProps {
  isLoading: boolean;
  queryParams: IGetContactsQueryParams;
  setQueryParams: Dispatch<SetStateAction<IGetContactsQueryParams>>;
  selectedContactsIds: string[];
  handleDeleteManyContacts: (ids: string[]) => void;
  handleCreateContact: () => void;
  handleImportContacts: () => void;
}

const ContactsHeader = ({
  isLoading,
  selectedContactsIds,
  queryParams,
  setQueryParams,
  handleDeleteManyContacts,
  handleCreateContact,
  handleImportContacts,
}: IContactsHeaderProps) => {
  const handleUpdateQueryParams = (params: Partial<IGetContactsQueryParams>) =>
    setQueryParams((prevState) => ({
      ...prevState,
      ...params,
      skip: 0,
    }));

  if (selectedContactsIds.length > 0) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        px={2}
        py={1}
        sx={{
          bgcolor: (theme) =>
            selectedContactsIds.length
              ? alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
              : 'initial',
        }}
      >
        <Typography variant="subtitle1" component="span">
          {selectedContactsIds.length} selected
        </Typography>

        <Tooltip title="Delete">
          <IconButton
            disabled={isLoading}
            onClick={() => handleDeleteManyContacts(selectedContactsIds)}
          >
            {isLoading ? <CircularProgress /> : <Delete color="error" />}
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" px={2} pb={1}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ flexGrow: 1, maxWidth: '40rem' }}
      >
        <TextField
          fullWidth
          type="search"
          label="Search"
          variant="outlined"
          size="small"
          value={queryParams.search}
          onChange={(e) => handleUpdateQueryParams({ search: e.target.value })}
          onBlur={(e) => handleUpdateQueryParams({ search: e.target.value })}
        />

        <ToggleButtonGroup
          fullWidth
          exclusive
          size="small"
          value={queryParams.contactType}
          onChange={(e, value) => (value ? handleUpdateQueryParams({ contactType: value }) : null)}
        >
          <ToggleButton value={CONTACT_TYPE.COMPANY}>Company</ToggleButton>
          <ToggleButton value={CONTACT_TYPE.PRIVATE}>Private</ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Tooltip title="Upload contacts" placement="right">
          <IconButton color="primary" onClick={handleImportContacts}>
            <UploadFile />
          </IconButton>
        </Tooltip>

        <Tooltip title="Add new contact" placement="right">
          <IconButton color="primary" onClick={handleCreateContact}>
            <Add />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default ContactsHeader;

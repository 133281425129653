export const filesCounterBtnSX = {
  display: 'flex',
  justifyContent: 'center',
};

export const imageListSX = {
  display: 'flex',
  justifyContent: 'row',
  minHeight: 180,
  overflow: 'auto',
};

export const imageListItemSX = {
  minHeight: '150px',
  minWidth: '200px',
  border: '1px solid',
  borderColor: 'grey',
  borderRadius: '2px',
};

export const image = {
  borderRadius: '2px',
  height: '150px',
  width: '200px',
};

export const descriptionSX = {
  height: '50px',
  width: '50px',
};

export const imageListItemBarSX = {
  '.MuiImageListItemBar-title': { fontSize: '10px', color: 'white' },
  borderBottomRightRadius: '2px',
  borderBottomLeftRadius: '2px',
};

export const iconBtn1SX = {
  color: 'rgba(255, 255, 255, 0.54)',
};

const defaults = () => ({ 'd-type': 'web' });

const applicationJson = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
});

export default {
  defaults,
  applicationJson,
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { ITenantSetTimeZoneRequest } from '@/interfaces/tenant';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import handleErrors from '@/services/handle-errors';

import { IThunkExtra } from '../store';

import * as SNACKBAR from '@/constants/snackbar';

export const fetchTenantInfo = createAsyncThunk(
  'tenant/fetchTenantInfo',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.getTenantInfo();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchTenant = createAsyncThunk('tenant/fetchTenant', async (data, thunkAPI) => {
  const { v1Apis } = thunkAPI.extra as IThunkExtra;
  const response = await v1Apis.tenantsApis.getTenantInfo();

  if (!response.success) {
    handleErrors(thunkAPI, response.statusCode, response);
    thunkAPI.abort();
  } else {
    return response.data;
  }
});

export const fetchSetTimeZone = createAsyncThunk(
  'tenant/fetchSetTimeZone',
  async (data: ITenantSetTimeZoneRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.setTimeZone(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.UPDATED('Time zone')),
      );
      return response.data;
    }
  },
);

import makeRequest from '../make-request';
import token from '../token';

const DOWNLOAD = '/api/v1/file/download';
const DOWNLOAD_WITH_AUTH = '/api/v1/file/assets/download/with-auth';

export const download = async (key: string) => {
  try {
    const result = await makeRequest(DOWNLOAD, {
      method: 'GET',
      params: { key },
      responseType: 'blob',
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const downloadFileSample = async (key: string) => {
  try {
    const result = await makeRequest(DOWNLOAD_WITH_AUTH, {
      method: 'GET',
      params: { key },
      responseType: 'blob',
      headers: {
        Authorization: await token(),
      },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export default {
  STATUS: {
    QUEUED: 'queued',
    FAILED: 'failed',
    SEND: 'sent',
  },
  TYPE: {
    TEXT: 'text',
    MEDIA: 'media',
    TEXT_MEDIA: 'text/media',
  },
  TRACK_TYPE: {
    INBOUND: 'inbound',
    OUTBOUND: 'outbound',
  },
  PROVIDER: {
    TWILIO: 'twilio',
  } as { [key: string]: 'twilio' },
};

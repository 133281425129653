import makeRequest from '../make-request';

import token from '../token';

const VALIDATE_FILE = '/api/v1/broadcast/campaign/validate/file';
const CREATE = '/api/v1/broadcast/campaign';

export const validateFile = async (data: FormData) => {
  try {
    const result = await makeRequest(VALIDATE_FILE, {
      method: 'POST',
      headers: {
        Authorization: await token(),
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
      },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const create = async (data: FormData) => {
  try {
    const result = await makeRequest(CREATE, {
      method: 'POST',
      headers: {
        Authorization: await token(),
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
      },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

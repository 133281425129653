import makeRequest from '../make-request';
import token from '../token';

import {
  IContactsGroupCreateRequest,
  IContactsGroupUpdateRequest,
  IGetContactsGroupsQueryParams,
} from '@/interfaces/contacts-group';

const GET_CONTACTS_GROUPS = '/api/v1/contacts-groups';
const CREATE = '/api/v1/contacts-groups';
const UPDATE_BY_ID = '/api/v1/contacts-groups/:id';
const DELETE_BY_ID = '/api/v1/contacts-groups/:id';

const getByParams = async (data: IGetContactsGroupsQueryParams) => {
  const queryParams: Record<string, string> = {
    limit: data.limit.toString(),
    skip: data.skip.toString(),
  };

  if (data.search) {
    queryParams.search = data.search;
  }

  try {
    const result = await makeRequest(GET_CONTACTS_GROUPS, {
      method: 'GET',
      headers: { Authorization: await token() },
      params: queryParams,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

const create = async (data: IContactsGroupCreateRequest) => {
  try {
    const result = await makeRequest(CREATE, {
      method: 'POST',
      headers: {
        Authorization: await token(),
      },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

const updateById = async (id: string, data: IContactsGroupUpdateRequest) => {
  try {
    const result = await makeRequest(UPDATE_BY_ID.replace(':id', id), {
      method: 'PUT',
      headers: {
        Authorization: await token(),
      },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

const deleteById = async (id: string) => {
  try {
    const result = await makeRequest(DELETE_BY_ID.replace(':id', id), {
      method: 'DELETE',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export default {
  getByParams,
  create,
  updateById,
  deleteById,
};

import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  IPhoneNumbersAssignUnassignMaskingPhoneNumberForTenantRequest,
  IPhoneNumbersCallerIdResult,
} from '@/interfaces/phone-numbers';

import * as phoneNumbersThunk from '@/store/thunk/phone-numbers';

import { TemplateDialog } from '@/components/Dialogs/Template-dialog';

import useTemplateDialog from '@/hooks/use-template-dialog';

import { PHONE_NUMBER_TYPE } from '@/constants/phone-numbers';

const MaskingSettings: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const tenant = useAppSelector((state) => state.tenant.tenant);

  const [callerIdPhoneNumbers, setCallerIdPhoneNumbers] = useState<IPhoneNumbersCallerIdResult[]>(
    [],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [activeMaskingPhoneNumberId, setActiveMaskingPhoneNumberId] = useState<string | null>('');

  const [dialogState, dialogActions] = useTemplateDialog({
    isOpened: false,
    title: 'Masking phone number',
    textContent: '',
    showCloseButton: false,
    submitButtonProps: { children: 'Activate' },
  });

  const handleAssignUnassignMaskingPhoneNumber = async (id: string) => {
    let idOfEltoFind: string;

    if (!!id) {
      idOfEltoFind = id;

      dialogActions.setProps({
        textContent: 'If other phone number is active it will be deactivated',
        submitButtonProps: { children: 'Activate' },
      });
    } else {
      idOfEltoFind = activeMaskingPhoneNumberId as string;

      dialogActions.setProps({
        textContent: 'Phone number will be deactivated',
        submitButtonProps: { children: 'Deactivate' },
      });
    }

    const response = await dialogActions.openDialog();

    if (!response) {
      return;
    }

    const data = callerIdPhoneNumbers.find((el) => el.id === idOfEltoFind);

    if (!data) {
      return;
    }

    setIsLoading(true);

    const body: IPhoneNumbersAssignUnassignMaskingPhoneNumberForTenantRequest = {
      id: data.id,
      isUsedForMasking: !data.isUsedForMasking,
    };

    const { payload } = await dispatch(
      phoneNumbersThunk.fetchAssignUnassignMaskingPhoneNumberForTenant(body),
    );

    if (payload) {
      setCallerIdPhoneNumbers((prevState) =>
        prevState.map((el) => {
          if (el.isUsedForMasking) {
            el.isUsedForMasking = false;
          }

          if (el.id === data.id) {
            el.isUsedForMasking = body.isUsedForMasking;
          }

          return el;
        }),
      );

      setActiveMaskingPhoneNumberId(body.isUsedForMasking ? payload.id : null);
    }

    setIsLoading(false);
  };

  const handleLoadMaskingPhoneNumbers = async () => {
    setIsLoading(true);

    const { payload } = await dispatch(
      phoneNumbersThunk.fetchGetPhoneNumbers({
        type: PHONE_NUMBER_TYPE.CALLER_ID,
        sortField: 'isUsedForMasking',
        sortDirection: -1,
        limit: 0,
        skip: 0,
      }),
    );

    if (payload) {
      if (payload[0]?.isUsedForMasking) {
        setActiveMaskingPhoneNumberId(payload[0].id);
      }

      setCallerIdPhoneNumbers(payload);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (tenant?.id) {
      handleLoadMaskingPhoneNumbers();
    }
  }, [tenant?.id]);

  return (
    <Stack spacing={1}>
      <TemplateDialog {...dialogState} />

      <Typography variant="body1" component="p">
        Masking phone number
      </Typography>

      <Typography variant="body2" component="p">
        *For outgoing calls
      </Typography>

      <FormControl fullWidth>
        <InputLabel id="masking-phone-number-label">Select from list</InputLabel>
        <Select
          variant="standard"
          labelId="masking-phone-number-label"
          label="Select masking phone."
          placeholder="Select masking phone..."
          disabled={!callerIdPhoneNumbers.length}
          value={activeMaskingPhoneNumberId}
          onChange={(event) => handleAssignUnassignMaskingPhoneNumber(event.target.value as string)}
        >
          <MenuItem disabled={!activeMaskingPhoneNumberId} value={''}>
            Deactivate masking phone number
          </MenuItem>

          {callerIdPhoneNumbers.map((el) => (
            <MenuItem key={el.id} value={el.id}>
              {el.phoneNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default MaskingSettings;

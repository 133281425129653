import { Box, Divider, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

import VoicemailTranscription from './Voicemail-transcription';
import LiveCallsTranscription from './Live-calls-transcription';
import CallRecordings from './Call-recordings';
import DevicesPreferences from './Devices-preferences';

const CallsSettingsLayout: FunctionComponent = (): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" flex={1} gap={1}>
      <Box mb={2}>
        <Typography variant="h6" component="p">
          Call settings
        </Typography>

        <Divider />
      </Box>

      <Box display="flex" flexDirection="column" gap={2}>
        <CallRecordings />
        <LiveCallsTranscription />
        <VoicemailTranscription />

        <Box mt={2}>
          <DevicesPreferences />
        </Box>
      </Box>
    </Box>
  );
};

export default CallsSettingsLayout;

import * as voiceThunk from '@/pages/dashboard/voice/thunk';
import * as voiceReducer from '@/store/reducers/dashboard/voice';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import { AppDispatch } from '@/store/store';

import { getSelectedConnection } from '../voice/call';

export const addParticipant = async (data: {
  dispatch: AppDispatch;
  uuid?: string;
  phoneNumber?: string;
  userId?: string;
  contactId?: string;
}): Promise<boolean | void> => {
  const { dispatch, phoneNumber, contactId, userId } = data;

  const selectedNowConnection = getSelectedConnection();
  if (!selectedNowConnection) {
    return;
  }

  if (selectedNowConnection.onHold) {
    dispatch(
      snackbarReducer.showInfo(
        `We're unable to add a participant at the moment because the connection is currently on hold. `,
      ),
    );
    return;
  }

  if (!phoneNumber && !userId && !contactId) {
    return;
  }

  const { payload, meta } = await dispatch(
    voiceThunk.fetchAddParticipants({
      uuid: selectedNowConnection.uuid,
      data: { phoneNumber, contactId, userId },
    }),
  );

  if (payload) {
    dispatch(
      voiceReducer.updateConnection({ ...selectedNowConnection, isMultipleParticipants: true }),
    );
  }

  return meta.requestStatus === 'fulfilled';
};

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { Switch, Box, Stack, Typography } from '@mui/material';

import * as settingsThunk from '../../thunk';

const LiveCallsTranscription = () => {
  const dispatch = useAppDispatch();

  const { tenant } = useAppSelector((state) => state.tenant);

  const handleTurnOnOffCallsRecordings = async (): Promise<void> => {
    await dispatch(settingsThunk.fetchTurnOnOffLiveCallsTranscription());
  };

  return (
    <Stack spacing={1}>
      <Typography ml={1} component="label">
        *AI Live Calls Transcription
      </Typography>
      <Box>
        <Switch
          checked={!!tenant?.liveCallsTranscription}
          onChange={handleTurnOnOffCallsRecordings}
        />
        <Typography ml={1} component="label">
          Turn On/Off live calls transcription for all outgoing and incoming calls
        </Typography>
      </Box>
    </Stack>
  );
};

export default LiveCallsTranscription;

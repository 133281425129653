import { FunctionComponent } from 'react';
import { Stack, Typography } from '@mui/material';

import { IMessageResult } from '@/interfaces/message';

import MESSAGES from '@/constants/message';

const prepareTooltipData = (message: IMessageResult) => {
  const { isInternal, trackType, userSender, user, contact, from } = message;

  let displayName;
  let companyExtension;
  let directExtension;
  let phoneNumber;

  if (isInternal) {
    displayName = userSender ? userSender.fullName : 'DELETED ACCOUNT';
    companyExtension = userSender?.companyExtension;
  }

  if (!isInternal && trackType === MESSAGES.TRACK_TYPE.INBOUND) {
    displayName = contact ? contact.fullName : '';
    phoneNumber = contact?.phoneNumber ?? from;
  }
  if (!isInternal && trackType === MESSAGES.TRACK_TYPE.OUTBOUND) {
    displayName = user ? user.fullName : 'DELETED ACCOUNT';
    companyExtension = user?.companyExtension;
    directExtension = user?.directExtension;
  }

  return {
    displayName,
    companyExtension,
    directExtension,
    phoneNumber,
  };
};

const MessageSenderInfoTooltip: FunctionComponent<Record<'message', IMessageResult>> = ({
  message,
}): JSX.Element => {
  const { displayName, companyExtension, directExtension, phoneNumber } =
    prepareTooltipData(message);

  return (
    <Stack>
      {displayName ? <Typography fontSize="small">Name: {displayName}</Typography> : null}

      {phoneNumber ? <Typography fontSize="small">Phone Number: {phoneNumber}</Typography> : null}

      {companyExtension ? (
        <Typography fontSize="small">Company Extension: {companyExtension}</Typography>
      ) : null}

      {directExtension ? (
        <Typography fontSize="small">Direct Extension: {directExtension}</Typography>
      ) : null}
    </Stack>
  );
};

export default MessageSenderInfoTooltip;

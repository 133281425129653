import {
  IGetPhoneNumbersParams,
  IPhoneNumbersDirectExtensionResult,
} from '@/interfaces/phone-numbers';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { IUserResult } from '@/interfaces/users/users';

import TemplateTable, { ITemplateTableProps } from '@/components/Template-table/Template-table';
import Dot from '@/components/Dot/Dot';

import apisV1 from '@/services/api/v1/index';

interface IDirectExtensionsListProps {
  isLoading: boolean;
  params: IGetPhoneNumbersParams<IPhoneNumbersDirectExtensionResult>;
  setParams: (state: Partial<IGetPhoneNumbersParams<IPhoneNumbersDirectExtensionResult>>) => void;
  directExtensions: IPhoneNumbersDirectExtensionResult[];
  handleUserChange?: (
    user: IUserResult | null,
    directExtension: IPhoneNumbersDirectExtensionResult,
  ) => void;
}

interface IUserDirectExtensionsAutocompleteProps {
  isLoading: boolean;
  value: IUserResult | null;
  users: IUserResult[];
  directExtension: IPhoneNumbersDirectExtensionResult;
  directExtensions: IPhoneNumbersDirectExtensionResult[];
  handleChange: Required<IDirectExtensionsListProps>['handleUserChange'];
}

const UserDirectExtensionsAutocomplete = ({
  isLoading,
  value: initialValue,
  users,
  directExtension,
  directExtensions,
  handleChange,
}: IUserDirectExtensionsAutocompleteProps) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue?.id]);

  return (
    <Autocomplete
      disablePortal
      options={users}
      clearOnBlur
      value={value}
      disabled={isLoading}
      onChange={(event, option) => {
        if (event.type === 'change' && !option) {
          return;
        } else {
          setValue(option);
          handleChange(option, directExtension);
        }
      }}
      getOptionLabel={(el) => !!el && (el.fullName as string)}
      getOptionDisabled={(option) => directExtensions.some((el) => el.userRef === option.id)}
      renderInput={(params) => <TextField {...params} placeholder="User is not assigned" />}
    />
  );
};

const DirectExtensionsList = ({
  isLoading,
  params,
  setParams,
  directExtensions,
  handleUserChange,
}: IDirectExtensionsListProps) => {
  const [users, setUsers] = useState<IUserResult[]>([]);

  const loadUsers = async () => {
    if (!isUpdateAllowed) {
      return;
    }

    const { success, data } = await apisV1.usersApis.getByParams({
      excludeMe: false,
      skip: 0,
      limit: 0,
    });

    setUsers(success ? data.records : []);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const isUpdateAllowed = !!handleUserChange;
  const templateDirectExtensionsTableProps: ITemplateTableProps<IPhoneNumbersDirectExtensionResult> =
    {
      getDataEntityId: (el) => el.phoneNumber,
      data: directExtensions,
      columns: [
        {
          header: 'Phone number',
          getValue: (el) => el.phoneNumber,
          sortFieldName: 'phoneNumber',
        },
        {
          header: 'Assigned',
          getValue: (el) => <Dot isActive={!!el.userRef} />,
          sortFieldName: 'userRef',
        },
        {
          sharedProps: { sx: { width: '40%' } },
          header: 'User',
          getValue: (el) => {
            const user = users.find(({ id }) => id === el.userRef);

            if (!isUpdateAllowed) {
              return user ? (user.fullName as string) : 'not assigned';
            }

            return (
              <UserDirectExtensionsAutocomplete
                isLoading={isLoading}
                directExtension={el}
                value={user || null}
                users={users}
                directExtensions={directExtensions}
                handleChange={handleUserChange}
              />
            );
          },
        },
      ],
      onSortChange: (event, sortParams) => setParams(sortParams),
      currentSortParams: {
        sortField: params.sortField,
        sortDirection: params.sortDirection,
      },
      tableContainerProps: {
        sx: {
          '&::-webkit-scrollbar': { display: 'none' },
          overflow: 'auto',
          height: 'calc(100vh - 360px)',
        },
      },
    };

  return <TemplateTable {...templateDirectExtensionsTableProps} />;
};

export default DirectExtensionsList;

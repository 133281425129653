import { Dispatch, SetStateAction } from 'react';
import { Dialog, DialogActions, DialogTitle, Button, Link } from '@mui/material';

const InternetConnectionDialog = (props: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  const { setShow, show } = props;

  return (
    <Dialog fullWidth maxWidth="xs" open={show}>
      <DialogTitle>Please check your internet connection...</DialogTitle>

      <DialogActions>
        <Link
          disableElevation
          component={Button}
          onClick={() => {
            setShow(false);
            window.location.reload();
          }}
          sx={{ textDecoration: 'none' }}
        >
          Refresh
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default InternetConnectionDialog;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IProfile {
  newAvatar: any;
}

const initialState: IProfile = {
  newAvatar: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setDefault: (state) => {
      for (const key in initialState) {
        //@ts-ignore
        state[key] = initialState[key];
      }
    },
    setNewAvatar: (state, action: PayloadAction<any>) => {
      state.newAvatar = action.payload;
    },
  },
});

export const { setDefault, setNewAvatar } = profileSlice.actions;

export default profileSlice.reducer;

import { IResponseResult } from '@/interfaces/base';
import makeRequest from '../make-request';
import makeRequestV2 from '../make-request-v2';

import token from '../token';

import {
  IUserChangePasswordRequest,
  IUserUpdateMePreferencesRequest,
  IUserUpdateAllUsersPreferencesRequest,
  IGetUsersQueryParams,
  IUserLinkAccountsRequest,
  IUserSwitchAccountRequest,
  IUserUnlinkAccountRequest,
  IUserAcceptLegalInfoRequest,
  IUserUpdateMeRequest,
  IUserSetPermissionsRequest,
  IUserSetAllowedCountryCodesRequest,
  ISetUsersAllowedCountryCodesRequest,
  IUsersUpdateSaAllowedIpRecordRequest,
  IUsersCreateSaAllowedIpRecordRequest,
  IUserResult,
} from '@/interfaces/users/users';
import { IPartialResult } from '@/interfaces/querying-data';

const CREATE = '/api/v1/user';
const EDIT = '/api/v1/user';
const DELETE_BY_ID = '/api/v1/user';
const GET_USERS = '/api/v1/user';
const SET_SESSION = '/api/v1/user/session';
const CHECK_SESSION = '/api/v1/user/session';
const SAVE_LEGAL_INFO = '/api/v1/user/legalinfo';
const GET_BY_COMPANY_EXTENSION = '/api/v1/user/company-extension';
const GET_BY_DIRECT_EXTENSION = '/api/v1/user/direct-extension';
const RESET_USER_PASSWORD = '/api/v1/user/$id/reset-password';
const UPDATE_ALL_USERS_PREFERENCES = '/api/v1/user/all-users-preferences';

const UPLOAD_USER_AVATAR = '/api/v1/user/me/avatar';
const DELETE_USER_AVATAR = '/api/v1/user/me/avatar';
const UPDATE_ME = '/api/v1/user/me';
const CHANGE_PASSWORD = '/api/v1/user/me/change-password';
const GET_USER_INFO = '/api/v1/user/me';
const UPDATE_ME_PREFERENCES = '/api/v1/user/me/preferences';
const LOGOUT = '/api/v1/user/me/logout';
const LOGIN = '/api/v1/user/me/login';
const GET_ACCOUNTS = '/api/v1/user/me/accounts';
const LINK_NEW_ACCOUNT = '/api/v1/user/me/accounts';
const SWITCH_ACCOUNT = '/api/v1/user/me/accounts/switch';
const UNLINK_ACCOUNT = '/api/v1/user/me/accounts/unlink';
const CLICK_TO_CALL_DEKSTOP_APP_REQUEST = `/api/v1/user/me/request/click-to-call-desktop-app`;

const SET_USERS_ALLOWED_COUNTRIES = '/api/v1/user/users-allowed-country-codes';
const SET_PERMISSIONS = (userId: string) => `/api/v1/user/${userId}/permissions`;
const GET_BY_ID = (userId: string) => `/api/v1/user/${userId}`;
const SET_ALLOWED_COUNTRY_CODES = (userId: string) =>
  `/api/v1/user/${userId}/allowed-country-codes`;
const RESET_MISSED_CALLS_COUNT = `/api/v1/user/me/reset-missed-calls-count`;
const UPDATE_SA_ALLOWED_IP = (id: string) => `/api/v1/user/sa/ips/${id}`;
const DELETE_SA_ALLOWED_IP = (id: string) => `/api/v1/user/sa/ips/${id}`;
const CREATE_SA_ALLOWED_IP = `/api/v1/user/sa/ips`;

// Refer to IUserCreateRequest interface for structure of FormData
export const create = async (data: FormData) => {
  try {
    const result = await makeRequest(CREATE, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const deleteById = async (id: string) => {
  try {
    const result = await makeRequest(`${DELETE_BY_ID}/${id}`, {
      method: 'DELETE',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

// Refer to IUserUpdateRequest interface for structure of FormData
export const edit = async (id: string, data: FormData) => {
  try {
    const result = await makeRequest(`${EDIT}/${id}`, {
      method: 'PUT',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getByParams = async (
  data: IGetUsersQueryParams,
): Promise<IResponseResult<IPartialResult<IUserResult>>> => {
  const queryParams: Record<string, string> = {
    excludeMe: data.excludeMe.toString(),
    skip: data.skip.toString(),
    limit: data.limit.toString(),
  };

  if (data.search) {
    queryParams.search = data.search;
  }

  if (data.sortField) {
    queryParams.sortField = data.sortField;
  }

  if (data.sortDirection) {
    queryParams.sortDirection = data.sortDirection.toString();
  }

  const result = await makeRequestV2(GET_USERS, {
    method: 'GET',
    headers: { Authorization: await token() },
    query: queryParams,
  });

  return result;
};

export const getUserInfo = async () => {
  try {
    const result = await makeRequest(GET_USER_INFO, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const setSession = async () => {
  try {
    const result = await makeRequest(SET_SESSION, {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const checkSession = async () => {
  try {
    const result = await makeRequest(CHECK_SESSION, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const saveLegalInfoAccepted = async (data: IUserAcceptLegalInfoRequest) => {
  try {
    const result = await makeRequest(SAVE_LEGAL_INFO, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getByCompanyExtension = async (companyExtension: string) => {
  try {
    const result = await makeRequest(`${GET_BY_COMPANY_EXTENSION}/${companyExtension}`, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getByDirectExtension = async (directExtension: string) => {
  try {
    const result = await makeRequest(`${GET_BY_DIRECT_EXTENSION}/${directExtension}`, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

// Refer to IUserSetAvatarRequest interface for structure of FormData
export const uploadUserAvatar = async (data: FormData) => {
  try {
    const result = await makeRequest(UPLOAD_USER_AVATAR, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateMe = async (data: IUserUpdateMeRequest) => {
  try {
    const result = await makeRequest(UPDATE_ME, {
      method: 'PATCH',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = async (data: IUserChangePasswordRequest) => {
  try {
    const result = await makeRequest(CHANGE_PASSWORD, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const deleteAvatar = async () => {
  try {
    const result = await makeRequest(DELETE_USER_AVATAR, {
      method: 'DELETE',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateMePreferences = async (data: IUserUpdateMePreferencesRequest) => {
  return makeRequest(UPDATE_ME_PREFERENCES, {
    method: 'PATCH',
    headers: { Authorization: await token() },
    data,
  });
};

export const resetPassword = async (userId: string) => {
  try {
    const result = await makeRequest(RESET_USER_PASSWORD.replace('$id', userId), {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateAllUsersPreferences = async (data: IUserUpdateAllUsersPreferencesRequest) => {
  try {
    const result = await makeRequest(UPDATE_ALL_USERS_PREFERENCES, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const logout = async () => {
  try {
    const result = await makeRequest(LOGOUT, {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const login = async () => {
  try {
    const result = await makeRequest(LOGIN, {
      method: 'POST',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getAccounts = async () => {
  try {
    const result = await makeRequest(GET_ACCOUNTS, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const linkNewAccount = async (data: IUserLinkAccountsRequest) => {
  try {
    const result = await makeRequest(LINK_NEW_ACCOUNT, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const switchAccount = async (data: IUserSwitchAccountRequest) => {
  try {
    const result = await makeRequest(SWITCH_ACCOUNT, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const unlinkAccount = async (data: IUserUnlinkAccountRequest) => {
  try {
    const result = await makeRequest(UNLINK_ACCOUNT, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const setPermissions = async (userId: string, data: IUserSetPermissionsRequest) => {
  try {
    const result = await makeRequest(SET_PERMISSIONS(userId), {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getById = async (userId: string) => {
  try {
    const result = await makeRequest(GET_BY_ID(userId), {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const setAllowedCountryCodes = async (
  userId: string,
  data: IUserSetAllowedCountryCodesRequest,
) => {
  try {
    const result = await makeRequest(SET_ALLOWED_COUNTRY_CODES(userId), {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const setUsersAllowedCountryCodes = async (data: ISetUsersAllowedCountryCodesRequest) => {
  return makeRequest(SET_USERS_ALLOWED_COUNTRIES, {
    method: 'POST',
    headers: { Authorization: await token() },
    data,
  });
};

export const resetMissedCallsCount = async () => {
  return makeRequest(RESET_MISSED_CALLS_COUNT, {
    method: 'POST',
    headers: { Authorization: await token() },
  });
};

export const updateSaAllowedIp = async (id: string, data: IUsersUpdateSaAllowedIpRecordRequest) => {
  return makeRequest(UPDATE_SA_ALLOWED_IP(id), {
    method: 'PATCH',
    headers: { Authorization: await token() },
    data,
  });
};

export const deleteSaAllowedIp = async (id: string, data: IUsersUpdateSaAllowedIpRecordRequest) => {
  return makeRequest(DELETE_SA_ALLOWED_IP(id), {
    method: 'POST',
    headers: { Authorization: await token() },
    data,
  });
};

export const createSaAllowedIp = async (data: IUsersCreateSaAllowedIpRecordRequest) => {
  return makeRequest(CREATE_SA_ALLOWED_IP, {
    method: 'POST',
    headers: { Authorization: await token() },
    data,
  });
};

export const requestClickToCallDekstopApp = async () => {
  return makeRequest(CLICK_TO_CALL_DEKSTOP_APP_REQUEST, {
    method: 'POST',
    headers: { Authorization: await token() },
  });
};

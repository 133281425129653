import { useRef } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { Formik, FormikProps } from 'formik';

import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';

import * as userThunk from '@/pages/dashboard/users/thunk';

import { IUserUpdateMePreferencesRequest } from '@/interfaces/users/users';

import usersValidationSchemas from '@/helpers/validation-schemas/users/index';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import PhoneNumberInput from '@/components/Phone-number-input/Phone-number-input';

import * as SNACKBAR from '@/constants/snackbar';
import { FORMIK_STATUS } from '@/constants/formik';
import CALL from '@/constants/voice/voice';

type CallPreferences = Omit<
  Required<IUserUpdateMePreferencesRequest>,
  'dnd' | 'selectedPhoneNumberTypeToMakeCallsFrom'
>;

const CallPreferences = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.user);

  const formikRef = useRef<FormikProps<CallPreferences> | null>(null);

  const handleSubmit = async (body: IUserUpdateMePreferencesRequest): Promise<void> => {
    if (!formikRef.current) {
      return;
    }

    const formik = formikRef.current;

    const { payload } = await dispatch(userThunk.fetchUpdateMePreferences(body));

    if (payload) {
      formik.resetForm({
        touched: {},
        errors: {},
      });

      dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.UPDATED('Preferences')));
    } else {
      formik.resetForm();
    }
  };

  if (!user) {
    return <></>;
  }

  return (
    <Box display="flex" flexDirection="column" gap={2} component={Paper} p={2}>
      <Typography variant="h6">Call preferences</Typography>

      <Divider />

      <Formik
        innerRef={(ref) => (formikRef.current = ref)}
        enableReinitialize
        initialValues={
          {
            alwaysForwardTo: user?.alwaysForwardTo ?? '',
            isVoicemailEnabled: !!user?.isVoicemailEnabled,
            isAlwaysForwardEnabled: !!user?.isAlwaysForwardEnabled,
            devicesPreferences: user?.devicesPreferences ?? [],
          } as CallPreferences
        }
        validationSchema={usersValidationSchemas.updateMeCallPreferences}
        onSubmit={async (values) => {
          await handleSubmit(values);
        }}
      >
        {({ values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => {
          return (
            <Box display="flex" flexDirection="column" gap={1}>
              <FormControlLabel
                label="Enable voicemail"
                control={
                  <Switch
                    name="isVoicemailEnabled"
                    checked={values.isVoicemailEnabled}
                    onChange={async (event, value) => {
                      handleChange(event);
                      await handleSubmit({ isVoicemailEnabled: value });
                    }}
                    onBlur={handleBlur}
                  />
                }
              />

              <Box display="flex" flexDirection="column" gap={1}>
                <FormControlLabel
                  label="Always forward"
                  control={
                    <Switch
                      name="isAlwaysForwardEnabled"
                      checked={values.isAlwaysForwardEnabled}
                      onBlur={handleBlur}
                      onChange={(event, checked) => {
                        setFieldValue('isAlwaysForwardEnabled', checked);
                        setFieldTouched('isAlwaysForwardEnabled', true);

                        handleSubmit({
                          isAlwaysForwardEnabled: checked,
                          alwaysForwardTo: values.alwaysForwardTo ?? null,
                        });
                      }}
                    />
                  }
                />

                <PhoneNumberInput
                  fullWidth
                  skipValidationOnEmpty
                  value={values.alwaysForwardTo ?? ''}
                  countryCodesListType={'user-list'}
                  onPhoneNumberChange={(value: string | null) => {
                    setFieldValue('alwaysForwardTo', value ?? '');
                    setFieldTouched('alwaysForwardTo', true);
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        disabled={!values.isAlwaysForwardEnabled || !values.alwaysForwardTo}
                        color="success"
                        onClick={() => {
                          handleSubmit({
                            isAlwaysForwardEnabled: values.isAlwaysForwardEnabled,
                            alwaysForwardTo: values.alwaysForwardTo,
                          });
                        }}
                        sx={{ p: 0 }}
                      >
                        <SaveIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Box>

              <Box display="flex" flexDirection="column" gap={1} mt={1}>
                <Typography component="label" htmlFor="devices-preferences">
                  Select devices that should ring for inbound call
                </Typography>

                <ToggleButtonGroup
                  id="devices-preferences"
                  fullWidth
                  size="small"
                  value={values.devicesPreferences
                    ?.filter((el) => el.allowCall)
                    .map((el) => el.type)}
                  onChange={async (event, types) => {
                    const newDevicesPreferences = values.devicesPreferences.map(
                      (el: CallPreferences['devicesPreferences']['0']) => ({
                        type: el.type,
                        allowCall: types.includes(el.type),
                      }),
                    );

                    handleChange({
                      ...event,
                      target: {
                        ...event.target,
                        name: 'devicesPreferences',
                        value: newDevicesPreferences,
                      },
                    });

                    await handleSubmit({ devicesPreferences: newDevicesPreferences });
                  }}
                >
                  {values.devicesPreferences.map((el: any) => (
                    <ToggleButton name="devicesPreferences" key={el.type} value={el.type}>
                      {CALL.VOICE_DEVICES_PREFERENCES_LABELS[el.type as CALL.VOICE_DEVICE]}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CallPreferences;

import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';

import { IFeatureComparison } from '@/interfaces/features-comparison';
import { ISubscriptionResult } from '@/interfaces/subscriptions';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SportsIcon from '@mui/icons-material/Sports';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Box,
  Button,
  Typography,
} from '@mui/material';

import { SubsTerm } from '@/types/subs';

import * as pricingThunk from '@/pages/pricing/thunk';

import MonthlyYearlySwitcher from '@/components/Buttons/Monthly-yearly/Monthly-yearly';
import PublicLayoutFooter from '@/components/Public-layout/Public-layout-footer';
import PublicLayoutHeader from '@/components/Public-layout/Public-layout-header';

import useThemeBreakpoints from '@/hooks/use-theme-breakpoints';

import * as styles from './styles';

import ROUTES from '@/constants/routes';
import SUBS from '@/constants/subscriptions';

interface IFeature extends IFeatureComparison {
  expanded: boolean;
}

const Pricing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isXsOrDown = useThemeBreakpoints('down', 'xs');

  const [subsTerm, setSubsTerm] = useState<SubsTerm>(SUBS.TERM.ANNUAL);
  const [features, setFeatures] = useState<IFeature[]>([]);
  const [subscriptions, setSubscriptions] = useState<ISubscriptionResult[]>([]);

  const { isLoggedIn } = useAppSelector((state) => state.auth);

  const getFeaturesComparison = async (): Promise<void> => {
    const { payload } = await dispatch(pricingThunk.fetchFeaturesComparison());
    if (payload) {
      setFeatures(payload.map((element: IFeature) => ({ ...element, expanded: false })));
    }
  };

  const getSubscriptions = async (): Promise<void> => {
    const { payload } = await dispatch(pricingThunk.fetchSubscriptions());

    if (payload) {
      setSubscriptions(payload);
    }
  };

  const handleExpandAccordion = (index: number): void => {
    const updatedFeatures = features.map((element, i) => {
      if (i === index) {
        element.expanded = !element.expanded;
      }
      return element;
    });
    setFeatures(updatedFeatures);
  };

  const handleGoToSignup = (): void => {
    navigate(ROUTES.SIGN_UP);
  };

  const getPrice = (data: ISubscriptionResult): number => {
    const price = data.prices.find((element) => element.name === SUBS.PRICE_TYPES.GLOBAL);

    if (!price) {
      return 99;
    }

    if (data.pricingTerm !== SUBS.PRICING_TERM.ALL) {
      return data.pricingTerm === SUBS.PRICING_TERM.ANNUAL ? price.annual : price.monthly;
    }
    if (price) {
      return subsTerm === SUBS.TERM.MONTHLY ? price[subsTerm] : +(price[subsTerm] / 12).toFixed(2);
    }
    return 99;
  };

  useEffect(() => {
    getFeaturesComparison();
    getSubscriptions();
  }, []);

  return (
    <Box display="flex" justifyContent="space-between" flexDirection="column" minHeight="100vh">
      <PublicLayoutHeader showSignUp={!isLoggedIn} />

      <Box
        display="flex"
        flex={1}
        sx={{
          // 128px because it is height of AppBar and Footer components combined
          height: 'calc(100vh - 128px)',
          overflow: 'auto',
        }}
      >
        <Grid container spacing={2} justifyContent={'center'} pb={2}>
          <Grid item xs={12} alignItems={'center'}></Grid>

          <Grid item xs={12}>
            <Grid
              container
              justifyContent={'center'}
              alignItems={'flex-end'}
              flexDirection={'row'}
              spacing={1}
              px={3}
            >
              <Grid item xs={12}>
                <MonthlyYearlySwitcher paymentType={subsTerm} setPaymentType={setSubsTerm} />
              </Grid>
              {subscriptions.map((element, index) => {
                return (
                  <Grid item xs={12} sm={9} md={4} xl={3} lg={4} key={index}>
                    <Card elevation={24}>
                      {element.isBestValue ? (
                        <CardContent>
                          <Typography sx={styles.Typography19SX}>Best Value</Typography>
                        </CardContent>
                      ) : null}

                      <CardContent sx={styles.CardContent1SX}>
                        <Box>
                          <Typography sx={styles.Typography5SX}>{element.name}</Typography>
                          {element.subheader && (
                            <Typography sx={styles.Typography20SX}>{element.subheader}</Typography>
                          )}
                          <Typography sx={styles.Typography6SX}>{element.description}</Typography>
                          {element.description2 && (
                            <Typography sx={styles.Typography6SX}>
                              {element.description2}
                            </Typography>
                          )}
                        </Box>

                        <Box sx={styles.CardContent2SX}>
                          <Typography sx={styles.Typography7SX}>${getPrice(element)}</Typography>
                          <Typography sx={styles.Typography8SX}>user/month</Typography>
                        </Box>
                      </CardContent>

                      <CardActions sx={styles.CardActions1SX}>
                        <Button variant="outlined" onClick={handleGoToSignup} sx={styles.Button1SX}>
                          Get started
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
              {/* <Grid item xs={12} sm={9} md={3} lg={2}>
                <Card elevation={15}>
                  <CardContent sx={styles.CardContent1SX}>
                    <Box>
                      <Typography sx={styles.Typography5SX}>
                        Scale-Up / Political Campaigns
                      </Typography>
                      <Typography sx={styles.Typography20SX}>
                        Custom licensing or Political Campaigns
                      </Typography>
                      <Typography sx={styles.Typography6SX}>
                        For special business needs, political campaigns, or for Enterprise
                        requirements, please contact us and our sales team can assist.
                      </Typography>
                      <Typography sx={styles.Typography6SX}>
                        Please click 'Get Started' button below to provide information to create a
                        basic account. No pricing is applied at this point and you are not under
                        contract. Our sales team will use the information to reach out.
                      </Typography>
                    </Box>
                  </CardContent>

                  <CardActions sx={styles.CardActions1SX}>
                    <Button variant="outlined" onClick={handleGoToSignup} sx={styles.Button1SX}>
                      Get started
                    </Button>
                  </CardActions>
                </Card>
              </Grid> */}
            </Grid>
          </Grid>

          <Grid item md={11} lg={11}>
            <Box sx={styles.Box2SX}>
              <Box sx={styles.Box3SX}>
                <Box sx={styles.Box10SX}>
                  <Typography sx={styles.Typography9SX} fontSize={isXsOrDown ? 10 : 13}>
                    BUSINESS FEATURES COMPARISON
                  </Typography>
                </Box>

                {subscriptions.map((element, index) => {
                  return (
                    <Box key={index} sx={styles.Box4SX}>
                      <Typography sx={styles.Typography10SX}>{element.name}</Typography>
                    </Box>
                  );
                })}
              </Box>

              {features.map((element, index) => {
                return (
                  <Accordion
                    disableGutters
                    key={index}
                    expanded={element.expanded}
                    onChange={() => handleExpandAccordion(index)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={styles.Typography11SX}>{element.category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {element.items.map((item, i) => {
                        return (
                          <Box key={i}>
                            <Box sx={styles.Box5SX}>
                              <Box sx={styles.Box6SX}>
                                <Typography fontSize={isXsOrDown ? 10 : 13} py={1}>
                                  {item}
                                </Typography>
                              </Box>

                              {subscriptions.map((subscription, idx) => {
                                const isIncluded = subscription.featuresComparisons?.some(
                                  (feature: string) => feature === item,
                                );

                                return (
                                  <Box key={idx} sx={styles.Box7SX}>
                                    <SportsIcon
                                      color={isIncluded ? 'success' : 'error'}
                                      sx={styles.Icon1SX}
                                    />
                                  </Box>
                                );
                              })}
                            </Box>
                            <Divider />
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <PublicLayoutFooter />
    </Box>
  );
};

export default Pricing;

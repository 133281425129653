import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    fontSize: theme.typography.pxToRem(15),
    border: '1px solid white',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'black',
    '&::before': {
      backgroundColor: 'black',
      border: '1px solid white',
    },
  },
}));

export default HtmlTooltip;

import { FunctionComponent } from 'react';

import { Typography } from '@mui/material';

import { NO_DATA_VALUE } from '@/constants/layout';

interface INoDataValueProps {
  fontSize?: number;
}

const NoDataValue: FunctionComponent<INoDataValueProps> = (
  props: INoDataValueProps,
): JSX.Element => {
  const fontSize = props?.fontSize;

  return (
    <Typography fontSize={fontSize || 'inherit'} color={'gray'}>
      {NO_DATA_VALUE}
    </Typography>
  );
};

export default NoDataValue;

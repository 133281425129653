import '@/styles/datepicker-styles.scss';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Grid from '@mui/material/Grid';

import { VoiceSettingsIncomingSetup } from '@/interfaces/voice/settings';
import { IUserResult } from '@/interfaces/users/users';
import { IExtensionsGroupResult } from '@/interfaces/extensions-groups';

import Switch from '@mui/material/Switch';
import CustomAvatar from '@/components/Custom-avatar/Avatar';

import * as styles from './styles';

import VOICE_SETTINGS from '@/constants/voice/settings';

type callFlowType =
  | VoiceSettingsIncomingSetup['workingHours']['callFlow']
  | VoiceSettingsIncomingSetup['afterHours']['callFlow'];

const CallFlow = (props: {
  callFlow: callFlowType;
  whichHours: 'workingHours' | 'afterHours';
  users: IUserResult[];
  extensionsGroups: IExtensionsGroupResult[];
  setVoiceSetup: React.Dispatch<React.SetStateAction<VoiceSettingsIncomingSetup>>;
}) => {
  const { callFlow, whichHours, users, setVoiceSetup, extensionsGroups } = props;

  const handleSelectExtensionsGroup = (reason: string, index: number, value: string) => {
    if (reason === 'selectOption') {
      setVoiceSetup((current) => {
        return {
          ...current,
          [whichHours]: {
            ...current[whichHours],
            callFlow: callFlow.map((element, i) => {
              if (i === index) {
                return { ...element, extensionsGroupRef: value };
              }
              return element;
            }),
          },
        };
      });
    }
    if (reason === 'clear') {
      setVoiceSetup((current) => {
        return {
          ...current,
          [whichHours]: {
            ...current[whichHours],
            callFlow: callFlow.map((element, i) => {
              if (i === index) {
                return { ...element, extensionsGroupRef: null };
              }
              return element;
            }),
          },
        };
      });
    }
  };

  const handleSelectNumber = (reason: string, index: number, value: number) => {
    if (reason === 'selectOption') {
      setVoiceSetup((current) => {
        return {
          ...current,
          [whichHours]: {
            ...current[whichHours],
            callFlow: callFlow.map((element, i) => {
              if (i === index) {
                return { ...element, number: value };
              }
              return element;
            }),
          },
        };
      });
    }
    if (reason === 'clear') {
      setVoiceSetup((current) => {
        return {
          ...current,
          [whichHours]: {
            ...current[whichHours],
            callFlow: callFlow.map((element, i) => {
              if (i === index) {
                return { ...element, number: 1 };
              }
              return element;
            }),
          },
        };
      });
    }
  };

  const handleSelectCompanyExtension = (
    reason: string,
    index: number,
    value: string | undefined,
  ) => {
    if (reason === 'selectOption' && value) {
      setVoiceSetup((current) => {
        return {
          ...current,
          [whichHours]: {
            ...current[whichHours],
            callFlow: callFlow.map((element, i) => {
              if (i === index) {
                return { ...element, companyExtension: value };
              }
              return element;
            }),
          },
        };
      });
    }
    if (reason === 'clear') {
      setVoiceSetup((current) => {
        return {
          ...current,
          [whichHours]: {
            ...current[whichHours],
            callFlow: callFlow.map((element, i) => {
              if (i === index) {
                return { ...element, companyExtension: null };
              }
              return element;
            }),
          },
        };
      });
    }
  };

  const handleActiveSwitch = (index: number) => {
    if (!callFlow) {
      return;
    }
    const oldValue = callFlow[index].active ?? false;

    setVoiceSetup((current) => {
      return {
        ...current,
        [whichHours]: {
          ...current[whichHours],
          callFlow: callFlow.map((element, i) => {
            if (i === index) {
              return { ...element, active: !oldValue };
            }
            return element;
          }),
        },
      };
    });
  };

  const handleDeleteCallFlowItem = (index: number) => {
    callFlow.splice(index, 1);
    setVoiceSetup((current) => {
      return {
        ...current,
        [whichHours]: {
          ...current[whichHours],
          callFlow,
        },
      };
    });
  };

  const handleAddCallFlowItem = () => {
    setVoiceSetup((current) => {
      return {
        ...current,
        [whichHours]: {
          ...current[whichHours],
          callFlow: [
            ...callFlow,
            {
              active: true,
              companyExtension: null,
              extensionsGroupRef: null,
              number: 1,
            },
          ],
        },
      };
    });
  };

  return (
    <Box sx={styles.box1SX}>
      <Grid container>
        <Grid item xs={12}>
          <Typography>Call flow:</Typography>
        </Grid>
        {callFlow?.length ? (
          <Grid item xs={12}>
            {callFlow.map((flow, index) => {
              return (
                <Grid container key={index} columnSpacing={1} alignItems="end" sx={{ mb: 1 }}>
                  <Grid item xs={1.5}>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      options={VOICE_SETTINGS.GATHER_INPUT_OPTIONS}
                      getOptionLabel={(option) => option.toString()}
                      isOptionEqualToValue={(option, value) => option === value}
                      onChange={(event, list, reason, detail) =>
                        handleSelectNumber(reason, index, detail?.option as number)
                      }
                      //@ts-ignore
                      value={flow.number}
                      renderInput={(params) => (
                        <TextField {...params} label="Number" variant="standard" />
                      )}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Autocomplete
                      disablePortal
                      disabled={!!flow.companyExtension}
                      options={extensionsGroups}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.name === value?.name}
                      onChange={(event, list, reason, detail) =>
                        handleSelectExtensionsGroup(reason, index, detail?.option.id as string)
                      }
                      value={
                        {
                          name:
                            extensionsGroups.find(
                              (element) => element.id === flow.extensionsGroupRef,
                            )?.name || '',
                        } as any
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Extensions Group" variant="standard" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      disabled={!!flow.extensionsGroupRef}
                      disablePortal
                      getOptionLabel={(option) =>
                        option.companyExtension
                          ? `${option.companyExtension} | ${option.fullName}`
                          : ''
                      }
                      id="default-extension"
                      options={users}
                      value={
                        users.find((user) => user.companyExtension === flow.companyExtension) ??
                        (flow.companyExtension as any)
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?.companyExtension === value.companyExtension
                      }
                      onChange={(event, list, reason, detail) =>
                        handleSelectCompanyExtension(
                          reason,
                          index,
                          detail?.option?.companyExtension,
                        )
                      }
                      renderOption={(props, option) => {
                        return (
                          <Box
                            {...props}
                            component="li"
                            display={'flex'}
                            alignItems={'center'}
                            px={1}
                            key={option.id}
                          >
                            <CustomAvatar
                              url={option.avatar?.url}
                              showLiveStatus={false}
                              sx={{ height: 30, width: 30 }}
                            />

                            <Box>
                              <Typography ml={1}>{option.fullName}</Typography>

                              <Typography fontSize={12} color={'grey'} ml={1}>
                                {option.companyExtension}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            size="small"
                            id="connectToCompanyExtension"
                            label="Extension"
                            variant="standard"
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <Box sx={styles.box2SX}>
                      <Typography sx={styles.typography1SX}>Active:</Typography>
                      <Switch checked={flow.active} onChange={() => handleActiveSwitch(index)} />
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      sx={styles.iconButton1SX}
                      onClick={() => handleDeleteCallFlowItem(index)}
                    >
                      <DeleteOutlineIcon color="error"></DeleteOutlineIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ) : null}
      </Grid>

      <IconButton color="primary" onClick={() => handleAddCallFlowItem()}>
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export default CallFlow;

import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { Box, Button, Paper, Stack, TextField, Typography } from '@mui/material';

import {
  IIotDevicesGroupCreateRequest,
  IIotDevicesGroupResult,
  IIotDevicesGroupUpdateRequest,
} from '@/interfaces/iot-devices-groups';

import iotDevicesValidationSchemas from '@/helpers/validation-schemas/iot-devices/index';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import { useAppDispatch } from '@/store/hooks';

import SelectIotDevicesFromListDialog, {
  SelectIotDevicesFromListDialogIotDevice,
} from '@/components/Dialogs/Select-iot-devices-from-list-dialog';
import SelectUsersFromListDialog, {
  SelectUsersFromListDialogUser,
} from '@/components/Dialogs/Select-users-from-list-dialog';
import SelectUsersGroupsFromListDialog, {
  SelectUsersGroupsFromListDialogUser,
} from '@/components/Dialogs/Select-users-groups-from-list-dialog';

import apisV1 from '@/services/api/v1/index';

import ROUTES from '@/constants/routes';
import { FORMIK_STATUS } from '@/constants/formik';

const AddEditIotDevicesGroupsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const iotDevicesGroup = location.state?.data as IIotDevicesGroupResult | undefined;
  const isCreating = !iotDevicesGroup;

  const formik = useFormik({
    initialStatus: FORMIK_STATUS.IDLE,
    enableReinitialize: true,
    initialValues: isCreating
      ? {
          name: '',
          description: '',
          selectAllIotDevices: false,
          selectedIotDevices: [] as SelectIotDevicesFromListDialogIotDevice[],
          excludedIotDevices: [] as SelectIotDevicesFromListDialogIotDevice[],
          selectAllUsers: false,
          selectedUsers: [] as SelectUsersFromListDialogUser[],
          excludedUsers: [] as SelectUsersFromListDialogUser[],
          selectAllUsersGroups: false,
          selectedUsersGroups: [] as SelectUsersGroupsFromListDialogUser[],
          excludedUsersGroups: [] as SelectUsersGroupsFromListDialogUser[],
        }
      : {
          name: iotDevicesGroup.name as string,
          description: iotDevicesGroup.description ?? '',
          selectAllIotDevices: false,
          selectedIotDevices: iotDevicesGroup.iotDevices,
          excludedIotDevices: [] as SelectIotDevicesFromListDialogIotDevice[],
          selectAllUsers: false,
          selectedUsers: iotDevicesGroup.users,
          excludedUsers: [] as SelectUsersFromListDialogUser[],
          selectAllUsersGroups: false,
          selectedUsersGroups: iotDevicesGroup.usersGroups,
          excludedUsersGroups: [] as SelectUsersGroupsFromListDialogUser[],
        },
    validationSchema: isCreating
      ? iotDevicesValidationSchemas.devicesGroups.create
      : iotDevicesValidationSchemas.devicesGroups.update,
    onSubmit: async (values, formikHelpers) => {
      const body: IIotDevicesGroupCreateRequest | IIotDevicesGroupUpdateRequest = {
        name: values.name,
        description: values.description,
        selectAll: values.selectAllIotDevices,
        selectedIds: values.selectAllIotDevices
          ? []
          : (values.selectedIotDevices.map((el) => el.id) as string[]),
        excludedIds: values.excludedIotDevices.map((el) => el.id) as string[],
        selectAllUsers: values.selectAllUsers,
        selectedUsersIds: values.selectAllUsers
          ? []
          : (values.selectedUsers.map((el) => el.id) as string[]),
        excludedUsersIds: values.excludedUsers.map((el) => el.id) as string[],
        selectAllUsersGroups: values.selectAllUsersGroups,
        selectedUsersGroupsIds: values.selectAllUsersGroups
          ? []
          : (values.selectedUsersGroups.map((el) => el.id) as string[]),
        excludedUsersGroupsIds: values.excludedUsersGroups.map((el) => el.id) as string[],
      };

      const { success } = isCreating
        ? await apisV1.iotDevicesGroupsApis.create(body)
        : await apisV1.iotDevicesGroupsApis.update(iotDevicesGroup.id, body);

      if (success) {
        formikHelpers.resetForm();
        navigate(ROUTES.DASHBOARD_IOT_DEVICES_GROUPS);
        dispatch(
          snackbarReducer.showSuccess(
            isCreating ? 'Devices Group created.' : 'Devices Group updated.',
          ),
        );
      }
    },
  });

  const [isSelectIotDevicesDialogOpened, setIsSelectIotDevicesDialogOpened] = useState(false);
  const [isSelectUsersDialogOpened, setIsSelectUsersDialogOpened] = useState(false);
  const [isSelectUsersGroupsDialogOpened, setIsSelectUsersGroupsDialogOpened] = useState(false);

  const getSelectedIotDevicesLabel = (
    label: 'device' | 'user' | 'users group',
    selectAllField: 'selectAllIotDevices' | 'selectAllUsers' | 'selectAllUsersGroups',
    selectedField: 'selectedIotDevices' | 'selectedUsers' | 'selectedUsersGroups',
    excludedField: 'excludedIotDevices' | 'excludedUsers' | 'excludedUsersGroups',
  ) => {
    const selectAll = formik.values[selectAllField];
    const selected = formik.values[selectedField];
    const excluded = formik.values[excludedField];

    if (selectAll) {
      const hasMoreThanOne = excluded.length > 1;
      return `all ${label}s selected ${
        excluded.length ? `except ${excluded.length} ${label}${hasMoreThanOne ? 's' : ''}` : ''
      }`;
    } else {
      return selected.length ? selected.length : `No ${label}s selected`;
    }
  };

  const isLoading = formik.status === FORMIK_STATUS.LOADING;

  return (
    <Box flex={1} display="flex" p={2} justifyContent="center" overflow="auto">
      <SelectIotDevicesFromListDialog
        initialIotDevices={formik.values.selectedIotDevices}
        isOpened={isSelectIotDevicesDialogOpened}
        setIsOpened={setIsSelectIotDevicesDialogOpened}
        handleSubmit={(response) => {
          if (response) {
            formik.setValues((prevState) => ({
              ...prevState,
              selectAllIotDevices: response.isAllSelected,
              excludedIotDevices: response.excluded,
              selectedIotDevices: response.selected,
            }));
          } else {
            formik.setValues((prevState) => ({
              ...prevState,
              selectAllIotDevices: false,
              excludedIotDevices: [],
              selectedIotDevices: [],
            }));
          }
        }}
      />

      <SelectUsersFromListDialog
        initialUsers={formik.values.selectedUsers}
        isOpened={isSelectUsersDialogOpened}
        setIsOpened={setIsSelectUsersDialogOpened}
        handleSubmit={(response) => {
          if (response) {
            formik.setValues((prevState) => ({
              ...prevState,
              selectAllUsers: response.isAllSelected,
              excludedUsers: response.excluded,
              selectedUsers: response.selected,
            }));
          } else {
            formik.setValues((prevState) => ({
              ...prevState,
              selectAllUsers: false,
              excludedUsers: [],
              selectedUsers: [],
            }));
          }
        }}
      />

      <SelectUsersGroupsFromListDialog
        initialUsersGroups={formik.values.selectedUsersGroups}
        isOpened={isSelectUsersGroupsDialogOpened}
        setIsOpened={setIsSelectUsersGroupsDialogOpened}
        handleSubmit={(response) => {
          if (response) {
            formik.setValues((prevState) => ({
              ...prevState,
              selectAllUsersGroups: response.isAllSelected,
              excludedUsersGroups: response.excluded,
              selectedUsersGroups: response.selected,
            }));
          } else {
            formik.setValues((prevState) => ({
              ...prevState,
              selectAllUsersGroups: false,
              excludedUsersGroups: [],
              selectedUsersGroups: [],
            }));
          }
        }}
      />

      <Box
        component={Paper}
        display="flex"
        flexDirection="column"
        flex={1}
        gap={1}
        p={2}
        maxWidth="sm"
      >
        <Box flexGrow={1} m={2}>
          <Stack spacing={2}>
            <Typography variant="h5" color="primary.main" textAlign="center" fontWeight="bold">
              {isCreating ? 'Add' : 'Edit'} devices group
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  required
                  name="name"
                  label="Name"
                  placeholder="Sales"
                  disabled={isLoading}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <TextField
                  fullWidth
                  name="description"
                  label="Description"
                  placeholder="Some descriptions here"
                  disabled={isLoading}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && !!formik.errors.description}
                  helperText={formik.touched.description && formik.errors.description}
                />

                <Stack spacing={1}>
                  <Stack
                    spacing={1}
                    direction="row"
                    gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle2" component="p">
                      Devices:{' '}
                      {getSelectedIotDevicesLabel(
                        'device',
                        'selectAllIotDevices',
                        'selectedIotDevices',
                        'excludedIotDevices',
                      )}
                    </Typography>

                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setIsSelectIotDevicesDialogOpened(true)}
                    >
                      Modify selection
                    </Button>
                  </Stack>
                </Stack>

                <Stack spacing={1}>
                  <Stack
                    spacing={1}
                    direction="row"
                    gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle2" component="p">
                      Users Groups:{' '}
                      {getSelectedIotDevicesLabel(
                        'users group',
                        'selectAllUsersGroups',
                        'selectedUsersGroups',
                        'excludedUsersGroups',
                      )}
                    </Typography>

                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setIsSelectUsersGroupsDialogOpened(true)}
                    >
                      Modify selection
                    </Button>
                  </Stack>
                </Stack>

                <Stack spacing={1}>
                  <Stack
                    spacing={1}
                    direction="row"
                    gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle2" component="p">
                      Users:{' '}
                      {getSelectedIotDevicesLabel(
                        'user',
                        'selectAllUsers',
                        'selectedUsers',
                        'excludedUsers',
                      )}
                    </Typography>

                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setIsSelectUsersDialogOpened(true)}
                    >
                      Modify selection
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </Box>

        <Box display="flex" mt="auto" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            size="large"
            disabled={isLoading || !formik.isValid || formik.isSubmitting}
            onClick={formik.submitForm}
          >
            {isCreating ? 'Create' : 'Update'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditIotDevicesGroupsPage;

import * as yup from 'yup';

import { validate } from './phone-number';
import { getMin, getMax, getInvalid } from '@/helpers/form-fields-error-messages';

declare module 'yup' {
  interface StringSchema<
    TType extends yup.Maybe<string> = string | undefined,
    TContext = yup.AnyObject,
    TDefault = undefined,
    TFlags extends yup.Flags = '',
  > extends yup.Schema<TType, TContext, TDefault, TFlags> {
    phone(config?: {
      errorMessage?: string;
      skipIfEmpty?: boolean;
    }): yup.StringSchema<TType, TContext, TDefault, TFlags>;
  }

  interface NumberSchema<
    TType extends yup.Maybe<number> = number | undefined,
    TContext = yup.AnyObject,
    TDefault = undefined,
    TFlags extends yup.Flags = '',
  > extends yup.Schema<TType, TContext, TDefault, TFlags> {
    extension(): yup.NumberSchema<TType, TContext, TDefault, TFlags>;
  }

  interface ObjectSchema<
    TIn extends yup.Maybe<yup.AnyObject>,
    TContext = yup.AnyObject,
    TDefault = any,
    TFlags extends yup.Flags = '',
  > extends yup.Schema<Partial<TIn>, TContext, TDefault, TFlags> {
    phoneObject(): yup.ObjectSchema<TIn, TContext, TDefault, TFlags>;
  }
}

yup.addMethod(yup.string, 'phone', (config) => {
  return yup
    .string()
    .test('phone', config?.errorMessage ?? getInvalid('Phone number'), (value) => {
      if (config?.skipIfEmpty && !value) {
        return true;
      }

      const { isValid } = validate(value + '');

      return isValid;
    })
    .typeError(getInvalid('Phone number'));
});

yup.addMethod(yup.number, 'extension', () =>
  yup
    .number()
    .min(100, getMin('Extension', '3 digits'))
    .max(9999, getMax('Extension', '4 digits'))
    .typeError(getInvalid('Extension')),
);

yup.addMethod(yup.object, 'phoneObject', () =>
  yup
    .object()
    .shape({
      countryShortName: yup.string(),
      countryCode: yup.string(),
      rawPhoneNumber: yup.string().phone(),
    })
    .typeError(getInvalid('Phone number')),
);

export default yup;

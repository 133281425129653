import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAdvancedSearchMsgDataPayload } from '@/interfaces/message';
import { IGetOneChatByRelativeInfo } from '@/interfaces/chat';

import handleErrors from '@/services/handle-errors';

import { IThunkExtra } from '@/store/store';
import { IIoTDeviceSendMessageRequest } from '@/interfaces/iot-device';

export const fetchAdvancedSearch = createAsyncThunk(
  'chats/fetchAdvancedSearch',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.messagesApis.advancedSearch(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchAdvancedSearchMsgData = createAsyncThunk(
  'chats/fetchAdvancedSearchMsgData',
  async (data: IAdvancedSearchMsgDataPayload, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.messagesApis.advancedSearchMsgData(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchChatByReceiverCompanyExt = createAsyncThunk(
  'chats/fetchChatByReceiverCompanyExt',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.chatsApis.findByReceiverCompanyExt(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchUserByCompanyExtension = createAsyncThunk(
  'chats/fetchUserByCompanyExtension',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.getByCompanyExtension(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchGetOneByRelativeInfo = createAsyncThunk(
  'chats/fetchGetOneByRelativeInfo',
  async (data: IGetOneChatByRelativeInfo, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.chatsApis.getOneByRelativeInfo(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchGetChatById = createAsyncThunk(
  'chats/fetchGetChatById',
  async (data: Record<'chatId', string>, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.chatsApis.getById(data.chatId);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchGetIotDevices = createAsyncThunk(
  'chats/fetchGetIotDevices',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.iotDevicesApis.getByParams({ skip: 0, limit: 1000 });

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchGetChatByIotDeviceId = createAsyncThunk(
  'chats/fetchGetChatByIotDeviceId',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.chatsApis.getByIotDeviceId(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSendMessageToIotDevice = createAsyncThunk(
  'chats/fetchSendMessageToIotDevice',
  async (data: IIoTDeviceSendMessageRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.iotDevicesApis.sendMessage(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

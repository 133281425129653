import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';

import { Stack, TextField } from '@mui/material';

import userValidationSchemas from '@/helpers/validation-schemas/users/index';

import { IIpResult } from '@/interfaces/ip';

import { TemplateDialog } from '@/components/Dialogs/Template-dialog';
import Transition from '@/components/Dialogs/Transition';
import TwoFADialog from '@/components/Dialogs/Two-fa';

import * as profileSaThunk from './thunk';
import { useAppDispatch } from '@/store/hooks';

import { FORMIK_STATUS } from '@/constants/formik';
import TWOFA from '@/constants/2fa';

type IpRecordAddEdit = {
  ip: string;
  description: string | null;
  deleteAfterMin: number | null;
};

const AddEditIpDialog = (props: {
  isOpened: boolean;
  handleClose: Dispatch<SetStateAction<boolean>>;
  ipRecord?: IIpResult;
}) => {
  const { isOpened, handleClose, ipRecord } = props;
  const dispatch = useAppDispatch();
  const formikRef = useRef<FormikProps<IpRecordAddEdit> | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTwoFADialog, setSetShowTwoFADialog] = useState<boolean>(false);

  const handleShow2FADialog = async (): Promise<void> => {
    if (!formikRef.current?.values) {
      return;
    }

    const errors = await formikRef.current.validateForm(formikRef.current.values);
    if (errors) {
      for (let key in errors) {
        formikRef.current.setTouched({ [key]: true });
      }
      formikRef.current.setErrors(errors);
    }
    if (!formikRef.current.isValid) {
      return;
    }

    setSetShowTwoFADialog(true);
  };

  const handleAddEdit = async (twoFACode: string): Promise<void> => {
    if (!formikRef.current?.values || isLoading) {
      return;
    }
    setIsLoading(true);

    let result;
    const values = formikRef.current.values;
    if (ipRecord) {
      result = await dispatch(
        profileSaThunk.updateSaAllowedIp({
          id: ipRecord.id,
          updates: {
            ip: values.ip,
            description: values.description,
            twoFA: twoFACode,
          },
        }),
      );
    } else {
      result = await dispatch(
        profileSaThunk.createSaAllowedIp({
          ip: values.ip,
          description: values.description,
          twoFA: twoFACode,
          deleteAfterMin: values.deleteAfterMin,
        }),
      );
    }
    setSetShowTwoFADialog(false);
    if (result.meta.requestStatus === 'fulfilled') {
      handleClose(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpened) {
      formikRef.current?.isInitialValid;
    }
  }, [isOpened]);

  return (
    <>
      <TwoFADialog
        show={showTwoFADialog}
        handleClose={() => setSetShowTwoFADialog(false)}
        type={TWOFA.TYPES.SA_ALLOWED_IP}
        handler={handleAddEdit}
      />
      <TemplateDialog
        isLoading={isLoading}
        isOpened={isOpened}
        handleClose={handleClose}
        title={ipRecord ? 'Edit allowed IP' : 'Add allowed IP'}
        showCancelButton={false}
        dialogProps={{
          maxWidth: 'sm',
          onClose: () => handleClose(false),
          TransitionComponent: Transition,
        }}
        submitButtonProps={{
          onClick: () => handleShow2FADialog(),
          disabled: isLoading,
        }}
        getChildren={() => (
          <Formik
            innerRef={(ref) => (formikRef.current = ref)}
            enableReinitialize
            initialStatus={FORMIK_STATUS.IDLE}
            initialValues={
              {
                ip: ipRecord?.ip ?? '',
                description: ipRecord?.description ?? '',
                deleteAfterMin: null,
              } as IpRecordAddEdit
            }
            validationSchema={
              ipRecord
                ? userValidationSchemas.updateSaAllowedIp
                : userValidationSchemas.createSaAllowedIp
            }
            onSubmit={() => {}}
          >
            {({ values, handleChange, handleBlur, touched, errors }) => {
              return (
                <Stack spacing={1}>
                  <TextField
                    required
                    id="ip"
                    key="ip"
                    label="IP"
                    variant="standard"
                    type="text"
                    size="small"
                    value={values.ip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ip && Boolean(errors.ip)}
                    helperText={touched.ip && errors.ip}
                  />
                  <TextField
                    id="description"
                    key="description"
                    label="Description"
                    variant="standard"
                    type="text"
                    size="small"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                  {!ipRecord && (
                    <TextField
                      id="deleteAfterMin"
                      key="deleteAfterMin"
                      label="Auto-delete IP after minutes"
                      variant="standard"
                      type="number"
                      size="small"
                      value={values.deleteAfterMin ?? ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.deleteAfterMin && Boolean(errors.deleteAfterMin)}
                      helperText={touched.deleteAfterMin && errors.deleteAfterMin}
                    />
                  )}
                </Stack>
              );
            }}
          </Formik>
        )}
      />
    </>
  );
};

export default AddEditIpDialog;

import { useState, useEffect, FunctionComponent } from 'react';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { IUserResult } from '@/interfaces/users/users';

import { TemplateDialog } from '@/components/Dialogs/Template-dialog';
import Transition from '@/components/Dialogs/Transition';

import * as usersThunk from '../thunk';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

type CountryCode = IUserResult['allowedCountries'][0];

interface IManageCountryCodesDialogProps {
  user: IUserResult;
  isOpened: boolean;
  handleClose: () => void;
}

function not(a: CountryCode[], b: CountryCode[]) {
  return a.filter((value) => !b.find((element) => element.countryCodeRef === value.countryCodeRef));
}

function intersection(a: CountryCode[], b: CountryCode[]) {
  return a.filter((value) => b.find((element) => element.countryCodeRef === value.countryCodeRef));
}

function union(a: CountryCode[], b: CountryCode[]) {
  return [...a, ...not(b, a)];
}

const ManageCountryCodesDialog: FunctionComponent<IManageCountryCodesDialogProps> = (props) => {
  const { user, isOpened, handleClose } = props;
  const dispatch = useAppDispatch();

  const { tenant } = useAppSelector((state) => state.tenant);

  const [checked, setChecked] = useState<CountryCode[]>([]);
  const [allCountryCodes, setAllCountryCodes] = useState<CountryCode[]>(
    tenant?.allowedCountries ?? [],
  );
  const [allowedCountryCodes, setAllowedCountryCodes] = useState<CountryCode[]>([]);
  const [allCountryCodesSearch, setAllCountryCodesSearch] = useState<string>('');
  const [allowedCountryCodesSearch, setAllowedCountryCodesSearch] = useState<string>('');

  const leftChecked = intersection(checked, allCountryCodes);
  const rightChecked = intersection(checked, allowedCountryCodes);

  const prepareLists = async (): Promise<void> => {
    const { payload } = await dispatch(usersThunk.fetchGetById(user.id));
    if (payload) {
      setAllowedCountryCodes(payload.allowedCountries);
      setAllCountryCodes(not(tenant?.allowedCountries ?? [], payload.allowedCountries));
    }
  };

  const handleToggle = (value: CountryCode) => () => {
    if (value.isDefault) {
      return;
    }

    const currentIndex = checked.findIndex(
      (element) => element.countryCodeRef === value.countryCodeRef,
    );
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: CountryCode[]) => intersection(checked, items).length;

  const handleToggleAll = (items: CountryCode[]) => () => {
    const nonDefItems = items.filter((element) => !element.isDefault);
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, nonDefItems));
    } else {
      setChecked(union(checked, nonDefItems));
    }
  };

  const handleSearch = (
    value: string,
    which: 'All Country Codes' | 'Allowed Country Codes',
  ): void => {
    if (which === 'All Country Codes') {
      setAllCountryCodesSearch(value);
    } else {
      setAllowedCountryCodesSearch(value);
    }
  };

  const handleCheckedRight = () => {
    setAllowedCountryCodes(allowedCountryCodes.concat(leftChecked));
    setAllCountryCodes(not(allCountryCodes, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setAllCountryCodes(allCountryCodes.concat(rightChecked));
    setAllowedCountryCodes(not(allowedCountryCodes, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleSetAllowedCountries = async (): Promise<void> => {
    const { meta } = await dispatch(
      usersThunk.fetchSetAllowedCountryCodes({
        userId: user.id,
        data: {
          ids: allowedCountryCodes
            .filter((element) => !element.isDefault)
            .map((element) => element.countryCodeRef),
        },
      }),
    );

    if (meta.requestStatus === 'fulfilled') {
      handleClose();
      dispatch(snackbarReducer.showSuccess('User Allowed Country Codes were updated.'));
    }
  };

  const sortCb = (a: CountryCode, b: CountryCode) => {
    return a.isDefault || b.isDefault
      ? 0
      : a.country.toLowerCase().localeCompare(b.country.toLowerCase());
  };

  const customList = (
    title: 'All Country Codes' | 'Allowed Country Codes',
    items: IUserResult['allowedCountries'],
  ) => (
    <Card>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Box>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          type="search"
          placeholder="Search country code..."
          value={title === 'All Country Codes' ? allCountryCodesSearch : allowedCountryCodesSearch}
          onChange={(e) => handleSearch(e.target.value, title)}
        />
      </Box>
      <Divider />
      <List
        sx={{
          width: { md: 370 },
          height: 400,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {[...items]
          .sort(sortCb)
          .filter((element) => {
            const search =
              title === 'All Country Codes' ? allCountryCodesSearch : allowedCountryCodesSearch;
            if (search) {
              return (
                element.country.toLowerCase().includes(search.toLowerCase()) ||
                element.iso.toLowerCase().includes(search.toLowerCase()) ||
                element.extension.toLowerCase().includes(search.toLowerCase())
              );
            }

            return true;
          })
          .map((value: IUserResult['allowedCountries'][0]) => {
            const className = `fi fi-${value.iso.toLowerCase()}`;

            return (
              <ListItemButton
                key={value.countryCodeRef}
                role="listitem"
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.some(
                      (element) => element.countryCodeRef === value.countryCodeRef,
                    )}
                    tabIndex={-1}
                    disableRipple
                    disabled={value.isDefault}
                  />
                </ListItemIcon>
                <Box flexDirection={'row'} display={'flex'}>
                  <Box className={className} justifyContent={'center'} />
                  <ListItemText
                    primary={`${value.country} ${value.isDefault ? '(default)' : ''}`}
                    secondary={value.extension}
                    sx={{ ml: 1 }}
                  />
                </Box>
              </ListItemButton>
            );
          })}
      </List>
    </Card>
  );

  useEffect(() => {
    if (!isOpened) {
      handleSearch('', 'All Country Codes');
      handleSearch('', 'Allowed Country Codes');
    } else {
      prepareLists();
    }
  }, [user.id, isOpened]);

  return (
    <TemplateDialog
      isOpened={isOpened}
      handleClose={handleClose}
      showCancelButton={false}
      submitButtonProps={{ onClick: handleSetAllowedCountries }}
      dialogProps={{ maxWidth: 'lg', TransitionComponent: Transition }}
      closeButtonProps={{ color: 'primary' }}
      title="Manage User Allowed Country Codes"
      getChildren={() => (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>{customList('All Country Codes', allCountryCodes)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
                sx={{
                  my: 0.5,
                  backgroundColor: leftChecked.length === 0 ? 'disabled' : 'secondary.main',
                  fontWeight: 'bold',
                }}
              >
                <KeyboardArrowRightIcon />
              </Button>

              <Button
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
                sx={{
                  my: 0.5,
                  backgroundColor: rightChecked.length === 0 ? 'disabled' : 'secondary.main',
                }}
              >
                <KeyboardArrowLeftIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList('Allowed Country Codes', allowedCountryCodes)}</Grid>
        </Grid>
      )}
    />
  );
};

export default ManageCountryCodesDialog;

import { FunctionComponent } from 'react';

import { Chip, ChipProps } from '@mui/material';

import CHAT from '@/constants/chat';

interface IChatPlatformChipProps {
  isInternal?: boolean;
  platform: CHAT.PLATFORM;
  chipProps?: ChipProps;
}

const ChatPlatformChip: FunctionComponent<IChatPlatformChipProps> = (props): JSX.Element => {
  const getChipColor = () => {
    if (props?.isInternal) {
      return 'info';
    }

    if (props.platform === CHAT.PLATFORM.SMS) {
      return 'primary';
    }

    if (props.platform === CHAT.PLATFORM.WHATSAPP) {
      return 'success';
    }

    return 'default';
  };

  const label = props.isInternal ? 'internal' : props.platform;

  return <Chip label={label} color={getChipColor()} {...(props.chipProps || {})} size="small" />;
};

export default ChatPlatformChip;

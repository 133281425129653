import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';
import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';

import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';

import {
  IUseMultiCheckboxSelectState,
  useMultiCheckboxSelect,
} from '@/hooks/use-multi-checkbox-select';

import { IUserResult } from '@/interfaces/users/users';
import { IGetUsersQueryParams } from '@/interfaces/users/users';
import { IPartialResult } from '@/interfaces/querying-data';

import DataTemplate from '@/components/Data-template/Data-template';

import apisV1 from '@/services/api/v1/index';

export type SelectUsersFromListDialogUser = Pick<
  IUserResult,
  'id' | 'email' | 'firstName' | 'lastName' | 'fullName' | 'companyExtension' | 'directExtension'
> & { avatarUrl?: string | null };

interface ISelectUsersFromListDialogProps {
  initialUsers: SelectUsersFromListDialogUser[];
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (
    response: IUseMultiCheckboxSelectState<SelectUsersFromListDialogUser> | null,
  ) => void;
}

const SelectUsersFromListDialog: FunctionComponent<ISelectUsersFromListDialogProps> = ({
  initialUsers,
  isOpened,
  setIsOpened,
  handleSubmit,
}): JSX.Element => {
  const [usersQueryParams, setUsersQueryParams] = useState<IGetUsersQueryParams>({
    search: '',
    skip: 0,
    limit: 50,
    excludeMe: false,
  });

  const [usersResult, setUsersResult] = useState<IPartialResult<IUserResult>>({
    maxCount: 0,
    records: [],
  });

  const [usersSelection, selectedUsersActions] =
    useMultiCheckboxSelect<SelectUsersFromListDialogUser>({
      selectAllBehavior: 'select-all-with-exclude',
      initialOptions: initialUsers,
      getItemId: (el) => el.id as string,
      maxCount: usersResult.maxCount,
    });

  const [isLoading, setIsLoading] = useState(false);

  const loadUsers = async () => {
    setIsLoading(true);

    const { success, data } = await apisV1.usersApis.getByParams(usersQueryParams);

    if (success) {
      setUsersResult(data);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoading) {
      loadUsers();
    }
  }, [usersQueryParams.search, usersQueryParams.skip, usersQueryParams.limit]);

  const handleClose = (
    response: IUseMultiCheckboxSelectState<SelectUsersFromListDialogUser> | null,
  ) => {
    setIsOpened(false);
    handleSubmit(response);
  };

  return (
    <Dialog keepMounted fullWidth maxWidth="sm" open={isOpened} onClose={() => handleClose(null)}>
      <DialogContent sx={{ pb: 0 }}>
        <Stack spacing={2}>
          <>
            <TextField
              fullWidth
              type="search"
              placeholder="Search"
              variant="outlined"
              size="small"
              value={usersQueryParams.search}
              onChange={(e) =>
                setUsersQueryParams((prevState) => ({ ...prevState, search: e.target.value }))
              }
              onBlur={(e) =>
                setUsersQueryParams((prevState) => ({ ...prevState, search: e.target.value }))
              }
            />

            <DataTemplate
              isLoading={isLoading}
              pagination={{
                maxCount: usersResult.maxCount,
                paginationData: {
                  skip: usersQueryParams.skip,
                  limit: usersQueryParams.limit,
                },
                onPageChange: (paginationData) =>
                  setUsersQueryParams((prevState) => ({
                    ...prevState,
                    ...paginationData,
                  })),
                onRowsPerPageChange: (paginationData) =>
                  setUsersQueryParams((prevState) => ({
                    ...prevState,
                    ...paginationData,
                  })),
              }}
            >
              <List
                dense
                disablePadding
                sx={{
                  position: 'relative',
                  height: '50vh',
                  overflow: 'auto',
                }}
              >
                <ListItem
                  disablePadding
                  disableGutters
                  dense
                  sx={{
                    position: 'sticky',
                    top: 0,
                    bgcolor: 'common.white',
                    zIndex: 2,
                    borderColor: 'secondary.light',
                  }}
                  secondaryAction={
                    <Checkbox
                      tabIndex={-1}
                      disableRipple
                      indeterminate={selectedUsersActions.isAllOptionHasIndeterminateState(
                        usersResult.records,
                      )}
                      checked={selectedUsersActions.isAllOptionSelected(usersResult.records)}
                      inputProps={{ 'aria-labelledby': 'all-users-selected-btn' }}
                      onChange={(event, checked) => selectedUsersActions.handleSelectAll(checked)}
                    />
                  }
                >
                  <ListItemButton
                    disableRipple
                    role="checkbox"
                    onClick={() => {
                      const currentlyChecked = selectedUsersActions.isAllOptionSelected();
                      selectedUsersActions.handleSelectAll(!currentlyChecked);
                    }}
                  >
                    <ListItemText id="all-users-selected-btn" primary="Select all" />
                  </ListItemButton>
                </ListItem>

                {usersResult.records.map((el) => {
                  const labelId = `users-checkbox-list-label-${el.id}`;

                  return (
                    <ListItem
                      key={el.id}
                      disablePadding
                      disableGutters
                      dense
                      secondaryAction={
                        <Checkbox
                          edge="start"
                          checked={selectedUsersActions.isSelected(el)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          onChange={() => selectedUsersActions.handleSelect(el)}
                        />
                      }
                    >
                      <ListItemButton
                        role="checkbox"
                        onClick={() => selectedUsersActions.handleSelect(el)}
                      >
                        <ListItemAvatar>
                          <Avatar src={el.avatar?.url || DefaultAvatar} />
                        </ListItemAvatar>

                        <ListItemText
                          id={labelId}
                          primary={el.fullName}
                          secondary={el.companyExtension}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </DataTemplate>
          </>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          color="error"
          size="medium"
          disabled={isLoading}
          onClick={() => setIsOpened(false)}
        >
          Cancel
        </Button>

        <Button
          disableElevation
          color="primary"
          variant="contained"
          size="medium"
          disabled={isLoading}
          onClick={() => {
            handleClose(usersSelection);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectUsersFromListDialog;

//@ts-ignore
import incomingCallRingtone from '@/assets/audio/incoming-call-ringtone.mp3';

import { useEffect, useRef, useState } from 'react';
import { Call } from '@twilio/voice-sdk';

import { IUserMeResult } from '@/interfaces/users/users';
import { IConnection } from '@/interfaces/voice/call';

import { delay } from '@/helpers/delay';

import * as voiceThunk from '@/store/thunk/dashboard/voice';

import * as twilioReducer from '@/store/reducers/twilio';
import * as voiceReducer from '@/store/reducers/dashboard/voice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { twilioDevice } from '@/services/twilio/device';
import { handleInboundCallEvents } from '@/services/twilio/inbound-call';
import { askMicrophonePermission } from '@/services/devices/microphone';
import * as socketService from '@/services/socketio/socketio';

import useBrowserNotifications from '@/hooks/use-browser-notifications';

import InternetConnectionDialog from '@/components/Dialogs/Internet-connection';

import { FEATURES } from '@/constants/features';
import BN from '@/constants/browser-notifications';
import SOCKETIO from '@/constants/socketio';
import { LOCAL_STORAGE_KEYS } from '@/constants/local-storage';
import CALL from '@/constants/voice/voice';

function TwilioDevice() {
  const dispatch = useAppDispatch();
  const { showNotification } = useBrowserNotifications();
  const activeProviderRef = useRef<null | { name: string }>(null);

  const { createDevice, device } = useAppSelector((state) => state.twilio);
  const { connections } = useAppSelector((state) => state.voice);
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const { activeProvider, tenant } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.user);
  const connectionsRef = useRef(connections);
  const userRef = useRef<IUserMeResult | null>(null);

  const [showInternetDialog, setShowInternetDialog] = useState<boolean>(false);

  const handleTwilioRegistered = (newDevice: any) => {
    dispatch(twilioReducer.setDevice(newDevice));
    dispatch(twilioReducer.onReady());
  };

  const handleNotification = (
    firstName: string | undefined,
    lastName: string | undefined,
    phoneNumber: string,
  ) => {
    const body = `${firstName ?? ''} ${lastName ?? ''} ${phoneNumber}`;
    showNotification(BN.INCOMING_CALL, body);
  };

  const handleTwilioIncomingCall = async (connection: IConnection['conn']): Promise<void> => {
    const { customParameters, parameters } = connection;
    const callUuid = customParameters.get('uuid');
    const isCompanyExtensionCall = !!customParameters.get('isCompanyExtensionCall');
    const connectionType = customParameters.get('connectionType');
    const isConference = customParameters.get('isConference') === 'true';
    const displayPhoneNumber = customParameters.get('displayPhoneNumber');
    const isMultipleParticipants = customParameters.get('isMultipleParticipants') === 'true';

    const caller = {
      uuid: callUuid,
      firstName: customParameters.get('callerFirstName'),
      lastName: customParameters.get('callerLastName'),
      phoneNumber: parameters.From,
    };
    if (displayPhoneNumber) {
      caller.phoneNumber = `+${displayPhoneNumber.trim()}`;
    }
    if (isCompanyExtensionCall) {
      caller.phoneNumber = customParameters.get('callerCompanyExtension');
    }
    // For better user experience
    await delay(1500);

    handleNotification(caller.firstName, caller.lastName, caller.phoneNumber);

    dispatch(
      voiceReducer.setConnection({
        caller,
        isConference,
        isCompanyExtensionCall,
        isMultipleParticipants,
        connectionType,
        uuid: callUuid,
        direction: 'inbound',
        conn: connection,
        callee: null,
        showHoldPulsation: false,
        isForwarding: false,
        answered: false,
        onHold: false,
        activeNow: false,
        internalHoldByOther: false,
        counter: null,
        counterFunction: null,
        muted: false,
        selected: false,
        isHoldDisabled: false,
        transcription: [],
        transcriptionStatus: CALL.TRANSCRIPTION_STATUS.ONLINE,
        participants: [],
      }),
    );
    handleInboundCallEvents({ connection, dispatch });
    socketService.socket?.emit(SOCKETIO.EVENTS.JOIN_CALL, JSON.stringify({ uuid: callUuid }));
  };

  const handleTwilioError = async (error: { code: number }) => {
    console.debug(error);
    if (error.code === 20104) {
      await createTwilioDevice();
    }
    if (error.code === 31005) {
      setShowInternetDialog(true);
    }
  };

  const handleTwilioOffline = async () => {
    await createTwilioDevice();
  };

  const getTwilioAccessToken = async () => {
    const result = await dispatch(voiceThunk.fetchTwilioAccessToken());
    return result.payload?.token;
  };

  const createTwilioDevice = async () => {
    try {
      const twilioAccessToken = await getTwilioAccessToken();
      if (!twilioAccessToken) {
        return;
      }

      dispatch(twilioReducer.setAccessToken(twilioAccessToken));

      if (device?.state === 'registered') {
        device.unregister();
      }

      const newDevice = await twilioDevice({
        token: twilioAccessToken,
        options: {
          codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
          allowIncomingWhileBusy: true,
          sounds: { incoming: incomingCallRingtone },
        },
      });

      newDevice
        .on('registered', () => handleTwilioRegistered(newDevice))
        .on('incoming', (connection: IConnection['conn']) => handleTwilioIncomingCall(connection))
        .on('error', (error: any) => handleTwilioError(error))
        .on('offline', () => handleTwilioOffline());

      await newDevice.register();

      newDevice.audio?.outgoing(false);
      newDevice.audio?.disconnect(false);

      dispatch(twilioReducer.setCreateDevice({ initCreate: false }));
    } catch (error) {
      console.error('Create Twilio device error: ', error);
    }
  };

  useEffect(() => {
    const hasAccess = tenant?.features?.some((element) => element.name === FEATURES.VOICE);

    if (hasAccess) {
      if (
        isLoggedIn &&
        localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN) &&
        activeProvider &&
        !device
      ) {
        createTwilioDevice();
      } else {
        dispatch(twilioReducer.onReady());
      }

      askMicrophonePermission();
    }
  }, [isLoggedIn, createDevice, activeProvider, device]);

  // Tracking init force device creation
  useEffect(() => {
    if (createDevice.initCreate && createDevice.force) {
      createTwilioDevice();
    }
  }, [createDevice]);

  useEffect(() => {
    connectionsRef.current = connections;
  }, [connections]);

  useEffect(() => {
    activeProviderRef.current = activeProvider;
  }, [activeProvider]);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  return <InternetConnectionDialog show={showInternetDialog} setShow={setShowInternetDialog} />;
}
export default TwilioDevice;

import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  IconProps,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  Today as TodayIcon,
  ExpandMore as ExpandMoreIcon,
  Phone as PhoneIcon,
  AvTimer as AvTimerIcon,
  MoreVert as MoreVertIcon,
  MessageOutlined as MessageOutlinedIcon,
  WhatsApp as WhatsAppIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  HdrAuto as HdrAutoIcon,
  PlayCircle as PlayCircleIcon,
  CheckCircle as CheckCircleIcon,
  GroupWork as GroupWorkIcon,
  SwapHorizontalCircle as SwapHorizontalCircleIcon,
  Voicemail as VoicemailIcon,
} from '@mui/icons-material';

import { ICallsHistoryResult } from '@/interfaces/voice/call';
import { IUserMeResult } from '@/interfaces/users/users';
import { IAddNewChatDialogResponse } from '@/interfaces/chat';

import * as dateHelper from '@/helpers/date/date';

import * as callService from '@/services/voice/call';
import * as socketService from '@/services/socketio/socketio';

import * as voiceThunk from '../thunk';
import * as chatsThunk from '@/pages/dashboard/chat/thunk';
import * as contactThunk from '@/pages/dashboard/contacts/thunk';
import * as usersThunk from '@/store/thunk/dashboard/users';

import * as menuReducer from '@/store/reducers/dashboard/menu';
import * as voiceReducer from '@/store/reducers/dashboard/voice';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as usersReducer from '@/store/reducers/user';

import useThemeBreakpoints from '@/hooks/use-theme-breakpoints';
import useAccess from '@/hooks/use-access';

import CallDetailsDialog from './Call-details-dialog';
import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';

import { defStyles } from '@/theme';

import DASHBOARD from '@/constants/dashboard';
import CALL from '@/constants/voice/voice';
import ROUTES from '@/constants/routes';
import { PHONE_NUMBER_TYPE } from '@/constants/phone-numbers';
import CHAT from '@/constants/chat';
import { ADDONS } from '@/constants/addons';
import SOCKETIO from '@/constants/socketio';
import { ROLES } from '@/constants/roles';

const callInfo: {
  key: keyof ICallsHistoryResult;
  title: (el: any) => string;
  Icon: (isPositiveColor: boolean) => JSX.Element;
}[] = [
  {
    key: 'isAnswered',
    title: () => 'This call was answered',
    Icon: (isPositiveColor: boolean) => (
      <CheckCircleIcon color={isPositiveColor ? 'info' : 'disabled'} sx={{ fontSize: 15 }} />
    ),
  },
  {
    key: 'hasRecording',
    title: () => 'This call has recording',
    Icon: (isPositiveColor: boolean) => (
      <PlayCircleIcon color={isPositiveColor ? 'info' : 'disabled'} sx={{ fontSize: 15 }} />
    ),
  },
  {
    key: 'hasTranscription',
    title: () => 'This call has transcription',
    Icon: (isPositiveColor: boolean) => (
      <HdrAutoIcon color={isPositiveColor ? 'info' : 'disabled'} sx={{ fontSize: 15 }} />
    ),
  },
  {
    key: 'isMultipleParticipants',
    title: () => 'This call had multiple participants',
    Icon: (isPositiveColor: boolean) => (
      <SupervisedUserCircleIcon
        color={isPositiveColor ? 'info' : 'disabled'}
        sx={{ fontSize: 15 }}
      />
    ),
  },
  {
    key: 'extensionsGroupRef',
    title: (data: ICallsHistoryResult) => `Extension Group Call. Group name: ${data.extGroupName}`,
    Icon: (isPositiveColor: boolean) => (
      <GroupWorkIcon color={isPositiveColor ? 'info' : 'disabled'} sx={{ fontSize: 15 }} />
    ),
  },
  {
    key: 'wasForwarded',
    title: () => `This call was forwarded`,
    Icon: (isPositiveColor: boolean) => (
      <SwapHorizontalCircleIcon
        color={isPositiveColor ? 'info' : 'disabled'}
        sx={{ fontSize: 15 }}
      />
    ),
  },
  {
    key: 'hasVoicemail',
    title: () => 'This call has voicemail',
    Icon: (isPositiveColor: boolean) => (
      <VoicemailIcon color={isPositiveColor ? 'info' : 'disabled'} sx={{ fontSize: 15 }} />
    ),
  },
];

export interface ICallerCalleeDataToDisplay {
  caller: {
    avatarUrl: string | undefined;
    fullName: string | null;
    numberToDisplay: string;
    showNumber: boolean;
  };
  callee: {
    avatarUrl: string | undefined;
    fullName: string | null;
    numberToDisplay: string;
    showNumber: boolean;
  };
  call: {
    trackType: string;
    iconColor: IconProps['color'];
    showGroupIcon: boolean;
    showCallButton: boolean;
  };
}

const prepareDisplayData = (
  data: ICallsHistoryResult,
  user: IUserMeResult | null,
): ICallerCalleeDataToDisplay => {
  const deletedAccountLabel = 'DELETED ACCOUNT';

  const caller: ICallerCalleeDataToDisplay['caller'] = {
    avatarUrl: data.caller?.avatarUrl || DefaultAvatar,
    fullName:
      data.caller?.fullName ??
      (data.caller?.userRef ? data.caller?.companyExtension ?? deletedAccountLabel : data.from),
    numberToDisplay: data.from,
    get showNumber(): boolean {
      return this.fullName !== this.numberToDisplay;
    },
  };

  const callee: ICallerCalleeDataToDisplay['callee'] = {
    avatarUrl: data.callee?.avatarUrl || DefaultAvatar,
    fullName:
      data.callee?.fullName ??
      (data.callee?.userRef ? data.callee?.companyExtension ?? deletedAccountLabel : data.to),
    numberToDisplay: data.to,
    get showNumber(): boolean {
      return this.fullName !== this.numberToDisplay;
    },
  };

  const call: ICallerCalleeDataToDisplay['call'] = {
    trackType: data.trackType,
    iconColor: 'disabled',
    showGroupIcon: !!data.extensionsGroupRef,
    showCallButton: true,
  };

  //TODO: need to fix enums
  switch (data.status as any) {
    case CALL.STATUSES.COMPLETED:
      call.iconColor = 'info';
      break;
    case CALL.STATUSES.BUSY:
      call.iconColor = 'error';
      break;
    case CALL.STATUSES.NO_ANSWER:
      call.iconColor = 'error';
      break;
    case CALL.STATUSES.MISSED:
      call.iconColor = 'error';
      break;
  }

  const isAuthorizedUserCaller = data.caller?.userRef === user?.id;
  const isAuthorizedUserCallee = data.callee?.userRef === user?.id;

  if (data.connectionType === CALL.CONNECTION_TYPES.INTERNAL) {
    if (isAuthorizedUserCaller) {
      call.showCallButton = !!data.callee?.companyExtension;
    } else if (isAuthorizedUserCallee) {
      call.showCallButton = !!data.caller?.companyExtension;
    } else {
      call.showCallButton = false;
    }
  } else {
    if (user?.role === ROLES.SUPER_ADMIN) {
      if (data.isDirectCall) {
        call.showCallButton = isAuthorizedUserCaller || isAuthorizedUserCallee;
      }
    }
  }

  if (data.from === 'UNKNOWN') {
    call.showCallButton = false;
  }

  return { caller, callee, call };
};

const CallsHistory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const callToContact = useAppSelector((state) => state.voice.callToContact);
  const user = useAppSelector((state) => state.user.user);

  const [callsHistory, setCallsHistory] = useState<ICallsHistoryResult[]>([]);
  const [selectedCallHistory, setSelectedCallHistory] = useState<ICallsHistoryResult>();

  const [maxCount, setMaxCount] = useState<number>(0);

  const [showCallDetailsDialog, setShowCallDetailsDialog] = useState<boolean>(false);

  const { isAddonAvailable } = useAccess();

  const isMdOrUp = useThemeBreakpoints('up', 'md');

  const getCallsHistoryInitial = async (): Promise<void> => {
    dispatch(voiceReducer.setIsShowCallStatusLoader(true));
    const { payload } = await dispatch(voiceThunk.fetchCallsHistoryInitial({ skip: 0, limit: 25 }));
    if (payload) {
      setCallsHistory(payload.records);
      setMaxCount(payload.maxCount);
    }
    dispatch(voiceReducer.setIsShowCallStatusLoader(false));
  };

  const handleLoadMore = async (): Promise<void> => {
    const { payload } = await dispatch(
      voiceThunk.fetchCallsHistory({
        skip: callsHistory.length,
        limit: 25,
      }),
    );
    if (payload) {
      setCallsHistory((current) => [...current, ...payload.records]);
      setMaxCount(payload.maxCount);
    }
  };

  const handleStartCall = async () => {
    callService.handleInitCall({ dispatch, to: '' });
    navigate(ROUTES.DASHBOARD_VOICE_STATUS);
  };

  const checkForDeletedUser = (): void => {
    dispatch(snackbarReducer.showWarning('User was deleted, can not start call...'));
    return;
  };

  const handleStartCallFromHistory = async (data: ICallsHistoryResult): Promise<void> => {
    if (data.from === 'UNKNOWN') {
      return;
    }

    const isInternalCall = data.connectionType === CALL.CONNECTION_TYPES.INTERNAL;
    const isAuthorizedUserCaller = data.caller?.userRef === user?.id;
    const isAuthorizedUserCallee = data.callee?.userRef === user?.id;

    if (isInternalCall && !isAuthorizedUserCaller && !isAuthorizedUserCallee) {
      dispatch(snackbarReducer.showWarning('Please use dialer to call to extension.'));
      return;
    }

    let to!: string;

    if (isInternalCall && isAuthorizedUserCaller) {
      if (!data.callee?.companyExtension) {
        checkForDeletedUser();
        return;
      }
      to = data.callee?.companyExtension;
    }

    if (isInternalCall && isAuthorizedUserCallee) {
      if (!data.caller?.companyExtension) {
        checkForDeletedUser();
        return;
      }
      to = data.caller.companyExtension;
    }

    if (data.connectionType === CALL.CONNECTION_TYPES.EXTERNAL) {
      to = data.trackType === CALL.TRACK_TYPE.INBOUND ? data.from : data.to;
    }

    callService.handleInitCall({ dispatch, to });

    navigate(ROUTES.DASHBOARD_VOICE_STATUS);
  };

  const handleOpenCallDetailsDialog = (data: ICallsHistoryResult): void => {
    setSelectedCallHistory(data);
    setShowCallDetailsDialog(true);
  };

  const handleResetMissedCallsCount = async (): Promise<void> => {
    const { meta } = await dispatch(voiceThunk.fetchResetMissedCallsCount());
    if (meta.requestStatus === 'fulfilled') {
      dispatch(usersReducer.setUserMissedCalls({ count: 0 }));
    }
  };

  const handleSendMessageToContact = async (
    record: ICallsHistoryResult,
    chatPlatform: CHAT.PLATFORM,
  ): Promise<void> => {
    if (chatPlatform === CHAT.PLATFORM.WHATSAPP && !isAddonAvailable(ADDONS.WHATSAPP)) {
      return;
    }

    const response: IAddNewChatDialogResponse = {
      chatPlatform,
      // chatVariant: CHAT.VARIANT.CLASSIC,
      chat: null,
      contact: null,
      chatType:
        record.connectionType === CALL.CONNECTION_TYPES.INTERNAL
          ? CHAT.TYPE.INTERNAL
          : CHAT.TYPE.TENANT,
      internalUser: null,
      phoneNumber: null,
    };
    /*
        If call type is internal then check by yourself in caller/callee and take other user
        if you are not caller/callee then take callee
      
        If call is not internal then get contact by checking contactRef in caller/callee
        if no contactRef in both then get the one without userRef
      */
    if (record.connectionType === CALL.CONNECTION_TYPES.INTERNAL) {
      let companyExtensionToSearch!: string;

      const authorizedUserId = user?.id as string;

      if (record.caller?.userRef === authorizedUserId) {
        companyExtensionToSearch = (record.callee as ICallsHistoryResult['callee'])
          ?.companyExtension as string;
      }

      if (!companyExtensionToSearch && record.callee.userRef === authorizedUserId) {
        companyExtensionToSearch = (record.caller as ICallsHistoryResult['caller'])
          ?.companyExtension as string;
      }

      if (!companyExtensionToSearch) {
        dispatch(snackbarReducer.showError('Receiver was not found'));
        return;
      }

      response.internalUser = await dispatch(
        usersThunk.fetchByCompanyExtension(companyExtensionToSearch),
      ).unwrap();

      if (!response.internalUser) {
        dispatch(snackbarReducer.showError('Receiver was not found'));
        return;
      }

      response.chat = await dispatch(
        chatsThunk.fetchChatByReceiverCompanyExt(companyExtensionToSearch),
      ).unwrap();
    } else {
      const contactInfo =
        record[record.trackType === CALL.TRACK_TYPE.INBOUND ? 'caller' : 'callee'];

      response.contact = contactInfo?.contactRef
        ? await dispatch(contactThunk.fetchGetById({ id: contactInfo.contactRef })).unwrap()
        : await dispatch(
            contactThunk.fetchGetPrivateOrCompanyContact({
              chatType: CHAT.TYPE.TENANT,
              phoneNumber: contactInfo?.phoneNumber as string,
            }),
          ).unwrap();

      if (!response.contact) {
        response.phoneNumber = contactInfo?.phoneNumber as string;
      }

      response.chat = await dispatch(
        chatsThunk.fetchGetOneByRelativeInfo({
          phoneNumber: (response.contact?.phoneNumber ?? response.phoneNumber) as string,
          type: response.chatType,
          platform: response.chatPlatform,
        }),
      ).unwrap();
    }

    if (response.chat) {
      socketService.socket?.emit(
        SOCKETIO.EVENTS.JOIN_CHAT,
        JSON.stringify({ chatId: response.chat.id }),
      );
    }

    navigate(ROUTES.DASHBOARD_MESSAGES, {
      state: {
        sendMessageInformation: response,
      },
    });
  };

  const handleCallHistoryChanges = (data: {
    id: string;
    hasRecording?: boolean;
    hasTranscription?: boolean;
  }): void => {
    setCallsHistory((current) =>
      current.map((element) => {
        if (element.id === data.id) {
          element = { ...element, ...data };
        }
        return element;
      }),
    );
  };

  useEffect(() => {
    if (callToContact) {
      handleStartCall();
    }
  }, [callToContact]);

  useEffect(() => {
    handleResetMissedCallsCount();
    getCallsHistoryInitial();
    //TODO: need to check if we still need it
    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.VOICE }));
  }, []);

  const phoneNumberTypeLabel =
    user?.selectedPhoneNumberTypeToMakeCallsFrom === PHONE_NUMBER_TYPE.DIRECT_EXTENSION
      ? 'Direct extension'
      : 'Main/Business';

  return (
    <>
      <Typography px={2} variant="body1" component="span">
        Outgoing number: {phoneNumberTypeLabel}
      </Typography>

      {selectedCallHistory && (
        <CallDetailsDialog
          show={showCallDetailsDialog}
          setShow={setShowCallDetailsDialog}
          call={selectedCallHistory}
          handleCallHistoryChanges={handleCallHistoryChanges}
        />
      )}

      <List dense disablePadding sx={[{ overflow: 'auto' }, defStyles.scroll]}>
        {callsHistory.map((record) => {
          const { caller, callee, call } = prepareDisplayData(record, user);

          return (
            <CallHistoryListItem
              key={record.id}
              record={record}
              caller={caller}
              callee={callee}
              call={call}
              handleStartCallFromHistory={handleStartCallFromHistory}
              handleOpenCallDetailsDialog={handleOpenCallDetailsDialog}
              handleSendMessageToContact={handleSendMessageToContact}
              isWhatsAppEnabled={isAddonAvailable(ADDONS.WHATSAPP)}
              isMdOrUp={isMdOrUp}
            />
          );
        })}

        {maxCount > callsHistory.length ? (
          <Box display="flex" justifyContent="center" py={1}>
            <IconButton onClick={handleLoadMore}>
              <ExpandMoreIcon fontSize="medium" />
            </IconButton>
          </Box>
        ) : null}
      </List>
    </>
  );
};

interface ICallHistoryListItemData {
  record: ICallsHistoryResult;
  caller: ICallerCalleeDataToDisplay['caller'];
  callee: ICallerCalleeDataToDisplay['callee'];
  call: ICallerCalleeDataToDisplay['call'];
  handleStartCallFromHistory: (data: ICallsHistoryResult) => Promise<void>;
  handleOpenCallDetailsDialog: (data: ICallsHistoryResult) => void;
  handleSendMessageToContact: (data: ICallsHistoryResult, chatPlatform: CHAT.PLATFORM) => void;
  isMdOrUp: boolean;
  isWhatsAppEnabled: boolean;
}

const CallHistoryListItem = ({
  record,
  caller,
  callee,
  call,
  handleStartCallFromHistory,
  isMdOrUp,
  handleOpenCallDetailsDialog,
  handleSendMessageToContact,
  isWhatsAppEnabled,
}: ICallHistoryListItemData) => {
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const GridItemCallHistoryActions = (
    <Grid item xs={1} md={2}>
      <Box display="flex" justifyContent={{ md: 'flex-end' }}>
        <Tooltip title={'Start call'}>
          <Box component="span">
            <IconButton
              size="small"
              disabled={!call.showCallButton}
              color="primary"
              onClick={() => handleStartCallFromHistory(record)}
            >
              <PhoneIcon fontSize="small" />
            </IconButton>
          </Box>
        </Tooltip>

        {isWhatsAppEnabled ? (
          <IconButton
            color="primary"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
              setOpen((current) => !current);
            }}
          >
            <Menu
              anchorEl={anchorEl}
              open={open}
              disableAutoFocusItem
              color="primary"
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem
                color="primary"
                onClick={() => handleSendMessageToContact(record, CHAT.PLATFORM.SMS)}
              >
                <ListItemIcon>
                  <MessageOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Send SMS message
              </MenuItem>
              <MenuItem
                sx={{ color: 'success.main' }}
                onClick={() => handleSendMessageToContact(record, CHAT.PLATFORM.WHATSAPP)}
              >
                <ListItemIcon>
                  <WhatsAppIcon color="success" fontSize="small" />
                </ListItemIcon>
                Send WhatsApp message
              </MenuItem>
            </Menu>

            <MessageOutlinedIcon fontSize="small" />
          </IconButton>
        ) : (
          <Tooltip title="Send message">
            <IconButton
              disabled={!call.showCallButton}
              color="primary"
              size="small"
              onClick={() => handleSendMessageToContact(record, CHAT.PLATFORM.SMS)}
            >
              <MessageOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title={'Call details'}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => handleOpenCallDetailsDialog(record)}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Grid>
  );

  return (
    <>
      <ListItem key={record.id}>
        <Grid container spacing={2} columns={{ xs: 2, sm: 8, md: 12 }} alignItems="center">
          <Grid item xs={3}>
            <Box display="flex" gap={1} alignItems="center">
              <Avatar src={caller.avatarUrl} />
              <Box>
                <Typography variant="body2">{caller.fullName}</Typography>
                {caller.showNumber ? (
                  <Typography variant="caption">{caller.numberToDisplay}</Typography>
                ) : null}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={1}>
            <Typography variant="body2" fontSize={12} component="span" color={call.iconColor}>
              {record.status}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Box display="flex" gap={1} alignItems="center">
              <Avatar src={callee.avatarUrl} />
              <Box>
                <Typography variant="body2">{callee.fullName}</Typography>
                {callee.showNumber ? (
                  <Typography variant="caption">{callee.numberToDisplay}</Typography>
                ) : null}
              </Box>
            </Box>
          </Grid>

          {!isMdOrUp ? GridItemCallHistoryActions : null}

          <Grid item xs={1} sm={6} md={2}>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography
                variant="caption"
                display="flex"
                alignItems="flex-start"
                sx={{ lineHeight: 1 }}
              >
                <TodayIcon fontSize="inherit" sx={{ mr: 0.5 }} />
                {dateHelper.format(record.createdAt, 'yyyy-MM-dd hh:mm a')}
              </Typography>

              <Typography
                variant="caption"
                display="flex"
                alignItems="flex-start"
                sx={{ lineHeight: 1 }}
              >
                <AvTimerIcon fontSize="inherit" sx={{ mr: 0.5 }} />
                {dateHelper.secondsToHms(record.duration)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={1} sm={2} md={1}>
            <Box
              display="flex"
              flexDirection={{
                xs: 'row',
                md: 'column',
              }}
              alignContent={{ md: 'flex-start' }}
              justifyContent={{ sm: 'flex-end' }}
              flexWrap="wrap"
              gap={0.5}
              maxHeight={65}
            >
              {callInfo.map((element) => {
                return (
                  <Tooltip
                    key={element.key}
                    title={record[element.key] ? element.title(record) : ''}
                  >
                    {element.Icon(!!record[element.key])}
                  </Tooltip>
                );
              })}
            </Box>
          </Grid>

          {isMdOrUp ? GridItemCallHistoryActions : null}
        </Grid>
      </ListItem>

      <Divider variant="fullWidth" component="div" />
    </>
  );
};

export default CallsHistory;

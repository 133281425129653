import { Dispatch, SetStateAction, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  Button,
  Avatar,
  Divider,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Switch,
  FormControlLabel,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Logout as LogoutIcon,
  EmojiPeople as EmojiPeopleIcon,
  AirlineSeatFlat as AirlineSeatFlatIcon,
} from '@mui/icons-material';

import * as userThunk from '@/pages/dashboard/users/thunk';

import logo from '@/assets/images/whiisl-logo.png';
import defAvatar from '@/assets/images/generic_wwtjhr.png';

import * as lsService from '@/services/local-storage';

import useThemeBreakpoints from '@/hooks/use-theme-breakpoints';

import * as userReducer from '@/store/reducers/user';

import ROUTES from '@/constants/routes';
import TENANT from '@/constants/tenant';
import { FEATURES } from '@/constants/features';

interface IDashboardLayoutHeaderProps {
  showSideMenu?: boolean;
  isSideMenuOpened?: boolean;
  setIsSideMenuOpened?: Dispatch<SetStateAction<boolean>>;
}

const PrivateLayoutHeader = ({
  showSideMenu = true,
  setIsSideMenuOpened,
}: IDashboardLayoutHeaderProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth0();

  const { user } = useAppSelector((state) => state.user);
  const { tenant } = useAppSelector((state) => state.tenant);
  const { device } = useAppSelector((state) => state.twilio);

  const [rightMenuAnchorEl, setRightMenuAnchorEl] = useState<HTMLElement | null>(null);

  const isXlOrUp = useThemeBreakpoints('up', 'xl');
  const isXsOrDown = useThemeBreakpoints('down', 'xs');

  const handleCloseRightMenu = () => {
    setRightMenuAnchorEl(null);
  };

  const handleLogout = async (): Promise<void> => {
    if (device && device.state === 'registered') {
      await device.unregister();
    }

    await dispatch(userThunk.fetchLogout());

    handleCloseRightMenu();
    lsService.onLogout();

    return logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URL,
      },
    });
  };

  const handleOnLogoPress = (): void => {
    if (location.pathname.includes(ROUTES.PRICING)) {
      window.open('https://whistle.tech/', '_self');
      return;
    }

    if (!tenant) {
      navigate(ROUTES.AUTH_LAYOUT);
      return;
    }

    if (tenant.skipSubsCheck) {
      navigate(
        tenant.features?.some((element) => element.name === FEATURES.VOICE)
          ? ROUTES.DASHBOARD_VOICE
          : ROUTES.DASHBOARD_MESSAGES,
      );
      return;
    }

    if (!tenant.skipSubsCheck && tenant.subs && !tenant.subs.isActive) {
      navigate(ROUTES.PROFILE_SUBSCRIPTIONS);
      return;
    }

    if (tenant.status === TENANT.STATUSES.ACTIVE && user && tenant?.subs) {
      if (tenant.features?.some((element) => element.name === FEATURES.VOICE)) {
        navigate(ROUTES.DASHBOARD_VOICE);
        return;
      } else {
        navigate(ROUTES.DASHBOARD_MESSAGES);
        return;
      }
    }

    if (tenant.status === TENANT.STATUSES.PENDING_APPROVAL && tenant.subs) {
      navigate(ROUTES.KYC);
      return;
    }
  };

  const handleDoNotDisturbChange = () => {
    const newValue = !user?.dnd;
    dispatch(userThunk.fetchUpdateMePreferences({ dnd: newValue }));
  };

  const handleOpenSwitchAccounts = (): void => {
    dispatch(userReducer.openAccountsDialog());
    navigate(ROUTES.PROFILE_ME);
  };

  const isRightMenuOpened = Boolean(rightMenuAnchorEl);

  const hideMenuOnView =
    (!tenant?.skipSubsCheck && tenant?.status === TENANT.STATUSES.PENDING_APPROVAL) || isXlOrUp;

  return (
    <AppBar
      position="sticky"
      color="primary"
      sx={{
        backgroundSize: '100%',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar disableGutters>
        <Box display="flex" justifyContent="space-between" alignItems="center" flex={1}>
          <Box display="flex" gap={1}>
            {hideMenuOnView || !showSideMenu ? (
              <></>
            ) : (
              <IconButton
                color="inherit"
                onClick={() => setIsSideMenuOpened?.((prevState) => !prevState)}
              >
                <MenuIcon />
              </IconButton>
            )}

            {isXsOrDown ? null : (
              <Button
                color="primary"
                variant="contained"
                sx={{
                  py: 0,
                  fontSize: 36,
                  fontFamily: 'DM Serif Display',
                  '&:hover': {
                    bgcolor: 'primary.main',
                  },
                }}
                startIcon={
                  <Box component="img" width="50px" height="50px" src={logo} loading="lazy" />
                }
                onClick={handleOnLogoPress}
              >
                Whistle
                {/* This is TM trademark symbol. Commented for now because waiiting fo approval */}
                {/* &#8482; */}
              </Button>
            )}
          </Box>

          <Box>
            <Tooltip
              title={`Do not disturb mode is currently ${user?.dnd ? 'on' : 'off'}`}
              placement="left"
              sx={{ mr: 2 }}
            >
              <IconButton
                size="large"
                color={user?.dnd ? 'error' : 'success'}
                onClick={handleDoNotDisturbChange}
              >
                {user?.dnd ? (
                  <AirlineSeatFlatIcon fontSize="large" />
                ) : (
                  <EmojiPeopleIcon fontSize="large" />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip title="Account settings" placement="left">
              <IconButton
                onClick={(event) => setRightMenuAnchorEl(event.currentTarget)}
                size="small"
                aria-controls={isRightMenuOpened ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isRightMenuOpened ? 'true' : undefined}
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    border: '2px solid #323232',
                  }}
                  src={user?.avatar?.url || defAvatar}
                />
              </IconButton>
            </Tooltip>

            <Menu
              anchorEl={rightMenuAnchorEl}
              id="account-menu"
              open={isRightMenuOpened}
              onClose={handleCloseRightMenu}
              onClick={handleCloseRightMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => navigate(ROUTES.PROFILE_ME)}>Profile</MenuItem>
              <MenuItem onClick={() => handleOpenSwitchAccounts()}>Accounts</MenuItem>

              <Divider />

              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default PrivateLayoutHeader;

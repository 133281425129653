import { useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

const LS_PREV_PATH = 'nav_prev_path';

const useRedirectToPreviousLocation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = localStorage.getItem(LS_PREV_PATH);

    if (path) {
      navigate(path, { replace: true });
    } else {
      localStorage.setItem(LS_PREV_PATH, location.pathname);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(LS_PREV_PATH, location.pathname);
  }, [location.pathname]);
};

export default useRedirectToPreviousLocation;

import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import headers from './headers';

const BACK_END_HOST = process.env.REACT_APP_BACKEND_HOST;

interface IOptions extends AxiosRequestConfig<any> {}

const makeRequest = async (url: string, options: IOptions) => {
  try {
    const { data } = await axios({
      url: `${BACK_END_HOST}${url}`,
      ...options,
      headers: { ...options.headers, ...headers.defaults() },
    });

    return data;
  } catch (error: AxiosError | any) {
    console.error({
      description: error.response.data.description,
      message: error.response.data.message,
    });

    return error.response.data;
  }
};

export default makeRequest;

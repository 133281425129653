import { createAsyncThunk } from '@reduxjs/toolkit';

import { ITenantCompanyMsgsCallsGroupRequest } from '@/interfaces/tenant';
import { ISetUsersAllowedCountryCodesRequest } from '@/interfaces/users/users';

import handleErrors from '@/services/handle-errors';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import { IThunkExtra } from '@/store/store';

import * as SNACKBAR from '@/constants/snackbar';

export const fetchCompanyMsgsCallsGroup = createAsyncThunk(
  'setting/fetchCompanyMsgsCallsGroup',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.getCompanyMsgsCallsGroup();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSetCompanyMsgsCallsGroup = createAsyncThunk(
  'setting/fetchSetCompanyMsgsCallsGroup',
  async (data: ITenantCompanyMsgsCallsGroupRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.setCompanyMsgsCallsGroup(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(
          SNACKBAR.MESSAGES.GENERAL.CREATED('Company messages and calls group'),
        ),
      );
      return response.data;
    }
  },
);

export const fetchTurnOnOffCallsRecordings = createAsyncThunk(
  'setting/fetchTurnOnOffCallsRecordings',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.turnOnOffCallsRecordings();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(
          SNACKBAR.MESSAGES.GENERAL.UPDATED('Call recordings preferences'),
        ),
      );
      return response.data;
    }
  },
);

export const fetchTurnOnOffLiveCallsTranscription = createAsyncThunk(
  'setting/fetchTurnOnOffLiveCallsTranscription',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.turnOnOffLiveCallsTranscription();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(
          SNACKBAR.MESSAGES.GENERAL.UPDATED('Call Transcriptions preferences'),
        ),
      );
      return response.data;
    }
  },
);

export const fetchTurnOnOffVoicemailTranscription = createAsyncThunk(
  'setting/fetchTurnOnOffVoicemailTranscription',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.turnOnOffVoicemailTranscription();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(
          SNACKBAR.MESSAGES.GENERAL.UPDATED('Call Transcriptions preferences'),
        ),
      );
      return response.data;
    }
  },
);

export const fetchSetUsersAllowedCountries = createAsyncThunk(
  'setting/fetchSetUsersAllowedCountries',
  async (data: ISetUsersAllowedCountryCodesRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.setUsersAllowedCountryCodes(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(
          SNACKBAR.MESSAGES.GENERAL.UPDATED('Users Allowed Country Codes'),
        ),
      );
      return response.data;
    }
  },
);

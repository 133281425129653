import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICountryCode {
  countryCodeValue: number;
}

const initialState: ICountryCode = {
  countryCodeValue: 1,
};

export const countryCodeSlice = createSlice({
  name: 'countryCode',
  initialState,
  reducers: {
    setCountryCodeValue: (state, action: PayloadAction<number>) => {
      state.countryCodeValue = action.payload;
    },
  },
});

export const { setCountryCodeValue } = countryCodeSlice.actions;

export default countryCodeSlice.reducer;

import makeRequest from '../make-request-v2';
import token from '../token';
import headers from '../headers';

import {
  IGetIotDevicesGroupsQueryParams,
  IIotDevicesGroupCreateRequest,
  IIotDevicesGroupResult,
} from '@/interfaces/iot-devices-groups';
import { IPartialResult } from '@/interfaces/querying-data';
import { IResponseResult } from '@/interfaces/base';

const GET_IOT_DEVICES_GROUPS = '/api/v1/iot/devices-groups';
const CREATE_IOT_DEVICES_GROUPS = '/api/v1/iot/devices-groups';
const UPDATE_IOT_DEVICES_GROUP = (iotDeviceId: string) =>
  `/api/v1/iot/devices-groups/${iotDeviceId}`;
const REMOVE_IOT_DEVICES_GROUP = (iotDeviceId: string) =>
  `/api/v1/iot/devices-groups/${iotDeviceId}`;

export const getByParams = async (
  data: IGetIotDevicesGroupsQueryParams,
): Promise<IResponseResult<IPartialResult<IIotDevicesGroupResult>>> => {
  const queryParams: Record<string, string> = {
    limit: data.limit.toString(),
    skip: data.skip.toString(),
  };

  if (data.search) {
    queryParams.search = data.search;
  }

  if (data.sortField) {
    queryParams.sortField = data.sortField;
  }

  if (data.sortDirection) {
    queryParams.sortDirection = data.sortDirection.toString();
  }

  return makeRequest(GET_IOT_DEVICES_GROUPS, {
    method: 'GET',
    headers: { Authorization: await token() },
    query: queryParams,
  });
};

export const create = async (
  data: IIotDevicesGroupCreateRequest,
): Promise<IResponseResult<IIotDevicesGroupResult>> => {
  return makeRequest(CREATE_IOT_DEVICES_GROUPS, {
    method: 'POST',
    headers: {
      ...headers.applicationJson(),
      Authorization: await token(),
    },
    body: JSON.stringify(data),
  });
};

export const update = async (
  iotDeviceId: string,
  data: IIotDevicesGroupCreateRequest,
): Promise<IResponseResult<IIotDevicesGroupResult>> => {
  return makeRequest(UPDATE_IOT_DEVICES_GROUP(iotDeviceId), {
    method: 'PUT',
    headers: {
      ...headers.applicationJson(),
      Authorization: await token(),
    },
    body: JSON.stringify(data),
  });
};

export const remove = async (iotDeviceId: string): Promise<IResponseResult<string>> => {
  return makeRequest(REMOVE_IOT_DEVICES_GROUP(iotDeviceId), {
    method: 'DELETE',
    headers: { Authorization: await token() },
  });
};

export const paperStyle = {
  style: {
    left: '10%',
    transform: 'translateX(-30%) translateY(0%)',
  },
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

export const avatarTableMainCell = {
  fontWeight: 'bold',
};

export const othersTableCell = {
  fontWeight: 'bold',
};

export const actionTableCell = {
  fontWeight: 'bold',
  paddingRight: '3rem',
};

export const topography = {
  flex: '1 1 100%',
};

export const uploadAddNewContactsDiv = {
  display: 'flex',
};

export const mainDiv = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
};

export const tableBoxSX = {
  width: '100%',
};

export const tableSX = {
  minWidth: 750,
};

export const avatar = {
  marginLeft: '1rem',
};

export const tableContainerSX = {
  '&::-webkit-scrollbar': { display: 'none' },
  overflow: 'auto',
  minHeight: 'calc(100vh - 290px)',
  maxHeight: 'calc(100vh - 290px)',
};

export const box1SX = {
  flexGrow: 1,
  m: 2,
};

import { ReactElement } from 'react';
import { ISortParams } from '@/interfaces/querying-data';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableProps,
  TableRow,
  TableRowProps,
  TableSortLabel,
} from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import { visuallyHidden } from '@mui/utils';

export interface ITemplateTableColumn<Entity> {
  sortFieldName?: keyof Entity;
  sortArrowPosition?: 'before' | 'after';
  value?: any;
  header?: string;
  getHeader?: () => any;
  getValue?: (el: Entity, index: number) => any;
  valueProps?: TableCellProps;
  headerProps?: TableCellProps;
  sharedProps?: TableCellProps;
}

export interface ITemplateTableProps<Entity> {
  getDataEntityId: (el: Entity) => string | number;
  data: Entity[];
  columns: ITemplateTableColumn<Entity>[];
  currentSortParams?: ISortParams<Entity>;
  // Should be used to return TableCell or <></> with multiple TableCell components inside
  getExpandComponent?: (el: Entity) => ReactElement;
  onSortChange?: (event: React.MouseEvent<any, any>, sortParams: ISortParams<Entity>) => void;
  tableProps?: TableProps;
  tableContainerProps?: TableContainerProps;
  getTableRowProps?: (el: Entity) => TableRowProps;
}

interface IExapandableRowProps extends PropsWithChildren {
  expandComponent: ReactElement;
  tableRowProps: TableRowProps;
}

interface ISortableHeaderTableCellProps {
  header: string;
  sortFieldName: string;
  currentSortParams: ISortParams<any>;
  sortArrowPosition: 'before' | 'after';
  onSortChange: Required<ITemplateTableProps<any>>['onSortChange'];
}

const ExapandableRow = (props: IExapandableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const sxTableRowProps: any = props.tableRowProps?.sx || {};

  if (isExpanded) {
    sxTableRowProps['& > *'] = {
      borderBottomColor: 'transparent',
    };
  }

  return (
    <>
      <TableRow {...props.tableRowProps} sx={sxTableRowProps}>
        {props.children}

        <TableCell sx={{ py: 0 }}>
          <IconButton color="primary" onClick={() => setIsExpanded((prevState) => !prevState)}>
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
      </TableRow>

      {isExpanded ? <TableRow>{props.expandComponent as any}</TableRow> : null}
    </>
  );
};

const SortableHeaderTableCell = (props: ISortableHeaderTableCellProps) => {
  const isSortActive = props.currentSortParams.sortField === props.sortFieldName;
  const showSortBefore = props.sortArrowPosition === 'before';

  const handleSortChange = (event: React.MouseEvent<any, any>) => {
    const { currentSortParams } = props;

    const newSortParams: ISortParams<any> = {
      sortDirection: undefined,
      sortField: props.sortFieldName,
    };

    const isAlreadyAssigned = newSortParams.sortField === currentSortParams.sortField;

    if (isAlreadyAssigned) {
      if (currentSortParams.sortDirection === -1) {
        newSortParams.sortDirection = 1;
      } else if (currentSortParams.sortDirection === 1) {
        newSortParams.sortDirection = undefined;
        newSortParams.sortField = undefined;
      }
    } else {
      newSortParams.sortDirection = -1;
    }

    props.onSortChange(event, newSortParams);
  };

  return (
    <TableSortLabel
      active={isSortActive}
      direction={props.currentSortParams.sortDirection === 1 ? 'asc' : 'desc'}
      onClick={handleSortChange}
      sx={{ flexDirection: showSortBefore ? 'row-reverse' : 'row' }}
    >
      {props.header}

      {isSortActive ? (
        <Box component="span" sx={visuallyHidden}>
          {props.currentSortParams.sortDirection === 1 ? 'sorted ascending' : 'sorted descending'}
        </Box>
      ) : null}
    </TableSortLabel>
  );
};

const TemplateTable = (props: ITemplateTableProps<any>) => {
  const { tableProps = {}, tableContainerProps = {}, getTableRowProps } = props;

  const isExpandable = !!props.getExpandComponent;

  return (
    <TableContainer {...tableContainerProps}>
      <Table stickyHeader {...tableProps}>
        <TableHead>
          <TableRow>
            {props.columns.map((el, i) => {
              const { headerProps = {}, sharedProps = {} } = el;
              const header = el.getHeader ? el.getHeader() : el.header;
              return (
                <TableCell
                  key={header + i}
                  {...headerProps}
                  {...sharedProps}
                  sx={
                    {
                      fontWeight: 'bold',
                      py: '.25rem',
                      ...(headerProps.sx || {}),
                      ...(sharedProps.sx || {}),
                    } as any
                  }
                >
                  {el.sortFieldName ? (
                    <SortableHeaderTableCell
                      header={header}
                      onSortChange={props.onSortChange as any}
                      sortFieldName={el.sortFieldName as string}
                      sortArrowPosition={el.sortArrowPosition || 'after'}
                      currentSortParams={
                        props.currentSortParams as Required<
                          ITemplateTableProps<any>
                        >['currentSortParams']
                      }
                    />
                  ) : (
                    header
                  )}
                </TableCell>
              );
            })}

            {isExpandable ? <TableCell padding="checkbox"></TableCell> : <></>}
          </TableRow>
        </TableHead>

        <TableBody>
          {props.data.map((el, rowIndex) => {
            const tableRowProps = getTableRowProps ? getTableRowProps(el) : {};

            const tableCells = props.columns.map((columnProps, i) => {
              const { valueProps = {}, sharedProps = {} } = columnProps;

              const value = columnProps.getValue
                ? columnProps.getValue(el, rowIndex)
                : columnProps.value;
              const header = el.getHeader ? el.getHeader() : el.header;

              return (
                <TableCell
                  key={props.getDataEntityId(el) + value + header + i}
                  {...valueProps}
                  {...sharedProps}
                  sx={{ py: '.75rem', ...(valueProps.sx || {}), ...(sharedProps.sx || {}) } as any}
                >
                  {value}
                </TableCell>
              );
            });

            if (isExpandable) {
              return (
                <ExapandableRow
                  key={props.getDataEntityId(el)}
                  expandComponent={(props.getExpandComponent as Function)(el)}
                  tableRowProps={tableRowProps}
                >
                  {tableCells}
                </ExapandableRow>
              );
            } else {
              return (
                <TableRow key={props.getDataEntityId(el)} {...tableRowProps}>
                  {tableCells}
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TemplateTable;

import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { Box, Button, Container, Grid, Paper, Stack, TextField, Typography } from '@mui/material';

import {
  IContactsGroupCreateRequest,
  IContactsGroupUpdateRequest,
} from '@/interfaces/contacts-group';
import { IContact } from '@/interfaces/contact';

import contactsGroupsThunk from '@/store/thunk/dashboard/contacts-groups';

import * as menuReducer from '@/store/reducers/dashboard/menu';

import contactsGroupsValidationSchemas from '@/helpers/validation-schemas/contacts-groups/index';

import SelectContactsFromListDialog from '@/components/Dialogs/Select-contacts-from-list-dialog/Select-contacts-from-list-dialog';

import ROUTES from '@/constants/routes';
import DASHBOARD from '@/constants/dashboard';
import { FORMIK_STATUS } from '@/constants/formik';

const CreateOrUpdateContactsGroupsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const contactsGroup = location.state?.contactsGroup;
  const isCreatingContactsGroup = !contactsGroup;

  const formik = useFormik({
    initialStatus: FORMIK_STATUS.IDLE,
    enableReinitialize: true,
    initialValues: isCreatingContactsGroup
      ? {
          name: '',
          description: '',
          selectAllContacts: false,
          selectedContacts: [] as IContact[],
          excludedContacts: [] as IContact[],
        }
      : {
          name: contactsGroup.name as string,
          description: contactsGroup.description as string,
          selectAllContacts: false,
          selectedContacts: contactsGroup.contacts as IContact[],
          excludedContacts: [] as IContact[],
        },
    validationSchema: isCreatingContactsGroup
      ? contactsGroupsValidationSchemas.create
      : contactsGroupsValidationSchemas.update,
    onSubmit: async (values, formikHelpers) => {
      if (isCreatingContactsGroup) {
        const body: IContactsGroupCreateRequest = {
          name: values.name,
          description: values.description,
          selectAllContacts: values.selectAllContacts,
          selectedContactsIds: values.selectedContacts.map((el) => el.id) as string[],
          excludedContactsIds: values.excludedContacts.map((el) => el.id) as string[],
        };

        const { meta } = await dispatch(contactsGroupsThunk.fetchCreate(body));

        if (meta.requestStatus === 'fulfilled') {
          formikHelpers.resetForm();
          navigate(ROUTES.DASHBOARD_CONTACTS_GROUPS);
        }
      } else {
        const body: IContactsGroupUpdateRequest & Record<'id', string> = {
          id: contactsGroup.id,
          name: values.name,
          description: values.description,
          selectAllContacts: values.selectAllContacts,
          selectedContactsIds: values.selectedContacts.map((el) => el.id) as string[],
          excludedContactsIds: values.excludedContacts.map((el) => el.id) as string[],
        };

        const { meta } = await dispatch(contactsGroupsThunk.fetchUpdateById(body));

        if (meta.requestStatus === 'fulfilled') {
          formikHelpers.resetForm();
          navigate(ROUTES.DASHBOARD_CONTACTS_GROUPS, { replace: true });
        }
      }
    },
  });

  const [isSelectContactsDialogOpened, setIsSelectContactsDialogOpened] = useState(false);

  const getSelectedContactsLabel = () => {
    const { selectAllContacts, selectedContacts, excludedContacts } = formik.values;

    if (selectAllContacts) {
      const hasMoreThanOneContact = excludedContacts.length > 1;

      return `all contacts selected ${
        excludedContacts.length
          ? `except ${excludedContacts.length} contact${hasMoreThanOneContact ? 's' : ''}`
          : ''
      }`;
    } else {
      return selectedContacts.length ? selectedContacts.length : 'No contacts selected';
    }
  };

  useEffect(() => {
    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.CONTACTS }));
  }, []);

  const isLoading = formik.status === FORMIK_STATUS.LOADING;

  return (
    <Box flex={1} display="flex" p={2} justifyContent="center" overflow="auto">
      <SelectContactsFromListDialog
        initialContacts={formik.values.selectedContacts}
        isOpened={isSelectContactsDialogOpened}
        setIsOpened={setIsSelectContactsDialogOpened}
        handleSubmit={(response) => {
          if (response) {
            formik.setValues((prevState) => ({
              ...prevState,
              selectAllContacts: response.isAllSelected,
              excludedContacts: response.excluded,
              selectedContacts: response.selected,
            }));
          } else {
            formik.setValues((prevState) => ({
              ...prevState,
              selectAllContacts: false,
              excludedContacts: [],
              selectedContacts: [],
            }));
          }
        }}
      />

      <Box
        component={Paper}
        display="flex"
        flexDirection="column"
        flex={1}
        gap={1}
        p={2}
        maxWidth="sm"
      >
        <Box sx={{ flexGrow: 1, m: 2 }}>
          <Stack spacing={2}>
            <Typography variant="h5" color="primary.main" textAlign="center" fontWeight="bold">
              {isCreatingContactsGroup ? 'Add' : 'Edit'} contacts group
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  required
                  name="name"
                  label="Name"
                  placeholder="Sales"
                  disabled={isLoading}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <TextField
                  fullWidth
                  name="description"
                  label="Description"
                  placeholder="Whistle company's sales team"
                  disabled={isLoading}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && !!formik.errors.description}
                  helperText={formik.touched.description && formik.errors.description}
                />

                <Stack spacing={1}>
                  <Stack
                    spacing={1}
                    direction="row"
                    gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle2" component="p">
                      Contacts: {getSelectedContactsLabel()}
                    </Typography>

                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setIsSelectContactsDialogOpened(true)}
                    >
                      Modify selection
                    </Button>
                  </Stack>

                  <Typography variant="body2" component="p"></Typography>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </Box>

        <Box display="flex" mt="auto" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            size="large"
            disabled={isLoading || !formik.isValid || formik.isSubmitting}
            onClick={formik.submitForm}
          >
            {isCreatingContactsGroup ? 'Create' : 'Update'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateOrUpdateContactsGroupsPage;

import { FunctionComponent, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import uuid from '@/utils/uuid';

import * as phoneNumberValidator from '@/helpers/phone-number';

import { LOCAL_STORAGE_KEYS } from '@/constants/local-storage';

// This will be initiated as soon as possible to listen to url changes and capture specific param for click-to-call
const ClickToCallUrlListener: FunctionComponent = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    if (!location.search) {
      return;
    }

    const params = new URLSearchParams(location.search);

    for (const [key, value] of params.entries()) {
      if (key === 'clickToCallPhoneNumber') {
        const { isValid, validatedPhoneNumber } = phoneNumberValidator.validate(
          value.includes('+') ? value : `+${value}`,
        );

        if (isValid) {
          // Param "clickToCallPhoneNumber" will be deleted from url automatically by implemented redirect mechanism
          localStorage.setItem(LOCAL_STORAGE_KEYS.CLICK_TO_CALL_PHONE_NUMBER, validatedPhoneNumber);
        }
      }
    }
  }, [location.search]);

  return <></>;
};

export default ClickToCallUrlListener;

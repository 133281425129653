import { Dispatch, Fragment, FunctionComponent, SetStateAction } from 'react';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Box,
  Badge,
  CircularProgress,
  Divider,
  Button,
  ListItemButton,
  alpha,
  Typography,
} from '@mui/material';
import RouterIcon from '@mui/icons-material/Router';

import { useAppSelector } from '@/store/hooks';

import { IChatResult, IGetChatsQueryParams, INotCreatedChat } from '@/interfaces/chat';
import { IPartialResult } from '@/interfaces/querying-data';
import { IChatMenuState } from '../Chats-messages-layout';

interface IIoTDevicesListProps {
  isSearchActive: boolean;
  isLoading: boolean;
  menuState: IChatMenuState;
  selectedChat: IChatResult | null;
  notCreatedSelectedChat: INotCreatedChat | null;
  chatsResult: IPartialResult<IChatResult>;
  setQueryParams: Dispatch<
    SetStateAction<Required<IGetChatsQueryParams> & Record<'search', string>>
  >;
  handleSelectChat: (chat: IChatResult) => void;
}

const IoTDeviceList: FunctionComponent<IIoTDevicesListProps> = ({
  isLoading,
  menuState,
  selectedChat,
  notCreatedSelectedChat,
  chatsResult,
  setQueryParams,
  handleSelectChat,
  isSearchActive,
}): JSX.Element => {
  const { user } = useAppSelector((state) => state.user);

  return (
    <List
      dense
      disablePadding
      sx={{
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        pb: 8,
      }}
    >
      {chatsResult.records.map((chat) => {
        const isChatSelected =
          selectedChat?.id === chat.id || notCreatedSelectedChat?.localId === chat.id;

        return (
          <Fragment
            // @ts-expect-error because not creatd chat might be in array it has localId field
            key={chat.id + chat['localId']}
          >
            <ListItem
              disablePadding
              sx={
                isChatSelected
                  ? {
                      bgcolor: (theme) => alpha(theme.palette.secondary.light, 0.24),
                      color: 'primary.contrastText',
                    }
                  : {}
              }
            >
              <ListItemButton
                disableRipple={!!menuState.anchor}
                onClick={() => handleSelectChat(chat)}
                sx={{
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  px: 1,
                  pb: 0.75,
                }}
              >
                <Box display="flex" alignItems="center" gap={2} py={0.2}>
                  <ListItemAvatar sx={{ ml: 0.5, minWidth: 'auto' }}>
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      variant="dot"
                      color="primary"
                      badgeContent={chat.lastMsgSentByRef === user?.id ? 0 : chat.newMsgsCount ?? 0}
                    >
                      <Box
                        height={35}
                        width={35}
                        borderColor={'primary'}
                        border={'2px solid'}
                        borderRadius={50}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <RouterIcon color="primary" />
                      </Box>
                    </Badge>
                  </ListItemAvatar>

                  <Box display="flex" flexDirection="column" flex={1}>
                    <Box display="flex" gap={1} justifyContent="space-between">
                      <ListItemText
                        primary={chat?.iotDevice?.name ?? 'Unknown Device'}
                        secondary={
                          <Typography color={'grey'} fontSize={12.5} height={15}>
                            {chat.iotDevice?.description ?? ' '}
                          </Typography>
                        }
                        primaryTypographyProps={{
                          fontSize: 'small',
                          color: 'primary.main',
                        }}
                      />

                      <Box display="flex" gap={1} alignItems="center"></Box>
                    </Box>
                  </Box>
                </Box>
              </ListItemButton>
            </ListItem>

            <Divider />
          </Fragment>
        );
      })}

      {chatsResult.records.length < chatsResult.maxCount && !isSearchActive ? (
        <Button
          fullWidth
          color="secondary"
          onClick={() =>
            setQueryParams((prevState) => ({
              ...prevState,
              skip: chatsResult.records.length,
            }))
          }
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="5%" />
          ) : (
            <ExpandMoreIcon fontSize="medium" />
          )}
        </Button>
      ) : null}
    </List>
  );
};

export default IoTDeviceList;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { ISignUpTenant } from '@/interfaces/tenant';

import handleErrors from '@/services/handle-errors';

import * as backdropReducer from '@/store/reducers/backdrop';

import * as tenantApi from '@/services/api/v1/tenant';

export const fetchTenantSignUp = createAsyncThunk(
  'user/fetchTenantSignUp',
  async (data: ISignUpTenant, thunkAPI) => {
    const response = await tenantApi.signUpTenant(data);

    const { dispatch } = thunkAPI;
    dispatch(backdropReducer.setShow(false));

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const stepperBoxSX = {
  width: '100%',
  m: 2,
};

export const box1SX = {
  flexGrow: 1,
  p: 2,
  overflow: 'auto',
};

export const box2SX = {
  flex: '1 1 auto',
};

export const box3SX = {
  display: 'flex',
  flexDirection: 'row',
  pt: 2,
};

export const btnSX = {
  mr: 1,
};

export const Typography1SX = {
  fontSize: 13,
  display: {
    xs: 'none',
    md: 'flex',
  },
};

import { useEffect, useRef } from 'react';

import { IUserMeResult } from '@/interfaces/users/users';

import { useAppSelector } from '@/store/hooks';

import * as socketService from '@/services/socketio/socketio';

import useBrowserNotifications from '@/hooks/use-browser-notifications';

import SOCKETIO from '@/constants/socketio';
import MESSAGES from '@/constants/message';

function BrowserNotifications() {
  const { showNotification } = useBrowserNotifications();

  const { user } = useAppSelector((state) => state.user);
  const userRef = useRef<IUserMeResult | null>(null);

  useEffect(() => {
    socketService.socket?.on(SOCKETIO.EVENTS.NEW_MSG_BROWSER_NOTIFICATION, (json) => {
      const data = JSON.parse(json);

      if (!data.userSender || data.userSender?.id !== userRef.current?.id) {
        let title = data.contact
          ? `${data.contact?.fullName ?? ''}, ${data.contact?.phoneNumber}`
          : data.unknownContact?.phoneNumber;

        if (data.isInternal) {
          title = `${data.userSender?.fullName ?? ''}, ${data.userSender?.companyExtension}`;
        }

        showNotification(
          title as string,
          data.type === MESSAGES.TYPE.TEXT ? (data.text as string) : 'Attachment',
        );
      }
    });

    return function cleanup() {
      socketService.socket?.removeListener(SOCKETIO.EVENTS.NEW_MSG_BROWSER_NOTIFICATION);
    };
  }, [socketService.socket]);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  return <></>;
}
export default BrowserNotifications;

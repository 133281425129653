import React, { useState } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { useFormik } from 'formik';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Grid, Paper } from '@mui/material';

import * as userThunk from '@/pages/dashboard/users/thunk';

import ChangePasswordSchema from '@/helpers/validation-schemas/change-password';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ChangePassword = () => {
  const dispatch = useAppDispatch();

  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState<boolean>(false);

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: () => {},
  });

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangePassword = async () => {
    const result = await dispatch(
      userThunk.fetchChangePassword({ password: formik.values.password }),
    );
    if (result.payload) {
      formik.setTouched({ password: false, confirmPassword: false });
      formik.setValues(initialValues);
    }
  };

  return (
    <Box display="flex" justifyContent="center" flex={1} m={2} p={2}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flex={1}
        maxWidth="25rem"
        gap={2}
        component={Paper}
        p={2}
      >
        <TextField
          variant="standard"
          required
          label="New password"
          id="password"
          name="password"
          type={showNewPassword ? 'text' : 'password'}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowNewPassword((prevState) => !prevState)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          variant="standard"
          required
          label="Confirm new password"
          id="confirmPassword"
          name="confirmPassword"
          type={showNewConfirmPassword ? 'text' : 'password'}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowNewConfirmPassword((prevState) => !prevState)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showNewConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!formik.isValid}
            onClick={handleChangePassword}
          >
            Change password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;

import React, { Dispatch, SetStateAction } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogTitle, DialogContentText, Button } from '@mui/material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IDisclaimer {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  handleAccept: VoidFunction;
}

const PricingDialogSlide = (props: IDisclaimer) => {
  const { isVisible, setIsVisible, handleAccept } = props;

  const handleClose = (): void => {
    setIsVisible(false);
  };

  return (
    <Dialog open={isVisible} TransitionComponent={Transition} keepMounted maxWidth={false}>
      <DialogTitle>{'Subscription changes will apply to your account immediately'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Do you want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => handleAccept()}>Purchase</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PricingDialogSlide;

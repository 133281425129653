export enum TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export const MESSAGES = {
  SUCCESS: 'Success.',
  SOME_ERROR_OCURRED: 'Some error occurred.',
  GENERAL: {
    CREATED: (context: string) => `${context} created successfully.`,
    SAVED: (context: string) => `${context}  saved successfully.`,
    DELETED: (context: string) => `${context}  deleted successfully.`,
    UPDATED: (context: string) => `${context}  updated successfully.`,
    FAILED_TO: (details: string) => `Failed to ${details}`,
  },
  CONTACT: {
    UPLOADED_SUCCESSFULLY: 'Contacts saved successfully.',
    COPIED: 'Contact copied successfully.',
  },
  USER: {
    AVATAR_SUCCESSFULLY_UPLOADED: 'Avatar successfully uploaded.',
    PASSWORD_SUCCESSFULLY_CHANGED: 'Password successfully changed.',
    PASSWORD_RESET_EMAIL_SENT: 'Password reset email was sent.',
    CLICK_TO_CALL_DESKTOP_APP_REQUEST_EMAIL_SENT:
      'Click-To-Call desktop app request email was sent.',
  },
  TENANT: {
    CREDIT_CARD_ADDED:
      'You credit card added successfully. Soon it will be displayed in cards list.',
  },
};

import React, { Dispatch, SetStateAction, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';

import { IMessageAdvancedSearchData } from '@/interfaces/message';

import { TransitionProps } from '@mui/material/transitions';

import {
  Sports as SportsIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';

import {
  Box,
  Typography,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from '@mui/material';

import { format } from '@/helpers/date/date';

import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';
import { TemplateDialogButton } from '@/components/Dialogs/Template-dialog';

import * as chatsThunk from '@/pages/dashboard/chat/thunk';

import * as styles from './styles';

import MESSAGES from '@/constants/message';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IPricingDialogSlide {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  handleOpenChatFromSearch: (chatId: string, messageId: string) => void;
}

const messageStatusIconColor = (status: string): string => {
  if (MESSAGES.STATUS.FAILED === status) {
    return '#DC143C';
  }
  if (MESSAGES.STATUS.QUEUED === status) {
    return '#FF8C00';
  }
  return 'green';
};

const prepareDisplayData = (
  value: IMessageAdvancedSearchData,
): { title: string; subTitle: string; avatar: string } => {
  const { isInternal, trackType, userSender, user, from, contact } = value;

  let title;
  let subTitle;
  let avatar;
  if (isInternal) {
    title = userSender ? `${userSender?.firstName} ${userSender?.lastName}` : 'DELETED ACCOUNT';
    subTitle = userSender?.companyExtension;
    avatar = userSender?.avatarUrl;
  }

  if (!isInternal && trackType === MESSAGES.TRACK_TYPE.OUTBOUND) {
    title = user ? `${user.firstName} ${user.lastName ?? ''}` : 'DELETED ACCOUNT';
    subTitle = '';
    avatar = user?.avatarUrl;
  }
  if (!isInternal && trackType === MESSAGES.TRACK_TYPE.INBOUND) {
    title = contact ? `${contact.firstName} ${contact.lastName ?? ''}` : from;
    subTitle = contact?.phoneNumber ?? from;
    avatar = contact?.avatarUrl;
  }

  return {
    title: title as string,
    subTitle: subTitle as string,
    avatar: avatar ?? DefaultAvatar,
  };
};

const AdvancedSearch = (props: IPricingDialogSlide) => {
  const { isVisible, setIsVisible, handleOpenChatFromSearch } = props;
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState<string>('');
  const [results, setResults] = useState<IMessageAdvancedSearchData[]>([]);

  const handleSearch = async (value?: string | undefined): Promise<void> => {
    setSearchValue(value ?? '');

    if (value && value.length > 2) {
      const { payload } = await dispatch(chatsThunk.fetchAdvancedSearch(value));

      if (payload) {
        setResults(payload);
      }
    } else {
      setResults([]);
    }
  };

  const handleClose = (): void => {
    setIsVisible(false);
  };

  return (
    <Dialog
      open={isVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth={'md'}
      fullWidth
    >
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1} position="relative">
          <TextField
            fullWidth
            type="search"
            id="outlined-basic"
            label="Search messages..."
            variant="outlined"
            value={searchValue}
            onChange={(event) => handleSearch(event.target.value)}
          />

          <Typography sx={styles.Typography1SX}>{results.length} results</Typography>

          <List
            disablePadding
            sx={{
              height: '55vh',
              overflow: 'auto',
            }}
          >
            {results.map((element, index) => {
              const { text, createdAt, status, trackType } = element;
              const { avatar, subTitle, title } = prepareDisplayData(element);

              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => {
                        handleOpenChatFromSearch(element.chatId, element.id);
                        setIsVisible(false);
                      }}
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar src={avatar} />
                  </ListItemAvatar>

                  <Box sx={styles.Box1SX}>
                    <Box sx={styles.Box2SX}>
                      <Typography>{title}</Typography>
                      <Typography color={'grey'} sx={styles.Typography2SX}>
                        {subTitle}
                      </Typography>
                      <Typography sx={styles.Typography3SX}>
                        {format(createdAt, 'yyyy-MM-dd hh:mm a')}
                      </Typography>
                      <Typography sx={styles.Typography4SX}>{trackType}</Typography>
                      <SportsIcon
                        sx={{
                          ...styles.Icon1SX,
                          color: messageStatusIconColor(status),
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography color={'grey'} sx={styles.Typography5SX}>
                        {text}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </DialogContent>

      <DialogActions>
        <TemplateDialogButton color="primary" onClick={handleClose}>
          Close
        </TemplateDialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default AdvancedSearch;

import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';

import { SubsTerm } from '@/types/subs';

import { delay } from '@/helpers/delay';

import {
  ITenantResult,
  ITenantSubsCalculatePriceResult,
  ITenantSubsCheckPaymentResult,
  ITenantSubsQuantityCalcResult,
} from '@/interfaces/tenant';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import Slider from '@mui/material/Slider';

import { ISubscriptionResult } from '@/interfaces/subscriptions/subscriptions';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as profileSubsThunk from '@/pages/profile/subscriptions/thunk';
import * as profileCreditCardsThunk from '@/pages/profile/credit-cards/thunk';
import * as tenantThunk from '@/store/thunk/tenant';

import PricingDialog from '@/components/Dialogs/Pricing/Pricing';
import TemplateTable, { ITemplateTableProps } from '@/components/Template-table/Template-table';

import DisclaimerDialog from './dialogs/Disclaimer';
import CalculationResultDialog from './dialogs/Calculation-result';

import * as styles from './styles';

import useAccess from '@/hooks/use-access';

import SUBS from '@/constants/subscriptions';
import ROUTES from '@/constants/routes';
import PAYMENTS from '@/constants/payments';
import { NO_DATA_VALUE } from '@/constants/layout';
import { ADDONS } from '@/constants/addons';

type ActionType = 'quantity changes' | 'new subs purchase' | 'check payment' | 'top up balance';

const topUpThresholdMarks = [
  {
    value: 10,
    label: '10%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 30,
    label: '30%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 60,
    label: '60%',
  },
  {
    value: 70,
    label: '70%',
  },
  {
    value: 80,
    label: '80%',
  },
  {
    value: 90,
    label: '90%',
  },
];

function topUpThresholdValuetext(value: number): string {
  return `${value}%`;
}

const Subscriptions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAddonAvailable } = useAccess();

  const { tenant } = useAppSelector((state) => state.tenant);
  const { analytics } = useAppSelector((state) => state.analytics);

  const [subscriptions, setSubscriptions] = useState<ISubscriptionResult[]>([]);
  const [selectedSubs, setSelectedSubs] = useState<ISubscriptionResult>();
  const [quantity, setQuantity] = useState<number>(tenant?.subs?.subsCount ?? 1);
  const [topUpAmount, setTopUpAmount] = useState<number | string>(tenant?.subs?.topUp ?? 50);
  const [topUpThreshold, setTopUpThreshold] = useState<number>(tenant?.subs?.topUpThreshold ?? 90);
  const [subsQuantity, setSubsQuantity] = useState<number>(1);
  const [prices, setPrices] = useState<
    | ITenantSubsQuantityCalcResult
    | ITenantSubsCalculatePriceResult
    | ITenantSubsCheckPaymentResult
    | null
  >(null);
  const [isPurchasing, setIsPurchasing] = useState<boolean>(false);
  const [subsTerm, setSubsTerm] = useState<SubsTerm>(SUBS.TERM.ANNUAL);
  const [showPriceDialog, setShowPricingDialog] = useState<boolean>(false);
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [actionType, setActionType] = useState<ActionType>('quantity changes');
  const [hasCreditCards, setHasCreditCards] = useState<boolean>(false);
  const [showCalculationResultDialog, setShowCalculationResultDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [processingPaymentInterval, setProcessingPaymentInterval] = useState<{
    interval: NodeJS.Timeout | null;
    value: number;
  }>({ value: 30, interval: null });

  const getSubscriptions = async (): Promise<void> => {
    const { payload } = await dispatch(profileSubsThunk.fetchSubscriptions());
    if (payload) {
      setSubscriptions(
        payload.filter((element: ISubscriptionResult) => element.id !== tenant?.subs.subsRef),
      );
    }
  };

  const handleSubsQuantityCalculate = async (): Promise<void> => {
    if (!tenant?.subs) {
      return;
    }

    setActionType('quantity changes');

    setIsLoading(true);
    const { payload } = await dispatch(profileSubsThunk.fetchSubsQuantityCalculate({ quantity }));
    if (payload) {
      setPrices(payload);
      if (payload.amount) {
        setShowCalculationResultDialog(true);
      } else {
        setIsDialogVisible(true);
      }
    }

    setIsLoading(false);
  };

  const handleCalculateNewSubs = async (): Promise<void> => {
    if (!selectedSubs) {
      return;
    }
    setIsLoading(true);

    setActionType('new subs purchase');

    const { payload } = await dispatch(
      profileSubsThunk.fetchCalculateSubsPrice({
        id: selectedSubs.id,
        subsTerm: subsTerm,
        quantity: subsQuantity,
      }),
    );
    if (payload) {
      setPrices(payload);
      setShowCalculationResultDialog(true);
    }
    setIsLoading(false);
  };

  const handleUpdateSubsQuantity = async (): Promise<void> => {
    setIsPurchasing(true);

    if (!prices) {
      return;
    }

    const { payload } = await dispatch(
      profileSubsThunk.fetchSubsUpdateQuantity({
        priceCalculationId: prices.priceCalculationId,
      }),
    );

    if (payload) {
      await dispatch(tenantThunk.fetchTenantInfo());
      setPrices(null);
    }

    setIsPurchasing(false);
  };

  const handlePurchaseSubs = async (): Promise<void> => {
    if (!prices) {
      return;
    }

    setIsPurchasing(true);

    const { payload } = await dispatch(
      profileSubsThunk.fetchPurchaseSubs({
        priceCalculationId: prices.priceCalculationId,
      }),
    );
    if (payload) {
      dispatch(tenantThunk.fetchTenantInfo());
      setSelectedSubs(undefined);
      setPrices(null);
    }
    setIsPurchasing(false);
  };

  const handleUpdateQuantity = (value: number): void => {
    setPrices(null);
    setQuantity(value < 1 ? 1 : value);
  };

  const handleTopUpUpdates = (value: string): void => {
    setPrices(null);
    setTopUpAmount(value);
  };

  const handleSelectSub = (subName: string): void => {
    const sub = subscriptions.find((element) => element.name === subName) as ISubscriptionResult;
    setSelectedSubs(sub);
    if (sub.isRatesApplicable) {
      setSubsTerm(SUBS.TERM.MONTHLY);
    }
  };

  const handleSubsQuantity = (value: number): void => {
    const newSubsQuantity = value < 1 ? 1 : value;
    setSubsQuantity(newSubsQuantity);
  };

  const handlePaymentsType = (value: SubsTerm): void => {
    setSubsTerm(value);
  };

  const handleAccept = async (): Promise<void> => {
    setIsDialogVisible(false);
    if (actionType === 'quantity changes') {
      await handleUpdateSubsQuantity();
    }
    if (actionType === 'new subs purchase') {
      await handlePurchaseSubs();
    }
    if (actionType === 'check payment') {
      await handleProceedPayment();
    }
    if (actionType === 'top up balance') {
      await handleProceedPayment();
    }
  };

  const handleShowDisclaimer = (type: ActionType): void => {
    setIsDialogVisible(true);
    setActionType(type);
  };

  const handleSubsCheckPayment = async (): Promise<void> => {
    if (!hasCreditCards) {
      dispatch(snackbarReducer.showWarning('Please add Credit Card first'));
      navigate(ROUTES.PROFILE_CREDIT_CARDS);
    }

    setActionType('check payment');

    const { payload } = await dispatch(profileSubsThunk.fetchSubsCheckPayment());
    if (payload) {
      setPrices(payload);
      setShowCalculationResultDialog(true);
    }
  };

  const handleSubsTopUpBalance = async (): Promise<void> => {
    if (!hasCreditCards) {
      dispatch(snackbarReducer.showWarning('Please add Credit Card first'));
      navigate(ROUTES.PROFILE_CREDIT_CARDS);
    }

    setActionType('top up balance');

    const { payload } = await dispatch(profileSubsThunk.fetchSubsTopUpBalance());
    if (payload) {
      setPrices(payload);
      setShowCalculationResultDialog(true);
    }
  };

  const handleProceedPayment = async (): Promise<void> => {
    if (!prices) {
      return;
    }
    setIsPurchasing(true);

    const { payload } = await dispatch(
      profileSubsThunk.fetchSubsProceedPayment({ priceCalculationId: prices.priceCalculationId }),
    );

    if (payload) {
      if (payload.paymentStatus === PAYMENTS.STATUSES.COMPLETED) {
        setPrices(null);
        dispatch(snackbarReducer.showSuccess('We are processing your payment. Please wait...'));
        await delay(15_000);
        await dispatch(tenantThunk.fetchTenantInfo());
      }
      if (payload.paymentStatus === PAYMENTS.STATUSES.ERROR) {
        dispatch(
          snackbarReducer.showWarning(
            'Error while processing payment. Please check your card balance...',
          ),
        );
      }
    }

    setIsPurchasing(false);
  };

  const handleSetTopUp = async (): Promise<void> => {
    const { meta } = await dispatch(
      profileSubsThunk.fetchSetSubsTopUp({ topUpThreshold, topUp: topUpAmount as number }),
    );
    if (meta.requestStatus === 'rejected') {
      return;
    }

    await dispatch(tenantThunk.fetchTenantInfo());
  };

  const getChangeableFields = (data: ITenantResult['subs']) => {
    if (data.isRatesApplicable) {
      return [
        {
          header: 'Balance',
          getValue: (el: ITenantResult['subs']) =>
            el.isRatesApplicable ? (
              <Box display={'flex'} alignItems={'center'}>
                <Typography m={0} mr={1}>
                  ${el.balance}
                </Typography>

                <Button variant="outlined" onClick={handleSubsTopUpBalance}>
                  Top Up Now
                </Button>
              </Box>
            ) : (
              '-'
            ),
        },
      ];
    }
    if (data.isLimitsApplicable) {
      return [
        {
          header: 'SMS Left',
          getValue: (el: ITenantResult['subs']) =>
            el.isLimitsApplicable ? `${el.usage?.sms}` : '-',
        },
        {
          header: 'Minutes Left',
          getValue: (el: ITenantResult['subs']) =>
            el.isLimitsApplicable ? `${el.usage?.minutes}` : '-',
        },
      ];
    }
    return [
      {
        header: 'Balance',
        getValue: (el: ITenantResult['subs']) => (el.isRatesApplicable ? `$${el.balance}` : '-'),
      },
      {
        header: 'SMS Left',
        getValue: (el: ITenantResult['subs']) => (el.isLimitsApplicable ? `${el.usage?.sms}` : '-'),
      },
      {
        header: 'Minutes Left',
        getValue: (el: ITenantResult['subs']) =>
          el.isLimitsApplicable ? `${el.usage?.minutes}` : '-',
      },
    ];
  };

  const handleHasCreditCards = async (): Promise<void> => {
    const { payload } = await dispatch(profileCreditCardsThunk.fetchCreditCards());
    setHasCreditCards(!!payload?.length);
  };

  const templateTableProps: ITemplateTableProps<ITenantResult['subs']> = {
    data: tenant?.subs ? [tenant.subs] : [],
    getDataEntityId: (el) => el?.subsRef,
    columns: [
      {
        header: 'Name',
        getValue: (el) => el.name,
      },
      {
        header: 'Status',
        getValue: (el) => (el.isActive ? 'ACTIVE' : 'INACTIVE'),
      },
      {
        header: 'Seats',
        getValue: (el) => el.subsCount,
      },
      {
        header: 'Monthly billing amount',
        getValue: (el) => `$${el.monthlyPaymentAmount}`,
      },
      ...getChangeableFields(tenant?.subs as ITenantResult['subs']),
      {
        header: 'End of Contract',
        getValue: (el) =>
          (el.subsTerm as SUBS.TERM) === SUBS.TERM.ANNUAL
            ? `${el.endDate.split('T')[0]} CDT`
            : 'Not Applicable',
      },
      {
        sharedProps: {
          align: 'center',
          sx: {
            py: '.25rem',
            maxWidth: '8rem',
          },
        },
        header: 'Action',
        getValue: () => (
          <Box>
            <Button
              disabled={tenant?.subs?.isActive}
              variant="contained"
              color="secondary"
              onClick={handleSubsCheckPayment}
            >
              <Typography fontWeight={'bold'}>Pay subscription</Typography>
            </Button>
          </Box>
        ),
      },
    ],
  };

  const handleRefresh = async (): Promise<void> => {
    setIsLoading(true);
    await dispatch(tenantThunk.fetchTenantInfo());
    setIsLoading(false);
  };

  const handleProcessingPaymentInterval = (): void => {
    const interval = setInterval(() => {
      setProcessingPaymentInterval((current) => ({ ...current, value: current.value - 1 }));
    }, 1000);
    setProcessingPaymentInterval((current) => ({ ...current, interval }));
  };

  useEffect(() => {
    if (isPurchasing) {
      handleProcessingPaymentInterval();
    }
    if (!isPurchasing && processingPaymentInterval.interval) {
      clearInterval(processingPaymentInterval.interval);
      setProcessingPaymentInterval({ value: 30, interval: null });
    }
  }, [isPurchasing]);

  useEffect(() => {
    if (processingPaymentInterval.value === 0 && processingPaymentInterval.interval) {
      clearInterval(processingPaymentInterval.interval);
      setProcessingPaymentInterval({ value: 30, interval: null });
    }
  }, [processingPaymentInterval]);

  useEffect(() => {
    getSubscriptions();
    handleHasCreditCards();
  }, [tenant?.subs]);

  useEffect(() => {
    if (!selectedSubs) {
      return;
    }
    const pricingTermToSubsTerm = {
      [SUBS.PRICING_TERM.ALL]: SUBS.TERM.ANNUAL,
      [SUBS.PRICING_TERM.ANNUAL]: SUBS.TERM.ANNUAL,
      [SUBS.PRICING_TERM.MONTHLY]: SUBS.TERM.MONTHLY,
    };

    setSubsTerm(pricingTermToSubsTerm[selectedSubs.pricingTerm as SUBS.PRICING_TERM]);
  }, [selectedSubs]);

  return (
    <Box width={'100%'}>
      <DisclaimerDialog
        isVisible={isDialogVisible}
        setIsVisible={setIsDialogVisible}
        handleAccept={handleAccept}
      />

      <CalculationResultDialog
        isVisible={showCalculationResultDialog}
        setIsVisible={setShowCalculationResultDialog}
        handleAccept={() => handleShowDisclaimer(actionType)}
        amount={prices?.amount}
        safetyDepositAmount={prices?.safetyDepositAmount}
        subTotal={prices?.subTotal}
        total={prices?.total}
        taxes={prices?.taxes}
        topUp={prices?.topUp}
        description={prices?.description}
      />

      <PricingDialog isVisible={showPriceDialog} setIsVisible={setShowPricingDialog} />

      <Grid container rowGap={1} rowSpacing={2} justifyContent={'center'} p={4}>
        {tenant?.subs && (
          <>
            <Grid
              item
              xs={12}
              flexDirection={'column'}
              component={Paper}
              borderRadius={5}
              py={2}
              px={3}
            >
              {isPurchasing && (
                <Box display={'flex'} flexDirection={'row'}>
                  <CircularProgress
                    size={20}
                    disableShrink={true}
                    thickness={10}
                    sx={{ color: 'primary.main', mr: 1 }}
                  />
                  <Typography>{processingPaymentInterval.value}...</Typography>
                </Box>
              )}
              <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Typography sx={styles.Typography4SX}>Subscription:</Typography>
                  <Tooltip title={'Refresh'}>
                    <IconButton onClick={handleRefresh}>
                      <CachedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Button variant="text" color="secondary" onClick={() => setShowPricingDialog(true)}>
                  <Typography sx={styles.Typography2SX}>Check pricing</Typography>
                </Button>
              </Box>
              {tenant.subs.isRatesApplicable ? (
                <Typography mb={1} fontSize={13}>
                  Calls and Messages will stop working when balance reaches 95% and auto top-up
                  based on minimum threshold is not successful.
                </Typography>
              ) : null}

              <TemplateTable {...templateTableProps} />

              {isAddonAvailable(ADDONS.ANALYTICS) && (
                <>
                  <Box display={'flex'} alignItems={'center'} mt={1}>
                    <Typography sx={styles.Typography4SX}>Stats:</Typography>
                  </Box>
                  <Box display={'flex'} flexDirection={'row'}>
                    <Typography mr={2}>Calls: {analytics?.callsCount ?? NO_DATA_VALUE}</Typography>
                    <Typography>Messages: {analytics?.messagesCount ?? NO_DATA_VALUE}</Typography>
                  </Box>
                </>
              )}
            </Grid>

            {tenant.subs?.isFirstPaymentDone &&
              tenant.subs?.isActive &&
              tenant.subs?.isRatesApplicable && (
                <Grid item xs={12} component={Paper} elevation={1} borderRadius={5} py={2} px={3}>
                  <Typography sx={styles.Typography4SX}>Top Up:</Typography>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box width={'60%'}>
                      <InputLabel>Automatic Top Up Threshold:</InputLabel>
                      <Slider
                        min={10}
                        max={90}
                        defaultValue={tenant.subs.topUpThreshold}
                        getAriaValueText={topUpThresholdValuetext}
                        step={10}
                        valueLabelDisplay="auto"
                        marks={topUpThresholdMarks}
                        onChange={(_, value) => {
                          if (typeof value === 'number') {
                            setTopUpThreshold(value);
                          }
                        }}
                      />
                    </Box>
                    <Box width={'35%'}>
                      <InputLabel>Top Up Amount ($):</InputLabel>
                      <TextField
                        onChange={(e) => handleTopUpUpdates(e.target.value)}
                        variant="standard"
                        value={topUpAmount}
                        type="number"
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Button
                    color="inherit"
                    variant="contained"
                    disabled={!topUpAmount || Number.isNaN(+topUpAmount)}
                    onClick={handleSetTopUp}
                    sx={{ mt: 1 }}
                  >
                    Save
                  </Button>
                </Grid>
              )}

            {tenant.subs?.isFirstPaymentDone && tenant.subs?.isActive && (
              <Grid item xs={12} component={Paper} elevation={1} borderRadius={5} py={2} px={3}>
                <Typography sx={styles.Typography4SX}>Update quantity:</Typography>

                <Box>
                  <InputLabel>Quantity:</InputLabel>
                  <TextField
                    onChange={(e) => handleUpdateQuantity(+e.target.value)}
                    variant="standard"
                    value={quantity}
                    type="number"
                    fullWidth
                  />
                </Box>

                <Button
                  disabled={tenant?.subs.subsCount === quantity || isLoading}
                  color="inherit"
                  variant="contained"
                  onClick={handleSubsQuantityCalculate}
                  sx={{ mt: 1 }}
                >
                  Calculate
                </Button>
              </Grid>
            )}

            {tenant.subs?.isFirstPaymentDone && tenant.subs?.isActive && (
              <Grid item xs={12} component={Paper} borderRadius={5} py={2} px={3}>
                <Typography sx={styles.Typography4SX}>Change subscription:</Typography>

                <InputLabel sx={styles.InputLabel4SX}>Subscription:</InputLabel>
                <FormControl fullWidth>
                  <Select
                    value={selectedSubs?.name ?? ''}
                    variant="standard"
                    onChange={(e) => handleSelectSub(e.target.value)}
                  >
                    {subscriptions.map((element) => {
                      return (
                        <MenuItem
                          selected={tenant?.subs?.name === element.name}
                          key={element.id}
                          value={element.name}
                        >
                          {element.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <Box display={'flex'} flexDirection={'column'} justifyContent={'end'}>
                  <InputLabel sx={styles.InputLabel4SX}>Quantity:</InputLabel>
                  <TextField
                    onChange={(e) => handleSubsQuantity(+e.target.value)}
                    variant="standard"
                    value={subsQuantity}
                    type="number"
                    fullWidth
                  />
                </Box>
                <Box sx={styles.Box7SX}>
                  <InputLabel sx={styles.InputLabel4SX}>Subscription term:</InputLabel>
                  <FormControl disabled={selectedSubs?.pricingTerm !== SUBS.PRICING_TERM.ALL}>
                    <RadioGroup
                      row
                      defaultValue={SUBS.TERM.ANNUAL}
                      value={subsTerm}
                      onChange={(e) => handlePaymentsType(e.target.value as SubsTerm)}
                    >
                      <FormControlLabel
                        value={SUBS.TERM.MONTHLY}
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        value={SUBS.TERM.ANNUAL}
                        control={<Radio />}
                        label="Annual"
                      />
                    </RadioGroup>
                  </FormControl>

                  <Typography sx={styles.Typography5SX}>
                    {subsTerm === SUBS.TERM.ANNUAL
                      ? 'Annual commitments apply'
                      : 'Monthly commitment'}
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems={'end'}>
                  <Button
                    color="inherit"
                    variant="contained"
                    disabled={isPurchasing || isLoading || !selectedSubs}
                    onClick={() => handleCalculateNewSubs()}
                    sx={{ mt: 1 }}
                  >
                    Calculate
                  </Button>
                </Box>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Subscriptions;

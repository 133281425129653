import { FunctionComponent, useEffect, useRef, useState } from 'react';

import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';

import { IIpResult } from '@/interfaces/ip';

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { format } from '@/helpers/date/date';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import TwoFADialog from '@/components/Dialogs/Two-fa';
import TemplateTable, { ITemplateTableProps } from '@/components/Template-table/Template-table';

import AddEditIpDialog from './Add-edit-ip-dialog';

import * as profileSaThunk from './thunk';

import { ROLES } from '@/constants/roles';
import TWOFA from '@/constants/2fa';

const Ips: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.user);

  const [ips, setIps] = useState<IIpResult[]>([]);
  const [search, setSearch] = useState<string>('');
  const [showTwoFADialog, setSetShowTwoFADialog] = useState<boolean>(false);
  const [selectedIp, setSelectedIp] = useState<IIpResult>();
  const [twoFADialogHandler, setTwoFADialogHandler] = useState<() => void>(() => {});
  const [showAddEditIpDialog, setShowAddEditIpDialog] = useState<boolean>(false);
  const selectedIpRef = useRef(selectedIp);

  if (user?.role !== ROLES.SUPER_ADMIN) {
    return <></>;
  }

  const handleGetIps = async (): Promise<void> => {
    const { meta, payload } = await dispatch(profileSaThunk.fetchGetAll());
    if (meta.requestStatus === 'rejected') {
      return;
    }

    setIps(payload);
  };

  const handleShowTwoFA = async (
    ipRecord: IIpResult,
    handler: (twoFACode: string) => Promise<void>,
  ): Promise<void> => {
    setSelectedIp(ipRecord);
    setTwoFADialogHandler(() => handler);

    setSetShowTwoFADialog(true);
  };

  const handleUpdateIsActive = async (twoFACode: string): Promise<void> => {
    if (!selectedIpRef.current) {
      return;
    }
    const currentSelectedIp = selectedIpRef.current;

    setSetShowTwoFADialog(false);

    const { meta } = await dispatch(
      profileSaThunk.updateSaAllowedIp({
        id: selectedIpRef.current.id,
        updates: { isActive: !currentSelectedIp.isActive, twoFA: twoFACode },
      }),
    );
    if (meta.requestStatus === 'fulfilled') {
      setIps((current) =>
        current.map((element) => {
          if (element.id === currentSelectedIp.id) {
            element.isActive = !element.isActive;
          }
          return element;
        }),
      );
      dispatch(snackbarReducer.showSuccess('Super Admin allowed ip was updated.'));
    }
    setSelectedIp(undefined);
  };

  const handleDeleteIp = async (twoFACode: string): Promise<void> => {
    if (!selectedIpRef.current) {
      return;
    }
    const currentSelectedIp = selectedIpRef.current;

    setSetShowTwoFADialog(false);

    const { meta } = await dispatch(
      profileSaThunk.deleteSaAllowedIp({
        id: currentSelectedIp.id,
        updates: { twoFA: twoFACode },
      }),
    );
    if (meta.requestStatus === 'fulfilled') {
      setIps((current) => current.filter((element) => element.id !== currentSelectedIp.id));
      dispatch(snackbarReducer.showSuccess('Super Admin allowed ip was deleted.'));
    }
    setSelectedIp(undefined);
  };

  const templateTableProps: ITemplateTableProps<IIpResult> = {
    data: search
      ? ips.filter((element) => {
          return element.ip.includes(search) || element.description?.includes(search.toLowerCase());
        })
      : ips,
    getDataEntityId: (el) => el.id,
    columns: [
      {
        header: 'IP',
        getValue: (el) => el.ip,
      },
      {
        header: 'ON/OFF',
        getValue: (el) => (
          <Switch
            color="primary"
            checked={el.isActive}
            onChange={() => handleShowTwoFA(el, handleUpdateIsActive)}
          />
        ),
      },
      {
        header: 'Description',
        getValue: (el) => el.description,
      },
      {
        header: 'Modified At',
        getValue: (el) => format(el.updatedAt, 'yyyy-MM-dd hh:mm a'),
      },
      {
        sharedProps: { align: 'center', sx: { py: '.25rem', width: '4rem' } },
        header: 'Action',
        getValue: (el) => (
          <Box display="flex">
            <IconButton
              color="primary"
              onClick={() => {
                setSelectedIp(el);
                setShowAddEditIpDialog(true);
              }}
            >
              <EditIcon />
            </IconButton>

            <IconButton color="primary" onClick={() => handleShowTwoFA(el, handleDeleteIp)}>
              <DeleteForeverIcon color="error" />
            </IconButton>
          </Box>
        ),
      },
    ],

    tableContainerProps: { sx: { height: 'calc(100vh - 25rem)' } },
  };

  const handleCloseAddEditDialog = async (): Promise<void> => {
    setShowAddEditIpDialog(false);
    await handleGetIps();
    setSelectedIp(undefined);
  };

  const handleCloseTwoFADialog = (): void => {
    setSetShowTwoFADialog(false);
    setSelectedIp(undefined);
  };

  useEffect(() => {
    handleGetIps();
  }, []);

  useEffect(() => {
    selectedIpRef.current = selectedIp;
  }, [selectedIp]);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      component={Paper}
      p={2}
      gap={2}
      width={'100%'}
    >
      <AddEditIpDialog
        isOpened={showAddEditIpDialog}
        handleClose={() => handleCloseAddEditDialog()}
        ipRecord={selectedIp}
      />

      <TwoFADialog
        show={showTwoFADialog}
        handleClose={handleCloseTwoFADialog}
        type={TWOFA.TYPES.SA_ALLOWED_IP}
        handler={twoFADialogHandler}
      />

      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="h6">IPs</Typography>
      </Box>

      <Divider />

      <Box display={'flex'} justifyContent={'space-between'}>
        <TextField
          id="standard-basic"
          label="Search"
          variant="outlined"
          type="search"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          sx={{ maxWidth: '20rem', flexGrow: 1 }}
        />
        <IconButton color="primary" onClick={() => setShowAddEditIpDialog(true)}>
          <AddIcon />
        </IconButton>
      </Box>

      <TemplateTable {...templateTableProps} />
    </Box>
  );
};

export default Ips;

export default {
  GENERAL: {
    INTERNAL_SERVER_ERROR: 'Internal server error.',
    CONFLICT: 'Conflict.',
    FORBIDDEN: 'Forbidden.',
    NOT_FOUND: 'Not found.',
    INVALID_FORMAT: 'Invalid format.',
    UNAUTHORIZED: 'Unauthorized.',
    BAD_REQUEST: 'Bad request.',
  },
  FILES: {
    MAX_FILES_COUNT: 'Only up to $count files allowed.',
    MAX_FILES_SIZE: 'Max file size should be less then $size.',
    UNSUPPORTED_FILE_TYPE: 'Unsupported file type.',
  },
  VOICE: {
    OUTBOUND_CALL: 'Can not start a call. Please contact support.',
    INVALID_PHONE_NUMBER: 'Invalid phone number/company extension.',
    INVALID_COMPANY_EXTENSION: 'Invalid company extension.',
    CALL_TO_OWN_COMPANY_EXT: 'You are trying to reach out to your own company extension.',
    MAX_CALLS_NUMBER_REACHED: 'You have reached your max active calls threshold.',
    CAN_NOT_USE_COMPANY_PHONE_NUMBER:
      'Permission denied. You are not allowed to use company phone number. Please use your Direct extension.',
  },
  VOICE_SETTINGS: {
    INVALID_AFTER_HOURS_PHONE_NUMBER: 'Invalid after hours forwarding phone number.',
    INVALID_WORKING_HOURS_PHONE_NUMBER: 'Invalid working hours forwarding phone number.',
  },
  PAYMENTS: {
    PAYMENT_ERROR: 'Some error occurred...',
  },
};

import * as authReducer from '@/store/reducers/auth';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as tenantReducer from '@/store/reducers/tenant';
import * as userReducer from '@/store/reducers/user';
import * as twilioReducer from '@/store/reducers/twilio';

import ERRORS from '@/constants/errors';

const handleError = (
  thunkAPI: any,
  statusCode: number,
  error?:
    | { barType: 'success' | 'error' | 'warning' | 'info'; message: string }
    | { success: boolean; message?: string; statusCode: number; data: any },
  response?: { success: boolean; message: string; statusCode: number; data: any },
) => {
  const snackbarMsg = error?.message;

  if (statusCode === 401) {
    localStorage.clear();

    const { twilio: twilioState } = thunkAPI.getState();

    if (twilioState.device && twilioState.device.state === 'registered') {
      twilioState.device.unregister();
    }

    thunkAPI.dispatch(authReducer.reset());
    thunkAPI.dispatch(twilioReducer.reset());
    thunkAPI.dispatch(tenantReducer.reset());
    thunkAPI.dispatch(userReducer.reset());
    thunkAPI.dispatch(snackbarReducer.showError(snackbarMsg || ERRORS.GENERAL.UNAUTHORIZED));
  } else {
    thunkAPI.dispatch(snackbarReducer.showError(snackbarMsg));
  }
};

export default handleError;

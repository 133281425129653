import { Dispatch, SetStateAction } from 'react';

import { IGetIotDevicesGroupsQueryParams } from '@/interfaces/iot-devices-groups';

import { IconButton, Stack, TextField, Tooltip } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

interface IIotDevicesGroupsHeaderProps {
  queryParams: IGetIotDevicesGroupsQueryParams;
  setQueryParams: Dispatch<SetStateAction<IGetIotDevicesGroupsQueryParams>>;
  handleCreate: VoidFunction;
}

const IotDevicesGroupsHeader = ({
  queryParams,
  setQueryParams,
  handleCreate,
}: IIotDevicesGroupsHeaderProps) => {
  const handleUpdateQueryParams = (params: Partial<IGetIotDevicesGroupsQueryParams>) =>
    setQueryParams((prevState) => ({
      ...prevState,
      ...params,
      skip: 0,
    }));

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" px={2} pb={1}>
      <TextField
        fullWidth
        sx={{ maxWidth: '20rem' }}
        type="search"
        label="Search"
        variant="outlined"
        size="small"
        value={queryParams.search}
        onChange={(e) => handleUpdateQueryParams({ search: e.target.value })}
        onBlur={(e) => handleUpdateQueryParams({ search: e.target.value })}
      />

      <Tooltip title="Add new users group" placement="right">
        <IconButton color="primary" onClick={handleCreate}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default IotDevicesGroupsHeader;

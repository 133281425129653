export const Box3SX = {
  alignItems: { xs: 'flex-start', sm: 'flex-start', md: 'center' },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
};

export const Box6SX = {
  display: 'flex',
  alignItems: { xs: 'flex-start', md: 'center' },
  flexDirection: 'column',
};

export const Box7SX = {
  mt: 2,
  mb: 2,
};

export const Box10SX = {
  height: 10,
};

export const InputLabel1SX = {
  fontSize: 15,
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: 'bold',
};

export const InputLabel2SX = {
  fontSize: 15,
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: 'bold',
};

export const TextField1SX = {
  '& .MuiFormLabel-root': {
    fontSize: 20,
    fontWeight: 'bold',
  },
  width: 70,
};

export const Typography1SX = {
  fontSize: 10,
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: 'bold',
};

export const Typography2SX = {
  fontSize: 15,
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: 'bold',
};

export const Typography3SX = {
  fontSize: 15,
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: 'bold',
};

export const Typography4SX = {
  fontSize: 20,
  fontStyle: 'italic',
};

export const Typography5SX = {
  textDecoration: 'underline',
  fontSize: 13,
};

export const Typography6SX = {
  fontSize: 12.5,
  fontWeight: 'bold',
};

export const Typography7SX = {
  fontSize: 20,
  fontStyle: 'italic',
};

export const Icon4SX = {
  width: 50,
  height: 50,
};

export const Icon1SX = {
  fontSize: 15,
  color: 'grey',
};

export const Button1SX = {
  mr: 1,
};

export const Button2SX = {
  height: 20,
};

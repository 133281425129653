import makeRequest from '../make-request';

import token from '../token';

const GET_ALL = '/api/v1/permissions';

export const getAll = async () => {
  try {
    const result = await makeRequest(GET_ALL, {
      method: 'GET',
      headers: { Authorization: await token() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

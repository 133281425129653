import { FunctionComponent } from 'react';
import { Dialog, DialogContent, DialogActions, Avatar, Box, Typography } from '@mui/material';

import { Phone as PhoneIcon } from '@mui/icons-material';

import { IMessageResult } from '@/interfaces/message';

import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';
import useAccess from '@/hooks/use-access';

import { TemplateDialogButton } from '@/components/Dialogs/Template-dialog';

import MESSAGES from '@/constants/message';
import { FEATURES } from '@/constants/features';
import { useAppSelector } from '@/store/hooks';

export interface IMessageSenderReceiverDialogProps {
  isOpened: boolean;
  message: IMessageResult;
  handleClose: VoidFunction;
  handleCall: (data: {
    phoneNumber: string | null | undefined;
    companyExtension: string | null | undefined;
    directExtension: string | null | undefined;
  }) => void;
}

const prepareMessageInfo = (message: IMessageResult) => {
  const { isInternal, trackType, userSender, user, contact, from } = message;

  let displayName;
  let companyExtension;
  let directExtension;
  let phoneNumber;
  let avatarUrl;

  if (isInternal) {
    displayName = userSender ? userSender.fullName : 'DELETED ACCOUNT';
    companyExtension = userSender?.companyExtension;
    avatarUrl = userSender?.avatarUrl;
  }

  if (!isInternal && trackType === MESSAGES.TRACK_TYPE.INBOUND) {
    displayName = contact ? contact.fullName : '';
    phoneNumber = contact?.phoneNumber ?? from;
    avatarUrl = contact ? contact?.avatarUrl : null;
  }
  if (!isInternal && trackType === MESSAGES.TRACK_TYPE.OUTBOUND) {
    displayName = user ? user.fullName : 'DELETED ACCOUNT';
    companyExtension = user?.companyExtension;
    directExtension = user?.directExtension;
    avatarUrl = user ? user?.avatarUrl : null;
  }

  return {
    displayName,
    companyExtension,
    directExtension,
    phoneNumber,
    avatarUrl,
  };
};

const MessageSenderReceiverDialog: FunctionComponent<IMessageSenderReceiverDialogProps> = ({
  isOpened,
  message,
  handleClose,
  handleCall,
}): JSX.Element => {
  const user = useAppSelector((state) => state.user.user);

  const { isFeatureAvailable } = useAccess();

  if (!message) {
    return <></>;
  }

  const { displayName, companyExtension, directExtension, phoneNumber, avatarUrl } =
    prepareMessageInfo(message);

  return (
    <Dialog open={isOpened} maxWidth="xs">
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems="center"
          sx={{ minWidth: '16rem' }}
        >
          <Avatar src={avatarUrl ?? DefaultAvatar} sx={{ width: 75, height: 75 }} />

          <Box display="flex" flexDirection="column" gap={1} alignItems="center">
            {displayName ? <Typography>{displayName}</Typography> : null}

            {phoneNumber ? <Typography>{phoneNumber}</Typography> : null}

            {companyExtension || directExtension ? (
              <Typography>{companyExtension || directExtension}</Typography>
            ) : null}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <TemplateDialogButton
          color="success"
          fullWidth
          disabled={
            !isFeatureAvailable(FEATURES.VOICE) ||
            user?.directExtension === directExtension ||
            user?.directExtension === phoneNumber ||
            user?.companyExtension === companyExtension ||
            displayName === 'DELETED ACCOUNT'
          }
          startIcon={<PhoneIcon />}
          onClick={() => handleCall({ phoneNumber, companyExtension, directExtension })}
        >
          Call
        </TemplateDialogButton>

        <TemplateDialogButton fullWidth color="primary" onClick={handleClose}>
          Close
        </TemplateDialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default MessageSenderReceiverDialog;

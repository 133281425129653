import * as devicesService from '../devices/microphone';

import { IConnection } from '@/interfaces/voice/call';

import * as voiceReducer from '@/store/reducers/dashboard/voice';
import { AppDispatch, store } from '@/store/store';

import * as twilioMakeCallService from '../twilio/make-call';

import PROVIDERS from '@/constants/providers';
import CALL from '@/constants/voice/voice';

export const handleInitCall = async (data: {
  dispatch: AppDispatch;
  to: string;
}): Promise<void> => {
  const { dispatch, to } = data;
  const state = store.getState();
  const { tenant: tenantState } = state;

  const { activeProvider } = tenantState;

  if (!(await devicesService.isMicrophoneEnabled())) {
    dispatch(voiceReducer.setShowEnableMicrophoneDialog(true));
    dispatch(voiceReducer.setCallToContact(null));
    return;
  }

  if (to === '911') {
    dispatch(voiceReducer.setShowEmergencyCallDialog(true));
    return;
  }

  if (activeProvider?.name === PROVIDERS.TWILIO) {
    await twilioMakeCallService.makeTwilioCall({
      dispatch,
      input: to,
    });
  }
};

export const handleInitEmergencyCall = async (dispatch: AppDispatch): Promise<void> => {
  const state = store.getState();
  const { tenant: tenantState } = state;
  const { activeProvider } = tenantState;

  if (!(await devicesService.isMicrophoneEnabled({ dispatch }))) {
    dispatch(voiceReducer.setShowEnableMicrophoneDialog(true));
    dispatch(voiceReducer.setCallToContact(null));
    return;
  }

  if (activeProvider?.name === PROVIDERS.TWILIO) {
    await twilioMakeCallService.makeTwilioCall({
      dispatch,
      input: '911',
    });
  }
};

export const startCallDurationCounter = async (data: {
  uuid: string;
  dispatch: AppDispatch;
}): Promise<void> => {
  const { dispatch, uuid } = data;

  const state = store.getState();
  const { voice: voiceState } = state;
  const { connections } = voiceState;

  const existConnection = connections.find((connection) => connection.uuid === uuid);

  if (!existConnection?.counter) {
    dispatch(voiceReducer.setCounter({ uuid, counter: { minutes: 0, seconds: 0 } }));

    const intervalFn = setInterval(() => {
      const state = store.getState();
      const { voice: voiceState } = state;
      const { connections } = voiceState;

      let counter: { minutes: number; seconds: number } = { minutes: 0, seconds: 0 };

      const connection = connections.find((connection) => connection.uuid === uuid);

      if (connection && connection.counter) {
        counter = {
          seconds: connection.counter.seconds,
          minutes: connection.counter.minutes,
        };
      }

      const nextSecond = counter.seconds + 1;
      if (nextSecond === 60) {
        counter.minutes = counter.minutes + 1;
        counter.seconds = 0;
      } else {
        counter.seconds = counter.seconds + 1;
      }

      dispatch(voiceReducer.setCounter({ uuid, counter }));
    }, 1000);

    dispatch(voiceReducer.setCounterFunction({ uuid, counterFunction: intervalFn }));
  }
};

export const getInProgressCall = (): IConnection | undefined => {
  const state = store.getState();
  const { voice: voiceState } = state;

  const { connections } = voiceState;
  return connections.find((connection) => !connection.onHold && connection.answered);
};

export const getInternalOnHoldCall = () => {
  const state = store.getState();
  const { voice: voiceState } = state;

  const { connections } = voiceState;
  return connections.find(
    (connection) => connection.connectionType === CALL.CONNECTION_TYPES.INTERNAL,
  );
};

export const isCallOnHold = (uuid?: string): boolean => {
  const state = store.getState();
  const { voice: voiceState } = state;
  const { connections } = voiceState;

  if (uuid) {
    return connections.some((connection) => connection.uuid === uuid && connection.onHold);
  }

  return connections.some((connection) => connection.selected && connection.onHold);
};

export const isCompanyExtensionCall = (uuid: string): boolean => {
  const state = store.getState();
  const { voice: voiceState } = state;
  const { connections } = voiceState;

  return connections.some(
    (connection) => connection.uuid === uuid && connection.isCompanyExtensionCall,
  );
};

export const getActiveConnection = (): IConnection | undefined => {
  const state = store.getState();
  const { voice: voiceState } = state;
  const { connections } = voiceState;

  return connections.find((connection) => connection.activeNow);
};

export const getSelectedConnection = (): IConnection | undefined => {
  const state = store.getState();
  const { voice: voiceState } = state;
  const { connections } = voiceState;

  return connections.find((connection) => connection.selected);
};

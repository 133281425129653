import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { IPartialResult } from '@/interfaces/querying-data';
import { IGetUsersGroupsQueryParams, IUsersGroupResult } from '@/interfaces/users-groups';

import { Stack } from '@mui/material';

import apisV1 from '@/services/api/v1/index';

import DataTemplate from '@/components/Data-template/Data-template';

import UsersGroupsHeader from '@/pages/dashboard/users/users-groups/Header';
import UserGroupsList from '@/pages/dashboard/users/users-groups/List';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import { useAppDispatch } from '@/store/hooks';

import ROUTES from '@/constants/routes';

const UsersGroupsLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<IGetUsersGroupsQueryParams>({
    search: '',
    skip: 0,
    limit: 50,
  });

  const [usersGroupsResult, setUsersGroupsResult] = useState<IPartialResult<IUsersGroupResult>>({
    maxCount: 0,
    records: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleGetUsersGroups = async (): Promise<void> => {
    setIsLoading(true);

    const { success, data } = await apisV1.usersGroupsApis.getByParams(queryParams);
    if (success) {
      setUsersGroupsResult(data);
    }

    setIsLoading(false);
  };

  const handleDelete = async (usersGroup: IUsersGroupResult): Promise<void> => {
    setIsLoading(true);

    const { success } = await apisV1.usersGroupsApis.deleteById(usersGroup.id);
    if (success) {
      setUsersGroupsResult((current) => {
        return {
          maxCount: current.maxCount - 1 ?? 0,
          records: current.records.filter((element) => element.id !== usersGroup.id),
        };
      });
      dispatch(snackbarReducer.showSuccess('Users Group was deleted.'));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoading) {
      handleGetUsersGroups();
    }
  }, [queryParams.search, queryParams.skip, queryParams.limit]);

  return (
    <Stack spacing={2} pb={1}>
      <UsersGroupsHeader
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        handleCreate={() => navigate(ROUTES.DASHBOARD_USERS_GROUPS_ADD_EDIT)}
      />

      <DataTemplate
        isLoading={isLoading}
        pagination={{
          maxCount: usersGroupsResult.maxCount,
          paginationData: {
            skip: queryParams.skip,
            limit: queryParams.limit,
          },
          onPageChange: (paginationData) =>
            setQueryParams((prevState) => ({
              ...prevState,
              ...paginationData,
            })),
          onRowsPerPageChange: (paginationData) =>
            setQueryParams((prevState) => ({
              ...prevState,
              ...paginationData,
            })),
        }}
      >
        <UserGroupsList
          data={usersGroupsResult.records}
          handleUpdate={(data) =>
            navigate(ROUTES.DASHBOARD_USERS_GROUPS_ADD_EDIT, {
              state: { data },
            })
          }
          handleDelete={handleDelete}
        />
      </DataTemplate>
    </Stack>
  );
};

export default UsersGroupsLayout;

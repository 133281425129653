namespace SOCKETIO {
  export enum EVENTS {
    CONNECT = 'connect',

    CHATS_UPDATES = 'chats_updates',
    NEW_MSG_BROWSER_NOTIFICATION = 'new_msg_browser_notification',
    MESSAGE = 'message',
    MESSAGE_STATUS = 'message_status',
    JOIN_CHAT = 'join_chat',

    CALL_ANSWERED = 'call_answered',
    JOIN_CALL = 'join_call',
    CALL_OUTBOUND_JOIN = 'call_outbound_join',
    RINGING_CALL_CANCELED = 'ringing_call_canceled',
    CALL_ON_HOLD_CANCELED = 'call_on_hold_canceled',
    INTERNAL_CALL_CANCELED = 'internal_call_canceled',
    INTERNAL_CALL_IS_ON_HOLD = 'internal_call_is_on_hold',
    INTERNAL_CALL_IS_RESUMED = 'internal_call_is_resumed',
    LIVE_CALL_TRANSCRIPTION = 'live_call_transcription',
    LIVE_CALL_TRANSCRIPTION_STATUS = 'live_call_transcription_status',
    REJECT_INCOMING_CALL = 'reject_incoming_call',
    CONFERENCE_IS_MULTIPLE_PARTICIPANTS = 'conference_is_multiple_participants',
    CONFERENCE_PARTICIPANTS = 'conference_participants',
    CONFERENCE_PARTICIPANT_UPDATES = 'conference_participant_updates',
    MISSED_CALL = 'missed_call',

    FETCH_DATA = 'fetch_data',
  }
}

export default SOCKETIO;

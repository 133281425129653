import { isAfter, addMinutes } from '@/helpers/date/date';

import { LiveStatus } from '@/types/user';

import USERS from '@/constants/users';

const useUserLiveStatus = () => {
  const getStatus = (lastActivityAt: Date | string | undefined | null): LiveStatus => {
    if (!lastActivityAt) {
      return USERS.LIVE_STATUSES.OFFLINE;
    }

    if (lastActivityAt && isAfter(addMinutes(lastActivityAt, 15), new Date())) {
      return USERS.LIVE_STATUSES.ACTIVE;
    }

    return USERS.LIVE_STATUSES.INACTIVE;
  };

  return {
    getStatus,
  };
};

export default useUserLiveStatus;

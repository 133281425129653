import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { Box, Stack, Typography } from '@mui/material';

import * as settingsThunk from '../../thunk';
import Switch from '@mui/material/Switch';

const VoicemailTranscription = () => {
  const dispatch = useAppDispatch();

  const { tenant } = useAppSelector((state) => state.tenant);

  const handleTurnOnOff = async (): Promise<void> => {
    await dispatch(settingsThunk.fetchTurnOnOffVoicemailTranscription());
  };

  return (
    <Stack spacing={1}>
      <Typography ml={1} component="label">
        *AI Voicemail Transcription
      </Typography>
      <Box>
        <Switch checked={!!tenant?.voicemailsTranscription} onChange={handleTurnOnOff} />
        <Typography ml={1} component="label">
          Turn On/Off voicemail transcription
        </Typography>
      </Box>
    </Stack>
  );
};

export default VoicemailTranscription;

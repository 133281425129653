export const firstCharToUpperCase = (val: string) => val[0].toUpperCase() + val.slice(1);

export const extractLinks = (text: string): string[] => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const urls = text.match(urlRegex);
  return urls || [];
};

export const addDotsIfLength = (val: string | undefined | null, maxLength: number = 10) =>
  val && val.length > maxLength ? val.slice(0, maxLength) + '...' : val;

import { IPartialResult } from '@/interfaces/querying-data';
import { IGetUsersQueryParams, IUserResult } from '@/interfaces/users/users';

import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';

import { Stack } from '@mui/material';

import * as usersThunk from '@/store/thunk/dashboard/users';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as menuReducer from '@/store/reducers/dashboard/menu';

import DataTemplate from '@/components/Data-template/Data-template';
import UsersList from './Users-list';
import UsersHeader from './Users-header';

import ROUTES from '@/constants/routes';
import DASHBOARD from '@/constants/dashboard';
import * as SNACKBAR from '@/constants/snackbar';

const UsersLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.user);

  const [queryParams, setQueryParams] = useState<IGetUsersQueryParams>({
    search: '',
    excludeMe: false,
    sortField: undefined,
    sortDirection: undefined,
    skip: 0,
    limit: 50,
  });

  const [usersResult, setUsersResult] = useState<IPartialResult<IUserResult>>({
    maxCount: 0,
    records: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  const loadUsers = async () => {
    setIsLoading(true);

    const { payload } = await dispatch(usersThunk.fetchGetUsers(queryParams));

    if (payload) {
      setUsersResult(payload as IPartialResult<IUserResult>);
    }

    setIsLoading(false);
  };

  const handleCreateUser = () => {
    navigate(ROUTES.DASHBOARD_ADD_EDIT_USER);
  };

  const handleUpdateUser = (user: IUserResult) => {
    navigate(ROUTES.DASHBOARD_ADD_EDIT_USER, { state: { data: user } });
  };

  const handleDeleteUser = async (user: IUserResult) => {
    setIsLoading(true);

    const { meta } = await dispatch(usersThunk.fetchDeleteById({ id: user.id }));

    if (meta.requestStatus === 'fulfilled') {
      await loadUsers();
      dispatch(snackbarReducer.showSuccess('User was deleted.'));
    }

    setIsLoading(false);
  };

  const handleResetUserPassword = async (user: IUserResult) => {
    setIsLoading(true);

    const { meta } = await dispatch(usersThunk.fetchResetPassword(user.id));
    if (meta.requestStatus === 'fulfilled') {
      dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.USER.PASSWORD_RESET_EMAIL_SENT));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.USERS }));
    const intervalId = setInterval(() => loadUsers(), 60_000); // 1 minute
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      loadUsers();
    }
  }, [
    queryParams.search,
    queryParams.excludeMe,
    queryParams.skip,
    queryParams.limit,
    queryParams.sortField,
    queryParams.sortDirection,
  ]);

  return (
    <Stack spacing={2}>
      <UsersHeader
        user={user}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        handleCreateUser={handleCreateUser}
      />

      <DataTemplate
        isLoading={isLoading}
        pagination={{
          maxCount: usersResult.maxCount,
          paginationData: {
            skip: queryParams.skip,
            limit: queryParams.limit,
          },
          onPageChange: (paginationData) =>
            setQueryParams((prevState) => ({
              ...prevState,
              ...paginationData,
            })),
          onRowsPerPageChange: (paginationData) =>
            setQueryParams((prevState) => ({
              ...prevState,
              ...paginationData,
            })),
        }}
      >
        <UsersList
          isLoading={isLoading}
          data={usersResult.records}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          handleDeleteUser={handleDeleteUser}
          handleUpdateUser={handleUpdateUser}
          handleResetUserPassword={handleResetUserPassword}
        />
      </DataTemplate>
    </Stack>
  );
};

export default UsersLayout;

import { Dispatch, Fragment, FunctionComponent, SetStateAction } from 'react';
import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';

import {
  MoreVert as MoreVertIcon,
  ExpandMore as ExpandMoreIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  MoreTime as MoreTimeIcon,
} from '@mui/icons-material';

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Box,
  Badge,
  CircularProgress,
  Divider,
  Button,
  ListItemButton,
  IconButton,
  Tooltip,
  alpha,
} from '@mui/material';

import { useAppSelector } from '@/store/hooks';

import { IChatResult, IGetChatsQueryParams, INotCreatedChat } from '@/interfaces/chat';
import { IPartialResult } from '@/interfaces/querying-data';
import { IChatMenuState } from '../Chats-messages-layout';

import ChatTypeChip from '@/components/Chat-type-chip/Chat-type-chip';
import ChatPlatformChip from '@/components/Chat-platform-chip/Chat-platform-chip';
import CustomAvatar from '@/components/Custom-avatar/Avatar';

import CHAT from '@/constants/chat';

const CHAT_MARKS_DISPLAY_DATA: {
  label: string;
  value: string;
  getIcon: (props: Record<'isActive', boolean>) => JSX.Element;
}[] = [
  {
    label: 'Completed',
    value: CHAT.MARKS.COMPLETED,
    getIcon: (props) => (
      <CheckCircleOutlineIcon
        sx={{
          fontSize: '1.1rem',
          color: props.isActive ? 'success.dark' : 'disabled',
        }}
      />
    ),
  },
  {
    label: 'Follow Up',
    value: CHAT.MARKS.FOLLOW_UP,
    getIcon: (props) => (
      <MoreTimeIcon
        sx={{
          fontSize: '1.1rem',
          color: props.isActive ? 'primary.dark' : 'disabled',
        }}
      />
    ),
  },
];

interface IClassicListProps {
  isChatMenuOpened: boolean;
  isSearchActive: boolean;
  isLoading: boolean;
  menuState: IChatMenuState;
  setMenuState: Dispatch<SetStateAction<IChatMenuState>>;
  selectedChat: IChatResult | null;
  notCreatedSelectedChat: INotCreatedChat | null;
  chatsResult: IPartialResult<IChatResult>;
  queryParams: Required<IGetChatsQueryParams> & Record<'search', string>;
  setQueryParams: Dispatch<SetStateAction<IClassicListProps['queryParams']>>;
  handleSelectChat: (chat: IChatResult) => void;
  handleUpdateChatMarks: (chatId: string, marks: CHAT.MARKS[]) => Promise<void>;
}

const ClassicList: FunctionComponent<IClassicListProps> = ({
  isLoading,
  menuState,
  setMenuState,
  selectedChat,
  notCreatedSelectedChat,
  chatsResult,
  queryParams,
  setQueryParams,
  handleSelectChat,
  handleUpdateChatMarks,
  isSearchActive,
  isChatMenuOpened,
}): JSX.Element => {
  const { user } = useAppSelector((state) => state.user);

  const prepareChatDisplayData = (
    chat: IChatResult,
  ): {
    title: string | undefined;
    subTitle: string;
    avatar: string;
    lastActivityAt: string | undefined;
  } => {
    const {
      userReceiver,
      userSender,
      contact,
      unknownContact,
      isInternal,
      senderCompanyExt,
      receiverCompanyExt,
    } = chat;

    let title;
    let subTitle;
    let avatar;
    let lastActivityAt;

    const noUserCompanyExt =
      senderCompanyExt === user?.companyExtension ? receiverCompanyExt : senderCompanyExt;

    if (isInternal && user?.companyExtension === senderCompanyExt) {
      title = userReceiver ? userReceiver.fullName : 'Unassigned Company Extension';
      subTitle = userReceiver?.companyExtension ?? noUserCompanyExt;
      avatar = userReceiver?.avatarUrl;
      lastActivityAt = userReceiver?.lastActivityAt;
    }

    if (isInternal && user?.companyExtension === receiverCompanyExt) {
      title = userSender ? userSender.fullName : 'Unassigned Company Extension';
      subTitle = userSender?.companyExtension ?? noUserCompanyExt;
      avatar = userSender?.avatarUrl;
      lastActivityAt = userSender?.lastActivityAt;
    }

    if (!isInternal) {
      title = unknownContact ?? 'Unknown contact';
      subTitle = unknownContact ?? 'Unknown contact';
      avatar = DefaultAvatar;
    }
    if (!isInternal && contact) {
      title = contact.fullName;
      subTitle = contact?.phoneNumber;
      avatar = contact?.avatarUrl;
    }

    return {
      title,
      lastActivityAt,
      subTitle: subTitle as string,
      avatar: avatar ?? DefaultAvatar,
    };
  };

  return (
    <List
      dense
      disablePadding
      sx={{
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        pb: 8,
      }}
    >
      {chatsResult.records.map((chat) => {
        const { avatar, title, lastActivityAt } = prepareChatDisplayData(chat);

        const isChatSelected =
          selectedChat?.id === chat.id || notCreatedSelectedChat?.localId === chat.id;

        return (
          <Fragment
            // @ts-expect-error because not creatd chat might be in array it has localId field
            key={chat.id + chat['localId']}
          >
            <ListItem
              disablePadding
              sx={
                isChatSelected
                  ? {
                      bgcolor: (theme) => alpha(theme.palette.secondary.light, 0.24),
                      color: 'primary.contrastText',
                    }
                  : {}
              }
            >
              <ListItemButton
                disableRipple={!!menuState.anchor}
                onClick={() => handleSelectChat(chat)}
                sx={{
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  pt: 0,
                  px: 1,
                  pb: 0.75,
                }}
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <ListItemAvatar sx={{ ml: 0.5, minWidth: 'auto' }}>
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      variant="dot"
                      color="primary"
                      badgeContent={chat.lastMsgSentByRef === user?.id ? 0 : chat.newMsgsCount ?? 0}
                    >
                      <CustomAvatar
                        url={avatar}
                        lastActivityAt={chat.userSender ? lastActivityAt : undefined}
                        showLiveStatus={!!chat.userSender}
                      />
                    </Badge>
                  </ListItemAvatar>

                  <Box display="flex" flexDirection="column" flex={1}>
                    <Box display="flex" gap={1} justifyContent="space-between">
                      <ListItemText
                        primary={title}
                        primaryTypographyProps={{
                          fontSize: 'small',
                          color: 'primary.main',
                        }}
                      />

                      <Box display="flex" gap={1} alignItems="center">
                        <ChatTypeChip chatType={chat.type as CHAT.TYPE} />

                        <IconButton
                          color="primary"
                          size="small"
                          aria-controls={isChatMenuOpened ? 'chat-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={isChatMenuOpened ? 'true' : undefined}
                          onClick={(event) => {
                            event.stopPropagation();

                            setMenuState({
                              anchor: event.currentTarget,
                              chat,
                            });
                          }}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>

                    <Box display="flex" gap={1} alignItems="center" justifyContent="space-between">
                      <Box display="flex" gap={1} alignItems="center">
                        {CHAT_MARKS_DISPLAY_DATA.map((chatMarkDisplayData) => {
                          return (
                            <Tooltip
                              key={chatMarkDisplayData.value}
                              title={
                                chat.marks.includes(chatMarkDisplayData.value)
                                  ? `This chat is marked as ${chatMarkDisplayData.label.toLowerCase()}`
                                  : `Mark this chat as ${chatMarkDisplayData.label.toLowerCase()}`
                              }
                            >
                              <IconButton
                                sx={{ p: 0 }}
                                onMouseDown={(event) => event.stopPropagation()}
                                onClick={(event) => {
                                  event.stopPropagation();

                                  if (chat.marks.includes(chatMarkDisplayData.value)) {
                                    handleUpdateChatMarks(
                                      chat.id,
                                      chat.marks.filter(
                                        (el) => el !== chatMarkDisplayData.value,
                                      ) as CHAT.MARKS[],
                                    );
                                  } else {
                                    handleUpdateChatMarks(chat.id, [
                                      ...chat.marks,
                                      chatMarkDisplayData.value,
                                    ] as CHAT.MARKS[]);
                                  }
                                }}
                              >
                                {chatMarkDisplayData.getIcon({
                                  isActive: chat.marks.includes(chatMarkDisplayData.value),
                                })}
                              </IconButton>
                            </Tooltip>
                          );
                        })}
                      </Box>

                      {queryParams.platform === CHAT.PLATFORM.ANY ? (
                        <ChatPlatformChip
                          platform={chat.platform as CHAT.PLATFORM}
                          isInternal={chat.isInternal}
                          chipProps={{
                            sx: {
                              fontSize: '.625rem',
                              height: '1rem',
                            },
                          }}
                        />
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </ListItemButton>
            </ListItem>

            <Divider />
          </Fragment>
        );
      })}

      {chatsResult.records.length < chatsResult.maxCount && !isSearchActive ? (
        <Button
          fullWidth
          color="secondary"
          onClick={() =>
            setQueryParams((prevState) => ({
              ...prevState,
              skip: chatsResult.records.length,
            }))
          }
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="5%" />
          ) : (
            <ExpandMoreIcon fontSize="medium" />
          )}
        </Button>
      ) : null}
    </List>
  );
};

export default ClassicList;

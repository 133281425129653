import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  IPhoneNumbersSetDefaultForTenantRequest,
  IPhoneNumbersTenantResult,
} from '@/interfaces/phone-numbers';

import * as phoneNumbersThunk from '@/store/thunk/phone-numbers';

import { TemplateDialog } from '@/components/Dialogs/Template-dialog';

import useTemplateDialog from '@/hooks/use-template-dialog';

import { PHONE_NUMBER_TYPE } from '@/constants/phone-numbers';

const DefaultPhoneNumberForTenantSettings: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const tenant = useAppSelector((state) => state.tenant.tenant);

  const [phoneNumbers, setPhoneNumbers] = useState<IPhoneNumbersTenantResult[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedPhoneNumberId, setSelectedPhoneNumberId] = useState<string | null>('');

  const [dialogState, dialogActions] = useTemplateDialog({
    isOpened: false,
    title: 'Default Main/Business phone number',
    showCloseButton: false,
    textContent:
      'Proceeding will set selected phone number as default. Note: only one phone number can be default for tenant.',
    submitButtonProps: { children: 'Set default' },
  });

  const handleSetDefaultPhoneNumber = async (id: string) => {
    const response = await dialogActions.openDialog();

    if (!response) {
      return;
    }

    const data = phoneNumbers.find((el) => el.id === id);

    if (!data) {
      return;
    }

    setIsLoading(true);

    const body: IPhoneNumbersSetDefaultForTenantRequest = {
      id: data.id,
    };

    const { payload } = await dispatch(phoneNumbersThunk.fetchSetDefaultPhoneNumberForTenant(body));

    if (payload) {
      setPhoneNumbers((prevState) =>
        prevState.map((el) => {
          el.isDefault = el.id === data.id;
          return el;
        }),
      );

      setSelectedPhoneNumberId(payload.id);
    }

    setIsLoading(false);
  };

  const handleLoad = async () => {
    setIsLoading(true);

    const { payload } = await dispatch(
      phoneNumbersThunk.fetchGetPhoneNumbers({
        type: PHONE_NUMBER_TYPE.TENANT,
        sortField: 'isDefault',
        sortDirection: -1,
        limit: 0,
        skip: 0,
      }),
    );

    if (payload) {
      if (payload[0]?.isDefault) {
        setSelectedPhoneNumberId(payload[0].id);
      }

      setPhoneNumbers(payload);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (tenant?.id) {
      handleLoad();
    }
  }, [tenant?.id]);

  return (
    <Stack spacing={1}>
      <TemplateDialog {...dialogState} />

      <Typography variant="body1" component="p">
        Default Main/Business phone number
      </Typography>

      <Typography variant="body2" component="p">
        *For outgoing calls when no masking phone number selected
      </Typography>

      <FormControl fullWidth>
        <InputLabel id="default-phone-number-label">Select from list</InputLabel>
        <Select
          variant="standard"
          labelId="default-phone-number-label"
          label="Select default phone number."
          placeholder="Select phone number..."
          disabled={!phoneNumbers.length || !isLoading}
          value={selectedPhoneNumberId}
          onChange={(event) =>
            event.target.value ? handleSetDefaultPhoneNumber(event.target.value) : null
          }
        >
          {phoneNumbers.map((el) => (
            <MenuItem key={el.id} value={el.id}>
              {el.phoneNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default DefaultPhoneNumberForTenantSettings;

import * as voiceReducer from '@/store/reducers/dashboard/voice';
import * as voiceThunk from '@/pages/dashboard/voice/thunk';
import { AppDispatch, store } from '@/store/store';

import * as voiceService from '../voice/call';
import { getInProgressCall } from '../voice/call';
import { putTwilioCallOnHold } from './put-on-hold';
import { endCall } from './end-call';

export const acceptCall = async (data: {
  dispatch: AppDispatch;
  uuid: string;
  connection: any;
}) => {
  const { dispatch, uuid, connection } = data;
  const state = store.getState();
  const { user: userState, voice: voiceState } = state;

  if (!userState.user) {
    return;
  }

  if (connection._isAnswered) {
    return;
  }

  const inProgressCall = getInProgressCall();

  dispatch(voiceReducer.setAnswered(uuid));

  if (inProgressCall && inProgressCall.uuid && inProgressCall.uuid !== uuid) {
    if (!inProgressCall.internalHoldByOther) {
      await putTwilioCallOnHold({ dispatch, uuid: inProgressCall.uuid });
    } else {
      await endCall({ dispatch, uuid: inProgressCall.uuid });
    }
  }

  connection.accept();

  dispatch(voiceThunk.fetchSetAnsweredBy({ uuid, userId: userState.user.id }));
  if (voiceState.connections.find((element) => element.uuid === uuid)?.isMultipleParticipants) {
    dispatch(voiceThunk.fetchConferenceParticipants(uuid));
  }

  voiceService.startCallDurationCounter({ dispatch, uuid });
};

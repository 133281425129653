import { IAdvancedSearchMsgDataPayload } from '@/interfaces/message';
import makeRequest from '../make-request';

import token from '../token';

const GET_ALL = '/api/v1/message';
const SEND_MESSAGE = '/api/v1/message';
const ADVANCED_SEARCH_MSG_DATA = '/api/v1/message/:chatId/advanced-search-msg-data/:messageId';
const ADVANCED_SEARCH = '/api/v1/message/advanced-search';

export const getAll = async (chatId: string, limit = 20, skip = 0) => {
  try {
    const result = await makeRequest(`${GET_ALL}/${chatId}`, {
      method: 'GET',
      headers: { Authorization: await token() },
      params: { limit: limit.toString(), skip: skip.toString() },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const sendMessage = async (data: FormData) => {
  try {
    const result = await makeRequest(SEND_MESSAGE, {
      method: 'POST',
      headers: { Authorization: await token() },
      data,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const advancedSearchMsgData = async (data: IAdvancedSearchMsgDataPayload) => {
  try {
    const result = await makeRequest(
      ADVANCED_SEARCH_MSG_DATA.replace(':chatId', data.chatId).replace(
        ':messageId',
        data.messageId,
      ),
      {
        method: 'GET',
        headers: { Authorization: await token() },
      },
    );

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const advancedSearch = async (search: string) => {
  try {
    const result = await makeRequest(ADVANCED_SEARCH, {
      method: 'GET',
      headers: { Authorization: await token() },
      params: { search: encodeURIComponent(search) },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';

import { Box, Checkbox, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import {
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  MessageOutlined as MessageOutlinedIcon,
} from '@mui/icons-material';

import { IGetIotDevicesQueryParams, IIoTDeviceResult } from '@/interfaces/iot-device';

import { format } from '@/helpers/date/date';

import TemplateTable, {
  ITemplateTableColumn,
  ITemplateTableProps,
} from '@/components/Template-table/Template-table';
import ConfirmationDialog from '@/components/Dialogs/Confirmation';
import NoDataValue from '@/components/Data/No-data-value';

import useAccess from '@/hooks/use-access';
import { IUseMultiCheckboxSelectActions } from '@/hooks/use-multi-checkbox-select';

import { DATE_FORMAT_DATE } from '@/constants/layout';
import { ROLES } from '@/constants/roles';

interface IIotDevicesListProps {
  data: IIoTDeviceResult[];
  selectedContactsIdsActions: IUseMultiCheckboxSelectActions<string>;
  queryParams: IGetIotDevicesQueryParams;
  setQueryParams: Dispatch<SetStateAction<IGetIotDevicesQueryParams>>;
  handleUpdate: (el: IIoTDeviceResult) => void;
  handleDelete: (el: IIoTDeviceResult) => Promise<void>;
  handleSendMessage: (el: IIoTDeviceResult) => void;
}

interface IIotDeviceActionsMenuButtonProps
  extends Omit<
    IIotDevicesListProps,
    | 'queryParams'
    | 'data'
    | 'selectedContactsIdsActions'
    | 'queryParams'
    | 'setQueryParams'
    | 'handleCallContact'
    | 'handleSendMessageToContact'
    | 'isWhatsAppEnabled'
  > {
  iotDevice: IIoTDeviceResult;
}

const IotDeviceActionsMenuButton: FunctionComponent<IIotDeviceActionsMenuButtonProps> = ({
  iotDevice,
  handleDelete,
  handleUpdate,
}): JSX.Element => {
  const { isUserRoleMatches } = useAccess();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<boolean>(false);

  const isOpened = !!anchorEl;

  return (
    <IconButton
      disabled={!isUserRoleMatches([ROLES.ADMIN, ROLES.SUPER_ADMIN])}
      color="primary"
      onClick={(event) => {
        event.stopPropagation();
        setAnchorEl((prevState) => (prevState ? null : event.currentTarget));
      }}
    >
      <ConfirmationDialog
        handleAccepted={() => handleDelete(iotDevice)}
        handleRejected={() => setShowDeleteConfirmationDialog(false)}
        show={showDeleteConfirmationDialog}
        title="Delete device?"
        subtitle="By pressing Yes you will delete this device."
      />
      <Menu
        anchorEl={anchorEl}
        open={isOpened}
        disableAutoFocusItem
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleUpdate(iotDevice)}>
          <ListItemIcon>
            <EditIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Edit
        </MenuItem>

        <MenuItem onClick={() => setShowDeleteConfirmationDialog(true)}>
          <ListItemIcon>
            <DeleteOutlineIcon color="error" fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>

      <MoreVertIcon />
    </IconButton>
  );
};

const ContactsList: FunctionComponent<IIotDevicesListProps> = ({
  data,
  queryParams,
  setQueryParams,
  selectedContactsIdsActions,

  handleUpdate,
  handleDelete,
  handleSendMessage,
}): JSX.Element => {
  const { isUserRoleMatches } = useAccess();

  const onlyAdminsAccessibleHeaders: string[] = ['IP Address', 'MAC', 'Serial Number', 'Groups'];

  const tableColumns = (): ITemplateTableColumn<IIoTDeviceResult>[] => {
    const columns: ITemplateTableColumn<IIoTDeviceResult>[] = [
      {
        sharedProps: { padding: 'checkbox', sx: { py: 0 } },
        getHeader: () => {
          const dataToCompare = data.map((el) => el.id) as string[];

          return (
            <Checkbox
              color="primary"
              indeterminate={selectedContactsIdsActions.isAllOptionHasIndeterminateState(
                dataToCompare,
              )}
              checked={selectedContactsIdsActions.isAllOptionSelected(dataToCompare)}
              onChange={(event) =>
                selectedContactsIdsActions.handleSelectAll(event.target.checked, dataToCompare)
              }
              inputProps={{
                'aria-label': 'Select all devices',
              }}
            />
          );
        },
        getValue: (el: IIoTDeviceResult) =>
          isUserRoleMatches([ROLES.ADMIN, ROLES.SUPER_ADMIN]) ? (
            <Checkbox
              color="primary"
              checked={selectedContactsIdsActions.isSelected(el.id as string)}
              inputProps={{
                'aria-labelledby': el.id,
              }}
            />
          ) : undefined,
      },
      {
        header: 'Name',
        sortFieldName: 'name',
        getValue: (el: IIoTDeviceResult) => el.name,
      },
      {
        header: 'Description',
        sortFieldName: 'description',
        getValue: (el: IIoTDeviceResult) => el.description ?? <NoDataValue />,
      },
      {
        header: 'Groups',
        sortFieldName: 'groupsNames',
        getValue: (el: IIoTDeviceResult) => el.groupsNames || <NoDataValue />,
      },
      {
        header: 'IP Address',
        sortFieldName: 'ipAddress',
        getValue: (el: IIoTDeviceResult) => el.ipAddress || <NoDataValue />,
      },
      {
        header: 'MAC',
        sortFieldName: 'mac',
        getValue: (el: IIoTDeviceResult) => el.mac || <NoDataValue />,
      },
      {
        header: 'Serial Number',
        sortFieldName: 'serialNumber',
        getValue: (el: IIoTDeviceResult) => el.serialNumber || <NoDataValue />,
      },
      {
        header: 'Identifier',
        sortFieldName: 'identifier',
        getValue: (el: IIoTDeviceResult) => el.identifier,
      },
      // Do not delete
      // {
      //   header: 'Status',
      //   sortFieldName: 'status',
      //   getValue: (el: IIoTDeviceResult) => el.status,
      // },
      {
        header: 'Created At',
        sortFieldName: 'createdAt',
        getValue: (el: IIoTDeviceResult) => format(el.createdAt, DATE_FORMAT_DATE),
      },
      {
        sharedProps: { align: 'center', sx: { py: '.25rem', maxWidth: '12rem' } },
        header: 'Actions',
        getValue: (el: IIoTDeviceResult) => (
          <Box display="flex" gap={1} justifyContent="center">
            <Tooltip title="Send message">
              <IconButton
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  handleSendMessage(el);
                }}
              >
                <MessageOutlinedIcon sx={{ fontSize: 19 }} />
              </IconButton>
            </Tooltip>
            <IotDeviceActionsMenuButton
              iotDevice={el}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
              handleSendMessage={handleSendMessage}
            />
          </Box>
        ),
      },
    ];

    return isUserRoleMatches([ROLES.ADMIN, ROLES.SUPER_ADMIN])
      ? columns
      : columns.filter(
          (element) => element.header && !onlyAdminsAccessibleHeaders.includes(element.header),
        );
  };

  const templateTableProps: ITemplateTableProps<IIoTDeviceResult> = {
    data,
    getDataEntityId: (el) => el?.id + el.name,
    getTableRowProps: (el) => ({
      role: 'checkbox',
      tabIndex: -1,
      key: el.id,
      hover: true,
      onClick: () =>
        isUserRoleMatches([ROLES.ADMIN, ROLES.SUPER_ADMIN])
          ? selectedContactsIdsActions.handleSelect(el.id as string)
          : undefined,
      selected: selectedContactsIdsActions.isSelected(el.id as string),
      'aria-checked': selectedContactsIdsActions.isSelected(el.id as string),
      sx: { cursor: 'pointer' },
    }),
    columns: tableColumns(),
    onSortChange: (event, sortParams) =>
      setQueryParams((prevState) => ({ ...prevState, ...sortParams })),
    currentSortParams: {
      sortField: queryParams.sortField,
      sortDirection: queryParams.sortDirection,
    },
  };

  return <TemplateTable {...templateTableProps} />;
};

export default ContactsList;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IMenu {
  menu: string | null;
}

const initialState: IMenu = {
  menu: null,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenu: (state, action: PayloadAction<{ menu: string | null }>) => {
      state.menu = action.payload.menu;
    },
  },
});

export const { setMenu } = menuSlice.actions;

export default menuSlice.reducer;

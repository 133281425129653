import { useState } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import * as styles from './styles';

import Contacts from './contacts/Contacts';
import Users from './users/Users';

import { CONTACT_TYPE } from '@/constants/contacts';

type SelectedTab = 'contacts' | 'users';

interface IContactsExtensionsProps {
  disableElevation: boolean;
  onCallPlacement?: VoidFunction;
}

const ContactsExtensions = ({ disableElevation, onCallPlacement }: IContactsExtensionsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<SelectedTab>('users');
  const [search, setSearch] = useState<string>('');
  const [contactType, setContactType] = useState(CONTACT_TYPE.COMPANY);

  const handleSelectTab = async (e: any, value: SelectedTab): Promise<void> => {
    if (!value) {
      return;
    }
    setSearch('');
    setSelectedTab(value);
  };

  return (
    <Box flex={1} display="flex" flexDirection="column" gap={1}>
      <Paper elevation={disableElevation ? 0 : 2} sx={{ p: disableElevation ? 0 : 1 }}>
        <TextField
          fullWidth
          sx={disableElevation ? {} : styles.searchInputSX}
          id="outlined-basic"
          label="Search"
          type="search"
          size="small"
          variant="outlined"
          autoComplete="off"
          value={search}
          onChange={(event) => setSearch(event.target.value || '')}
        />
      </Paper>

      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        component={Paper}
        elevation={disableElevation ? 0 : 1}
        overflow="auto"
      >
        <ToggleButtonGroup
          fullWidth
          exclusive
          value={selectedTab}
          onChange={handleSelectTab}
          size="extra-small"
        >
          <ToggleButton value="users" sx={{ borderBottomLeftRadius: 0 }}>
            Extensions
          </ToggleButton>

          <ToggleButton value="contacts" sx={{ borderBottomRightRadius: 0 }}>
            Contacts
          </ToggleButton>
        </ToggleButtonGroup>

        {selectedTab === 'contacts' ? (
          <ToggleButtonGroup
            fullWidth
            exclusive
            size="extra-small"
            value={contactType}
            onChange={(e, value) => (value ? setContactType(value) : null)}
          >
            <ToggleButton value={CONTACT_TYPE.COMPANY} sx={{ borderRadius: 0 }}>
              Company
            </ToggleButton>

            <ToggleButton value={CONTACT_TYPE.PRIVATE} sx={{ borderRadius: 0 }}>
              Private
            </ToggleButton>
          </ToggleButtonGroup>
        ) : null}

        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          overflow="auto"
          sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
        >
          {selectedTab === 'contacts' ? (
            <Contacts
              isLoading={isLoading}
              search={search}
              setIsLoading={setIsLoading}
              contactType={contactType}
              setContactType={setContactType}
              onCallPlacement={onCallPlacement}
            />
          ) : (
            <Users
              isLoading={isLoading}
              search={search}
              setIsLoading={setIsLoading}
              onCallPlacement={onCallPlacement}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ContactsExtensions;

import axios from 'axios';

const ENDPOINT = 'https://api.hostip.info/';

export const getUserLocationByIP = async () => {
  try {
    const { data } = await axios({
      url: ENDPOINT,
    });

    return { data, success: true };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { Box, Stack, Typography } from '@mui/material';

import * as settingsThunk from '../../thunk';
import Switch from '@mui/material/Switch';

const CallRecordings = () => {
  const dispatch = useAppDispatch();

  const { tenant } = useAppSelector((state) => state.tenant);

  const handleTurnOnOffCallRecordings = async (): Promise<void> => {
    await dispatch(settingsThunk.fetchTurnOnOffCallsRecordings());
  };

  return (
    <Stack spacing={1}>
      <Typography ml={1} component="label" htmlFor="devices-preferences">
        *Call recording will remain in the system for 30 days
      </Typography>

      <Box>
        <Switch checked={!!tenant?.recordCalls} onChange={handleTurnOnOffCallRecordings} />
        <Typography ml={1} component="label" htmlFor="devices-preferences">
          Turn On/Off call recordings for all outgoing and incoming calls
        </Typography>
      </Box>
    </Stack>
  );
};

export default CallRecordings;

import '@/pages/dashboard/voice/call-status/conference/styles/voice.css';
import { FunctionComponent } from 'react';

import { Box, Button, Typography, Tooltip, Divider, IconButton } from '@mui/material';

import {
  HowToReg as HowToRegIcon,
  CallEnd as CallEndIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@mui/icons-material';

import { IConnection } from '@/interfaces/voice/call';
import { IUserMeResult } from '@/interfaces/users/users';

import CustomAvatar from '@/components/Custom-avatar/Avatar';

import CALL from '@/constants/voice/voice';

interface IConferenceCallListItemProps {
  conference: IConnection;
  user: IUserMeResult | null;
  orderNumber: number;
  handleSelectConnection: (connection: IConnection) => void;
  handleDropParticipant: (callUuid: string, participantUuid: string) => void;
  handleMakeParticipantConferenceOwner: (callUuid: string, participantUuid: string) => void;
}

const ConferenceCallListItem: FunctionComponent<IConferenceCallListItemProps> = ({
  conference,
  user,
  orderNumber,
  handleDropParticipant,
  handleMakeParticipantConferenceOwner,
  handleSelectConnection,
}): JSX.Element => {
  const isCurrentUserOwner =
    !!user && conference.participants.find((element) => element.owner)?.userRef === user?.id;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" gap={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body1">Conference {orderNumber + 1}</Typography>

          {conference.activeNow && (
            <Tooltip title="Currently speaking">
              <RadioButtonCheckedIcon color="success" fontSize="small" />
            </Tooltip>
          )}

          {conference.selected && (
            <Tooltip title="Selected connection">
              <CheckCircleOutlineIcon color="success" fontSize="small" />
            </Tooltip>
          )}
        </Box>

        {!conference.selected && (
          <Button
            variant="contained"
            size="extra-small"
            onClick={() => {
              handleSelectConnection(conference);
            }}
          >
            Select
          </Button>
        )}
      </Box>

      <Box display="flex" flexWrap="wrap" gap={2}>
        {[...conference.participants]
          .sort((a) => (a.owner ? -1 : 1))
          .map((participant) => {
            const numberOrExtension = participant.phoneNumber ?? participant.companyExtension;
            let participantCardLabel = participant.fullName ?? numberOrExtension ?? '';

            if (participantCardLabel !== numberOrExtension) {
              participantCardLabel = `${participantCardLabel} | ${numberOrExtension}`;
            }

            return (
              <Box
                key={participant.uuid + orderNumber}
                onClick={() => handleSelectConnection(conference)}
                overflow="hidden"
                color="primary.contrastText"
                bgcolor="primary.main"
                width={100}
                p={1}
                pt={2}
                borderRadius={2}
                position="relative"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  position="absolute"
                  top="1%"
                  right="1%"
                  width="100%"
                >
                  {participant.owner && (
                    <Typography ml={1} variant="caption" component="span">
                      Owner
                    </Typography>
                  )}
                </Box>

                <Box display="flex" flexDirection="column" flex={1} alignItems="center">
                  <Box position="relative">
                    <CustomAvatar
                      url={participant.avatarUrl}
                      sx={{ width: 60, height: 60 }}
                      showLiveStatus={false}
                    />

                    <Typography
                      position="absolute"
                      bottom="1%"
                      left="50%"
                      whiteSpace="nowrap"
                      sx={{ transform: 'translateX(-50%)' }}
                      variant="body2"
                      component="span"
                      color="secondary.contrastText"
                      bgcolor="secondary.main"
                      px={0.5}
                      borderRadius={2}
                      fontSize="0.625rem"
                    >
                      {participant.status}
                    </Typography>
                  </Box>

                  <Typography
                    variant="caption"
                    component="span"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    className={
                      participantCardLabel.length > 16
                        ? 'participant-name-number-sliding-animation'
                        : ''
                    }
                  >
                    {participantCardLabel}
                  </Typography>
                </Box>

                {isCurrentUserOwner ? (
                  <Box display="flex" flexWrap="wrap" justifyContent="center">
                    {participant.userRef && (
                      <Tooltip title="Make this user owner of the call">
                        <Box component="span">
                          <IconButton
                            size="small"
                            color="success"
                            disabled={
                              participant.owner || participant.status !== CALL.STATUSES.IN_PROGRESS
                            }
                            sx={{ '&[disabled]': { color: 'primary.light' } }}
                            onClick={() => {
                              handleMakeParticipantConferenceOwner(
                                conference.uuid,
                                participant.uuid,
                              );
                            }}
                          >
                            <HowToRegIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    )}

                    <Tooltip title="Drop from call">
                      <Box component="span">
                        <IconButton
                          size="small"
                          color="error"
                          disabled={participant.owner}
                          sx={{ '&[disabled]': { color: 'primary.light' } }}
                          onClick={() => {
                            handleDropParticipant(conference.uuid, participant.uuid);
                          }}
                        >
                          <CallEndIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  </Box>
                ) : null}
              </Box>
            );
          })}
      </Box>

      <Divider />
    </Box>
  );
};

export default ConferenceCallListItem;

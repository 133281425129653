import { Tab, TabProps, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

interface INavTabProps {
  tabProps?: Omit<TabProps, 'value' | 'onChange'>;
  links: Array<{
    label: string;
    path: string;
    disabled?: boolean;
  }>;
}

const NavTab = ({ links, tabProps = {} }: INavTabProps) => {
  const getTabIndex = (): number => {
    const index = links.findIndex((el) => el.path === location.pathname);
    return index === -1 ? 0 : index;
  };

  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(() => getTabIndex());

  useEffect(() => {
    setTabIndex(getTabIndex());
  }, [window.location.href]);

  return (
    <Tabs
      scrollButtons="auto"
      variant="scrollable"
      aria-label="navgation tabs"
      {...(tabProps as any)}
      value={tabIndex}
      onChange={(event, index) => setTabIndex(index)}
      TabIndicatorProps={{
        style: {
          backgroundColor: 'primary',
          height: '.25rem',
          borderRadius: '2rem',
        },
      }}
    >
      {links.map((props, index) => {
        const { path, ...tabProps } = props;
        return (
          <Tab
            {...tabProps}
            key={path}
            to={path}
            tabIndex={index}
            component={NavLink}
            sx={{
              fontWeight: 600,
              '&.active': { color: 'primary.main' },
            }}
          />
        );
      })}
    </Tabs>
  );
};

export default NavTab;

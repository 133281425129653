import { useAuth0 } from '@auth0/auth0-react';

import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '@/store/hooks';
import { PropsWithChildren, ReactElement } from 'react';

interface IAuthGuardProps extends Required<PropsWithChildren> {
  // either onUnauthorizedRedirectTo or  onAuthorizedRedirectTo must be provided
  onUnauthorizedRedirectTo?: string;
  onAuthorizedRedirectTo?: string;
}

const AuthGuard = ({
  onUnauthorizedRedirectTo,
  onAuthorizedRedirectTo,
  children,
}: IAuthGuardProps) => {
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const user = useAppSelector((state) => state.user.user);
  const tenant = useAppSelector((state) => state.tenant.tenant);

  const isUnauthorized = !user || !tenant || !isAuthenticated || !isLoggedIn;

  if (
    onUnauthorizedRedirectTo &&
    !location.pathname.includes(onUnauthorizedRedirectTo) &&
    isUnauthorized
  ) {
    return <Navigate replace to={onUnauthorizedRedirectTo} />;
  }

  if (
    onAuthorizedRedirectTo &&
    !location.pathname.includes(onAuthorizedRedirectTo) &&
    !isUnauthorized
  ) {
    return <Navigate replace to={onAuthorizedRedirectTo} />;
  }

  return children as ReactElement;
};

export default AuthGuard;

import { Dispatch, SetStateAction, FunctionComponent } from 'react';
import saveAs from 'file-saver';

import { ImageList, ImageListItem, ImageListItemBar, IconButton } from '@mui/material';

import { Description as DescriptionIcon, Download as DownloadIcon } from '@mui/icons-material';

import FILE from '@/constants/file';

interface IImagesListProps {
  files: { url: string; extension: string; name: string }[];
  setImagePreview: Dispatch<SetStateAction<string | null>>;
}

const getColsNumber = (num: number): number => {
  if (num === 1) {
    return 1;
  }

  if (num % 2 === 0) {
    return 2;
  }

  if (num % 2 === 1) {
    return 3;
  }

  return 1;
};

const MessageImages: FunctionComponent<IImagesListProps> = ({
  files,
  setImagePreview,
}): JSX.Element => {
  return (
    <ImageList
      variant="masonry"
      sx={{
        width: '100%',
        height: '100%',
      }}
      cols={getColsNumber(files.length)}
      rowHeight={200}
    >
      {files.map((file) => {
        return (
          <ImageListItem style={{ cursor: 'pointer', height: '100px' }} key={file.url}>
            {Object.values(FILE.IMAGE.EXTENSION).includes(file.extension) ? (
              <img onClick={() => setImagePreview(file.url)} src={file.url} loading="lazy" />
            ) : (
              <DescriptionIcon
                color="inherit"
                sx={{
                  width: '50%',
                  height: '50%',
                }}
              />
            )}

            <ImageListItemBar
              subtitle={file.extension}
              actionIcon={
                <IconButton
                  sx={{
                    color: 'rgba(255, 255, 255, 0.54)',
                  }}
                  aria-label={`info about ${file.name}`}
                  onClick={() => saveAs(file.url, `${file.name}${file.extension}`)}
                >
                  <DownloadIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};

export default MessageImages;

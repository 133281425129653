import { validate } from '@/helpers/phone-number';

import uuidUtil from '@/utils/uuid';

import * as contactsThunk from '@/pages/dashboard/contacts/thunk';
import * as userThunk from '@/store/thunk/dashboard/users';
import * as tenantThunk from '@/store/thunk/tenant';

import { IUserResult } from '@/interfaces/users/users';
import { IConnection } from '@/interfaces/voice/call';
import { ITenantResult } from '@/interfaces/tenant';
import { IPhoneNumbersResult } from '@/interfaces/phone-numbers';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as voiceReducer from '@/store/reducers/dashboard/voice';
import { AppDispatch, store } from '@/store/store';

import { handleOutboundCallEvents } from './outbound-call';
import { putTwilioCallOnHold } from './put-on-hold';
import { endCall } from './end-call';

import ERRORS from '@/constants/errors';
import CALL from '@/constants/voice/voice';
import { CONTACT_TYPE } from '@/constants/contacts';
import { PHONE_NUMBER_TYPE } from '@/constants/phone-numbers';

const connect = async (data: {
  dispatch: AppDispatch;
  state: any;
  normalizedPhoneNumber: string;
  companyExtensionUser?: IUserResult | null;
}) => {
  const { dispatch, state, normalizedPhoneNumber, companyExtensionUser } = data;

  const { tenant, phoneNumbers } = state.tenant;
  const { user } = state.user;
  const { device } = state.twilio;
  const { callToContact } = state.voice;

  let contact = callToContact;
  let connectionType = CALL.CONNECTION_TYPES.EXTERNAL;
  let isCompanyExtensionCall = false;

  const tenantDefaultPhoneNumber = (tenant as ITenantResult)?.phoneNumbers.find(
    (el) => el.isDefault,
  )?.phoneNumber as string;

  const uuid = uuidUtil();
  const callee = {
    phoneNumber: normalizedPhoneNumber,
    firstName: callToContact?.firstName,
    lastName: callToContact?.lastName,
  };

  const isFromDirectExtension =
    user.selectedPhoneNumberTypeToMakeCallsFrom === PHONE_NUMBER_TYPE.DIRECT_EXTENSION;

  if (companyExtensionUser) {
    callee.firstName = companyExtensionUser.firstName;
    callee.lastName = companyExtensionUser.lastName ?? '';
    callee.phoneNumber = companyExtensionUser.companyExtension;
    connectionType = CALL.CONNECTION_TYPES.INTERNAL;
    isCompanyExtensionCall = true;
  } else {
    if (!contact) {
      let fetchContactResult = await dispatch(
        contactsThunk.fetchByPhoneNumberAndType({
          phoneNumber: normalizedPhoneNumber,
          type: isFromDirectExtension ? CONTACT_TYPE.PRIVATE : CONTACT_TYPE.COMPANY,
        }),
      );

      if (!fetchContactResult.payload && isFromDirectExtension) {
        fetchContactResult = await dispatch(
          contactsThunk.fetchByPhoneNumberAndType({
            phoneNumber: normalizedPhoneNumber,
            type: CONTACT_TYPE.COMPANY,
          }),
        );
      }

      if (fetchContactResult.payload) {
        contact = fetchContactResult.payload;
        callee.firstName = contact?.firstName;
        callee.lastName = contact?.lastName;
      }
    }
  }

  if (
    !isCompanyExtensionCall &&
    phoneNumbers.some(
      (element: IPhoneNumbersResult) =>
        element.phoneNumber === normalizedPhoneNumber &&
        element.type !== PHONE_NUMBER_TYPE.CALLER_ID,
    )
  ) {
    dispatch(
      snackbarReducer.showError(
        `It looks like you're trying to reach out your own company phone number. For internal calls, please use the company extension instead.`,
      ),
    );
    dispatch(voiceReducer.setCallToContact(null));
    dispatch(voiceReducer.setDialerActionBtn('call'));

    return;
  }
  const connection = await device.connect({
    params: {
      uuid,
      connectionType,
      isCompanyExtensionCall,
      To: normalizedPhoneNumber,
      From: tenantDefaultPhoneNumber,
      callerId: tenantDefaultPhoneNumber,
      userId: user.id,
      tenantId: tenant.id,
      contactId: contact?.id || null,
      calleeFirstName: callee.firstName,
      calleeLastName: callee.lastName,
      calleeCompanyExtension: companyExtensionUser?.companyExtension,
    },
  });

  handleOutboundCallEvents({ connection, dispatch });

  const { payload } = await dispatch(tenantThunk.fetchTenantInfo());

  dispatch(
    voiceReducer.setConnection({
      uuid,
      callee,
      connectionType,
      isCompanyExtensionCall,
      conn: connection,
      isConference: payload.voice === CALL.VOICE.CONFERENCE,
      activeNow: true,
      direction: CALL.TRACK_TYPE.OUTBOUND,
      caller: null,
      isForwarding: false,
      answered: false,
      showHoldPulsation: false,
      onHold: false,
      internalHoldByOther: false,
      counter: null,
      counterFunction: null,
      muted: false,
      selected: false,
      isHoldDisabled: false,
      isMultipleParticipants: false,
      transcription: [],
      transcriptionStatus: CALL.TRANSCRIPTION_STATUS.ONLINE,
      participants: [],
    }),
  );
  dispatch(voiceReducer.setIsActiveNow({ uuid }));
  dispatch(voiceReducer.setSelectedConnection(uuid));
  dispatch(voiceReducer.setIsShowCallStatusLoader());
  dispatch(voiceReducer.setCallToContact(null));
};

const validateNumber = (data: { dispatch: AppDispatch; phoneNumber: string }) => {
  const { dispatch, phoneNumber } = data;
  const validationResult = validate(phoneNumber);

  if (!validationResult.isValid) {
    dispatch(snackbarReducer.showWarning(ERRORS.VOICE.INVALID_PHONE_NUMBER));
    dispatch(voiceReducer.setDialerActionBtn('call'));
    return;
  }

  return validationResult;
};

const prepareExistingConnections = async (
  connections: IConnection[],
  dispatch: AppDispatch,
): Promise<void> => {
  for (const connection of connections) {
    const isInternal = connection.connectionType === CALL.CONNECTION_TYPES.INTERNAL;
    if (connection.answered) {
      if (isInternal) {
        if (connection.internalHoldByOther) {
          await endCall({ dispatch, uuid: connection.uuid });
        } else {
          await putTwilioCallOnHold({ dispatch, uuid: connection.uuid });
        }
      } else {
        if (!connection.onHold) {
          await putTwilioCallOnHold({ dispatch, uuid: connection.uuid });
        }
      }
    } else {
      await endCall({ dispatch, uuid: connection.uuid });
    }
  }
};

export const makeTwilioCall = async (data: { dispatch: AppDispatch; input: string }) => {
  const { dispatch, input } = data;

  const state = store.getState();
  const { voice: voiceState, twilio: twilioState, tenant: tenantState, user: userState } = state;
  const { device } = twilioState;
  const { connections, callToContact } = voiceState;
  const { activeProvider } = tenantState;
  const { user } = userState;

  if (!device || !activeProvider || !user) {
    dispatch(voiceReducer.setDialerActionBtn('call'));
    dispatch(snackbarReducer.showError(ERRORS.VOICE.OUTBOUND_CALL));
    return;
  }

  let normalizedPhoneNumber: string = '';
  let companyExtensionUser: IUserResult | null = null;

  if ((!input || input.length < 3) && !callToContact) {
    return;
  }

  if (connections.length === CALL.MAX_CONNECTIONS) {
    dispatch(snackbarReducer.showWarning(ERRORS.VOICE.MAX_CALLS_NUMBER_REACHED));
    return;
  }

  if (callToContact) {
    const toPhoneNumber = callToContact.phoneNumber || input;
    const validationResult = validateNumber({ dispatch, phoneNumber: toPhoneNumber });
    if (validationResult) {
      normalizedPhoneNumber = validationResult.validatedPhoneNumber as string;
    }
  } else {
    if (input === '911') {
      connect({ dispatch, state, normalizedPhoneNumber: input });
      return;
    }

    if (+input >= 21 && +input <= 9999) {
      if (input === user.companyExtension) {
        dispatch(snackbarReducer.showWarning(ERRORS.VOICE.CALL_TO_OWN_COMPANY_EXT));
        return;
      }

      const result = await dispatch(userThunk.fetchByCompanyExtension(input));

      if (result.payload) {
        const defaultTenantPhoneNumber = tenantState.tenant?.phoneNumbers.find((el) => el.isDefault)
          ?.phoneNumber as string;

        normalizedPhoneNumber = defaultTenantPhoneNumber;
        companyExtensionUser = result.payload;
      } else {
        dispatch(snackbarReducer.showWarning(ERRORS.VOICE.INVALID_COMPANY_EXTENSION));
        dispatch(voiceReducer.setDialerActionBtn('call'));
        return;
      }
    } else {
      const toPhoneNumber = input;
      const validationResult = validateNumber({ dispatch, phoneNumber: toPhoneNumber });
      if (validationResult) {
        normalizedPhoneNumber = validationResult.validatedPhoneNumber as string;
      }
    }
  }

  const existConnectionByPhoneNumber = connections.find(
    (connection) =>
      connection.caller?.phoneNumber === normalizedPhoneNumber ||
      connection.callee?.phoneNumber === normalizedPhoneNumber,
  );

  if (existConnectionByPhoneNumber) {
    return;
  }

  dispatch(voiceReducer.setDialerActionBtn('end call'));

  await prepareExistingConnections(connections, dispatch);

  setTimeout(
    () => connect({ dispatch, state, normalizedPhoneNumber, companyExtensionUser }),
    connections.length ? 1500 : 0,
  );
};

export const paper1SX = {
  display: 'flex',
  bgcolor: 'primary.main',
  color: 'primary.contrastText',
  width: '250px',
  height: '50px',
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  mb: 0.5,
};

export const box1SX = {
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
};

export const box2SX = {
  display: 'flex',
  m: 0.5,
  ml: 1,
  flexDirection: 'column',
  width: '50%',
};

export const box3SX = {
  position: 'absolute',
  top: '75px',
  right: 0,
  maxWidth: '100%',
  zIndex: 3,
};

export const box4SX = {
  display: 'flex',
  m: 0.5,
  width: '48%',
  justifyContent: 'flex-end',
  mr: 2,
};

export const typography1SX = {
  fontSize: 13,
};

export const typography2SX = {
  fontSize: 12,
};

export const iconButton1SX = {
  backgroundColor: 'green',
  width: '35px',
  height: '35px',
  '&:hover': {
    backgroundColor: 'green',
  },
};

export const iconButton2SX = {
  backgroundColor: 'red',
  width: '35px',
  height: '35px',
  ml: 1,
  '&:hover': {
    backgroundColor: 'red',
  },
};

export const callIcon1SX = {
  color: 'white',
};

export const callIcon2SX = {
  color: 'white',
};

import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { ITenantCreditCardsResult } from '@/interfaces/tenant';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DoneIcon from '@mui/icons-material/Done';
import {
  Avatar,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from '@mui/material';

import * as profileCreditCardsThunk from '@/pages/profile/credit-cards/thunk';

import StripeCheckoutForm from '@/components/Stripe/Checkout-form';

import * as styles from './styles';

import visa from '@/assets/images/credit-card-brands/visa.png';
import mastercard from '@/assets/images/credit-card-brands/mastercard.png';
import amex from '@/assets/images/credit-card-brands/american_express.png';
import discover from '@/assets/images/credit-card-brands/discover.png';
import jcb from '@/assets/images/credit-card-brands/jcb.png';

const CARDS_IMAGES = {
  mastercard: mastercard,
  visa: visa,
  amex: amex,
  discover: discover,
  jcb: jcb,
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

const Subscriptions: React.FC = () => {
  const dispatch = useAppDispatch();

  const [cards, setCards] = useState<ITenantCreditCardsResult[]>([]);
  const [stripeClientSecret, setStripeClientSecret] = useState<string>();
  const [showCardsLoader, setShowCardsLoader] = useState<boolean>(false);

  const getCreditCards = async (): Promise<void> => {
    const { payload } = await dispatch(profileCreditCardsThunk.fetchCreditCards());
    if (payload) {
      setCards(payload);
    }
    setShowCardsLoader(false);
  };

  const handleAddCreditCard = async (): Promise<void> => {
    const { payload } = await dispatch(profileCreditCardsThunk.fetchAddCreditCard());
    if (payload) {
      setStripeClientSecret(payload.secret);
    }
  };

  const handleCardAdded = (): void => {
    setStripeClientSecret(undefined);
    setShowCardsLoader(true);
    setTimeout(() => getCreditCards(), 5_000); // 5 sec, enough time for Stripe web hook
  };

  const handleSetCardAsDefault = async (paymentMethodId: string): Promise<void> => {
    const { payload } = await dispatch(
      profileCreditCardsThunk.fetchMakeCreditCardDefault({ paymentMethodId }),
    );

    if (payload) {
      const updatedCards = cards.map((card) => ({
        ...card,
        isDefault: card.paymentMethodId === paymentMethodId,
      }));
      setCards(updatedCards);
    }
  };

  useEffect(() => {
    getCreditCards();
  }, []);

  return (
    <Grid container rowGap={1} spacing={1}>
      <Grid container spacing={2} justifyContent={'center'} p={2}>
        <Grid item xs={12} sm={12} lg={6} position={'relative'}>
          {showCardsLoader && (
            <CircularProgress
              sx={styles.CircularProgress1SX}
              size={20}
              color="primary"
              thickness={8}
            />
          )}

          <Box component={Paper} sx={styles.Box1SX}>
            <List sx={styles.List1SX}>
              {cards.map((card, index) => {
                return (
                  <Box key={index}>
                    <ListItem>
                      <ListItemAvatar>
                        {CARDS_IMAGES[card.brand as never] ? (
                          <Avatar src={CARDS_IMAGES[card.brand as never]} />
                        ) : (
                          <CreditCardIcon sx={{ width: 40, height: 40, color: '#1E90FF' }} />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={`**** **** **** ${card.last4}`}
                        secondary={card.brand.toUpperCase()}
                        secondaryTypographyProps={{ fontSize: 11, fontWeight: 'bold' }}
                        sx={{ width: 150 }}
                      />
                      <ListItemText
                        sx={styles.ListItemText1SX}
                        secondary={`Expires: ${card.expMonth}/${card.expYear}`}
                      />

                      <Box sx={styles.Box4SX}>
                        {card.isDefault && <DoneIcon sx={styles.Icon1SX} color={'success'} />}
                        <Checkbox
                          edge="start"
                          icon={<CreditCardIcon />}
                          checkedIcon={<CreditCardIcon color="success" />}
                          checked={card.isDefault}
                          onChange={() => handleSetCardAsDefault(card.paymentMethodId)}
                        />
                      </Box>
                    </ListItem>
                    <Divider />
                  </Box>
                );
              })}
            </List>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} lg={6}>
          <Box component={Paper} sx={styles.Box2SX}>
            {!stripeClientSecret ? (
              <Button variant="contained" color="primary" onClick={handleAddCreditCard}>
                Add card
              </Button>
            ) : (
              <Box sx={styles.Box3SX}>
                <Elements stripe={stripePromise} options={{ clientSecret: stripeClientSecret }}>
                  <StripeCheckoutForm
                    setIsPaymentProcessed={() => {}}
                    buttonText="SAVE"
                    handlePaymentSuccessful={handleCardAdded}
                  />
                </Elements>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Subscriptions;

import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useFormik } from 'formik';

import {
  Typography,
  Box,
  Button,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  CircularProgress,
  Select,
  Paper,
} from '@mui/material';

import { IPhoneNumbersDirectExtensionResult } from '@/interfaces/phone-numbers';
import { IUserCreateRequest, IUserUpdateRequest } from '@/interfaces/users/users';

import userValidationSchemas from '@/helpers/validation-schemas/users/index';

import * as usersThunk from '@/store/thunk/dashboard/users';
import * as phoneNumbersThunk from '@/store/thunk/phone-numbers';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import useTemplateDialog from '@/hooks/use-template-dialog';

import DirectExtensionSelect from '@/components/Formik-form-components/Direct-extension-select';
import { TemplateDialog } from '@/components/Dialogs/Template-dialog';
import UploadFileInput from '@/components/Upload-file-input/Upload-file-input';

import { defStyles } from '@/theme';

import * as SNACKBAR from '@/constants/snackbar';
import ROUTES from '@/constants/routes';
import { ROLES, ROLES_LIST } from '@/constants/roles';
import { PHONE_NUMBER_TYPE } from '@/constants/phone-numbers';
import { FORMIK_STATUS } from '@/constants/formik';

const roles = ROLES_LIST.filter((role) => role !== ROLES.SUPER_ADMIN).map((role) => role);

const AddEditUser: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const user = useAppSelector((state) => state.user.user);
  const tenant = useAppSelector((state) => state.tenant.tenant);

  const [directExtensions, setDirectExtensions] = useState<IPhoneNumbersDirectExtensionResult[]>(
    [],
  );

  const [dialogState, dialogStateActions] = useTemplateDialog({
    isOpened: false,
    title: 'Tranfer messages',
    textContent: 'Do you wish to transfer all chats/messages of selected Direct extension to user?',
    dialogProps: { maxWidth: 'md' },
    submitButtonProps: { children: 'Yes, transfer all messages' },
    closeButtonProps: {
      color: 'primary',
      children: "No, don't transfer any messages",
    },
  });

  const userToEdit = state?.data;
  const isEditing = !!userToEdit;

  const formik = useFormik<IUserCreateRequest | IUserUpdateRequest>({
    initialStatus: FORMIK_STATUS.LOADING,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: isEditing
      ? {
          firstName: userToEdit.firstName,
          lastName: userToEdit.lastName ?? '',
          email: userToEdit.email,
          companyExtension: userToEdit.companyExtension,
          sip: userToEdit.sip ?? '',
          directExtension: userToEdit.directExtension ?? null,
          role: userToEdit.role,
          ipPhoneMac: userToEdit.ipPhone?.mac ?? '',
          file: undefined,
          transferChatsToAssignedUser: false,
        }
      : {
          firstName: '',
          lastName: '',
          email: '',
          companyExtension: '',
          sip: '',
          directExtension: '',
          role: ROLES.USER,
          file: undefined,
          transferChatsToAssignedUser: false,
        },
    validationSchema: isEditing ? userValidationSchemas.updateUser : userValidationSchemas.create,
    onSubmit: async (formikValues, formikHelpers) => {
      if (dialogState.isOpened) {
        return;
      }

      if (isEditing) {
        const values = formikValues as IUserUpdateRequest;

        if (values.directExtension && values.directExtension !== userToEdit?.directExtension) {
          const response = await dialogStateActions.openDialog();

          if (response === null) {
            return;
          } else {
            values.transferChatsToAssignedUser = response;
          }
        }

        const formData = new FormData();

        formData.append('firstName', values.firstName);
        formData.append('email', values.email);
        formData.append('companyExtension', values.companyExtension);
        formData.append('role', values.role);

        if (values?.ipPhoneMac && values?.ipPhoneMac !== user?.ipPhone?.mac) {
          formData.append('ipPhoneMac', values.ipPhoneMac);
        }

        if (values.lastName) {
          formData.append('lastName', values.lastName);
        }

        if (values.sip) {
          formData.append('sip', values.sip);
        }

        if (values.directExtension !== userToEdit?.directExtension) {
          formData.append('directExtension', values.directExtension ?? '');
          formData.append(
            'transferChatsToAssignedUser',
            values.transferChatsToAssignedUser.toString(),
          );
        }

        if (values.file) {
          formData.append('file', values.file);
        }

        const { payload } = await dispatch(usersThunk.fetchEdit({ id: userToEdit.id, formData }));

        if (payload) {
          formikHelpers.resetForm();

          dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.UPDATED('User')));
          navigate(ROUTES.DASHBOARD_USERS);
        }
      } else {
        const values = formikValues as IUserCreateRequest;

        if (values.directExtension) {
          const previousDirectExtension = directExtensions.find(
            (el) => el.phoneNumber === values.directExtension && el.previousUserRef,
          );

          if (previousDirectExtension?.previousUserRef) {
            const response = await dialogStateActions.openDialog();

            if (response === null) {
              return;
            } else {
              values.transferChatsToAssignedUser = response;
            }
          }
        }

        const formData = new FormData();

        formData.append('firstName', values.firstName);
        formData.append('email', values.email);
        formData.append('companyExtension', values.companyExtension);
        formData.append('role', values.role);

        if (values.lastName) {
          formData.append('lastName', values.lastName);
        }

        if (values.sip) {
          formData.append('sip', values.sip);
        }

        if (values.directExtension) {
          formData.append('directExtension', values.directExtension ?? '');
          formData.append(
            'transferChatsToAssignedUser',
            values.transferChatsToAssignedUser.toString(),
          );
        }

        if (values.file) {
          formData.append('file', values.file);
        }

        const { payload } = await dispatch(usersThunk.fetchCreateUser(formData));

        if (payload) {
          formikHelpers.resetForm();

          dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.CREATED('User')));
          navigate(ROUTES.DASHBOARD_USERS);
        }
      }
    },
  });

  const loadDirectExtensions = async (): Promise<void> => {
    if (!tenant) {
      return;
    }

    const { payload } = await dispatch(
      phoneNumbersThunk.fetchGetPhoneNumbers({
        type: PHONE_NUMBER_TYPE.DIRECT_EXTENSION,
        limit: 0,
        skip: 0,
        sortDirection: undefined,
        sortField: undefined,
        search: '',
      }),
    );

    if (payload) {
      setDirectExtensions(payload);
    }

    return;
  };

  const handleLoadFormDependencies = async (): Promise<void> => {
    if (!tenant) {
      return;
    }

    formik.setStatus(FORMIK_STATUS.LOADING);

    await loadDirectExtensions();

    formik.setStatus(FORMIK_STATUS.IDLE);
  };

  useEffect(() => {
    handleLoadFormDependencies();
  }, [tenant?.id]);

  const isLoading = formik.status === FORMIK_STATUS.LOADING;

  const formikAsUpdateUser = formik as ReturnType<typeof useFormik<IUserUpdateRequest>>;

  return (
    <Box
      display="flex"
      flex={1}
      gap={1}
      p={2}
      overflow="auto"
      position="relative"
      justifyContent="center"
    >
      <TemplateDialog {...dialogState} />

      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        gap={1}
        p={2}
        overflow="auto"
        maxWidth="40rem"
        component={Paper}
        sx={defStyles.scroll}
      >
        <Typography
          component="p"
          color="primary.main"
          fontWeight="bold"
          textAlign="center"
          fontSize={24}
        >
          {isEditing ? 'Edit' : 'Add'} user
        </Typography>

        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gap={1}
          flex={1}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            size="small"
            fullWidth
            required
            variant="standard"
            label="First name"
            id="firstName"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />

          <TextField
            size="small"
            fullWidth
            variant="standard"
            label="Last name"
            id="lastName"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />

          <TextField
            size="small"
            fullWidth
            required
            variant="standard"
            label="Email"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <Box display="flex" gap="inherit">
            <TextField
              fullWidth
              required
              size="small"
              variant="standard"
              label="Company extension"
              id="companyExtension"
              name="companyExtension"
              value={formik.values.companyExtension}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.companyExtension && Boolean(formik.errors.companyExtension)}
              helperText={formik.touched.companyExtension && formik.errors.companyExtension}
            />

            {userToEdit?.role !== ROLES.SUPER_ADMIN && (
              <FormControl fullWidth variant="standard" size="small">
                <InputLabel id="role-select-label">Role</InputLabel>

                <Select
                  labelId="role-select-label"
                  id="role"
                  size="small"
                  name="role"
                  fullWidth
                  label="Role"
                  value={formik.values.role}
                  variant="standard"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {roles.map((role) => {
                    return (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </Box>

          {user?.role === ROLES.SUPER_ADMIN && (
            <TextField
              size="small"
              fullWidth
              variant="standard"
              label="SIP"
              id="sip"
              name="sip"
              value={formik.values.sip}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.sip && Boolean(formik.errors.sip)}
              helperText={formik.touched.sip && formik.errors.sip}
            />
          )}

          {/* 
            For now leave as is because ip phone configuration is not used
            {isUserRoleMatches([ROLES.SUPER_ADMIN, ROLES.ADMIN]) && isEditing ? (
              <TextField
                size="small"
                fullWidth
                variant="standard"
                label="Ip phone mac address"
                id="ipPhoneMac"
                name="ipPhoneMac"
                value={formikAsUpdateUser.values.ipPhoneMac}
                onChange={formikAsUpdateUser.handleChange}
                onBlur={formikAsUpdateUser.handleBlur}
                error={
                  formikAsUpdateUser.touched.ipPhoneMac &&
                  Boolean(formikAsUpdateUser.errors.ipPhoneMac)
                }
                helperText={
                  formikAsUpdateUser.touched.ipPhoneMac && formikAsUpdateUser.errors.ipPhoneMac
                }
              />
            ) : null} */}

          <DirectExtensionSelect
            formik={formik}
            fieldName="directExtension"
            directExtensions={directExtensions}
            isLoading={isLoading}
            userInEditId={userToEdit?.id}
            formControlProps={{
              size: 'small',
            }}
          />

          <Box mt={2}>
            <UploadFileInput
              accept="image/*"
              label="Upload avatar"
              value={formik.values.file}
              onChange={({ file }) => {
                formik.setFieldValue('file', file, true);
                formik.setFieldTouched('file', true);
              }}
              error={formik.touched.file && Boolean(formik.errors.file)}
              helperText={formik.touched.file ? formik.errors.file : ''}
              buttonProps={{
                size: 'small',
              }}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" mt="auto">
            <Button
              disabled={formik.isSubmitting}
              color="primary"
              size="large"
              variant="contained"
              type="submit"
            >
              {formik.isSubmitting ? <CircularProgress /> : 'Save'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditUser;

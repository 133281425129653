import { Outlet } from 'react-router-dom';

import { Box, Grid, Paper, Stack } from '@mui/material';

import NavTab from '@/components/Tabs-navigation/Tabs-navigation';

import * as styles from './styles';

import ROUTES from '@/constants/routes';

const ContactsPageLayout = () => {
  const links = [
    { label: 'Contacts', path: ROUTES.DASHBOARD_CONTACTS },
    { label: 'Groups', path: ROUTES.DASHBOARD_CONTACTS_GROUPS },
  ];

  return (
    <Box sx={styles.box1SX}>
      <Stack spacing={2} component={Paper}>
        <NavTab links={links} />
        <Outlet />
      </Stack>
    </Box>
  );
};

export default ContactsPageLayout;

import { Dispatch, FunctionComponent, SetStateAction } from 'react';

import { Box, ListItem, ListItemAvatar, Tooltip, Avatar, Paper, Divider } from '@mui/material';

import { Sports as SportsIcon } from '@mui/icons-material';

import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';

import { IMessageResult } from '@/interfaces/message';

import * as dateHelper from '@/helpers/date/date';

import MessageText from '@/pages/dashboard/chat/chat-messages/classic/Message-text';
import MessageImages from './Message-images';
import MessageSenderInfoTooltip from './Message-sender-info-tooltip';

import * as styles from '@/pages/dashboard/chat/style';

import MESSAGES from '@/constants/message';

interface IMessageProps {
  message: IMessageResult;
  setImagePreview: Dispatch<SetStateAction<string | null>>;
  onAvatarClick: VoidFunction;
}

const messageStatusIconColor = (status: string): string => {
  if (MESSAGES.STATUS.FAILED === status) {
    return '#DC143C';
  }
  if (MESSAGES.STATUS.QUEUED === status) {
    return '#FF8C00';
  }
  return 'green';
};

const Message: FunctionComponent<IMessageProps> = ({
  message,
  setImagePreview,
  onAvatarClick,
}): JSX.Element => {
  const avatar = message.isInternal ? message.userSender?.avatarUrl : message.user?.avatarUrl;

  const isSenderMessage = message.trackType === MESSAGES.TRACK_TYPE.OUTBOUND;

  return (
    <Box
      id={message.id}
      display="flex"
      justifyContent={{ justifyContent: isSenderMessage ? 'flex-end' : 'flex-start' }}
    >
      <ListItem
        alignItems="flex-start"
        sx={{
          minWidth: '50%',
          maxWidth: '75%',
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: isSenderMessage ? 'row-reverse' : 'row',
        }}
      >
        <ListItemAvatar onClick={onAvatarClick}>
          <Tooltip placement="bottom" title={<MessageSenderInfoTooltip message={message} />}>
            <Avatar src={avatar ?? DefaultAvatar} />
          </Tooltip>
        </ListItemAvatar>

        <Paper elevation={5} sx={styles.messagePaperSX}>
          <Box sx={styles.messageBox1SX}>
            {[MESSAGES.TYPE.MEDIA, MESSAGES.TYPE.TEXT_MEDIA].includes(message.type) ? (
              <MessageImages
                //@ts-ignore
                files={message.files}
                setImagePreview={setImagePreview}
              />
            ) : null}

            <MessageText text={message.text} />

            <Divider variant="fullWidth" component="div" />

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: isSenderMessage ? 'space-between' : 'flex-end',
              }}
            >
              <Box
                style={{
                  fontSize: 9,
                  color: 'grey',
                  marginTop: 6,
                  marginBottom: 6,
                }}
              >
                {dateHelper.format(message.createdAt, 'yyyy-MM-dd hh:mm a')}
              </Box>

              {isSenderMessage ? (
                <Tooltip title={message.status}>
                  <SportsIcon
                    sx={{ ...styles.checkIcon1SX, color: messageStatusIconColor(message.status) }}
                  />
                </Tooltip>
              ) : null}
            </Box>
          </Box>
        </Paper>
      </ListItem>
    </Box>
  );
};

export default Message;

import { Dispatch, FunctionComponent, SetStateAction } from 'react';

import { Box, IconButton, IconButtonProps, Tooltip, Typography } from '@mui/material';
import {
  MicOff as MicOffIcon,
  PhonePaused as PhonePausedIcon,
  PhoneForwarded as PhoneForwardedIcon,
  CallEnd as CallEndIcon,
  Call as CallIcon,
  PersonAdd as PersonAddIcon,
} from '@mui/icons-material';

import { IUserResult } from '@/interfaces/users/users';
import { IConnection } from '@/interfaces/voice/call';

import DialMobileKeyboard from '@/pages/dashboard/voice/call-status/components/Dial-mobile-keyboard';

import DialTextField from '../components/Dial-text-field';
import CallOrConferenceActions from '../components/Call-or-conference-actions';

interface IActiveConferenceProps {
  buildTimer: (
    counter: { minutes: number | undefined; seconds: number | undefined } | undefined,
  ) => string;

  selectedConnection: IConnection | undefined;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  countryCode: IUserResult['allowedCountries'][0] | null;
  setCountryCode: Dispatch<SetStateAction<IUserResult['allowedCountries'][0] | null>>;
  isForwardProcessInProgress: boolean;

  isMenuBtnDisabled: (buttonType?: string) => boolean;
  getCallActionIconButtonSxProps: (color: string) => IconButtonProps['sx'];
  isHoldPulsation: () => boolean;

  handleMute: () => Promise<void>;
  handleHoldOrResumeCall: () => Promise<void>;
  handleStartCall: () => Promise<void>;
  handleForward: () => Promise<void>;
  handleCancelForward: VoidFunction;
  handleEndCall: () => Promise<void>;
  handleSendDigits: (value: string) => void;
  handleAddParticipant: VoidFunction;
}

const ActiveConference: FunctionComponent<IActiveConferenceProps> = ({
  buildTimer,
  selectedConnection,
  phoneNumber,
  setPhoneNumber,
  countryCode,
  setCountryCode,
  isForwardProcessInProgress,
  isMenuBtnDisabled,
  getCallActionIconButtonSxProps,
  isHoldPulsation,
  handleMute,
  handleHoldOrResumeCall,
  handleStartCall,
  handleForward,
  handleCancelForward,
  handleAddParticipant,
  handleEndCall,
  handleSendDigits,
}): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        bgcolor="primary.main"
        color="primary.contrastText"
        py={1}
        px={2}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body1">Conference</Typography>

          <Typography variant="body2">
            {selectedConnection?.participants.length === 0 ? 'No participants' : null}

            {selectedConnection?.participants.length === 1 ? '1 participant' : null}

            {selectedConnection && selectedConnection?.participants.length > 1
              ? `${selectedConnection?.participants.length} participants`
              : null}
          </Typography>
        </Box>

        <Typography variant="body1" fontSize="1.5rem">
          {buildTimer({
            minutes: selectedConnection?.counter?.minutes,
            seconds: selectedConnection?.counter?.seconds,
          })}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap={1} px={2} alignItems="center">
        <DialTextField
          disabled={!selectedConnection}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          handleOnBackspacePress={() => {
            setPhoneNumber((prevState) => prevState.slice(0, -1));
          }}
        />

        <DialMobileKeyboard
          disabled={!selectedConnection}
          handleDialChange={(value) => {
            handleSendDigits(value);
            setPhoneNumber((prevState) => prevState + value);
          }}
        />
      </Box>

      <CallOrConferenceActions
        selectedConnection={selectedConnection}
        isForwardProcessInProgress={isForwardProcessInProgress}
        isMenuBtnDisabled={isMenuBtnDisabled}
        getCallActionIconButtonSxProps={getCallActionIconButtonSxProps}
        isHoldPulsation={isHoldPulsation}
        handleMute={handleMute}
        handleHoldOrResumeCall={handleHoldOrResumeCall}
        handleStartCall={handleStartCall}
        handleForward={handleForward}
        handleCancelForward={handleCancelForward}
        handleEndCall={handleEndCall}
        handleAddParticipant={handleAddParticipant}
      />
    </Box>
  );
};

export default ActiveConference;

import { Box, Button } from '@mui/material';

const pages = ['Privacy Policy', 'Terms of Service', 'Contact us'];

const PublicLayoutFooter = () => {
  const handleOpenLegalInfo = (which: string): void => {
    if (which === 'Contact us') {
      window.open('https://whistle.tech/contact-us/', '_blank', 'noopener,noreferrer');
      return;
    }

    window.open('https://whistle.tech/tos/', '_blank', 'noopener,noreferrer');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'primary.main',
        position: 'sticky',
        bottom: 0,
        height: '64px',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      {pages.map((page) => (
        <Button
          disableRipple
          disableTouchRipple
          key={page}
          color="primary"
          variant="contained"
          onClick={() => handleOpenLegalInfo(page)}
        >
          {page}
        </Button>
      ))}
    </Box>
  );
};

export default PublicLayoutFooter;

import { IContact, IGetContactsQueryParams } from '@/interfaces/contact';
import { IUseMultiCheckboxSelectActions } from '@/hooks/use-multi-checkbox-select';

import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';

import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  ContentCopy as ContentCopyIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  MessageOutlined as MessageOutlinedIcon,
  Call as CallIcon,
  WhatsApp as WhatsAppIcon,
} from '@mui/icons-material';

import { format } from '@/helpers/date/date';

import TemplateTable, { ITemplateTableProps } from '@/components/Template-table/Template-table';
import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';

import { DATE_FORMAT_LONG, NO_DATA_VALUE } from '@/constants/layout';
import { CONTACT_TYPE } from '@/constants/contacts';
import CHAT from '@/constants/chat';

interface IContactsListProps {
  data: IContact[];
  selectedContactsIdsActions: IUseMultiCheckboxSelectActions<string>;
  queryParams: IGetContactsQueryParams;
  setQueryParams: Dispatch<SetStateAction<IGetContactsQueryParams>>;
  handleUpdateContact: (el: IContact) => void;
  handleDeleteContact: (el: IContact) => void;
  handleCopyContact: (el: IContact) => void;
  handleCallContact: (el: IContact) => void;
  handleSendMessageToContact: (el: IContact, chatPlatform: CHAT.PLATFORM) => void;
  isWhatsAppEnabled: boolean;
}

interface IContactActionsMenuButtonProps
  extends Omit<
    IContactsListProps,
    | 'queryParams'
    | 'data'
    | 'selectedContactsIdsActions'
    | 'queryParams'
    | 'setQueryParams'
    | 'handleCallContact'
    | 'handleSendMessageToContact'
    | 'isWhatsAppEnabled'
  > {
  contact: IContact;
}

const ContactActionsMenuButton: FunctionComponent<IContactActionsMenuButtonProps> = ({
  contact,
  handleDeleteContact,
  handleUpdateContact,
  handleCopyContact,
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isCompanyContact = contact.type === CONTACT_TYPE.COMPANY;
  const isOpened = !!anchorEl;

  return (
    <IconButton
      color="primary"
      onClick={(event) => {
        event.stopPropagation();
        setAnchorEl((prevState) => (prevState ? null : event.currentTarget));
      }}
    >
      <Menu
        anchorEl={anchorEl}
        open={isOpened}
        disableAutoFocusItem
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleUpdateContact(contact)}>
          <ListItemIcon>
            <EditIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Edit
        </MenuItem>

        <MenuItem onClick={() => handleCopyContact(contact)}>
          <ListItemIcon>
            <ContentCopyIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Copy to {isCompanyContact ? 'private' : 'company'}
        </MenuItem>

        <MenuItem onClick={() => handleDeleteContact(contact)}>
          <ListItemIcon>
            <DeleteOutlineIcon color="error" fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>

      <MoreVertIcon />
    </IconButton>
  );
};

const ContactsList: FunctionComponent<IContactsListProps> = ({
  data,
  queryParams,
  setQueryParams,
  selectedContactsIdsActions,
  isWhatsAppEnabled,
  handleUpdateContact,
  handleDeleteContact,
  handleCopyContact,
  handleCallContact,
  handleSendMessageToContact,
}): JSX.Element => {
  const templateTableProps: ITemplateTableProps<IContact> = {
    data,
    getDataEntityId: (el) => el?.id + el.firstName,
    getTableRowProps: (el) => ({
      role: 'checkbox',
      tabIndex: -1,
      hover: true,
      onClick: () => selectedContactsIdsActions.handleSelect(el.id as string),
      selected: selectedContactsIdsActions.isSelected(el.id as string),
      'aria-checked': selectedContactsIdsActions.isSelected(el.id as string),
      sx: { cursor: 'pointer' },
    }),
    columns: [
      {
        sharedProps: { padding: 'checkbox', sx: { py: 0 } },
        getHeader: () => {
          const dataToCompare = data.map((el) => el.id) as string[];

          return (
            <Checkbox
              color="primary"
              indeterminate={selectedContactsIdsActions.isAllOptionHasIndeterminateState(
                dataToCompare,
              )}
              checked={selectedContactsIdsActions.isAllOptionSelected(dataToCompare)}
              onChange={(event) =>
                selectedContactsIdsActions.handleSelectAll(event.target.checked, dataToCompare)
              }
              inputProps={{
                'aria-label': 'Select all contacts',
              }}
            />
          );
        },
        getValue: (el) => (
          <Checkbox
            color="primary"
            checked={selectedContactsIdsActions.isSelected(el.id as string)}
            inputProps={{
              'aria-labelledby': el.id,
            }}
          />
        ),
      },
      {
        sharedProps: { sx: { py: 0 } },
        header: 'Avatar',
        getValue: (el) => (
          <Box width={40} height={40}>
            <Avatar src={el.avatar?.url ? el.avatar?.url : DefaultAvatar} />
          </Box>
        ),
      },
      {
        header: 'First name',
        sortFieldName: 'firstName',
        getValue: (el) => el.firstName,
      },
      {
        header: 'Last name',
        sortFieldName: 'lastName',
        getValue: (el) => el.lastName,
      },
      {
        header: 'Email',
        sortFieldName: 'email',
        getValue: (el) => el.email || NO_DATA_VALUE,
      },
      {
        header: 'Phone number',
        sortFieldName: 'phoneNumber',
        getValue: (el) => el.phoneNumber,
      },
      {
        header: 'Imported At',
        sortFieldName: 'importedAt',
        getValue: (el) => (el.importedAt ? format(el.importedAt, DATE_FORMAT_LONG) : NO_DATA_VALUE),
      },
      {
        sharedProps: { align: 'center', sx: { py: '.25rem', maxWidth: '12rem' } },
        header: 'Actions',
        getValue: (el) => (
          <Box display="flex" gap={1} justifyContent="center">
            <Tooltip title="Start a call">
              <IconButton
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  handleCallContact(el);
                }}
              >
                <CallIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Send message">
              <IconButton
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  handleSendMessageToContact(el, CHAT.PLATFORM.SMS);
                }}
              >
                {/* Anil wanted it a bit smaller */}
                <MessageOutlinedIcon sx={{ fontSize: 19 }} />
              </IconButton>
            </Tooltip>

            {isWhatsAppEnabled ? (
              <Tooltip title="Send WhatsApp message">
                <IconButton
                  sx={{ color: 'success.main' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSendMessageToContact(el, CHAT.PLATFORM.WHATSAPP);
                  }}
                >
                  <WhatsAppIcon color="success" fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}

            <ContactActionsMenuButton
              contact={el}
              handleUpdateContact={handleUpdateContact}
              handleDeleteContact={handleDeleteContact}
              handleCopyContact={handleCopyContact}
            />
          </Box>
        ),
      },
    ],
    onSortChange: (event, sortParams) =>
      setQueryParams((prevState) => ({ ...prevState, ...sortParams })),
    currentSortParams: {
      sortField: queryParams.sortField,
      sortDirection: queryParams.sortDirection,
    },
    tableContainerProps: { sx: { height: 'calc(100vh - 24rem)' } },
  };

  return <TemplateTable {...templateTableProps} />;
};

export default ContactsList;

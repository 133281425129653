import { FunctionComponent } from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

import { TemplateDialogButton } from '@/components/Dialogs/Template-dialog';

interface IBroadcastValidationDialogProps {
  isOpened: boolean;
  handleClose: VoidFunction;
  handleGoBackAndUpdateStopTime: VoidFunction;
  errors: string[];
}

const BroadcastValidationDialog: FunctionComponent<IBroadcastValidationDialogProps> = ({
  isOpened,
  handleClose,
  handleGoBackAndUpdateStopTime,
  errors,
}): JSX.Element => {
  return (
    <Dialog open={isOpened} fullWidth maxWidth="sm">
      <DialogTitle>Validation information</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Following issues were found during validation of broadcast data:
        </DialogContentText>

        <Box component="ul">
          {errors.map((el) => (
            <Box component="li">
              <Typography variant="body1" component="span">
                {el}
              </Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <TemplateDialogButton color="secondary" onClick={handleClose}>
          Close
        </TemplateDialogButton>

        <TemplateDialogButton color="primary" onClick={handleGoBackAndUpdateStopTime}>
          Go back and update stop date and time
        </TemplateDialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default BroadcastValidationDialog;

import makeRequest from '../make-request';

const GET_ALL = '/api/v1/features-comparison';

export const getAll = async () => {
  try {
    const result = await makeRequest(GET_ALL, {
      method: 'GET',
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

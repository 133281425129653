import React, { FunctionComponent } from 'react';
import { TransitionProps } from 'notistack';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Slide,
  Tooltip,
  styled,
} from '@mui/material';

import { IConnection } from '@/interfaces/voice/call';
import { ICallsTranscriptionResult } from '@/interfaces/voice/call-transcription';

import { format } from '@/helpers/date/date';

import CustomAvatar from '@/components/Custom-avatar/Avatar';

import CALL from '@/constants/voice/voice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface ILiveTranscriptionDialogProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  connection: IConnection | undefined;
  liveCallsTranscription: boolean;
}

const LiveTranscriptionDialog: FunctionComponent<ILiveTranscriptionDialogProps> = ({
  show,
  setShow,
  connection,
  liveCallsTranscription,
}): JSX.Element => {
  const handleClose = (): void => {
    setShow(false);
  };

  const handleLiveStatusColor = (value: CALL.TRANSCRIPTION_STATUS): string => {
    if (!liveCallsTranscription) {
      return 'secondary.main';
    }

    if (value === CALL.TRANSCRIPTION_STATUS.ERROR) {
      return 'error.main';
    }
    if (value === CALL.TRANSCRIPTION_STATUS.ON_HOLD) {
      return 'gray';
    }
    if (value === CALL.TRANSCRIPTION_STATUS.ONLINE) {
      return 'success.main';
    }

    return 'gray';
  };

  if (!connection) {
    return <></>;
  }

  return (
    <BootstrapDialog
      fullWidth
      maxWidth="sm"
      open={show}
      keepMounted={false}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box display={'flex'} alignItems={'center'} px={2} pt={2}>
        <Tooltip
          title={
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant="caption" mb={1}>
                {liveCallsTranscription
                  ? connection.transcriptionStatus.toUpperCase()
                  : 'LIVE CALL TRANSCRIPTION IS DISABLED'}
              </Typography>
              <Typography variant="caption">
                Quick note: For the best experience with our live call transcription feature, it's
                important to have good voice quality(microphone, internet connection). This helps
                our AI accurately transcribe the conversation in real-time.
              </Typography>
            </Box>
          }
        >
          <Box
            borderRadius={20}
            width={15}
            height={15}
            bgcolor={handleLiveStatusColor(connection.transcriptionStatus)}
            mr={1}
          />
        </Tooltip>

        <Typography variant="h6">AI Live Call Transcription</Typography>
      </Box>

      <DialogContent sx={{ px: 1 }}>
        <List sx={{ height: 400 }} dense={false}>
          {connection?.transcription.map(
            (element: ICallsTranscriptionResult['transcriptions'][0], index) => {
              return (
                <ListItem alignItems="flex-start" key={index} sx={{ p: 0, py: 1 }}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    width={'100%'}
                    p={1}
                  >
                    <CustomAvatar url={''} key={index} showLiveStatus={false} />
                    <Box display={'flex'} width={'100%'} ml={1} flexDirection={'column'}>
                      <Typography fontSize={12}>{element.label}</Typography>
                      <Typography>{element.text}</Typography>
                      <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
                        <Typography fontSize={11} color={'Highlight'}>
                          AI transcription accuracy: {(element.confidence * 100).toFixed()}%
                        </Typography>
                        <Typography fontSize={11}>
                          {format(element.createdAt, 'hh:mm:ss a')}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              );
            },
          )}
        </List>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default LiveTranscriptionDialog;

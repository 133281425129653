import makeRequest from '../make-request-v2';
import token from '../token';
import headers from '../headers';

import {
  IGetUsersGroupsQueryParams,
  IUsersGroupCreateRequest,
  IUsersGroupResult,
  IUsersGroupUpdateRequest,
} from '@/interfaces/users-groups';
import { IPartialResult } from '@/interfaces/querying-data';
import { IResponseResult } from '@/interfaces/base';

const GET_ALL = '/api/v1/users-groups';
const DELETE_BY_ID = (usersGroupId: string) => `/api/v1/users-groups/${usersGroupId}`;
const CREATE = `/api/v1/users-groups`;
const UPDATE_BY_ID = (usersGroupId: string) => `/api/v1/users-groups/${usersGroupId}`;

export const getByParams = async (
  data: IGetUsersGroupsQueryParams,
): Promise<IResponseResult<IPartialResult<IUsersGroupResult>>> => {
  const queryParams: Record<string, string> = {
    limit: data.limit.toString(),
    skip: data.skip.toString(),
  };

  if (data.search) {
    queryParams.search = data.search;
  }

  if (data.sortField) {
    queryParams.sortField = data.sortField;
  }

  if (data.sortDirection) {
    queryParams.sortDirection = data.sortDirection.toString();
  }

  return makeRequest(GET_ALL, {
    method: 'GET',
    headers: { Authorization: await token() },
    query: queryParams,
  });
};

export const deleteById = async (usersGroupId: string): Promise<IResponseResult<string>> => {
  return makeRequest(DELETE_BY_ID(usersGroupId), {
    method: 'DELETE',
    headers: { Authorization: await token() },
  });
};

export const create = async (
  data: IUsersGroupCreateRequest,
): Promise<IResponseResult<IUsersGroupResult>> => {
  return makeRequest(CREATE, {
    method: 'POST',
    headers: {
      Authorization: await token(),
      ...headers.applicationJson(),
    },
    body: JSON.stringify(data),
  });
};

export const update = async (
  usersGroupId: string,
  data: IUsersGroupUpdateRequest,
): Promise<IResponseResult<IUsersGroupResult>> => {
  return makeRequest(UPDATE_BY_ID(usersGroupId), {
    method: 'PUT',
    headers: {
      Authorization: await token(),
      ...headers.applicationJson(),
    },
    body: JSON.stringify(data),
  });
};

import { PropsWithChildren, ReactElement } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import useAccess from '@/hooks/use-access';

import { ADDONS } from '@/constants/addons';

interface IAddonsGuardProps extends Required<PropsWithChildren> {
  addons: ADDONS[];
  // either defaultOnNoAccessRedirectTo or onNoAccessRedirectTo must be provided
  defaultOnNoAccessRedirectTo: string;
  onNoAccessRedirects?: Record<ADDONS, string>;
}

const AddonsGuard = ({
  onNoAccessRedirects,
  defaultOnNoAccessRedirectTo,
  addons,
  children,
}: IAddonsGuardProps) => {
  const { isAddonAvailable } = useAccess();
  const location = useLocation();

  const unavailableAddon = addons.find((el) => !isAddonAvailable(el));

  if (unavailableAddon) {
    const redirectTo = onNoAccessRedirects?.[unavailableAddon] ?? defaultOnNoAccessRedirectTo;

    if (!location.pathname.includes(redirectTo)) {
      return <Navigate replace to={redirectTo} />;
    }
  }

  return children as ReactElement;
};

export default AddonsGuard;

import yup from '@/helpers/yup';

import { getRequired } from '@/helpers/form-fields-error-messages';

export default yup.object().shape({
  ip: yup.string().required(getRequired('IP')),
  description: yup.string().nullable().optional().default(null),
  deleteAfterMin: yup
    .number()
    .nullable()
    .min(5, 'Auto-delete IP after minutes must be greater than or equal to 5')
    .max(60, 'Auto-delete IP after minutes must be less than or equal to 60')
    .optional()
    .default(null),
});

import { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';

import {
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';

import { IExtensionsGroupResult } from '@/interfaces/extensions-groups';

import * as extensionGroupsThunk from '@/store/thunk/dashboard/extensions-groups';

import * as menuReducer from '@/store/reducers/dashboard/menu';

import TemplateTable, { ITemplateTableProps } from '@/components/Template-table/Template-table';
import DataTemplate from '@/components/Data-template/Data-template';

import { NO_DATA_VALUE } from '@/constants/layout';
import DASHBOARD from '@/constants/dashboard';
import ROUTES from '@/constants/routes';

interface IExtensionsGroupMenuState {
  anchorEl: HTMLElement | null;
  selectedExtensionGroup: IExtensionsGroupResult | null;
}

const ExtensionsGroupsLayout: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [menuState, setMenuState] = useState<IExtensionsGroupMenuState>({
    anchorEl: null,
    selectedExtensionGroup: null,
  });

  const [extensionsGroups, setExtensionGroups] = useState<IExtensionsGroupResult[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLoadExtensionGroups = async (): Promise<void> => {
    setIsLoading(true);

    const { payload } = await dispatch(extensionGroupsThunk.fetchGetAll());

    if (payload) {
      setExtensionGroups(payload);
    }

    setIsLoading(false);
  };

  const handleDelete = async (): Promise<void> => {
    if (!menuState.selectedExtensionGroup) {
      return;
    }

    setIsLoading(true);

    const { meta } = await dispatch(
      extensionGroupsThunk.fetchDeleteExtensionsGroup(menuState.selectedExtensionGroup?.id),
    );

    if (meta.requestStatus === 'fulfilled') {
      await handleLoadExtensionGroups();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.EXTENSIONS_GROUPS }));
    handleLoadExtensionGroups();
  }, []);

  const templateTableProps: ITemplateTableProps<IExtensionsGroupResult> = {
    data: extensionsGroups,
    getDataEntityId: (el) => el.id,
    columns: [
      {
        header: 'Group name',
        getValue: (el) => el.name,
      },
      {
        header: 'Connect to',
        getValue: (el) =>
          el.connectTo.map((element) => element.extension || element.phoneNumber).join(', '),
      },
      {
        header: 'Connection type',
        getValue: (el) => el.connectionType,
      },
      {
        header: 'Description',
        getValue: (el) => el.description ?? NO_DATA_VALUE,
      },
      {
        sharedProps: { align: 'center' },
        getHeader: () => (
          <Tooltip title="Create new group" placement="left">
            <IconButton
              size="small"
              color="primary"
              onClick={() => navigate(ROUTES.DASHBOARD_EXTENSIONS_GROUPS_ADD)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        ),
        getValue: (el) => (
          <Tooltip title="Manage extension group" placement="left">
            <IconButton
              size="small"
              color="primary"
              onClick={(event) =>
                setMenuState((prevState) => {
                  const isMenuOpened = !!prevState.anchorEl;

                  if (isMenuOpened) {
                    return {
                      anchorEl: null,
                      selectedExtensionGroup: null,
                    };
                  } else {
                    return {
                      anchorEl: event.currentTarget,
                      selectedExtensionGroup: el,
                    };
                  }
                })
              }
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
  };

  return (
    <Box m={2} component={Paper} flex={1} overflow="auto">
      <DataTemplate isLoading={isLoading} loadingComponentProps={{ sx: { top: 0 } }}>
        <Menu
          disableAutoFocusItem
          anchorEl={menuState.anchorEl}
          open={!!menuState.anchorEl}
          onClose={() => setMenuState({ anchorEl: null, selectedExtensionGroup: null })}
        >
          <MenuItem
            onClick={() => {
              setMenuState((prevState) => ({ ...prevState, anchorEl: null }));

              navigate(ROUTES.DASHBOARD_EXTENSIONS_GROUPS_UPDATE, {
                state: {
                  extensionsGroupToEdit: menuState.selectedExtensionGroup,
                },
              });
            }}
          >
            <ListItemIcon>
              <EditIcon color="primary" fontSize="small" />
            </ListItemIcon>
            Edit
          </MenuItem>

          <MenuItem
            onClick={() => {
              setMenuState((prevState) => ({ ...prevState, anchorEl: null }));
              handleDelete();
            }}
          >
            <ListItemIcon>
              <DeleteOutlineIcon color="error" fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>

        <TemplateTable {...templateTableProps} />
      </DataTemplate>
    </Box>
  );
};

export default ExtensionsGroupsLayout;

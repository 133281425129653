export default {
  MENU: {
    MESSAGES: 'Messages',
    MESSAGE_BROADCAST_CAMPAIGN: 'Broadcast',
    CONTACTS: 'Contacts',
    USERS: 'Users',
    VOICE: 'Voice',
    SETTINGS: 'Settings',
    EXTENSIONS_GROUPS: 'Extensions groups',
  },
};

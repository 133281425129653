import { createAsyncThunk } from '@reduxjs/toolkit';

import { ITenantCardsMakeDefaultRequest } from '@/interfaces/tenant';

import handleErrors from '@/services/handle-errors';

import { IThunkExtra } from '@/store/store';

export const fetchCreditCards = createAsyncThunk(
  'profileCreditCards/fetchCreditCards',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.getCreditCards();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchAddCreditCard = createAsyncThunk(
  'profileCreditCards/fetchAddCreditCard',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.addCreditCards();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchMakeCreditCardDefault = createAsyncThunk(
  'profileCreditCards/fetchMakeCreditCardDefault',
  async (data: ITenantCardsMakeDefaultRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.tenantsApis.makeCreditCardDefault(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

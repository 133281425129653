const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const getRequired = (entity: string, usePlural = false) =>
  `${capitalizeFirstLetter(entity)} ${usePlural ? 'are' : 'is'} required.`;

export const getInvalid = (entity: string, usePlural = false) =>
  `${capitalizeFirstLetter(entity)} ${usePlural ? 'are' : 'is'} invalid.`;

export const getEmpty = (entity: string) => `${capitalizeFirstLetter(entity)} cannot be empty.`;

export const getMax = (entity: string, amount: number | string, amountLabel = 'length') =>
  `Max ${entity.toLowerCase()}${amountLabel ? ` ${amountLabel} ` : ''}is ${amount}.`;

export const getMin = (entity: string, amount: number | string, amountLabel = 'length') =>
  `Min ${entity.toLowerCase()}${amountLabel ? ` ${amountLabel} ` : ''}is ${amount}.`;

export const getAtLeast = (amountWithEntityOrEntities: string, reason = '') =>
  `At least ${amountWithEntityOrEntities} is required${reason ? ` ${reason}` : ''}.`;

export const getAllowedOnly = (amountWithEntityOrEntities: string, reason = '') =>
  `Only ${amountWithEntityOrEntities} is allowed${reason ? ` ${reason}` : ''}.`;

export const getOneOfType = (entity: string, ...types: Array<number | string>) =>
  `${capitalizeFirstLetter(entity)} should be ${types.join(' or ')}.`;

export const PASSWORD_REGEX_REQUIREMENTS =
  'Password should contain at least one capital letter one number and one special character';
export const PASSWORDS_MATCH = 'Passwords must match';

export const START_TIME_BIGGER_THAN_END = 'Start time must be bigger than end time';
export const END_TIME_BIGGER_THAN_START = 'End time must be bigger than start time';

export const SYNC_CONTACTS_MIN_LENGTH = 'At least one contact should be included for synchronyzing';

import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import * as menuReducer from '@/store/reducers/dashboard/menu';
import * as voiceSettingsThunk from '@/store/thunk/dashboard/voice-settings';

import { VoiceSettingsIncomingSetup, IVoiceSettingsResult } from '@/interfaces/voice/settings';
import { IUserResult } from '@/interfaces/users/users';
import { IExtensionsGroupResult } from '@/interfaces/extensions-groups';

import WorkingScheduleGroups from '@/pages/dashboard/settings/voice-setup/working-schedule-groups/Working-schedule-groups';
import WorkingHours from '@/pages/dashboard/settings/voice-setup/working-hours/Working-hours';
import AfterHours from '@/pages/dashboard/settings/voice-setup/after-hours/After-hours';

import apisV1 from '@/services/api/v1/index';

import DASHBOARD from '@/constants/dashboard';

const DEFAULT_SETUP: IVoiceSettingsResult['incoming']['setup'] = {
  workingHours: {
    connectionTime: 30,
    textToSpeech: '',
    repeat: 1,
    gatherInput: true,
    timeout: 1,
    onNoAction: {
      active: true,
      extensionsGroupRef: '',
    },
    forwarding: {
      active: false,
      phoneNumber: '',
    },
    callFlow: [],
  },
  afterHours: {
    connectionTime: 30,
    textToSpeech: '',
    repeat: 1,
    gatherInput: true,
    timeout: 1,
    onNoAction: {
      active: true,
      extensionsGroupRef: '',
    },
    forwarding: {
      active: false,
      phoneNumber: '',
    },
    callFlow: [],
  },
  workingScheduleGroups: [
    {
      days: [],
      start: '09:00',
      end: '17:00',
    },
  ],
};

const VoiceSetup: React.FC = () => {
  const dispatch = useAppDispatch();

  const [previousVoiceSetup, setPreviousVoiceSetup] =
    useState<VoiceSettingsIncomingSetup>(DEFAULT_SETUP);
  const [voiceSetup, setVoiceSetup] = useState<VoiceSettingsIncomingSetup>(DEFAULT_SETUP);
  const [users, setUsers] = useState<IUserResult[]>([]);
  const [extensionsGroups, setExtensionsGroups] = useState<IExtensionsGroupResult[]>([]);

  const getVoiceSettings = async (): Promise<void> => {
    const { payload } = await dispatch(voiceSettingsThunk.fetchGetVoiceSettings());
    if (payload) {
      if (payload.incoming?.setup) {
        setPreviousVoiceSetup(payload.incoming.setup);
        setVoiceSetup(payload.incoming.setup);
      }
    }
  };

  const getExtensionsGroups = async (): Promise<void> => {
    const { payload } = await dispatch(voiceSettingsThunk.fetchExtensionsGroups());
    if (payload) {
      setExtensionsGroups(payload);
    }
  };

  const getUsers = async (): Promise<void> => {
    const { success, data } = await apisV1.usersApis.getByParams({
      excludeMe: false,
      limit: 0,
      skip: 0,
    });

    if (success) {
      setUsers(data.records);
    }
  };

  const handleDiscard = () => {
    setVoiceSetup(previousVoiceSetup);
  };

  const handleSaveGreetingSettings = async (): Promise<void> => {
    const data: typeof voiceSetup = {
      ...voiceSetup,
      afterHours: {
        ...voiceSetup.afterHours,
        onNoAction: {
          ...voiceSetup.afterHours.onNoAction,
          extensionsGroupRef: voiceSetup.afterHours.onNoAction.extensionsGroupRef
            ? voiceSetup.afterHours.onNoAction.extensionsGroupRef
            : null,
        },
      },
      workingHours: {
        ...voiceSetup.workingHours,
        onNoAction: {
          ...voiceSetup.workingHours.onNoAction,
          extensionsGroupRef: voiceSetup.workingHours.onNoAction.extensionsGroupRef
            ? voiceSetup.workingHours.onNoAction.extensionsGroupRef
            : null,
        },
      },
    };

    const { payload } = await dispatch(voiceSettingsThunk.fetchSaveSetupSettings(data));

    if (payload) {
      if (payload.incoming?.setup) {
        setPreviousVoiceSetup(payload.incoming.setup);
        setVoiceSetup(payload.incoming.setup);
      }
    }
  };

  useEffect(() => {
    getVoiceSettings();
    getUsers();
    getExtensionsGroups();

    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.SETTINGS }));
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="auto"
      p={3}
      gap={1}
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
      maxHeight="calc(100vh - 238px)"
    >
      <Box display="flex" alignItems="center">
        <WorkingScheduleGroups voiceSetup={voiceSetup} setVoiceSetup={setVoiceSetup} />
      </Box>

      <Box display="flex" justifyContent="space-between" gap={2}>
        <WorkingHours
          voiceSetup={voiceSetup}
          setVoiceSetup={setVoiceSetup}
          users={users}
          extensionsGroups={extensionsGroups}
        />

        <AfterHours
          voiceSetup={voiceSetup}
          setVoiceSetup={setVoiceSetup}
          users={users}
          extensionsGroups={extensionsGroups}
        />
      </Box>

      <Box display="flex" gap={1}>
        <Button variant="contained" color="success" onClick={handleSaveGreetingSettings}>
          Save
        </Button>

        <Button color="primary" variant="contained" onClick={handleDiscard}>
          Discard
        </Button>
      </Box>
    </Box>
  );
};

export default VoiceSetup;

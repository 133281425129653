import { createAsyncThunk } from '@reduxjs/toolkit';

import { IThunkExtra } from '../store';

import handleErrors from '@/services/handle-errors';

export const fetchAnalytics = createAsyncThunk(
  'analytics/fetchAnalytics',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.analyticsApis.getByParams();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

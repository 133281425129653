import { Dispatch, FunctionComponent, SetStateAction } from 'react';

import { Box, IconButton } from '@mui/material';
import { Call as CallIcon } from '@mui/icons-material';

import { IUserResult } from '@/interfaces/users/users';

import DialMobileKeyboard from './Dial-mobile-keyboard';
import DialTextField from './Dial-text-field';

interface IIdleCallOrConferenceProps {
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  countryCode: IUserResult['allowedCountries'][0] | null;
  setCountryCode: Dispatch<SetStateAction<IUserResult['allowedCountries'][0] | null>>;
  handleStartCall: VoidFunction;
}

const IdleCallOrConference: FunctionComponent<IIdleCallOrConferenceProps> = ({
  phoneNumber,
  setPhoneNumber,
  countryCode,
  setCountryCode,
  handleStartCall,
}): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" gap={1} p={1}>
      <DialTextField
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        handleOnBackspacePress={() => {
          setPhoneNumber((prevState) => prevState.slice(0, -1));
        }}
      />

      <Box display="flex" justifyContent="center">
        <DialMobileKeyboard
          handleDialChange={(value) => {
            setPhoneNumber((prevState) => prevState + value);
          }}
        />
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="center">
        <IconButton color="success" size="large" onClick={handleStartCall}>
          <CallIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default IdleCallOrConference;

import { useEffect, useState } from 'react';
import { useAppSelector } from '@/store/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Link,
} from '@mui/material';

import { ITenantResult } from '@/interfaces/tenant';

import ROUTES from '@/constants/routes';
import { ROLES } from '@/constants/roles';

const CheckSubscriptionDialog = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { tenant } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.user);

  const [show, setShow] = useState<boolean>(false);

  const handleCheckSubs = (tenant: ITenantResult) => {
    if (!tenant.skipSubsCheck && tenant.subs && !tenant.subs.isActive && tenant.subs.isSetupDone) {
      navigate(ROUTES.PROFILE_SUBSCRIPTIONS);
      setShow(true);
    }
  };

  const handleGoToProfile = () => {
    setShow(false);
    setTimeout(() => navigate(ROUTES.PROFILE_SUBSCRIPTIONS), 100);
  };

  useEffect(() => {
    if (tenant) {
      handleCheckSubs(tenant);
    }
  }, [tenant]);

  return (
    <Dialog fullWidth maxWidth="xs" open={show}>
      <DialogTitle>Please check your subscription details</DialogTitle>

      <DialogContent>
        <Typography>
          {[ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(user?.role as ROLES)
            ? `Your subscription is inactive. Please go  to Profile, add Credit Card if needed and make payment to activate the subscription. `
            : `Your subscription is inactive. Please contact your Billing Administrator.`}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Link component={Button} href="mailto: support@whistle.biz" sx={{ textDecoration: 'none' }}>
          Contact support
        </Link>
        <Link
          disableElevation
          component={Button}
          onClick={() => handleGoToProfile()}
          sx={{ textDecoration: 'none' }}
        >
          {location.pathname.includes(ROUTES.PROFILE_SUBSCRIPTIONS) ? ' Close' : 'Open Profile'}
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default CheckSubscriptionDialog;

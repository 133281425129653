import makeRequest from '../make-request';

import token from '../token';

const GET_ALL = '/api/v1/ips';

export const getAll = async () => {
  return makeRequest(GET_ALL, {
    method: 'GET',
    headers: { Authorization: await token() },
  });
};

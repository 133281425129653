namespace EXTENSIONS_GROUP {
  export const DEFAULT_RING_ALL_CONNECTION_TIME = 45;
  export const DEFAULT_RING_ALL_CONNECT_TO_CONNECTION_TIME = 5;
  export const DEFAULT_CALL_HUNT_CONNECT_TO_CONNECTION_TIME = 35;

  export enum CONNECTION_TYPE {
    RING_ALL = 'ring-all',
    CALL_HUNT = 'call-hunt',
  }
}

export default EXTENSIONS_GROUP;

import { styled } from '@mui/material/styles';

export const MainBox = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down(2000)]: {
    minWidth: 'calc(100vw - 399px)',
    maxWidth: 'calc(100vw - 399px)',
  },
  [theme.breakpoints.up(2000)]: {
    minWidth: 'calc(100vw - 513px)',
    maxWidth: 'calc(100vw - 513px)',
  },
}));

export const box5SX = {
  display: 'flex',
  gap: 2,
  alignItems: 'center',
  height: '100%',
};

export const box6SX = {
  display: 'flex',
  alignItems: 'flex-start',
  mb: 1,
  width: '100%',
};

export const box8SX = {
  display: 'flex',
  alignItems: 'end',
};

export const box9SX = {
  width: '100px',
  display: 'flex',
};

export const box10SX = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

export const box11SX = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
};

export const box12SX = {
  width: '100px',
  display: 'flex',
};

export const typography1SX = {
  mr: 2,
};

export const typography2SX = {
  mr: 2,
};

export const typography3SX = {
  fontSize: 12,
};

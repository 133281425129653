import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { Autocomplete, Box, DialogTitle, Stack, Typography } from '@mui/material';

import { IAddNewIotDeviceChatDialogResponse } from '@/interfaces/chat';
import { IIoTDeviceResult } from '@/interfaces/iot-device';

import Transition from '@/components/Dialogs/Transition';

import * as chatsThunk from '../../thunk';

import * as socketService from '@/services/socketio/socketio';
import apisV1 from '@/services/api/v1/index';

import useAccess from '@/hooks/use-access';

import { useFormik } from 'formik';

import CreateNewIoTDeviceChatValidationSchema from '@/helpers/validation-schemas/create-new-iot-device-chat';

import SOCKETIO from '@/constants/socketio';
import CHAT from '@/constants/chat';
import { FORMIK_STATUS } from '@/constants/formik';
import { ROLES } from '@/constants/roles';

const NewChatDialog = (props: {
  isOpen: boolean;
  handleNewChat: (data: IAddNewIotDeviceChatDialogResponse) => void;
  setShowStartNewIotDeviceChatDialog: Dispatch<SetStateAction<boolean>>;
}) => {
  const { handleNewChat, isOpen, setShowStartNewIotDeviceChatDialog } = props;
  const { isUserRoleMatches } = useAccess();
  const dispatch = useAppDispatch();

  const [iotDevices, setIotDevices] = useState<IIoTDeviceResult[]>([]);

  const formik = useFormik<IAddNewIotDeviceChatDialogResponse & { chatVariant: CHAT.VARIANT }>({
    initialStatus: FORMIK_STATUS.LOADING,
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      chatVariant: CHAT.VARIANT.IOT_DEVICE,
      chatType: CHAT.TYPE.TENANT,
      chatPlatform: CHAT.PLATFORM.ANY,
      contact: null,
      internalUser: null,
      phoneNumber: null,
      chat: null,
      iotDevice: null,
    },
    validationSchema: CreateNewIoTDeviceChatValidationSchema,
    onSubmit: async (response, formikHelpers) => {
      if (response.iotDevice) {
        const { meta, payload } = await dispatch(
          chatsThunk.fetchGetChatByIotDeviceId(response.iotDevice.id),
        );

        if (meta.requestStatus === 'fulfilled' && payload) {
          socketService.socket?.emit(
            SOCKETIO.EVENTS.JOIN_CHAT,
            JSON.stringify({ chatId: payload.id }),
          );

          handleNewChat({ ...response, chat: payload });
          setShowStartNewIotDeviceChatDialog(false);
          formikHelpers.resetForm({
            status: FORMIK_STATUS.IDLE,
          });
        }
      }
    },
  });

  const getIoTDevices = async (): Promise<void> => {
    const { success, data } = await apisV1.iotDevicesApis.getByParams({ skip: 0, limit: 1000 });

    if (success) {
      setIotDevices(data.records);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getIoTDevices();
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={() => setShowStartNewIotDeviceChatDialog(false)}
    >
      <DialogTitle color="primary">Send message</DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <Stack spacing={2} width={500} maxHeight={300}>
          <Box minHeight={60}>
            <Autocomplete
              id="internalUser"
              options={iotDevices}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              getOptionLabel={(option) => `${option.name} `}
              onChange={(event, list, reason, detail) => {
                formik.setFieldValue('iotDevice', detail?.option);
              }}
              onBlur={formik.handleBlur}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  display={'flex'}
                  alignItems={'center'}
                  px={1}
                  {...props}
                  key={option.id}
                >
                  <Box>
                    <Typography fontSize="small" ml={1}>
                      {option.name}
                    </Typography>
                    <Typography fontSize="small" color={'grey'} ml={1}>
                      Description: {option.description ?? 'None'}
                    </Typography>
                    {isUserRoleMatches([ROLES.ADMIN, ROLES.SUPER_ADMIN]) && (
                      <>
                        <Typography fontSize="small" ml={1}>
                          IP: {option.ipAddress ?? 'None'} | MAC: {option.mac ?? 'None'} | Serial
                          Number: {option.serialNumber ?? 'None'}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Device"
                  error={formik.touched.iotDevice && !!formik.errors.iotDevice}
                  helperText={formik.touched.iotDevice && (formik.errors.iotDevice as string)}
                />
              )}
            />
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setShowStartNewIotDeviceChatDialog(false);
          }}
        >
          Close
        </Button>

        <Button
          color="primary"
          variant="contained"
          disabled={false}
          onClick={() => {
            formik.submitForm();
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewChatDialog;

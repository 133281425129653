namespace CALL {
  export enum CONNECTION_TYPES {
    INTERNAL = 'internal',
    EXTERNAL = 'external',
  }

  export enum TRACK_TYPE {
    INBOUND = 'inbound',
    OUTBOUND = 'outbound',
  }

  export enum STATUSES {
    INITIATED = 'initiated',
    RINGING = 'ringing',
    NO_ANSWER = 'no-answer',
    IN_PROGRESS = 'in-progress',
    COMPLETED = 'completed',
    MISSED = 'missed',
    ON_HOLD = 'on-hold',
    BUSY = 'busy',
  }

  export enum INTERNAL_HOLD_BY {
    CALLEE = 'callee',
    CALLER = 'caller',
  }

  export enum VOICE {
    CALL = 'call',
    CONFERENCE = 'conference',
  }

  export enum TRANSCRIPTION_STATUS {
    ONLINE = 'online',
    ON_HOLD = 'on hold',
    ERROR = 'error',
  }

  export enum VOICE_DEVICE {
    SIP = 'sip',
    WEB = 'web',
    IOS = 'ios',
    ANDROID = 'android',
  }

  export const VOICE_DEVICES_PREFERENCES_LABELS: Record<VOICE_DEVICE, string> = {
    [VOICE_DEVICE.WEB]: 'Web',
    [VOICE_DEVICE.SIP]: 'IP Phone',
    [VOICE_DEVICE.IOS]: 'iOS',
    [VOICE_DEVICE.ANDROID]: 'Android',
  };

  export const MAX_CONNECTIONS = 5;
}

export default CALL;

export default {
  HOME: '/home',
  SPLASH: '/splash',
  AUTH_LAYOUT: '/',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  PRICING: '/pricing',
  SUBSCRIPTION_SETUP: '/subscription-setup',
  KYC: '/KYC',

  DASHBOARD_LAYOUT: '/dashboard',
  DASHBOARD_MESSAGES: '/dashboard/messages',
  DASHBOARD_BROADCAST_CAMPAIGN: '/dashboard/broadcast',
  DASHBOARD_CONTACTS_PAGE_LAYOUT: '/dashboard/contacts',
  DASHBOARD_CONTACTS: '/dashboard/contacts/list',
  DASHBOARD_CONTACTS_GROUPS: '/dashboard/contacts/groups',
  DASHBOARD_CONTACTS_GROUPS_CREATE_OR_UPDATE: '/dashboard/contacts/groups/add-edit',
  DASHBOARD_CONTACTS_ADD_EDIT: '/dashboard/contacts/add-edit',
  DASHBOARD_CONTACTS_UPLOAD: '/dashboard/contacts/upload',
  DASHBOARD_USERS_RELATED_INFO: '/dashboard/users',
  DASHBOARD_USERS: '/dashboard/users/list',
  DASHBOARD_DIRECT_EXTENSIONS: '/dashboard/users/direct-extensions',
  DASHBOARD_USERS_GROUPS: '/dashboard/users/groups',
  DASHBOARD_USERS_GROUPS_ADD_EDIT: '/dashboard/users/groups/add-edit',
  DASHBOARD_ADD_EDIT_USER: '/dashboard/users/add-edit',
  DASHBOARD_VOICE: '/dashboard/voice',
  DASHBOARD_VOICE_STATUS: '/dashboard/voice/status',
  DASHBOARD_VOICE_HISTORY: '/dashboard/voice/history',
  DASHBOARD_VOICE_CALLS_VOICEMAILS: '/dashboard/voice/calls-voicemails',
  DASHBOARD_SETTINGS_LAYOUT: '/dashboard/settings',
  DASHBOARD_SETTINGS_VOICE_SETUP: '/dashboard/settings/voice-setup',
  DASHBOARD_SETTINGS_CALL_FLOW: '/dashboard/settings/call-flow',
  DASHBOARD_SETTINGS_GENERAL: '/dashboard/settings/general',
  DASHBOARD_SETTINGS_COUNTRY_CODES: '/dashboard/settings/country-codes',
  DASHBOARD_SETTINGS_FORWARDING: '/dashboard/settings/forwarding',
  DASHBOARD_EXTENSIONS_GROUPS: '/dashboard/extensions-groups',
  DASHBOARD_EXTENSIONS_GROUPS_ADD: '/dashboard/extensions-groups/add',
  DASHBOARD_EXTENSIONS_GROUPS_UPDATE: '/dashboard/extensions-groups/update',

  DASHBOARD_IOT_DEVICES_PAGE_LAYOUT: '/dashboard/iot-devices',
  DASHBOARD_IOT_DEVICES: '/dashboard/iot-devices/list',
  DASHBOARD_IOT_DEVICES_ADD_EDIT: '/dashboard/iot-devices/add-edit',
  DASHBOARD_IOT_DEVICES_GROUPS: '/dashboard/iot-devices/groups',
  DASHBOARD_IOT_DEVICES_GROUPS_ADD_EDIT: '/dashboard/iot-devices/groups/add-edit',

  PROFILE: '/profile',
  PROFILE_ME: '/profile/me',
  PROFILE_PREFERENCES: '/profile/preferences',
  PROFILE_CHANGE_PASSWORD: '/profile/change-password',
  PROFILE_SUBSCRIPTIONS: '/profile/subscriptions',
  PROFILE_CREDIT_CARDS: '/profile/credit-cards',
  PROFILE_SUPER_ADMIN: '/profile/super-admin',
};

import React from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import TextField from '@mui/material/TextField';

import * as bmcReducer from '@/pages/dashboard/broadcast/campaign/reducer';
import { Box } from '@mui/material';

const TypeText: React.FC = () => {
  const dispatch = useAppDispatch();

  const { message } = useAppSelector((state) => state.broadcastCampaign);

  const handleOnMessageChange = (event: any) => {
    if (event.target.value) {
      dispatch(bmcReducer.setInvalidSteps([]));
    }
    dispatch(bmcReducer.setMessage(event.target.value));
  };

  return (
    <Box pt={2}>
      <TextField
        fullWidth
        multiline
        id="outlined-multiline-static"
        variant="outlined"
        label="Message"
        rows={7}
        value={message ?? ''}
        onChange={handleOnMessageChange}
      />
    </Box>
  );
};

export default TypeText;

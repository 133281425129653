import React from 'react';
import { useAppSelector } from '@/store/hooks';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import DescriptionIcon from '@mui/icons-material/Description';
import Divider from '@mui/material/Divider';

import * as styles from './styles';

import { format } from '@/helpers/date/date';

import FILE from '@/constants/file';

const Review: React.FC = () => {
  const {
    message,
    contactsFile,
    phoneNumbersCount,
    selectedContactsCount,
    contactsGroupsState,
    startDate,
    stopDate,
    startTime,
    stopTime,
    runDays,
    files,
  } = useAppSelector((state) => state.broadcastCampaign);

  return (
    <Box display="flex" overflow="auto" flexDirection="column" gap={3} p={2}>
      {message ? (
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h6" component="p">
            Message
          </Typography>

          <Divider />

          <Chip sx={{ ...styles.chipSX, mr: 'auto', px: 2 }} label={message} />
        </Box>
      ) : null}

      {files.length ? (
        <Box overflow="auto">
          <Typography variant="h6" component="p">
            Attachments
          </Typography>

          <Divider sx={{ my: 1 }} />

          <Box display="flex" overflow="auto" gap={1}>
            <ImageList
              sx={{
                display: 'flex',
                justifyContent: 'row',
                overflow: 'auto',
              }}
            >
              {files.map((item: any) => (
                <ImageListItem sx={styles.imageListItemSX} key={URL.createObjectURL(item)}>
                  {Object.values(FILE.IMAGE.EXTENSION).includes(
                    `.${item.name.split('.').reverse()[0]}`,
                  ) ? (
                    <img style={styles.image} src={URL.createObjectURL(item)} loading="lazy" />
                  ) : (
                    <DescriptionIcon sx={styles.descriptionSX} color="inherit" />
                  )}

                  <ImageListItemBar
                    sx={styles.imageListItemBarSX}
                    title={item.name.split('.').reverse()[1]}
                    subtitle={`.${item.name.split('.').reverse()[0]}`}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        </Box>
      ) : null}

      <Box>
        <Typography variant="h6" component="p">
          Recipients
        </Typography>

        <Divider sx={{ my: 1 }} />

        <Typography variant="body2">
          {contactsFile
            ? `Campaign will be broadcasted to ${phoneNumbersCount} phone number(s)`
            : null}

          {selectedContactsCount
            ? `Campaign will be broadcasted to ${selectedContactsCount} contact(s)`
            : null}

          {contactsGroupsState.selectedContactsGroupsCount
            ? `Campaign will be broadcasted to ${contactsGroupsState.selectedContactsGroupsCount} group(s)`
            : null}
        </Typography>
      </Box>

      <Box>
        <Typography variant="h6" component="p">
          Scheduled date and time
        </Typography>

        <Divider sx={{ my: 1 }} />

        <Box>
          <Typography variant="body2">
            Start broadcast date: {format(startDate, 'yyyy-MM-dd')}
          </Typography>

          <Typography variant="body2">
            Stop broadcast date: {format(stopDate, 'yyyy-MM-dd')}
          </Typography>

          <Typography variant="body2">
            Broadcast daily start time: {format(startTime, 'hh:mm a')}
          </Typography>

          <Typography variant="body2">
            Broadcast daily stop time: {format(stopTime, 'hh:mm a')}
          </Typography>

          <Typography variant="body2">Broadcasting days: {runDays.join(', ')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Review;

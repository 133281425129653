import { FunctionComponent } from 'react';

import { useAppSelector } from '@/store/hooks';

import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

import { PHONE_NUMBER_TYPE } from '@/constants/phone-numbers';

interface IOutgoingNumberToggleButtonGroupProps extends Pick<ToggleButtonGroupProps, 'onChange'> {}

const OutgoingNumberToggleButtonGroup: FunctionComponent<IOutgoingNumberToggleButtonGroupProps> = ({
  onChange,
}): JSX.Element => {
  const user = useAppSelector((state) => state.user.user);
  const connections = useAppSelector((state) => state.voice.connections);

  return (
    <ToggleButtonGroup
      sx={{ flex: 1 }}
      size="extra-small"
      exclusive
      disabled={!!connections.length}
      value={user?.selectedPhoneNumberTypeToMakeCallsFrom || PHONE_NUMBER_TYPE.TENANT}
      onChange={onChange}
    >
      <ToggleButton sx={{ borderRadius: 0 }} value={PHONE_NUMBER_TYPE.TENANT}>
        Main/Business
      </ToggleButton>

      <ToggleButton
        sx={{ borderRadius: 0 }}
        value={PHONE_NUMBER_TYPE.DIRECT_EXTENSION}
        disabled={!user?.directExtension}
      >
        Direct extension
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default OutgoingNumberToggleButtonGroup;

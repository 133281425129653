import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';
import { useState } from 'react';

import {
  Avatar,
  Box,
  Chip,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  Typography,
} from '@mui/material';

import {
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';

import { IUsersGroupResult } from '@/interfaces/users-groups';

import TemplateTable, { ITemplateTableProps } from '@/components/Template-table/Template-table';
import ConfirmationDialog from '@/components/Dialogs/Confirmation';
import NoDataValue from '@/components/Data/No-data-value';

interface IUsersGroupsListProps {
  data: IUsersGroupResult[];
  handleUpdate: (el: IUsersGroupResult) => void;
  handleDelete: (el: IUsersGroupResult) => void;
}

interface IUsersGroupActionsMenuButtonProps {
  data: IUsersGroupResult;
  handleUpdate: (el: IUsersGroupResult) => void;
  handleDelete: (el: IUsersGroupResult) => void;
}

const UsersGroupActionsMenuButton = ({ data, ...actions }: IUsersGroupActionsMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const isOpened = !!anchorEl;

  return (
    <>
      <ConfirmationDialog
        show={showConfirmationDialog}
        handleAccepted={() => {
          setShowConfirmationDialog(false);
          actions.handleDelete(data);
        }}
        handleRejected={() => setShowConfirmationDialog(false)}
        title="Do you wish to delete Users Group?"
        subtitle="Once you press Yes, the Users Group will be deleted, and this operation cannot be undone."
      />
      <IconButton
        color="primary"
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl((prevState) => (prevState ? null : event.currentTarget));
        }}
      >
        <Menu
          anchorEl={anchorEl}
          open={isOpened}
          disableAutoFocusItem
          onClose={() => setAnchorEl(null)}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => actions.handleUpdate(data)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="small" />
            </ListItemIcon>
            Edit
          </MenuItem>

          <MenuItem onClick={() => setShowConfirmationDialog(true)}>
            <ListItemIcon>
              <DeleteOutlineIcon color="error" fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>

        <MoreVertIcon />
      </IconButton>
    </>
  );
};

const UsersGroupsList = ({ data, ...actions }: IUsersGroupsListProps) => {
  const templateTableProps: ITemplateTableProps<IUsersGroupResult> = {
    data,
    getDataEntityId: (el) => el.id,
    getExpandComponent: (el) => (
      <TableCell colSpan={templateTableProps.columns.length + 1} sx={{ pt: 0 }}>
        <Typography variant="subtitle2" component="p" fontWeight="bold" mb={1}>
          Users
        </Typography>

        <Box display="flex" flexWrap="wrap" gap={1}>
          {el.users.length ? (
            el.users.map((el) => (
              <Chip
                sx={{ height: 'auto', pl: 1 }}
                key={el.id}
                avatar={<Avatar src={el.avatarUrl ? el.avatarUrl : DefaultAvatar} />}
                label={
                  <Stack p={1}>
                    <Typography color="inherit" fontSize="inherit">
                      {el.fullName}
                    </Typography>
                    <Typography color="inherit" fontSize="inherit">
                      {el.companyExtension}
                    </Typography>
                  </Stack>
                }
              />
            ))
          ) : (
            <NoDataValue />
          )}
        </Box>
      </TableCell>
    ),
    columns: [
      {
        header: 'Name',
        getValue: (el) => el.name,
      },
      {
        sharedProps: { sx: { width: '45%' } },
        header: 'Description',
        getValue: (el) => el.description || <NoDataValue />,
      },
      {
        sharedProps: { sx: { minWidth: '10rem' } },
        header: 'Users in group',
        getValue: (el) => el.users.length || <NoDataValue />,
      },
      {
        sharedProps: { sx: { width: '7rem' } },
        header: 'Actions',
        getValue: (el) => <UsersGroupActionsMenuButton data={el} {...actions} />,
      },
    ],
    tableContainerProps: { sx: { height: 'calc(100vh - 24rem)' } },
  };

  return <TemplateTable {...templateTableProps} />;
};

export default UsersGroupsList;

export default {
  IMAGE: {
    EXTENSION: {
      JPG: '.jpg',
      JPEG: '.jpeg',
      JFIF: '.jfif',
      PJPEG: '.pjpeg',
      PJP: '.pjp',
      PNG: '.png',
      SVG: '.svg',
      WEBP: '.webp',
      ICO: '.ico',
    },
    MIMETYPE: {
      JPEG: 'image/jpeg',
      PGN: 'image/png',
      WEBP: 'image/webp',
      ICO: 'image/x-icon',
      SVG: 'image/svg+xml',
    },
  },
  EXCEL: {
    EXTENSION: {
      XLSX: '.xlsx',
      XLS: '.xls',
    },
    MIMETYPE: {
      XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      XLS1: 'application/excel',
      XLS2: 'application/vnd.ms-excel',
      XLS3: 'application/x-excel',
      XLS4: 'application/x-msexcel',
    },
  },

  MAX_FILES_COUNT: 10,
  MAX_FILE_SIZE: 20971520, //20 MB
};

import { FunctionComponent } from 'react';

import { Tooltip } from '@mui/material';

import {
  Business as BusinessIcon,
  Person as PersonIcon,
  SmsFailed as SmsFailedIcon,
  Group as GroupIcon,
} from '@mui/icons-material';

import CHAT from '@/constants/chat';

interface IChatTypeChipProps {
  chatType: CHAT.TYPE;
}

const ChatTypeChip: FunctionComponent<IChatTypeChipProps> = ({ chatType }): JSX.Element => {
  let title = '';
  let icon!: JSX.Element;

  if (chatType === CHAT.TYPE.INTERNAL) {
    title = 'Internal messaging';
    icon = <GroupIcon fontSize="small" color="primary" />;
  } else if (chatType === CHAT.TYPE.TENANT) {
    title = 'Company messaging';
    icon = <BusinessIcon fontSize="small" color="primary" />;
  } else if (chatType === CHAT.TYPE.DIRECT) {
    title = 'Direct messaging';
    icon = <PersonIcon fontSize="small" color="primary" />;
  } else {
    icon = <SmsFailedIcon fontSize="small" color="primary" />;
  }

  return <Tooltip title={title}>{icon}</Tooltip>;
};

export default ChatTypeChip;

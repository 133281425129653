import { Features } from '@/types/feature';

import { useAppSelector } from '@/store/hooks';
import { Role } from '@/types/user';

import { ADDONS } from '@/constants/addons';
import { PERMISSIONS } from '@/constants/permissions';
import { ACCESS_CONTROLS } from '@/constants/access-controls';

const useAccess = () => {
  const { user } = useAppSelector((state) => state.user);
  const { tenant } = useAppSelector((state) => state.tenant);

  const isFeatureAvailable = (feature: Features): boolean => {
    if (!tenant || !tenant.features?.length) {
      return false;
    }

    return tenant.features.some((element) => element.name === feature);
  };

  const isUserRoleMatches = (roles: Role[]): boolean => {
    //TODO: update type for IME
    //@ts-ignore
    return !user ? false : roles.includes(user.role);
  };

  const isRoleAndFeatureMatches = (feature: Features, roles: Role[]): boolean => {
    return isFeatureAvailable(feature) && isUserRoleMatches(roles);
  };

  const isAddonAvailable = (addon: ADDONS): boolean => {
    if (!tenant || !tenant.addons?.length) {
      return false;
    }

    return tenant.addons.some((element) => element.name === addon);
  };

  const isPermissions = (permission: PERMISSIONS): boolean => {
    if (!user || !user.permissions.length) {
      return false;
    }

    return user.permissions.some((element) => element.key === permission);
  };

  const isAccessControl = (accessControl: ACCESS_CONTROLS): boolean => {
    if (!tenant || !tenant.accessControls.length) {
      return false;
    }
    return tenant.accessControls.some((element) => element.name === accessControl);
  };

  return {
    isFeatureAvailable,
    isRoleAndFeatureMatches,
    isUserRoleMatches,
    isAddonAvailable,
    isPermissions,
    isAccessControl,
  };
};

export default useAccess;

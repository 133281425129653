import { createAsyncThunk } from '@reduxjs/toolkit';
import { IThunkExtra } from '@/store/store';

import handleErrors from '@/services/handle-errors';

import {
  IGetIotDevicesQueryParams,
  IIoTCreateDeviceRequest,
  IIoTUpdateDeviceRequest,
} from '@/interfaces/iot-device';

export const fetchGetIotDevices = createAsyncThunk(
  'iotDevices/fetchGetIotDevices',
  async (data: IGetIotDevicesQueryParams, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.iotDevicesApis.getByParams(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCreateIotDevices = createAsyncThunk(
  'iotDevices/fetchCreateIotDevices',
  async (data: IIoTCreateDeviceRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.iotDevicesApis.create(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchUpdateIotDevices = createAsyncThunk(
  'iotDevices/fetchUpdateIotDevices',
  async (data: { id: string; updates: IIoTUpdateDeviceRequest }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.iotDevicesApis.update(data.id, data.updates);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchDeleteIotDevices = createAsyncThunk(
  'iotDevices/fetchDeleteIotDevices',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.iotDevicesApis.remove(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchBulkDeleteIotDevices = createAsyncThunk(
  'iotDevices/fetchBulkDeleteIotDevices',
  async (data: { ids: string[] }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.iotDevicesApis.bulkDelete(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchGetChatByIotDeviceId = createAsyncThunk(
  'iotDevices/fetchGetChatByIotDeviceId',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.chatsApis.getByIotDeviceId(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

import { Dispatch, SetStateAction, useState } from 'react';

import { Add, Delete } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import { IGetIotDevicesQueryParams } from '@/interfaces/iot-device';

import ConfirmationDialog from '@/components/Dialogs/Confirmation';

import { useAppSelector } from '@/store/hooks';

import useAccess from '@/hooks/use-access';

import { ROLES } from '@/constants/roles';

interface IIotDevicesHeaderProps {
  isLoading: boolean;
  queryParams: IGetIotDevicesQueryParams;
  setQueryParams: Dispatch<SetStateAction<IGetIotDevicesQueryParams>>;
  selectedIotDevicesIds: string[];
  handleBulkDelete: (ids: string[]) => void;
  handleCreateIotDevice: () => void;
}

const IotDevicesHeader = ({
  isLoading,
  selectedIotDevicesIds,
  queryParams,
  setQueryParams,
  handleBulkDelete,
  handleCreateIotDevice,
}: IIotDevicesHeaderProps) => {
  const { tenant } = useAppSelector((state) => state.tenant);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const { isUserRoleMatches } = useAccess();

  const handleUpdateQueryParams = (params: Partial<IGetIotDevicesQueryParams>) =>
    setQueryParams((prevState) => ({
      ...prevState,
      ...params,
      skip: 0,
    }));

  if (selectedIotDevicesIds.length > 0) {
    return (
      <>
        <ConfirmationDialog
          show={showConfirmationDialog}
          handleAccepted={() => {
            setShowConfirmationDialog(false);
            handleBulkDelete(selectedIotDevicesIds);
          }}
          handleRejected={() => {
            setShowConfirmationDialog(false);
          }}
          title="Do you with to delete selected devices?"
          subtitle="Once you press Yes, the devices will be deleted, and this operation cannot be undone."
        />
        <Stack
          component={Paper}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          py={1}
          sx={{
            bgcolor: (theme) =>
              selectedIotDevicesIds.length
                ? alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
                : 'initial',
          }}
        >
          <Typography variant="subtitle1" component="span">
            {selectedIotDevicesIds.length} selected
          </Typography>

          <Tooltip title="Delete">
            <IconButton disabled={isLoading} onClick={() => setShowConfirmationDialog(true)}>
              {isLoading ? <CircularProgress /> : <Delete color="error" />}
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  return (
    <Box
      justifyContent="space-between"
      alignItems="center"
      flexDirection={'row'}
      display={'flex'}
      p={2}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ flexGrow: 1, maxWidth: '40rem' }}
      >
        <TextField
          type="search"
          label="Search"
          variant="outlined"
          size="small"
          value={queryParams.search}
          onChange={(e) => handleUpdateQueryParams({ search: e.target.value })}
          onBlur={(e) => handleUpdateQueryParams({ search: e.target.value })}
        />
        {tenant?.isIotWsConnected ? (
          <Typography fontSize={13} color={'green'}>
            Connection established
          </Typography>
        ) : null}
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Tooltip title="Add new IoT device" placement="right">
          <IconButton
            disabled={isUserRoleMatches([ROLES.USER])}
            color="primary"
            onClick={handleCreateIotDevice}
          >
            <Add />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
};

export default IotDevicesHeader;

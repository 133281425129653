import { Box, BoxProps, Paper } from '@mui/material';

interface IDotProps {
  isActive: boolean | null;
  size?: number;
}

const Dot = ({ isActive, size = 14 }: IDotProps) => {
  const boxProps: BoxProps = {};

  if (isActive === null) {
    boxProps.bgcolor = 'secondary.main';
  } else if (isActive) {
    boxProps.bgcolor = 'success.light';
  } else {
    boxProps.bgcolor = 'error.main';
  }

  return (
    <Box
      {...boxProps}
      width={size}
      height={size}
      borderRadius="50%"
      component={Paper}
      elevation={1}
    />
  );
};

export default Dot;

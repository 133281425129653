import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';

import { IDecodedToken } from '@/interfaces/auth/jwt';

import * as socketServicer from '@/services/socketio/socketio';
import * as lsService from '@/services/local-storage';

import * as jwtUtil from '@/utils/jwt';

import * as twilioReducer from '@/store/reducers/twilio';
import * as authReducer from '@/store/reducers/auth';
import * as backdropReducer from '@/store/reducers/backdrop';
import * as countryCodesReducer from '@/store/reducers/country-codes';

import * as userThunk from '@/pages/dashboard/users/thunk';
import * as tenantThunk from '@/store/thunk/tenant';
import * as countryCodesThunk from '@/store/thunk/country-codes';
import * as phoneNumbersThunk from '@/store/thunk/phone-numbers';

import useSocket from '@/hooks/use-socket';

import ROUTES from '@/constants/routes';

export let _getAccessTokenSilently: (
  options?: GetTokenSilentlyOptions | undefined,
) => Promise<string>;

const UseAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useSocket();

  const { device } = useAppSelector((state) => state.twilio);

  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  const { start: startIdleTimer, getIdleTime } = useIdleTimer({
    onIdle: async () => {
      if (device) {
        await device.unregister();
      }

      await dispatch(userThunk.fetchLogout());

      lsService.onLogout();

      logout({
        logoutParams: {
          returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URL,
        },
      });
    },
    throttle: 1000,
    crossTab: true,
    syncTimers: 200,
    timeout: 1.44e7, //4 hours idle timeout
    stopOnIdle: true,
    startOnMount: false,
    startManually: true,
  });

  const getCountryCodes = async () => {
    const storedCountryCodes = localStorage.getItem('country_codes');
    if (storedCountryCodes) {
      dispatch(countryCodesReducer.setCountryCodes(JSON.parse(storedCountryCodes)));
    } else {
      const result = await dispatch(countryCodesThunk.fetchCountryCodes()).unwrap();
      dispatch(countryCodesReducer.setCountryCodes(result));
      localStorage.setItem('country_codes', JSON.stringify(result));
    }
  };

  const authorize = async () => {
    dispatch(backdropReducer.setShow(true));

    _getAccessTokenSilently = getAccessTokenSilently;

    if (getIdleTime() === 0) {
      startIdleTimer();
    }

    let storedToken = localStorage.getItem('t');

    if (isAuthenticated) {
      dispatch(authReducer.set());
    } else {
      dispatch(authReducer.reset());
    }

    if (!storedToken && isAuthenticated) {
      storedToken = await getAccessTokenSilently();

      const decoded = jwtUtil.decode<IDecodedToken>(storedToken);

      if (decoded) {
        localStorage.setItem('t', storedToken);
        localStorage.setItem('exp_at', decoded.exp.toString());
      }

      const { meta } = await dispatch(
        userThunk.fetchSaveLegalInfoAccepted({
          privacyPolicyAccepted: new Date(),
          termsOfServicesAccepted: new Date(),
        }),
      );

      if (meta.requestStatus === 'rejected') {
        dispatch(backdropReducer.setShow(false));
        navigate(ROUTES.AUTH_LAYOUT);
        return;
      }

      dispatch(twilioReducer.setCreateDevice({ initCreate: true }));

      const loginResponse = await dispatch(userThunk.fetchLogin());

      if (loginResponse.meta.requestStatus === 'rejected') {
        dispatch(backdropReducer.setShow(false));

        navigate(ROUTES.AUTH_LAYOUT);
        return;
      }

      dispatch(phoneNumbersThunk.fetchGetTenantPhoneNumbersList());
      getCountryCodes();
    }

    if (isAuthenticated && storedToken) {
      const userResponse = await dispatch(userThunk.fetchGetUserInfo());

      if (userResponse.meta.requestStatus === 'rejected') {
        dispatch(backdropReducer.setShow(false));

        navigate(ROUTES.AUTH_LAYOUT);
        return;
      }

      const tenantResponse = await dispatch(tenantThunk.fetchTenantInfo());

      if (tenantResponse.meta.requestStatus === 'rejected') {
        dispatch(backdropReducer.setShow(false));

        navigate(ROUTES.AUTH_LAYOUT);
        return;
      }

      socketServicer.connectSocket();

      dispatch(phoneNumbersThunk.fetchGetTenantPhoneNumbersList());
      getCountryCodes();

      dispatch(backdropReducer.setShow(false));

      navigate(ROUTES.DASHBOARD_LAYOUT);
    }

    dispatch(backdropReducer.setShow(false));
  };

  return {
    authorize,
  };
};

export default UseAuth;

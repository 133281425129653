import phone from 'phone';

export const validate = (
  number: string,
): { isValid: boolean; validatedPhoneNumber: string; countryCode: string } => {
  const { isValid, phoneNumber, countryCode } = phone(number, {
    validateMobilePrefix: true,
  });

  return {
    isValid,
    countryCode: countryCode as string,
    validatedPhoneNumber: phoneNumber as string,
  };
};

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { ITenantResult } from '@/interfaces/tenant';

import * as tenantThunk from '@/store/thunk/tenant';
import * as settingsThunk from '../thunk';

type CountryCode = ITenantResult['allowedCountries'][0];

function not(a: CountryCode[], b: CountryCode[]) {
  return a.filter((value) => !b.find((element) => element.countryCodeRef === value.countryCodeRef));
}

function intersection(a: CountryCode[], b: CountryCode[]) {
  return a.filter((value) => b.find((element) => element.countryCodeRef === value.countryCodeRef));
}

function union(a: CountryCode[], b: CountryCode[]) {
  return [...a, ...not(b, a)];
}

const CountryCodes = () => {
  const dispatch = useAppDispatch();

  const { tenant } = useAppSelector((state) => state.tenant);

  const [checked, setChecked] = useState<CountryCode[]>([]);
  const [allCountryCodes, setAllCountryCodes] = useState<CountryCode[]>(
    tenant?.allowedCountries ?? [],
  );
  const [allowedCountryCodes, setAllowedCountryCodes] = useState<CountryCode[]>(
    tenant?.usersAllowedCountries ?? [],
  );
  const [allCountryCodesSearch, setAllCountryCodesSearch] = useState<string>('');
  const [allowedCountryCodesSearch, setAllowedCountryCodesSearch] = useState<string>('');

  const leftChecked = intersection(checked, allCountryCodes);
  const rightChecked = intersection(checked, allowedCountryCodes);

  const prepareLists = async (): Promise<void> => {
    if (!tenant) {
      return;
    }

    const usersAllowedCountryCodes = tenant.usersAllowedCountries;
    setAllowedCountryCodes(usersAllowedCountryCodes);
    setAllCountryCodes(not(tenant.allowedCountries ?? [], usersAllowedCountryCodes));
  };

  const handleToggle = (value: CountryCode) => () => {
    if (value.isDefault) {
      return;
    }

    const currentIndex = checked.findIndex(
      (element) => element.countryCodeRef === value.countryCodeRef,
    );
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: CountryCode[]) => intersection(checked, items).length;

  const handleToggleAll = (items: CountryCode[]) => () => {
    const nonDefItems = items.filter((element) => !element.isDefault);
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, nonDefItems));
    } else {
      setChecked(union(checked, nonDefItems));
    }
  };

  const handleSearch = (
    value: string,
    which: 'All Country Codes' | 'Selected Country Codes',
  ): void => {
    if (which === 'All Country Codes') {
      setAllCountryCodesSearch(value);
    } else {
      setAllowedCountryCodesSearch(value);
    }
  };

  const handleCheckedRight = () => {
    setAllowedCountryCodes(allowedCountryCodes.concat(leftChecked));
    setAllCountryCodes(not(allCountryCodes, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setAllCountryCodes(allCountryCodes.concat(rightChecked));
    setAllowedCountryCodes(not(allowedCountryCodes, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleSetUsersAllowedCountries = async (): Promise<void> => {
    const { meta } = await dispatch(
      settingsThunk.fetchSetUsersAllowedCountries({
        ids: allowedCountryCodes
          .filter((element) => !element.isDefault)
          .map((element) => element.countryCodeRef),
      }),
    );

    if (meta.requestStatus === 'fulfilled') {
      await dispatch(tenantThunk.fetchTenantInfo());
    }
  };

  const sortCb = (a: CountryCode, b: CountryCode) => {
    return a.isDefault || b.isDefault
      ? 0
      : a.country.toLowerCase().localeCompare(b.country.toLowerCase());
  };

  const customList = (
    title: 'All Country Codes' | 'Selected Country Codes',
    items: CountryCode[],
  ) => (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Box>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          type="search"
          placeholder="Search country code..."
          value={title === 'All Country Codes' ? allCountryCodesSearch : allowedCountryCodesSearch}
          onChange={(e) => handleSearch(e.target.value, title)}
        />
      </Box>
      <Divider />
      <List
        sx={{
          height: 'calc(100vh - 440px)',
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {[...items]
          .sort(sortCb)
          .filter((element) => {
            const search =
              title === 'All Country Codes' ? allCountryCodesSearch : allowedCountryCodesSearch;
            if (search) {
              return (
                element.country.toLowerCase().includes(search.toLowerCase()) ||
                element.iso.toLowerCase().includes(search.toLowerCase()) ||
                element.extension.toLowerCase().includes(search.toLowerCase())
              );
            }

            return true;
          })
          .map((value: CountryCode) => {
            const className = `fi fi-${value.iso.toLowerCase()}`;

            return (
              <ListItemButton
                key={value.countryCodeRef}
                role="listitem"
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.some(
                      (element) => element.countryCodeRef === value.countryCodeRef,
                    )}
                    tabIndex={-1}
                    disableRipple
                    disabled={value.isDefault}
                  />
                </ListItemIcon>
                <Box flexDirection={'row'} display={'flex'}>
                  <Box className={className} justifyContent={'center'} />
                  <ListItemText
                    primary={`${value.country} ${value.isDefault ? '(default)' : ''}`}
                    secondary={value.extension}
                    sx={{ ml: 1 }}
                  />
                </Box>
              </ListItemButton>
            );
          })}
      </List>
    </Card>
  );

  useEffect(() => {
    prepareLists();
  }, []);

  useEffect(() => {
    prepareLists();
  }, []);

  return (
    <Box display="flex" flexWrap="wrap" p={2}>
      <Typography my={1}>Users Allowed Country Codes Management</Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center" width={'100%'}>
        <Grid item flex={1}>
          {customList('All Country Codes', allCountryCodes)}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
              sx={{
                my: 0.5,
                fontWeight: 'bold',
              }}
            >
              <KeyboardArrowRightIcon />
            </Button>

            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
              sx={{ my: 0.5 }}
            >
              <KeyboardArrowLeftIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid item flex={1}>
          {customList('Selected Country Codes', allowedCountryCodes)}
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="success"
        onClick={handleSetUsersAllowedCountries}
        sx={{ width: 100, mt: 2 }}
      >
        Save
      </Button>
    </Box>
  );
};

export default CountryCodes;

import { IGetUsersQueryParams, IUserMeResult } from '@/interfaces/users/users';

import { Dispatch, SetStateAction } from 'react';

import { Stack, Tooltip, IconButton, TextField } from '@mui/material';

import { Add } from '@mui/icons-material';

import { ROLES } from '@/constants/roles';

interface IUsersHeaderProps {
  user: IUserMeResult | null;
  queryParams: IGetUsersQueryParams;
  setQueryParams: Dispatch<SetStateAction<IGetUsersQueryParams>>;
  handleCreateUser: VoidFunction;
}

const UsersHeader = ({
  user,
  queryParams,
  setQueryParams,
  handleCreateUser,
}: IUsersHeaderProps) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" px={2}>
      <TextField
        fullWidth
        sx={{ maxWidth: '20rem' }}
        type="search"
        label="Search"
        variant="outlined"
        size="small"
        value={queryParams.search}
        onChange={(e) =>
          setQueryParams((prevState) => ({ ...prevState, skip: 0, search: e.target.value }))
        }
        onBlur={(e) =>
          setQueryParams((prevState) => ({ ...prevState, skip: 0, search: e.target.value }))
        }
      />

      {[ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(user?.role as ROLES) ? (
        <Tooltip title="Add new user">
          <IconButton color="primary" onClick={handleCreateUser}>
            <Add />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default UsersHeader;

import makeRequest from '../make-request-v2';
import token from '../token';

import { IResponseResult } from '@/interfaces/base';
import { IAnalyticsResult } from '@/interfaces/analytics';

const GET_ALL = '/api/v1/analytics';

export const getByParams = async (): Promise<IResponseResult<IAnalyticsResult>> => {
  return makeRequest(GET_ALL, {
    method: 'GET',
    headers: { Authorization: await token() },
  });
};

import { IPhoneNumbersDirectExtensionResult } from '@/interfaces/phone-numbers';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  FormControlProps,
  FormHelperText,
} from '@mui/material';
import { FormikHelpers, FormikState, FormikValues, useFormik } from 'formik';

interface IDirectExtensionSelectProps {
  userInEditId: string | null | undefined;
  fieldName: string;
  formik: ReturnType<typeof useFormik> | (FormikValues & FormikHelpers<any> & FormikState<any>);
  directExtensions: IPhoneNumbersDirectExtensionResult[];
  formControlProps?: FormControlProps;
  isLoading?: boolean;
  error?: string;
}

const DirectExtensionSelect = ({
  fieldName,
  userInEditId,
  directExtensions,
  formik,
  formControlProps,
  isLoading = false,
  error = '',
}: IDirectExtensionSelectProps) => {
  directExtensions.sort((a) => (a.userRef ? 1 : -1));

  return (
    <FormControl fullWidth id={fieldName} variant="standard" {...formControlProps}>
      <InputLabel id={fieldName}>Direct extension</InputLabel>

      <Select
        id={fieldName}
        name={fieldName}
        value={formik.values[fieldName] || ''}
        disabled={isLoading || !!formControlProps?.disabled}
        onChange={(event) => {
          formik.setFieldTouched(fieldName);

          formControlProps?.onChange
            ? formControlProps.onChange(event as any)
            : formik.handleChange(event);
        }}
        onBlur={(event) => {
          formik.setFieldTouched(fieldName);

          formControlProps?.onBlur
            ? formControlProps.onBlur(event as any)
            : formik.handleBlur(event);
        }}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
      >
        {!directExtensions.length && !isLoading && (
          <MenuItem unselectable="on" disabled>
            <ListItemText primary="No direct extensions" />
          </MenuItem>
        )}

        {!!directExtensions?.length && (
          <MenuItem value="" disabled={!formik.values.directExtension}>
            <ListItemText primary="Unassign Direct extension from user" />
          </MenuItem>
        )}

        {directExtensions.map((el, i) => {
          const isSameUser = userInEditId === el.userRef;

          let secondaryText = '';

          if (isSameUser) {
            secondaryText = 'Assigned to current user';
          } else if (el.userRef) {
            secondaryText = 'In use';
          }

          return (
            <MenuItem
              key={i + el.phoneNumber}
              disabled={!isSameUser && !!el.userRef}
              value={el.phoneNumber}
            >
              <ListItemText primary={el.phoneNumber} secondary={secondaryText} />
            </MenuItem>
          );
        })}
      </Select>

      {error && <FormHelperText>{error}</FormHelperText>}

      {formik.touched[fieldName] && (
        <FormHelperText>{formik.errors[fieldName] as string}</FormHelperText>
      )}
    </FormControl>
  );
};

export default DirectExtensionSelect;

import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store/hooks';

import {
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  Paper,
} from '@mui/material/';

import { Formik, FormikProps } from 'formik';

import { IPartialResult } from '@/interfaces/querying-data';
import { IContactsGroupRecord, IGetContactsGroupsQueryParams } from '@/interfaces/contacts-group';

import contactsValidationSchemas from '@/helpers/validation-schemas/contacts/index';

import * as contactsThunk from '@/pages/dashboard/contacts/thunk';
import contactsGroupsThunk from '@/store/thunk/dashboard/contacts-groups';

import * as menuReducer from '@/store/reducers/dashboard/menu';

import UploadFileInput from '@/components/Upload-file-input/Upload-file-input';

import ROUTES from '@/constants/routes';
import DASHBOARD from '@/constants/dashboard';
import { FORMIK_STATUS } from '@/constants/formik';
import { defStyles } from '@/theme';

const INITIAL_VALUE = {
  file: null,
  contactsGroupRef: null,
  newContactsGroupName: '',
  newContactsGroupDescription: '',
};

enum IMPORT_CONTACTS_CONTACTS_GROUP_ACTION {
  CREATE = 'create',
  SELECT_EXISING = 'select_exising',
}

const UploadContacts: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formikRef = useRef<FormikProps<typeof INITIAL_VALUE> | null>(null);

  const [activeStep, setActiveStep] = useState<number>(0);

  const [contactsGroupsQueryParams, setContactsGroupsQueryParams] =
    useState<IGetContactsGroupsQueryParams>({
      search: '',
      skip: 0,
      limit: 100,
    });

  const [contactsGroupsResult, setContactsGroupsResult] = useState<
    IPartialResult<IContactsGroupRecord>
  >({
    maxCount: 0,
    records: [],
  });

  const [contactsGroupAction, setContactsGroupAction] =
    useState<IMPORT_CONTACTS_CONTACTS_GROUP_ACTION>(IMPORT_CONTACTS_CONTACTS_GROUP_ACTION.CREATE);

  const handleLoadContactsGroups = async (): Promise<void> => {
    formikRef.current?.setStatus(FORMIK_STATUS.LOADING);

    const { payload } = await dispatch(
      contactsGroupsThunk.fetchGetByParams(contactsGroupsQueryParams),
    );

    if (payload) {
      setContactsGroupsResult((prevState) => {
        if (!contactsGroupsQueryParams.skip) {
          return payload;
        }

        return {
          ...payload,
          records: prevState.records.concat(payload.records),
        };
      });
    }

    formikRef.current?.setStatus(FORMIK_STATUS.IDLE);
  };

  const handleDownloadFileSample = async (): Promise<void> => {
    const { meta, payload } = await dispatch(contactsThunk.fetchDownloadContactsFileSample());

    if (meta.requestStatus === 'fulfilled') {
      const url = window.URL.createObjectURL(new Blob([payload]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `upload_contacts_sample.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    handleLoadContactsGroups();
  }, [
    contactsGroupsQueryParams.search,
    contactsGroupsQueryParams.skip,
    contactsGroupsQueryParams.limit,
  ]);

  return (
    <Box flex={1} display="flex" p={2} justifyContent="center" overflow="auto">
      <Box component={Paper} flex={1} maxWidth="md" p={2} overflow="auto" sx={defStyles.scroll}>
        <Typography variant="h5" color="primary.main" textAlign="center" mb={2}>
          Upload company contacts
        </Typography>

        <Formik
          validateOnMount
          enableReinitialize
          innerRef={(ref) => (formikRef.current = ref)}
          initialStatus={FORMIK_STATUS.IDLE}
          initialValues={INITIAL_VALUE}
          validationSchema={contactsValidationSchemas.uploadContacts}
          onSubmit={async (values, helpers) => {
            helpers.setStatus(FORMIK_STATUS.LOADING);

            const formData = new FormData();

            formData.append('file', values.file as unknown as File);

            if (
              contactsGroupAction === IMPORT_CONTACTS_CONTACTS_GROUP_ACTION.SELECT_EXISING &&
              values.contactsGroupRef
            ) {
              formData.append('contactsGroupRef', values.contactsGroupRef);
            }

            if (
              contactsGroupAction === IMPORT_CONTACTS_CONTACTS_GROUP_ACTION.CREATE &&
              values.newContactsGroupName
            ) {
              formData.append('newContactsGroupName', values.newContactsGroupName);
              formData.append(
                'newContactsGroupDescription',
                values.newContactsGroupDescription ?? '',
              );
            }

            const result = await dispatch(contactsThunk.fetchUpload(formData));

            if (result.payload) {
              navigate(ROUTES.DASHBOARD_CONTACTS);
            }

            helpers.setStatus(FORMIK_STATUS.IDLE);
          }}
        >
          {({
            status,
            isValid,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            submitForm,
          }) => {
            const isLoading = status === FORMIK_STATUS.LOADING;

            return (
              <Stepper sx={{ flex: 1 }} orientation="vertical" activeStep={activeStep}>
                <Step key={0}>
                  <StepLabel>Upload contacts file</StepLabel>

                  <StepContent>
                    <Box display="flex" flexDirection="column" gap={2} p={2}>
                      <Box
                        display="flex"
                        gap={1}
                        justifyContent="space-between"
                        alignItems="flex-start"
                        flexWrap="wrap"
                      >
                        <UploadFileInput
                          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          label="Select file with contacts"
                          value={values.file}
                          onChange={({ file }) => {
                            setFieldTouched('file', true);
                            setFieldValue('file', file, true);
                          }}
                          error={touched.file && Boolean(errors.file)}
                          helperText={touched.file ? errors.file : ''}
                        />

                        <Button color="info" variant="text" onClick={handleDownloadFileSample}>
                          Download File Sample
                        </Button>
                      </Box>

                      <Box display="flex" gap={1} justifyContent="flex-end" alignItems="center">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={!values.file}
                          sx={{ minWidth: '8rem' }}
                          onClick={() => setActiveStep(1)}
                        >
                          Next
                        </Button>
                      </Box>
                    </Box>
                  </StepContent>
                </Step>

                <Step key={1}>
                  <StepLabel optional={<Typography variant="caption">Optional</Typography>}>
                    Assign contacts from file to contacts group
                  </StepLabel>

                  <StepContent>
                    <Box display="flex" flexDirection="column" gap={2} p={2}>
                      <ToggleButtonGroup
                        fullWidth
                        exclusive
                        sx={{ maxWidth: '30rem' }}
                        size="extra-small"
                        value={contactsGroupAction}
                        onChange={(event, value) => {
                          if (value) {
                            setContactsGroupAction(value);
                          }
                        }}
                      >
                        <ToggleButton value={IMPORT_CONTACTS_CONTACTS_GROUP_ACTION.CREATE}>
                          Create new contacts group
                        </ToggleButton>

                        <ToggleButton value={IMPORT_CONTACTS_CONTACTS_GROUP_ACTION.SELECT_EXISING}>
                          Select existing contacts group
                        </ToggleButton>
                      </ToggleButtonGroup>

                      {contactsGroupAction ===
                      IMPORT_CONTACTS_CONTACTS_GROUP_ACTION.SELECT_EXISING ? (
                        <>
                          <TextField
                            sx={{ flexGrow: 1 }}
                            type="search"
                            label="Search contacts groups"
                            variant="outlined"
                            size="small"
                            value={contactsGroupsQueryParams.search}
                            onChange={(e) =>
                              setContactsGroupsQueryParams((prevState) => ({
                                ...prevState,
                                skip: 0,
                                search: e.target.value,
                              }))
                            }
                            onBlur={(e) =>
                              setContactsGroupsQueryParams((prevState) => ({
                                ...prevState,
                                skip: 0,
                                search: e.target.value,
                              }))
                            }
                          />

                          <Box overflow="auto" maxHeight="20rem" sx={defStyles.scroll}>
                            <List dense disablePadding>
                              {contactsGroupsResult.records.map((contactsGroup) => {
                                return (
                                  <ListItem
                                    key={contactsGroup.id}
                                    disablePadding
                                    disableGutters
                                    sx={
                                      values.contactsGroupRef === contactsGroup.id
                                        ? {
                                            color: 'primary.contrastText',
                                            bgcolor: 'primary.main',
                                          }
                                        : {}
                                    }
                                  >
                                    <ListItemButton
                                      color="primary"
                                      onClick={() => {
                                        const value =
                                          values.contactsGroupRef === contactsGroup.id
                                            ? null
                                            : contactsGroup.id;

                                        setFieldValue('contactsGroupRef', value, true);
                                        setFieldTouched('contactsGroupRef', true);
                                      }}
                                    >
                                      <ListItemText
                                        primaryTypographyProps={{ sx: { color: 'inherit' } }}
                                        secondaryTypographyProps={{ sx: { color: 'inherit' } }}
                                        primary={contactsGroup.name}
                                        secondary={contactsGroup.description}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })}

                              {contactsGroupsResult.records.length >=
                              contactsGroupsResult.maxCount ? null : (
                                <ListItem disablePadding disableGutters>
                                  <ListItemButton
                                    onClick={() => {
                                      setContactsGroupsQueryParams((prevState) => ({
                                        ...prevState,
                                        skip: contactsGroupsResult.records.length,
                                      }));
                                    }}
                                  >
                                    <ListItemText sx={{ textAlign: 'center' }}>
                                      Load more
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              )}
                            </List>
                          </Box>
                        </>
                      ) : null}

                      {contactsGroupAction === IMPORT_CONTACTS_CONTACTS_GROUP_ACTION.CREATE ? (
                        <Stack spacing={2}>
                          <TextField
                            fullWidth
                            required
                            name="newContactsGroupName"
                            label="Name"
                            size="small"
                            variant="outlined"
                            placeholder="Sales"
                            disabled={isLoading}
                            value={values.newContactsGroupName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.newContactsGroupName && !!errors.newContactsGroupName}
                            helperText={
                              touched.newContactsGroupName && !!errors.newContactsGroupName
                            }
                          />

                          <TextField
                            fullWidth
                            variant="outlined"
                            name="newContactsGroupDescription"
                            label="Description"
                            size="small"
                            placeholder="Whistle company's sales team"
                            disabled={isLoading}
                            value={values.newContactsGroupDescription}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.newContactsGroupDescription &&
                              !!errors.newContactsGroupDescription
                            }
                            helperText={
                              touched.newContactsGroupDescription &&
                              !!errors.newContactsGroupDescription
                            }
                          />
                        </Stack>
                      ) : null}

                      <Box
                        display="flex"
                        gap={1}
                        mt={3}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ minWidth: '8rem' }}
                          onClick={() => setActiveStep(0)}
                        >
                          Back
                        </Button>

                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ minWidth: '10rem' }}
                          disabled={isLoading || !isValid}
                          onClick={submitForm}
                        >
                          {isLoading ? <CircularProgress /> : 'Upload contacts'}
                        </Button>
                      </Box>
                    </Box>
                  </StepContent>
                </Step>
              </Stepper>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default UploadContacts;

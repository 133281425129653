import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';

import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';

import {
  Clear as ClearIcon,
  ContentPaste as ContentPasteIcon,
  Backspace as BackspaceIcon,
} from '@mui/icons-material';

import { useAppSelector } from '@/store/hooks';

import { IUserResult } from '@/interfaces/users/users';

import * as phoneNumberHelper from '@/helpers/phone-number';

import CountryCode from '@/components/Country-code/Country-code';
import HtmlTooltip from '@/components/Html-tooltip/Html-tooltip';
import CustomAvatar from '@/components/Custom-avatar/Avatar';
import ContextMenu from '@/components/Context-menu/Context-menu';

interface IDialTextFieldProps {
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  countryCode: IUserResult['allowedCountries'][0] | null;
  setCountryCode: Dispatch<SetStateAction<IUserResult['allowedCountries'][0] | null>>;
  handleOnBackspacePress: VoidFunction;
  disabled?: boolean;
}

const DialTextField: FunctionComponent<IDialTextFieldProps> = ({
  phoneNumber,
  disabled,
  setPhoneNumber,
  setCountryCode,
  handleOnBackspacePress,
}): JSX.Element => {
  const dialerSearchedUser = useAppSelector((state) => state.voice.dialerSearchedUser);

  const [customContextMenuAnchorEl, setCustomContextMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  const handlePhoneNumberInputClick = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): Promise<void> => {
    e.preventDefault();

    setCustomContextMenuAnchorEl(e.currentTarget);
  };

  const handlePastePhoneNumber = async (): Promise<void> => {
    const copiedPhoneNumber = await navigator.clipboard.readText();

    const { isValid, validatedPhoneNumber, countryCode } =
      phoneNumberHelper.validate(copiedPhoneNumber);

    if (isValid && countryCode) {
      setPhoneNumber(validatedPhoneNumber.replace(countryCode, ''));
    }

    setCustomContextMenuAnchorEl(null);
  };

  const handleClearPhoneNumber = (): void => {
    setPhoneNumber('');
    setCustomContextMenuAnchorEl(null);
  };

  return (
    <Box>
      <ContextMenu
        anchorEl={customContextMenuAnchorEl}
        open={Boolean(customContextMenuAnchorEl)}
        handleClose={() => setCustomContextMenuAnchorEl(null)}
        list={[
          {
            name: 'Paste',
            props: { onClick: () => handlePastePhoneNumber() },
            icon: {
              Icon: ContentPasteIcon,
              props: { sx: { mr: 1 }, fontSize: 'small' },
            },
          },
          {
            isDivider: true,
          },
          {
            name: 'Clear',
            props: { onClick: () => handleClearPhoneNumber() },
            icon: {
              Icon: ClearIcon,
              props: { sx: { mr: 1 }, fontSize: 'small', color: 'error' },
            },
            typographyProps: { color: 'error' },
          },
        ]}
      />

      <Box display="flex" gap={1}>
        <CountryCode
          countryCodesListType="user-list"
          handleChange={(value) => setCountryCode(value)}
          disabled={(phoneNumber.length > 2 && phoneNumber.length < 5) || disabled}
          formControlProps={{
            variant: 'outlined',
            size: 'small',
          }}
        />

        <HtmlTooltip
          title={
            <>
              {/* need this check because open property is working to slow */}
              {dialerSearchedUser && (
                <Box>
                  <Typography fontSize={11} color="primary.contrastText" fontWeight="bold" mb={0.5}>
                    User found:
                  </Typography>
                  <Box display="flex" gap={1} justifyContent="center" alignItems="center">
                    <CustomAvatar
                      url={dialerSearchedUser.avatar?.url}
                      lastActivityAt={dialerSearchedUser.lastActivityAt}
                      sx={{ width: 30, height: 30 }}
                    />

                    <Box display="flex" flexDirection="column" justifyContent="center">
                      <Typography fontSize={12} color="primary.contrastText" fontWeight="bold">
                        Name: {dialerSearchedUser.fullName}
                      </Typography>
                      <Typography fontSize={12} color="primary.contrastText" fontWeight="bold">
                        DND: {dialerSearchedUser.dnd ? 'ON' : 'OFF'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          }
          open={!!dialerSearchedUser}
          placement="top"
          arrow={true}
        >
          <TextField
            sx={{
              flex: 1,
              '& .Mui-disabled': {
                '-webkit-text-fill-color': disabled ? 'initial' : 'black !important',
              },
            }}
            disabled
            value={phoneNumber}
            type="tel"
            variant="outlined"
            size="small"
            onContextMenu={handlePhoneNumberInputClick}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" size="small" onClick={handleOnBackspacePress}>
                    <BackspaceIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </HtmlTooltip>
      </Box>
    </Box>
  );
};

export default DialTextField;

import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useFormik } from 'formik';

import {
  Stack,
  Avatar,
  Button,
  IconButton,
  TextField,
  Tooltip,
  InputAdornment,
  CircularProgress,
  Box,
  Paper,
} from '@mui/material';
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';

import { IPhoneNumbersDirectExtensionResult } from '@/interfaces/phone-numbers';
import { IUserUpdateMeRequest } from '@/interfaces/users/users';

import * as stringHelper from '@/helpers/string';
import userValidationSchemas from '@/helpers/validation-schemas/users/index';

import * as userThunk from '@/pages/dashboard/users/thunk';
import * as phoneNumbersThunk from '@/store/thunk/phone-numbers';

import * as profileReducer from '@/store/reducers/profile/profile';

import useTemplateDialog from '@/hooks/use-template-dialog';

import DirectExtensionSelect from '@/components/Formik-form-components/Direct-extension-select';
import { TemplateDialog } from '@/components/Dialogs/Template-dialog';

import * as styles from './styles';

import { PHONE_NUMBER_TYPE } from '@/constants/phone-numbers';
import { FORMIK_STATUS } from '@/constants/formik';
import { ROLES } from '@/constants/roles';
import useThemeBreakpoints from '@/hooks/use-theme-breakpoints';

interface IProfileMeInputEndAdornmentProps {
  disabled?: boolean;
  onDiscardChange: VoidFunction;
  onSubmit: VoidFunction;
}

const ProfileMeInputEndAdornment: FunctionComponent<IProfileMeInputEndAdornmentProps> = ({
  disabled = false,
  onDiscardChange,
  onSubmit,
}): JSX.Element => {
  return (
    <InputAdornment position="end">
      <Tooltip title="Discard">
        <span>
          <IconButton
            size="medium"
            aria-haspopup="true"
            color="error"
            disabled={disabled}
            onClick={onDiscardChange}
          >
            <CloseIcon />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title="Save">
        <span>
          <IconButton
            size="medium"
            aria-haspopup="true"
            color="success"
            disabled={disabled}
            onClick={onSubmit}
          >
            <CheckIcon />
          </IconButton>
        </span>
      </Tooltip>
    </InputAdornment>
  );
};

const MeProfile: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user.user);
  const newAvatar = useAppSelector((state) => state.profile.newAvatar);
  const tenant = useAppSelector((state) => state.tenant.tenant);

  const [directExtensions, setDirectExtensions] = useState<IPhoneNumbersDirectExtensionResult[]>(
    [],
  );

  const [isDirectExtensionsTextFieldDisabled, setIsDirectExtensionsTextFieldDisabled] =
    useState<boolean>(true);

  const [dialogState, dialogStateActions] = useTemplateDialog({
    isOpened: false,
    title: 'Tranfer messages',
    textContent: 'Do you wish to transfer all chats/messages of selected Direct extension to user?',
    dialogProps: { maxWidth: 'md' },
    submitButtonProps: { children: 'Yes, transfer all messages' },
    closeButtonProps: {
      color: 'primary',
      children: "No, don't transfer any messages",
    },
  });

  const formik = useFormik<Omit<IUserUpdateMeRequest, 'transferChatsToAssignedUser'>>({
    initialStatus: FORMIK_STATUS.IDLE,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      directExtension: '',
      ipPhoneMac: '',
    },
    validationSchema: userValidationSchemas.updateMe,
    onSubmit: () => {},
  });

  const isMdOrUp = useThemeBreakpoints('up', 'md');

  const handleUpdateMe = async (data: Partial<IUserUpdateMeRequest>) => {
    if (!user) {
      formik.resetForm();
      return;
    }

    formik.setStatus(FORMIK_STATUS.LOADING);

    const { meta } = await dispatch(userThunk.fetchUpdateMe(data));

    if (meta.requestStatus === 'rejected') {
      formik.resetForm();
    } else {
      const { ipPhoneMac, ...partialUserData } = data;
      const userData = { ...user, ...partialUserData };

      if (ipPhoneMac) {
        userData.ipPhone = { mac: ipPhoneMac };
      }

      if (Object.hasOwn(data, 'directExtension')) {
        await handleLoadDirectExtensions();
      }

      formik.resetForm({ values: { ...formik.values, ...data } });
    }

    formik.setStatus(FORMIK_STATUS.IDLE);
  };

  const handleLoadDirectExtensions = async () => {
    if (!user || !tenant) {
      return;
    }

    setIsDirectExtensionsTextFieldDisabled(true);

    const { payload } = await dispatch(
      phoneNumbersThunk.fetchGetPhoneNumbers({
        type: PHONE_NUMBER_TYPE.DIRECT_EXTENSION,
        limit: 0,
        skip: 0,
        search: '',
      }),
    );

    if (payload) {
      setDirectExtensions(payload);
      setIsDirectExtensionsTextFieldDisabled(false);
    }
  };

  const handleNewAvatar = async (event: any) => {
    const avatar = event.target.files[0];
    dispatch(profileReducer.setNewAvatar(avatar));

    const formData = new FormData();
    formData.append('file', avatar);
    await dispatch(userThunk.fetchUploadUserAvatar(formData));
  };

  const deleteAvatar = () => {
    dispatch(profileReducer.setNewAvatar(null));
    dispatch(userThunk.fetchDeleteAvatar());
  };

  const handleFieldReset = async (fieldName: string, resetValue: any): Promise<void> => {
    if (user) {
      await formik.setFieldValue(fieldName, resetValue);

      formik.setFieldError(fieldName, undefined);

      await formik.setFieldTouched(fieldName, false);
    }
  };

  const getNoAvatarLabel = (): string => {
    if (!user || user.avatar?.url) {
      return '';
    }

    return user.firstName.charAt(0).toUpperCase() + (user.lastName?.charAt(0)?.toUpperCase() ?? '');
  };

  useEffect(() => {
    handleLoadDirectExtensions();
  }, [tenant?.id, user?.id]);

  useEffect(() => {
    if (user) {
      formik.resetForm({
        values: {
          firstName: user.firstName,
          lastName: user.lastName ?? '',
          email: user.email,
          directExtension: user.directExtension,
          ipPhoneMac: user.ipPhone?.mac ?? '',
        },
        touched: {},
      });
    }
  }, [user]);

  const userAvatar = user?.avatar?.url;

  const isFormDisabled = !user || formik.status === FORMIK_STATUS.LOADING;

  return (
    <Box display="flex" flex={1} p={2} overflow="auto">
      <TemplateDialog {...dialogState} />

      <Box
        display="flex"
        gap={2}
        justifyContent="center"
        alignItems={{ md: 'flex-start' }}
        flexDirection={{ xs: 'column', md: 'row' }}
        flex={1}
        component={isMdOrUp ? undefined : Paper}
      >
        <Box
          display="flex"
          flex={1}
          maxWidth={{ md: '13rem' }}
          flexDirection="column"
          alignItems="center"
          gap={2}
          p={2}
          component={isMdOrUp ? Paper : undefined}
        >
          {user ? (
            <Avatar
              src={newAvatar ? URL.createObjectURL(newAvatar) : user?.avatar?.url}
              sx={{
                ...styles.avatar,
                bgcolor: userAvatar ? 'initial' : 'secondary.main',
              }}
            >
              {getNoAvatarLabel()}
            </Avatar>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" sx={styles.avatar}>
              <CircularProgress size={120} />
            </Box>
          )}

          <Stack spacing={1} maxWidth="12rem">
            <Button fullWidth color="primary" variant="contained" component="label">
              Upload new avatar
              <input
                hidden
                accept="image/*"
                multiple={false}
                type="file"
                onChange={handleNewAvatar}
              />
            </Button>

            <Button
              fullWidth
              disabled={!user?.avatar}
              color="error"
              variant="outlined"
              component="label"
              onClick={deleteAvatar}
            >
              Delete avatar
            </Button>
          </Stack>
        </Box>

        <Box
          display="flex"
          flex={1}
          maxWidth={{ md: '40rem' }}
          flexDirection="column"
          gap={1}
          p={2}
          component={isMdOrUp ? Paper : undefined}
        >
          <TextField
            required
            id="firstName"
            name="firstName"
            type="text"
            variant="standard"
            label="First name"
            disabled={isFormDisabled}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            InputProps={{
              endAdornment: (
                <ProfileMeInputEndAdornment
                  onDiscardChange={() => handleFieldReset('firstName', user?.firstName)}
                  onSubmit={() => handleUpdateMe({ firstName: formik.values.firstName })}
                />
              ),
            }}
          />

          <TextField
            id="lastName"
            name="lastName"
            variant="standard"
            type="text"
            label="Last name"
            disabled={isFormDisabled}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            InputProps={{
              endAdornment: (
                <ProfileMeInputEndAdornment
                  onDiscardChange={() => handleFieldReset('lastName', user?.lastName)}
                  onSubmit={() => handleUpdateMe({ lastName: formik.values.lastName })}
                />
              ),
            }}
          />

          <TextField
            required
            id="email"
            name="email"
            variant="standard"
            type="email"
            label="Email"
            disabled={isFormDisabled}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputProps={{
              endAdornment: (
                <ProfileMeInputEndAdornment
                  onDiscardChange={() => handleFieldReset('email', user?.email)}
                  onSubmit={() => handleUpdateMe({ email: formik.values.email })}
                />
              ),
            }}
          />

          <DirectExtensionSelect
            formik={formik}
            fieldName="directExtension"
            directExtensions={directExtensions}
            isLoading={!directExtensions.length}
            userInEditId={user?.id}
            formControlProps={{
              required: true,
              disabled: isFormDisabled || isDirectExtensionsTextFieldDisabled,
              onChange: async (event: ChangeEvent<HTMLInputElement>) => {
                if (dialogState.isOpened || !user) {
                  return;
                }

                const directExtension = event.target.value || null;

                const data = {
                  directExtension,
                  transferChatsToAssignedUser: false,
                };

                if (data.directExtension && data.directExtension !== user?.directExtension) {
                  const response = await dialogStateActions.openDialog();

                  if (response === null) {
                    return;
                  } else {
                    data.transferChatsToAssignedUser = response;
                  }
                }

                await formik.setFieldValue('directExtension', directExtension, true);
                await formik.setFieldTouched('directExtension', true);

                await handleUpdateMe(data);
              },
            }}
          />

          {/* 
          For now leave as is because ip phone configuration is not used
          <TextField
            id="ipPhoneMac"
            name="ipPhoneMac"
            variant="standard"
            type="ipPhoneMac"
            label="Ip phone mac address"
            disabled={!user?.ipPhone?.mac || !isUserRoleMatches([ROLES.SUPER_ADMIN, ROLES.ADMIN])}
            value={formik.values.ipPhoneMac}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.ipPhoneMac && Boolean(formik.errors.ipPhoneMac)}
            helperText={formik.touched.ipPhoneMac && formik.errors.ipPhoneMac}
            sx={styles.disabledButReadableTextField}
            InputProps={{
              endAdornment: (
                <ProfileMeInputEndAdornment
                  disabled={!user?.ipPhone?.mac}
                  onDiscardChange={() => handleFieldReset('ipPhoneMac')}
                  onSubmit={updateMe}
                />
              ),
            }}
          /> */}

          {user?.companyExtension ? (
            <TextField
              required
              disabled
              id="companyExtension"
              name="companyExtension"
              variant="standard"
              type="text"
              label="Company extension"
              value={user?.companyExtension}
              sx={styles.disabledButReadableTextField}
            />
          ) : null}

          {user?.role ? (
            <TextField
              required
              disabled
              id="role"
              name="role"
              variant="standard"
              type="text"
              label="Role"
              value={stringHelper.firstCharToUpperCase(user?.role)}
              sx={styles.disabledButReadableTextField}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default MeProfile;

import * as voiceThunk from '@/store/thunk/dashboard/voice';

import * as voiceReducer from '@/store/reducers/dashboard/voice';
import { AppDispatch, store } from '@/store/store';

import { getActiveConnection } from '../voice/call';

export const endCall = async (data: {
  dispatch: AppDispatch;
  uuid?: string;
  skipInvokeCancelCall?: boolean;
}) => {
  const state = store.getState();
  const { dispatch } = data;
  let uuid = data.uuid;

  const { voice: voiceState, twilio: twilioState } = state;
  const { connections } = voiceState;
  const { device } = twilioState;

  dispatch(voiceReducer.setIsShowCallStatusLoader(false));

  if (!uuid) {
    const activeNowConnection = getActiveConnection();
    if (activeNowConnection) {
      uuid = activeNowConnection.uuid;
    } else {
      return;
    }
  }

  const connection = connections.find((conn: any) => conn.uuid === uuid);

  if (!connection || connection.isForwarding) {
    return;
  }

  dispatch(voiceReducer.setDialerActionBtn('call'));
  dispatch(voiceReducer.setCallToContact(null));

  const remainingConnections = connections.filter((conn: any) => conn.uuid !== uuid);

  if (remainingConnections.length) {
    dispatch(voiceReducer.setSelectedConnection(remainingConnections[0].uuid));
  }

  dispatch(voiceReducer.removeConnection(uuid));

  if (!data.skipInvokeCancelCall) {
    await dispatch(voiceThunk.fetchCancelCall(uuid));
  }

  clearInterval(connection?.counterFunction);

  connection.conn.disconnect();
  connection.conn.reject();

  if (connections.length === 1) {
    device?.audio?.incoming(true);
  }

  dispatch(voiceReducer.setDialerSearchedUser(undefined));
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ICountryCodesResult } from '@/interfaces/country-code';

import { fetchCountryCodes } from '../thunk/country-codes';

export interface ICountryCodesSlice {
  list: ICountryCodesResult[];
}

const initialState: ICountryCodesSlice = {
  list: [],
};

export const countryCodesSlice = createSlice({
  name: 'country-codes',
  initialState,
  reducers: {
    setCountryCodes: (state, action: PayloadAction<ICountryCodesResult[]>) => {
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCountryCodes.fulfilled, (state, action) => {
      state.list = action.payload;
    });
  },
});

export const { setCountryCodes } = countryCodesSlice.actions;

export default countryCodesSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUserLocation } from '@/interfaces/users/user-location';

export interface IUserLocationSlice {
  locationData: IUserLocation | null;
}

const initialState: IUserLocationSlice = {
  locationData: null,
};

export const userLocationSlice = createSlice({
  name: 'userLocation',
  initialState,
  reducers: {
    setUserLocation: (state, action: PayloadAction<IUserLocation>) => {
      state.locationData = action.payload;
    },
  },
});

export const { setUserLocation } = userLocationSlice.actions;

export default userLocationSlice.reducer;

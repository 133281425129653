import { IGetUsersQueryParams } from '@/interfaces/users/users';
import { IUserResult } from '@/interfaces/users/users';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { IThunkExtra } from '@/store/store';

import handleErrors from '@/services/handle-errors';

export const fetchGetUsers = createAsyncThunk(
  'user/fetchUsers',
  async (data: IGetUsersQueryParams, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.getByParams(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

// Refer to IUserCreateRequest interface for structure of FormData
export const fetchCreateUser = createAsyncThunk(
  'user/fetchCreateUser',
  async (data: FormData, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.usersApis.create(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

// Refer to IUserUpdateRequest interface for structure of FormData
export const fetchEdit = createAsyncThunk(
  'user/fetchEdit',
  async (data: { id: string; formData: FormData }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.usersApis.edit(data.id, data.formData);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchDeleteById = createAsyncThunk(
  'user/fetchDeleteById',
  async (data: { id: string }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.usersApis.deleteById(data.id);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchByCompanyExtension = createAsyncThunk(
  'user/fetchByCompanyExtension',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.usersApis.getByCompanyExtension(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchByDirectExtension = createAsyncThunk(
  'user/fetchByDirectExtension',
  async (data: string, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.usersApis.getByDirectExtension(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchResetPassword = createAsyncThunk(
  'user/fetchResetPassword',
  async (userId: IUserResult['id'], thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;

    const response = await v1Apis.usersApis.resetPassword(userId);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

import { useEffect, useState } from 'react';

import {
  TextFieldProps,
  AutocompleteProps,
  Autocomplete,
  Chip,
  TextField,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteValue,
} from '@mui/material';
import {
  IPromoCodeResult,
  IPromoCodeVerifyRequest,
  IPromoCodeVerifyResult,
} from '@/interfaces/promo-codes';
import { ISubscriptionResult } from '@/interfaces/subscriptions';

import promoCodesThunk from '@/store/thunk/promo-codes';
import { useAppDispatch } from '@/store/hooks';

export interface IPromoCodeOnChangeData {
  valid: IPromoCodeResult[];
  all: Array<IPromoCodeResult | IAutocompleteOption>;
  haveErrors: boolean;
}

interface IPromoCodesInputProps {
  seats: number;
  selectedSubscription: ISubscriptionResult | undefined;
  onChange: (data: IPromoCodeOnChangeData) => void;
  AutocompleteProps?: Partial<AutocompleteProps<any, true, false, true>>;
  TextFieldProps?: TextFieldProps;
}

interface IAutocompleteOption {
  value: string;
  label: string;
  errorMessage: string;
}

interface IExtendedPromoCodeResult extends IPromoCodeResult {
  label: string;
  errorMessage: string;
}

const PromoCodesInput = ({
  seats,
  selectedSubscription,
  onChange,
  AutocompleteProps = {},
  TextFieldProps = {},
}: IPromoCodesInputProps) => {
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<Array<IExtendedPromoCodeResult | IAutocompleteOption>>([]);
  const [error, setError] = useState('');

  const validatePromoCodes = async (
    data: IPromoCodeVerifyRequest,
  ): Promise<Array<IExtendedPromoCodeResult | IAutocompleteOption> | undefined> => {
    const apiResponse = await dispatch(promoCodesThunk.fetchVerifyPromoCodes(data));

    if (!apiResponse) {
      return;
    }

    const payload = apiResponse.payload as IPromoCodeVerifyResult;

    setError(payload.errorMessage ? payload.errorMessage : '');

    return payload.data.map(({ promoCode, validationResult }, i) => {
      if (promoCode) {
        return {
          label: promoCode.value,
          errorMessage: validationResult.errorMessage,
          ...promoCode,
        };
      } else {
        const { value } = data.data[i];

        return {
          value: value,
          label: value,
          errorMessage: validationResult.errorMessage,
        };
      }
    });
  };

  const handleValueChange = async (
    event: React.SyntheticEvent,
    newValue: AutocompleteValue<any, true, false, true>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>,
  ) => {
    if (['createOption', 'selectOption'].includes(reason)) {
      const optionValue = details?.option?.value || details?.option;

      if (!optionValue) {
        return;
      }

      const promoCodesToValidate = optionValue.includes(',')
        ? optionValue
            .split(',')
            .filter((elToAdd: string) => value.every((el) => el.value !== elToAdd) && elToAdd)
            .map((el: string) => ({ value: el }))
        : [{ value: optionValue }];

      if (!promoCodesToValidate.length || value.some((el) => el.value === optionValue)) {
        return;
      }

      const validatedPromoCodes = await validatePromoCodes({
        seats,
        data: promoCodesToValidate,
        subscriptionRef: selectedSubscription?.id as string,
      });

      if (!validatedPromoCodes) {
        return;
      }

      setValue((prevState) => [...prevState, ...validatedPromoCodes]);

      return;
    } else if (['clear', 'removeOption'].includes(reason)) {
      const validatedPromoCodes = await validatePromoCodes({
        seats,
        data: newValue.map((el) => ({ value: el?.value || el })),
        subscriptionRef: selectedSubscription?.id as string,
      });

      if (!validatedPromoCodes) {
        return;
      }

      setValue(validatedPromoCodes);
    }
  };

  const getHelperText = () => {
    if (error) {
      return error;
    }

    const errorMessage = value.some((el) => !!el.errorMessage);

    if (errorMessage) {
      return value.map((el) => (el.errorMessage ? el.errorMessage + '. ' : ''));
    }

    return 'You can paste promo codes separated by comma';
  };

  const validatePromoCodesOnDependenciesChange = async () => {
    const noDependenciesFound = !seats || !selectedSubscription;

    if (noDependenciesFound) {
      if (!!value.length) {
        setError('Please verify seats amount and selected subscription');
      }

      return;
    }

    const validatedPromoCodes = await validatePromoCodes({
      seats,
      subscriptionRef: selectedSubscription.id,
      data: value.map(({ value }) => ({ value })),
    });

    if (!validatedPromoCodes) {
      return;
    }

    setValue(validatedPromoCodes);
  };

  useEffect(() => {
    const valid = value
      .filter(
        (el) =>
          !el.errorMessage &&
          Object.hasOwn(el, 'id') &&
          (el as Record<string, any>)?.subscriptionRef === selectedSubscription?.id,
      )
      .slice(0, seats) as IPromoCodeResult[];

    onChange({
      valid,
      all: value,
      haveErrors: !!error || value.some((el) => !!el.errorMessage),
    });
  }, [value]);

  useEffect(() => {
    const id = setTimeout(() => validatePromoCodesOnDependenciesChange(), 500);
    return () => clearTimeout(id);
  }, [seats, selectedSubscription]);

  return (
    <Autocomplete
      {...AutocompleteProps}
      freeSolo
      multiple
      handleHomeEndKeys
      options={[]}
      value={value}
      onChange={handleValueChange}
      filterOptions={(options, { inputValue }) => {
        if (inputValue !== '') {
          return [
            {
              value: inputValue,
              label: `Add "${inputValue}"`,
            },
          ];
        }

        return [];
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }

        return option.label;
      }}
      renderTags={(value: Array<IExtendedPromoCodeResult | IAutocompleteOption>, getTagProps) =>
        value.map((option, index: number) => (
          <Chip
            variant="outlined"
            color={option.errorMessage ? 'error' : 'default'}
            label={option.value}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...TextFieldProps}
          {...params}
          variant="outlined"
          label="Promo codes"
          placeholder="Enter promo code..."
          error={!!error || value.some((el) => !!el.errorMessage)}
          helperText={getHelperText()}
        />
      )}
    />
  );
};

export default PromoCodesInput;

import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';

import {
  IUseMultiCheckboxSelectState,
  useMultiCheckboxSelect,
} from '@/hooks/use-multi-checkbox-select';

import { IPartialResult } from '@/interfaces/querying-data';
import { IGetUsersGroupsQueryParams, IUsersGroupResult } from '@/interfaces/users-groups';

import DataTemplate from '@/components/Data-template/Data-template';

import apisV1 from '@/services/api/v1/index';

export type SelectUsersGroupsFromListDialogUser = Pick<
  IUsersGroupResult,
  'id' | 'name' | 'description'
>;

interface ISelectUsersFromListDialogProps {
  initialUsersGroups: SelectUsersGroupsFromListDialogUser[];
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (
    response: IUseMultiCheckboxSelectState<SelectUsersGroupsFromListDialogUser> | null,
  ) => void;
}

const SelectUsersGroupsFromListDialog: FunctionComponent<ISelectUsersFromListDialogProps> = ({
  initialUsersGroups,
  isOpened,
  setIsOpened,
  handleSubmit,
}): JSX.Element => {
  const [usersGroupsQueryParams, setUsersGroupsQueryParams] = useState<IGetUsersGroupsQueryParams>({
    search: '',
    skip: 0,
    limit: 50,
  });

  const [usersGroupsResult, setUsersGroupsResult] = useState<IPartialResult<IUsersGroupResult>>({
    maxCount: 0,
    records: [],
  });

  const [usersSelection, selectedUsersActions] =
    useMultiCheckboxSelect<SelectUsersGroupsFromListDialogUser>({
      selectAllBehavior: 'select-all-with-exclude',
      initialOptions: initialUsersGroups,
      getItemId: (el) => el.id as string,
      maxCount: usersGroupsResult.maxCount,
    });

  const [isLoading, setIsLoading] = useState(false);

  const loadUsers = async () => {
    setIsLoading(true);

    const { success, data } = await apisV1.usersGroupsApis.getByParams(usersGroupsQueryParams);

    if (success) {
      setUsersGroupsResult(data);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoading) {
      loadUsers();
    }
  }, [usersGroupsQueryParams.search, usersGroupsQueryParams.skip, usersGroupsQueryParams.limit]);

  const handleClose = (
    response: IUseMultiCheckboxSelectState<SelectUsersGroupsFromListDialogUser> | null,
  ) => {
    setIsOpened(false);
    handleSubmit(response);
  };

  return (
    <Dialog keepMounted fullWidth maxWidth="sm" open={isOpened} onClose={() => handleClose(null)}>
      <DialogContent sx={{ pb: 0 }}>
        <Stack spacing={2}>
          <>
            <TextField
              fullWidth
              type="search"
              placeholder="Search"
              variant="outlined"
              size="small"
              value={usersGroupsQueryParams.search}
              onChange={(e) =>
                setUsersGroupsQueryParams((prevState) => ({ ...prevState, search: e.target.value }))
              }
              onBlur={(e) =>
                setUsersGroupsQueryParams((prevState) => ({ ...prevState, search: e.target.value }))
              }
            />

            <DataTemplate
              isLoading={isLoading}
              pagination={{
                maxCount: usersGroupsResult.maxCount,
                paginationData: {
                  skip: usersGroupsQueryParams.skip,
                  limit: usersGroupsQueryParams.limit,
                },
                onPageChange: (paginationData) =>
                  setUsersGroupsQueryParams((prevState) => ({
                    ...prevState,
                    ...paginationData,
                  })),
                onRowsPerPageChange: (paginationData) =>
                  setUsersGroupsQueryParams((prevState) => ({
                    ...prevState,
                    ...paginationData,
                  })),
              }}
            >
              <List
                dense
                disablePadding
                sx={{
                  position: 'relative',
                  height: '50vh',
                  overflow: 'auto',
                }}
              >
                <ListItem
                  disablePadding
                  disableGutters
                  dense
                  sx={{
                    position: 'sticky',
                    top: 0,
                    bgcolor: 'common.white',
                    zIndex: 2,
                    borderColor: 'secondary.light',
                  }}
                  secondaryAction={
                    <Checkbox
                      tabIndex={-1}
                      disableRipple
                      indeterminate={selectedUsersActions.isAllOptionHasIndeterminateState(
                        usersGroupsResult.records,
                      )}
                      checked={selectedUsersActions.isAllOptionSelected(usersGroupsResult.records)}
                      inputProps={{ 'aria-labelledby': 'all-users-selected-btn' }}
                      onChange={(event, checked) => selectedUsersActions.handleSelectAll(checked)}
                    />
                  }
                >
                  <ListItemButton
                    disableRipple
                    role="checkbox"
                    onClick={() => {
                      const currentlyChecked = selectedUsersActions.isAllOptionSelected();
                      selectedUsersActions.handleSelectAll(!currentlyChecked);
                    }}
                  >
                    <ListItemText id="all-users-groups-selected-btn" primary="Select all" />
                  </ListItemButton>
                </ListItem>

                {usersGroupsResult.records.map((el) => {
                  const labelId = `users-groups-checkbox-list-label-${el.id}`;

                  return (
                    <ListItem
                      key={el.id}
                      disablePadding
                      disableGutters
                      dense
                      secondaryAction={
                        <Checkbox
                          edge="start"
                          checked={selectedUsersActions.isSelected(el)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          onChange={() => selectedUsersActions.handleSelect(el)}
                        />
                      }
                    >
                      <ListItemButton
                        role="checkbox"
                        onClick={() => selectedUsersActions.handleSelect(el)}
                      >
                        <ListItemText
                          id={labelId}
                          primary={el.name}
                          secondary={el.description ?? 'No description'}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </DataTemplate>
          </>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          color="error"
          size="medium"
          disabled={isLoading}
          onClick={() => setIsOpened(false)}
        >
          Cancel
        </Button>

        <Button
          disableElevation
          color="primary"
          variant="contained"
          size="medium"
          disabled={isLoading}
          onClick={() => {
            handleClose(usersSelection);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectUsersGroupsFromListDialog;

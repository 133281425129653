import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  SyntheticEvent,
} from 'react';

import ImageViewer from 'react-simple-image-viewer';

import {
  Box,
  Paper,
  LinearProgress,
  Modal,
  List,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
  CircularProgress,
  Button,
  Typography,
} from '@mui/material';

import {
  AttachFile as AttachFileIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';

import { IMessageResult } from '@/interfaces/message';
import { IChatResult } from '@/interfaces/chat';
import { IPaginationParams, IPartialResult } from '@/interfaces/querying-data';

import sendMsgImage from '@/assets/images/img-send-msg.png';

import { ISendMessageContent } from '@/pages/dashboard/chat/Chats-messages-layout';
import Message from '@/pages/dashboard/chat/chat-messages/classic/Message';

import * as styles from '@/pages/dashboard/chat/style';
import { defStyles } from '@/theme';

interface IMessagesProps {
  isMessageSending: boolean;
  isMessagesLoading: boolean;
  isSearchActive: boolean;
  selectedChat: IChatResult | null;
  messagesResult: IPartialResult<IMessageResult>;
  setQueryParams: Dispatch<SetStateAction<IPaginationParams>>;
  setChatsResult: React.Dispatch<React.SetStateAction<IPartialResult<IChatResult>>>;
  sendMessageContent: ISendMessageContent;
  setSendMessageContent: Dispatch<SetStateAction<ISendMessageContent>>;
  setIsAdvancedSearchDialogOpened: React.Dispatch<React.SetStateAction<boolean>>;
  handleSendMessage: (event: KeyboardEvent | MouseEvent | SyntheticEvent) => void;
  handleSelectFiles: (files: FileList) => void;
  handleSelectMessage: (message: IMessageResult) => void;
}

const Messages: FunctionComponent<IMessagesProps> = ({
  isMessagesLoading,
  isMessageSending,
  isSearchActive,
  selectedChat,
  messagesResult,
  setQueryParams,
  sendMessageContent,
  setSendMessageContent,
  setIsAdvancedSearchDialogOpened,
  handleSendMessage,
  handleSelectFiles,
  handleSelectMessage,
}): JSX.Element => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      //@ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setImagePreview(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <Box display="flex" flexDirection="column" flex={1} overflow="auto" component={Paper}>
      <Box>
        {isMessagesLoading ? <LinearProgress variant="indeterminate" color="primary" /> : null}
      </Box>

      <Button
        fullWidth
        variant="contained"
        size="large"
        disableElevation={false}
        onClick={() => setIsAdvancedSearchDialogOpened(true)}
        sx={{
          color: 'grey',
          bgcolor: 'white',
          fontSize: '1rem',
          pl: 2.75,
          boxShadow: 2,
          justifyContent: 'flex-start',
          '&:hover': {
            color: 'grey[700]',
            bgcolor: 'white',
          },
          mb: 1,
        }}
      >
        Search messages
      </Button>

      <Box sx={styles.paperSx} flex={1}>
        {/* //TODO: move it to the component */}
        <Modal open={!!imagePreview}>
          <Box>
            <ImageViewer
              src={[imagePreview ?? '']}
              currentIndex={0}
              disableScroll={true}
              closeOnClickOutside={true}
              onClose={() => setImagePreview(null)}
              backgroundStyle={styles.imagePreview}
            />
          </Box>
        </Modal>

        <List
          sx={{
            '&::-webkit-scrollbar': { display: 'none' },
            bgcolor: 'background.paper',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
        >
          {messagesResult.records.map((message) => (
            <Message
              key={message.id}
              message={message}
              setImagePreview={setImagePreview}
              onAvatarClick={() => handleSelectMessage(message)}
            />
          ))}

          {selectedChat &&
          !isMessagesLoading &&
          messagesResult.maxCount > messagesResult.records.length ? (
            <Button
              fullWidth
              color="secondary"
              onClick={() => {
                setQueryParams((prevState) => ({
                  ...prevState,
                  skip: messagesResult.records.length,
                }));
              }}
            >
              <KeyboardArrowUpIcon fontSize="medium" />
            </Button>
          ) : null}
        </List>

        {isSearchActive ? null : (
          <Box display="flex" flexDirection="column" gap={1} p={1}>
            {sendMessageContent.files.length ? (
              <Box
                component={Paper}
                display="flex"
                flexDirection="column"
                maxHeight="160px"
                flex={1}
                overflow="auto"
                sx={defStyles.scroll}
              >
                {sendMessageContent.files.map((file, index) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    key={file.name + file.size + file.lastModified + index}
                  >
                    <IconButton
                      aria-label="comment"
                      color="error"
                      onClick={() =>
                        setSendMessageContent((prevState) => ({
                          ...prevState,
                          files: prevState.files.filter((el, i) => i !== index),
                        }))
                      }
                    >
                      <DeleteOutlineIcon />
                    </IconButton>

                    <Typography variant="body2">{file.name}</Typography>
                  </Box>
                ))}
              </Box>
            ) : null}

            <Box component="form" sx={styles.inputBoxSX} noValidate autoComplete="off">
              {/* SEND BUTTON */}
              <TextField
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                value={sendMessageContent.text}
                onChange={(event) =>
                  setSendMessageContent((prevState) => ({
                    ...prevState,
                    text: event.target.value,
                  }))
                }
                onKeyDown={handleSendMessage}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        component="label" //@ts-ignore
                        onClick={(event: React.ChangeEvent<HTMLInputElement>) =>
                          //@ts-ignore
                          (event.target.value = null)
                        }
                      >
                        <AttachFileIcon color="inherit" />

                        <input
                          hidden
                          accept="*"
                          multiple={true}
                          type="file"
                          onChange={(event) => {
                            if (event.target.files) {
                              handleSelectFiles(event.target.files);
                            }
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled={isMessageSending} onClick={handleSendMessage}>
                        {isMessageSending ? (
                          <CircularProgress color="inherit" />
                        ) : (
                          <Box
                            component="img"
                            sx={{ width: '50px' }}
                            src={sendMsgImage}
                            loading="lazy"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Messages;

import { createAsyncThunk } from '@reduxjs/toolkit';

import handleErrors from '@/services/handle-errors';

import { IThunkExtra } from '@/store/store';

export const fetchFeaturesComparison = createAsyncThunk(
  'pricing/fetchFeaturesComparison',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.featuresComparisonsApis.getAll();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSubscriptions = createAsyncThunk(
  'pricing/fetchSubscriptions',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.subscriptionsApis.getAll();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

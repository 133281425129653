import { ChangeEventHandler, FunctionComponent } from 'react';

import { Box, Button, ButtonProps, IconButton, Stack, Tooltip, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';

interface IUploadFileInputProps {
  // For now need to build/update component to suport multiple files upload
  multiple?: boolean;
  // MIME types
  accept: string;
  label?: string;
  value: (File | Blob | null | undefined) | FileList;
  onChange: (value: { file: File | null; files: FileList | null }) => void;
  error?: boolean;
  helperText?: string;
  buttonProps?: ButtonProps<'label'>;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadFileInput: FunctionComponent<IUploadFileInputProps> = ({
  multiple = false,
  label = 'Upload file',
  value: inputValue,
  accept,
  onChange,
  error = false,
  helperText = '',
  buttonProps = {},
}): JSX.Element => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    if (multiple) {
      onChange({
        file: null,
        files: event.target.files as FileList,
      });
    } else {
      onChange({
        file: event.target.files?.item(0) ?? null,
        files: null,
      });
    }
  };

  const handleDeleteFile = (): void => {
    if (multiple) {
      // Build for multiple files
    } else {
      onChange({
        file: null,
        files: null,
      });
    }
  };

  const value = multiple ? (inputValue as FileList) : (inputValue as File | Blob);

  return (
    <Stack spacing={1}>
      <Box display="flex" alignItems="center" gap={2}>
        <Button
          variant="contained"
          sx={{ minWidth: '8rem', ...(buttonProps?.sx ?? {}) }}
          {...buttonProps}
          component="label"
          role={undefined}
          tabIndex={-1}
        >
          {label}
          <VisuallyHiddenInput
            type="file"
            accept={accept}
            multiple={multiple}
            onChange={handleChange}
          />
        </Button>

        {value ? (
          <>
            <Typography variant="body1" component="span" sx={{ wordBreak: 'break-all' }}>
              {value instanceof File ? value.name : 'File uploaded'}
            </Typography>

            <Tooltip title="Delete" placement="right">
              <span>
                <IconButton
                  aria-label="delete"
                  color="error"
                  size="small"
                  onClick={handleDeleteFile}
                >
                  <DeleteOutlineIcon fontSize="inherit" />
                </IconButton>
              </span>
            </Tooltip>
          </>
        ) : null}
      </Box>

      {helperText ? (
        <Typography variant="body2" color={error ? 'error' : 'secondary.light'}>
          {helperText}
        </Typography>
      ) : null}
    </Stack>
  );
};

export default UploadFileInput;

import {
  IGetPhoneNumbersParams,
  IPhoneNumbersAssignUnassignMaskingPhoneNumberForTenantRequest,
  IPhoneNumbersSetDefaultForTenantRequest,
  IUpdateAssignUnassignDirectExtensionForUserRequest,
} from '@/interfaces/phone-numbers';

import makeRequest from '../make-request';
import token from '../token';

const GET_PHONE_NUMBERS = '/api/v1/phone-numbers';
const GET_ASSIGN_UNASSIGN_DIRECT_EXTENSION_FOR_USER_URL = (id: string) =>
  `/api/v1/phone-numbers/direct-extensions/${id}`;
const GET_ASSIGN_UNASSIGN_MASKING_PHONE_NUMBER_FOR_TENANT_URL = (id: string) =>
  `/api/v1/phone-numbers/assign-unassign-masking/${id}`;
const GET_URL_SET_DEFAULT_PHONE_NUMBER_FOR_TENANT_URL = (id: string) =>
  `/api/v1/phone-numbers/set-default-tenant/${id}`;
const GET_TENANT_PHONE_NUMBERS_LIST = `/api/v1/phone-numbers/tenant-list`;

export const getPhoneNumbers = async (data: IGetPhoneNumbersParams) => {
  const queryParams: Record<string, string> = {
    type: data.type,
    search: data.search || '',
    limit: data.limit.toString(),
    skip: data.skip.toString(),
  };

  if (data.sortField) {
    queryParams.sortField = data.sortField;
  }

  if (data.sortDirection) {
    queryParams.sortDirection = data.sortDirection.toString();
  }

  try {
    const result = await makeRequest(GET_PHONE_NUMBERS, {
      method: 'GET',
      headers: { Authorization: await token() },
      params: queryParams,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const assignUnassignDirectExtensionForUser = async (
  data: IUpdateAssignUnassignDirectExtensionForUserRequest,
) => {
  const { id, transferChatsToAssignedUser, ...body } = data;

  try {
    const result = await makeRequest(GET_ASSIGN_UNASSIGN_DIRECT_EXTENSION_FOR_USER_URL(id), {
      method: 'PUT',
      headers: { Authorization: await token() },
      data: {
        ...body,
        transferChatsToAssignedUser: transferChatsToAssignedUser.toString(),
      },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const assignUnassignMaskingPhoneNumberForTenant = async (
  data: IPhoneNumbersAssignUnassignMaskingPhoneNumberForTenantRequest,
) => {
  const { id, ...body } = data;

  try {
    const result = await makeRequest(GET_ASSIGN_UNASSIGN_MASKING_PHONE_NUMBER_FOR_TENANT_URL(id), {
      method: 'PATCH',
      headers: { Authorization: await token() },
      data: body,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const setDefaultPhoneNumberForTenant = async (
  data: IPhoneNumbersSetDefaultForTenantRequest,
) => {
  const { id } = data;

  try {
    const result = await makeRequest(GET_URL_SET_DEFAULT_PHONE_NUMBER_FOR_TENANT_URL(id), {
      method: 'PATCH',
      headers: { Authorization: await token() },
      data: {},
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getTenantPhoneNumbersList = async () => {
  return await makeRequest(GET_TENANT_PHONE_NUMBERS_LIST, {
    method: 'GET',
    headers: { Authorization: await token() },
  });
};

import { Box, Button, LinearProgress, Stack, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';

import {
  IGetPhoneNumbersParams,
  IUpdateAssignUnassignDirectExtensionForUserRequest,
  IPhoneNumbersDirectExtensionResult,
} from '@/interfaces/phone-numbers';
import { IUserResult } from '@/interfaces/users/users';

import useTemplateDialog from '@/hooks/use-template-dialog';

import DirectExtensionsList from './Direct-extensions-list';
import { TemplateDialog } from '@/components/Dialogs/Template-dialog';

import * as phoneNumbersThunk from '@/store/thunk/phone-numbers';

import { PHONE_NUMBER_TYPE } from '@/constants/phone-numbers';

const DirectExtensionsLayout = () => {
  const dispatch = useAppDispatch();
  const { tenant } = useAppSelector((state) => state.tenant);

  const [isLoading, setIsLoading] = useState(false);
  const [directExtensions, setDirectExtensions] = useState<IPhoneNumbersDirectExtensionResult[]>(
    [],
  );
  const [params, setParams] = useState<IGetPhoneNumbersParams<IPhoneNumbersDirectExtensionResult>>({
    type: PHONE_NUMBER_TYPE.DIRECT_EXTENSION,
    search: '',
    sortField: undefined,
    sortDirection: undefined,
    skip: 0,
    limit: 50,
  });

  const [dialogState, dialogStateActions] = useTemplateDialog({
    isOpened: false,
    title: 'Tranfer messages',
    textContent: 'Do you wish to transfer all chats/messages of selected Direct extension to user?',
    dialogProps: { maxWidth: 'md' },
    submitButtonProps: { children: 'Yes, transfer all messages' },
    closeButtonProps: {
      color: 'primary',
      children: "No, don't transfer any messages",
    },
  });

  const setParamsPartially = (
    newState: Partial<IGetPhoneNumbersParams<IPhoneNumbersDirectExtensionResult>>,
  ) => {
    setParams((prevState) => ({ ...prevState, ...newState }));
  };

  const loadDirectExtensions = async () => {
    setIsLoading(true);

    const { payload } = await dispatch(phoneNumbersThunk.fetchGetPhoneNumbers(params));

    if (payload) {
      setDirectExtensions((prevState) => {
        if (!params.skip) {
          return payload;
        }
        return [...prevState, ...payload];
      });
    }

    setTimeout(() => setIsLoading(false), 1000);
  };

  const handleUserChange = async (
    user: IUserResult | null,
    directExtension: IPhoneNumbersDirectExtensionResult,
  ) => {
    if (!user) {
      await updateUser({
        id: directExtension.id,
        userRef: null,
        transferChatsToAssignedUser: false,
      });

      return;
    }

    if (dialogState.isOpened || directExtension.userRef === user.id) {
      return;
    }

    let transferChatsToAssignedUser = true;

    if (directExtension.previousUserRef) {
      const response = await dialogStateActions.openDialog();

      if (response === null) {
        return;
      } else {
        transferChatsToAssignedUser = response;
      }
    }

    await updateUser({
      id: directExtension.id,
      userRef: user.id,
      transferChatsToAssignedUser,
    });
  };

  const updateUser = async (body: IUpdateAssignUnassignDirectExtensionForUserRequest) => {
    setIsLoading(true);

    const { payload } = await dispatch(
      phoneNumbersThunk.fetchAssignUnassignDirectExtensionForUser(body),
    );

    if (payload) {
      setDirectExtensions((prevState) =>
        prevState.map((el) =>
          el.id === body.id
            ? {
                ...el,
                previousUserRef: el.userRef,
                userRef: payload.userRef,
              }
            : el,
        ),
      );
    }

    setTimeout(() => setIsLoading(false), 1000);
  };

  // tenant can be null while screen is loading
  useEffect(() => {
    if (tenant?.id) {
      setParams((prevState) => ({ ...prevState, tenantId: tenant.id }));
    }
  }, [tenant?.id]);

  useEffect(() => {
    loadDirectExtensions();
  }, [params.search, params.sortField, params.sortDirection, params.skip, params.limit]);

  const noMoreRecordsAvailable = directExtensions.length < params.limit + params.skip;

  return (
    <Stack spacing={2}>
      <TemplateDialog {...dialogState} />

      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" px={2}>
        <TextField
          sx={{ maxWidth: '20rem' }}
          type="search"
          label="Search"
          variant="outlined"
          size="small"
          value={params.search}
          onChange={(e) => setParamsPartially({ search: e.target.value, skip: 0 })}
          onBlur={(e) => setParamsPartially({ search: e.target.value, skip: 0 })}
        />
      </Stack>

      <Box height={2}>
        {isLoading && <LinearProgress variant="indeterminate" color="primary" />}
      </Box>

      <DirectExtensionsList
        isLoading={isLoading}
        params={params}
        setParams={setParamsPartially}
        directExtensions={directExtensions}
        handleUserChange={handleUserChange}
      />

      <Button
        variant="text"
        disabled={directExtensions.length < params.limit + params.skip}
        onClick={() =>
          setParams((prevState) => ({ ...prevState, skip: prevState.skip + prevState.limit }))
        }
      >
        {noMoreRecordsAvailable ? 'No more records available' : 'Load more'}
      </Button>
    </Stack>
  );
};

export default DirectExtensionsLayout;

enum TYPES {
  SMS = 'sms',
  VOICE = 'voice',
}

enum SMS_FILE_LIMITS {
  MAX_FILES_COUNT = 10,
  MAX_FILE_SIZE = 20971520, //20 MB
}

enum VOICE_FILE_LIMITS {
  MAX_FILES_COUNT = 1,
  MAX_FILE_SIZE = 20971520, //20 MB
}

enum MIME_TYPES {
  MPEG = 'audio/mpeg',
  WAV = 'audio/wav',
  WAVE = 'audio/wave',
  X_WAV = 'audio/x-wav',
  AIFF = 'audio/aiff',
  X_AIFC = 'audio/x-aifc',
  X_AIFF = 'audio/x-aiff',
  X_GSM = 'audio/x-gsm',
  GSM = 'audio/gsm',
  ULAW = 'audio/ulaw',
}

export default {
  TYPES,
  MIME_TYPES,
  VOICE_FILE_LIMITS,
  SMS_FILE_LIMITS,
};

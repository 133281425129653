import * as authReducer from '@/store/reducers/auth';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as tenantReducer from '@/store/reducers/tenant';
import * as userReducer from '@/store/reducers/user';
import * as twilioReducer from '@/store/reducers/twilio';

import ERRORS from '@/constants/errors';

const handleError = async (
  statusCode: number,
  errorMessage: string = 'Some error occurred. Please try again.',
) => {
  const { store } = await import('@/store/store');

  if (statusCode === 401) {
    localStorage.clear();

    const { twilio } = store.getState();
    if (twilio?.device?.state === 'registered') {
      twilio.device.unregister();
    }

    store.dispatch(authReducer.reset());
    store.dispatch(twilioReducer.reset());
    store.dispatch(tenantReducer.reset());
    store.dispatch(userReducer.reset());
    store.dispatch(snackbarReducer.showError(errorMessage ?? ERRORS.GENERAL.UNAUTHORIZED));
  } else {
    store.dispatch(snackbarReducer.showError(errorMessage));
  }
};

export default handleError;

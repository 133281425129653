import { useEffect, useRef } from 'react';

import { IUserMeResult } from '@/interfaces/users/users';
import { ICallsTranscriptionResult } from '@/interfaces/voice/call-transcription';
import { ICallConferenceParticipantResult } from '@/interfaces/voice/call';

import * as voiceReducer from '@/store/reducers/dashboard/voice';
import * as userReducer from '@/store/reducers/user';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { endCall } from '@/services/twilio/end-call';
import * as socketService from '@/services/socketio/socketio';
import * as callService from '@/services/voice/call';
import * as twilioEndCallService from '@/services/twilio/end-call';
import * as twilioHandleEndRingingCallService from '@/services/twilio/handle-end-ringing';

import SOCKETIO from '@/constants/socketio';
import PROVIDERS from '@/constants/providers';
import CALL from '@/constants/voice/voice';

function VoiceSocketListeners() {
  const dispatch = useAppDispatch();
  const activeProviderRef = useRef<null | { name: string }>(null);

  const { connections } = useAppSelector((state) => state.voice);
  const { isSocketConnected } = useAppSelector((state) => state.socket);
  const { activeProvider } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.user);
  const connectionsRef = useRef(connections);
  const userRef = useRef<IUserMeResult | null>(null);

  const handleRejectIncomingCall = (uuid: string, userId: string): void => {
    if (userRef?.current?.id === userId) {
      if (activeProviderRef.current?.name === PROVIDERS.TWILIO) {
        endCall({ dispatch, uuid });
      }
    }
  };

  const handleCallAnswered = async (uuid: string) => {
    const connection = connectionsRef.current.find((connection) => connection.uuid === uuid);
    if (connection?.direction === 'outbound') {
      callService.startCallDurationCounter({ dispatch, uuid });
    }
    dispatch(voiceReducer.setAnswered(uuid));
    dispatch(voiceReducer.setDialerActionBtn('call'));
  };

  const handleCallOnHoldCanceled = async (uuid: string) => {
    if (activeProviderRef.current?.name === PROVIDERS.TWILIO) {
      twilioEndCallService.endCall({ dispatch, uuid });
    }
  };

  const handleRingingCallCancel = async (uuid: string, userId: string) => {
    if (activeProviderRef.current?.name === PROVIDERS.TWILIO) {
      twilioHandleEndRingingCallService.handleEndRinging({ dispatch, uuid, userId });
    }
  };

  const handleInternalCallOnHoldCanceled = async (uuid: string) => {
    if (activeProviderRef.current?.name === PROVIDERS.TWILIO) {
      twilioEndCallService.endCall({ dispatch, uuid });
    }
  };

  const handleInternalCallHoldActions = async (
    uuid: string,
    isOnHold = true,
    needResume = true,
  ) => {
    dispatch(voiceReducer.setInternalHoldByOther({ uuid, isOnHold }));
    dispatch(voiceReducer.setIsOnHoldDisabled({ uuid: uuid, value: isOnHold }));

    if (needResume) {
      setTimeout(
        () => dispatch(voiceReducer.setIsOnHoldDisabled({ uuid: uuid, value: false })),
        4000,
      );
    }
  };

  const handleLiveCallTranscription = async (
    uuid: string,
    transcription: ICallsTranscriptionResult['transcriptions'][0],
  ) => {
    dispatch(voiceReducer.setLiveCallTranscription({ uuid, transcription }));
  };

  const handleLiveCallTranscriptionStatus = async (
    uuid: string,
    status: CALL.TRANSCRIPTION_STATUS,
  ) => {
    dispatch(voiceReducer.setLiveCallTranscriptionStatus({ uuid, status }));
  };

  const handleConferenceIsMultipleParticipants = async (
    uuid: string,
    isMultipleParticipants: boolean,
  ) => {
    dispatch(voiceReducer.setIsMultipleParticipants({ uuid, value: isMultipleParticipants }));
  };

  const handleConferenceParticipants = async (
    uuid: string,
    participants: ICallConferenceParticipantResult[],
  ) => {
    if (
      userRef.current &&
      !participants.find((element) => element.userRef === userRef.current?.id)
    ) {
      dispatch(voiceReducer.removeConnection(uuid));
    }
    dispatch(voiceReducer.setParticipants({ uuid, value: participants }));
  };

  useEffect(() => {
    connectionsRef.current = connections;
  }, [connections]);

  useEffect(() => {
    activeProviderRef.current = activeProvider;
  }, [activeProvider]);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    if (!socketService.socket) {
      return;
    }
    socketService.socket.on(SOCKETIO.EVENTS.REJECT_INCOMING_CALL, (json: string) => {
      const data = JSON.parse(json);
      handleRejectIncomingCall(data.uuid, data.userId);
    });
    socketService.socket.on(SOCKETIO.EVENTS.CALL_ANSWERED, (json: string) => {
      const data = JSON.parse(json);
      handleCallAnswered(data.uuid);
    });
    socketService.socket.on(SOCKETIO.EVENTS.CALL_ON_HOLD_CANCELED, (json: string) => {
      const data = JSON.parse(json);
      handleCallOnHoldCanceled(data.uuid);
    });
    socketService.socket.on(SOCKETIO.EVENTS.RINGING_CALL_CANCELED, (json: string) => {
      const data = JSON.parse(json);
      handleRingingCallCancel(data.uuid, data.userId);
    });
    socketService.socket.on(SOCKETIO.EVENTS.INTERNAL_CALL_CANCELED, (json: string) => {
      const data = JSON.parse(json);

      handleInternalCallOnHoldCanceled(data.uuid);
    });
    socketService.socket.on(SOCKETIO.EVENTS.INTERNAL_CALL_IS_ON_HOLD, (json: string) => {
      const data = JSON.parse(json);
      handleInternalCallHoldActions(data.uuid, true, false);
    });
    socketService.socket.on(SOCKETIO.EVENTS.INTERNAL_CALL_IS_RESUMED, (json: string) => {
      const data = JSON.parse(json);
      handleInternalCallHoldActions(data.uuid, false, false);
    });
    socketService.socket.on(SOCKETIO.EVENTS.LIVE_CALL_TRANSCRIPTION, (json: string) => {
      const data = JSON.parse(json);
      handleLiveCallTranscription(data.uuid, data.transcription);
    });
    socketService.socket.on(SOCKETIO.EVENTS.LIVE_CALL_TRANSCRIPTION_STATUS, (json: string) => {
      const data = JSON.parse(json);
      handleLiveCallTranscriptionStatus(data.uuid, data.status);
    });
    socketService.socket.on(SOCKETIO.EVENTS.CONFERENCE_IS_MULTIPLE_PARTICIPANTS, (json: string) => {
      const data = JSON.parse(json);
      handleConferenceIsMultipleParticipants(data.uuid, data.isMultipleParticipants);
    });
    socketService.socket.on(SOCKETIO.EVENTS.CONFERENCE_PARTICIPANTS, (json: string) => {
      const data = JSON.parse(json);
      handleConferenceParticipants(data.uuid, data.participants);
    });
    socketService.socket.on(SOCKETIO.EVENTS.MISSED_CALL, (json) => {
      const data = JSON.parse(json);
      dispatch(userReducer.setUserMissedCalls(data));
    });

    return function cleanup() {
      if (!socketService.socket) {
        return;
      }
      socketService.socket.removeListener(SOCKETIO.EVENTS.CALL_ANSWERED);
      socketService.socket.removeListener(SOCKETIO.EVENTS.CALL_ON_HOLD_CANCELED);
      socketService.socket.removeListener(SOCKETIO.EVENTS.INTERNAL_CALL_CANCELED);
      socketService.socket.removeListener(SOCKETIO.EVENTS.INTERNAL_CALL_IS_ON_HOLD);
      socketService.socket.removeListener(SOCKETIO.EVENTS.INTERNAL_CALL_IS_RESUMED);
      socketService.socket.removeListener(SOCKETIO.EVENTS.RINGING_CALL_CANCELED);
      socketService.socket.removeListener(SOCKETIO.EVENTS.LIVE_CALL_TRANSCRIPTION);
      socketService.socket.removeListener(SOCKETIO.EVENTS.LIVE_CALL_TRANSCRIPTION_STATUS);
      socketService.socket.removeListener(SOCKETIO.EVENTS.CONFERENCE_PARTICIPANTS);
      socketService.socket.removeListener(SOCKETIO.EVENTS.CONFERENCE_IS_MULTIPLE_PARTICIPANTS);
      socketService.socket.removeListener(SOCKETIO.EVENTS.REJECT_INCOMING_CALL);
      socketService.socket.removeListener(SOCKETIO.EVENTS.MISSED_CALL);
    };
  }, [socketService.socket, isSocketConnected]);

  return <></>;
}
export default VoiceSocketListeners;

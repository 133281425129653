import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@/store/hooks';

import { IContactsGroupRecord, IGetContactsGroupsQueryParams } from '@/interfaces/contacts-group';
import { IPartialResult } from '@/interfaces/querying-data';

import { Stack } from '@mui/material';

import * as menuReducer from '@/store/reducers/dashboard/menu';

import contactsGroupsThunk from '@/store/thunk/dashboard/contacts-groups';

import DataTemplate from '@/components/Data-template/Data-template';
import ContactsGroupsHeader from '@/pages/dashboard/contacts/contacts-groups/Contacts-groups-header';
import ContactsGroupsList from '@/pages/dashboard/contacts/contacts-groups/Contacts-groups-list';

import DASHBOARD from '@/constants/dashboard';
import ROUTES from '@/constants/routes';

const ContactGroupsLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<IGetContactsGroupsQueryParams>({
    search: '',
    skip: 0,
    limit: 50,
  });

  const [contactsGroupsResult, setContactsGroupsResult] = useState<
    IPartialResult<IContactsGroupRecord>
  >({
    maxCount: 0,
    records: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleLoad = async () => {
    setIsLoading(true);

    const { payload } = await dispatch(contactsGroupsThunk.fetchGetByParams(queryParams));

    if (payload) {
      setContactsGroupsResult(payload as IPartialResult<IContactsGroupRecord>);
    }

    setIsLoading(false);
  };

  const handleDelete = async (contactsGroup: IContactsGroupRecord) => {
    setIsLoading(true);

    const { meta } = await dispatch(contactsGroupsThunk.fetchDeleteById({ id: contactsGroup.id }));

    if (meta.requestStatus === 'fulfilled') {
      handleLoad();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.CONTACTS }));
    const intervalId = setInterval(() => handleLoad(), 60_000); // 1 minute
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      handleLoad();
    }
  }, [queryParams.search, queryParams.skip, queryParams.limit]);

  return (
    <Stack spacing={2} pb={1}>
      <ContactsGroupsHeader
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        handleCreate={() => navigate(ROUTES.DASHBOARD_CONTACTS_GROUPS_CREATE_OR_UPDATE)}
      />

      <DataTemplate
        isLoading={isLoading}
        pagination={{
          maxCount: contactsGroupsResult.maxCount,
          paginationData: {
            skip: queryParams.skip,
            limit: queryParams.limit,
          },
          onPageChange: (paginationData) =>
            setQueryParams((prevState) => ({
              ...prevState,
              ...paginationData,
            })),
          onRowsPerPageChange: (paginationData) =>
            setQueryParams((prevState) => ({
              ...prevState,
              ...paginationData,
            })),
        }}
      >
        <ContactsGroupsList
          data={contactsGroupsResult.records}
          handleUpdate={(contactsGroup) =>
            navigate(ROUTES.DASHBOARD_CONTACTS_GROUPS_CREATE_OR_UPDATE, {
              state: { contactsGroup },
            })
          }
          handleDelete={handleDelete}
        />
      </DataTemplate>
    </Stack>
  );
};

export default ContactGroupsLayout;

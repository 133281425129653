import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IChatCreateRequest,
  IGetOneChatByRelativeInfo,
  IGetChatsQueryParams,
  IChatResult,
  IChatUpdateMarksRequest,
} from '@/interfaces/chat';

import handleErrors from '@/services/handle-errors';

import * as chatsApi from '@/services/api/v1/chat';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import * as SNACKBAR from '@/constants/snackbar';

export const fetchGetChats = createAsyncThunk(
  'chats/fetchGetChats',
  async (data: IGetChatsQueryParams, thunkAPI) => {
    const response = await chatsApi.getByParams(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchOnLoadMoreChats = createAsyncThunk(
  'chats/fetchOnLoadMoreChats',
  async (data: IGetChatsQueryParams, thunkAPI) => {
    const response = await chatsApi.getByParams(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchCreate = createAsyncThunk(
  'chats/fetchCreate',
  async (data: IChatCreateRequest, thunkAPI) => {
    const response = await chatsApi.createChat(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchUpdateMarks = createAsyncThunk(
  'chats/fetchUpdateMarks',
  async ({ id, ...body }: Pick<IChatResult, 'id'> & IChatUpdateMarksRequest, thunkAPI) => {
    const response = await chatsApi.updateMarks(id, body);

    if (!response.success) {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.FAILED_TO('Update marks')),
      );

      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchGetOneByRelativeInfo = createAsyncThunk(
  'chats/fetchGetOneByRelativeInfo',
  async (data: IGetOneChatByRelativeInfo, thunkAPI) => {
    const response = await chatsApi.getOneByRelativeInfo(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchSearchChats = createAsyncThunk(
  'chats/fetchSearchChats',
  async (data: Pick<IGetChatsQueryParams, 'variant'> & { search: string }, thunkAPI) => {
    const response = await chatsApi.search(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

import * as phoneNumberHelper from '@/helpers/phone-number';

import { IPhoneNumbersResult } from '@/interfaces/phone-numbers';

import * as voiceReducer from '@/store/reducers/dashboard/voice';
import * as voiceThunk from '@/store/thunk/dashboard/voice';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import { AppDispatch, store } from '@/store/store';

import { getSelectedConnection } from '../voice/call';

import ERRORS from '@/constants/errors';
import { PHONE_NUMBER_TYPE } from '@/constants/phone-numbers';

export const forwardCall = async (data: {
  dispatch: AppDispatch;
  to: string;
  phoneNumbers: IPhoneNumbersResult[];
}) => {
  const { dispatch, phoneNumbers } = data;

  let { to } = data;

  if (!Number.isNaN(to) && +to >= 100 && +to <= 9999) {
    const state = store.getState();
    const { user } = state.user;

    if (user?.companyExtension === to) {
      dispatch(snackbarReducer.showInfo(ERRORS.VOICE.CALL_TO_OWN_COMPANY_EXT));
      return;
    }

    const { payload } = await dispatch(voiceThunk.fetchUserByCompanyExtension(to));

    if (!payload) {
      dispatch(snackbarReducer.showInfo(ERRORS.VOICE.INVALID_PHONE_NUMBER));
      return;
    }
  } else {
    const { isValid, validatedPhoneNumber } = phoneNumberHelper.validate(to);

    if (!isValid) {
      dispatch(snackbarReducer.showInfo(ERRORS.VOICE.INVALID_PHONE_NUMBER));
      return;
    }

    if (
      phoneNumbers.some(
        (element) =>
          element.phoneNumber === validatedPhoneNumber &&
          element.type !== PHONE_NUMBER_TYPE.CALLER_ID,
      )
    ) {
      dispatch(
        snackbarReducer.showError(
          `It looks like you're trying to reach out your own company phone number. For internal calls, please use the company extension instead.`,
        ),
      );
      return;
    }

    to = validatedPhoneNumber;
  }

  const connection = getSelectedConnection();

  if (connection) {
    dispatch(voiceReducer.setIsForwarding({ uuid: connection.uuid, value: true }));

    const result = await dispatch(voiceThunk.fetchForwardCall({ to, uuid: connection.uuid }));

    if (result.meta.requestStatus === 'rejected') {
      dispatch(voiceReducer.setIsForwarding({ uuid: connection.uuid, value: false }));
      return;
    }

    dispatch(voiceReducer.removeConnection(connection.uuid));

    dispatch(voiceReducer.setDialerActionBtn('call'));
    dispatch(voiceReducer.setDialerSearchedUser(undefined));
  }
};

import { Outlet, useLocation } from 'react-router-dom';

import { Box, Paper } from '@mui/material';

import NavTab from '@/components/Tabs-navigation/Tabs-navigation';

import ROUTES from '@/constants/routes';

const SettingsLayout = () => {
  const location = useLocation();

  const links = [
    { label: 'Voice Setup', path: ROUTES.DASHBOARD_SETTINGS_VOICE_SETUP },
    { label: 'Call Flow', path: ROUTES.DASHBOARD_SETTINGS_CALL_FLOW },
    { label: 'General', path: ROUTES.DASHBOARD_SETTINGS_GENERAL },
    { label: 'Country Codes', path: ROUTES.DASHBOARD_SETTINGS_COUNTRY_CODES },
  ];

  const isGeneralSettingsPageOpened = location.pathname.includes(ROUTES.DASHBOARD_SETTINGS_GENERAL);

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {isGeneralSettingsPageOpened ? (
        <Box display="flex" flexDirection="column" flex={1} gap={2}>
          <Box p={2} pb={0}>
            <Paper>
              <NavTab links={links} />
            </Paper>
          </Box>

          <Outlet />
        </Box>
      ) : (
        <Box m={2} component={Paper}>
          <NavTab links={links} />

          <Box flex={1}>
            <Outlet />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SettingsLayout;

import { FunctionComponent, Ref, useEffect, useRef, useState } from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';

import { ICountryCodesResult } from '@/interfaces/country-code';

import { validate } from '@/helpers/phone-number';

import { useAppSelector } from '@/store/hooks';
import CountryCode from '../Country-code/Country-code';

type CountryCodesListType = 'full-list' | 'user-list' | 'tenant-list' | 'only-default';

const DEF_COUNTRY_CODE = {
  country: 'United States of America',
  extension: '+1',
  isDefault: true,
  iso: 'US',
  id: '',
};
interface IPhoneNumberInputProps extends Omit<TextFieldProps, 'type' | 'onChange' | 'onBlur'> {
  value: string;
  onPhoneNumberChange: (value: string | null) => void;
  onPhoneNumberBlur?: (value: string) => void;
  onPhoneNumberCountryCodeChange?: (value: string) => void;
  countryCodesListType: CountryCodesListType;
  disabled?: boolean;
  returnNullOnInvalid?: boolean;
  skipValidationOnEmpty?: boolean;
}

const PhoneNumberInput: FunctionComponent<IPhoneNumberInputProps> = ({
  label,
  value,
  onPhoneNumberChange,
  onPhoneNumberBlur,
  onPhoneNumberCountryCodeChange,
  countryCodesListType,
  disabled: isDisabled = false,
  returnNullOnInvalid = false,
  skipValidationOnEmpty = false,
  ...textFieldProps
}): JSX.Element => {
  const { list } = useAppSelector((state) => state.countryCodes);

  const [selectedCountryCode, setSelectedCountryCode] =
    useState<ICountryCodesResult>(DEF_COUNTRY_CODE);

  const [extractedNumber, setExtractedNumber] = useState<string>('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(true);

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleCountryCodeSelect = (countryCode: ICountryCodesResult): void => {
    setSelectedCountryCode(countryCode);

    const { isValid } = validate(countryCode.extension + extractedNumber);
    setIsPhoneNumberValid(isValid);

    if (returnNullOnInvalid && !isValid) {
      onPhoneNumberChange(null);
      return;
    }
    onPhoneNumberChange(countryCode.extension + extractedNumber);
  };

  const handleInputChange = (data: string): void => {
    if (!selectedCountryCode) {
      return;
    }
    const { isValid } = validate(selectedCountryCode.extension + data);
    setIsPhoneNumberValid(isValid);

    setExtractedNumber(data);

    if (returnNullOnInvalid && !isValid) {
      onPhoneNumberChange(null);
      return;
    }

    onPhoneNumberChange(selectedCountryCode.extension + data.trim());
  };

  const extractNumber = (data: string, list: ICountryCodesResult[]): void => {
    const { countryCode, validatedPhoneNumber, isValid } = validate(data);

    if (!isValid) {
      setIsPhoneNumberValid(false);
      return;
    }

    const inputPhoneNumberCountryCode = list.find((element) => element.extension === countryCode);
    setSelectedCountryCode(inputPhoneNumberCountryCode as ICountryCodesResult);

    setExtractedNumber(validatedPhoneNumber.replace(countryCode, ''));
  };

  useEffect(() => {
    if (value && !extractedNumber) {
      extractNumber(value, list);
    }
  }, [value]);

  useEffect(() => {
    if (!extractedNumber && skipValidationOnEmpty) {
      setIsPhoneNumberValid(true);
    }
  }, [extractedNumber]);

  return (
    <FormControl
      fullWidth={textFieldProps.fullWidth}
      size={textFieldProps.size}
      variant={textFieldProps.variant}
      focused={isFocused}
    >
      <FormLabel disabled={isDisabled} focused={isFocused}>
        {label}
      </FormLabel>

      <Box display="flex" alignItems="flex-end" gap={0.5}>
        <CountryCode
          disabled={isDisabled}
          countryCodesListType={countryCodesListType}
          handleChange={(data) => {
            handleCountryCodeSelect(data as unknown as ICountryCodesResult);
          }}
          formControlProps={{
            variant: textFieldProps.variant,
            size: textFieldProps.size,
            error: !isPhoneNumberValid,
            sx: {
              minWidth: '5rem',
            },
            onFocus: () => setIsFocused(true),
            onBlur: () => setIsFocused(false),
          }}
        />

        <TextField
          {...textFieldProps}
          disabled={isDisabled}
          type="tel"
          value={extractedNumber}
          error={!isPhoneNumberValid}
          onFocus={(event) => {
            setIsFocused(true);

            if (textFieldProps.onFocus) {
              textFieldProps.onFocus(event);
            }
          }}
          onBlur={(event) => {
            setIsFocused(false);
          }}
          onChange={(event) => {
            handleInputChange(event.target.value);
          }}
        />
      </Box>

      <FormHelperText sx={{ color: !isPhoneNumberValid ? 'error.main' : 'initial' }}>
        {!isPhoneNumberValid ? 'Phone Number is invalid' : undefined}
      </FormHelperText>
    </FormControl>
  );
};

export default PhoneNumberInput;

import {
  IGetContactsQueryParams,
  IGetContactByPhoneNumberAndTypeRequest,
  IContact,
} from '@/interfaces/contact';
import { IPaginationParams } from '@/interfaces/querying-data';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { IThunkExtra } from '@/store/store';

import handleErrors from '@/services/handle-errors';

import * as contactApi from '@/services/api/v1/contact';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import * as SNACKBAR from '@/constants/snackbar';
import CHAT from '@/constants/chat';
import { CONTACT_TYPE } from '@/constants/contacts';

export const fetchGetContacts = createAsyncThunk(
  'contacts/fetchGetContacts',
  async (data: IGetContactsQueryParams, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.contactsApis.getByParams(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchGetImportHistory = createAsyncThunk(
  'contacts/fetchGetImportHistory',
  async (data: IPaginationParams, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.contactsApis.getImportHistory(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchBulkDelete = createAsyncThunk(
  'contacts/fetchBulkDelete',
  async (data: { ids: string[] }, thunkAPI) => {
    const response = await contactApi.bulkDelete(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.DELETED('Contacts')));
      return response.data;
    }
  },
);

export const fetchGetById = createAsyncThunk(
  'contacts/fetchGetById',
  async (data: Pick<Required<IContact>, 'id'>, thunkAPI) => {
    const response = await contactApi.getById(data.id);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchDeleteById = createAsyncThunk(
  'contacts/fetchDeleteById',
  async (data: { id: string }, thunkAPI) => {
    const response = await contactApi.deleteById(data.id);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.DELETED('Contact')));
      return response.data;
    }
  },
);

export const fetchCreate = createAsyncThunk(
  'contacts/fetchCreate',
  async (data: FormData, thunkAPI) => {
    const response = await contactApi.create(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.CREATED('Contact')));
      return response.data;
    }
  },
);

export const fetchCopy = createAsyncThunk(
  'contacts/fetchCopy',
  async (data: FormData, thunkAPI) => {
    const response = await contactApi.create(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.CONTACT.COPIED));
      return response.data;
    }
  },
);

export const fetchEdit = createAsyncThunk(
  'contacts/fetchEdit',
  async (data: { id: string; formData: FormData }, thunkAPI) => {
    const response = await contactApi.edit(data.id, data.formData);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(snackbarReducer.showSuccess(SNACKBAR.MESSAGES.GENERAL.UPDATED('Contact')));
      return response.data;
    }
  },
);

export const fetchUpload = createAsyncThunk(
  'contacts/fetchUpload',
  async (data: FormData, thunkAPI) => {
    const response = await contactApi.upload(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      thunkAPI.dispatch(
        snackbarReducer.showSuccess(SNACKBAR.MESSAGES.CONTACT.UPLOADED_SUCCESSFULLY),
      );
      return response.data;
    }
  },
);

export const fetchByPhoneNumberAndType = createAsyncThunk(
  'contacts/getByPhoneNumberAndType',
  async (data: IGetContactByPhoneNumberAndTypeRequest, thunkAPI) => {
    const response = await contactApi.getByPhoneNumberAndType(data);
    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const fetchDownloadContactsFileSample = createAsyncThunk(
  'contacts/fetchDownloadContactsFileSample',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    return v1Apis.filesApis.downloadFileSample('media_contacts-upload.xlsx');
  },
);

export const fetchGetPrivateOrCompanyContact = createAsyncThunk(
  'contacts/fetchGetPrivateOrCompanyContact',
  async ({ chatType, phoneNumber }: { chatType: CHAT.TYPE; phoneNumber: string }, thunkAPI) => {
    let response: any;

    if (chatType === CHAT.TYPE.DIRECT) {
      response = await contactApi.getByPhoneNumberAndType({
        phoneNumber,
        type: CONTACT_TYPE.PRIVATE,
      });

      if (!response.data) {
        response = await contactApi.getByPhoneNumberAndType({
          phoneNumber,
          type: CONTACT_TYPE.COMPANY,
        });
      }
    } else {
      response = await contactApi.getByPhoneNumberAndType({
        phoneNumber,
        type: CONTACT_TYPE.COMPANY,
      });
    }

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

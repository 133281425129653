namespace VOICE_SETTINGS {
  export enum ON_NO_ACTION_TYPE {
    COMPANY_EXTENSION = 'company_extension',
    EXTENSIONS_GROUP = 'extensions_group',
  }

  export const GATHER_INPUT_OPTIONS = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
}

export default VOICE_SETTINGS;

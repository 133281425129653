import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  Box,
  LinearProgress,
  TextField,
  Typography,
  Button,
} from '@mui/material';

import * as twilioAddParticipantService from '@/services/twilio/add-participant';

import * as voiceThunk from '../../../thunk';
import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import { IUserResult } from '@/interfaces/users/users';
import { IContactResult } from '@/interfaces/contact';

import CustomAvatar from '@/components/Custom-avatar/Avatar';
import PhoneNumberInput from '@/components/Phone-number-input/Phone-number-input';
import Transition from '@/components/Dialogs/Transition';

import apisV1 from '@/services/api/v1/index';

import PROVIDERS from '@/constants/providers';

const AddParticipantDialog = (props: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  const { show, setShow } = props;
  const dispatch = useAppDispatch();

  const { activeProvider } = useAppSelector((state) => state.tenant);
  const [users, setUsers] = useState<IUserResult[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUserResult>();
  const [contacts, setContacts] = useState<IContactResult[]>([]);
  const [selectedContact, setSelectedContact] = useState<IContactResult>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  const handleClose = () => {
    setShow(false);
  };

  const getInitialData = async (): Promise<void> => {
    const contactsResult = await dispatch(voiceThunk.fetchContacts({ limit: 1000 }));
    if (contactsResult.payload) {
      setContacts(contactsResult.payload.records);
    }

    const { success, data } = await apisV1.usersApis.getByParams({
      limit: 1000,
      skip: 0,
      excludeMe: true,
    });
    if (success) {
      setUsers(data.records);
    }
  };

  const handleSelectUser = (value: IUserResult | undefined): void => {
    setPhoneNumber('');
    setSelectedContact(undefined);
    setSelectedUser(value?.id ? value : undefined);
  };

  const handleSelectContact = (value: IContactResult | undefined): void => {
    setPhoneNumber('');
    setSelectedUser(undefined);
    setSelectedContact(value?.id ? value : undefined);
  };

  const addParticipant = async (): Promise<void> => {
    if (activeProvider?.name === PROVIDERS.TWILIO) {
      setIsLoading(true);

      const isAdding = await twilioAddParticipantService.addParticipant({
        dispatch,
        phoneNumber: phoneNumber ?? '',
        contactId: selectedContact?.id,
        userId: selectedUser?.id,
      });

      if (isAdding) {
        dispatch(snackbarReducer.showInfo('Adding new participant...'));
      }

      setShow(false);

      setPhoneNumber('');
      setSelectedContact(undefined);
      setSelectedUser(undefined);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      getInitialData();
    }
  }, [show]);

  return (
    <Dialog
      open={show}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      onClose={handleClose}
      keepMounted={false}
    >
      <Box height={5}>{isLoading && <LinearProgress />}</Box>

      <DialogTitle>Add participant</DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          You can add participant by phone number or select from contacts or users list.
        </DialogContentText>

        <PhoneNumberInput
          fullWidth
          returnNullOnInvalid
          skipValidationOnEmpty
          value={phoneNumber ?? ''}
          countryCodesListType={'user-list'}
          onPhoneNumberChange={(value) => setPhoneNumber(value)}
        />

        <Box mt={1}>
          <Autocomplete
            disabled={!!selectedUser || !!phoneNumber}
            options={contacts}
            value={selectedContact}
            isOptionEqualToValue={(options, value) => options.id === value?.id}
            getOptionLabel={(option) => option.fullName}
            onChange={(event, list, reason, detail) => {
              handleSelectContact(detail?.option);
            }}
            renderOption={(props, option) => {
              return (
                <Box
                  key={option.id}
                  component="li"
                  display={'flex'}
                  alignItems={'center'}
                  px={1}
                  {...props}
                >
                  <CustomAvatar
                    url={option.avatar?.url}
                    showLiveStatus={false}
                    sx={{ height: 20, width: 20 }}
                  />

                  <Box>
                    <Typography fontSize="small" ml={1}>
                      {option.fullName}
                    </Typography>

                    <Typography fontSize="small" color={'grey'} ml={1}>
                      {option.phoneNumber}
                    </Typography>
                  </Box>
                </Box>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Contacts" />}
          />
        </Box>

        <Box mt={1}>
          <Autocomplete
            disabled={!!selectedContact || !!phoneNumber}
            options={users}
            value={selectedUser}
            isOptionEqualToValue={(options, value) => options.id === value?.id}
            getOptionLabel={(option) => option.fullName ?? ''}
            onChange={(event, list, reason, detail) => {
              handleSelectUser(detail?.option);
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                display={'flex'}
                alignItems={'center'}
                px={1}
                key={option.id}
                {...props}
              >
                <CustomAvatar
                  url={option.avatar?.url}
                  showLiveStatus={true}
                  sx={{ height: 20, width: 20 }}
                  lastActivityAt={option.lastActivityAt}
                />

                <Box>
                  <Typography fontSize="small" ml={1}>
                    {option.fullName}
                  </Typography>

                  <Typography fontSize="small" color={'grey'} ml={1}>
                    {option.companyExtension}
                  </Typography>
                </Box>
              </Box>
            )}
            renderInput={(params) => <TextField {...params} fullWidth label="Users" />}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button color="primary" variant="contained" disabled={isLoading} onClick={handleClose}>
          Close
        </Button>

        <Button
          color="primary"
          variant="contained"
          disabled={isLoading || (!phoneNumber && !selectedContact && !selectedUser)}
          onClick={addParticipant}
        >
          Add to conference
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddParticipantDialog;

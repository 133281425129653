import { useState } from 'react';

import { ITemplateDialogProps } from '@/components/Dialogs/Template-dialog';

const VOID_FUNCTION = () => {};

const getInitialValue: <SubmitResponse>() => ITemplateDialogProps<SubmitResponse> = () => ({
  title: '',
  isOpened: false,
  handleClose: VOID_FUNCTION,
});

interface IUseTemplateDialogConfig<SubmitResponse = true, ExtraProps = {}>
  extends Omit<ITemplateDialogProps<SubmitResponse>, 'handleClose' | 'returnValueOnSubmit'> {
  extraProps?: ExtraProps;
}

interface ITemplateDialogActions<SubmitResponse = true, ExtraProps = {}> {
  openDialog: (
    extraProps?: IUseTemplateDialogConfig<SubmitResponse, ExtraProps>['extraProps'],
  ) => Promise<SubmitResponse | false | null>;
  reset: VoidFunction;
  setProps: (value: Partial<IUseTemplateDialogConfig<SubmitResponse, ExtraProps>>) => void;
}

export interface IUseTemplateDialogProps<SubmitResponse, ExtraProps = {}>
  extends IUseTemplateDialogConfig<SubmitResponse, ExtraProps>,
    ITemplateDialogProps<SubmitResponse> {}

function useTemplateDialog<SubmitResponse = true, ExtraProps = {}>(
  props?: IUseTemplateDialogConfig<SubmitResponse, ExtraProps>,
): [
  IUseTemplateDialogConfig<SubmitResponse, ExtraProps> & ITemplateDialogProps<SubmitResponse>,
  ITemplateDialogActions<SubmitResponse, ExtraProps>,
] {
  const defaultValue = props
    ? { ...props, handleClose: VOID_FUNCTION }
    : getInitialValue<SubmitResponse>();

  const [state, setState] = useState<
    IUseTemplateDialogConfig<SubmitResponse, ExtraProps> & ITemplateDialogProps<SubmitResponse>
  >(defaultValue);

  const openDialog: ITemplateDialogActions<SubmitResponse, ExtraProps>['openDialog'] = (
    extraProps,
  ) =>
    new Promise((resolve) => {
      setState((prevState) => ({
        ...prevState,
        isOpened: true,
        extraProps: {
          ...(prevState.extraProps ?? ({} as ExtraProps)),
          ...(extraProps ?? ({} as ExtraProps)),
        },
        handleClose: (value: SubmitResponse | false | null) => {
          setState((prevState) => ({ ...prevState, isOpened: false }));
          resolve(value);
        },
      }));
    });

  const reset = (): void => {
    setState(defaultValue);
  };

  const setProps = (state: Partial<IUseTemplateDialogConfig<SubmitResponse, ExtraProps>>) => {
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  return [state, { openDialog, reset, setProps }];
}

export default useTemplateDialog;

import { PropsWithChildren, ReactElement } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import useAccess from '@/hooks/use-access';

import { FEATURES } from '@/constants/features';

interface IFeaturesGuardProps extends Required<PropsWithChildren> {
  features: FEATURES[];
  // either defaultOnNoAccessRedirectTo or onNoAccessRedirectTo must be provided
  defaultOnNoAccessRedirectTo: string;
  onNoAccessRedirects?: Record<FEATURES, string>;
}

const FeaturesGuard = ({
  onNoAccessRedirects,
  defaultOnNoAccessRedirectTo,
  features,
  children,
}: IFeaturesGuardProps) => {
  const { isFeatureAvailable } = useAccess();
  const location = useLocation();

  const unavaiableFeature = features.find((el) => !isFeatureAvailable(el));

  if (unavaiableFeature) {
    const redirectTo = onNoAccessRedirects?.[unavaiableFeature] ?? defaultOnNoAccessRedirectTo;

    if (!location.pathname.includes(redirectTo)) {
      return <Navigate replace to={redirectTo} />;
    }
  }

  return children as ReactElement;
};

export default FeaturesGuard;

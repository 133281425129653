import { Box } from '@mui/material';

import CallPreferences from './Call-preferences';

const MePreferencesLayout = () => {
  return (
    <Box display="flex" flexWrap="wrap" gap={2} p={2}>
      <Box minWidth="24rem">
        <CallPreferences />
      </Box>
    </Box>
  );
};

export default MePreferencesLayout;

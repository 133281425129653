import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import { ListItemIcon, Stack } from '@mui/material';

import { IContact } from '@/interfaces/contact';

import * as styles from './styles';

import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';

import * as bmcThunk from '@/pages/dashboard/broadcast/campaign/thunk';
import * as bmcReducer from '@/pages/dashboard/broadcast/campaign/reducer';

interface ISelectReceiversFromContactsProps {
  isContactsFileValidating: boolean;
}

const SelectReceiversFromContacts = ({
  isContactsFileValidating,
}: ISelectReceiversFromContactsProps) => {
  const dispatch = useAppDispatch();

  const {
    contacts,
    selectedContacts,
    minLimit,
    contactsMaxCount,
    selectedContactsCount,
    contactsFile,
  } = useAppSelector((state) => state.broadcastCampaign);

  const formik = useFormik({
    initialValues: { search: '' },
    onSubmit: () => {},
  });

  const getContactsInitial = async () => {
    if (!contacts.length) {
      await dispatch(bmcThunk.fetchContactsInitial(50));
    }
  };

  const handleLoadMore = async () => {
    if (contacts.length !== contactsMaxCount) {
      const limit = contacts.length + minLimit;
      const skip = contacts.length;
      await dispatch(bmcThunk.fetchOnLoadMore({ limit, skip }));
    }
  };

  const handleToggle = (id: string) => {
    dispatch(bmcReducer.selectedContact(id));
  };

  const handleSelectAll = () => {
    dispatch(
      bmcReducer.setSelectAllContacts(contacts.map((contact: IContact) => contact.id as string)),
    );
  };

  const searchContacts = async (search: string) => {
    formik.setFieldValue('search', search, false);
    if (search) {
      await dispatch(bmcThunk.fetchSearchContact(search));
    } else if (!search && !contactsMaxCount) {
      await dispatch(bmcThunk.fetchContactsInitial(50));
    }
  };

  useEffect(() => {
    getContactsInitial();
  }, []);

  return (
    <Stack spacing={2} mt={2}>
      {contactsFile || isContactsFileValidating ? null : (
        <Stack spacing={1} direction="row">
          <TextField
            sx={{ flex: 1 }}
            size="small"
            id="outlined-basic"
            label="Search contact"
            variant="outlined"
            value={formik.values.search}
            onChange={(event) => {
              searchContacts(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      formik.setFieldValue('search', '');
                      searchContacts('');
                    }}
                  >
                    <CloseIcon color="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            disabled={!!contactsFile || isContactsFileValidating}
            color="primary"
            variant="contained"
            component="label"
            onClick={handleSelectAll}
          >
            Select all
          </Button>
        </Stack>
      )}

      {contactsFile ? null : <Typography>Selected {selectedContactsCount} contacts</Typography>}

      {contactsFile ? null : (
        <List dense disablePadding sx={styles.listSX}>
          {contacts.map((contact: IContact, index: number) => {
            const labelId = `checkbox-list-secondary-label-${contact.id}`;

            return (
              <ListItem
                disablePadding
                disableGutters
                sx={styles.listItemSX}
                key={contact.id}
                onClick={() => handleToggle(contact.id as string)}
              >
                <ListItemButton disabled={!!contactsFile || isContactsFileValidating}>
                  <ListItemIcon>
                    <Checkbox
                      edge="end"
                      disabled={!!contactsFile || isContactsFileValidating}
                      checked={selectedContacts.indexOf(contact.id as string) !== -1}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>

                  <ListItemAvatar>
                    <Avatar src={contact.avatar?.url || DefaultAvatar} />
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={`${contact.firstName} ${contact.lastName ?? ''}`}
                    secondary={contact.phoneNumber}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}

          {contactsMaxCount && contacts.length < contactsMaxCount ? (
            <ListItemButton onClick={handleLoadMore} sx={styles.loadMoreSX}>
              <ExpandMoreIcon fontSize="medium" />
            </ListItemButton>
          ) : null}
        </List>
      )}
    </Stack>
  );
};

export default SelectReceiversFromContacts;

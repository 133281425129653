import { useEffect, useState } from 'react';
import {
  Avatar,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material';

import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';

import useUserLiveStatus from '@/hooks/user-live-status';

import USERS from '@/constants/users';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'black',
    '&::before': {
      backgroundColor: 'black',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    color: 'primary',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const CustomAvatar = (props: {
  url: string | undefined | null;
  lastActivityAt?: Date | string | undefined | null;
  showLiveStatus?: boolean;
  sx?: SxProps<Theme> | undefined;
}) => {
  const { url, lastActivityAt = undefined, sx = {}, showLiveStatus = true } = props;

  const { getStatus } = useUserLiveStatus();

  const [className, setClassName] = useState<string>('');

  const handleStatusColor = (): string => {
    if (!lastActivityAt) {
      return 'red';
    }

    const status = getStatus(lastActivityAt);

    return status === USERS.LIVE_STATUSES.ACTIVE ? 'green' : '#f6c004';
  };

  useEffect(() => {
    //Animation test, will use it for voice call menu
    //Do not forget to comment it!!!
    setTimeout(() => {
      setClassName('wheel');
      setTimeout(() => setClassName(''), 5000);
    }, 1.8e6);
  }, []);

  return (
    <HtmlTooltip title={showLiveStatus ? getStatus(lastActivityAt).toUpperCase() : ''} arrow>
      <Avatar
        className={className}
        sx={{
          height: 35,
          width: 35,
          border: showLiveStatus
            ? {
                outline: `2px solid ${handleStatusColor()}`,
                border: '2px solid black',
              }
            : {},
          ...sx,
        }}
        src={url ?? DefaultAvatar}
      />
    </HtmlTooltip>
  );
};

export default CustomAvatar;

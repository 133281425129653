import '../../../node_modules/flag-icons/css/flag-icons.min.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import Routes from './routes/Routes';

import Snackbar from './components/snackbars/Snackbar';
import TwilioDevice from './components/Voice/Twilio-device';
import VoiceSocketListeners from './components/Voice/Socket-listeners';
import NewIncomingCall from './components/New-incoming-call/New-incoming-call';
import FeaturesAccessDialog from './components/Features-access-dialog/Dialog';
import Backdrop from './components/Backdrop/Backdrop';
import CheckSubsDialog from './components/Dialogs/Check-subs-dialog';
import BrowserNotifications from './components/Browser-notifications/Browser-notifications';
import ClickToCallUrlListener from './components/Click-to-call/Click-to-call-url-listener/Click-to-call-url-listener';
import LowBalanceDialog from './components/Dialogs/Low-balance-dialog';

import { theme } from '@/theme';

function App(): JSX.Element {
  return (
    <BrowserRouter window={window}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URL,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          prompt: 'login',
        }}
      >
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <Snackbar />
            <Backdrop />
            <NewIncomingCall />
            <BrowserNotifications />
            <VoiceSocketListeners />
            <TwilioDevice />
            <FeaturesAccessDialog />
            <CheckSubsDialog />
            <ClickToCallUrlListener />
            <Routes />
            <LowBalanceDialog />
          </LocalizationProvider>
        </ThemeProvider>
      </Auth0Provider>
    </BrowserRouter>
  );
}

export default App;

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import CallIcon from '@mui/icons-material/Call';

import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';

import { IContact } from '@/interfaces/contact';

import * as voiceThunk from '../../thunk';
import * as voiceReducer from '@/store/reducers/dashboard/voice';

import * as callService from '@/services/voice/call';

import ROUTES from '@/constants/routes';
import { CONTACT_TYPE } from '@/constants/contacts';
import { Button } from '@mui/material';

interface IContactsProps {
  search: string | undefined;
  isLoading: boolean;
  contactType: CONTACT_TYPE;
  setContactType: Dispatch<SetStateAction<CONTACT_TYPE>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  onCallPlacement?: VoidFunction;
}

const Contacts = (props: IContactsProps) => {
  const { search, isLoading, setIsLoading } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { connections } = useAppSelector((state) => state.voice);

  const [contacts, setContacts] = useState<IContact[]>([]);
  const [maxCount, setMaxCount] = useState<number>(0);

  const getInitialContacts = async (): Promise<void> => {
    setIsLoading(true);

    const { payload } = await dispatch(
      voiceThunk.fetchContacts({ limit: 20, contactType: props.contactType }),
    );

    if (payload) {
      setContacts(payload.records);
      setMaxCount(payload.maxCount);
    }

    setIsLoading(false);
  };

  const handleLoadMore = async (): Promise<void> => {
    setIsLoading(true);

    const { payload } = await dispatch(
      voiceThunk.fetchOnLoadMore({
        limit: 20,
        skip: contacts.length,
        contactType: props.contactType,
      }),
    );

    if (payload) {
      setContacts((current) => [...current, ...payload.records]);
      setMaxCount(payload.maxCount);
    }

    setIsLoading(false);
  };

  const handleStartCall = (data: IContact): void => {
    props.onCallPlacement?.();

    callService.handleInitCall({ dispatch, to: data.phoneNumber });
    navigate(ROUTES.DASHBOARD_VOICE_STATUS);
  };

  const isCallBtnDisabled = (data: IContact): boolean => {
    const isAnswered = connections.some(
      (connection) => !connection.answered && connection.direction === 'outbound',
    );

    const isInConnections = connections.some(
      (connection) =>
        connection.caller?.phoneNumber === data.phoneNumber ||
        connection.callee?.phoneNumber === data.phoneNumber,
    );

    return isAnswered || isInConnections;
  };

  const searchContacts = async (search: string | undefined): Promise<void> => {
    if (typeof search === 'undefined') {
      return;
    }

    dispatch(voiceReducer.setIsSearchingContact(true));

    if (!search) {
      await getInitialContacts();
      return;
    }

    const { payload } = await dispatch(
      voiceThunk.fetchSearchContact({ search, contactType: props.contactType }),
    );

    if (payload) {
      setContacts(payload.records);
      setMaxCount(0);
    }
  };

  useEffect(() => {
    searchContacts(search);
  }, [search, props.contactType]);

  return (
    <List dense sx={{ widht: '100%', bgcolor: 'background.paper' }}>
      {contacts.map((contact: IContact) => {
        return (
          <Box key={contact.id} display={'flex'} flexDirection={'column'}>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={contact?.avatar?.url ? contact?.avatar?.url : DefaultAvatar} />
              </ListItemAvatar>
              <ListItemText
                style={{ cursor: 'pointer' }}
                primary={
                  <Typography fontSize={14} sx={{ wordBreak: 'break-word' }}>
                    {contact.fullName}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      display={'inline'}
                      sx={{ color: 'grey' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {contact.phoneNumber}
                    </Typography>
                  </React.Fragment>
                }
              />
              <IconButton
                color="primary"
                disabled={isCallBtnDisabled(contact)}
                onClick={() => handleStartCall(contact)}
              >
                <CallIcon fontSize="small" />
              </IconButton>
            </ListItem>

            <Divider component="li" />
          </Box>
        );
      })}
      {contacts.length < maxCount && (
        <Button fullWidth color="secondary" onClick={handleLoadMore}>
          {isLoading ? (
            <CircularProgress color="inherit" size="5%" />
          ) : (
            <ExpandMoreIcon fontSize="medium" />
          )}
        </Button>
      )}
    </List>
  );
};

export default Contacts;

export const Box1SX = {
  borderRadius: 5,
};

export const Box2SX = {
  borderRadius: 5,
  minHeight: 300,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const Box3SX = {
  width: '95%',
};

export const Box4SX = {
  display: 'flex',
  alignItems: 'center',
  width: 100,
  justifyContent: 'flex-end',
};

export const List1SX = {
  width: '100%',
  bgcolor: 'background.paper',
  maxHeight: 'calc(100vh - 250px)',
  overflow: 'auto',
};

export const ListItemText1SX = {
  display: {
    xs: 'none',
    sm: 'flex',
  },
  width: 150,
  textAlign: 'start',
};

export const Typography1SX = {
  fontSize: '0.875rem',
  color: 'grey',
};

export const CircularProgress1SX = {
  position: 'absolute',
  top: 5,
  right: -5,
  zIndex: 2,
};

export const Icon1SX = {
  mr: 1,
};

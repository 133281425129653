import * as yup from 'yup';

import * as phoneNumberHelper from '@/helpers/phone-number';

import REGEX from '@/constants/regex';

const SignUpSchema = yup.object().shape({
  firstName: yup.string().min(1).max(50).required('First name is required'),
  lastName: yup.string().min(1).max(50).required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .min(12, 'Min password length is 12')
    .max(30, 'Max password length is 30')
    .matches(
      REGEX.PASSWORD,
      'Password should contain at least one capital letter one number and one special character',
    )
    .required('Password is required'),
  confirmPassword: yup
    .string() //@ts-ignore
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
  companyName: yup.string().min(1).max(100).required('Company name is required'),
  timeZone: yup.string().required('Time zone is required'),
  // Company contact
  companyContactFirstName: yup
    .string()
    .min(1)
    .max(50)
    .required('Company contact first name is required'),
  companyContactLastName: yup
    .string()
    .min(1)
    .max(50)
    .required('Company contact last name is required'),
  companyContactEmail: yup
    .string()
    .email('Invalid email')
    .required('Company contacts: Email is required'),
  companyContactPhoneNumber: yup
    .string()
    .test('validate phone number', function (value) {
      if (value === undefined) {
        return this.createError({
          path: this.path,
          message: 'Invalid phone number',
        });
      }

      const { isValid } = phoneNumberHelper.validate(value);
      if (!isValid) {
        return this.createError({
          path: this.path,
          message: 'Invalid phone number',
        });
      } else {
        return true;
      }
    })
    .required('Company contact phone number is required.'),
  // Billing admin
  companyBillingAdminFirstName: yup
    .string()
    .min(1)
    .max(50)
    .required('First name of billing admin is required'),
  companyBillingAdminLastName: yup
    .string()
    .min(1)
    .max(50)
    .required('Last name of billing admin is required'),
  companyBillingAdminEmail: yup.string().email('Invalid email').required(),
  companyBillingAdminPhoneNumber: yup
    .string()
    .test('validate phone number', function (value) {
      if (value === undefined) {
        return this.createError({
          path: this.path,
          message: 'Phone phone number is required',
        });
      }

      const { isValid } = phoneNumberHelper.validate(value);
      if (!isValid) {
        return this.createError({
          path: this.path,
          message: 'Invalid phone number',
        });
      } else {
        return true;
      }
    })
    .required('Billing administrator phone number is required.'),
  // Company address
  companyAddressCountry: yup
    .string()
    .test('Check entered country', function (value) {
      if (['CA', 'US', 'IN', 'MX'].includes(value as string)) {
        return true;
      }

      return this.createError({
        path: this.path,
        message: 'Allowed countries are: Canada, USA, India, Mexico',
      });
    })
    .required('Company address: Country is required'),
  companyAddressCity: yup.string().required('Company address: City is required'),
  companyAddressAddress1: yup.string().required('Company address: Address1 is required'),
  companyAddressAddress2: yup.string().optional(),
  companyAddressProvince: yup.string().required('Company address: Province/State is required'),
  companyAddressPostcode: yup.string().required('Company address: Postal code/ZIP is required'),
});

export default SignUpSchema;

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Outlet, useLocation } from 'react-router-dom';

import { Fab, LinearProgress, Box, Paper } from '@mui/material';
import { Call as CallIcon } from '@mui/icons-material';

import * as menuReducer from '@/store/reducers/dashboard/menu';

import ContactsAndExtensions from './contacts-extensions/Contacts-extensions';
import MicrophoneDialog from './dialogs/microphone/Dialog';

import useThemeBreakpoints from '@/hooks/use-theme-breakpoints';
import useTemplateDialog from '@/hooks/use-template-dialog';

import NavTab from '@/components/Tabs-navigation/Tabs-navigation';
import { TemplateDialog } from '@/components/Dialogs/Template-dialog';

import DASHBOARD from '@/constants/dashboard';
import ROUTES from '@/constants/routes';

const links = [
  { label: 'Call status', path: ROUTES.DASHBOARD_VOICE_STATUS },
  { label: 'Call history', path: ROUTES.DASHBOARD_VOICE_HISTORY },
  { label: 'Voicemails', path: ROUTES.DASHBOARD_VOICE_CALLS_VOICEMAILS },
];

const VoiceLayout = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const isMdOrUp = useThemeBreakpoints('up', 'lg');

  const { isShowCallStatusLoader } = useAppSelector((state) => state.voice);

  const [viewContactsDialogState, viewContactsDialogActions] = useTemplateDialog({
    isOpened: false,
    title: 'Contacts',
    showCancelButton: false,
    showCloseButton: false,
    submitButtonProps: { children: 'Close' },
  });

  useEffect(() => {
    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.VOICE }));
  }, []);

  const isCallStatusPageOpened = location.pathname.includes(ROUTES.DASHBOARD_VOICE_STATUS);

  return (
    <>
      <MicrophoneDialog />

      <Box display="flex" flex={1} gap={1} p={2} position="relative">
        {isMdOrUp ? (
          <Box display="flex" flex={1} maxWidth={320}>
            <ContactsAndExtensions disableElevation={false} />
          </Box>
        ) : (
          <>
            <Fab
              color="primary"
              variant="extended"
              size="small"
              sx={{
                position: 'absolute',
                zIndex: (theme) => theme.zIndex.snackbar - 1,
                bottom: 3,
                left: 3,
              }}
              onClick={() => viewContactsDialogActions.openDialog()}
            >
              <CallIcon sx={{ mr: 1 }} />
              View contacts
            </Fab>

            <TemplateDialog
              {...viewContactsDialogState}
              getChildren={({ handleClose }) => (
                <Box sx={{ minHeight: '50vh', overflow: 'auto' }}>
                  <ContactsAndExtensions
                    disableElevation
                    onCallPlacement={() => handleClose(null)}
                  />
                </Box>
              )}
            />
          </>
        )}

        {isCallStatusPageOpened ? (
          <Box display="flex" flexDirection="column" flex={1} gap={1}>
            <Box component={Paper} position={'relative'}>
              <Box position={'absolute'} width={'100%'}>
                {isShowCallStatusLoader && <LinearProgress sx={{ borderRadius: 10 }} />}
              </Box>

              <NavTab links={links} />
            </Box>

            <Outlet />
          </Box>
        ) : (
          <Box component={Paper} display="flex" flexDirection="column" flex={1} gap={1}>
            <Box position={'absolute'} width={'100%'}>
              {isShowCallStatusLoader && <LinearProgress sx={{ borderRadius: 10 }} />}
            </Box>
            <NavTab links={links} />

            <Outlet />
          </Box>
        )}
      </Box>
    </>
  );
};

export default VoiceLayout;

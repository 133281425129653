export const messagePaperSX = {
  pl: 1,
  pr: 1,
  pt: 1,
  mr: 1,
  display: 'flex',
};

export const messageBox1SX = {
  display: 'flex',
  flexDirection: 'column',
};

export const messageBox2SX = {
  mb: 0.5,
  fontSize: 14,
  wordBreak: 'break-word',
};

export const rightMessageBox3SX = {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
};

export const container = {
  display: 'flex',
};

export const checkIcon1SX = {
  width: '1rem',
  ml: 2,
};

export const paperSx = {
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
};

export const inputBoxSX = {
  mt: 1,
  '& .MuiTextField-root': { mb: 1 },
  display: 'flex',
  justifyContent: 'center',
};

export const imagePreviewBoxSX = {
  zIndex: 1,
  mt: 10,
  display: 'flex',
  justifyContent: 'center',
};

export const imagePreview = {
  background: 'none',
};

import * as yup from 'yup';

const updateContactValidationSchema = yup.object().shape({
  firstName: yup.string().min(1).max(50).required('First name is required'),
  lastName: yup.string().nullable().optional(),
  email: yup.string().email('Email is invalid').nullable().optional(),
  phoneNumber: yup.string().required('Phone number is required'),
});

export default updateContactValidationSchema;

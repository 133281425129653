import React from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import enableMicrophoneInstruction from '@/assets/images/instructions/enable_microphone_instruction.png';

import * as styles from './styles';

import * as voiceReducer from '@/store/reducers/dashboard/voice';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = () => {
  const dispatch = useAppDispatch();

  const { showEnableMicrophoneDialog } = useAppSelector((state) => state.voice);

  const handleClose = () => {
    dispatch(voiceReducer.setShowEnableMicrophoneDialog(false));
  };

  return (
    <Dialog
      open={showEnableMicrophoneDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={styles.dialogTitle1SX}>Microphone</DialogTitle>
      <DialogContent sx={styles.dialogContent1SX}>
        <DialogContentText sx={styles.dialogContentText1SX}>
          It seems your microphone isn't quite ready. Let's fix that! Head over to your browser
          settings, find the microphone settings, and make sure it's turned on. If you don't have a
          microphone connected, plug one in and try again.
        </DialogContentText>
        <img src={enableMicrophoneInstruction} style={styles.imgStyle} />
      </DialogContent>
      <DialogActions sx={styles.dialogAction1SX}>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialogSlide;

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Box, CircularProgress, Typography } from '@mui/material';

import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

import { showWarning } from '@/store/reducers/snackbar/snackbar';

import { useAppDispatch } from '@/store/hooks';

import * as styles from '@/components/Stripe/styles';

import ERRORS from '@/constants/errors';

function CheckoutForm(props: {
  setIsPaymentProcessed: Dispatch<SetStateAction<boolean>>;
  buttonText?: string;
  handlePaymentSuccessful?: VoidFunction;
}) {
  const {
    setIsPaymentProcessed,
    buttonText = 'Checkout',
    handlePaymentSuccessful = () => {},
  } = props;
  const dispatch = useAppDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const [progress, setProgress] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMakePaymentBtnDisabled, setIsMakePaymentBtnDisabled] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsPaymentProcessed(true);
    setIsMakePaymentBtnDisabled(true);

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: process.env.REACT_APP_STRIPE_REDIRECT as string,
      },
    });

    if (result.error) {
      console.error(result.error);
      dispatch(showWarning(result.error.message ?? ERRORS.PAYMENTS.PAYMENT_ERROR));
      setIsPaymentProcessed(false);
    } else {
      setIsMakePaymentBtnDisabled(true);
      setShowSuccess(true);
      handlePaymentSuccessful();
    }
    setIsMakePaymentBtnDisabled(false);
  };

  const isButtonDisabled = (): boolean => {
    return !stripe || isMakePaymentBtnDisabled;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress >= 100 ? 0 : prevProgress + 10;
        if (newProgress === 100) {
          setTimeout(() => setIsLoading(false), 100);
        }
        return newProgress;
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Box sx={styles.Box1SX}>
          <Box sx={styles.Box2SX}>
            <CircularProgress color="primary" size={90} variant="indeterminate" />
            <Box sx={styles.Box3SX}>
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                fontSize={20}
              >{`${Math.round(progress)}%`}</Typography>
            </Box>
          </Box>
        </Box>
      ) : showSuccess ? (
        <Box>
          <DoneAllIcon color="success" sx={styles.Icon1SX} />
          <Typography sx={styles.Typography1SX}>Your payment was successful!</Typography>
        </Box>
      ) : (
        <Box m={2}>
          <form onSubmit={handleSubmit}>
            <PaymentElement />
            <Button
              disabled={isButtonDisabled()}
              variant="contained"
              sx={styles.Button1SX}
              type="submit"
            >
              {buttonText}
            </Button>
          </form>
        </Box>
      )}
    </>
  );
}

export default CheckoutForm;

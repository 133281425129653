import { useAppSelector } from '@/store/hooks';
import { Stack, Typography } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function SimpleBackdrop() {
  const { show } = useAppSelector((state) => state.backdrop);

  return (
    <Backdrop
      sx={{ bgcolor: 'background.default', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
      transitionDuration={0}
    >
      <Stack spacing={1} alignItems="center">
        <CircularProgress color="primary" size={100} />
        <Typography>Loading...</Typography>
      </Stack>
    </Backdrop>
  );
}

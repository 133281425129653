import * as voiceThunk from '@/store/thunk/dashboard/voice';

import * as voiceReducer from '@/store/reducers/dashboard/voice';
import { AppDispatch, store } from '@/store/store';

import { getActiveConnection } from '../voice/call';

import CALL from '@/constants/voice/voice';

export const handleDisconnect = async (data: { dispatch: AppDispatch; uuid?: string }) => {
  const state = store.getState();
  const { dispatch } = data;
  let uuid = data.uuid;

  const { voice: voiceState, twilio: twilioState } = state;
  const { connections } = voiceState;
  const { device } = twilioState;

  dispatch(voiceReducer.setIsShowCallStatusLoader(false));

  if (!uuid) {
    const activeNowConnection = getActiveConnection();
    if (activeNowConnection) {
      uuid = activeNowConnection.uuid;
    } else {
      return;
    }
  }

  const connection = connections.find((conn) => conn.uuid === uuid);
  if (!connection || connection.isForwarding) {
    return;
  }

  if (connection.connectionType === CALL.CONNECTION_TYPES.INTERNAL && connection.answered) {
    return;
  }

  dispatch(voiceReducer.setIsShowCallStatusLoader(false));

  if (connection.answered) {
    await dispatch(voiceThunk.fetchCancelCall(uuid));
  }
  clearInterval(connection.counterFunction);
  connection.conn.disconnect();
  connection.conn.reject();

  if (connections.length === 1) {
    device?.audio?.incoming(true);
  }

  dispatch(voiceReducer.setDialerActionBtn('call'));
  dispatch(voiceReducer.setCallToContact(null));
  dispatch(voiceReducer.removeConnection(uuid));
};

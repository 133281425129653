import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '@/store/hooks';
import { PropsWithChildren, ReactElement } from 'react';

import ROUTES from '@/constants/routes';
import TENANT from '@/constants/tenant';

interface ITenantGuardProps extends Required<PropsWithChildren> {}

const TenantGuard = ({ children }: ITenantGuardProps) => {
  const location = useLocation();
  const tenant = useAppSelector((state) => state.tenant.tenant);

  if (!tenant && !location.pathname.includes(ROUTES.SIGN_IN)) {
    return <Navigate replace to={ROUTES.SIGN_IN} />;
  }

  if (
    tenant?.status === TENANT.STATUSES.PENDING_APPROVAL &&
    !location.pathname.includes(ROUTES.KYC)
  ) {
    return <Navigate replace to={ROUTES.KYC} />;
  }

  if (
    tenant?.status === TENANT.STATUSES.REGISTERED &&
    !location.pathname.includes(ROUTES.SUBSCRIPTION_SETUP)
  ) {
    return <Navigate replace to={ROUTES.SUBSCRIPTION_SETUP} />;
  }

  return children as ReactElement;
};

export default TenantGuard;

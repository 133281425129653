import { createAsyncThunk } from '@reduxjs/toolkit';

import handleErrors from '@/services/handle-errors';

import {
  IUsersCreateSaAllowedIpRecordRequest,
  IUsersDeleteSaAllowedIpRecordRequest,
  IUsersUpdateSaAllowedIpRecordRequest,
} from '@/interfaces/users/users';

import { IThunkExtra } from '@/store/store';

export const fetchGetAll = createAsyncThunk(
  'profileSuperAdmin/fetchGetAll',
  async (data, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.ipsApis.getAll();

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const updateSaAllowedIp = createAsyncThunk(
  'profileSuperAdmin/updateSaAllowedIp',
  async (data: { id: string; updates: IUsersUpdateSaAllowedIpRecordRequest }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.updateSaAllowedIp(data.id, data.updates);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const createSaAllowedIp = createAsyncThunk(
  'profileSuperAdmin/createSaAllowedIp',
  async (data: IUsersCreateSaAllowedIpRecordRequest, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.createSaAllowedIp(data);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

export const deleteSaAllowedIp = createAsyncThunk(
  'profileSuperAdmin/deleteSaAllowedIp',
  async (data: { id: string; updates: IUsersDeleteSaAllowedIpRecordRequest }, thunkAPI) => {
    const { v1Apis } = thunkAPI.extra as IThunkExtra;
    const response = await v1Apis.usersApis.deleteSaAllowedIp(data.id, data.updates);

    if (!response.success) {
      handleErrors(thunkAPI, response.statusCode, response);
      thunkAPI.abort();
    } else {
      return response.data;
    }
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IBackdropSlice {
  show: boolean;
}

const initialState: IBackdropSlice = {
  show: false,
};

export const backdropSlice = createSlice({
  name: 'backdrop',
  initialState,
  reducers: {
    setShow: (state, action: PayloadAction<boolean | undefined>) => {
      state.show = action.payload === undefined ? !state.show : action.payload;
    },
  },
});

export const { setShow } = backdropSlice.actions;

export default backdropSlice.reducer;

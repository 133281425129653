import * as yup from 'yup';
import REGEX from '@/constants/regex';

const ChangePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(12, 'Min password length is 12')
    .max(30, 'Max password length is 30')
    .matches(
      REGEX.PASSWORD,
      'Password should contain at least one capital letter one number and one special character',
    )
    .required('Password is required'),
  confirmPassword: yup
    .string() //@ts-ignore
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
});

export default ChangePasswordSchema;

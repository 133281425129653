import * as datefnsTZ from 'date-fns-tz';

import DATE from '@/constants/date';

export const clientTomeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatTZ = (
  date: Date | string = new Date(),
  timezone = 'UTC',
  format = 'yyyy-MM-dd HH:mm:ss',
) => datefnsTZ.formatInTimeZone(date, timezone, format);

export const zonedTimeToUtcAndFormatAsISOString = (
  date: Date | string = new Date(),
  timezone = 'UTC',
) => datefnsTZ.fromZonedTime(date, timezone).toISOString();

export const utcToZoned = (date: Date | string = new Date(), timezone = 'UTC') =>
  datefnsTZ.toZonedTime(date, timezone);

export const getUTCDateNameByDate = (date: Date) => {
  //@ts-ignore
  return DATE.DAYS.SHORT_NAME[datefnsTZ.formatInTimeZone(date, 'UTC', 'EEEE').toUpperCase()];
};

export const zonedTimeToUtc = (date: Date | string = new Date(), timezone = 'UTC') =>
  datefnsTZ.fromZonedTime(date, timezone);

export const getTimeUTC = (date: Date) => datefnsTZ.formatInTimeZone(date, 'UTC', 'HH:mm');

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Box,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Slide,
  IconButton,
  DialogContent,
  Tooltip,
  LinearProgress,
  Paper,
} from '@mui/material';
import { TransitionProps } from 'notistack';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import * as dateHelper from '@/helpers/date/date';

import * as socketService from '@/services/socketio/socketio';

import CustomAvatar from '@/components/Custom-avatar/Avatar';
import LinkNewAccountDialog from './Link-new-account';
import SwitchingAccountDialog from './Switching-account';

import { IUserAccountsResult } from '@/interfaces/users/users';
import { ITenantResult } from '@/interfaces/tenant';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';
import * as twilioReducer from '@/store/reducers/twilio';
import * as userReducer from '@/store/reducers/user';
import * as userThunk from '@/pages/dashboard/users/thunk';
import * as tenantThunk from '@/store/thunk/tenant';
import * as phoneNumbersThunk from '@/store/thunk/phone-numbers';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { getAndSaveToken } from '@/services/api/token';

import { PHONE_NUMBER_TYPE } from '@/constants/phone-numbers';
import { FEATURES } from '@/constants/features';
import PROVIDERS from '@/constants/providers';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AccountsDialog = (props: { show: boolean; setShow: Dispatch<SetStateAction<boolean>> }) => {
  const { setShow, show } = props;
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<IUserAccountsResult[]>([]);
  const [showLinkNewAccountDialog, setShowLinkNewAccountDialog] = useState<boolean>(false);
  const [showSwitchingAccountDialog, setShowSwitchingAccountDialog] = useState<boolean>(false);
  const { device } = useAppSelector((state) => state.twilio);

  const getAccounts = async (): Promise<void> => {
    setIsLoading(true);
    const { meta, payload } = await dispatch(userThunk.fetchAccounts());
    if (meta.requestStatus === 'fulfilled') {
      setAccounts(payload);
    }

    setIsLoading(false);
  };

  const handleSwitchAccount = async (userId: string): Promise<void> => {
    if (isLoading) {
      return;
    }
    setShowSwitchingAccountDialog(true);

    const { meta } = await dispatch(userThunk.fetchSwitchAccount({ userId }));
    if (meta.requestStatus === 'fulfilled') {
      if (device && device.state === 'registered') {
        await device.unregister();
        dispatch(twilioReducer.reset());
      }

      await getAndSaveToken();

      dispatch(userReducer.reset());

      const { meta: fetchTenantMeta, payload: fetchedTenant } = await dispatch(
        tenantThunk.fetchTenantInfo(),
      );
      await dispatch(userThunk.fetchGetUserInfo());
      await dispatch(
        phoneNumbersThunk.fetchGetPhoneNumbers({
          limit: 0,
          skip: 0,
          type: PHONE_NUMBER_TYPE.DIRECT_EXTENSION,
          sortDirection: -1,
          sortField: '_id',
        }),
      );

      socketService.disconnectSocket();
      socketService.connectSocket();

      if (fetchTenantMeta.requestStatus === 'fulfilled') {
        const activeProvider = fetchedTenant.providers.find(
          (element: ITenantResult['providers'][0]) => element.active,
        );

        // Handle Twilio Provider
        if (activeProvider?.name === PROVIDERS.TWILIO) {
          const hasVoice = fetchedTenant.features.some(
            (element: { name: string }) => element.name === FEATURES.VOICE,
          );

          if (hasVoice) {
            dispatch(twilioReducer.setCreateDevice({ initCreate: true, force: true }));
          }
        }
      }

      dispatch(snackbarReducer.showSuccess('Account was switched successfully.'));
    }
    setShowSwitchingAccountDialog(false);
    await getAccounts();
  };

  const handleUnlinkAccount = async (userId: string): Promise<void> => {
    setIsLoading(true);
    const { meta } = await dispatch(userThunk.fetchUnlinkAccount({ userId }));
    if (meta.requestStatus === 'fulfilled') {
      dispatch(snackbarReducer.showSuccess('Account was unlinked successfully.'));
      await getAndSaveToken();

      await getAccounts();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (show) {
      getAccounts();
    } else {
      setAccounts([]);
    }
  }, [show]);

  return (
    <Dialog
      TransitionComponent={Transition}
      open={show}
      keepMounted={false}
      onClose={() => setShow(false)}
    >
      <LinkNewAccountDialog
        show={showLinkNewAccountDialog}
        setShow={setShowLinkNewAccountDialog}
        getAccounts={getAccounts}
      />
      <SwitchingAccountDialog show={showSwitchingAccountDialog} />

      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" pr={1}>
        <DialogTitle>Accounts</DialogTitle>

        <Tooltip title="Link new account">
          <IconButton color="primary" onClick={() => setShowLinkNewAccountDialog(true)}>
            <PersonAddIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Box>

      <DialogContent sx={{ p: 0, minHeight: 100 }}>
        <Box minHeight={10}>
          {isLoading && (
            <LinearProgress variant="indeterminate" color="primary" sx={{ minWidth: 500 }} />
          )}
        </Box>

        {accounts.map((element, index) => {
          return (
            <ListItem key={index} sx={{ py: 0 }}>
              <ListItemAvatar>
                <CustomAvatar url={element.avatarUrl} showLiveStatus={false} />
              </ListItemAvatar>
              <ListItemText
                primary={element.fullName}
                secondary={
                  <>
                    <Typography component="span">{element.companyName}</Typography>
                    <Typography component="br" />
                    {element.switchedAt && (
                      <Typography component="span" fontSize={12}>
                        Since: {dateHelper.format(element.switchedAt, 'yyyy-MM-dd hh:mm a')}
                      </Typography>
                    )}
                  </>
                }
              />

              <Box>
                {element.selected && (
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={40}
                    height={15}
                    borderRadius={1}
                    bgcolor={'#65C466'}
                    component={Paper}
                    elevation={1}
                    mx={5}
                  >
                    <Typography fontSize={10} color={'white'}>
                      active
                    </Typography>
                  </Box>
                )}
              </Box>
              <Button
                disabled={element.selected || isLoading}
                onClick={() => handleSwitchAccount(element.userRef)}
                variant="text"
                color="info"
              >
                Switch
              </Button>
              <Button
                onClick={() => handleUnlinkAccount(element.userRef)}
                disabled={element.selected || isLoading || element.isTokenOwner}
                variant="text"
                color="error"
              >
                Unlink
              </Button>
            </ListItem>
          );
        })}
      </DialogContent>

      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setShow(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountsDialog;

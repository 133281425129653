import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { Box, Paper } from '@mui/material';

import * as userReducer from '@/store/reducers/user';

import NavTab from '@/components/Tabs-navigation/Tabs-navigation';
import PrivateLayoutHeader from '@/components/Private-layout/Private-layout-header';
import PrivateLayoutFooter from '@/components/Private-layout/Private-layout-footer';
import AccountsDialog from '@/components/Dialogs/Accounts/Accounts';

import ROUTES from '@/constants/routes';
import TENANT from '@/constants/tenant';
import { ROLES } from '@/constants/roles';

const ProfileLayout = () => {
  const dispatch = useAppDispatch();

  const { user, showAccountsDialog } = useAppSelector((state) => state.user);
  const { tenant } = useAppSelector((state) => state.tenant);

  const links = [
    {
      label: 'Profile',
      path: ROUTES.PROFILE_ME,
    },
    {
      label: 'Preferences',
      path: ROUTES.PROFILE_PREFERENCES,
    },
    {
      label: 'Change password',
      path: ROUTES.PROFILE_CHANGE_PASSWORD,
    },
    {
      label: 'Subscriptions',
      path: ROUTES.PROFILE_SUBSCRIPTIONS,
      disabled:
        ![ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(user?.role as ROLES) ||
        tenant?.status === TENANT.STATUSES.PENDING_APPROVAL ||
        tenant?.skipSubsCheck,
    },
    {
      label: 'Credit cards',
      path: ROUTES.PROFILE_CREDIT_CARDS,
      disabled:
        ![ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(user?.role as ROLES) ||
        tenant?.status === TENANT.STATUSES.PENDING_APPROVAL ||
        tenant?.skipSubsCheck,
    },
  ];

  if (user?.role === ROLES.SUPER_ADMIN) {
    links.push({
      label: 'Super Admin',
      path: ROUTES.PROFILE_SUPER_ADMIN,
    });
  }

  return (
    <>
      <AccountsDialog
        show={showAccountsDialog}
        setShow={() => dispatch(userReducer.closeAccountsDialog())}
      />

      <Box display="flex" justifyContent="space-between" flexDirection="column" minHeight="100vh">
        <PrivateLayoutHeader showSideMenu={false} />

        <Box
          flex={1}
          sx={{
            // 128px because it is height of AppBar and Footer components combined
            height: 'calc(100vh - 128px)',
            overflow: 'auto',
          }}
        >
          <Box overflow="auto">
            <Paper sx={{ borderRadius: 0 }}>
              <NavTab links={links} />
            </Paper>

            <Box display="flex" flex={1}>
              <Outlet />
            </Box>
          </Box>
        </Box>

        <PrivateLayoutFooter />
      </Box>
    </>
  );
};

export default ProfileLayout;

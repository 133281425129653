import yup from '@/helpers/yup';

import { getInvalid, getMax, getMin, getRequired } from '@/helpers/form-fields-error-messages';

import REGEX from '@/constants/regex';

const updateMe = yup.object().shape({
  firstName: yup
    .string()
    .min(1, getMin('first name', 1))
    .max(50, getMax('first name', 50))
    .required(getRequired('first name')),
  lastName: yup.string().min(1, getMin('last name', 1)).max(50, getMax('last name', 50)),
  email: yup.string().email(getInvalid('email')).required(getRequired('email')),
  ipPhoneMac: yup.string().matches(REGEX.MAC_ADDRESS, getInvalid('mac address')),
  transferChatsToAssignedUser: yup.boolean(),
  directExtension: yup.string().phone().nullable(),
});

export default updateMe;

import * as yup from 'yup';

import { getInvalid, getRequired } from '@/helpers/form-fields-error-messages';

import IOT_DEVICE from '@/constants/iot-device';

export default yup.object().shape({
  name: yup.string().min(1).required(getRequired('Name')),
  description: yup.string().nullable().optional(),
  identifier: yup.string().nullable().optional(),
  mac: yup.string().nullable().optional(),
  ipAddress: yup.string().nullable().optional(),
  serialNumber: yup.string().nullable().optional(),
  connectionType: yup
    .string()
    .oneOf(Object.values(IOT_DEVICE.CONNECTION_TYPE), getInvalid('Connection Type'))
    .optional(),
  connectionDirection: yup
    .string()
    .oneOf(Object.values(IOT_DEVICE.CONNECTION_DIRECTION), getInvalid('Connection Direction'))
    .required(getRequired('Connection Direction')),
  wsUrl: yup.string().when(['connectionType', 'connectionDirection'], {
    is: (
      connectionType: IOT_DEVICE.CONNECTION_TYPE,
      connectionDirection: IOT_DEVICE.CONNECTION_DIRECTION,
    ) =>
      connectionType === IOT_DEVICE.CONNECTION_TYPE.WS &&
      connectionDirection === IOT_DEVICE.CONNECTION_DIRECTION.OUT,
    then: (schema) => schema.required(getRequired('WS URL')),
    otherwise: (schema) => schema.nullable(),
  }),
  restApi: yup
    .object({
      url: yup.string().nullable().optional(),
      method: yup.string().nullable().optional(),
    })
    .nullable()
    .optional(),
});

export const Box1SX = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Box2SX = {
  position: 'relative',
  display: 'inline-flex',
};

export const listSX = {
  '&::-webkit-scrollbar': { display: 'none' },
  overflow: 'auto',
  height: 'calc(100vh - 510px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  bgcolor: 'background.paper',
};

export const listItemBoxSX = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
};

export const listItemSX = {
  width: '100%',
};

export const loadMoreSX = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
};

export const image = {
  borderRadius: '2px',
  height: '150px',
  width: '200px',
};

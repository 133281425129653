import { Dispatch, SetStateAction } from 'react';

import { IGetContactsGroupsQueryParams } from '@/interfaces/contacts-group';

import { IconButton, Stack, TextField, Tooltip } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

interface IContactGroupsHeaderProps {
  queryParams: IGetContactsGroupsQueryParams;
  setQueryParams: Dispatch<SetStateAction<IGetContactsGroupsQueryParams>>;
  handleCreate: VoidFunction;
}

const ContactGroupsHeader = ({
  queryParams,
  setQueryParams,
  handleCreate,
}: IContactGroupsHeaderProps) => {
  const handleUpdateQueryParams = (params: Partial<IGetContactsGroupsQueryParams>) =>
    setQueryParams((prevState) => ({
      ...prevState,
      ...params,
      skip: 0,
    }));

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" px={2} pb={1}>
      <TextField
        fullWidth
        sx={{ maxWidth: '20rem' }}
        type="search"
        label="Search"
        variant="outlined"
        size="small"
        value={queryParams.search}
        onChange={(e) => handleUpdateQueryParams({ search: e.target.value })}
        onBlur={(e) => handleUpdateQueryParams({ search: e.target.value })}
      />

      <Tooltip title="Add new contacts group" placement="right">
        <IconButton color="primary" onClick={handleCreate}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default ContactGroupsHeader;

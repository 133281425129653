import { FunctionComponent } from 'react';

import { Typography } from '@mui/material';

import { extractLinks } from '@/helpers/string';

import * as styles from '@/pages/dashboard/chat/style';

interface IMessageTextProps {
  text: string | null;
}

const MessageText: FunctionComponent<IMessageTextProps> = ({ text }): JSX.Element => {
  if (!text) {
    return <></>;
  }

  const links = extractLinks(text);
  const parts = [];

  let lastIndex = 0;

  links.forEach((link, index) => {
    const startIndex = text.indexOf(link, lastIndex);
    const beforeLink = text.substring(lastIndex, startIndex);

    if (beforeLink) {
      parts.push(
        <Typography
          fontSize="inherit"
          key={beforeLink + index}
          component="span"
          sx={{ wordBreak: 'break-word' }}
        >
          {beforeLink}
        </Typography>,
      );
    }

    parts.push(
      <Typography
        key={link + index}
        component="a"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        color="primary"
        fontSize="inherit"
        sx={{ wordBreak: 'break-word' }}
        whiteSpace="wrap"
      >
        {link}
      </Typography>,
    );

    lastIndex = startIndex + link.length;
  });

  const remainingText = text.substring(lastIndex);

  if (remainingText) {
    parts.push(
      <Typography fontSize="inherit" key={remainingText} component="span">
        {remainingText}
      </Typography>,
    );
  }

  return (
    <Typography sx={styles.messageBox2SX} whiteSpace="break-spaces">
      {parts}
    </Typography>
  );
};

export default MessageText;

import { FunctionComponent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '@/store/hooks';

import { Box } from '@mui/material';

import { IMessageResult, ISocketNewMessageStatus } from '@/interfaces/message';
import {
  IChatResult,
  IChatCreateRequest,
  IGetChatsQueryParams,
  IAddNewChatDialogResponse,
  IAddNewIotDeviceChatDialogResponse,
} from '@/interfaces/chat';
import { INotCreatedChat } from '@/interfaces/chat';
import { IPaginationParams, IPartialResult } from '@/interfaces/querying-data';

import useAccess from '@/hooks/use-access';

import * as filesHelpers from '@/helpers/files';
import { delay } from '@/helpers/delay';

import * as messagesThunk from '@/store/thunk/dashboard/messages';
import * as chatsThunk from '@/store/thunk/dashboard/chats';
import * as contactsThunk from '@/pages/dashboard/contacts/thunk';
import * as localChatsThunk from './thunk';

import * as snackbarReducer from '@/store/reducers/snackbar/snackbar';

import * as socketService from '@/services/socketio/socketio';
import * as callService from '@/services/voice/call';

import ChatsList from '@/pages/dashboard/chat/chats-list/Chats-lists';
import Messages from '@/pages/dashboard/chat/chat-messages/classic/Messages';
import IotDevicesMessages from '@/pages/dashboard/chat/chat-messages/iot-device/Messages';
import NewChatDialog from './dialogs/new-chat-dialog/New-chat-dialog';
import NewIotDeviceChatDialog from './dialogs/new-chat-dialog/New-iot-chat-dialog';
import AdvancedSearchDialog from './dialogs/advanced-search/Advanced-search';
import MessageSenderReceiverDialog from './chat-messages/classic/Message-sender-receiver-dialog';

import SOCKETIO from '@/constants/socketio';
import FILE from '@/constants/file';
import ERRORS from '@/constants/errors';
import ROUTES from '@/constants/routes';
import CHAT from '@/constants/chat';
import { CONTACT_TYPE } from '@/constants/contacts';
import { FEATURES } from '@/constants/features';

export interface ISendMessageContent {
  text: string;
  files: File[];
}

export interface IChatMenuState {
  anchor: HTMLElement | null;
  chat: IChatResult | null;
}

const ChatMessagesLayout: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.user);
  const { isSocketConnected } = useAppSelector((state) => state.socket);

  const { isFeatureAvailable } = useAccess();

  const [chatsQueryParams, setChatsQueryParams] = useState<
    Required<IGetChatsQueryParams> & Record<'search', string>
  >({
    search: '',
    limit: 20,
    skip: 0,
    marks: [],
    platform: CHAT.PLATFORM.ANY,
    variant: location.state?.sendMessageInformation?.variant ?? CHAT.VARIANT.CLASSIC,
  });
  const chatVariantRef = useRef(chatsQueryParams.variant);

  const [messagesQueryParams, setMessagesQueryParams] = useState<IPaginationParams>({
    limit: 50,
    skip: 0,
  });

  // FYI: Message are rendered with flex column-reverse
  const [messagesResult, setMessagesResult] = useState<IPartialResult<IMessageResult>>({
    maxCount: 0,
    records: [],
  });

  const [chatsResult, setChatsResult] = useState<IPartialResult<IChatResult>>({
    maxCount: 0,
    records: [],
  });

  const [chatMenuState, setChatMenuState] = useState<IChatMenuState>({
    anchor: null,
    chat: null,
  });

  const [isMessageSending, setIsMessageSending] = useState<boolean>(false);
  const [isMessagesLoading, setIsMessagesLoading] = useState<boolean>(false);

  const [isChatsLoading, setIsChatsLoading] = useState<boolean>(false);
  const [isAdvancedSearchDialogOpened, setIsAdvancedSearchDialogOpened] = useState<boolean>(false);
  const [isEnterPhoneNumberDialogOpened, setIsEnterPhoneNumberDialogOpened] =
    useState<boolean>(false);
  const [showStartNewIotDeviceChatDialog, setShowStartNewIotDeviceChatDialog] =
    useState<boolean>(false);
  const [messageSenderReceiverDialogState, setMessageSenderReceiverDialogState] = useState<{
    isOpened: boolean;
    message: IMessageResult | null;
  }>({
    isOpened: false,
    message: null,
  });

  const [selectedChat, setSelectedChat] = useState<IChatResult | null>(null);
  const [notCreatedSelectedChat, setNotCreatedSelectedChat] = useState<INotCreatedChat | null>(
    null,
  );

  const [sendMessageContent, setSendMessageContent] = useState<ISendMessageContent>({
    text: '',
    files: [],
  });

  const socketDataRef = useRef<{ selectedChat: typeof selectedChat }>({
    selectedChat: null,
  });

  const messageIdToScrollToRef = useRef<string | null>(null);
  const wasSendMessageInformationInvokedRef = useRef<boolean>(false);

  const scrollToMessage = (messageId: string): void => {
    const htmlElement = document.getElementById(messageId);

    htmlElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  /* 
    CHATS LOGIC
  */
  const handleLoadChats = async (
    needOpenChat: boolean = true,
    showLoader: boolean = true,
  ): Promise<void> => {
    if (showLoader) {
      setIsChatsLoading(true);
    }

    setChatMenuState({
      anchor: null,
      chat: null,
    });

    if (chatsQueryParams.search) {
      const chatsFromSearchResult = await dispatch(
        chatsThunk.fetchSearchChats({
          search: chatsQueryParams.search,
          variant: chatsQueryParams.variant,
        }),
      );
      if (chatsFromSearchResult.meta.requestStatus === 'rejected') {
        setIsChatsLoading(false);
        return;
      }

      setChatsResult({
        maxCount: chatsFromSearchResult.payload.length,
        records: chatsFromSearchResult.payload,
      });
    } else {
      const allChatsResult = await dispatch(
        chatsThunk.fetchGetChats({
          limit: chatsQueryParams.limit,
          skip: chatsQueryParams.skip,
          marks: chatsQueryParams.marks,
          platform: chatsQueryParams.platform,
          variant: chatsQueryParams.variant,
        }),
      );
      if (allChatsResult.meta.requestStatus === 'rejected') {
        return;
      }

      if (allChatsResult.payload) {
        setChatsResult((prevState) => {
          if (!chatsQueryParams.skip) {
            return allChatsResult.payload;
          }

          return {
            ...prevState,
            records: [...prevState.records, ...allChatsResult.payload.records],
          };
        });

        const newChatListItemInfo = location.state?.sendMessageInformation;

        // TODO: TEST !!!
        if (!wasSendMessageInformationInvokedRef.current && newChatListItemInfo) {
          wasSendMessageInformationInvokedRef.current = true;

          if (newChatListItemInfo.id) {
            handleSelectChat(newChatListItemInfo);
          } else {
            handleNewChat(newChatListItemInfo);
          }
        } else if (allChatsResult.payload.records?.length && needOpenChat) {
          if (selectedChat) {
            handleSelectChat(selectedChat as IChatResult);
          } else {
            handleSelectChat(allChatsResult.payload.records[0]);
          }
        }
      }
    }

    setTimeout(() => setIsChatsLoading(false), 300);
  };

  const handleNewChat = (
    data: IAddNewChatDialogResponse | IAddNewIotDeviceChatDialogResponse,
  ): void => {
    if (chatsQueryParams.variant === CHAT.VARIANT.IOT_DEVICE) {
      handleSelectChat(data.chat as IChatResult);
      return;
    }

    const isChatInternal = data.chatType === 'internal';

    if (isChatInternal ? !data.internalUser : !data.phoneNumber && !data.contact?.phoneNumber) {
      return;
    }

    if (data.chat) {
      handleSelectChat(data.chat);
      return;
    }

    const newChat: INotCreatedChat = {
      localId: Date.now().toString(),
      isInternal: data.chatType === 'internal',
      contact: null,
      unknownContact: null,
      type: data.chatType as CHAT.TYPE,
      platform: data.chatPlatform,
      newMsgsCount: 0,
      userReceiver: null,
      marks: [],
      variant: CHAT.VARIANT.CLASSIC,
    };

    if (newChat.isInternal) {
      newChat.senderCompanyExt = user?.companyExtension;
      newChat.userReceiver = data.internalUser;
    } else {
      if (data.contact && data.contact?.id === data.contact?.id) {
        newChat.contact = data.contact;
        newChat.unknownContact = null;
        newChat.contactType = data.contact.type as CONTACT_TYPE;
      } else {
        newChat.contact = null;
        newChat.unknownContact = data.phoneNumber;
        newChat.contactType =
          newChat.type === CHAT.TYPE.DIRECT ? CONTACT_TYPE.PRIVATE : CONTACT_TYPE.COMPANY;
      }
    }

    setSelectedChat(null);
    setNotCreatedSelectedChat(newChat);

    // @ts-expect-error for not saving unused chat without messages to database we allow it to be in chats
    setChatsResult((prevState) => ({
      ...prevState,
      records: [newChat, ...prevState.records.filter((chat) => !!chat.id)],
    }));

    setMessagesResult({
      maxCount: 0,
      records: [],
    });
  };

  const handleUpdateChatMarks = async (chatId: string, marks: CHAT.MARKS[]): Promise<void> => {
    setIsChatsLoading(true);

    const { meta } = await dispatch(
      chatsThunk.fetchUpdateMarks({
        id: chatId,
        marks,
      }),
    );

    if (meta.requestStatus === 'fulfilled') {
      setChatsResult((prevState) => ({
        ...prevState,
        records: prevState.records.map((el) =>
          el.id === chatId
            ? {
                ...el,
                marks,
              }
            : el,
        ),
      }));

      setChatMenuState((prevState) => ({
        ...prevState,
        chat: {
          ...(prevState.chat as IChatResult),
          marks,
        },
      }));
    }

    setIsChatsLoading(false);
  };

  const handleSelectChat = async (chat: IChatResult): Promise<void> => {
    if (!chat || chat?.id === selectedChat?.id) {
      return;
    }

    if (chatsQueryParams.search) {
      setChatsQueryParams((prevState) => ({ ...prevState, search: '' }));
    }

    setIsMessagesLoading(true);

    setSelectedChat(chat);

    socketService.socket?.emit(SOCKETIO.EVENTS.JOIN_CHAT, JSON.stringify({ chatId: chat.id }));

    setChatsResult((prevState) => {
      const prevStateFilteredRecords = prevState.records.filter((el) => el.id);
      const isChatInList = prevStateFilteredRecords.some((el) => el.id === chat.id);

      return {
        ...prevState,
        records: isChatInList
          ? prevStateFilteredRecords.map((el) => {
              if (el.id === chat.id) {
                let newMsgsCount = el.newMsgsCount;

                if (
                  chat.type !== CHAT.TYPE.INTERNAL ||
                  (chat.type === CHAT.TYPE.INTERNAL && chat.lastMsgSentByRef !== user?.id)
                ) {
                  newMsgsCount = 0;
                }

                return { ...chat, newMsgsCount };
              }

              return el;
            })
          : [{ ...chat, newMsgsCount: 0 }, ...prevStateFilteredRecords],
      };
    });

    await delay(300);

    setIsMessagesLoading(false);
  };

  const handleSelectChatFromAdvancedSearch = async (
    chatId: string,
    messageId: string,
  ): Promise<void> => {
    setIsMessagesLoading(true);

    const msgDataResult = await dispatch(
      localChatsThunk.fetchAdvancedSearchMsgData({ chatId, messageId }),
    );

    if (!msgDataResult.payload) {
      return;
    }

    const { meta, payload: chat } = await dispatch(localChatsThunk.fetchGetChatById({ chatId }));
    if (meta.requestStatus === 'rejected') {
      return;
    }

    messageIdToScrollToRef.current = messageId;

    setIsMessagesLoading(false);

    setSelectedChat(chat);
    setChatsQueryParams((prevState) => ({
      ...prevState,
      skip: 0,
      platform: CHAT.PLATFORM.ANY,
    }));
  };

  const handleCallFromChatMenu = (chat: IChatResult): void => {
    if (
      !chat ||
      (!chat?.contact && !chat?.unknownContact && !chat?.userReceiver && !chat?.userSender)
    ) {
      return;
    }

    let to!: string;
    if (chat.type === CHAT.TYPE.INTERNAL) {
      to =
        chat.userSender?.id === user?.id
          ? (chat.userReceiver?.companyExtension as string)
          : (chat.userSender?.companyExtension as string);
    } else {
      to = chat.contact ? chat.contact.phoneNumber : (chat.unknownContact as string);
    }

    handleCallFromChatToNumber(to);
  };

  const handleCallFromChatToNumber = async (to: string): Promise<void> => {
    if (!isFeatureAvailable(FEATURES.VOICE)) {
      return;
    }

    callService.handleInitCall({ dispatch, to });

    navigate(ROUTES.DASHBOARD_VOICE_STATUS);
  };

  /* MESSAGES LOGIC */
  const handleLoadMessages = async (): Promise<void> => {
    if (!selectedChat?.id) {
      return;
    }

    const { payload } = await dispatch(
      messagesThunk.fetchOnLoadMore({
        chatId: selectedChat.id,
        ...messagesQueryParams,
      }),
    );

    if (payload) {
      setMessagesResult((prevState) => ({
        ...prevState,
        records: messagesQueryParams.skip
          ? [...prevState.records, ...payload.records]
          : payload.records,
      }));

      const messageId = messageIdToScrollToRef.current;

      if (messageId) {
        messageIdToScrollToRef.current = null;

        setTimeout(() => {
          scrollToMessage(messageId);
        }, 500);
      }
    }
  };

  const handleSendOtherTypeOfMessage = async (chat: IChatResult) => {
    if (chat.type === CHAT.TYPE.INTERNAL || !chat.id) {
      return;
    }

    const chatType = chat.type === CHAT.TYPE.DIRECT ? CHAT.TYPE.TENANT : CHAT.TYPE.DIRECT;
    const phoneNumber = (chat.contact ? chat.contact.phoneNumber : chat.unknownContact) as string;

    const { meta, payload: chatPayload } = await dispatch(
      localChatsThunk.fetchGetOneByRelativeInfo({
        phoneNumber,
        type: chatType,
        platform: chat.platform,
      }),
    );
    if (meta.requestStatus === 'rejected') {
      return;
    }

    if (chatPayload) {
      handleSelectChat(chatPayload);

      if (chatsQueryParams.search) {
        setChatsQueryParams((prevState) => ({ ...prevState, skip: 0, search: '' }));
      }
    } else {
      const contactResult = await dispatch(
        contactsThunk.fetchGetPrivateOrCompanyContact({
          phoneNumber,
          chatType,
        }),
      );

      const newChatData: IAddNewChatDialogResponse = {
        phoneNumber,
        chatType,
        chatPlatform: chat.platform as CHAT.PLATFORM,
        contact: contactResult?.payload,
        chat: null,
        internalUser: null,
      };

      if (
        newChatData.chatType === CHAT.TYPE.TENANT &&
        newChatData.contact?.type === CONTACT_TYPE.PRIVATE
      ) {
        newChatData.contact = null;
      }

      handleNewChat(newChatData);
    }

    setChatMenuState((prevState) => ({ ...prevState, anchor: null }));
  };

  const handleSendMessageToNotCreatedChat = async (): Promise<void> => {
    if (!notCreatedSelectedChat) {
      return;
    }

    setIsMessageSending(true);

    const { contact } = notCreatedSelectedChat;

    const createChatBody: IChatCreateRequest = {
      contactId: null,
      receiverId: notCreatedSelectedChat.userReceiver?.id,
      isInternal: notCreatedSelectedChat.isInternal,
      platform: notCreatedSelectedChat?.platform as CHAT.PLATFORM,
      type: notCreatedSelectedChat.type as CHAT.TYPE,
      variant: notCreatedSelectedChat.variant,
      phoneNumber: null,
    };

    if (contact) {
      createChatBody.contactId = contact.id;
      createChatBody.phoneNumber = contact.phoneNumber;
    } else {
      createChatBody.phoneNumber = notCreatedSelectedChat?.unknownContact as string;
    }

    const newChatResult = await dispatch(chatsThunk.fetchCreate(createChatBody));
    if (newChatResult.meta.requestStatus === 'rejected') {
      setIsMessageSending(false);
      return;
    }

    if (!newChatResult.payload) {
      setIsMessageSending(false);
      return;
    }

    setNotCreatedSelectedChat(null);

    const formData = new FormData();

    formData.append('text', sendMessageContent.text);
    formData.append('chatId', newChatResult.payload.id);

    sendMessageContent.files.forEach((el, i) => {
      formData.append(`file${i + 1}`, el);
    });

    const { meta, payload: newMessage } = await dispatch(messagesThunk.fetchSend(formData));
    if (meta.requestStatus === 'rejected') {
      setIsMessageSending(false);
      return;
    }

    handleSelectChat(newChatResult.payload);

    setTimeout(() => {
      scrollToMessage(newMessage.id);
    }, 1000);

    setSendMessageContent({
      text: '',
      files: [],
    });

    setIsMessageSending(false);
  };

  const handleSendMessageToIotDevice = async (): Promise<void> => {
    if (!selectedChat || !sendMessageContent.text) {
      return;
    }
    setIsMessageSending(true);

    const { meta, payload: newMessage } = await dispatch(
      localChatsThunk.fetchSendMessageToIotDevice({
        chatId: selectedChat.id,
        text: sendMessageContent.text,
      }),
    );

    if (meta.requestStatus === 'rejected') {
      setIsMessageSending(false);
      return;
    }

    if (newMessage) {
      setMessagesResult((prevState) => ({
        ...prevState,
        records: [newMessage, ...prevState.records],
      }));
    }

    setTimeout(() => {
      scrollToMessage(newMessage.id);
    }, 1000);

    setSendMessageContent({
      text: '',
      files: [],
    });

    setIsMessageSending(false);
  };

  const handleSendMessageToCreatedChat = async (): Promise<void> => {
    if (!selectedChat) {
      return;
    }

    setIsMessageSending(true);

    const formData = new FormData();

    formData.append('text', sendMessageContent.text);
    formData.append('chatId', selectedChat.id);

    sendMessageContent.files.forEach((el, i) => {
      formData.append(`file${i + 1}`, el);
    });

    const { meta, payload: newMessage } = await dispatch(messagesThunk.fetchSend(formData));
    if (meta.requestStatus === 'rejected') {
      setIsMessageSending(false);
      return;
    }

    if (newMessage) {
      setMessagesResult((prevState) => ({
        ...prevState,
        records: [newMessage, ...prevState.records],
      }));
    }

    setTimeout(() => {
      scrollToMessage(newMessage.id);
    }, 1000);

    setSendMessageContent({
      text: '',
      files: [],
    });

    setIsMessageSending(false);
  };

  const handleMessageFilesChange = (newFiles: FileList): void => {
    const filesCount = newFiles.length + sendMessageContent.files.length;

    if (filesCount > FILE.MAX_FILES_COUNT) {
      dispatch(snackbarReducer.showInfo(ERRORS.FILES.MAX_FILES_COUNT.replace('$count', '10')));
      return;
    }

    const filesToAppend: File[] = [];

    for (const file of newFiles) {
      if (file.size > FILE.MAX_FILE_SIZE) {
        dispatch(
          snackbarReducer.showInfo(
            ERRORS.FILES.MAX_FILES_SIZE.replace(
              '$size',
              filesHelpers.convertBytes(FILE.MAX_FILE_SIZE),
            ),
          ),
        );

        return;
      } else {
        filesToAppend.push(file);
      }
    }

    setSendMessageContent((prevState) => ({
      ...prevState,
      files: [...filesToAppend, ...prevState.files],
    }));
  };

  /* SOCKET EVENTS */
  const handleSocketChatsUpdates = async (data: IChatResult): Promise<void> => {
    if (data.variant !== chatVariantRef.current) {
      return;
    }

    if (data.variant === CHAT.VARIANT.CLASSIC) {
      if (data.type == CHAT.TYPE.TENANT && !user?.canSeeCompanyMsgsAndCalls) {
        return;
      }

      if (
        data.id !== socketDataRef.current.selectedChat?.id &&
        (!data.isInternal || data.userSender?.id !== user?.id)
      ) {
        setChatsResult((prevState) => ({
          ...prevState,
          records: [data, ...prevState.records.filter((chat) => chat.id !== data.id)],
        }));
      } else {
        dispatch(
          chatsThunk.fetchUpdateMarks({
            id: data.id,
            isViewed: true,
          }),
        );
      }
    } else {
      setChatsResult((prevState) => ({
        ...prevState,
        records: [data, ...prevState.records.filter((chat) => chat.id !== data.id)],
      }));
    }
  };

  const handleSocketNewMessage = (data: IMessageResult): void => {
    if (
      data.chatId === socketDataRef.current.selectedChat?.id &&
      (!data.userSender || data.userSender?.id !== user?.id)
    ) {
      setMessagesResult((prevState) => ({
        maxCount: prevState.maxCount + 1,
        // In case of multiple incoming messages to avoid duplications on open chat load messages
        //TODO: need to check this place
        records: [
          {
            ...data,
            trackType:
              data.isInternal && data.userSender?.companyExtension !== user?.companyExtension
                ? 'inbound'
                : data.trackType,
          },
          ...prevState.records.filter((el: IMessageResult) => el.id !== data.id),
        ],
      }));

      setTimeout(() => {
        scrollToMessage(data.id);
      }, 1000);
    }
  };

  const handleSocketNewMessageStatus = (data: ISocketNewMessageStatus): void => {
    if (data.chatId === socketDataRef.current.selectedChat?.id) {
      setMessagesResult((prevState) => ({
        ...prevState,
        records: prevState.records.map((el) =>
          el.id === data.messageId ? { ...el, status: data.status } : el,
        ),
      }));
    }
  };

  /* USE EFFECTS */
  useEffect(() => {
    socketDataRef.current.selectedChat = selectedChat;

    if (selectedChat?.id) {
      if (messagesQueryParams.skip) {
        setMessagesQueryParams((prevState) => ({ ...prevState, skip: 0 }));
      } else {
        handleLoadMessages();
      }
    } else {
      setMessagesResult({
        maxCount: 0,
        records: [],
      });
    }
  }, [selectedChat]);

  useEffect(() => {
    if (selectedChat?.id) {
      handleLoadMessages();
    }
  }, [messagesQueryParams.skip, messagesQueryParams.limit]);

  useEffect(() => {
    if (chatsQueryParams.search) {
      setSelectedChat(null);
    }
  }, [chatsQueryParams.search, chatsQueryParams.platform, chatsQueryParams.marks]);

  useEffect(() => {
    handleLoadChats(!chatsQueryParams.search);
    setMessagesQueryParams((prevState) => ({ ...prevState, skip: 0 }));
    chatVariantRef.current = chatsQueryParams.variant;
  }, [
    chatsQueryParams.search,
    chatsQueryParams.skip,
    chatsQueryParams.limit,
    chatsQueryParams.marks,
    chatsQueryParams.platform,
    chatsQueryParams.variant,
  ]);

  useEffect(() => {
    socketService.socket?.on(SOCKETIO.EVENTS.MESSAGE, (json) =>
      handleSocketNewMessage(JSON.parse(json)),
    );

    socketService.socket?.on(SOCKETIO.EVENTS.CHATS_UPDATES, (json) => {
      handleSocketChatsUpdates(JSON.parse(json));
    });

    socketService.socket?.on(SOCKETIO.EVENTS.MESSAGE_STATUS, (json) =>
      handleSocketNewMessageStatus(JSON.parse(json)),
    );

    if (selectedChat) {
      socketService.socket?.emit(
        SOCKETIO.EVENTS.JOIN_CHAT,
        JSON.stringify({ chatId: selectedChat.id }),
      );
    }

    return () => {
      socketService.socket?.removeListener(SOCKETIO.EVENTS.MESSAGE);
      socketService.socket?.removeListener(SOCKETIO.EVENTS.MESSAGE_STATUS);
      socketService.socket?.removeListener(SOCKETIO.EVENTS.CHATS_UPDATES);
    };
  }, [isSocketConnected]);

  return (
    <>
      <NewChatDialog
        handleNewChat={handleNewChat}
        setShowEnterPhoneNumberDialog={setIsEnterPhoneNumberDialogOpened}
        showEnterPhoneNumberDialog={isEnterPhoneNumberDialogOpened}
      />

      <NewIotDeviceChatDialog
        handleNewChat={handleNewChat}
        setShowStartNewIotDeviceChatDialog={setShowStartNewIotDeviceChatDialog}
        isOpen={showStartNewIotDeviceChatDialog}
      />

      <AdvancedSearchDialog
        isVisible={isAdvancedSearchDialogOpened}
        setIsVisible={setIsAdvancedSearchDialogOpened}
        handleOpenChatFromSearch={handleSelectChatFromAdvancedSearch}
      />

      <MessageSenderReceiverDialog
        isOpened={messageSenderReceiverDialogState.isOpened}
        message={messageSenderReceiverDialogState.message as IMessageResult}
        handleClose={() => {
          setMessageSenderReceiverDialogState((prevState) => ({ ...prevState, isOpened: false }));
        }}
        handleCall={(info) => {
          setMessageSenderReceiverDialogState((prevState) => ({ ...prevState, isOpened: false }));

          const { message } = messageSenderReceiverDialogState;

          if (!message) {
            return;
          }

          if (message.isInternal && info.companyExtension) {
            handleCallFromChatToNumber(info.companyExtension);
          } else if (!message.isInternal && info.phoneNumber) {
            handleCallFromChatToNumber(info.phoneNumber);
          } else {
            dispatch(
              snackbarReducer.showError(
                'Could not place a call. Please use the dialog in Voice page instead.',
              ),
            );
          }
        }}
      />

      <Box flex={1} display="flex" gap={1} p={2} justifyContent="space-between">
        <ChatsList
          setChatsResult={setChatsResult}
          menuState={chatMenuState}
          setMenuState={setChatMenuState}
          isLoading={isChatsLoading}
          selectedChat={selectedChat}
          notCreatedSelectedChat={notCreatedSelectedChat}
          setSelectedChat={setSelectedChat}
          setNotCreatedSelectedChat={setNotCreatedSelectedChat}
          chatsResult={chatsResult}
          queryParams={chatsQueryParams}
          setQueryParams={setChatsQueryParams}
          handleSendOtherTypeOfMessage={handleSendOtherTypeOfMessage}
          handleSelectChat={handleSelectChat}
          handleUpdateChatMarks={handleUpdateChatMarks}
          setIsEnterPhoneNumberDialogOpened={setIsEnterPhoneNumberDialogOpened}
          setShowStartNewIotDeviceChatDialog={setShowStartNewIotDeviceChatDialog}
          handleCallFromChatMenu={handleCallFromChatMenu}
        />

        {chatsQueryParams.variant === CHAT.VARIANT.CLASSIC && (
          <Messages
            setChatsResult={setChatsResult}
            setIsAdvancedSearchDialogOpened={setIsAdvancedSearchDialogOpened}
            isMessagesLoading={isMessagesLoading}
            selectedChat={selectedChat}
            isSearchActive={!!chatsQueryParams.search}
            handleSendMessage={(event: KeyboardEvent | MouseEvent | SyntheticEvent) => {
              if (event.type == 'keydown') {
                const { ctrlKey, code } = event as KeyboardEvent;

                if (!ctrlKey || (code !== 'Enter' && code !== 'NumpadEnter')) {
                  return;
                }
              }

              if (selectedChat) {
                handleSendMessageToCreatedChat();
              } else if (notCreatedSelectedChat) {
                handleSendMessageToNotCreatedChat();
              } else {
                dispatch(
                  snackbarReducer.showError(
                    'Could not send a message to unknown chat. Please refresh the page and reopen chat',
                  ),
                );
              }
            }}
            isMessageSending={isMessageSending}
            messagesResult={messagesResult}
            sendMessageContent={sendMessageContent}
            setSendMessageContent={setSendMessageContent}
            setQueryParams={setMessagesQueryParams}
            handleSelectFiles={handleMessageFilesChange}
            handleSelectMessage={(message) => {
              if (
                !message.contact &&
                !message.user &&
                !message.userSender &&
                !message.userReceiver &&
                !message.from
              ) {
                return;
              }

              setMessageSenderReceiverDialogState({
                isOpened: true,
                message,
              });
            }}
          />
        )}

        {chatsQueryParams.variant === CHAT.VARIANT.IOT_DEVICE && (
          <IotDevicesMessages
            setChatsResult={setChatsResult}
            isMessagesLoading={isMessagesLoading}
            selectedChat={selectedChat}
            isSearchActive={!!chatsQueryParams.search}
            handleSendMessage={(event: KeyboardEvent | MouseEvent | SyntheticEvent) => {
              if (event.type == 'keydown') {
                const { ctrlKey, code } = event as KeyboardEvent;

                if (!ctrlKey || (code !== 'Enter' && code !== 'NumpadEnter')) {
                  return;
                }
              }

              handleSendMessageToIotDevice();
            }}
            isMessageSending={isMessageSending}
            messagesResult={messagesResult}
            sendMessageContent={sendMessageContent}
            setSendMessageContent={setSendMessageContent}
            setQueryParams={setMessagesQueryParams}
            handleSelectMessage={(message) => {
              if (
                !message.contact &&
                !message.user &&
                !message.userSender &&
                !message.userReceiver &&
                !message.from
              ) {
                return;
              }

              setMessageSenderReceiverDialogState({
                isOpened: true,
                message,
              });
            }}
          />
        )}
      </Box>
    </>
  );
};

export default ChatMessagesLayout;

import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import {
  Stack,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  Checkbox,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import * as styles from './styles';

import * as bmcThunk from '@/pages/dashboard/broadcast/campaign/thunk';
import * as bmcReducer from '@/pages/dashboard/broadcast/campaign/reducer';

interface ISelectReceiversFromContactsGroupsProps {
  isContactsFileValidating: boolean;
}

const SelectReceiversFromContactsGroups = ({
  isContactsFileValidating,
}: ISelectReceiversFromContactsGroupsProps) => {
  const dispatch = useAppDispatch();

  const {
    contactsGroupsState: {
      contactGroupsResult,
      selectedContactsGroups,
      selectedContactsGroupsCount,
    },
    contactsFile,
  } = useAppSelector((state) => state.broadcastCampaign);

  const [queryParams, setQueryParams] = useState({
    search: '',
    limit: 50,
  });

  const getContactsGroupsInitial = async () => {
    if (!contactGroupsResult.records.length) {
      await dispatch(bmcThunk.fetchGetContactsGroupsInitial({ limit: queryParams.limit }));
    }
  };

  const handleLoadMore = async () => {
    if (contactGroupsResult.records.length !== contactGroupsResult.maxCount) {
      await dispatch(
        bmcThunk.fetchGetLoadMoreContactsGroups({
          limit: queryParams.limit,
          skip: contactGroupsResult.records.length,
        }),
      );
    }
  };

  const handleToggle = (id: string) => {
    dispatch(bmcReducer.selectedContactsGroup(id));
  };

  const handleSelectAll = () => {
    dispatch(
      bmcReducer.setSelectAllContactsGroups(contactGroupsResult.records.map(({ id }) => id)),
    );
  };

  const searchContactsGroups = async (search: string) => {
    setQueryParams((prevState) => ({ ...prevState, search }));

    if (search) {
      await dispatch(bmcThunk.fetchGetSearchContactsGroups({ search }));
    } else if (!search && !contactGroupsResult.maxCount) {
      await dispatch(bmcThunk.fetchGetContactsGroupsInitial({ limit: queryParams.limit }));
    }
  };

  useEffect(() => {
    getContactsGroupsInitial();
  }, []);

  return (
    <Stack spacing={2} mt={2}>
      {contactsFile || isContactsFileValidating ? null : (
        <Stack spacing={1} direction="row">
          <TextField
            sx={{ flex: 1 }}
            size="small"
            variant="outlined"
            type="search"
            label="Search contacts group"
            value={queryParams.search}
            onChange={(event) => searchContactsGroups(event.target.value)}
          />

          <Button
            disabled={!!contactsFile || isContactsFileValidating}
            color="primary"
            variant="contained"
            component="label"
            onClick={handleSelectAll}
          >
            {contactGroupsResult.maxCount &&
            selectedContactsGroupsCount === contactGroupsResult.maxCount
              ? 'Unselect all'
              : 'Select all'}
          </Button>
        </Stack>
      )}

      {contactsFile ? null : (
        <Typography>Selected {selectedContactsGroupsCount} contacts groups</Typography>
      )}

      {contactsFile ? null : (
        <List dense disablePadding sx={styles.listSX}>
          {contactGroupsResult.records.map((el) => {
            const labelId = `checkbox-list-secondary-label-${el.id}`;

            let primaryText = el.name;

            if (el.contactsRefs.length) {
              primaryText += ` (${el.contactsRefs.length} contact${
                el.contactsRefs.length > 1 ? 's' : ''
              })`;
            } else {
              primaryText += ' (has no contacts)';
            }

            return (
              <ListItem disablePadding disableGutters key={el.id}>
                <ListItemButton
                  disabled={!!contactsFile || isContactsFileValidating}
                  onClick={() => handleToggle(el.id as string)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="end"
                      disabled={!!contactsFile || isContactsFileValidating}
                      checked={selectedContactsGroups.indexOf(el.id as string) !== -1}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>

                  <ListItemText id={labelId} primary={primaryText} secondary={el.description} />
                </ListItemButton>
              </ListItem>
            );
          })}

          {contactGroupsResult.maxCount &&
          contactGroupsResult.records.length < contactGroupsResult.maxCount ? (
            <ListItemButton onClick={handleLoadMore} sx={styles.loadMoreSX}>
              <ExpandMoreIcon fontSize="medium" />
            </ListItemButton>
          ) : null}
        </List>
      )}
    </Stack>
  );
};

export default SelectReceiversFromContactsGroups;

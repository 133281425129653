import { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';

import { FormControl, FormLabel, Select, MenuItem, FormControlLabel } from '@mui/material';

import {
  Box,
  Button,
  Tooltip,
  TextField,
  Accordion,
  AccordionDetails,
  Typography,
  Switch,
  IconButton,
  AccordionSummary,
} from '@mui/material';

import {
  HelpOutline as HelpOutlineIcon,
  DeleteOutline as DeleteOutlineIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

import { IVoiceIncomingSettingsCallFlow } from '@/interfaces/voice/settings';

import * as voiceSettingsThunk from '@/store/thunk/dashboard/voice-settings';

import * as menuReducer from '@/store/reducers/dashboard/menu';

import DASHBOARD from '@/constants/dashboard';

const CONNECTION_TIMEOUTS = Array.from({ length: 30 }, (_, i) => i + 1);
const TEXT_TO_SPEECH_REPEAT_TIMES = Array.from({ length: 10 }, (_, i) => i + 1);

const WrongInputCallFlow: FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [prevCallFlow, setPrevCallFlow] = useState<IVoiceIncomingSettingsCallFlow[]>([]);
  const [callFlow, setCallFlow] = useState<IVoiceIncomingSettingsCallFlow[]>([]);
  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([]);

  const getCallFlow = async (): Promise<void> => {
    const { payload } = await dispatch(voiceSettingsThunk.fetchGetVoiceSettings());

    if (payload && payload.incoming.callFlow) {
      setCallFlow(payload.incoming.callFlow);
      setPrevCallFlow(payload.incoming.callFlow);
    }
  };

  const handleGatherInputSwitch = (index: number) => {
    setCallFlow((current) => {
      return current.map((element, i) => {
        return i === index
          ? {
              ...element,
              incorrect: { ...element.incorrect, gatherInput: !element.incorrect.gatherInput },
            }
          : element;
      });
    });
  };

  const handleTextToSpeechChanges = (index: number, value: string) => {
    setCallFlow((current) => {
      return current.map((element, i) => {
        return i === index
          ? { ...element, incorrect: { ...element.incorrect, textToSpeech: value } }
          : element;
      });
    });
  };

  const handleDiscard = () => {
    setCallFlow(prevCallFlow);
  };

  const handleAddStep = () => {
    if (callFlow?.length < 10 || !callFlow) {
      const newStep = {
        step: callFlow?.length + 1 || 1,
        correct: null,
        incorrect: {
          gatherInput: false,
          repeat: 1,
          textToSpeech: '',
          timeout: 1,
        },
      };
      setCallFlow((current) => {
        return [...current, newStep];
      });
      setExpandedAccordions((current) => [...current, current.length]);
    }
  };

  const handleRemoveStep = (index: number) => {
    setCallFlow((prevState) => prevState.filter((_, i) => i !== index));
  };

  const handleExpandAccordion = (index: number) => {
    const isExpanded = expandedAccordions.includes(index);
    if (!isExpanded) {
      setExpandedAccordions((current) => [...current, index]);
    } else {
      setExpandedAccordions((current) => current.filter((element) => element !== index));
    }
  };

  const handleSave = async (): Promise<void> => {
    if (callFlow && Object.hasOwn(callFlow, 'length')) {
      const { payload } = await dispatch(voiceSettingsThunk.fetchSaveCallFlowSettings(callFlow));

      if (payload) {
        setCallFlow(payload.incoming.callFlow);
        setPrevCallFlow(payload.incoming.callFlow);
      }
    }
  };

  const handleTimeoutChange = (index: number, value: number): void => {
    setCallFlow((current) => {
      return current.map((element, i) => {
        return i === index
          ? { ...element, incorrect: { ...element.incorrect, timeout: value } }
          : element;
      });
    });
  };

  const handleRepeatChange = (index: number, value: number): void => {
    setCallFlow((current) => {
      return current.map((element, i) => {
        return i === index
          ? { ...element, incorrect: { ...element.incorrect, repeat: value } }
          : element;
      });
    });
  };

  useEffect(() => {
    getCallFlow();
    dispatch(menuReducer.setMenu({ menu: DASHBOARD.MENU.SETTINGS }));
  }, []);

  return (
    <Box display="flex" flexDirection="column" flex={1} overflow="auto" p={2} gap={2}>
      <Box display="flex" flexDirection="column" flex={1} gap={1}>
        {callFlow?.map((element, index) => {
          return (
            <Accordion key={index} expanded={expandedAccordions.includes(index)} elevation={0}>
              <AccordionSummary
                onClick={() => {
                  handleExpandAccordion(index);
                }}
                expandIcon={<ExpandMoreIcon />}
                sx={{ flexDirection: 'row-reverse', gap: 1 }}
              >
                <Box display="flex" flex={1} alignItems="center" gap={1}>
                  <Typography>Step: {element.step}</Typography>

                  {index === callFlow.length - 1 ? (
                    <IconButton
                      color="error"
                      sx={{ ml: 'auto' }}
                      onClick={() => handleRemoveStep(index)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  ) : null}
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" justifyContent="center">
                    <Typography variant="body1" mr={1}>
                      Text to speech if dialed input by caller is wrong
                    </Typography>

                    <Tooltip title="The caller will hear this text as a voice on an incoming call. It can be a greeting or instruction on how to connect with company extension etc.">
                      <HelpOutlineIcon fontSize="small" />
                    </Tooltip>
                  </Box>

                  <TextField
                    fullWidth
                    id="textToSpeech"
                    label="Text to speech"
                    multiline
                    minRows={2}
                    maxRows={2}
                    variant="outlined"
                    value={element.incorrect?.textToSpeech}
                    onChange={(e) => {
                      handleTextToSpeechChanges(index, e.target.value);
                    }}
                  />

                  <Box display="flex" gap={1} justifyContent="space-between">
                    <Box flex={1} display="flex" flexDirection="column" gap={1}>
                      <FormControl size="small">
                        <FormLabel sx={{ color: 'text.primary' }}>
                          Wait time between Greeting and Call Connection
                          <Tooltip title="Enter time after which call will be connected to extensions or disconnected if no input is received.">
                            <HelpOutlineIcon fontSize="small" sx={{ mb: -0.5, ml: 1 }} />
                          </Tooltip>
                        </FormLabel>

                        <Select
                          size="small"
                          value={element.incorrect?.timeout || 1}
                          onChange={(event) => {
                            if (event.target.value) {
                              handleTimeoutChange(index, event.target.value as number);
                            }
                          }}
                          sx={{ maxWidth: '7rem' }}
                          MenuProps={{ sx: { maxHeight: '20rem' } }}
                        >
                          {CONNECTION_TIMEOUTS.map((el) => {
                            return (
                              <MenuItem key={el} value={el}>
                                {el}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      <FormControl size="small">
                        <FormLabel sx={{ color: 'text.primary' }}>
                          Repeat greeting times
                          <Tooltip title="Select the number of times you would like greeting to be played to caller before call connects.">
                            <HelpOutlineIcon fontSize="small" sx={{ mb: -0.5, ml: 1 }} />
                          </Tooltip>
                        </FormLabel>

                        <Select
                          size="small"
                          value={element.incorrect?.repeat || 1}
                          onChange={(event) => {
                            if (event.target.value) {
                              handleRepeatChange(index, event.target.value as number);
                            }
                          }}
                          sx={{ maxWidth: '7rem' }}
                          MenuProps={{ sx: { maxHeight: '20rem' } }}
                        >
                          {TEXT_TO_SPEECH_REPEAT_TIMES.map((el) => {
                            return (
                              <MenuItem key={el} value={el}>
                                {el}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box flex={1} display="flex" alignItems="flex-start" justifyContent="flex-end">
                      <FormControlLabel
                        label={
                          <Box color="text.primary">
                            Allow gather input
                            <Tooltip title=" Select ON for allowing calls to connect to individual extensions instead of redirecting all calls to Default Extension.">
                              <HelpOutlineIcon
                                color="primary"
                                fontSize="small"
                                sx={{ mb: -0.5, ml: 1 }}
                              />
                            </Tooltip>
                          </Box>
                        }
                        control={
                          <Switch
                            checked={element.incorrect?.gatherInput ?? false}
                            onChange={() => handleGatherInputSwitch(index)}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>

      <Box display="flex" gap={1}>
        <Button color="success" variant="contained" onClick={handleSave}>
          Save
        </Button>

        <Button color="primary" variant="contained" onClick={handleAddStep}>
          Add step
        </Button>

        <Button color="primary" variant="contained" onClick={handleDiscard}>
          Discard
        </Button>
      </Box>
    </Box>
  );
};

export default WrongInputCallFlow;

import Box from '@mui/material/Box';
import { CircularProgress, CircularProgressProps, Typography } from '@mui/material';

import * as styles from './styles';

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={styles.Box2SX}>
      <CircularProgress variant="determinate" color="primary" {...props} />
      <Box sx={styles.Box1SX}>
        <Typography
          variant="caption"
          component="div"
          fontWeight={'bold'}
          color="primary.contrastText"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
export default CircularProgressWithLabel;

import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import * as colors from '@mui/material/colors';
declare module '@mui/material/ToggleButton' {
  interface ToggleButtonPropsSizeOverrides {
    'extra-small': true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    'extra-small': true;
  }
}
declare module '@mui/material/ToggleButtonGroup' {
  interface ToggleButtonGroupPropsSizeOverrides {
    'extra-small': true;
  }
}
export const defStyles = {
  scroll: {
    '&::-webkit-scrollbar': { width: 5, height: 5 },
    '&::-webkit-scrollbar-track': {
      background: '#011F25',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#b3e0d7',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#83ccbe',
    },
  },
};
export const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#022d36',
      dark: '#011F25',
      light: '#215561',
    },
    secondary: {
      main: '#83ccbe',
      dark: '#33a891',
      light: '#b3e0d7',
    },
    success: {
      main: colors.green[600],
      dark: colors.green[800],
      light: colors.green[400],
      contrastText: colors.common.white,
    },
    warning: colors.orange,
    info: colors.blue,
    background: {
      default: '#fafafa',
    },
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiMenuItem: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 2,
      },
    },
    MuiTimePicker: {
      defaultProps: {
        closeOnSelect: false,
        minutesStep: 1,
        views: ['hours', 'minutes'],
        // OpenPickerButtonProps: { color: 'primary' },
      },
    },
    MuiDateTimePicker: {
      defaultProps: {
        closeOnSelect: false,
        minutesStep: 1,
        // OpenPickerButtonProps: { color: 'primary' },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '64px',
          minHeight: '64px',
          maxHeight: '64px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '64px',
          minHeight: '64px',
          maxHeight: '64px',
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        format: 'yyyy-MM-dd',
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:not(:hover)': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: '1.5rem',
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': { display: 'none' },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          justifyContent: 'center',
          display: 'flex',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { size: 'extra-small' },
          style: {
            padding: 0,
            fontSize: 12,
            lineHeight: 2.25,
          },
        },
      ],
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          minWidth: '7rem',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&::-webkit-scrollbar': { width: 5 },
          '&::-webkit-scrollbar-track': {
            background: theme.palette.secondary.main,
            borderRadius: 10,
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
            borderRadius: 10,
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiToggleButtonGroup: {
      variants: [
        {
          props: { size: 'extra-small' },
          style: {
            padding: 0,
            fontSize: 12,
            lineHeight: 2.25,
          },
        },
      ],
    },
    MuiToggleButton: {
      variants: [
        {
          props: { size: 'extra-small' },
          style: {
            padding: '0 8px',
            fontSize: 12,
            lineHeight: 2.25,
          },
        },
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          flex: 1,
          '&.MuiToggleButton-standard': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.contrastText,
            fontWeight: 'bolder',
            '&.Mui-selected,&.Mui-selected:hover': {
              zIndex: 2,
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
            },
            '&:not(.Mui-selected)': {
              bgcolor: 'primary.main',
              zIndex: 1,
              color: 'gold.contrastText',
            },
            '&:hover': {
              opacity: 0.93,
            },
            '&.Mui-disabled': {
              opacity: 0.38,
            },
          },
          textTransform: 'none',
        }),
      },
    },
  },
});

import yup from '@/helpers/yup';

import { getRequired, getMin, getMax, getInvalid } from '@/helpers/form-fields-error-messages';

import EXTENSIONS_GROUP from '@/constants/extensions-groups';

const notReadyConnectTo = yup.object().shape(
  {
    extension: yup.number().when('phoneNumber', {
      is: (value: any) => !value,
      then: (schema) => schema.extension().required(getRequired('Extension')),
      otherwise: (schema) => schema.nullable(),
    }),
    phoneNumber: yup.string().when('extension', {
      is: (value: any) => !value,
      then: (schema) => schema.phone().required(getRequired('Phone number')),
      otherwise: (schema) => schema.nullable(),
    }),
    sequence: yup.number().min(1, getMin('Sequence', 'zero')).required(getRequired('Sequence')),
  },
  [
    ['extension', 'phoneNumber'],
    ['phoneNumber', 'extension'],
  ],
);

const callHuntConnectTo = notReadyConnectTo.concat(
  yup.object().shape({
    connectionTime: yup
      .number()
      .min(5, getMin('Connection time', 'five seconds'))
      .max(300, getMax('Connection time', '300 seconds'))
      .required(getRequired('Connection time'))
      .typeError(getInvalid('Connection time')),
  }),
);

const ringAllConnectTo = notReadyConnectTo.concat(
  yup.object().shape({
    connectionTime: yup.number().typeError(getInvalid('Connection time')),
  }),
);

const createExtensionsGroup = yup.object().shape({
  name: yup.string().required(getRequired('Name')),
  description: yup.string().nullable().optional(),
  connectionType: yup.string().required(getRequired('Connection type')),
  connectionTime: yup
    .number()
    .min(5, getMin('Connection time', 'five seconds'))
    .max(300, getMax('Connection time', '300 seconds'))
    .required(getRequired('Connection time'))
    .typeError(getInvalid('Connection time')),
  connectTo: yup
    .array()
    .min(1, getMin('Connection sequence', 'one'))
    .max(10, getMin('Connection sequence', 'ten'))
    .when('connectionType', {
      is: EXTENSIONS_GROUP.CONNECTION_TYPE.RING_ALL,
      then: (schema) => schema.of(ringAllConnectTo),
      otherwise: (schema) => schema.of(callHuntConnectTo),
    })
    .required(getRequired('Connection sequence')),
});

const updateExtensionsGroup = yup.object().shape({
  name: yup.string().required(getRequired('Name')),
  description: yup.string().nullable().optional(),
  connectionType: yup.string().required(getRequired('Connection type')),
  connectionTime: yup
    .number()
    .min(5, getMin('Connection time', 'five seconds'))
    .max(300, getMax('Connection time', '300 seconds'))
    .required(getRequired('Connection time'))
    .typeError(getInvalid('Connection time')),
  connectTo: yup
    .array()
    .min(1, getMin('Connection sequence', 'one'))
    .max(10, getMin('Connection sequence', 'ten'))
    .when('connectionType', {
      is: EXTENSIONS_GROUP.CONNECTION_TYPE.RING_ALL,
      then: (schema) => schema.of(ringAllConnectTo),
      otherwise: (schema) => schema.of(callHuntConnectTo),
    })
    .required(getRequired('Connection sequence')),
});

export default { createExtensionsGroup, updateExtensionsGroup };

import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PhoneIcon from '@mui/icons-material/Phone';
import MessageIcon from '@mui/icons-material/Message';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ContactsIcon from '@mui/icons-material/Contacts';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsIcon from '@mui/icons-material/Settings';
import Groups3Icon from '@mui/icons-material/Groups3';
import { Badge, BadgeProps, Toolbar, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import RouterIcon from '@mui/icons-material/Router';

import { useAppDispatch, useAppSelector } from '@/store/hooks';

import useThemeBreakpoints from '@/hooks/use-theme-breakpoints';
import useAccess from '@/hooks/use-access';

import { Role } from '@/types/user';

import RequestClickToCallDesktopAppDialog from '@/components/Click-to-call/Request-click-to-call-desktop-app-dialog/Request-click-to-call-desktop-app-dialog';

import * as analyticsThunk from '@/store/thunk/analytics';

import ROUTES from '@/constants/routes';
import { FEATURES } from '@/constants/features';
import { ROLES } from '@/constants/roles';
import { PERMISSIONS } from '@/constants/permissions';
import { ADDONS } from '@/constants/addons';
import { ACCESS_CONTROLS } from '@/constants/access-controls';

interface IDashboardSideMenuProps {
  isSideMenuOpened: boolean;
  setIsSideMenuOpened: Dispatch<SetStateAction<boolean>>;
}

type Menus =
  | 'voice'
  | 'messages'
  | 'broadcast'
  | 'contacts'
  | 'users'
  | 'settings'
  | 'extensions-group'
  | 'iot-devices';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.paper}`,
    fontSize: '.625rem',
  },
}));

const getMenuFromRoute = (path: string): Menus | undefined => {
  if (path.includes(ROUTES.DASHBOARD_VOICE)) {
    return 'voice';
  } else if (path.includes(ROUTES.DASHBOARD_MESSAGES)) {
    return 'messages';
  } else if (path.includes(ROUTES.DASHBOARD_BROADCAST_CAMPAIGN)) {
    return 'broadcast';
  } else if (path.includes(ROUTES.DASHBOARD_CONTACTS_PAGE_LAYOUT)) {
    return 'contacts';
  } else if (path.includes(ROUTES.DASHBOARD_USERS_RELATED_INFO)) {
    return 'users';
  } else if (path.includes(ROUTES.DASHBOARD_SETTINGS_LAYOUT)) {
    return 'settings';
  } else if (path.includes(ROUTES.DASHBOARD_IOT_DEVICES_PAGE_LAYOUT)) {
    return 'iot-devices';
  } else if (path.includes(ROUTES.DASHBOARD_EXTENSIONS_GROUPS)) {
    return 'extensions-group';
  } else {
    return undefined;
  }
};

const NAV_ITEMS: {
  label: string;
  name: string;
  route: string;
  requirements: {
    roles: Role[];
    features: FEATURES[];
    permissions: PERMISSIONS[];
    accessControls: ACCESS_CONTROLS[];
    addons: ADDONS[];
  };
  getNavItemIcon?: (metadata: Record<string, any>) => JSX.Element;
  NavItemIcon?: any;
}[] = [
  {
    label: 'Voice',
    name: 'voice',
    route: ROUTES.DASHBOARD_VOICE,
    requirements: {
      roles: [],
      features: [FEATURES.VOICE],
      permissions: [],
      accessControls: [],
      addons: [],
    },
    getNavItemIcon: (metadata) => (
      <StyledBadge
        badgeContent={metadata.missedCallsCount ?? 0}
        color="primary"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <PhoneIcon sx={metadata.iconSxProps} />
      </StyledBadge>
    ),
  },
  {
    label: 'Messages',
    name: 'messages',
    route: ROUTES.DASHBOARD_MESSAGES,
    requirements: {
      roles: [],
      features: [FEATURES.SMS],
      permissions: [],
      accessControls: [],
      addons: [],
    },
    NavItemIcon: MessageIcon,
  },
  {
    label: 'Broadcast',
    name: 'broadcast',
    route: ROUTES.DASHBOARD_BROADCAST_CAMPAIGN,
    requirements: {
      roles: [],
      features: [],
      permissions: [PERMISSIONS.BROADCAST],
      accessControls: [],
      addons: [ADDONS.BROADCAST],
    },
    NavItemIcon: PeopleOutlineIcon,
  },
  {
    label: 'Contacts',
    name: 'contacts',
    route: ROUTES.DASHBOARD_CONTACTS,
    requirements: {
      features: [],
      roles: [],
      permissions: [],
      accessControls: [],
      addons: [],
    },
    NavItemIcon: ContactsIcon,
  },
  {
    label: 'Users',
    name: 'users',
    route: ROUTES.DASHBOARD_USERS,
    requirements: {
      features: [],
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      permissions: [],
      accessControls: [],
      addons: [],
    },
    NavItemIcon: GroupsIcon,
  },
  {
    label: 'Settings',
    name: 'settings',
    route: ROUTES.DASHBOARD_SETTINGS_VOICE_SETUP,
    requirements: {
      features: [FEATURES.VOICE],
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      permissions: [],
      accessControls: [],
      addons: [],
    },
    NavItemIcon: SettingsIcon,
  },
  {
    label: 'Extensions',
    name: 'extensions-group',
    route: ROUTES.DASHBOARD_EXTENSIONS_GROUPS,
    requirements: {
      features: [FEATURES.VOICE],
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
      permissions: [],
      accessControls: [],
      addons: [],
    },
    NavItemIcon: Groups3Icon,
  },
  {
    label: 'Devices',
    name: 'iot-devices',
    route: ROUTES.DASHBOARD_IOT_DEVICES_PAGE_LAYOUT,
    requirements: {
      features: [],
      roles: [],
      permissions: [],
      accessControls: [ACCESS_CONTROLS.IOT],
      addons: [],
    },
    NavItemIcon: RouterIcon,
  },
];

const DashboardSideMenu = ({ isSideMenuOpened, setIsSideMenuOpened }: IDashboardSideMenuProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const {
    isFeatureAvailable,
    isPermissions,
    isUserRoleMatches,
    isAccessControl,
    isAddonAvailable,
  } = useAccess();
  const { user } = useAppSelector((state) => state.user);

  const isLgOrUp = useThemeBreakpoints('up', 'xl');
  const isSmOrDown = useThemeBreakpoints('down', 'sm');

  const [selectedMenu, setSelectedMenu] = useState<Menus | undefined>(
    getMenuFromRoute(location.pathname),
  );

  const navigate = useNavigate();

  const [isRequestClickToCallDialogOpened, setIsRequestClickToCallDialogOpened] =
    useState<boolean>(false);

  useEffect(() => {
    setSelectedMenu(getMenuFromRoute(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (isAddonAvailable(ADDONS.ANALYTICS) && isUserRoleMatches([ROLES.SUPER_ADMIN, ROLES.ADMIN])) {
      dispatch(analyticsThunk.fetchAnalytics());
    }
  }, []);

  const isFullMenuViewEnabled = isLgOrUp || isSmOrDown;

  return (
    <>
      <RequestClickToCallDesktopAppDialog
        isOpened={isRequestClickToCallDialogOpened}
        handleClose={() => setIsRequestClickToCallDialogOpened(false)}
      />

      <Drawer
        variant={isSmOrDown ? 'temporary' : 'permanent'}
        anchor="left"
        open={isSideMenuOpened}
        onClose={() => setIsSideMenuOpened(false)}
        onClick={() => setIsSideMenuOpened(false)}
        elevation={5}
        sx={{
          width: isLgOrUp || isSmOrDown ? '15rem' : '3.5rem',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: isLgOrUp || isSmOrDown ? '15rem' : '3.5rem',
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar disableGutters />

        <List component="nav">
          {NAV_ITEMS.map((el) => {
            const isFeatureNotAvailable =
              el.requirements.features.length &&
              !el.requirements.features.some((feature) => isFeatureAvailable(feature));

            const isPermissionNotGranted =
              el.requirements.permissions.length &&
              !el.requirements.permissions.some((permission) => isPermissions(permission));

            const isRoleNotAvailable =
              el.requirements.roles.length && !isUserRoleMatches(el.requirements.roles);

            const isAddonNotAvailable =
              el.requirements.addons.length &&
              !el.requirements.addons.some((addon) => isAddonAvailable(addon));

            const isAccessControlNotAvailable =
              el.requirements.accessControls.length &&
              !el.requirements.accessControls.some((accessControl) =>
                isAccessControl(accessControl),
              );

            if (
              isFeatureNotAvailable ||
              isPermissionNotGranted ||
              isRoleNotAvailable ||
              isAddonNotAvailable ||
              isAccessControlNotAvailable
            ) {
              return <Fragment key={el.label} />;
            }

            const colorProps: Record<string, string> = {
              color: 'primary.main',
            };

            let tooltipTitle = '';

            if (!isFullMenuViewEnabled) {
              tooltipTitle = el.label;
            }

            if (selectedMenu === el.name) {
              colorProps.bgcolor = 'primary.main';
              colorProps.color = 'primary.contrastText';
            }

            return (
              <ListItem key={el.label} sx={colorProps} disablePadding>
                <Tooltip title={tooltipTitle} placement="right">
                  <ListItemButton onClick={() => navigate(el.route)}>
                    <ListItemIcon>
                      {el.NavItemIcon ? <el.NavItemIcon sx={colorProps} /> : null}

                      {el.getNavItemIcon
                        ? el.getNavItemIcon({
                            missedCallsCount: user?.missedCallsCount,
                            iconSxProps: colorProps,
                          })
                        : null}
                    </ListItemIcon>

                    <ListItemText primary={el.label} primaryTypographyProps={colorProps} />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            );
          })}

          <ListItem disablePadding>
            <Tooltip title={isFullMenuViewEnabled ? '' : 'Click to Call'} placement="right">
              <ListItemButton onClick={() => setIsRequestClickToCallDialogOpened(true)}>
                <ListItemIcon>
                  <AdsClickIcon color="primary" />
                </ListItemIcon>

                <ListItemText
                  primary="Click to Call"
                  sx={{ color: 'primary.main', whiteSpace: 'nowrap' }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>

        <Toolbar disableGutters />
      </Drawer>
    </>
  );
};

export default DashboardSideMenu;

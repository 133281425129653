import { useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  Typography,
} from '@mui/material';

import {
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';

import { IContactsGroupRecord } from '@/interfaces/contacts-group';

import TemplateTable, { ITemplateTableProps } from '@/components/Template-table/Template-table';
import DefaultAvatar from '@/assets/images/generic_wwtjhr.png';

import { NO_DATA_VALUE } from '@/constants/layout';

interface IContactGroupsListProps {
  data: IContactsGroupRecord[];
  handleUpdate: (el: IContactsGroupRecord) => void;
  handleDelete: (el: IContactsGroupRecord) => void;
}

interface IContactsGroupActionsMenuButtonProps {
  contactsGroup: IContactsGroupRecord;
  handleUpdate: (el: IContactsGroupRecord) => void;
  handleDelete: (el: IContactsGroupRecord) => void;
}

const ContactsGroupActionsMenuButton = ({
  contactsGroup,
  ...actions
}: IContactsGroupActionsMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpened = !!anchorEl;

  return (
    <IconButton
      color="primary"
      onClick={(event) => {
        event.stopPropagation();
        setAnchorEl((prevState) => (prevState ? null : event.currentTarget));
      }}
    >
      <Menu
        anchorEl={anchorEl}
        open={isOpened}
        disableAutoFocusItem
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => actions.handleUpdate(contactsGroup)}>
          <ListItemIcon>
            <EditIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Edit
        </MenuItem>

        <MenuItem onClick={() => actions.handleDelete(contactsGroup)}>
          <ListItemIcon>
            <DeleteOutlineIcon color="error" fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>

      <MoreVertIcon />
    </IconButton>
  );
};

const ContactGroupsList = ({ data, ...actions }: IContactGroupsListProps) => {
  const templateTableProps: ITemplateTableProps<IContactsGroupRecord> = {
    data,
    getDataEntityId: (el) => el.id,
    getExpandComponent: (el) => (
      <TableCell colSpan={templateTableProps.columns.length + 1} sx={{ pt: 0 }}>
        <Typography variant="subtitle2" component="p" fontWeight="bold" mb={1}>
          Contacts
        </Typography>

        <Box display="flex" flexWrap="wrap" gap={1}>
          {el.contacts.length
            ? el.contacts.map((el) => (
                <Chip
                  sx={{ height: 'auto', pl: 1 }}
                  key={el.id}
                  avatar={<Avatar src={el.avatarUrl ? el.avatarUrl : DefaultAvatar} />}
                  label={
                    <Stack p={1}>
                      <Typography color="inherit" fontSize="inherit">
                        {el.fullName}
                      </Typography>

                      <Typography color="inherit" fontSize="inherit">
                        {el.phoneNumber}
                      </Typography>
                    </Stack>
                  }
                />
              ))
            : NO_DATA_VALUE}
        </Box>
      </TableCell>
    ),
    columns: [
      {
        header: 'Name',
        getValue: (el) => el.name,
      },
      {
        sharedProps: { sx: { width: '45%' } },
        header: 'Description',
        getValue: (el) => el.description || NO_DATA_VALUE,
      },
      {
        sharedProps: { sx: { minWidth: '10rem' } },
        header: 'Contacts in group',
        getValue: (el) => el.contacts.length || NO_DATA_VALUE,
      },
      {
        sharedProps: { sx: { width: '7rem' } },
        header: 'Actions',
        getValue: (el) => <ContactsGroupActionsMenuButton contactsGroup={el} {...actions} />,
      },
    ],
    tableContainerProps: { sx: { height: 'calc(100vh - 24rem)' } },
  };

  return <TemplateTable {...templateTableProps} />;
};

export default ContactGroupsList;

import React from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import * as styles from './styles';

import * as featuresAccessDialogReducer from '@/store/reducers/features-access-dialog';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FeaturesAccessDialogSlide = () => {
  const dispatch = useAppDispatch();

  const { message, show } = useAppSelector((state) => state.featureAccessDialog);

  const handleClose = () => {
    dispatch(featuresAccessDialogReducer.show());
  };

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{}}
    >
      {/* <DialogTitle sx={styles.dialogTitle1SX}>Microphone is disabled</DialogTitle> */}
      <DialogContent sx={styles.dialogContent1SX}>
        <DialogContentText sx={styles.dialogContentText1SX}>
          {message}Please call or text Whistle Support with any questions .
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={styles.dialogAction1SX}>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeaturesAccessDialogSlide;

import { Box, Divider, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

import CompanyMsgsCallsGroup from './Company-msgs-calls-group';

const ChatsAndMessagingSettingsLayout: FunctionComponent = (): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" flex={1} gap={1}>
      <Box mb={2}>
        <Typography variant="h6" component="p">
          Messaging settings
        </Typography>

        <Divider />
      </Box>

      <Box display="flex" flexDirection="column" gap={2}>
        <CompanyMsgsCallsGroup />
      </Box>
    </Box>
  );
};

export default ChatsAndMessagingSettingsLayout;

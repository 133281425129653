import { useEffect, useState } from 'react';
import { useAppDispatch } from '@/store/hooks';

import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import { ITenantCompanyMsgsCallsGroupUsersResult } from '@/interfaces/tenant';
import { IUserResult } from '@/interfaces/users/users';

import CustomAvatar from '@/components/Custom-avatar/Avatar';

import * as settingsThunk from '../../thunk';
import * as userThunk from '@/pages/dashboard/users/thunk';

import apisV1 from '@/services/api/v1/index';

const CompanyMsgsCallsGroup = () => {
  const dispatch = useAppDispatch();

  const [companyMsgsCallsGroup, setCompanyMsgsCallsGroup] = useState<
    ITenantCompanyMsgsCallsGroupUsersResult[]
  >([]);
  const [users, setUsers] = useState<ITenantCompanyMsgsCallsGroupUsersResult[]>([]);

  const getCompanyMsgsCallsGroup = async (): Promise<void> => {
    const { payload } = await dispatch(settingsThunk.fetchCompanyMsgsCallsGroup());
    if (payload) {
      setCompanyMsgsCallsGroup(payload);
    }
  };

  const getUsers = async (): Promise<void> => {
    const { success, data } = await apisV1.usersApis.getByParams({
      search: '',
      excludeMe: false,
      limit: 1000,
      skip: 0,
      sortField: undefined,
      sortDirection: undefined,
    });

    if (success) {
      setUsers(
        data.records.map((element: IUserResult) => ({
          id: element.id,
          avatarUrl: element.avatar?.url ?? null,
          fullName: element.fullName,
        })),
      );
    }
  };

  const handleSetCompanyMsgsCallGroup = async (): Promise<void> => {
    await dispatch(
      settingsThunk.fetchSetCompanyMsgsCallsGroup({
        ids: companyMsgsCallsGroup.map((element) => element.id),
      }),
    );

    await dispatch(userThunk.fetchGetUserInfo());
  };

  const handleDeleteUser = (id: string): void => {
    setCompanyMsgsCallsGroup((current) => current.filter((element) => element.id !== id));
  };

  const handleAddToGroup = (data: ITenantCompanyMsgsCallsGroupUsersResult): void => {
    if (isSelected(data.id)) {
      return;
    }

    setCompanyMsgsCallsGroup((current) => [...current, data]);
  };

  const handleClear = (): void => {
    setCompanyMsgsCallsGroup([]);
  };

  const isSelected = (id: string): boolean => {
    return companyMsgsCallsGroup.some((element) => element.id === id);
  };

  useEffect(() => {
    getUsers();
    getCompanyMsgsCallsGroup();
  }, []);

  return (
    <Stack spacing={1}>
      <Typography component="label" htmlFor="devices-preferences">
        Select users who can see shared company messages and calls
      </Typography>

      <Stack spacing={3}>
        <Autocomplete
          multiple
          fullWidth
          options={users}
          value={companyMsgsCallsGroup}
          getOptionLabel={(option) => option.fullName}
          isOptionEqualToValue={(option, value) => option.fullName === value.fullName}
          onChange={(event, list, reason, detail) => {
            if (reason === 'clear') {
              handleClear();
            }
            if (detail?.option) {
              handleAddToGroup(detail.option);
            }
          }}
          renderTags={(value) => {
            return value.map((option, index: number) => (
              <Box
                display={'flex'}
                key={index}
                alignItems={'center'}
                borderRadius={20}
                px={1}
                sx={{ border: '1px solid black' }}
                mr={0.5}
              >
                <CustomAvatar
                  url={option.avatarUrl}
                  showLiveStatus={false}
                  sx={{ width: 15, height: 15 }}
                />
                <Typography ml={1} fontSize={15}>
                  {option.fullName}
                </Typography>
                <IconButton onClick={() => handleDeleteUser(option.id)} sx={{ p: 0, m: 0 }}>
                  <Tooltip title="Remove">
                    <CloseIcon sx={{ width: 20, height: 20, mb: 0.1 }} />
                  </Tooltip>
                </IconButton>
              </Box>
            ));
          }}
          renderOption={(props, option) => {
            return (
              <Box
                {...props}
                component="li"
                display={'flex'}
                key={option.id}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
              >
                <CustomAvatar
                  url={option.avatarUrl}
                  showLiveStatus={false}
                  sx={{ width: 15, height: 15 }}
                />
                <Typography ml={1} fontSize={15}>
                  {option.fullName}
                </Typography>
                {isSelected(option.id) && <DoneIcon sx={{ ml: 2 }} color="success" />}
              </Box>
            );
          }}
          renderInput={(params) => {
            return <TextField {...params} variant="standard" placeholder="Select users" />;
          }}
        />

        <Button
          variant="contained"
          color="success"
          onClick={handleSetCompanyMsgsCallGroup}
          sx={{ width: 100 }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default CompanyMsgsCallsGroup;
